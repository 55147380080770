import { useCallback, useMemo } from "react";
import { getValueFromKeyHelper } from "../../common/menu/utils/utils";
import { useAppSelector } from "../../app";
import { OptionType } from "../../metadata/interfaces/classifier";
import { MetricFilterItem } from "../interfaces/documentSetMetrics";
import { METRIC_FILTER_ITEM_DELIMITER } from "../constants";

const useMetricsFilterItem = (filterItem: MetricFilterItem) => {
  const { filterArray } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const getValueFromKey = useCallback(
    (
      key: string,
      separator: string,
      itemList?: Array<OptionType>
    ): string | undefined =>
      getValueFromKeyHelper(filterArray, key, separator, itemList),
    [filterArray]
  );

  const hasFilter = useMemo(() => {
    if (!filterItem?.metadata?.filterAble?.filterKey) {
      return false;
    }

    return (
      getValueFromKey(
        filterItem.metadata?.filterAble.filterKey,
        METRIC_FILTER_ITEM_DELIMITER
      ) !== undefined
    );
  }, [filterItem, getValueFromKey]);

  return {
    getValueFromKey,
    hasFilter,
  };
};

export default useMetricsFilterItem;

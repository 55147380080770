import React, { FC } from "react";
import { Button, InputNumber, Select, Switch } from "antd";
import "./StpEntityTableFooterAdd.scss";
import { useTranslation } from "react-i18next";
import useAddStpEntity from "../../../../hooks/useAddStpEntity";
import { Option } from "../../../../../common/dataRefining/interfaces/filteringData";

type Props = {
  handleCancel: (isAdding: boolean) => void;
  sourcesOptions: Array<Option>;
  validationOptions: Array<Option>;
};

const StpEntityTableFooterAdd: FC<Props> = ({
  handleCancel,
  sourcesOptions,
  validationOptions,
}) => {
  const { t } = useTranslation("stp");

  const { options, stpEntity, setStpEntity, handleClick, onSelectSearch } =
    useAddStpEntity(handleCancel);

  return (
    <div className="stp-entity-footer-add__container">
      <div className="stp-entity-footer-add__spacer" />
      <div className="stp-entity-footer-add__content">
        <div style={{ width: "21%", minWidth: "180px" }}>
          <Select
            className="filter-item__select full-width column-padding"
            showSearch
            allowClear
            value={stpEntity.entityTypeId}
            onChange={(value) =>
              setStpEntity((entity) => ({
                ...entity,
                entityTypeId: value,
              }))
            }
            placeholder={t("entities.selectEntity")}
            filterOption={onSelectSearch}
            options={options}
          />
        </div>
        <div style={{ width: "18%", minWidth: "150px" }}>
          <Select
            placeholder={t("entities.selectValidation")}
            value={stpEntity.entityValidation}
            allowClear={true}
            onChange={(value) =>
              setStpEntity((entity) => ({
                ...entity,
                entityValidation: value,
              }))
            }
            options={validationOptions}
            className="full-width column-padding"
          />
        </div>
        <div
          style={{
            minWidth: "80px",
            width: "9%",
            paddingRight: "8px",
            paddingLeft: "8px",
          }}
        >
          <InputNumber
            min={50}
            max={100}
            style={{ width: "100%" }}
            value={stpEntity.threshold}
            className="full-width"
            onChange={(value) =>
              setStpEntity((entity) => ({
                ...entity,
                threshold: value!,
              }))
            }
          />
        </div>
        <div style={{ width: "35%", minWidth: "300px" }}>
          <Select
            className="full-width column-padding"
            placeholder={t("entities.selectSources")}
            value={stpEntity.sources}
            allowClear={true}
            mode="multiple"
            onChange={(values) =>
              setStpEntity((entity) => ({
                ...entity,
                sources: values,
              }))
            }
            filterOption={onSelectSearch}
            options={sourcesOptions}
          />
        </div>
        <div style={{ width: "6%", minWidth: "70px" }}>
          <Switch
            className="column-padding"
            value={stpEntity.isActive}
            onChange={(value) =>
              setStpEntity((entity) => ({
                ...entity,
                isActive: value,
              }))
            }
          />
        </div>
        <div
          className="full-width"
          style={{
            width: "7%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ padding: "7px 10px" }}
            size="large"
            type="text"
            onClick={handleClick}
          >
            <i style={{ fontSize: "24px" }} className="bi bi-check-circle" />
          </Button>
          <Button
            style={{ padding: "7px 10px" }}
            onClick={() => handleCancel(false)}
            size="large"
            type="text"
          >
            <i style={{ fontSize: "24px" }} className="bi bi-x-circle" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StpEntityTableFooterAdd;

import React, { FC, MouseEvent, useCallback } from "react";
import { ColumnItem } from "../../../../../interfaces/overview";
import { DocumentSetData } from "../../../../../interfaces/documentSet";
import "./CopyableTypeCell.scss";
import { notification, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import useCellValue from "../../../../../hooks/useCellValue";

type Props = {
  documentSet: DocumentSetData;
  columnItem: ColumnItem;
};

const CopyableTypeCell: FC<Props> = ({ documentSet, columnItem }) => {
  const { t } = useTranslation("overview");
  const value = useCellValue(documentSet, columnItem);

  const copyValueToClipboard = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      navigator.clipboard.writeText(value);

      notification.success({
        message: t("columnCells.customCell.copiedToClipboard"),
      });
    },
    [value, t]
  );

  if (value.toUpperCase() === "N/A") {
    return (
      <div className="copyable-cell__container">
        <Tooltip placement="top" title={value}>
          <label className="copyable-cell__label">{value}</label>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className="copyable-cell__container">
      <Tooltip placement="top" title={value}>
        <label className="copyable-cell__label">{value}</label>
      </Tooltip>
      <Tooltip
        placement="topLeft"
        className="copyable-cell__icon"
        title={t("columnCells.customCell.copyTooltip")}
      >
        <i className="bi bi-copy" onClick={copyValueToClipboard}></i>
      </Tooltip>
    </div>
  );
};

export default CopyableTypeCell;

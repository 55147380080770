import React, { FC, MouseEvent, useCallback, useMemo } from "react";
import { Checkbox } from "antd";
import useBulkAction from "../../../../hooks/useBulkAction";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import { doneStatuses, Status } from "../../../../../common/status/status";
import { useAppSelector } from "../../../../../app";

type Props = {
  documentSet: DocumentSetData;
};

const BulkActionCheckboxCell: FC<Props> = ({ documentSet }) => {
  const { selectedRows, toggleBulkActionCheckbox } = useBulkAction();

  const user = useAppSelector((state) => state.userReducer.user);

  const checked = useMemo(() => {
    return selectedRows.includes(documentSet.id);
  }, [selectedRows, documentSet.id]);

  const canRowBeSelected = useMemo(() => {
    return (
      !doneStatuses.includes(documentSet.status) &&
      (!documentSet.assignee || documentSet.assignee?.id === user?.id) &&
      documentSet.status !== Status.Remove
    );
  }, [documentSet.status, documentSet.assignee, user?.id]);

  const handleCheckboxClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();

      if (!canRowBeSelected) {
        return;
      }

      toggleBulkActionCheckbox(documentSet.id);
    },
    [toggleBulkActionCheckbox, documentSet.id, canRowBeSelected]
  );

  return (
    <div className="bulk-action-checkbox" onClick={handleCheckboxClick}>
      <div className={selectedRows.length >= 1 ? "visible" : "hover"}>
        {canRowBeSelected && (
          <Checkbox
            checked={checked}
            disabled={!canRowBeSelected}
            title={canRowBeSelected.toString()}
          />
        )}
      </div>
    </div>
  );
};

export default BulkActionCheckboxCell;

import { useCallback, useMemo, useRef, useState } from "react";
import Fuse from "fuse.js";

const DEFAULT_FUSE_THRESHOLD = 0.15;

export interface FuseConfig<T extends object> {
  keys: Array<Fuse.FuseOptionKey<T>>;
  threshold?: number;
}

const useTableDataFilter = <T extends object, F extends Partial<T>>(
  data: Array<T>,
  filter: F,
  fuseConfig: FuseConfig<T>,
  idKey: keyof T
) => {
  const [filterData, setFilterData] = useState<Array<T>>([]);
  const [filterQuery, setFilterQuery] = useState<F>(filter);
  const hasRanFuseQuery = useRef(false);

  const fuse = useMemo(() => {
    return new Fuse(data, {
      keys: fuseConfig.keys,
      threshold: fuseConfig.threshold || DEFAULT_FUSE_THRESHOLD,
    });
  }, [fuseConfig, data]);

  const handleFilterUpdate = useCallback((prop: keyof T, value: T[keyof T]) => {
    setFilterQuery((prevState) => ({
      ...prevState,
      [prop]: value,
    }));
  }, []);

  const applyFilter = useCallback(() => {
    const fuseQuery: Fuse.Expression[] = [];

    Object.keys(filterQuery).forEach((k) => {
      if (Array.isArray(filterQuery[k as keyof T])) {
        if (!(filterQuery[k as keyof T] as unknown as any[]).length) {
          return;
        }

        const orGroup: Array<any> = [];

        (filterQuery[k as keyof T] as unknown as any[]).forEach((v) => {
          orGroup.push({ [k]: v });
        });
        fuseQuery.push({ $or: orGroup });
        return;
      }

      if (filterQuery[k as keyof T]) {
        fuseQuery.push({ [k]: filterQuery[k as keyof T] });
      }
    });

    if (!fuseQuery.length) {
      setFilterData([]);
      hasRanFuseQuery.current = false;
      return;
    }

    const fuseSearchResult = fuse.search({
      $and: fuseQuery,
    });

    setFilterData(fuseSearchResult.map(({ item }) => item));
    hasRanFuseQuery.current = true;
  }, [filterQuery, fuse]);

  const getValueFromFilterQuery = useCallback(
    (prop: keyof F) => {
      return filterQuery[prop];
    },
    [filterQuery]
  );

  const filteredData = useMemo(() => {
    if (!hasRanFuseQuery.current) {
      return data;
    }

    const ids = filterData.map((fd) => fd[idKey]);
    return data.filter((d) => ids.includes(d[idKey]));
  }, [data, filterData, idKey]);

  return {
    filteredData,
    handleFilterUpdate,
    applyFilter,
    getValueFromFilterQuery,
  };
};

export default useTableDataFilter;

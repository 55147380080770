import { useAppSelector } from "../../app";
import { useFetchTextLayerQuery } from "../store/documentApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useEffect } from "react";

const useTextLayer = () => {
  const { activeDocument } = useAppSelector((state) => state.documentReducer);

  const {
    isFetching: textLayerLoading,
    refetch,
    isUninitialized,
  } = useFetchTextLayerQuery(activeDocument ?? skipToken);

  useEffect(() => {
    if (!activeDocument) {
      return;
    }

    if (!isUninitialized) {
      refetch();
    }
  }, [refetch, activeDocument, isUninitialized]);

  return { textLayerLoading };
};

export default useTextLayer;

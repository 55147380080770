import React, { FC, useMemo } from "react";
import { getExtensionFromFileName } from "../../../../../common/utilities/file";

type DocumentIconMapperProps = {
  documentName: string;
};

const DocumentIconMapper: FC<DocumentIconMapperProps> = ({ documentName }) => {
  const extension = useMemo(
    () => getExtensionFromFileName(documentName)?.toLocaleLowerCase(),
    [documentName]
  );

  switch (extension) {
    case "pdf":
      return <i className="bi bi-file-earmark-pdf document-pill__icon" />;

    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return <i className="bi bi-file-earmark-image document-pill__icon" />;
    default:
      return <i className="bi bi-file-earmark document-pill__icon" />;
  }
};

export default DocumentIconMapper;

import React, { FunctionComponent, useRef, useMemo, Profiler } from "react";
import "../GroupBlocksContainer.scss";
import classNames from "classnames";
import GroupBlockAddButton from "./GroupBlockAddButton";
import GroupBlockTitle from "./GroupBlockTitle";
import {
  GroupBlockData,
  useHotKeyListener,
  useMultipleGroupBlocks,
} from "../../../../configMap";
import useAnnotations from "../../../../annotation";
import useActiveDocument from "../../../../document/hooks/useActiveDocument";
import EntityWrapper from "../../entity/EntityWrapper";
import CategorizationBlock from "../../../../metadata";
import useDocumentCategoryAnnotations from "../../../../metadata/hooks/useDocumentCategoryAnnotations";
import useGroupBlock from "../../../hooks/useGroupBlock";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import { onRender } from "../../../../app/utils/profiling";

interface Props {
  groupBlock: GroupBlockData;
  disabled: boolean;
  hideNonRequiredEntities: boolean;
  groupBlockCount: number;
}

const GroupBlock: FunctionComponent<Props> = ({
  groupBlock,
  disabled,
  hideNonRequiredEntities,
  groupBlockCount,
}) => {
  const {
    id,
    multipleGroupBlocks,
    groupBlockEntityTypes,
    translations,
    categorizationGroupBlocks,
  } = groupBlock;
  const elRef = useRef<HTMLDivElement>(null);
  const { position } = useIntersectionObserver(elRef);

  useHotKeyListener(
    hideNonRequiredEntities,
    disabled,
    groupBlockEntityTypes,
    multipleGroupBlocks
  );

  const { activeDocument } = useActiveDocument();
  const { addGroupBlock } = useMultipleGroupBlocks();
  const { updateMultiGroupBlockAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const { updateGroupBlockDocumentCategoryAnnotations } =
    useDocumentCategoryAnnotations();

  const {
    entityTypesSorted,
    entityIds,
    removeGroupBlocksForEntityAndAnnotations,
    removeGroupBlockAndAnnotations,
    removeAnnotations,
  } = useGroupBlock(false, groupBlock, activeDocument);

  const categorizations = useMemo(() => {
    if (!categorizationGroupBlocks) {
      return [];
    }

    return [...categorizationGroupBlocks].map((c) => c.categorization);
  }, [categorizationGroupBlocks]);

  const entityGroupBlockContainerClass = classNames({
    "entity-group-block-container": true,
    disabled,
  });

  if (multipleGroupBlocks) {
    return (
      <div ref={elRef}>
        {Array(groupBlockCount)
          .fill(0)
          .map((_, index) => {
            const currentIndex = index + 1;
            const targetIndex = currentIndex + 1;
            return (
              <div
                id={`group-block-${id}-${currentIndex}`}
                className={entityGroupBlockContainerClass}
                key={id + "-" + currentIndex}
              >
                <GroupBlockTitle
                  translations={translations}
                  entityIds={entityIds}
                  index={currentIndex}
                  showIndex={groupBlockCount > 1}
                  removeGroupBlockAndAnnotations={() =>
                    removeGroupBlockAndAnnotations(currentIndex)
                  }
                  removeAnnotations={() => removeAnnotations(currentIndex)}
                  removeGroupBlocksForEntityAndAnnotations={() =>
                    removeGroupBlocksForEntityAndAnnotations(index)
                  }
                  showRemoveAnnotationsButton={!disabled}
                  showRemoveGroupBlockButton={!disabled && groupBlockCount > 1}
                />
                {index === 0 ? (
                  <GroupBlockAddButton
                    id={`group-block-button-${id}-before-${currentIndex}`}
                    translations={translations}
                    show={!disabled}
                    onClick={() => {
                      updateMultiGroupBlockAnnotations(
                        false,
                        groupBlock.groupBlockEntityTypes?.map(
                          (entity) => entity.entityType.id
                        ) || [],
                        index,
                        activeDocument?.id || 0,
                        true
                      );
                      updateGroupBlockDocumentCategoryAnnotations(
                        categorizations,
                        index,
                        true
                      );
                      addGroupBlock(
                        groupBlock,
                        `group-block-${id}-${currentIndex}`,
                        currentIndex
                      );
                    }}
                  />
                ) : null}
                <CategorizationBlock
                  disabled={disabled}
                  categorizationsWithConfig={categorizationGroupBlocks}
                  showHeader={false}
                  groupBlockId={id}
                  index={currentIndex}
                />
                <div className="entity-group-block-list">
                  {entityTypesSorted?.map((groupBlockEntityType) => (
                    <Profiler
                      key={groupBlockEntityType.id}
                      id="EntityWrapper"
                      onRender={onRender}
                    >
                      <EntityWrapper
                        visibility={position}
                        groupBlockEntityType={{
                          ...groupBlockEntityType,
                          multipleGroupBlocks: groupBlock.multipleGroupBlocks,
                        }}
                        groupBlockIndex={currentIndex}
                        hideNonRequiredEntities={hideNonRequiredEntities}
                        disabled={disabled}
                      />
                    </Profiler>
                  ))}
                </div>
                <GroupBlockAddButton
                  id={`group-block-button-${id}-${currentIndex}`}
                  translations={translations}
                  show={!disabled}
                  onClick={() => {
                    updateMultiGroupBlockAnnotations(
                      false,
                      groupBlock.groupBlockEntityTypes?.map(
                        (entity) => entity.entityType.id
                      ) || [],
                      index,
                      activeDocument?.id || 0,
                      false
                    );
                    updateGroupBlockDocumentCategoryAnnotations(
                      categorizations,
                      index,
                      false
                    );
                    addGroupBlock(
                      groupBlock,
                      `group-block-${id}-${targetIndex}`,
                      targetIndex
                    );
                  }}
                />
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <div className={entityGroupBlockContainerClass} ref={elRef}>
      <GroupBlockTitle
        index={1}
        showIndex={false}
        entityIds={entityIds}
        translations={translations}
        removeAnnotations={() => removeAnnotations(1)}
        showRemoveAnnotationsButton={!disabled}
      />
      <CategorizationBlock
        disabled={disabled}
        categorizationsWithConfig={categorizationGroupBlocks}
        showHeader={false}
        groupBlockId={id}
      />
      <div className="entity-group-block-list">
        {entityTypesSorted?.map((groupBlockEntityType) => (
          <EntityWrapper
            visibility={position}
            key={groupBlockEntityType.id}
            groupBlockEntityType={{
              ...groupBlockEntityType,
              multipleGroupBlocks: groupBlock.multipleGroupBlocks,
            }}
            groupBlockIndex={1}
            hideNonRequiredEntities={hideNonRequiredEntities}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default GroupBlock;

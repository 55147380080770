import { createSelector } from "@reduxjs/toolkit";
import selectActiveDocument from "./activeDocumentSelector";
import { SupportedLanguage } from "../../app";

const selectActiveDocumentLanguage = createSelector(
  selectActiveDocument,
  (activeDocument): SupportedLanguage => {
    if (!activeDocument?.metaData?.language) {
      return "en";
    }

    return activeDocument.metaData.language;
  }
);

export default selectActiveDocumentLanguage;

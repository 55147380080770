import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Props = {
  modalKey: string;
};

const selectModalState = (state: RootState) => state.appReducer.modalStates;

const selectModalStateByKey = createSelector(
  selectModalState,
  (_: any, props: Props) => props,
  (modalState, props): boolean => {
    const { modalKey } = props;
    if (modalKey in modalState) {
      return modalState[modalKey];
    }

    return false;
  }
);

export default selectModalStateByKey;

import React, { FC } from "react";
import { GroupBlockEntityType } from "../../interfaces/entity";
import { getInLanguageOrDefault } from "../../../common/utilities/language";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { useAppSelector } from "../../../app";
import linkedEntitiesSelector from "../../selectors/linkedEntitiesSelector";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
};

const EntityTitle: FC<Props> = ({ groupBlockEntityType }) => {
  const { entityType } = groupBlockEntityType;
  const { translations } = entityType;

  const linkedEntities = useAppSelector((state) =>
    linkedEntitiesSelector(state, { groupBlockEntityType })
  );

  const {
    t,
    i18n: { language },
  } = useTranslation("detail");

  if (linkedEntities.length) {
    return (
      <div>
        <Tooltip
          title={
            <div>
              <label>{t("associatedEntities")}:</label>
              <ul>
                {linkedEntities.map((e) => (
                  <li key={e.id}>
                    {`• ${getInLanguageOrDefault(
                      e.entityType.translations,
                      language
                    )}`.trim()}
                  </li>
                ))}
              </ul>
            </div>
          }
        >
          <i className="bi bi-link-45deg" style={{ color: "white" }} />
        </Tooltip>
        <span style={{ marginLeft: "5px" }}>
          {`${getInLanguageOrDefault(translations, language)} ${
            groupBlockEntityType.required ? "*" : ""
          }`.trim()}
        </span>
      </div>
    );
  }

  return (
    <div>
      <span>
        {`${getInLanguageOrDefault(translations, language)} ${
          groupBlockEntityType.required ? "*" : ""
        }`.trim()}
      </span>
    </div>
  );
};

export default EntityTitle;

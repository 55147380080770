import React, { FC, useState } from "react";
// @ts-ignore
import hash from "object-hash";
import { TextLayerItem } from "../../../interfaces/textLayer";
import TokenContainer from "./TokenContainer";
import NotRenderedOverlay from "./NotRenderedOverlay";

interface Props {
  inView: boolean;
  shouldRender: boolean;
  canvasInitialized: boolean;
  textLayer?: Array<TextLayerItem>;
  pageNumber: number;
}

const TextLayer: FC<Props> = ({
  inView,
  shouldRender,
  canvasInitialized,
  textLayer,
  pageNumber,
}) => {
  const [render, setRender] = useState(shouldRender);

  if (!render) {
    if (textLayer?.length) {
      return (
        <NotRenderedOverlay
          handleClick={() => setRender((prevState) => !prevState)}
        />
      );
    }

    return null;
  }

  if (inView && canvasInitialized && textLayer?.length) {
    return (
      <>
        {textLayer.map((textLayerItem) => {
          if (!textLayerItem.text?.replace(/\s/g, "")) {
            return null;
          }

          const tokens = [textLayerItem.text];
          if (!tokens) {
            return null;
          }

          return (
            <TokenContainer
              key={hash(textLayerItem)}
              textLayerItem={textLayerItem}
              tokens={tokens}
              pageNumber={pageNumber}
            />
          );
        })}
      </>
    );
  }

  return null;
};

export default TextLayer;

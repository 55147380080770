import { useCallback, useState } from "react";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";

const useUserConflictModal = () => {
  const [isOpenUserConflictModal, setIsOpenUserConflictModal] = useState(false);

  const { navigateWithState } = useNavigateWithState();

  const handleGoToOverview = useCallback(() => {
    navigateWithState("/");
  }, [navigateWithState]);

  const openUserConflictModal = useCallback(() => {
    setIsOpenUserConflictModal(true);
  }, [setIsOpenUserConflictModal]);

  return {
    isOpenUserConflictModal,
    setIsOpenUserConflictModal,
    handleGoToOverview,
    openUserConflictModal,
  };
};

export default useUserConflictModal;

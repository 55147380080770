import React, { FC } from "react";
import { Rectangle } from "../../../interfaces/textLayer";

interface Props {
  isDragging: boolean;
  coordinates: Rectangle;
}

const SelectionRectangle: FC<Props> = ({ isDragging, coordinates }) => {
  if (!isDragging) {
    return null;
  }

  return (
    <span
      data-ignore={true}
      className="selection__rectangle"
      style={{
        left: `${coordinates.left}px`,
        top: `${coordinates.top}px`,
        width: `${coordinates.width}px`,
        height: `${coordinates.height}px`,
      }}
    />
  );
};

export default SelectionRectangle;

import React, { FC } from "react";
import RoleIconMapper from "./RoleIconMapper";
import "./RoleIcons.scss";

type Props = {
  roles: Array<string>;
};

const RoleIcons: FC<Props> = ({ roles }) => {
  return (
    <div className="role-icons__container">
      {[...roles].sort().map((r) => (
        <RoleIconMapper key={r} role={r} />
      ))}
    </div>
  );
};

export default RoleIcons;

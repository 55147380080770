import React, { FC, MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

type Props = {
  setHeight: (height: number) => void;
};

const ErrorAlert: FC<Props> = ({ setHeight }) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const { t } = useTranslation("annotationSideControls", {
    keyPrefix: "alert.error",
  });

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setHeight(ref.current.clientHeight);
  }, [setHeight]);

  return (
    <div ref={ref}>
      <Alert
        className="error__alert-warning"
        message={t("title")}
        description={t("description")}
        type="warning"
        icon={<i className="bi bi-exclamation-circle warning__icon" />}
        showIcon
      />
    </div>
  );
};

export default ErrorAlert;

import {
  Validation,
  ValidationTypes,
} from "../../../../../../common/validation/constants";
import { isValidDate } from "../../../../../../common/utilities/date";

const VALIDATIONS_DATE: Array<Validation> = [
  {
    type: ValidationTypes.ERROR,
    message: "dateEntity.errors.invalid",
    icon: "ExclamationCircle",
    requiresValue: true,
    validate: isValidDate,
  },
];

export default VALIDATIONS_DATE;

export const sortAlphabetically = (a: string, b: string): number => {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
};

export const sortNumerically = (a: number, b: number) => {
  return a - b;
};

// This function will sort based on a boolean, asc = true values on top, desc = false on top
export const sortByBoolean = (a: boolean, b: boolean): number => {
  if (a === b) {
    return 0;
  }
  return a ? -1 : 1;
};

import { RefObject, useEffect, useState } from "react";

export enum Visibility {
  VISIBLE,
  BELOW,
  ABOVE,
}
const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  offset = 0
) => {
  const [position, setPosition] = useState<Visibility>(Visibility.BELOW);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = ([
      entry,
    ]: Array<IntersectionObserverEntry>) => {
      if (!entry) {
        return;
      }

      if (entry.isIntersecting) {
        setPosition(Visibility.VISIBLE);
        return;
      }

      if (entry.boundingClientRect.top - offset > 0) {
        setPosition(Visibility.BELOW);
      } else {
        setPosition(Visibility.ABOVE);
      }
    };

    const observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0,
    });

    const el = elementRef.current;
    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.unobserve(el);
      }
    };
  }, [elementRef, offset]);

  return { position };
};

export default useIntersectionObserver;

import { createSelector } from "@reduxjs/toolkit";
import { Annotation } from "../../annotator/interfaces/annotation";
import selectAnnotationReducer from "./annotationReducerSelector";

const tempAnnotationSelector = createSelector(
  [selectAnnotationReducer],
  (annotations): Array<Annotation> => {
    return [...annotations].filter((a) => a.tempAnnotation);
  }
);

export default tempAnnotationSelector;

import "./index.scss";

// All required imports and extends required through the entire app
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import updateLocale from "dayjs/plugin/updateLocale";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import "dayjs/locale/nl";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";
import "./i18n";
import "./heap";
import "./hotjar";
import "./sentry";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

dayjs.updateLocale("en-gb", {
  weekStart: 1,
  monthsShort: "jan_feb_mar_apr_may_jun_jul_aug_sep_oct_nov_dec".split("_"),
});

dayjs.updateLocale("nl", {
  weekStart: 1,
});

dayjs.updateLocale("fr", {
  weekStart: 1,
  monthsShort: "janv_févr_mars_avr_mai_juin_juil_août_sept_oct_nov_déc".split(
    "_"
  ),
});

import React, { useCallback } from "react";
import { Select } from "antd";
import { useAppSelector } from "../../../hooks/hooks";
import useActiveDocument from "../../../../document/hooks/useActiveDocument";
import "./DetailDocumentPicker.scss";

const DetailDocumentPicker = () => {
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );
  const { activeDocument } = useAppSelector((state) => state.documentReducer);

  const { fetchDocument } = useActiveDocument();

  const onChange = useCallback(
    async (documentId: string) => {
      if (!activeDocumentSet) {
        return;
      }

      await fetchDocument(documentId, activeDocumentSet.status);
    },
    [activeDocumentSet, fetchDocument]
  );

  if (!activeDocumentSet?.documents?.length || !activeDocument) {
    return null;
  }

  if (activeDocumentSet.documents.length === 1) {
    return null;
  }

  return (
    <div>
      <Select
        className="detail-document-picker__select"
        value={activeDocument.id}
        onChange={onChange}
        size="large"
      >
        {activeDocumentSet.documents.map((document) => (
          <Select.Option key={document.id} value={document.id}>
            {document.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default DetailDocumentPicker;

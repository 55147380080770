import React from "react";
import Page from "./Page";
import { useAppSelector } from "../../../app";

const Pages = () => {
  const pdf = useAppSelector((state) => state.documentReducer.pdf);

  if (!pdf) {
    return null;
  }

  const { pages } = pdf;

  return (
    <>
      {pages.map(({ id }, index) => {
        return <Page key={id} page={index + 1} />;
      })}
    </>
  );
};

export default Pages;

import { useCallback, useMemo } from "react";
import useAnnotatorData from "./useAnnotatorData";
import useAnnotationUpdater from "./useAnnotationUpdater";

const useTempAnnotationOverview = () => {
  const { removeAnnotation, removeAnnotationsInBulk, updateAnnotationsInBulk } =
    useAnnotationUpdater();
  const { annotations } = useAnnotatorData();

  const tempAnnotations = useMemo(() => {
    return [...annotations].filter((a) => a.tempAnnotation);
  }, [annotations]);

  const scrollAnnotationIntoView = useCallback((id: string) => {
    const elem = document.getElementById(`annotation-${id}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const deleteAnnotations = useCallback(() => {
    removeAnnotationsInBulk(tempAnnotations, true);
  }, [tempAnnotations, removeAnnotationsInBulk]);

  const acceptAnnotations = useCallback(() => {
    const toUpdateAnnotations = [...tempAnnotations].map((a) => ({
      ...a,
      tempAnnotation: false,
    }));
    updateAnnotationsInBulk(toUpdateAnnotations, true);
  }, [tempAnnotations, updateAnnotationsInBulk]);

  return {
    tempAnnotations,
    scrollAnnotationIntoView,
    deleteAnnotations,
    acceptAnnotations,
    removeAnnotation,
  };
};

export default useTempAnnotationOverview;

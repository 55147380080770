import { useAppSelector } from "../../app";
import {
  CategorizationWithConfig,
  BlockingCategorization,
} from "../../configMap/interfaces/category";
import selectCategorizations from "../selectors/selectCategorizations";
import { useMultipleGroupBlocks } from "../../configMap";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  changeActiveButton,
  changeActiveEntity,
  changeAnnotating,
  changeEditing,
} from "../../app/store/appSlice";
import { notification } from "antd";
import { setIncompleteCategorizations } from "../store/metadataSlice";
import { useTranslation } from "react-i18next";

const useValidateBlockingCategorizations = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("errors");

  const categorizationsWithConfig = useAppSelector((state) =>
    selectCategorizations(state, { withConfig: true })
  ) as CategorizationWithConfig[];

  const groupBlocks = useAppSelector(
    (state) => state.configMapReducer.configMap?.groupBlocks
  );
  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState?.documentCategoryAnnotations
  );
  const enforceRequiredCategorizations = useAppSelector(
    (state) =>
      state.documentReducer.activeDocument?.documentType
        ?.enforceRequiredCategorizations
  );

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const getBlockingCategorizations = useCallback(() => {
    if (!categorizationsWithConfig || !enforceRequiredCategorizations) {
      return null;
    }

    return categorizationsWithConfig
      .filter(({ required }) => required)
      .flatMap((categorizationWithConfig) => {
        const groupBlock = groupBlocks?.find((gb) =>
          gb.categorizationGroupBlocks.some(
            (cgb) =>
              cgb.categorization.id ===
              categorizationWithConfig.categorization.id
          )
        );

        const groupBlockItemCount = groupBlock?.id
          ? countPerGroupBlockId(groupBlock.id)
          : 1;

        return Array.from({ length: groupBlockItemCount }, (_, index) => ({
          categorizationId: categorizationWithConfig.categorization.id,
          groupBlockId: groupBlock?.id,
          index: index + 1,
        }));
      });
  }, [
    categorizationsWithConfig,
    groupBlocks,
    countPerGroupBlockId,
    enforceRequiredCategorizations,
  ]);

  const setIncompleteCategorizationsAndScrollTo = useCallback(
    (incompleteCategorizations: Array<BlockingCategorization>) => {
      dispatch(setIncompleteCategorizations(incompleteCategorizations));

      if (!incompleteCategorizations.length) {
        return;
      }

      const { categorizationId, groupBlockId, index } =
        incompleteCategorizations[0];
      const groupBlockSuffix = groupBlockId ? `-${groupBlockId}` : "";
      const activeField = `categorization-${categorizationId}${groupBlockSuffix}-${index}`;

      dispatch(changeAnnotating(false));
      dispatch(changeActiveEntity(undefined));
      dispatch(changeActiveButton({ id: activeField }));
      dispatch(changeEditing(activeField));

      notification.error({
        message: t("enrichers.requiredError"),
      });
    },
    [dispatch, t]
  );

  return useCallback(() => {
    const blockingCategorizations = getBlockingCategorizations();
    if (!blockingCategorizations) {
      return true;
    }

    const incompleteCategorizations: Array<BlockingCategorization> =
      blockingCategorizations.filter(
        (blocker) =>
          !documentCategoryAnnotations.some(
            (annotation) =>
              annotation.categorizationId === blocker.categorizationId &&
              annotation.groupBlockId === blocker.groupBlockId &&
              annotation.index === blocker.index
          )
      );

    setIncompleteCategorizationsAndScrollTo(incompleteCategorizations);

    return !incompleteCategorizations.length;
  }, [
    getBlockingCategorizations,
    documentCategoryAnnotations,
    setIncompleteCategorizationsAndScrollTo,
  ]);
};

export default useValidateBlockingCategorizations;

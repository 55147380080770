import { Status } from "../common/status/status";

export const DOCUMENT_MAX_TOKENS = 1000;

export const STATUSES_TO_NORMALIZE_ANNOTATIONS = [
  Status.ToDo,
  Status.AnnotationToDo,
  Status.InProgress,
  Status.AnnotationInProgress,
  Status.OnHold,
  Status.AnnotationOnHold,
  Status.Error,
  Status.ExportFailed,
];

export const CSV_FILE_TYPES = ["text/csv", "application/csv"];
export const XML_FILE_TYPES = ["text/plain", "text/xml", "application/xml"];

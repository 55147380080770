import { useCallback, useMemo } from "react";
import { ITable } from "../interfaces/ITable";
import { AnnotationParams } from "../../annotator/interfaces/annotation";
import {
  addColumnAndGetTables,
  addRowAndGetTables,
  buildCellsForTable,
  deleteColumnAndGetTables,
  deleteExistingAnnotations,
  deleteRowAndGetTables,
  excludeRowAndGetTables,
  getAnnotationsForRow,
  getAnnotationsInCellsForSelection,
  linkTablesAndGetTables,
} from "../helpers/tableHelper";
import { generateRandomHash } from "../helpers/hashHelper";
import useAnnotatorConfig from "./useAnnotatorConfig";
import useAnnotatorData from "./useAnnotatorData";
import useAnnotationUpdater from "./useAnnotationUpdater";

const useTableUpdater = () => {
  const {
    updateAnnotationsInBulk,
    addAnnotationsInBulk,
    removeAnnotationsInBulk,
  } = useAnnotationUpdater();

  const { annotations, tables, textLayer, updateTables } = useAnnotatorData();

  const {
    previousTableState,
    setPreviousTableState,
    setTableLastAction,
    lastAction,
    readonly,
  } = useAnnotatorConfig();

  const tableControlAnnotations = useMemo(() => {
    return annotations.filter((a) => a.multipleGroupBlocks);
  }, [annotations]);

  const setTables = useCallback(
    (tables: Array<ITable>) => {
      if (readonly) {
        return;
      }

      updateTables(tables);
    },
    [updateTables, readonly]
  );

  const addTable = useCallback(
    (table: ITable, pageNumber: number) => {
      const { width, height } = table;
      if (width < 5 || height < 5) {
        return;
      }

      const textLayerForPage = textLayer.find((t) => t.page === table.page);

      table.rows.push({
        id: generateRandomHash(),
        height: table.height,
        y: 0,
        exclude: false,
      });
      table.columns.push({
        id: generateRandomHash(),
        width: table.width,
        x: 0,
      });
      table.cells = buildCellsForTable(
        table,
        textLayerForPage?.textMapItems || []
      );

      if (textLayer) {
        deleteExistingAnnotations(
          table,
          annotations,
          removeAnnotationsInBulk,
          pageNumber
        );
      }

      setTableLastAction("undo");
      setPreviousTableState(tables);
      setTables([...tables, table]);
    },
    [
      annotations,
      removeAnnotationsInBulk,
      textLayer,
      setTableLastAction,
      setPreviousTableState,
      tables,
      setTables,
    ]
  );

  const removeTable = useCallback(
    (table: ITable) => {
      const toRemoveAnnotations = [...tableControlAnnotations].filter(
        (a) => a.tableId === table.id
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);

      setTableLastAction("undo");
      setPreviousTableState(tables);

      const toSetTables = [...tables].filter(
        (prevTable) => prevTable.id !== table.id
      );
      setTables(toSetTables);
    },
    [
      tableControlAnnotations,
      setTableLastAction,
      setPreviousTableState,
      setTables,
      tables,
      removeAnnotationsInBulk,
    ]
  );

  const mapAnnotationToTable = useCallback(
    (markToAdd: AnnotationParams) => {
      if (!tables.length) {
        return;
      }

      const annotationsToAdd = getAnnotationsInCellsForSelection(
        tables,
        markToAdd
      );
      addAnnotationsInBulk(annotationsToAdd, true);
    },
    [tables, addAnnotationsInBulk]
  );

  const confirmAnnotations = useCallback(
    (table: ITable) => {
      const toUpdateAnnotations = [...tableControlAnnotations]
        .filter((a) => a.tableId === table.id)
        .map((a) => ({
          ...a,
          tempAnnotation: false,
        }));

      updateAnnotationsInBulk(toUpdateAnnotations, true);
    },
    [tableControlAnnotations, updateAnnotationsInBulk]
  );

  const deleteAnnotations = useCallback(
    (table: ITable) => {
      const toRemoveAnnotations = [...tableControlAnnotations].filter(
        (a) => a.tableId === table.id
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);
    },
    [tableControlAnnotations, removeAnnotationsInBulk]
  );

  const deleteRowAnnotations = useCallback(
    (table: ITable, rowIndex: number) => {
      const toRemoveAnnotations = getAnnotationsForRow(
        table,
        [...tableControlAnnotations],
        rowIndex
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);
    },
    [tableControlAnnotations, removeAnnotationsInBulk]
  );

  const addRow = useCallback(
    (tableId: string, y: number, id?: string) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(addRowAndGetTables(tables, textLayer, tableId, y, id));
    },
    [textLayer, setPreviousTableState, setTableLastAction, setTables, tables]
  );

  const addColumn = useCallback(
    (tableId: string, x: number, id?: string) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(addColumnAndGetTables(tables, textLayer, tableId, x, id));
    },
    [textLayer, setTables, setPreviousTableState, setTableLastAction, tables]
  );

  const deleteRow = useCallback(
    (tableId: string, rowId: string) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(deleteRowAndGetTables(tables, textLayer, tableId, rowId));
    },
    [textLayer, tables, setTables, setTableLastAction, setPreviousTableState]
  );

  const deleteColumn = useCallback(
    (tableId: string, colId: string) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(deleteColumnAndGetTables(tables, textLayer, tableId, colId));
    },
    [textLayer, tables, setTables, setPreviousTableState, setTableLastAction]
  );

  const excludeRow = useCallback(
    (tableId: string, rowId: string, exclude: boolean) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(
        excludeRowAndGetTables(tables, textLayer, tableId, rowId, exclude)
      );
    },
    [textLayer, tables, setTables, setPreviousTableState, setTableLastAction]
  );

  const linkTables = useCallback(
    (tableId: string) => {
      const targetTableIndex = tables.findIndex((t) => t.id === tableId) - 1;

      if (targetTableIndex < 0) {
        return;
      }

      const targetTable = tables[targetTableIndex];
      const { columns, x, width } = targetTable;

      setPreviousTableState(tables);

      setTables(
        linkTablesAndGetTables(
          tables,
          textLayer,
          tableId,
          columns,
          x,
          width,
          targetTable
        )
      );
    },
    [tables, textLayer, setPreviousTableState, setTables]
  );

  const undoOrRedoLastAction = useCallback(() => {
    setTableLastAction("redo");
    setPreviousTableState(tables);
    setTables(previousTableState);
  }, [
    tables,
    setTableLastAction,
    setPreviousTableState,
    setTables,
    previousTableState,
  ]);

  return {
    tables,
    addTable,
    removeTable,
    addColumn,
    addRow,
    mapAnnotationToTable,
    confirmAnnotations,
    deleteAnnotations,
    deleteRowAnnotations,
    deleteRow,
    deleteColumn,
    excludeRow,
    linkTables,
    undoOrRedoLastAction,
    lastAction,
  };
};

export default useTableUpdater;

import React, { FunctionComponent, memo } from "react";
import classNames from "classnames";
import "./AnnotationSideControlsHeader.scss";
import DocumentInformation from "./documentInformation/DocumentInformation";
import Actions from "./actions/Actions";

type AnnotationSideControlsHeaderProps = {
  disabled: boolean;
};

const AnnotationSideControlsHeader: FunctionComponent<
  AnnotationSideControlsHeaderProps
> = ({ disabled }) => {
  const headerClassNames = classNames({
    "transition-view-header": true,
    reverse: true,
  });

  return (
    <div className={headerClassNames}>
      <DocumentInformation disabled={disabled} />
      <Actions disabled={disabled} />
    </div>
  );
};

export default memo(AnnotationSideControlsHeader);

import { OptionType } from "../../../metadata/interfaces/classifier";
import { ADMIN_ROLE_ID, STP_ROLE_ID } from "../../utilities/env";

export const getActivePageFromSessionStorage = () => {
  const activePage = sessionStorage.getItem("activePage");

  if (!activePage) {
    sessionStorage.setItem("activePage", "0");
    return 0;
  }

  const parsed = parseInt(activePage);

  if (parsed < 0) {
    sessionStorage.setItem("activePage", "0");
    return 0;
  }

  return parsed;
};

export const getValueFromKeyHelper = (
  filterArray: Array<string>,
  key: string,
  separator: string,
  itemList?: Array<OptionType>
): string | undefined => {
  const found = filterArray.find((item) => item.split(separator)[0] === key);

  if (!found) {
    return undefined;
  }

  const cleaned = found.replace(key, "").replaceAll(separator, "");

  const listItem = itemList?.find((item) => item.value === cleaned);

  if (listItem) {
    return listItem.label;
  }

  return cleaned;
};

export const validateAccess = (access?: Array<string>) => {
  if (!access) {
    return false;
  }

  if (access.includes(ADMIN_ROLE_ID)) {
    return true;
  }

  return access.includes(STP_ROLE_ID);
};

import React, { FC, useCallback, useEffect, useState } from "react";
import { Select } from "antd";
import "./EmailTemplateSelector.scss";
import { EmailTemplate } from "../../../../../emailTemplates";

interface Option {
  value: string | undefined;
  label: string;
}

type Props = {
  emailTemplates: EmailTemplate[];
  favoriteTemplate: EmailTemplate | undefined;
  setMessage: (message: string) => void;
};

const EmailTemplateSelector: FC<Props> = ({
  setMessage,
  emailTemplates,
  favoriteTemplate,
}) => {
  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState<
    string | undefined
  >(favoriteTemplate?.id);

  useEffect(() => {
    if (!selectedEmailTemplateId) {
      setMessage("");
      return;
    }

    const emailTemplate = emailTemplates.find(
      (e) => e.id === selectedEmailTemplateId
    );

    if (!emailTemplate) {
      setMessage("");
      return;
    }

    setMessage(emailTemplate.body);
  }, [selectedEmailTemplateId, emailTemplates, setMessage]);

  useEffect(() => {
    setSelectedEmailTemplateId(favoriteTemplate?.id);
  }, [favoriteTemplate]);

  const onChange = useCallback(
    (value: string) => {
      setSelectedEmailTemplateId(value);
    },
    [setSelectedEmailTemplateId]
  );

  const onSearch = useCallback((input: string, option: Option | undefined) => {
    return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  }, []);

  return (
    <div className="selector-container">
      <h4 className="selector-label">{"Email template:"}</h4>
      <Select
        showSearch
        allowClear
        className="selector"
        value={selectedEmailTemplateId}
        onChange={onChange}
        filterOption={onSearch}
        options={emailTemplates.map((emailTemplate): Option => {
          return { value: emailTemplate.id, label: emailTemplate.name };
        })}
      />
    </div>
  );
};

export default EmailTemplateSelector;

import { useCallback, useMemo, useState } from "react";
import { getValueFromKeyHelper } from "../../common/menu/utils/utils";
import { OverviewType, useAppDispatch, useAppSelector } from "../../app";
import { ColumnItem } from "../interfaces/overview";
import { setFilterString } from "../../common/dataRefining/store/dataRefiningSlice";
import useUserSettings from "../../user/hooks/useUserSettings";
import { OptionType } from "../../metadata/interfaces/classifier";
import { getKeyFromMetaData } from "../utils/columnItemMetaDataHelpers";
import useCleanedFilter from "./useCleanedFilter";
import { EXTRA_SELECT_FILTER_PREFIX } from "../constants";
import { resetSelectedRows } from "../store/documentSetSlice";

const useTableHeaderFilter = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  loading: boolean = false
) => {
  const dispatch = useAppDispatch();
  const { updateUserFiltersAuth0 } = useUserSettings();

  const { overviewFilterArray, archiveFilterArray } = useAppSelector(
    (state) => state.dataRefiningReducer
  );

  const { filterArray } = useCleanedFilter();
  const { user, userSettings } = useAppSelector((state) => state.userReducer);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (loading) {
        return;
      }

      setIsOpen(open);
      if (!open) {
        dispatch(setFilterString(filterArray.join(",")));
        dispatch(resetSelectedRows());

        await updateUserFiltersAuth0(
          user,
          userSettings,
          overviewFilterArray,
          archiveFilterArray
        );
      }
    },
    [
      dispatch,
      overviewFilterArray,
      archiveFilterArray,
      user,
      userSettings,
      updateUserFiltersAuth0,
      filterArray,
      loading,
    ]
  );

  const getValueFromKey = useCallback(
    (
      key: string,
      separator: string,
      itemList?: Array<OptionType>
    ): string | undefined => {
      return getValueFromKeyHelper(filterArray, key, separator, itemList);
    },
    [filterArray]
  );

  const hasFilter = useMemo(() => {
    if (!columnItem?.metadata?.filterAble?.filterKey) {
      return false;
    }

    const mainFilter = getValueFromKey(
      getKeyFromMetaData(columnItem.metadata, overviewType),
      columnItem.metadata?.filterAble.delimiter
    );

    const extraSelectFilterMetadata = {
      filterAble: {
        filterKey: `${columnItem.metadata?.filterAble?.filterKey}${EXTRA_SELECT_FILTER_PREFIX}`,
      },
    };
    const extraSelectFilter = getValueFromKey(
      getKeyFromMetaData(extraSelectFilterMetadata, overviewType),
      columnItem.metadata?.filterAble.delimiter
    );

    return mainFilter !== undefined || extraSelectFilter !== undefined;
  }, [columnItem.metadata, getValueFromKey, overviewType]);

  return {
    getValueFromKey,
    hasFilter,
    handleOpenChange,
    isOpen,
  };
};

export default useTableHeaderFilter;

import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "./annotationReducerSelector";

const numberOfUserAnnotationsSelector = createSelector(
  selectAnnotationReducer,
  (annotations): number => {
    return annotations.length;
  }
);

export default numberOfUserAnnotationsSelector;

import React, { FC } from "react";
import useTableOverview from "../hooks/useTableOverview";

const TableOverview: FC = () => {
  const {
    tableHeaders,
    groupedData,
    deleteRow,
    confirmTableAnnotations,
    deleteTableAnnotations,
  } = useTableOverview();

  if (!tableHeaders.length) {
    return null;
  }

  return (
    <div className="table-data-overview__container">
      <table>
        <thead>
          <tr>
            <th />
            <th>Line Item</th>
            {tableHeaders.map((item: any) => (
              <th key={item.id}>{item.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(groupedData).map(([key, value]) => (
            <tr key={key}>
              <td>
                <span
                  className="bi bi-trash remove-line-icon"
                  onClick={() => deleteRow(parseInt(key))}
                />
              </td>
              <td>{key}</td>
              {value?.map((line) => (
                <td key={line.id}>{line.values?.join(" ")}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-buttons">
        <button onClick={confirmTableAnnotations}>Accept</button>
        <button onClick={deleteTableAnnotations}>Delete</button>
      </div>
    </div>
  );
};

export default TableOverview;

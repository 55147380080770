import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { addPageToPDFData } from "../store/documentSlice";
import { convertArrayBufferToImageUrl } from "../../common/utilities/image";
import {
  useLazyFetchPdfPageImageQuery,
  useLazyFetchPdfPageMetaDataQuery,
} from "../store/documentApi";
import { calculateScalingFactor } from "../../annotation/utils/scaling";
// @ts-ignore
import { inflate } from "pako";
import { IPdfPageMetadataResponse } from "../interfaces/request";
import pdfPageImageSelector from "../selectors/pdfPageImageSelector";

const usePDFPage = (page: number, fetchData: boolean) => {
  const dispatch = useAppDispatch();

  const documentId = useAppSelector(
    (state) => state.documentReducer.activeDocument?.id
  );

  const image = useAppSelector((state) => pdfPageImageSelector(state, page));

  const [fetchPdfPageImage, { isFetching: imageLoading, isError: imageError }] =
    useLazyFetchPdfPageImageQuery();
  const [
    fetchPdfPageMetaData,
    { isFetching: metadataLoading, isError: metadataError },
  ] = useLazyFetchPdfPageMetaDataQuery();

  const loading = useMemo(
    () => imageLoading || metadataLoading,
    [imageLoading, metadataLoading]
  );
  const error = useMemo(
    () => imageError || metadataError,
    [imageError, metadataError]
  );

  const loadPdfPageAndData = useCallback(
    async (fetchData: boolean) => {
      if (!documentId) {
        return;
      }

      if (!fetchData) {
        return;
      }

      try {
        const [pdfMetaDataBuffer, imageBuffer] = await Promise.all([
          fetchPdfPageMetaData({ documentId, page }).unwrap(),
          fetchPdfPageImage({ documentId, page }).unwrap(),
        ]);

        const pdfMetaData: IPdfPageMetadataResponse = JSON.parse(
          inflate(pdfMetaDataBuffer, { to: "string" })
        );

        const { width, height } = pdfMetaData.dimension;

        const [widthScalingFactor, heightScalingFactor] =
          calculateScalingFactor(width, height);

        const image = convertArrayBufferToImageUrl(imageBuffer);

        dispatch(
          addPageToPDFData({
            id: "",
            image,
            page,
            dimension: {
              unit: pdfMetaData.dimension.unit,
              width: width / widthScalingFactor,
              height: height / heightScalingFactor,
            },
            tokens: pdfMetaData.tokens.map((t, index) => ({
              ...t,
              dataI: index,
              left: t.left / widthScalingFactor,
              width: t.width / widthScalingFactor,
              top: t.top / heightScalingFactor,
              height: t.height / heightScalingFactor,
            })),
          })
        );
      } catch (e) {
        console.log(e);
      }
    },
    [documentId, fetchPdfPageMetaData, page, fetchPdfPageImage, dispatch]
  );

  useEffect(() => {
    loadPdfPageAndData(fetchData);
  }, [loadPdfPageAndData, fetchData]);

  return { image, loading, error };
};

export default usePDFPage;

export enum Locales {
  EN = "en-gb",
  NL = "nl",
  FR = "fr",
  BE = "be",
  BG = "bg",
  BN = "bn",
  BO = "bo",
  BR = "br",
  BS = "bs",
  CA = "ca",
  CS = "cs",
  CV = "cv",
  CY = "cy",
  DE = "de",
  ES = "es",
  IT = "it",
  PL = "pl",
  PT = "pt",
  RO = "ro",
  RU = "ru",
  ZH = "zh",
}

import { useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import useTextLayer from "./useTextLayer";
import { TextLayerItem } from "../interfaces/textLayer";
import { PageViewPort } from "../interfaces/pageViewPort";
import useAnnotatorConfig from "./useAnnotatorConfig";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app";
import activeEntityForAnnotatorSelector from "../../annotator/selectors/activeEntityForAnnotatorSelector";

const usePage = (
  pageNumber: number,
  page: any,
  scale: number,
  createTable: boolean,
  initialTextLayer?: Array<TextLayerItem>
) => {
  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const { setCurrentPage } = useAnnotatorConfig();

  const { ref: containerRef, inView } = useInView({ threshold: 0 });
  const [pageNumberRef, pageNumberInView] = useInView({ threshold: 0 });

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [pageViewport, setPageViewport] = useState<PageViewPort>({
    width: (916 / 1.5) * scale,
    height: (1174 / 1.5) * scale,
  });

  const context = canvasRef.current?.getContext("2d");

  const textLayer = useTextLayer(scale, context!, initialTextLayer);

  useEffect(() => {
    if (pageNumberInView) {
      setCurrentPage(pageNumber);
    }
  }, [pageNumberInView, pageNumber, setCurrentPage]);

  useEffect(() => {
    if (canvasRef && context && page && inView) {
      page.then((pdfPageResult: any) => {
        const viewport = pdfPageResult.getViewport({ scale });
        const { width, height } = viewport;
        setPageViewport(viewport);
        const canvas = canvasRef.current;
        if (canvas) {
          canvas!.width = width;
          canvas!.height = height;
        }

        pdfPageResult.render({
          canvasContext: context!,
          viewport,
        });
      });
    }
  }, [page, scale, canvasRef, context, inView]);

  const tableZIndex = useMemo(() => {
    if (createTable && entity) {
      return 0;
    }
    if (createTable) {
      return 10;
    }

    return 1;
  }, [createTable, entity]);

  return {
    containerRef,
    canvasRef,
    pageViewport,
    pageNumberRef,
    tableZIndex,
    context,
    inView,
    textLayer,
  };
};

export default usePage;

import { MetricsDatePeriod } from "../constants";
import dayjs from "dayjs";

const getAmountOfMonths = (period: MetricsDatePeriod) => {
  switch (period) {
    case MetricsDatePeriod.ONE_MONTH:
      return 1;
    case MetricsDatePeriod.THREE_MONTHS:
      return 3;
    case MetricsDatePeriod.SIX_MONTHS:
      return 6;
    case MetricsDatePeriod.ONE_YEAR:
      return 12;
    default:
      return 3;
  }
};

export const getRangeDateByPeriod = (period: MetricsDatePeriod) => {
  const amountOfMonths = getAmountOfMonths(period);

  return {
    startDate: dayjs().subtract(amountOfMonths, "month").toDate(),
    endDate: dayjs().toDate(),
  };
};

import { findIntersectingChildren, isCoordsEmpty } from "./selectionHelpers";
import { Entity } from "../../annotator/interfaces/entity";
import { PDFMetaData } from "../../annotator/interfaces/pdf";
import { AnnotationParams } from "../../annotator/interfaces/annotation";
import { Rectangle } from "../../annotator/interfaces/textLayer";

const getImageAsBase64 = (
  targetCoords: Rectangle,
  context: CanvasRenderingContext2D
): string => {
  const { left, top, width, height } = targetCoords;
  const imageContentRaw = context.getImageData(left, top, width, height);
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  canvas.getContext("2d")?.putImageData(imageContentRaw, 0, 0);
  return canvas.toDataURL("image/jpeg", 1.0);
};

export const buildNerAnnotation = (
  pageNumber: number,
  entity: Entity,
  selectionChildren: any,
  targetCoords: Rectangle
): AnnotationParams => {
  const intersects = findIntersectingChildren(selectionChildren, targetCoords);
  const markToAdd: AnnotationParams = {
    page: pageNumber,
    pageTokenIndices: [],
    values: [],
    entity: entity!,
    index: entity!.index,
    tempAnnotation: false,
    isByUser: true,
    isOutput: false,
    isLoading: false,
    multipleGroupBlocks: false,
  };

  intersects.forEach((intersect) => {
    const innerIntersects = findIntersectingChildren(
      intersect.children,
      targetCoords
    );
    innerIntersects.forEach((child) => {
      const dataI = child.getAttribute("data-i");
      if (dataI) {
        markToAdd.values.push(child.textContent);
        markToAdd.pageTokenIndices?.push(parseInt(dataI, 10));
      }
    });
  });

  return markToAdd;
};

export const buildAreaAnnotation = (
  pageNumber: number,
  entity: Entity,
  targetCoords: Rectangle,
  pdfInformation: PDFMetaData,
  context: CanvasRenderingContext2D
): AnnotationParams | null => {
  if (isCoordsEmpty(targetCoords)) {
    return null;
  }

  return {
    page: pageNumber,
    areaAnnotation: {
      boundingBox: targetCoords,
      pdfInformation,
      base64Image: getImageAsBase64(targetCoords, context),
    },
    entity: entity!,
    tempAnnotation: false,
    isByUser: true,
    pageTokenIndices: [],
    values: [],
    isOutput: false,
    isLoading: false,
    multipleGroupBlocks: false,
  };
};

import React, { forwardRef } from "react";
import { Alert, Collapse } from "antd";
import { useTranslation } from "react-i18next";
import { AlertError, MoreDetailsInfo } from "../../../interfaces/errorType";
import { LogWebhookRequest } from "../../../../documentSet/interfaces/documentSet";
import "./ExportFailedWithMessageAlert.scss";
import AlertIconRenderer from "./AlertIconRenderer";

type Props = {
  errorMessageObject: AlertError;
  latestLogWebhookRequest: LogWebhookRequest;
  moreDetailsInfo: MoreDetailsInfo;
};

const ExportFailedWithMessageAlert = forwardRef<HTMLDivElement, Props>(
  ({ errorMessageObject, latestLogWebhookRequest, moreDetailsInfo }, ref) => {
    const { t } = useTranslation("annotationSideControls", {
      keyPrefix: "alert.exportFailed",
    });

    return (
      <div ref={ref}>
        <Alert
          className="error__alert-warning"
          type="error"
          message={
            <div className="error-title-container">
              <AlertIconRenderer
                type={latestLogWebhookRequest.responseBody?.type}
              />
              {errorMessageObject.title}
            </div>
          }
          description={
            <div>
              <div className="error-description">
                {errorMessageObject.message}
              </div>
              <Collapse
                size="small"
                collapsible="header"
                bordered={false}
                items={[
                  {
                    key: "1",
                    label: t("moreDetails.header"),
                    children: (
                      <div className="error-more-details-container">
                        <p className="error-more-details-line">
                          <b>{t("moreDetails.httpStatusCode")}</b>
                          {moreDetailsInfo.statusCode}
                        </p>
                        <p className="error-more-details-line">
                          <b>{t("moreDetails.message")}</b>
                          {moreDetailsInfo.message}
                        </p>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          }
        />
      </div>
    );
  }
);

ExportFailedWithMessageAlert.displayName = "ExportFailedWithMessageAlert";

export default ExportFailedWithMessageAlert;

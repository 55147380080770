import React, { FC } from "react";
import { DocumentSetMetrics } from "../../interfaces/documentSetMetrics";
import LineGraph from "../graphs/lineGraph/LineGraph";
import StackedColumnLineGraph from "../graphs/stackedColumnLineGraph/StackedColumnLineGraph";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";

type Props = {
  metrics: DocumentSetMetrics | undefined;
  loading: boolean;
  showNoMetricsAlert: boolean;
};

const AdvancedMetrics: FC<Props> = ({
  metrics,
  loading,
  showNoMetricsAlert,
}) => {
  const { t } = useTranslation("analytics");

  if (showNoMetricsAlert && !loading) {
    return (
      <Alert
        message={t("noMetricsFound")}
        type="info"
        showIcon
        className="graph-alert"
      />
    );
  }

  return (
    <div className="advanced-metrics__container">
      <div className="metric-graphs-row">
        <div className="line-graph">
          <LineGraph
            title={t("metricGraphs.entityFirstTimeRight")}
            tooltipTitle={t("tooltips.entityFirstTimeRight")}
            metrics={metrics?.entityMetrics}
            loading={loading}
            tooltipSuffix="%"
            xField="yearMonth"
          />
        </div>
      </div>
      <StackedColumnLineGraph
        title={t("metricGraphs.averageCorrectionPerDocument")}
        tooltipTitle={t("tooltips.averageCorrectionPerDocument")}
        metrics={metrics?.corrections}
        loading={loading}
      />
    </div>
  );
};

export default AdvancedMetrics;

import { createSelector } from "@reduxjs/toolkit";
import selectDocumentCategoryAnnotations from "./selectDocumentCategoryAnnotations";
import { DocumentCategoryAnnotation } from "../../configMap/interfaces/category";

type Props = {
  categorizationId?: string;
  documentId?: string;
  groupBlockId?: string;
  index?: number;
};

const selectDocumentCategoryAnnotationId = createSelector(
  selectDocumentCategoryAnnotations,
  (_: any, props: Props) => props,
  (documentCategoryAnnotations, props): DocumentCategoryAnnotation | null => {
    const { categorizationId, documentId, index, groupBlockId } = props;
    if (!categorizationId || !documentId) {
      return null;
    }

    const found = documentCategoryAnnotations.find((dca) => {
      if (dca.groupBlockId !== undefined) {
        return (
          dca.documentId === documentId &&
          dca.categorizationId === categorizationId &&
          dca.groupBlockId === groupBlockId &&
          dca.index === index
        );
      }

      return (
        dca.documentId === documentId &&
        dca.categorizationId === categorizationId &&
        dca.index === index
      );
    });
    if (found) {
      return found;
    }

    return null;
  }
);

export default selectDocumentCategoryAnnotationId;

import React, { FunctionComponent, useMemo } from "react";
import { Translation } from "../../../";
import { useAppSelector } from "../../../../app";

type Props = {
  id: string;
  translations: Translation;
  show: boolean;
  onClick: () => void;
};

const GroupBlockAddButton: FunctionComponent<Props> = ({
  id,
  onClick,
  show,
}) => {
  const activeButton = useAppSelector((state) => state.appReducer.activeButton);

  const className = useMemo(() => {
    if (activeButton?.id === id) {
      return "group-block__add-button btn-focused";
    }

    return "group-block__add-button";
  }, [activeButton?.id, id]);

  if (show) {
    return (
      <div className="group-block__add-button-container separator">
        <div className="line" />
        <div className={className} onClick={onClick} id={id}>
          <i className="bi bi-plus-circle group-block__add-button-icon" />
        </div>
        <div className="line" />
      </div>
    );
  }
  return null;
};

export default GroupBlockAddButton;

import { useCallback, useMemo } from "react";
import { DocumentType } from "../";
import { useAppSelector } from "../../app";
import { useLazyGetDocumentTypesQuery } from "../store/documentTypeApi";

const useDocumentTypes = () => {
  const { inputTypes } = useAppSelector((state) => state.documentTypesReducer);

  const activeDocumentSetInputId = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet?.input?.id
  );

  const [getDocumentTypes] = useLazyGetDocumentTypesQuery();

  const documentTypes = useMemo((): Array<DocumentType> => {
    if (!inputTypes?.length || !activeDocumentSetInputId) {
      return [];
    }

    const found = inputTypes.find(
      (type) => type.id === activeDocumentSetInputId
    );

    if (!found) {
      return [];
    }

    return found.documentTypes;
  }, [inputTypes, activeDocumentSetInputId]);

  const fetchDocumentTypes = useCallback(
    async (inputIds: Array<string>) => {
      await getDocumentTypes(inputIds.join(","));
    },
    [getDocumentTypes]
  );

  return { fetchDocumentTypes, documentTypes };
};

export default useDocumentTypes;

import React, { FC, Key } from "react";
import { Button } from "antd";
import StpEntityTableFooterAdd from "./StpEntityTableFooterAdd";
import { useTranslation } from "react-i18next";
import { Option } from "../../../../../common/dataRefining/interfaces/filteringData";

type Props = {
  selectedRows: Array<Key>;
  setSelectedRows: (ids: Array<string>) => void;
  sourcesOptions: Array<Option>;
  validationOptions: Array<Option>;
  removeEntities: (ids: Array<string>) => void;
  documentTypeId: string | null;
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
};

const StpEntityTableFooter: FC<Props> = ({
  selectedRows,
  setSelectedRows,
  sourcesOptions,
  validationOptions,
  removeEntities,
  documentTypeId,
  isAdding,
  setIsAdding,
}) => {
  const { t } = useTranslation("stp");

  if (!documentTypeId) {
    return null;
  }

  if (isAdding) {
    return (
      <StpEntityTableFooterAdd
        handleCancel={setIsAdding}
        sourcesOptions={sourcesOptions}
        validationOptions={validationOptions}
      />
    );
  }

  if (selectedRows.length) {
    return (
      <div className="selected-rows-actions">
        <div>
          <Button type="text" icon={<span className="bi bi-plus-square" />}>
            {t("entities.addEntity")}
          </Button>
        </div>
        <div>
          <Button
            danger
            type="text"
            icon={<span className="bi bi-trash" />}
            onClick={() =>
              removeEntities(selectedRows.map((s) => s.toString()))
            }
          >
            {t("entities.deleteSelectedRows")}
          </Button>
          <Button type="text" onClick={() => setSelectedRows([])}>
            {t("entities.cancel")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Button
      type="text"
      icon={<span className="bi bi-plus-square" />}
      onClick={() => setIsAdding(true)}
    >
      {t("entities.addEntity")}
    </Button>
  );
};

export default StpEntityTableFooter;

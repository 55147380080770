import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo, useState } from "react";
import { fetchedMemberList } from "../store/UserManagementSlice";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { Option } from "../../common/dataRefining/interfaces/filteringData";
import {
  useAddPermissionToUserMutation,
  useRemovePermissionFromUserMutation,
} from "../../user/store/userApi";

const useUserInputs = () => {
  const { t } = useTranslation("userManagement");

  const dispatch = useAppDispatch();

  const { members, editingMemberId, organizationSources } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const [userInputs, setUserInputs] = useState<Array<string>>([]);
  const [searchValueUserInputs, setSearchValueUserInputs] = useState<
    string | null
  >(null);

  const [removePermissionFromUser] = useRemovePermissionFromUserMutation();
  const [addPermissionToUser] = useAddPermissionToUserMutation();

  const loadMemberInputs = useCallback(() => {
    if (!editingMemberId) {
      return;
    }

    const foundMember = members.find((mem) => mem.id === editingMemberId);

    if (!foundMember) {
      return;
    }

    const organizationInputs = organizationSources.map((source) =>
      source.id.toLowerCase()
    );

    const inputPermissions = foundMember.permissions
      .filter((input) => organizationInputs.includes(input.toLowerCase()))
      .map((input) => input.toLowerCase());

    setUserInputs(inputPermissions);
  }, [members, editingMemberId, organizationSources]);

  const handlePermissionsChange = useCallback(
    async (values: string[]) => {
      if (!editingMemberId) {
        return;
      }

      const newPermission = values.filter(
        (value) => !userInputs.includes(value)
      );
      const permissionToRemove = userInputs.filter(
        (input) => !values.includes(input)
      );

      try {
        if (newPermission.length) {
          await addPermissionToUser({
            userId: editingMemberId,
            permission: newPermission[0],
          }).unwrap();
          setUserInputs(values);
          setSearchValueUserInputs(null);
        } else if (permissionToRemove.length) {
          await removePermissionFromUser({
            userId: editingMemberId,
            data: {
              permissions: permissionToRemove,
            },
          }).unwrap();
          setUserInputs(values);
          setSearchValueUserInputs(null);
        }

        const newMembers = members.map((member) => {
          if (member.id === editingMemberId) {
            return {
              ...member,
              permissions: values,
            };
          }

          return member;
        });

        dispatch(fetchedMemberList(newMembers));
      } catch (e) {
        console.error(e);
        notification.error({
          message: t("permissions.errorMessage.message"),
          description: t("permissions.errorMessage.description"),
        });
      }
    },
    [
      editingMemberId,
      userInputs,
      t,
      dispatch,
      members,
      removePermissionFromUser,
      addPermissionToUser,
    ]
  );

  const allOptions = useMemo(() => {
    return [...organizationSources].map((input): Option => {
      return { value: input.id.toString(), label: input.displayName };
    });
  }, [organizationSources]);

  const options = useMemo(() => {
    if (!searchValueUserInputs) {
      return allOptions;
    }

    return [...allOptions].filter((option) =>
      option.label.toLowerCase().includes(searchValueUserInputs.toLowerCase())
    );
  }, [allOptions, searchValueUserInputs]);

  const handleSearch = useCallback((searchValue: string) => {
    setSearchValueUserInputs(searchValue);
  }, []);

  return {
    userInputs,
    loadMemberInputs,
    handlePermissionsChange,
    options,
    handleSearch,
  };
};

export default useUserInputs;

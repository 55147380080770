import React, { FC, MutableRefObject, Profiler, useMemo, useRef } from "react";
import SelectionRectangle from "./SelectionRectangle";
import CursorText from "./CursorText";
import useSelection from "../../../hooks/useSelection";
import { useAppSelector } from "../../../../app";
import "./Selection.scss";
import { onRender } from "../../../../app/utils/profiling";
import { mapAnnotatorModeToClassName } from "../../../../annotation/utils/utils";

interface Props {
  pageNumber: number;
  width: number;
  height: number;
}

const Selection: FC<Props> = ({ pageNumber, width, height }) => {
  const selectionRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const isAnnotating = useAppSelector((state) => state.appReducer.isAnnotating);

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    annotatorMode,
    coords,
    mousePosition,
    isDragging,
  } = useSelection({
    pageNumber,
    ref: selectionRef,
  });

  const style = useMemo(() => {
    if (isAnnotating) {
      return {
        width: `${width}px`,
        height: `${height}px`,
        zIndex: 15,
      };
    }

    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }, [isAnnotating, width, height]);

  return (
    <Profiler id="SelectionLayer" onRender={onRender}>
      <div
        role="document"
        ref={selectionRef}
        className={`selection-container page__text-layer-container ${mapAnnotatorModeToClassName(
          annotatorMode
        )}`}
        style={style}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <CursorText mouseCoords={mousePosition} />
        <SelectionRectangle isDragging={isDragging} coordinates={coords} />
      </div>
    </Profiler>
  );
};

export default Selection;

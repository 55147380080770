import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "./annotationReducerSelector";
import { RootState } from "../../app";
import { Annotation } from "../../annotator/interfaces/annotation";

type Props = {
  documentId?: string;
  entityIds?: Array<string>;
};

export const EMPTY_ARRAY: Array<Annotation> = [];

const selectDocumentId = (_: RootState, props: Props) => props.documentId;
const selectEntityIds = (_: RootState, props: Props) => props.entityIds;

const annotationsForEntitiesSelector = createSelector(
  selectAnnotationReducer,
  selectDocumentId,
  selectEntityIds,
  (selectAnnotations, documentId, entityIds): Array<Annotation> => {
    if (!selectAnnotations?.length || !documentId || !entityIds) {
      return EMPTY_ARRAY;
    }

    return selectAnnotations
      .filter((annotation) => entityIds.includes(annotation?.entity?.id))
      .filter((annotation) => !annotation.tempAnnotation)
      .map((annotation) => ({
        ...annotation,
        index: annotation.index,
        scores: [annotation.modelScore!],
      }));
  }
);

export default annotationsForEntitiesSelector;

import { useCallback } from "react";
import { canOpen, shouldUpdateStatusOrUser } from "../utils/helperFunctions";
import { determineNonErrorStatus, Status } from "../../common/status/status";
import { OverviewType, useAppSelector } from "../../app";
import useActiveDocumentSet from "./useActiveDocumentSet";
import { DocumentSetData } from "../interfaces/documentSet";
import { SortDirection, useSorting } from "../../common/dataRefining";
import { ColumnKey } from "../index";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";
import useUpdateDocumentSet from "./useUpdateDocumentSet";

const useTable = (overviewType: OverviewType) => {
  const { navigateWithState } = useNavigateWithState();
  const user = useAppSelector((state) => state.userReducer.user);
  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );
  const {
    updateActiveDocumentSet,
    updateAssigneeAndStatusWithExceptionHandling,
  } = useActiveDocumentSet();

  const updateDocumentSets = useUpdateDocumentSet();

  const { activeSorter, handleSortDirectionToggle, handleSorterToggle } =
    useSorting(overviewType);

  const handleRowSelect = useCallback(
    async (documentSet: DocumentSetData) => {
      if (!canOpen(documentSet, user)) {
        return;
      }

      if (shouldUpdateStatusOrUser(documentSet, user!, isAnnotationMode)) {
        const newStatus = determineNonErrorStatus(
          documentSet.status,
          isAnnotationMode ? Status.AnnotationInProgress : Status.InProgress
        );
        await updateAssigneeAndStatusWithExceptionHandling(
          documentSet,
          newStatus,
          user!
        );

        const updatedDocumentSet = {
          ...documentSet,
          status: newStatus,
          assignee: user!,
        };
        updateDocumentSets(updatedDocumentSet);
        updateActiveDocumentSet(updatedDocumentSet);

        navigateWithState(`/detail/${updatedDocumentSet.id}`);
        return;
      }

      updateActiveDocumentSet(documentSet);
      navigateWithState(`/detail/${documentSet.id}`);
    },
    [
      navigateWithState,
      isAnnotationMode,
      updateDocumentSets,
      updateActiveDocumentSet,
      updateAssigneeAndStatusWithExceptionHandling,
      user,
    ]
  );

  const getClassNameForRow = useCallback(
    (documentSet: DocumentSetData): string => {
      if (!documentSet) {
        return "overview-table__row";
      }

      if (documentSet.assignee) {
        if (isAnnotationMode) {
          return "overview-table__row";
        }
      }

      if ([Status.Processing, Status.Remove].includes(documentSet.status)) {
        return "overview-table__row-disabled";
      }

      return "overview-table__row";
    },
    [isAnnotationMode]
  );

  const handleSortClicked = useCallback(
    (columnKey: ColumnKey) => {
      if (
        activeSorter?.key === columnKey &&
        activeSorter?.sortDirection === SortDirection.Ascending
      ) {
        handleSortDirectionToggle(columnKey);
        return;
      }
      handleSorterToggle(columnKey);
    },
    [activeSorter, handleSortDirectionToggle, handleSorterToggle]
  );

  return {
    handleRowSelect,
    getClassNameForRow,
    handleSortClicked,
  };
};

export default useTable;

import React, { FC, useCallback } from "react";
import "./HeaderWithTitle.scss";
import { useAppDispatch } from "../../../../app";
import { triggerDetailBackButton } from "../../../../app/store/appSlice";
import useNavigateWithState from "../../../navigate/hooks/useNavigateWithState";
import { To } from "react-router-dom";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  pathName: string;
};

const GoBackRoute: FC<Props> = ({ pathName }) => {
  const { navigateWithState } = useNavigateWithState();
  const { t } = useTranslation("header", { keyPrefix: "buttons" });

  const dispatch = useAppDispatch();

  const navigateBack = useCallback(() => {
    if (pathName.includes("detail/")) {
      dispatch(triggerDetailBackButton());
    } else {
      navigateWithState(-1 as To);
    }
  }, [navigateWithState, pathName, dispatch]);

  return (
    <div className="flex__wrapper">
      <Tooltip title={t("return")}>
        <div className="header-with-title__back-button" onClick={navigateBack}>
          <i className="bi bi-arrow-left-short" />
        </div>
      </Tooltip>
      <span className="header-with-title__divider" />
    </div>
  );
};

export default GoBackRoute;

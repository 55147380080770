import { Annotation } from "../../annotator/interfaces/annotation";
import { useMemo } from "react";
import { useAppSelector } from "../../app";
import { Status } from "../../common/status/status";

const ATTENTION_THRESHOLD = 90;

const STATUS_LIST = [
  Status.ToDo,
  Status.InProgress,
  Status.OnHold,
  Status.ExportFailed,
  Status.Error,
];

const useEntityAttention = (annotations: Array<Annotation>) => {
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const requiresScoreAttention = useMemo(() => {
    if (!activeDocumentSet || !STATUS_LIST.includes(activeDocumentSet.status)) {
      return false;
    }

    const total = annotations
      .map((a) => {
        if (!a.modelScore) {
          return 100;
        }

        return a.modelScore;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const avg = total / annotations.length;

    return avg <= ATTENTION_THRESHOLD;
  }, [annotations, activeDocumentSet]);

  const requiresErrorAttention = useMemo(() => {
    if (!activeDocumentSet || !STATUS_LIST.includes(activeDocumentSet.status)) {
      return false;
    }

    return annotations
      .map((a) => {
        return (
          a.entityAnnotationNormalization?.normalizedValue?.toLowerCase() ===
          "invalid value"
        );
      })
      .includes(true);
  }, [annotations, activeDocumentSet]);

  return { requiresScoreAttention, requiresErrorAttention };
};

export default useEntityAttention;

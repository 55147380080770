import { useCallback, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  MAX_TABLE_CONTROLS_HEIGHT,
  MIN_TABLE_CONTROLS_HEIGHT,
} from "../constants";
import {
  setAutoResizeDisabled,
  setIsTableControlsResizing,
} from "../store/AnnotationTableControlsSlice";
import activeGroupBlockEntityTypeSelector from "../../configMap/selectors/activeGroupBlockEntityTypeSelector";
import { changeActiveEntity, changeAnnotating } from "../../app/store/appSlice";
import useSetTableControlsHeight from "./useSetTableControlsHeight";

const useResizableTableControls = (hasMultipleLinesGroupBlocks: boolean) => {
  const { tableControlsHeight } = useAppSelector(
    (state) => state.annotationTableControlsReducer
  );

  const activeGroupBlockEntityType = useAppSelector(
    activeGroupBlockEntityTypeSelector
  );

  const resizableRef = useRef<HTMLDivElement>(null);

  const { setTableControlHeight } = useSetTableControlsHeight();

  const dispatch = useAppDispatch();

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (!resizableRef.current) {
        return;
      }

      dispatch(setIsTableControlsResizing(true));
      dispatch(setAutoResizeDisabled(true));

      const startY = e.clientY;
      const startHeight = resizableRef.current.getBoundingClientRect().height;

      const handleMouseMove = (e: MouseEvent) => {
        let newHeight = startHeight - (e.clientY - startY);

        if (newHeight > MAX_TABLE_CONTROLS_HEIGHT) {
          newHeight = MAX_TABLE_CONTROLS_HEIGHT;
        } else if (newHeight < MIN_TABLE_CONTROLS_HEIGHT) {
          if (activeGroupBlockEntityType?.multipleGroupBlocks) {
            dispatch(changeAnnotating(false));
            dispatch(changeActiveEntity(undefined));
          }

          newHeight = MIN_TABLE_CONTROLS_HEIGHT;
        }

        setTableControlHeight(newHeight);
      };

      const handleMouseUp = () => {
        dispatch(setIsTableControlsResizing(false));

        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [
      dispatch,
      activeGroupBlockEntityType?.multipleGroupBlocks,
      setTableControlHeight,
    ]
  );

  const heightAnnotator = useMemo(() => {
    if (hasMultipleLinesGroupBlocks) {
      return `calc(100% - ${tableControlsHeight}px)`;
    }

    return "100%";
  }, [tableControlsHeight, hasMultipleLinesGroupBlocks]);

  return {
    handleMouseDown,
    tableControlsHeight,
    resizableRef,
    heightAnnotator,
  };
};

export default useResizableTableControls;

import React, { FC } from "react";
import { Rectangle } from "../../../interfaces/textLayer";
import { calculateLineProperties } from "../../../utils/geometry";

type Props = {
  vertices: Array<Rectangle>;
  color: string;
};

const AnnotationItemConnection: FC<Props> = ({ vertices, color }) => {
  if (vertices.length <= 1) {
    return null;
  }

  return (
    <>
      {vertices
        .map((coords, index) => {
          if (index === 0) {
            return null;
          }

          const prevCoords = vertices[index - 1];

          const [rect1, rect2] = calculateLineProperties(coords, prevCoords);

          return (
            <span key={index}>
              <span
                className="annotation-item__connection"
                key={`rect1-${coords.top}-${coords.left}-${coords.height}-${coords.width}`}
                style={{
                  top: rect1.top,
                  left: rect1.left,
                  width: rect1.width,
                  height: rect1.height,
                  transform: `rotate(${rect1.rotation}deg)`,
                  border: `1px dashed ${color}`,
                  position: "absolute",
                }}
              />
              <span
                className="annotation-item__connection"
                key={`rect2-${coords.top}-${coords.left}-${coords.height}-${coords.width}`}
                style={{
                  top: rect2.top,
                  left: rect2.left,
                  width: rect2.width,
                  height: rect2.height,
                  transform: `rotate(${rect2.rotation}deg)`,
                  transformOrigin: "0% 0%",
                  border: `1px dashed ${color}`,
                  position: "absolute",
                }}
              />
            </span>
          );
        })
        .filter(Boolean)}
    </>
  );
};

export default AnnotationItemConnection;

import { createSelector } from "@reduxjs/toolkit";
import selectConfigMap from "./configMapSelector";
import { RootState } from "../../app";

type Props = {
  hideNonRequiredEntities: boolean;
};

const selectHideNonRequiredEntities = (_: RootState, props: Props) =>
  props.hideNonRequiredEntities;

const flatGroupBlockWrapperListSelector = createSelector(
  selectConfigMap,
  selectHideNonRequiredEntities,
  (configMap, hideNonRequiredEntities) => {
    if (!configMap?.groupBlocks.length) {
      return {
        singleLineGroupBlocks: [],
        multipleLinesGroupBlocks: [],
      };
    }

    const singleLineGroupBlocks = configMap.groupBlocks
      .filter((gb) => !gb.multipleGroupBlocks)
      .map((block) => ({
        ...block,
        groupBlockEntityTypes: [...block.groupBlockEntityTypes!]
          .filter((entity) => {
            if (!hideNonRequiredEntities) {
              return true;
            }

            return entity.required;
          })
          .sort((a, b) => a.index - b.index),
      }))
      .sort((a, b) => a.index - b.index);
    const multipleLinesGroupBlocks = configMap.groupBlocks
      .filter((gb) => gb.multipleGroupBlocks)
      .map((block) => ({
        ...block,
        groupBlockEntityTypes: [...block.groupBlockEntityTypes!]
          .filter((entity) => {
            if (!hideNonRequiredEntities) {
              return true;
            }

            return entity.required;
          })
          .sort((a, b) => a.index - b.index),
      }))
      .sort((a, b) => a.index - b.index);

    return {
      singleLineGroupBlocks,
      multipleLinesGroupBlocks,
    };
  }
);

export default flatGroupBlockWrapperListSelector;

import { useAppDispatch, useAppSelector } from "../../app";
import { useEffect } from "react";
import {
  addHoveredEntities,
  clearHoveredEntities,
} from "../../app/store/appSlice";
import { resetLastAnnotation } from "../../annotation/store/annotationSlice";

const useActiveEntityListener = () => {
  const dispatch = useAppDispatch();
  const activeEntity = useAppSelector((state) => state.appReducer.activeEntity);

  useEffect(() => {
    dispatch(clearHoveredEntities());
    dispatch(resetLastAnnotation());

    if (activeEntity) {
      dispatch(
        addHoveredEntities([
          {
            id: activeEntity.entityType.id,
            index: activeEntity.index,
          },
        ])
      );
    }
  }, [dispatch, activeEntity]);
};

export default useActiveEntityListener;

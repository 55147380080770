import { Translation } from "../../configMap";

export const getInLanguageOrDefault = (
  translations: Translation,
  language: string,
  fallBack?: string
) => {
  if (!translations) {
    if (typeof fallBack === "string") {
      return fallBack;
    }

    throw new Error(
      "No translation object found, please check the configuration."
    );
  }

  const languagesForObject = Object.keys(translations);

  if (!languagesForObject.length) {
    if (typeof fallBack === "string") {
      return fallBack;
    }

    throw new Error(
      "There should be at least 1 translation, please check the configuration."
    );
  }

  if (languagesForObject.includes(language)) {
    return translations[language];
  }

  return translations[languagesForObject[0]];
};

import React, { FC } from "react";
import useTempAnnotationOverview from "../hooks/useTempAnnotationOverview";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  clearHoveredEntities,
  setHoveredEntities,
} from "../../app/store/appSlice";
import "./TempAnnotationOverview.scss";

const TempAnnotationOverview: FC = () => {
  const dispatch = useAppDispatch();
  const createTable = useAppSelector(
    (state) => state.annotatorReducer.annotationState.createTable
  );

  const {
    tempAnnotations,
    scrollAnnotationIntoView,
    deleteAnnotations,
    acceptAnnotations,
    removeAnnotation,
  } = useTempAnnotationOverview();

  if (createTable) {
    return null;
  }

  if (!tempAnnotations.length) {
    return null;
  }

  return (
    <div className="table-data-overview__container">
      <h2>Suggested annotations</h2>
      <table>
        <tbody>
          {tempAnnotations.map((ta) => (
            <tr key={ta.id}>
              <td>
                <span
                  className="bi bi-search remove-line-icon"
                  onClick={() => scrollAnnotationIntoView(ta.id)}
                  onMouseEnter={() =>
                    dispatch(
                      setHoveredEntities([
                        { id: ta.entity.id, index: ta.index },
                      ])
                    )
                  }
                  onMouseLeave={() => dispatch(clearHoveredEntities())}
                />
              </td>
              <td>{ta.values.join(" ")}</td>
              {ta.modelScore && <td>{Math.round(ta.modelScore * 100)}%</td>}
              <td>
                <span
                  className="bi bi-trash remove-line-icon"
                  onClick={() => removeAnnotation(ta.id, true)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-buttons">
        <button onClick={acceptAnnotations}>Accept</button>
        <button onClick={deleteAnnotations}>Delete</button>
      </div>
    </div>
  );
};

export default TempAnnotationOverview;

import { ColumnKey } from "../../documentSet";

export enum SortDirection {
  Ascending,
  Descending,
}

export type FilterState = {
  key: ColumnKey;
  selectedFilterKey: string;
  selectedFilter: string;
};

export type SortState = {
  key: ColumnKey;
  sortDirection: SortDirection;
};

export type SortStateAction = {
  storageKey: string;
  state: SortState | null;
};

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "./annotationReducerSelector";
import { Annotation } from "../../annotator/interfaces/annotation";

const getAnnotationId = (_: RootState, annotationId: string) => annotationId;

const annotationForIdSelector = createSelector(
  [selectAnnotationReducer, getAnnotationId],
  (annotations, annotationId): Annotation | null => {
    const found = annotations.find(
      (annotation) => annotation.id === annotationId
    );

    if (!found) {
      return null;
    }

    return found;
  }
);

export default annotationForIdSelector;

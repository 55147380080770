import { useCallback, useEffect, useMemo } from "react";
import {
  annotationTodoStatuses,
  Status,
  todoStatuses,
} from "../../common/status/status";
import { GroupBlockEntityType } from "../";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  changeActiveButton,
  changeActiveEntity,
  changeAnnotating,
} from "../../app/store/appSlice";
import useModifierKeyListener from "../../common/utilities/hooks/useModifierKeyListener";

enum keys {
  esc = "ESCAPE",
  enter = "ENTER",
}

const useHotKeyListener = (
  hideNonRequiredEntities: boolean,
  disabled: boolean,
  entityTypes?: Array<GroupBlockEntityType> | null,
  multipleGroupBlocks?: boolean
) => {
  const dispatch = useAppDispatch();
  const { activeEntity, activeButton, isEditing } = useAppSelector(
    (state) => state.appReducer
  );

  const { disabledEntities } = useAppSelector(
    (state) => state.configMapReducer
  );

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  const modifierKeyPressed = useModifierKeyListener();

  const breakEarly = useMemo((): boolean => {
    if (disabled) {
      return true;
    }

    if (activeDocumentSet?.status === Status.Error) {
      return true;
    }

    if (!isAnnotationMode) {
      if (
        ![...todoStatuses, Status.ExportFailed].includes(
          activeDocumentSet?.status!
        )
      ) {
        return true;
      }
    } else {
      if (!annotationTodoStatuses.includes(activeDocumentSet?.status!)) {
        return true;
      }
    }

    if (isEditing) {
      return true;
    }

    if (!entityTypes?.length) {
      return true;
    }

    return modifierKeyPressed;
  }, [
    disabled,
    isAnnotationMode,
    activeDocumentSet?.status,
    entityTypes?.length,
    isEditing,
    modifierKeyPressed,
  ]);

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (breakEarly) {
        return;
      }

      if (activeButton && event.key.toUpperCase() === keys.enter) {
        document.getElementById(activeButton.id)?.click();
        return;
      }

      if (
        event.key.toUpperCase() === keys.esc ||
        event.key.toUpperCase() === keys.enter
      ) {
        dispatch(changeActiveEntity(undefined));
        dispatch(changeActiveButton(undefined));
        dispatch(changeAnnotating(false));
        return;
      }

      entityTypes!.forEach((entity) => {
        if (disabledEntities.includes(entity.entityType.id)) {
          return;
        }

        if (hideNonRequiredEntities && !entity.required) {
          return;
        }

        const hotkey = entity?.hotkey?.toUpperCase();
        if (hotkey && hotkey === event.key.toUpperCase()) {
          if (activeEntity && activeEntity.hotkey === hotkey) {
            dispatch(changeActiveEntity(undefined));
            dispatch(changeAnnotating(false));
          } else {
            let index = 1;
            if (multipleGroupBlocks && activeEntity?.index) {
              index = activeEntity.index;
            }

            dispatch(
              changeActiveEntity({
                ...entity,
                index,
              })
            );
            dispatch(changeAnnotating(true));
          }
        }
      });
    },
    [
      breakEarly,
      activeButton,
      entityTypes,
      dispatch,
      disabledEntities,
      hideNonRequiredEntities,
      activeEntity,
      multipleGroupBlocks,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent);

    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, [handleKeyEvent]);
};

export default useHotKeyListener;

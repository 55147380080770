import { useCallback, useEffect, useMemo } from "react";
import { GroupBlockData } from "../../configMap";
import { useAppSelector } from "../../app";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import useGroupBlock from "../../configMap/hooks/useGroupBlock";

const useActions = (
  groupBlock: GroupBlockData,
  index: number,
  amountOfLines: number,
  disabled: boolean,
  addGroupBlock: () => void
) => {
  const activeButton = useAppSelector((state) => state.appReducer.activeButton);

  const {
    i18n: { language },
  } = useTranslation();

  const groupBlockName = useMemo(() => {
    return getInLanguageOrDefault(groupBlock?.translations, language);
  }, [groupBlock?.translations, language]);

  const { activeDocument } = useActiveDocument();

  const { removeGroupBlockAndAnnotations, removeAnnotations } = useGroupBlock(
    true,
    groupBlock,
    activeDocument
  );

  const hasMultipleLines = useMemo(() => {
    return amountOfLines > 1;
  }, [amountOfLines]);

  const isAddButtonInFocus = useMemo(() => {
    return (
      activeButton?.id === `group-block-button-add-${groupBlock.id}-${index}`
    );
  }, [activeButton?.id, groupBlock.id, index]);

  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key.toUpperCase() === "ENTER" && isAddButtonInFocus && !disabled) {
        addGroupBlock();
      }
    },
    [isAddButtonInFocus, disabled, addGroupBlock]
  );

  useEffect(() => {
    window.addEventListener("keydown", downHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [downHandler]);

  return {
    groupBlockName,
    removeGroupBlockAndAnnotations,
    removeAnnotations,
    hasMultipleLines,
    isAddButtonInFocus,
  };
};

export default useActions;

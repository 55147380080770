import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { validLanguages } from "../../../";

type Props = {
  changeHasBeenUpdated: (state: boolean) => void;
};

const LanguageSelector: FC<Props> = ({ changeHasBeenUpdated }) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation("accountPopover");

  useEffect(() => {
    if (!validLanguages.includes(language)) {
      changeLanguage("en");
    }
  }, [language, changeLanguage]);

  const handleLanguageChange = useCallback(
    async (lang: string) => {
      if (validLanguages.includes(lang)) {
        await changeLanguage(lang);
        changeHasBeenUpdated(true);
      }
    },
    [changeLanguage, changeHasBeenUpdated]
  );

  return (
    <div className="account-popover__item">
      <div>
        <i className="bi bi-globe account-popover__item-icon" />
        <label className="account-popover__item-label">
          {t("language.label")}:
        </label>
      </div>
      <Select
        value={language}
        style={{ width: 120 }}
        onChange={handleLanguageChange}
      >
        {validLanguages.map((languageInList) => (
          <Select.Option key={languageInList} value={languageInList}>
            {t(`language.languages.${languageInList}`)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSelector;

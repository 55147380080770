import { useCallback, useMemo } from "react";
import useDetailPageRouting from "../../app/hooks/useDetailPageRouting";
import { useAppDispatch, useAppSelector } from "../../app";
import { Status } from "../../common/status/status";
import { changeScale } from "../../annotation/store/annotationSlice";
import { useTableAnnotatingListener } from "../../configMap";
import {
  setAnnotatorDocumentInfo,
  setAnnotatorTables,
  setCreateTable,
  setCurrentPage,
  setPreviousTableState,
  setTableLastAction,
} from "../../annotator/store/AnnotatorSlice";
import {
  AnnotatorDocumentInfoPayload,
  LastAction,
} from "../../annotator/interfaces/annotator";
import { ITable } from "../interfaces/ITable";
import { recalculateTables } from "../helpers/tableHelper";
import useAnnotatorData from "./useAnnotatorData";

const useAnnotatorConfig = () => {
  const dispatch = useAppDispatch();

  const { disabled } = useDetailPageRouting();

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const { hoveredEntities } = useAppSelector((state) => state.appReducer);

  const { handleTableAnnotatingChange } = useTableAnnotatingListener();

  const { scale } = useAppSelector((state) => state.annotationReducer);

  const { createTable, currentPage, pages, error } = useAppSelector(
    (state) => state.annotatorReducer.annotationState
  );

  const { previousTableState, lastAction } = useAppSelector(
    (state) => state.annotatorReducer.tableState
  );

  const handleCurrentPage = useCallback(
    (page: number) => {
      dispatch(setCurrentPage(page));
    },
    [dispatch]
  );

  const readonly = useMemo(() => {
    return disabled || activeDocumentSet?.status === Status.Error;
  }, [disabled, activeDocumentSet]);

  const { tables } = useAnnotatorData();

  const setScale = useCallback(
    (newScale: number) => {
      if (newScale !== scale) {
        const toUpdateTables = tables.map((prevTable) =>
          recalculateTables(newScale, scale, prevTable)
        );
        dispatch(setAnnotatorTables(toUpdateTables));
        dispatch(changeScale(newScale));
      }
    },
    [dispatch, tables, scale]
  );

  const handleCreateTable = useCallback(
    (newCreateTable: boolean) => {
      handleTableAnnotatingChange(newCreateTable);
      dispatch(setCreateTable(newCreateTable));
    },
    [handleTableAnnotatingChange, dispatch]
  );

  const handleAnnotatorInfoDocument = useCallback(
    (payload: AnnotatorDocumentInfoPayload) => {
      dispatch(setAnnotatorDocumentInfo(payload));
    },
    [dispatch]
  );

  const handlePreviousTableState = useCallback(
    (tables: Array<ITable>) => {
      dispatch(setPreviousTableState(tables));
    },
    [dispatch]
  );

  const handleTableLastAction = useCallback(
    (action: LastAction) => {
      dispatch(setTableLastAction(action));
    },
    [dispatch]
  );

  return {
    readonly,
    hoveredEntities,
    scale,
    setScale,
    createTable,
    setCreateTable: handleCreateTable,
    currentPage,
    pages,
    error,
    setCurrentPage: handleCurrentPage,
    setAnnotatorInfoDocument: handleAnnotatorInfoDocument,
    previousTableState,
    lastAction,
    setPreviousTableState: handlePreviousTableState,
    setTableLastAction: handleTableLastAction,
  };
};

export default useAnnotatorConfig;

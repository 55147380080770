import { useAppSelector } from "../../../app";
import { selectActiveDocumentLanguage } from "../../../document";
import { useEffect } from "react";
import { setDayJsLocale } from "../date";

const useDateLocaleSwitcher = () => {
  const docLanguage = useAppSelector(selectActiveDocumentLanguage);

  useEffect(() => {
    setDayJsLocale(docLanguage);
  }, [docLanguage]);
};

export default useDateLocaleSwitcher;

import { ThemeConfig } from "antd";

export const themeConfig: Partial<ThemeConfig> = {
  token: {
    colorPrimary: "#38b2ac",
    colorInfo: "#38b2ac",
    colorSuccess: "#52c41a",
    colorWarning: "#faad14",
    colorError: "#ff4d4f",
    fontFamily: "Inter, sans-serif",
  },
};

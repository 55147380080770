import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import { Annotation } from "../../annotator/interfaces/annotation";
import { EntityTypeRelation } from "../interfaces/entity";
import flatEntityListSelector from "./flatEntityListSelector";

type Props = {
  childrenIds: Array<EntityTypeRelation> | null;
};

const getChildrenIds = (_: RootState, props: Props) => props.childrenIds;

const entityChildrenAnnotationsSelector = createSelector(
  [selectAnnotationReducer, flatEntityListSelector, getChildrenIds],
  (annotations, entities, childrenIds): Array<Annotation> => {
    if (!childrenIds?.length) {
      return [];
    }

    const foundEntityTypeIds = entities
      .filter((e) =>
        childrenIds
          .map((cid) => cid.id.toLowerCase())
          .includes(e.id.toLowerCase())
      )
      .map((e) => e.entityType.id.toLowerCase());

    return annotations.filter((a) =>
      foundEntityTypeIds.includes(a.entity.id.toLowerCase())
    );
  }
);

export default entityChildrenAnnotationsSelector;

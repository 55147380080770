import { useMemo } from "react";
import { useAppSelector } from "../../app";
import useAnnotations from "../../annotation";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";

const useAnnotatorData = (pageNumber?: number) => {
  const activeDocument = useAppSelector(
    (state) => state.documentReducer.activeDocument
  );

  const { textLayer } = useAppSelector((state) => state.annotatorReducer);

  const { tables } = useAppSelector(
    (state) => state.annotatorReducer.tableState
  );

  const annotations = useAppSelector(selectAnnotationReducer);

  const { updateAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const annotationsForPage = useMemo(() => {
    if (!pageNumber) {
      return [];
    }

    return annotations.filter(
      (annotation) =>
        !!annotation.areaAnnotation && annotation.page === pageNumber
    );
  }, [annotations, pageNumber]);

  return {
    tables,
    annotations,
    annotationsForPage,
    updateAnnotations,
    textLayer,
  };
};

export default useAnnotatorData;

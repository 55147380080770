import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../app";
import { useTranslation } from "react-i18next";
import useActions from "../../../annotationSideControls/hooks/useActions";

const useHeaderText = () => {
  const { pathname } = useLocation();

  const user = useAppSelector((state) => state.userReducer.user);

  const { t } = useTranslation("header");

  const { activeDocument } = useActions();

  const headerTitle = useMemo(() => {
    if (pathname.includes("analytics")) {
      return "Analytics";
    } else if (pathname.includes("user/management")) {
      return `${user?.organization.name} ${t(
        "headerWithTitle.userManagement"
      )}`;
    } else if (pathname.includes("user/settings")) {
      return t("headerWithTitle.personalSettings");
    } else if (pathname.includes("email-templates")) {
      return t("headerWithTitle.emailTemplates");
    } else if (pathname.includes("detail/")) {
      return activeDocument?.name;
    } else if (pathname.includes("releases")) {
      return t("headerWithTitle.releaseNotes");
    } else if (pathname.includes("organization/settings")) {
      return t("headerWithTitle.organizationSettings");
    } else {
      return null;
    }
  }, [pathname, user?.organization.name, t, activeDocument]);

  const showBackButton = useMemo(() => {
    return (
      pathname.includes("user/settings") ||
      pathname.includes("email-templates") ||
      pathname.includes("releases")
    );
  }, [pathname]);

  const showBackButtonAndUpDownButton = useMemo(
    () => pathname.includes("detail/"),
    [pathname]
  );

  return {
    headerTitle,
    showBackButton,
    showBackButtonAndUpDownButton,
    pathname,
  };
};

export default useHeaderText;

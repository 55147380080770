import React, { FC, ReactNode } from "react";
import { SideMenu } from "./index";
import { Layout } from "antd";
import HeaderSwitch, { HeaderType } from "./header/HeaderSwitch";
import "./WithLayout.scss";

type WithLayoutWrapperProps = {
  activeKey: string;
  headerType?: HeaderType;
  children: ReactNode;
};

const WithLayout: FC<WithLayoutWrapperProps> = ({
  activeKey,
  children,
  headerType,
}) => {
  return (
    <Layout hasSider>
      <SideMenu activeKey={activeKey} />
      <Layout className="layout-container">
        {headerType !== undefined && <HeaderSwitch headerType={headerType} />}
        {children}
      </Layout>
    </Layout>
  );
};

export default WithLayout;

import React, { ReactNode, FC } from "react";
import { CustomAvatar } from "../index";
import "./AvatarWithRemove.scss";

type Props = {
  onClick: (...args: any) => void;
  size: number | "large" | "small" | "default";
  showRemove: boolean;
  icon?: ReactNode;
  src: string;
  fontSize?: number;
  tooltip?: string;
};

const AvatarWithRemove: FC<Props> = ({
  size,
  icon,
  src,
  fontSize,
  tooltip,
  onClick,
  showRemove,
}) => {
  return (
    <div className="avatar__container">
      {showRemove ? (
        <span className="avatar__remove-icon" onClick={onClick}>
          <i className="bi bi-x" />
        </span>
      ) : null}
      <CustomAvatar
        size={size}
        icon={icon}
        src={src!}
        fontSize={fontSize}
        tooltipTitle={tooltip}
        placement="bottom"
      />
    </div>
  );
};

export default AvatarWithRemove;

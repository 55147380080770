import React, { FC } from "react";
import { OverviewType } from "../../../../../app";
import { Button } from "antd";
import useStatusSelector from "../../../hooks/useStatusSelector";
import StatusColoredLabel from "../../../../statusColoredLabel/StatusColoredLabel";

type Props = {
  overviewType: OverviewType;
};

const StatusSelector: FC<Props> = ({ overviewType }) => {
  const { activeStatusValue, handleChange, statusOptions, clearStatusFilter } =
    useStatusSelector(overviewType);

  return (
    <div className="header-status__container">
      {statusOptions.length > 1 && (
        <>
          <Button
            key={"All"}
            className={`header__status-button ${
              !activeStatusValue && "active"
            }`}
            icon={<i className="bi bi-eye" />}
            onClick={clearStatusFilter}
          >
            All
          </Button>
          {statusOptions.map((option) => (
            <Button
              key={option.value}
              className={`header__status-button ${
                activeStatusValue === option.value && "active"
              }`}
              onClick={() => handleChange(option.value)}
            >
              <StatusColoredLabel status={option.value} />
            </Button>
          ))}
        </>
      )}
    </div>
  );
};

export default StatusSelector;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateStpEntitiesAction, UpdateStpSuppliersActions } from "./actions";
import { IEntityStpTableData, IStpSupplierTableData } from "../interfaces/stp";
import { OrganizationSettingsMenuKeys } from "../utils/constants";
import { ConfigMap } from "../../configMap";

interface OrganizationStpSettings {
  stpEntitiesDocumentType: Array<IEntityStpTableData>;
  stpEntities: Array<IEntityStpTableData>;
  // TODO: this should become a generic tableCategorizationCell prop
  suppliers: Array<IStpSupplierTableData>;
}

interface OrganizationSettingsState {
  configMap: ConfigMap | null;
  documentTypeId: string | null;
  stp: OrganizationStpSettings;
}

interface OrganizationState {
  settings: OrganizationSettingsState;
  selectedKey: OrganizationSettingsMenuKeys;
}

const initialState: OrganizationState = {
  settings: {
    configMap: null,
    documentTypeId: null,
    stp: {
      stpEntitiesDocumentType: [],
      stpEntities: [],
      suppliers: [],
    },
  },
  selectedKey: OrganizationSettingsMenuKeys.USERMANAGEMENT,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setStpEntitiesDocumentType: (
      state,
      action: PayloadAction<UpdateStpEntitiesAction>
    ) => {
      const { entities } = action.payload;
      state.settings.stp.stpEntitiesDocumentType = entities;
    },
    setStpEntities: (state, action: PayloadAction<UpdateStpEntitiesAction>) => {
      const { entities } = action.payload;
      state.settings.stp.stpEntities = entities;
    },
    addStpEntity: (state, action: PayloadAction<IEntityStpTableData>) => {
      const stpEntity = action.payload;
      state.settings.stp.stpEntities.push(stpEntity);
    },
    removeStpEntity: (state, action: PayloadAction<string>) => {
      state.settings.stp.stpEntities = state.settings.stp.stpEntities.filter(
        (stpEntity) => stpEntity.id !== action.payload
      );
    },
    setStpSuppliers: (
      state,
      action: PayloadAction<UpdateStpSuppliersActions>
    ) => {
      const { suppliers } = action.payload;
      state.settings.stp.suppliers = suppliers;
    },
    setSelectedKey: (
      state,
      action: PayloadAction<OrganizationSettingsMenuKeys>
    ) => {
      state.selectedKey = action.payload;
    },
    setSelectedConfigMap: (state, action: PayloadAction<ConfigMap | null>) => {
      state.settings.configMap = action.payload;
    },
    setSelectedDocumentTypeId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.settings.documentTypeId = action.payload;
    },
  },
});

export const {
  setStpEntitiesDocumentType,
  setStpEntities,
  setStpSuppliers,
  setSelectedKey,
  setSelectedConfigMap,
  setSelectedDocumentTypeId,
} = organizationSlice.actions;

export default organizationSlice.reducer;

import React, { FC } from "react";
import ValidationIconMapper from "../../../../common/validation/ValidationIconMapper";
import { IssueLine } from "../../../interfaces/issuesEntity";
import { GroupBlockEntityType } from "../../../../configMap";

type Props = {
  groupBlockName: string;
  issueAnnotations: Array<IssueLine>;
  isError: boolean;
  navigateToEntity: (
    entityType: GroupBlockEntityType | undefined,
    groupBlockId: string | undefined,
    multipleGroupBlocks: boolean | undefined
  ) => void;
};

const GroupBlockIssuesContainer: FC<Props> = ({
  groupBlockName,
  issueAnnotations,
  isError,
  navigateToEntity,
}) => {
  if (!issueAnnotations.length) {
    return null;
  }

  return (
    <div className="issues-group-block-container">
      <h4 className="issues-group-block-name">{`${groupBlockName} ${
        isError ? " - Errors" : " - Warnings"
      }`}</h4>
      {issueAnnotations.map((annotation, index) => (
        <div
          key={index}
          className="issues-group-block-line"
          onClick={() =>
            navigateToEntity(
              annotation.entityType,
              annotation.groupBlockId,
              annotation.multipleGroupBlocks
            )
          }
        >
          {isError ? (
            <span className="error-icon">
              <ValidationIconMapper icon="ExclamationCircle" />
            </span>
          ) : (
            <span className="warning-icon">
              <ValidationIconMapper icon="Warning" />
            </span>
          )}

          <span className="name">{annotation.entityName}</span>
        </div>
      ))}
    </div>
  );
};

export default GroupBlockIssuesContainer;

import React from "react";
import TitleRowWithContent from "../../../../common/titleRowWithContent/TitleRowWithContent";
import Dragger from "antd/lib/upload/Dragger";
import { GetProp, UploadProps, message } from "antd";
import "./EdmacArticleCsvSettings.scss";
import { getVariableFromEnv } from "../../../../common/utilities/env";
import useAuthenticationCookie from "../../../../user/hooks/useAuthenticationCookie";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const requiredHeaders = ["PurchaseOrderID", "VendorItemNr"];

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

const props: UploadProps = {
  showUploadList: false,
  name: "file",
  multiple: false,
  action: `${API_BASE_URL}/customer/normalization/api/v1/upload/csv`,
  accept: ".csv",
  beforeUpload(file: FileType) {
    return new Promise((resolve: any, reject: any) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        const [headerLine] = text.split(/\r?\n/); // Get the first line (headers)
        const headers = headerLine
          .split(",")
          .map((header: string) => header.trim()); // Split headers by comma and trim whitespace

        const allHeadersPresent = requiredHeaders.every((header) =>
          headers.includes(header)
        );

        if (allHeadersPresent) {
          resolve(); // Continue the upload
        } else {
          message.error(
            `Missing required headers: ${requiredHeaders
              .filter((header) => !headers.includes(header))
              .join(", ")}`
          );
          reject(file); // Prevent the upload
        }
      };
      reader.onerror = () => {
        message.error("Failed to read the file");
        reject(file); // Prevent the upload on error
      };

      reader.readAsText(file); // Read the file as text
    });
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      message.success("File uploaded successfully.");
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const EdmacArticleCsvSettings = () => {
  const { cookie } = useAuthenticationCookie();

  return (
    <div>
      <TitleRowWithContent title="Article - PO CSV" content={null}>
        <div>
          <Dragger {...props} headers={{ Authorization: `Bearer ${cookie}` }}>
            <i
              className="ant-upload-drag-icon bi bi-upload"
              style={{ fontSize: "30px" }}
            />
            <p className="ant-upload-text">
              Click or drag CSV file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Upload a new version of the CSV containing the PO numbers and
              articles. Make sure the format of the CSV stays the same.
            </p>
          </Dragger>
        </div>
      </TitleRowWithContent>
    </div>
  );
};

export default EdmacArticleCsvSettings;

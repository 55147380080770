import React, { FC, useMemo } from "react";
import "./IssuesHeaderTag.scss";
import { Popover, Tag, Tooltip } from "antd";
import IssuesOverviewPopover from "./issuesOverviewPopover/IssuesOverviewPopover";
import { Annotation } from "../../../annotator/interfaces/annotation";
import { IssuesEntity } from "../../interfaces/issuesEntity";

type ProblemsOverviewProps = {
  disabled: boolean;
  entities: Array<IssuesEntity>;
  errorAttentionAnnotations: Array<Annotation>;
  scoreAttentionAnnotations: Array<Annotation>;
  tagColor: string;
  areThereIssues: boolean;
};

const IssuesHeaderTag: FC<ProblemsOverviewProps> = ({
  disabled,
  entities,
  errorAttentionAnnotations,
  scoreAttentionAnnotations,
  tagColor,
  areThereIssues,
}) => {
  const tagLabel = useMemo(() => {
    if (!areThereIssues) {
      return;
    }

    const labels = [];

    if (errorAttentionAnnotations?.length) {
      const errorLabel =
        errorAttentionAnnotations.length > 1 ? "errors" : "error";
      labels.push(`${errorAttentionAnnotations.length} ${errorLabel}`);
    }

    if (scoreAttentionAnnotations?.length) {
      const warningLabel =
        scoreAttentionAnnotations.length > 1 ? "warnings" : "warning";
      labels.push(`${scoreAttentionAnnotations.length} ${warningLabel}`);
    }

    return labels.join(" | ");
  }, [
    areThereIssues,
    errorAttentionAnnotations?.length,
    scoreAttentionAnnotations?.length,
  ]);

  if (!areThereIssues || disabled) {
    return null;
  }

  return (
    <div className="issues-overview-container">
      <Popover
        placement="leftBottom"
        className="icon-container"
        title={
          <>
            <h4 className="issues-overview-popover-title">
              Issues in document
            </h4>
            <Tooltip
              title={"Click on an issue to go to the entity automatically"}
            >
              <i className="bi bi-question-circle" />
            </Tooltip>
          </>
        }
        content={
          <IssuesOverviewPopover
            errorAttentionAnnotations={errorAttentionAnnotations}
            scoreAttentionAnnotations={scoreAttentionAnnotations}
            entities={entities}
          />
        }
      >
        <Tag color={tagColor} className="issues-overview-tag">
          {tagLabel}
          <i className="bi bi-question-circle"></i>
        </Tag>
      </Popover>
    </div>
  );
};

export default IssuesHeaderTag;

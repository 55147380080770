export enum ValidationTypes {
  ERROR,
  WARNING,
  INFO,
}

export interface ValidationProps {
  show: boolean;
  type: ValidationTypes | null;
  message?: string;
  icon?: JSX.Element;
}

export interface Validation {
  type: ValidationTypes | null;
  validate: (...args: Array<any>) => boolean;
  requiresValue: boolean;
  awaitBlur?: boolean;
  message?: string;
  icon?: string;
}

export interface ValidationResult {
  show: boolean;
  type: ValidationTypes | null;
  message?: string;
  icon?: string;
}

export enum NormalizationType {
  DATE = "date",
  NUMBER = "number",
  NUMBER_RAVAGO = "numberRavago",
  PRICE_RAVAGO = "priceRavago",
  WEEK = "week",
}

export type NormalizedOutput = {
  id?: string;
  normalizedValue: string;
  normalizedMethod: string | undefined;
  options?: Array<string>;
};

interface StringByStringArray {
  [key: string]: string[];
}

export const VALID_DATE_FORMATS: StringByStringArray = {
  eu: [
    // day and month parsing
    "D/M",
    "D-M",
    "D.M",
    "DD/MM",
    "DD-MM",
    "DD.MM",
    // year: 2022,  month:01, day: 01
    "YYYY.MM.DD",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    // day: 1,01 month:1,01 year: 22
    "D.M.YY",
    "D-M-YY",
    "D/M/YY",
    "D.MM.YY",
    "D-MM-YY",
    "D/MM/YY",
    "DD.M.YY",
    "DD-M-YY",
    "DD/M/YY",
    "DD.MM.YY",
    "DD-MM-YY",
    "DD/MM/YY",
    // year: 22, day:01 month:,01
    "YY.DD.MM",
    "YY/DD/MM",
    "YY-DD-MM",
    // day: 1,01 month:1,01 year: 2222
    "D.M.YYYY",
    "D-M-YYYY",
    "D/M/YYYY",
    "D.MM.YYYY",
    "D-MM-YYYY",
    "D/MM/YYYY",
    "DD-M-YYYY",
    "DD/M/YYYY",
    "DD.MM.YYYY",
    "DD-MM-YYYY",
    "DD/MM/YYYY",
    // day:1 month: full, year:2022
    "D MMMM YYYY",
    "D-MMMM-YYYY",
    "D/MMMM/YYYY",
    "D.MMMM.YYYY",
    "DDMMMMYYYY",
    "DD-MMMM-YYYY",
    "DD/MMMM/YYYY",
    "DD.MMMM.YYYY",
    // day:01 month: full, year:22
    "DDMMMMYY",
    "DD-MMMM-YY",
    "DD/MMMM/YY",
    "DD.MMMM.YY",
    "DMMMMYY",
    "D-MMMM-YY",
    "D/MMMM/YY",
    "D.MMMM.YY",
    // day:1 month: abr, year:2022
    "DMMMYYYY",
    "D-MMM-YYYY",
    "D/MMM/YYYY",
    "D.MMM.YYYY",
    "DDMMMYYYY",
    "DD-MMM-YYYY",
    "DD/MMM/YYYY",
    "DD.MMM.YYYY",
    // day:01 month: abr, year:22
    "DDMMMYY",
    "DD-MMM-YY",
    "DD/MMM/YY",
    "DD.MMM.YY",
    "DMMMYY",
    "D-MMM-YY",
    "D/MMM/YY",
    "D.MMM.YY",
    // special
    "L",
    "LL",
  ],
  us: [
    // day and month parsing
    "M/D",
    "M-D",
    "M.D",
    "MM/DD",
    "MM-DD",
    "MM.DD",
    // year: 2022,  month:01, day: 01
    "YYYY.MM.DD",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
    // month:1,01 day: 1,01  year: 22
    "M.D.YY",
    "M-D-YY",
    "M/D/YY",
    "M.DD.YY",
    "M-DD-YY",
    "M/DD/YY",
    "MM.D.YY",
    "MM-D-YY",
    "MM/D/YY",
    "MM.DD.YY",
    "MM-DD-YY",
    "MM/DD/YY",
    // year: 22, month:01, day:01
    "YY.MM.DD",
    "YY/MM/DD",
    "YY-MM-DD",
    //  month:1,01 day: 1,01 year: 2222
    "M.D.YYYY",
    "M-D-YYYY",
    "M/D/YYYY",
    "M.DD.YYYY",
    "M-DD-YYYY",
    "M/DD/YYYY",
    "MM.D.YYYY",
    "MM-D-YYYY",
    "MM/D/YYYY",
    "MM.DD.YYYY",
    "MM-DD-YYYY",
    "MM/DD/YYYY",
    // month: full day:1,02 , year:2022
    "MMMMDYYYY",
    "MMMM-D-YYYY",
    "MMMM/D/YYYY",
    "MMMM.D.YYYY",
    "MMMMDDYYYY",
    "MMMM-DD-YYYY",
    "MMMM/DD/YYYY",
    "MMMM.DD.YYYY",
    //  month: full,day:1,01 year:22
    "MMMMDDYY",
    "MMMM-DD-YY",
    "MMMM/DD/YY",
    "MMMM.DD.YY",
    "MMMMDYY",
    "MMMM-D-YY",
    "MMMM/D/YY",
    "MMMM.D.YY",
    // day:1,01 month: abr, year:2022
    "MMMDYYYY",
    "MMM-D-YYYY",
    "MMM/D/YYYY",
    "MMM.D.YYYY",
    "MMMDDYYYY",
    "MMM-DD-YYYY",
    "MMM/DD/YYYY",
    "MMM.DD.YYYY",
    // month: abr,day:01,1  year:22
    "MMMDYY",
    "MMM-D-YY",
    "MMM/D/YY",
    "MMM.D.YY",
    "MMMDDYY",
    "MMM-DD-YY",
    "MMM/DD/YY",
    "MMM.DD.YY",
    // special
    "L",
    "LL",
  ],
};

import { GroupBlockData } from "../interfaces/groupblock";
import { useCallback, useMemo } from "react";
import { Document } from "../../document";
import { useMultipleGroupBlocks } from "../index";
import useAnnotations from "../../annotation";
import useDocumentCategoryAnnotations from "../../metadata/hooks/useDocumentCategoryAnnotations";

const useGroupBlock = (
  multipleGroupBlocks: boolean,
  groupBlock: GroupBlockData,
  activeDocument?: Document
) => {
  const { groupBlockEntityTypes } = groupBlock;

  const { removeGroupBlock, resetMultipleGroupBlock } =
    useMultipleGroupBlocks();

  const { clearAnnotationsForEntities, clearAnnotationsForEntity } =
    useAnnotations({
      documentId: activeDocument?.id,
    });

  const {
    modifyDocumentCategoryAnnotations,
    removeDocumentCategoryAnnotationsByCategorizations,
  } = useDocumentCategoryAnnotations();

  const entityTypesSorted = useMemo(
    () =>
      groupBlockEntityTypes
        ? [...groupBlockEntityTypes].sort((a, b) => a.index - b.index)
        : [],
    [groupBlockEntityTypes]
  );

  const entityIds = useMemo(
    () => entityTypesSorted.map((entityType) => entityType.entityType.id),
    [entityTypesSorted]
  );

  const categorizations = useMemo(
    () => groupBlock.categorizationGroupBlocks.map((c) => c.categorization),
    [groupBlock.categorizationGroupBlocks]
  );

  const removeGroupBlockAndAnnotations = useCallback(
    (index: number) => {
      removeGroupBlock(groupBlock);
      clearAnnotationsForEntities(entityIds, index, true, multipleGroupBlocks);
      modifyDocumentCategoryAnnotations(
        categorizations,
        index,
        true,
        activeDocument?.id
      );
    },
    [
      removeGroupBlock,
      groupBlock,
      entityIds,
      clearAnnotationsForEntities,
      activeDocument?.id,
      modifyDocumentCategoryAnnotations,
      categorizations,
      multipleGroupBlocks,
    ]
  );

  const removeAnnotations = useCallback(
    (index: number) => {
      clearAnnotationsForEntities(entityIds, index, false, multipleGroupBlocks);
      modifyDocumentCategoryAnnotations(
        categorizations,
        index,
        false,
        activeDocument?.id
      );
    },
    [
      entityIds,
      clearAnnotationsForEntities,
      modifyDocumentCategoryAnnotations,
      activeDocument?.id,
      categorizations,
      multipleGroupBlocks,
    ]
  );

  const removeGroupBlocksForEntityAndAnnotations = useCallback(
    (index: number) => {
      resetMultipleGroupBlock(groupBlock);
      clearAnnotationsForEntity(entityIds, index, multipleGroupBlocks);
      removeDocumentCategoryAnnotationsByCategorizations(
        categorizations,
        activeDocument?.id
      );
    },
    [
      groupBlock,
      entityIds,
      resetMultipleGroupBlock,
      clearAnnotationsForEntity,
      removeDocumentCategoryAnnotationsByCategorizations,
      activeDocument?.id,
      categorizations,
      multipleGroupBlocks,
    ]
  );

  return {
    entityIds,
    entityTypesSorted,
    removeGroupBlockAndAnnotations,
    removeAnnotations,
    removeGroupBlocksForEntityAndAnnotations,
  };
};

export default useGroupBlock;

import React, { FC } from "react";
import TextEntity from "./textEntity";
import "./Entity.scss";
import { Component, GroupBlockEntityType } from "../../";
import { EntityAnnotation } from "../../../annotation";
import WeekEntity from "./weekEntity";
import DateEntity from "./dateEntity";
import NumberEntity from "./numberEntity";

export type SingleAnnotationProps = {
  entityType: GroupBlockEntityType;
  entityAnnotation: EntityAnnotation;
  isEditing: boolean;
  isAnnotating: boolean;
  groupBlockIndex: number;
  requiresAttention: boolean;
  disabled: boolean;
  documentId?: string;
  showLabels?: boolean;
  isEditable?: boolean;
  showNormalizedValue?: boolean;
};

const SingleAnnotation: FC<SingleAnnotationProps> = (props) => {
  const {
    entityType: { entityType },
  } = props;

  switch (entityType.component.toLowerCase()) {
    case Component.NUMBER.toLowerCase():
      return <NumberEntity {...props} />;
    case Component.DATE.toLowerCase():
      return <DateEntity {...props} />;
    case Component.WEEK.toLowerCase():
      return <WeekEntity {...props} />;
    default:
      return <TextEntity {...props} />;
  }
};

export default SingleAnnotation;

import { useAppSelector } from "../../app";
import inputNamesSelector from "../../documentTypes/selectors/inputNamesSelector";
import { useMemo } from "react";
import lodash from "lodash";
import { MetricsMode, OTHER_SOURCE_COLOR, SOURCE_COLORS } from "../constants";
import {
  GraphLineMetrics,
  GraphPieMetrics,
  SourceColor,
} from "../interfaces/documentSetMetrics";

const useGraphConfig = (
  metrics: Array<GraphLineMetrics | GraphPieMetrics> | undefined,
  sourceColors: Array<SourceColor> | undefined
) => {
  const inputNames = useAppSelector(inputNamesSelector);
  const metricsMode = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter.metricsMode
  );

  const seriesNames = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return lodash
      .chain(metrics)
      .map("name")
      .uniq()
      .sortBy((name) => name.toLowerCase())
      .value();
  }, [metrics]);

  const colors = useMemo(() => {
    if (metricsMode === MetricsMode.OPERATIONAL) {
      // Get colors from inputs
      const colors = inputNames
        .map((input) => {
          if (
            seriesNames
              .map((sn) => sn.toLowerCase())
              .includes(input.displayName.toLowerCase())
          ) {
            return input.color;
          }

          return null;
        })
        .filter((color): color is string => color !== null);

      if (colors.length < seriesNames.length) {
        const missingColorsCount = seriesNames.length - colors.length;
        const additionalColors = SOURCE_COLORS.slice(-missingColorsCount);

        colors.push(...additionalColors);
      }

      return colors;
    }

    if (!sourceColors?.length) {
      return SOURCE_COLORS;
    }

    // Get colors from the given sourceColors array
    const colors = seriesNames.map((name, index) => {
      const found = sourceColors.find((sc) => sc.source === name);
      if (found) {
        return found.color;
      }

      if (SOURCE_COLORS.length > index) {
        return SOURCE_COLORS[index];
      }

      return OTHER_SOURCE_COLOR;
    });

    return colors;
  }, [inputNames, seriesNames, metricsMode, sourceColors]);

  return { seriesNames, colors };
};

export default useGraphConfig;

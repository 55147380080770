import { useCallback, useMemo } from "react";
import useControls from "./useControls";
import lodash from "lodash";
import { Dictionary } from "@reduxjs/toolkit";
import { Annotation } from "../../annotator/interfaces/annotation";
import useAnnotatorData from "./useAnnotatorData";
import useTableUpdater from "./useTableUpdater";

const useTableOverview = () => {
  const {
    confirmAnnotations,
    deleteAnnotations,
    deleteRowAnnotations,
    removeTable,
  } = useTableUpdater();

  const { annotations, tables } = useAnnotatorData();

  const tableControlAnnotations = useMemo(() => {
    return annotations.filter((a) => a.multipleGroupBlocks);
  }, [annotations]);

  const { setCreateTable } = useControls();

  const groupedData: never[] | Dictionary<Annotation[]> = useMemo(() => {
    if (!tables.length) {
      return [];
    }

    const filtered = tableControlAnnotations.filter(
      (annotation) => annotation.tempAnnotation
    );
    return lodash(filtered).groupBy("index").value();
  }, [tables, tableControlAnnotations]);

  const tableHeaders = useMemo(() => {
    if (Object.keys(groupedData).length) {
      const firstKey = Object.keys(groupedData)[0];
      return (groupedData as any)[firstKey].map((a: any) => ({
        id: a.entity.id,
        value: a.entity.name,
      }));
    }

    return [];
  }, [groupedData]);

  const deleteTableAnnotations = () => {
    tables.forEach((table) => {
      deleteAnnotations(table);
      removeTable(table);
    });
  };

  const confirmTableAnnotations = () => {
    setCreateTable(false);

    tables.forEach((table) => {
      confirmAnnotations(table);
    });
  };

  const deleteRow = useCallback(
    (rowIndex: number) => {
      tables.forEach((table) => {
        deleteRowAnnotations(table, rowIndex);
      });
    },
    [tables, deleteRowAnnotations]
  );

  return {
    tableHeaders,
    deleteTableAnnotations,
    confirmTableAnnotations,
    deleteRow,
    groupedData,
  };
};

export default useTableOverview;

import React, { FC } from "react";
import RemoveButton from "./RemoveButton";

type Props = {
  isCreatingTable: boolean;
  id: string;
  tableId: string;
  left: number;
  height: number;
  isHiddenColumn: boolean;
  page: number;
};

const TableColumn: FC<Props> = ({
  isCreatingTable,
  id,
  tableId,
  left,
  height,
  isHiddenColumn,
  page,
}) => {
  if (isHiddenColumn) {
    return null;
  }

  return (
    <>
      <div className="col" style={{ left, height }} />
      <RemoveButton
        visible={isCreatingTable}
        tableId={tableId}
        id={id}
        style={{ left: left - 7, top: height + 3 }}
        page={page}
      />
    </>
  );
};

export default TableColumn;

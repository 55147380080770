import React, { FC, useCallback, useState } from "react";
import { UserProfileState } from "../../interfaces/interfaces";
import { Input } from "antd";

type Props = {
  value: string;
  name: keyof UserProfileState;
  label: string;
  handleBlur: (key: string, value: string) => void;
};

const ValueInput: FC<Props> = ({ value, name, label, handleBlur }) => {
  const [state, setState] = useState(value);

  const onBlur = useCallback(() => {
    if (state !== value) {
      handleBlur(name, state);
    }
  }, [name, state, value, handleBlur]);

  return (
    <div className="welcome-page__form-item">
      <label>{label}</label>
      <Input
        name={name}
        value={state}
        onChange={(event) => setState(event.target.value)}
        onBlur={onBlur}
        onPressEnter={onBlur}
        size="large"
      />
    </div>
  );
};

export default ValueInput;

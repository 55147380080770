import React from "react";
import useBulkAction from "../../hooks/useBulkAction";
import { Alert } from "antd";
import "./BulkActionAlert.scss";
import { HEIGHT_BULK_REMOVE_HEADER } from "../../constants";
import { useTranslation } from "react-i18next";

const BulkActionAlert = () => {
  const { removeRows, rejectRows, selectedRows, resetRows } = useBulkAction();

  const { t } = useTranslation("overview");

  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <>
      {selectedRows.length >= 1 && (
        <Alert
          className="bulk-action-alert"
          style={{ height: `${HEIGHT_BULK_REMOVE_HEADER}px` }}
          message={
            <div className="bulk-action-alert__container">
              <div>
                <span className="bulk-action-alert__description">
                  {selectedRows.length}{" "}
                  {selectedRows.length > 1
                    ? t("bulkActionAlert.descriptionMultiple")
                    : t("bulkActionAlert.description")}
                </span>
              </div>
              <div>
                <a
                  className="bulk-action-alert__link delete-action"
                  onClick={removeRows}
                >
                  <i className="bi bi-trash" />
                  {t("bulkActionAlert.removeLink")}
                </a>
                <a
                  className="bulk-action-alert__link reject-action"
                  onClick={rejectRows}
                >
                  <i className="bi bi-hand-thumbs-down" />
                  {t("bulkActionAlert.rejectLink")}
                </a>
              </div>
              <div>
                <a className="bulk-action-alert__link" onClick={resetRows}>
                  {t("bulkActionAlert.cancelLink")}
                </a>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

export default BulkActionAlert;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

type ReturnObject = {
  id: string;
  index: number;
};

const getActiveEntity = (state: RootState) => state.appReducer.activeEntity;
const getHighlightedEntities = (state: RootState) =>
  state.annotationReducer.highlightedEntities;
const getTableControlAnnotations = (state: RootState) =>
  state.annotationReducer.tableControlAnnotations;

const annotationToScrollToSelector = createSelector(
  [getActiveEntity, getHighlightedEntities, getTableControlAnnotations],
  (
    activeEntity,
    highlightedEntities,
    tableControlAnnotations
  ): ReturnObject | null => {
    if (activeEntity) {
      return {
        id: activeEntity.entityType.id,
        index: activeEntity.index,
      };
    }

    if (!tableControlAnnotations.length) {
      return null;
    }

    if (highlightedEntities.length) {
      const mappedToAnnotations = highlightedEntities
        .map((he) => {
          const found = tableControlAnnotations.find(
            (tca) => tca.index === he.index && tca.entity.id === he.id
          );

          if (found) {
            return found;
          }

          return null;
        })
        .filter(Boolean);

      if (mappedToAnnotations.length && mappedToAnnotations[0]) {
        return {
          id: mappedToAnnotations[0].entity.id,
          index: mappedToAnnotations[0].index!,
        };
      }
    }

    return null;
  }
);

export default annotationToScrollToSelector;

import { useMemo } from "react";
import { NormalizationType } from "../interfaces/normalization";
import { EntityNormalization } from "../../annotator/interfaces/entity";

const useEntityNormalization = (
  entityNormalizations: Array<EntityNormalization> | null | undefined
) => {
  const entityNormalizationFormat = useMemo(() => {
    if (!entityNormalizations?.length) {
      return null;
    }

    const sortedEntityNormalizations = entityNormalizations.sort(
      (item1, item2) => item1.index - item2.index
    );

    const lastNormalization =
      sortedEntityNormalizations[sortedEntityNormalizations.length - 1];
    const type = lastNormalization.normalizationStrategy["type"];

    switch (type.toLowerCase()) {
      case NormalizationType.DATE.toLowerCase():
        return lastNormalization.normalizationStrategy["format"];
      case NormalizationType.WEEK.toLowerCase():
        return lastNormalization.normalizationStrategy["format"];
      default:
        return null;
    }
  }, [entityNormalizations]);

  return {
    entityNormalizationFormat,
  };
};

export default useEntityNormalization;

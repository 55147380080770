import React, { FC, memo, MouseEvent, useCallback } from "react";
import { AvatarWithRemove } from "../../../../../common/avatar";
import { DocumentSetData } from "../../../../index";
import { getInitialsFromName } from "../../../../../user";
import {
  determineNonErrorStatus,
  Status,
} from "../../../../../common/status/status";
import useActiveDocumentSet from "../../../../hooks/useActiveDocumentSet";
import { useAppSelector } from "../../../../../app";
import { canUnassignUser } from "../../../../utils/helperFunctions";
import useUpdateDocumentSet from "../../../../hooks/useUpdateDocumentSet";

type Props = {
  documentSet: DocumentSetData;
};

const AssignedCell: FC<Props> = ({ documentSet }) => {
  const { updateAssigneeAndStatusWithExceptionHandling } =
    useActiveDocumentSet();
  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const updateDocumentSets = useUpdateDocumentSet();

  const unassignUser = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (canUnassignUser(documentSet, isAnnotationMode)) {
        const newStatus = determineNonErrorStatus(
          documentSet.status,
          isAnnotationMode ? Status.AnnotationToDo : Status.ToDo
        );

        updateAssigneeAndStatusWithExceptionHandling(
          documentSet!,
          newStatus,
          undefined,
          false
        ).then(() => {
          const updatedDocumentSet = {
            ...documentSet,
            assignee: undefined,
            status: newStatus,
          };

          updateDocumentSets(updatedDocumentSet);
        });
      }
    },
    [
      documentSet,
      updateAssigneeAndStatusWithExceptionHandling,
      updateDocumentSets,
      isAnnotationMode,
    ]
  );

  if (documentSet?.assignee?.firstName && documentSet?.assignee?.lastName) {
    const { assignee } = documentSet;

    return (
      <AvatarWithRemove
        showRemove={canUnassignUser(documentSet, isAnnotationMode)}
        onClick={unassignUser}
        size={32}
        tooltip={`${assignee.firstName} ${assignee.lastName}`}
        src={
          assignee?.avatar ||
          getInitialsFromName(assignee?.firstName, assignee?.lastName)
        }
        fontSize={13}
      />
    );
  }

  return <div className="avatar__placeholder" />;
};

export default memo(AssignedCell);

import { useEffect, useRef } from "react";
import { CategorizationWithConfig } from "../../configMap/interfaces/category";
import { BaseSelectRef } from "rc-select";
import { useAppSelector } from "../../app";
import useCategorization from "../../metadata/hooks/useCategorization";

const useCategorizationCell = (
  categorizationWithConfig: CategorizationWithConfig,
  index: number,
  groupBlockId?: string
) => {
  const selectRef = useRef<BaseSelectRef | null>(null);

  const { activeButton, isAnnotating } = useAppSelector(
    (state) => state.appReducer
  );

  const {
    tabId,
    handleChange,
    selectedCategory,
    options,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
  } = useCategorization(categorizationWithConfig, index, groupBlockId);

  useEffect(() => {
    if (!activeButton || !selectRef.current) {
      return;
    }

    if (activeButton.id !== tabId) {
      return;
    }

    selectRef.current!.focus();
  }, [activeButton, tabId]);

  useEffect(() => {
    if (isAnnotating) {
      selectRef.current!.blur();
    }
  }, [isAnnotating]);

  return {
    handleChange,
    selectedCategory,
    options,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
    selectRef,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
  };
};

export default useCategorizationCell;

import React, { FC } from "react";
import { Translation } from "../../../../configMap";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import { Tooltip } from "antd";

type Props = {
  translations: Translation;
  tooltip: Translation | null;
};

const FilterItemLabel: FC<Props> = ({ translations, tooltip }) => {
  const {
    i18n: { language },
  } = useTranslation();
  if (tooltip) {
    return (
      <label className="filter-item__label">
        {getInLanguageOrDefault(translations, language)}
        <Tooltip title={getInLanguageOrDefault(tooltip, language)}>
          <i className="bi bi-question-circle" />
        </Tooltip>
      </label>
    );
  }

  return (
    <label className="filter-item__label">
      {getInLanguageOrDefault(translations, language)}
    </label>
  );
};

export default FilterItemLabel;

import React, { FC } from "react";
import { ITable } from "../../../../interfaces/ITable";
import useTableBorder from "../../../../hooks/useTableBorder";

interface Props {
  type: "top" | "left";
  style: any;
  table: ITable;
  isCreatingTable: boolean;
}

const TableBorder: FC<Props> = ({ type, style, table, isCreatingTable }) => {
  const { ref, iconLeft, iconTop, addTableDetails } = useTableBorder(
    type,
    style,
    table,
    isCreatingTable
  );

  return (
    <div ref={ref} className="table-builder__border" style={{ ...style }}>
      <i
        className="bi-plus table-builder__border-icon"
        style={{ top: iconTop, left: iconLeft }}
        onClick={() => addTableDetails()}
      />
    </div>
  );
};

export default TableBorder;

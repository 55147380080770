import React, { FC, useMemo, useRef } from "react";
import { ITable } from "../../../../interfaces/ITable";
import TableRow from "./TableRow";
import useMouse from "@react-hook/mouse-position";
import TableColumn from "./TableColumn";
import ExcludeRowButton from "./ExcludeRowButton";
import ExcludedRow from "./ExcludedRow";
import useAnnotatorConfig from "../../../../hooks/useAnnotatorConfig";

interface Props {
  isCreatingTable: boolean;
  table: ITable;
  style: any;
}

const TableBody: FC<Props> = ({ isCreatingTable, table, style }) => {
  const { scale } = useAnnotatorConfig();

  const ref = useRef(null);
  const { x, y } = useMouse(ref);

  const rowString = useMemo(() => {
    const { rows } = table;

    return rows.map(({ height }) => `${height}px`).join(" ");
  }, [table]);

  const colString = useMemo(() => {
    const { columns } = table;

    return columns.map(({ width }) => `${width}px`).join(" ");
  }, [table]);

  return (
    <div
      ref={ref}
      className="table-builder__table"
      style={{
        ...style,
        top: table.y,
        left: table.x,
        width: table.width,
        height: table.height,
        gridTemplateRows: rowString,
        gridTemplateColumns: colString,
      }}
    >
      {table.rows.map((row) => (
        <React.Fragment key={`${row.id}-${row.y}-${table.id}-${scale}`}>
          <ExcludeRowButton
            tableId={table.id}
            rowId={row.id}
            excluded={row.exclude}
            isCreatingTable={isCreatingTable}
            top={row.y}
          />
          <ExcludedRow
            excluded={row.exclude}
            top={row.y}
            height={row.height}
            width={table.width}
          />
          <TableRow
            isCreatingTable={isCreatingTable}
            tableId={table.id}
            id={row.id}
            top={row.y}
            width={table.width}
            mouseLoc={y!}
            isHiddenRow={row.y === 0}
          />
        </React.Fragment>
      ))}
      {table.columns.map((column) => (
        <TableColumn
          key={`${column.id}-${column.x}-${table.id}-${scale}`}
          isCreatingTable={isCreatingTable}
          tableId={table.id}
          id={column.id}
          left={column.x}
          height={table.height}
          mouseLoc={x!}
          isHiddenColumn={column.x === 0}
        />
      ))}
    </div>
  );
};
export default TableBody;

import { Translation } from "../../configMap";
import { OverviewType } from "../../app";

export enum FilterComponentType {
  INPUT = "INPUT",
  SELECT = "SELECT",
  API_SELECT = "API_SELECT",
  DATE = "DATE",
}

export interface KeyByAny {
  [key: string]: any;
}

export interface ColumnItem {
  key: string;
  generic: boolean;
  archiveOnly: boolean;
  translations: Translation | null;
  metadata: KeyByAny | null;
  defaultIndex: number;
}

export interface OverviewConfig {
  columnItems: Array<ColumnItem>;
}

export enum CustomCellType {
  TEXT,
  COPYABLE,
}

export interface OrderColumnItem {
  key: string;
  title: string;
  show: boolean;
}

export interface ChangeOverviewType {
  overviewType: OverviewType;
  hasUserInputs: boolean;
}

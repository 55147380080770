import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect } from "react";
import { GroupBlockData } from "../../configMap";
import { HighlightedEntity } from "../interfaces/annotation";
import { setHighlightedEntities } from "../store/annotationSlice";
import useKeyPressedListener from "../../annotator/hooks/useKeyPressedListener";
import selectHighlightedIndex from "../selectors/selectHighlightedIndex";

const useHighlightEntities = () => {
  const dispatch = useAppDispatch();
  const keyPressed = useKeyPressedListener("ESCAPE");

  const highlightedIndex = useAppSelector(selectHighlightedIndex);

  const highlightEntitiesInGroupBlock = useCallback(
    (index: number, groupBlock: GroupBlockData) => {
      if (index === highlightedIndex) {
        dispatch(setHighlightedEntities([]));
        return;
      }

      const entitiesToHighlight = groupBlock.groupBlockEntityTypes.map(
        (e): HighlightedEntity => ({
          id: e.entityType.id,
          index,
        })
      );

      dispatch(setHighlightedEntities(entitiesToHighlight));
    },
    [dispatch, highlightedIndex]
  );

  const resetHighlightedEntities = useCallback(() => {
    dispatch(setHighlightedEntities([]));
  }, [dispatch]);

  useEffect(() => {
    if (keyPressed) {
      resetHighlightedEntities();
    }
  }, [keyPressed, resetHighlightedEntities]);

  return {
    highlightEntitiesInGroupBlock,
    resetHighlightedEntities,
  };
};

export default useHighlightEntities;

import React, { FC } from "react";
import { Select, Tooltip } from "antd";
import FilterItemLabel from "../metricsFilterItem/FilterItemLabel";
import { useTranslation } from "react-i18next";
import useSourceFilter from "../../../hooks/useSourceFilter";

type Props = {
  loading: boolean;
};

const SourceFilter: FC<Props> = ({ loading }) => {
  const { t } = useTranslation("overview");

  const { inputIds, inputTypesSelectOptions, handleChange } = useSourceFilter();

  return (
    <div className="filter-item__container">
      <FilterItemLabel
        translations={{ en: "Source", fr: "Source", nl: "Bron" }}
        tooltip={null}
      />
      <div className="filter-item">
        <Select
          className="filter-item__select"
          maxTagCount={2}
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              title={omittedValues.map((value) => value.label).join(", ")}
            >
              +{omittedValues.length}
            </Tooltip>
          )}
          mode={"multiple"}
          value={inputIds}
          options={inputTypesSelectOptions}
          placeholder={`${t("filters.placeholder")} ${t(
            "filters.source"
          ).toLowerCase()}`}
          onChange={handleChange}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default SourceFilter;

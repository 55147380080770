import React, { FC } from "react";
import { Layout } from "antd";
import useHeaderText from "../../hooks/useHeaderText";
import MetricsModeSelector from "./MetricsModeSelector/MetricsModeSelector";

const AnalyticsHeader: FC = () => {
  const { headerTitle } = useHeaderText();

  return (
    <Layout.Header className="header__container">
      <div className="header-title">{headerTitle}</div>
      <MetricsModeSelector />
    </Layout.Header>
  );
};

export default AnalyticsHeader;

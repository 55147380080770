import React, { FC } from "react";

const SelectEmptyState: FC = () => {
  return (
    <div className="select__empty-state">
      Type 3 or more letters to search...
    </div>
  );
};

export default SelectEmptyState;

import React, { FC } from "react";
import { Button, Input, Select } from "antd";
import useAddStpSupplier from "../../../../hooks/useAddStpSupplier";
import "./StpSupplierTableFooterAdd.scss";
import { useTranslation } from "react-i18next";

type Props = {
  handleCancel: (isAdding: boolean) => void;
};

const StpSupplierTableFooterAdd: FC<Props> = ({ handleCancel }) => {
  const { t } = useTranslation("stp");

  const {
    supplierId,
    vat,
    source,
    handleChange,
    handleSourceChange,
    handleClick,
    sourceOptions,
  } = useAddStpSupplier();

  return (
    <div className="stp-supplier-footer-add__container">
      <div className="stp-supplier-footer-add__spacer" />
      <div className="stp-supplier-footer-add__content">
        <div>
          <Input
            name="id"
            placeholder="ID"
            value={supplierId}
            onChange={handleChange}
            style={{ width: "200px" }}
          />
        </div>
        <div>
          <Input
            name="vat"
            placeholder={t("suppliers.vat")}
            value={vat}
            onChange={handleChange}
            style={{ width: "200px" }}
          />
        </div>
        <div>
          <Select
            placeholder={t("suppliers.source")}
            value={source}
            onChange={handleSourceChange}
            options={sourceOptions}
            style={{ width: "200px" }}
            allowClear={true}
          />
        </div>
        <div>
          <Button
            size="small"
            type="text"
            onClick={() => handleClick(handleCancel)}
          >
            {t("suppliers.add")}
          </Button>
          <Button onClick={() => handleCancel(false)} size="small" type="text">
            {t("suppliers.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StpSupplierTableFooterAdd;

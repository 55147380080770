import { Invite, OrganizationResponse } from "../interfaces/table";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";
import { setOrganizationResponse } from "./UserManagementSlice";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const userManagementApi = createApi({
  reducerPath: "userManagementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/user/management`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    fetchMembers: build.query<OrganizationResponse, string>({
      query: (organizationId) => ({
        url: `/${organizationId}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setOrganizationResponse(data));
        } catch (err: any) {
          console.error(
            `An error occurred while fetching the members: ${parseReduxQueryError(
              err
            )}`
          );
          dispatch(
            setOrganizationResponse({ members: [], inputs: [], roles: [] })
          );
        }
      },
    }),
    fetchInvites: build.query<Array<Invite>, string>({
      query: (organizationId) => ({
        url: `/${organizationId}/invites`,
        method: "GET",
      }),
    }),
    inviteMember: build.mutation<
      void,
      {
        organizationId: string;
        clientId: string;
        email: string;
        permissions: Array<string>;
      }
    >({
      query: ({ organizationId, clientId, email, permissions }) => ({
        url: `/${organizationId}/member/invite`,
        body: {
          clientId,
          memberEmail: email,
          permissions,
        },
        method: "POST",
      }),
    }),
    deleteInvite: build.mutation<
      void,
      { organizationId: string; inviteId: string; email: string }
    >({
      query: ({ organizationId, inviteId, email }) => ({
        url: `/${organizationId}/invites`,
        body: {
          inviteId,
          email,
        },
        method: "POST",
      }),
    }),
    deleteUserFromOrgApi: build.mutation<
      void,
      { organizationId: string; memberId: string }
    >({
      query: ({ organizationId, memberId }) => ({
        url: `/${organizationId}/member/${encodeURIComponent(memberId)}`,
        method: "DElETE",
      }),
    }),
    deleteRoleApi: build.mutation<
      void,
      { organizationId: string; memberId: string; data: any }
    >({
      query: ({ organizationId, memberId, data }) => ({
        url: `/${organizationId}/member/${encodeURIComponent(memberId)}/roles`,
        body: data,
        method: "DElETE",
      }),
    }),
    addRoleApi: build.mutation<
      void,
      { organizationId: string; memberId: string; data: any }
    >({
      query: ({ organizationId, memberId, data }) => ({
        url: `/${organizationId}/member/${encodeURIComponent(memberId)}/roles`,
        body: data,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useFetchMembersQuery,
  useLazyFetchInvitesQuery,
  useInviteMemberMutation,
  useDeleteInviteMutation,
  useDeleteUserFromOrgApiMutation,
  useDeleteRoleApiMutation,
  useAddRoleApiMutation,
} = userManagementApi;

import React, { FC } from "react";
import useTableUpdater from "../../../../hooks/useTableUpdater";

type Props = {
  visible: boolean;
  tableId: string;
  top: number;
  left: number;
};

const LinkTableButton: FC<Props> = ({ visible, tableId, top, left }) => {
  const { linkTables } = useTableUpdater();

  if (!visible) {
    return null;
  }

  return (
    <div
      className="link-table-button__container"
      style={{ top, left }}
      onClick={() => linkTables(tableId)}
    >
      <i className="bi bi-link-45deg link-table-button__icon" />
    </div>
  );
};

export default LinkTableButton;

import { useCallback } from "react";
import useAnnotationUpdater from "./useAnnotationUpdater";
import { useAppSelector } from "../../app";
import tempAnnotationSelector from "../../annotation/selectors/tempAnnotationSelector";

const useTempAnnotationOverview = () => {
  const { removeAnnotation, removeAnnotationsInBulk, updateAnnotationsInBulk } =
    useAnnotationUpdater();

  const tempAnnotations = useAppSelector(tempAnnotationSelector);

  const scrollAnnotationIntoView = useCallback((id: string) => {
    const elem = document.getElementById(`annotation-${id}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, []);

  const deleteAnnotations = useCallback(() => {
    removeAnnotationsInBulk(tempAnnotations, true);
  }, [tempAnnotations, removeAnnotationsInBulk]);

  const acceptAnnotations = useCallback(() => {
    const toUpdateAnnotations = [...tempAnnotations].map((a) => ({
      ...a,
      tempAnnotation: false,
    }));
    updateAnnotationsInBulk(toUpdateAnnotations, true);
  }, [tempAnnotations, updateAnnotationsInBulk]);

  return {
    tempAnnotations,
    scrollAnnotationIntoView,
    deleteAnnotations,
    acceptAnnotations,
    removeAnnotation,
  };
};

export default useTempAnnotationOverview;

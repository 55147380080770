import React, { FC } from "react";

type Props = {
  fill?: string;
};

const EditIcon: FC<Props> = ({ fill = "#ffffff" }: Props) => {
  return (
    <svg
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className="edit"
    >
      <path
        d="M13.1503 1.14991C12.5503 0.449912 11.6503 0.0999119 10.7003 0.199912C10.0503 0.249912 9.45031 0.599912 8.95031 1.09991L2.00031 7.99991C1.70031 8.29991 1.45031 8.69991 1.30031 9.09991L0.200313 12.2999C0.0503125 12.7499 0.150312 13.1999 0.500312 13.4999C0.700312 13.6999 1.00031 13.8499 1.30031 13.8499C1.45031 13.8499 1.55031 13.8499 1.70031 13.7999L4.90031 12.6999C5.30031 12.5499 5.70031 12.2999 6.00031 11.9999L12.9503 5.04991C14.0003 3.99991 14.0503 2.19991 13.1503 1.14991ZM1.35031 12.8499C1.25031 12.8999 1.20031 12.8499 1.20031 12.7999C1.15031 12.7499 1.15031 12.6999 1.15031 12.6499L2.15031 9.74991L4.25031 11.8499L1.35031 12.8499ZM12.2003 4.34991L5.25031 11.2999C5.20031 11.3499 5.20031 11.3499 5.15031 11.3499L2.60031 8.79991C2.60031 8.74991 2.65031 8.74991 2.65031 8.69991L9.65031 1.79991C10.0003 1.44991 10.4003 1.24991 10.8003 1.19991C11.4003 1.14991 12.0003 1.34991 12.4003 1.79991C13.0003 2.49991 12.9003 3.69991 12.2003 4.34991Z"
        fill={fill}
      />
    </svg>
  );
};

export default EditIcon;

import React, { FC } from "react";
import { Button, Table, TableColumnsType, Tag } from "antd";
import {
  GroupBlockData,
  GroupBlockEntityType,
  Translation,
} from "../../../../configMap";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import ConfigmapEntitiesTable from "./ConfigmapEntitiesTable";

type Props = {
  groupBlockData: Array<GroupBlockData>;
};

interface GroupBlockTableData {
  key: string;
  id: string;
  translations: Translation;
  multipleGroupBlocks: boolean;
  groupBlockEntityTypes: Array<GroupBlockEntityType>;
}

const ConfigmapGroupBlockTable: FC<Props> = ({ groupBlockData }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const columns: TableColumnsType<GroupBlockTableData> = [
    {
      title: "Name",
      key: "translations",
      render: (_, { translations }) =>
        `${getInLanguageOrDefault(
          translations,
          language,
          "No translations set"
        )}`,
    },
    {
      title: "Multiple instances",
      key: "multipleGroupBlocks",
      render: (_, record) => {
        if (record.multipleGroupBlocks) {
          return <Tag color="blue">Multiple instances</Tag>;
        }
        return <Tag color="cyan">Single instance</Tag>;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      render: () => (
        <>
          <Button type="text" icon={<span className="bi bi-globe" />} />
          <Button danger type="text" icon={<span className="bi bi-trash" />} />
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowRender: ({ groupBlockEntityTypes }) => (
          <ConfigmapEntitiesTable
            entitiesData={(groupBlockEntityTypes ?? [])
              .sort((a, b) => a.index - b.index)
              .map((r) => ({
                ...r,
                key: r.entityType.id,
              }))}
            language={language}
          />
        ),
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <span
              className="bi bi-arrows-collapse group__expand"
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <span
              className="bi bi-arrows-expand group__expand"
              onClick={(e) => onExpand(record, e)}
            />
          ),
        defaultExpandedRowKeys: [groupBlockData[0]?.id],
      }}
      dataSource={groupBlockData.map((r) => ({ ...r, key: r.id }))}
      size="small"
      pagination={false}
      footer={() => (
        <div className="footer__add-button">
          <Button type="text" icon={<span className="bi bi-plus-square" />}>
            Add group block
          </Button>
        </div>
      )}
    />
  );
};

export default ConfigmapGroupBlockTable;

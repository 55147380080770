export const arrayBufferToBase64 = (buffer: ArrayBuffer): string =>
  btoa(
    new Uint8Array(buffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );

export const base64ToArrayBuffer = (base64: string): ArrayBufferLike => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

export const convertBufferToString = (
  arrayBuffer: ArrayBufferLike | null
): string => {
  if (!arrayBuffer) {
    return "";
  }

  const enc = new TextDecoder();
  return enc.decode(arrayBuffer);
};

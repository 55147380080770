import React, { FC } from "react";
import { Drawer } from "antd";
import "./content/detailsContent/DetailsContent.scss";
import { SIDE_CONTROLS_WIDTH } from "../../constants";
import ContentSwitch from "./content/ContentSwitch";
import "./SideDrawer.scss";
import useSideDrawer from "../../hooks/useSideDrawer";

type Props = {
  height: number;
};

const SideDrawer: FC<Props> = ({ height }) => {
  const { content, loading, title, closeDrawer, isDrawerOpen, drawerType } =
    useSideDrawer();

  return (
    <Drawer
      className="drawer-container"
      styles={{ wrapper: { height: `${height}px` } }}
      getContainer=".side-controls"
      width={SIDE_CONTROLS_WIDTH}
      mask={false}
      title={title}
      open={isDrawerOpen}
      onClose={closeDrawer}
      loading={loading}
    >
      <ContentSwitch content={content} drawerType={drawerType} />
    </Drawer>
  );
};

export default SideDrawer;

import React, { FC } from "react";
import OverviewHeader from "./OverviewHeader/OverviewHeader";
import HeaderWithTitle from "./HeaderWithTitle/HeaderWithTitle";
import HeaderWithChildren from "./headerWithChildren/HeaderWithChildren";
import "./HeaderSwitch.scss";
import AnalyticsHeader from "./AnalyticsHeader/AnalyticsHeader";
import AddEmailTemplateButton from "../../../emailTemplates/components/button/AddEmailTemplateButton";
import DetailDocumentPicker from "../../../app/pages/detailView/detailDocumentPicker/DetailDocumentPicker";

export enum HeaderType {
  Overview,
  Title,
  UserManagement,
  Analytics,
  EmailTemplates,
  Details,
}

type Props = {
  headerType: HeaderType;
};

const HeaderSwitch: FC<Props> = ({ headerType }) => {
  switch (headerType) {
    case HeaderType.Overview:
      return <OverviewHeader />;
    case HeaderType.Analytics:
      return <AnalyticsHeader />;
    case HeaderType.EmailTemplates:
      return (
        <HeaderWithChildren>
          <AddEmailTemplateButton />
        </HeaderWithChildren>
      );
    case HeaderType.Details:
      return (
        <HeaderWithChildren>
          <DetailDocumentPicker />
        </HeaderWithChildren>
      );
    default:
      return <HeaderWithTitle />;
  }
};

export default HeaderSwitch;

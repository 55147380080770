import React, { FC } from "react";
import { useAppSelector } from "../../hooks/hooks";
import "./DocumentTypeViewer.scss";
import { determineExtension } from "../../../document/utils/extension";
import { ExtensionType } from "../../../document/interfaces";
import XmlViewer from "../../../document/components/xmlViewer/XmlViewer";
import Annotator from "../../../annotator";
import PDFSkeleton from "./pdfSkeleton/PDFSkeleton";
import TextViewer from "../../../document/components/textViewer/TextViewer";
import CsvViewer from "../../../document/components/csvViewer/CsvViewer";
import LegacyPdfViewer from "../../../document/components/legacyPdfViewer/LegacyPdfViewer";

const DocumentTypeViewer: FC = () => {
  const activeDocument = useAppSelector(
    (state) => state.documentReducer.activeDocument
  );
  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  switch (determineExtension(activeDocument, activeDocumentSet)) {
    case ExtensionType.CSV:
      return <CsvViewer />;
    case ExtensionType.XML:
      return <XmlViewer />;
    case ExtensionType.PDF:
      return <Annotator />;
    case ExtensionType.PDF_LEGACY:
      return <LegacyPdfViewer />;
    case ExtensionType.LOADING:
      return (
        <div className="detail-content">
          <PDFSkeleton />
        </div>
      );
    default:
      return <TextViewer />;
  }
};

export default DocumentTypeViewer;

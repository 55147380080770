import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import useDocumentSets from "../hooks/useDocumentSets";
import { Skeleton, Table, Tooltip } from "antd";
import usePagination from "../hooks/usePagination";
import { OverviewType, useAppSelector } from "../../app";
import { useSorting } from "../../common/dataRefining";
import { DocumentSetData, PAGE_OFFSET } from "../index";
import AlertRenderer from "./alertRenderer/AlertRenderer";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import DocumentPill from "./tableBody/tableCellComponents/documentPillCell/DocumentPill";
import AssignedCell from "./tableBody/tableCellComponents/assignedCell/AssignedCell";
import StatusCell from "./tableBody/tableCellComponents/statusCell/StatusCell";
import RemoveCellConditionalWrapper from "./tableBody/RemoveCellConditionalWrapper";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import useTable from "../hooks/useTable";
import "./tableHeader/TableHeader.scss";
import "./DocumentSetTable.scss";
import { Translation } from "../../configMap";
import { useTranslation } from "react-i18next";
import CustomCellSwitch from "./tableBody/tableCellComponents/customCell/CustomCellSwitch";
import {
  getLocaleByLanguage,
  getValidLocaleForBrowser,
} from "../../common/utilities/locale";
import { TourPage } from "../../tours/hooks/useTour";
import useStartTour from "../../tours/hooks/useStartTour";
import useOrderColumns from "../hooks/useOrderColumns";
import Pager from "./pager/Pager";
import BulkActionAlert from "./bulkActionAlert/BulkActionAlert";
import OrderColumnsPopover from "./tableHeader/orderColumns/OrderColumnsPopover";
import BulkActionCheckboxCell from "./tableBody/tableCellComponents/bulkActionCheckboxCell/BulkActionCheckboxCell";
import useOverviewTableDynamicHeight from "../hooks/useOverviewTableDynamicHeight";
import TableHeaderFilter from "./tableHeader/TableHeaderFilter/TableHeaderFilter";
import BulkActionHeader from "./tableHeader/bulkActionHeader/BulkActionHeader";
import NoSourcesAlert from "./noSourcesAlert/NoSourcesAlert";
import TableColumnContentLoader from "../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";
import { SkeletonLoaderType } from "../../common/skeletonLoader/interfaces/type";
import useSetFilterStringOnInit from "../hooks/useSetFilterStringOnInit";
import FetchingErrorAlert from "./fetchingErrorAlert/FetchingErrorAlert";

type Props = {
  overviewType: OverviewType;
};

const parseTitle = (
  translations: Translation | null,
  language: string,
  hideTitle?: boolean
): string => {
  if (hideTitle) {
    return "";
  }

  if (!translations) {
    return "";
  }

  if (typeof translations === "object") {
    return getInLanguageOrDefault(translations, language);
  }

  return "";
};

const DocumentSetTable: FC<Props> = ({ overviewType }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const skippedFirst = useRef(false);

  const { activeSorter, getSortOrderForKey } = useSorting(overviewType);
  const { currentPage, handlePageChange, pageable } = usePagination();
  const { handleRowSelect, getClassNameForRow, handleSortClicked } =
    useTable(overviewType);

  const { tableItems } = useOrderColumns();

  const { userSettings } = useAppSelector((state) => state.userReducer);

  const { documentSets, documentSetRows, numberOfPages, loading } =
    useDocumentSets({
      overviewType,
      sorters: activeSorter,
      pageable,
    });

  useSetFilterStringOnInit(overviewType);

  const { refHeightContainer, heightTableContent } =
    useOverviewTableDynamicHeight(overviewType);

  useStartTour({
    tourPage: TourPage.OVERVIEW,
    triggerStartTour: userSettings.showOverviewTour && !loading,
    dependencies: [userSettings.showOverviewTour, loading],
  });

  const [browserLocale, setBrowserLocale] = useState<string>(
    getLocaleByLanguage(userSettings.language)
  );

  useEffect(() => {
    const setLocale = async () => {
      const locale = await getValidLocaleForBrowser(
        getLocaleByLanguage(userSettings.language)
      );
      setBrowserLocale(locale);
    };

    setLocale();
  }, [userSettings.language]);

  useEffect(() => {
    if (!skippedFirst.current) {
      skippedFirst.current = true;
      return;
    }

    if (currentPage > numberOfPages - PAGE_OFFSET) {
      handlePageChange(numberOfPages - PAGE_OFFSET);
    }
  }, [numberOfPages, currentPage, handlePageChange]);

  const defaultColumns = useMemo(
    (): ColumnsType<DocumentSetData> => [
      {
        title: <BulkActionHeader documentSets={documentSets} />,
        dataIndex: "bulkDelete",
        key: "bulkDelete",
        width: "40px",
        render: (_, documentSet) => (
          <TableColumnContentLoader loading={loading}>
            <BulkActionCheckboxCell documentSet={documentSet} />
          </TableColumnContentLoader>
        ),
      },
      {
        title: <OrderColumnsPopover loading={loading} />,
        dataIndex: "order",
        key: "order",
        width: "50px",
        render: (_, documentSet) => (
          <TableColumnContentLoader loading={loading}>
            {null}
          </TableColumnContentLoader>
        ),
      },
      {
        title: "Source",
        dataIndex: "input",
        key: "input",
        render: (_, { input }) => (
          <TableColumnContentLoader loading={loading}>
            <Tooltip placement="top" title={input?.displayName}>
              <label className="source-cell__label">{input?.displayName}</label>
            </Tooltip>
          </TableColumnContentLoader>
        ),
        filterIcon: <i className="bi bi-inbox title-icon" />,
      },
      {
        title: "Date",
        dataIndex: "createdDate",
        key: "createdDate",
        render: (_, { createdDate }) => (
          <TableColumnContentLoader loading={loading}>
            <label className="document-name-cell__label">
              {dayjs(createdDate).locale(browserLocale).format("L LT")}
            </label>
          </TableColumnContentLoader>
        ),
        sorter: true,
        showSorterTooltip: false,
        defaultSortOrder: getSortOrderForKey("createdDate"),
        sortOrder: getSortOrderForKey("createdDate"),
        filterIcon: <i className="bi bi-calendar title-icon" />,
      },
      {
        title: "Approved",
        dataIndex: "approvedDate",
        key: "approvedDate",
        render: (_, { approvedDate }) => (
          <TableColumnContentLoader loading={loading}>
            <label className="document-name-cell__label">
              {dayjs(approvedDate).locale(browserLocale).format("L LT")}
            </label>
          </TableColumnContentLoader>
        ),
        sorter: true,
        showSorterTooltip: false,
        defaultSortOrder: getSortOrderForKey("approvedDate"),
        sortOrder: getSortOrderForKey("approvedDate"),
        filterIcon: <i className="bi bi-calendar title-icon" />,
      },
      {
        title: "Document type",
        dataIndex: "documents",
        key: "documents",
        render: (_, { documents }) => (
          <TableColumnContentLoader loading={loading}>
            <DocumentPill documents={documents!} />
          </TableColumnContentLoader>
        ),
        filterIcon: <i className="bi bi-file-earmark title-icon" />,
      },
      {
        title: "",
        dataIndex: "assignee",
        key: "assignee",
        render: (_, documentSet) => (
          <TableColumnContentLoader
            loading={loading}
            type={SkeletonLoaderType.AVATAR}
          >
            <AssignedCell documentSet={documentSet} />
          </TableColumnContentLoader>
        ),
        width: "80px",
        filterIcon: <i className="bi bi-person title-icon" />,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_, { status }) => (
          <TableColumnContentLoader loading={loading}>
            <StatusCell status={status} />
          </TableColumnContentLoader>
        ),
        width: "10vw",
        filterIcon: <i className="bi bi-info-circle title-icon" />,
      },
      {
        title: "",
        dataIndex: "remove",
        key: "remove",
        render: (_, documentSet) => (
          <TableColumnContentLoader loading={loading}>
            <RemoveCellConditionalWrapper
              overviewType={overviewType}
              documentSet={documentSet}
            />
          </TableColumnContentLoader>
        ),
        width: "80px",
      },
    ],
    [overviewType, browserLocale, documentSets, getSortOrderForKey, loading]
  );

  const filteredColumns = useMemo(
    () =>
      tableItems.map((item) => {
        const { metadata, translations } = item;
        const found = defaultColumns.find((dc) => item.key === dc.key);
        if (found) {
          return {
            ...found,
            title: (
              <div className="overview-table__header-cell">
                <div className="cell-title">
                  <>
                    {found.filterIcon}
                    {parseTitle(translations, language, metadata?.hideTitle) ||
                      found.title}
                  </>
                </div>
                {item.metadata?.filterAble && (
                  <TableHeaderFilter
                    overviewType={overviewType}
                    columnItem={item}
                    loading={loading}
                  />
                )}
              </div>
            ),
          };
        }

        return {
          title: (
            <div className="overview-table__header-cell">
              <div className="cell-title">
                {parseTitle(item.translations, language)}
              </div>
              {item.metadata?.filterAble && (
                <TableHeaderFilter
                  overviewType={overviewType}
                  columnItem={item}
                  loading={loading}
                />
              )}
            </div>
          ),
          dataIndex: item.key,
          key: item.key,
          sorter: item.metadata?.sortAble,
          showSorterTooltip: false,
          defaultSortOrder: getSortOrderForKey(item.key),
          sortOrder: getSortOrderForKey(item.key),
          render: (_: any, documentSet: DocumentSetData) =>
            loading ? (
              <Skeleton title active={true} paragraph={false} />
            ) : (
              <CustomCellSwitch documentSet={documentSet} columnItem={item} />
            ),
        };
      }),
    [
      getSortOrderForKey,
      defaultColumns,
      language,
      tableItems,
      overviewType,
      loading,
    ]
  );

  return (
    <div className="overview__container" ref={refHeightContainer}>
      <FetchingErrorAlert />
      <NoSourcesAlert />
      <AlertRenderer overviewType={overviewType} />
      <BulkActionAlert />
      <Table
        pagination={false}
        columns={filteredColumns}
        onRow={(record) => {
          return {
            onClick: () => !loading && handleRowSelect(record),
          };
        }}
        onChange={(_, __, sorter) => {
          //@ts-ignore
          handleSortClicked(sorter.columnKey);
        }}
        dataSource={documentSetRows}
        rowClassName={(documentSet) => getClassNameForRow(documentSet)}
        className="table-overview__container"
        id="tour-overview-step-1"
        scroll={{ y: heightTableContent }}
        rowKey={(record, index) => record?.id ?? index}
      />
      <Pager
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default DocumentSetTable;

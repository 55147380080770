// @ts-ignore
import humanizeDuration from "humanize-duration";

export const humanizeTime = (value: string, language: string): string => {
  const milliseconds = parseFloat(value) * 1000;
  return humanizeDuration(milliseconds, {
    language,
    fallbacks: ["en"],
    round: true,
  });
};

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
  delimiter: " ",
  spacer: "",
  round: true,
});

import React, { FC, useCallback } from "react";
import { Modal, Button, notification } from "antd";
import "./ConfirmLeaveModal.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { changeHasBeenEdited } from "../../../store/appSlice";
import { postData } from "../../../../configMap/utils/entityUtils";
import { calculateHideDelay } from "../../../../common/utilities/text";
import documentIdsSelector from "../../../../document/selectors/documentIdsSelector";
import selectAnnotationReducer from "../../../../annotation/selectors/annotationReducerSelector";

type Props = {
  open: boolean;
  setOpen(value: boolean): void;
  routeToOverview: () => void;
};

const ConfirmLeaveModal: FC<Props> = ({ open, setOpen, routeToOverview }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("detail");

  const annotations = useAppSelector(selectAnnotationReducer);

  const tableAnnotations = useAppSelector(
    (state) => state.annotationReducer.tableAnnotations
  );
  const { documentCategoryAnnotations } = useAppSelector(
    (state) => state.metadataReducer.categorizationState
  );
  const documentIds = useAppSelector(documentIdsSelector);

  const handleLeave = useCallback(
    async (save: boolean) => {
      setOpen(false);
      dispatch(changeHasBeenEdited(false));

      if (save) {
        await postData(
          documentIds,
          tableAnnotations,
          annotations,
          documentCategoryAnnotations
        );
        notification.success({
          message: "Success",
          description: t("modal.message"),
          duration: calculateHideDelay(t("modal.message")),
        });
      }

      routeToOverview();
    },
    [
      setOpen,
      dispatch,
      routeToOverview,
      documentIds,
      tableAnnotations,
      annotations,
      documentCategoryAnnotations,
      t,
    ]
  );

  return (
    <Modal
      onCancel={() => setOpen(false)}
      open={open}
      footer={[
        <Button key="leave" danger onClick={() => handleLeave(false)}>
          {t("modal.leave")}
        </Button>,
        <Button
          key="leaveSave"
          type="primary"
          onClick={() => handleLeave(true)}
        >
          {t("modal.leaveSave")}
        </Button>,
      ]}
    >
      <div className="confirm-modal__content-container">
        <i className="bi bi-exclamation-circle" style={{ color: "#faad14" }} />
        <h3>{t("modal.title")}</h3>
        <div />
        <p>{t("modal.description")}</p>
      </div>
    </Modal>
  );
};

export default ConfirmLeaveModal;

import React, { FC } from "react";
import { Select } from "antd";
import "./SelectDocumentType.scss";
import { useTranslation } from "react-i18next";
import useSelectDocumentType from "../hooks/useSelectDocumentType";

type Props = {
  disabled: boolean;
};

const SelectDocumentType: FC<Props> = ({ disabled }) => {
  const { t } = useTranslation("annotationSideControls");

  const { activeDocumentTypeId, changeDocumentType, documentTypes } =
    useSelectDocumentType();

  return (
    <div className="select-document-type__container">
      <label className="select-document-type__label">
        {t("groupBlocksHeader.documentTypeLabel")}:
      </label>
      <Select
        disabled={disabled}
        value={activeDocumentTypeId}
        className="select-document-type__selector"
        onChange={changeDocumentType}
        options={documentTypes.map((documentType) => ({
          value: documentType.id,
          label: documentType.name,
        }))}
      />
    </div>
  );
};

export default SelectDocumentType;

import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { ADMIN_ROLE_ID, getVariableFromEnv } from "../../common/utilities/env";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import selectModalStateByKey from "../../app/selectors/modalStateSelector";
import { changeModalStateForKey } from "../../app/store/appSlice";
import { useInviteMemberMutation } from "../store/UserManagementApi";

const modalKey = "userManagementAddMembers";

const useMemberModal = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("userManagement");

  const open = useAppSelector((state) =>
    selectModalStateByKey(state, { modalKey })
  );

  const { members } = useAppSelector((state) => state.userManagementReducer);
  const { user } = useAppSelector((state) => state.userReducer);

  const [inviteMember] = useInviteMemberMutation();

  const handleInviteMember = useCallback(
    async (email: string, permissions: Array<string>) => {
      if (!user?.access.includes(ADMIN_ROLE_ID)) {
        return;
      }

      if (!user?.organization.id) {
        return;
      }

      if (members.find((m) => m.email === email)) {
        return;
      }

      try {
        await inviteMember({
          organizationId: user.organization.id,
          clientId: getVariableFromEnv("AUTH0_CLIENT_ID"),
          email,
          permissions,
        }).unwrap();

        dispatch(changeModalStateForKey(modalKey));
        notification.success({
          message: t("addModal.successMessage.message"),
          description: t("addModal.successMessage.message").replace(
            "$MEMBER",
            email
          ),
        });
      } catch (e) {
        dispatch(changeModalStateForKey(modalKey));
        notification.error({
          message: t("addModal.errorMessage.message"),
          description: t("addModal.errorMessage.message"),
        });
        console.log(e);
      }
    },
    [user, members, t, dispatch, inviteMember]
  );

  const changeModalOpen = useCallback(() => {
    dispatch(changeModalStateForKey(modalKey));
  }, [dispatch]);

  return { open, changeModalOpen, handleInviteMember };
};

export default useMemberModal;

import React, { FC, MutableRefObject, useMemo, useRef } from "react";
import { Point } from "../../../interfaces/point";
import { getAccessibleTextColor } from "../../../../common/utilities/color";
import useCursorText from "../../../hooks/useCursorText";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app";
import activeEntityForAnnotatorSelector from "../../../selectors/activeEntityForAnnotatorSelector";

interface Props {
  mouseCoords: Point;
}

const OFFSET = 5;

const CursorText: FC<Props> = ({ mouseCoords }) => {
  const ref: MutableRefObject<HTMLSpanElement | null> = useRef(null);

  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const style = useMemo(() => {
    if (!entity || !ref.current) {
      return {};
    }

    return {
      left: `${mouseCoords.x + OFFSET}px`,
      top: `${mouseCoords.y + OFFSET}px`,
      backgroundColor: entity.color,
      color: getAccessibleTextColor(entity.color),
    };
  }, [entity, mouseCoords]);

  const value = useCursorText(entity);

  if (!entity) {
    return null;
  }

  if (mouseCoords.x + mouseCoords.y === 0) {
    return null;
  }

  return (
    <span className="cursor-text" ref={ref} style={style}>
      {value}
    </span>
  );
};

export default CursorText;

import { Entity } from "../interfaces/entity";
import { useMemo } from "react";

const useCursorText = (entity: Entity | null) => {
  return useMemo(() => {
    if (!entity) {
      return "";
    }

    if (entity.index) {
      return `${entity.name} #${entity.index}`;
    }

    return entity.name;
  }, [entity]);
};

export default useCursorText;

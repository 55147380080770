export const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

export const USER_LOCALSTORAGE_NAME = "user";

export const LANG_LOCALSTORAGE_NAME = "i18nextLng";

export const USER_SETTINGS_LOCALSTORAGE_NAME = "userSettings";

export const validLanguages = ["nl", "fr", "en"];

export const passwordValidations = [
  {
    name: "minLength",
    validation: /^.{8,}$/g,
  },
  {
    name: "numbers",
    validation: /\d/g,
  },
  {
    name: "lowerCase",
    validation: /[a-z]/g,
  },
  {
    name: "upperCase",
    validation: /[A-Z]/g,
  },
  {
    name: "specialChars",
    validation: /[!@#$%^&*]/g,
  },
];

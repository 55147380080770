import { MutableRefObject, useCallback, useMemo, useRef } from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import { ColumnItem } from "../interfaces/overview";
import { BaseSelectRef } from "rc-select";
import useFocus from "./useFocus";

const useApiFilterableItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean,
  isBlankEntriesChecked: boolean,
  blankValue: string,
  values: Array<string> | undefined
) => {
  const dispatch = useAppDispatch();

  const ref: MutableRefObject<null | BaseSelectRef> = useRef(null);

  const { open, setOpen } = useFocus(ref, isOpen);

  const selectValue = useMemo(() => {
    if (isBlankEntriesChecked) {
      return values?.filter((value) => value !== blankValue);
    }

    return values;
  }, [values, isBlankEntriesChecked, blankValue]);

  const handleChange = useCallback(
    (value: string | Array<string>) => {
      let updatedValues: Array<string> | string = value;
      if (isBlankEntriesChecked) {
        updatedValues = Array.isArray(value)
          ? [...value, blankValue]
          : [value, blankValue];
      }

      const updateCalls = buildFilterUpdateCalls(
        updatedValues,
        columnItem.metadata,
        overviewType
      );

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [
      dispatch,
      columnItem.metadata,
      overviewType,
      isBlankEntriesChecked,
      blankValue,
    ]
  );

  return {
    ref,
    selectValue,
    handleChange,
    open,
    setOpen,
  };
};

export default useApiFilterableItem;

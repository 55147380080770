import React from "react";

interface Props {
  showInput: boolean;
  setShowInput: (showInput: boolean) => void;
}

const AreaAnnotationToggle = ({ showInput, setShowInput }: Props) => {
  return (
    <>
      {showInput ? (
        <i
          className="bi bi-eye-fill area-annotation__toggle-icon"
          onClick={(event) => {
            event.stopPropagation();
            setShowInput(!showInput);
          }}
        />
      ) : (
        <i
          className="bi bi-eye-slash area-annotation__toggle-icon"
          onClick={(event) => {
            event.stopPropagation();
            setShowInput(!showInput);
          }}
        />
      )}
    </>
  );
};

export default AreaAnnotationToggle;

import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Input } from "antd";
import "./ValueInput.scss";
import { UserProfileState } from "../../../index";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  name: keyof UserProfileState;
  handleBlur: (key: string, value: string) => void;
};

const ValueInput: FC<Props> = ({ value, name, handleBlur }) => {
  const [state, setState] = useState(value);
  const inputRef = useRef<any>(null);

  const { t } = useTranslation("personalPage", {
    keyPrefix: "userSettings.userInfo",
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleEditingClick = useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, []);

  const onBlur = useCallback(() => {
    if (state !== value) {
      handleBlur(name, state);
    }

    setIsEditing(false);
  }, [name, state, value, handleBlur]);

  if (isEditing) {
    return (
      <div className="value-input__container">
        <Input
          ref={inputRef}
          name={name}
          value={state}
          onChange={(event) => setState(event.target.value)}
          onBlur={onBlur}
          onPressEnter={onBlur}
        />
      </div>
    );
  }

  return (
    <div className="value-input__container">
      <label>{value}</label>
      <Button type="link" onClick={handleEditingClick}>
        {t("changeButton")}
      </Button>
    </div>
  );
};

export default ValueInput;

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FilterableItemSwitch from "./FilterableItemSwitch";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import FilterItemLabel from "./FilterItemLabel";
import { MetricFilterItem } from "../../../interfaces/documentSetMetrics";

interface Props {
  filterItem: MetricFilterItem;
  loading: boolean;
}

const MetricsFilterItem: FC<Props> = ({ filterItem, loading }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("overview");

  return (
    <div>
      <FilterItemLabel
        translations={filterItem.translations!}
        tooltip={filterItem.metadata?.filterAble!.tooltip}
      />
      <FilterableItemSwitch
        filterItem={filterItem}
        filterComponentType={
          filterItem.metadata?.filterAble!.filterComponentType
        }
        placeholder={`${t("filters.placeholder")} ${getInLanguageOrDefault(
          filterItem.translations!,
          language
        ).toLowerCase()}`}
        options={filterItem.metadata?.filterAble!.options}
        mode={filterItem.metadata?.filterAble!.mode}
        loading={loading}
      />
    </div>
  );
};

export default MetricsFilterItem;

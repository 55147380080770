import { useCallback } from "react";
import { Annotation, AnnotationParams } from "../interfaces/annotation";
import { useAppSelector } from "../../app";
import {
  convertAnnotationsToAdd,
  convertToRemoveAnnotations,
} from "../helpers/annotationsConverter";
import useAnnotatorConfig from "./useAnnotatorConfig";
import useAnnotations from "../../annotation";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import flatEntityListSelector from "../../configMap/selectors/flatEntityListSelector";

const useAnnotationUpdater = () => {
  const annotations = useAppSelector(selectAnnotationReducer);
  const activeDocument = useAppSelector(
    (state) => state.documentReducer.activeDocument
  );

  const { updateAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const entityList = useAppSelector(flatEntityListSelector);

  const { readonly } = useAnnotatorConfig();

  const setAnnotations = useCallback(
    (annotations: Array<Annotation>, multipleGroupBlocks: boolean) => {
      if (readonly) {
        return;
      }

      updateAnnotations(annotations, multipleGroupBlocks);
    },
    [readonly, updateAnnotations]
  );

  const getAnnotationsForPage = useCallback(
    (page: number): Array<Annotation> => {
      return annotations.filter(
        (annotation: Annotation) => annotation.page === page
      );
    },
    [annotations]
  );

  const addAnnotation = useCallback(
    (annotation: AnnotationParams, tempAnnotations = false) => {
      if (readonly) {
        return;
      }

      const { updatedAnnotations, newAnnotation } = convertAnnotationsToAdd(
        annotation,
        annotations,
        entityList
      );

      setAnnotations(
        updatedAnnotations,
        newAnnotation?.multipleGroupBlocks ?? false
      );

      if (tempAnnotations) {
        return;
      }
    },
    [readonly, entityList, setAnnotations, annotations]
  );

  const addAnnotationsInBulk = useCallback(
    (
      annotationsInBulk: Array<AnnotationParams>,
      multipleGroupBlocks: boolean,
      tempAnnotations = false
    ) => {
      if (readonly) {
        return;
      }

      const filteredAnnotations = annotations.filter(
        (a) => a.multipleGroupBlocks === multipleGroupBlocks
      );

      let annotationsToAdd: Array<AnnotationParams> = [];
      for (const annotation of annotationsInBulk) {
        const { updatedAnnotations } = convertAnnotationsToAdd(
          annotation,
          filteredAnnotations,
          entityList
        );
        annotationsToAdd.push(...updatedAnnotations);
      }

      const uniqueAnnotations = [...new Set(annotationsToAdd)];
      setAnnotations(
        uniqueAnnotations as Array<Annotation>,
        multipleGroupBlocks
      );
    },
    [readonly, entityList, setAnnotations, annotations]
  );

  const updateAnnotation = useCallback(
    (annotation: Annotation, multipleGroupBlocks: boolean) => {
      if (readonly) {
        return;
      }

      const getUpdatedAnnotations = () =>
        [...annotations].map((prevAnnotation) => {
          if (prevAnnotation.id === annotation.id) {
            return annotation;
          }
          return prevAnnotation;
        });

      const updatedAnnotations = getUpdatedAnnotations();
      setAnnotations(updatedAnnotations, multipleGroupBlocks);
    },
    [readonly, annotations, setAnnotations]
  );

  const updateAnnotationsInBulk = useCallback(
    (annotationsToUpdate: Array<Annotation>, multipleGroupBlocks: boolean) => {
      if (readonly) {
        return;
      }

      const filteredAnnotations = annotations.filter(
        (a) => a.multipleGroupBlocks === multipleGroupBlocks
      );

      const getUpdatedAnnotations = () =>
        [...filteredAnnotations].map((prevAnnotation) => {
          const found = annotationsToUpdate.find(
            (a) => a.id === prevAnnotation.id
          );
          if (found) {
            return found;
          }
          return prevAnnotation;
        });

      const updatedAnnotations = getUpdatedAnnotations();
      setAnnotations(updatedAnnotations, multipleGroupBlocks);
    },
    [readonly, annotations, setAnnotations]
  );

  const updateLastAnnotationForEntity = useCallback(
    (annotation: AnnotationParams) => {
      if (readonly) {
        return;
      }

      const lastAnnotationForEntity = annotations
        .slice()
        .reverse()
        .find(
          (x) =>
            x.entity.id === annotation.entity.id && x.index === annotation.index
        );

      if (lastAnnotationForEntity) {
        const filteredAnnotations = annotations.filter(
          (a) =>
            a.multipleGroupBlocks ===
            lastAnnotationForEntity.multipleGroupBlocks
        );

        const updatedAnnotations = [...filteredAnnotations].map((x) => {
          if (x.id === lastAnnotationForEntity.id) {
            return {
              ...x,
              values: [...x.values, ...annotation.values],
              pageTokenIndices: [
                ...(x.pageTokenIndices ?? []),
                ...(annotation.pageTokenIndices || []),
              ],
              isByUser: true,
              triggerNormalization:
                x.isOutput && !!x.entity.entityNormalizations?.length,
            };
          }
          return x;
        });
        setAnnotations(
          updatedAnnotations,
          lastAnnotationForEntity.multipleGroupBlocks!
        );
      } else {
        addAnnotation(annotation, annotation.multipleGroupBlocks!);
      }
    },
    [addAnnotation, annotations, readonly, setAnnotations]
  );

  const removeAnnotation = useCallback(
    (id: string, multipleGroupBlocks: boolean) => {
      if (readonly) {
        return;
      }

      const updatedAnnotations = convertToRemoveAnnotations(
        id,
        annotations,
        multipleGroupBlocks
      );

      setAnnotations(updatedAnnotations, multipleGroupBlocks);
    },
    [readonly, setAnnotations, annotations]
  );

  const removeAnnotationsInBulk = useCallback(
    (annotationsToRemove: Array<Annotation>, multipleGroupBlocks: boolean) => {
      if (readonly) {
        return;
      }

      const filteredAnnotations = annotations.filter(
        (a) => a.multipleGroupBlocks === multipleGroupBlocks
      );

      let updatedAnnotations = [...filteredAnnotations];
      for (let annotation of annotationsToRemove) {
        updatedAnnotations = convertToRemoveAnnotations(
          annotation.id,
          updatedAnnotations,
          multipleGroupBlocks
        );
      }

      setAnnotations(updatedAnnotations, multipleGroupBlocks);
    },
    [readonly, annotations, setAnnotations]
  );

  return {
    annotations,
    getAnnotationsForPage,
    addAnnotation,
    addAnnotationsInBulk,
    updateAnnotation,
    updateAnnotationsInBulk,
    updateLastAnnotationForEntity,
    removeAnnotation,
    removeAnnotationsInBulk,
    updateAnnotations,
  };
};

export default useAnnotationUpdater;

import React from "react";
import "../../organization/components/settings/stpSettings/stpEntities/StpEntities.scss";
import { CSVLink } from "react-csv";
import useTableCsvExportButton from "./hooks/useTableCsvExportButton";
import "./TableCsvExportButton.scss";

type Props<T> = {
  data: Array<T>;
  prefixFileName: string;
  mapFields: (record: T) => Record<string, any>;
};

const TableCsvExportButton = <T,>(props: Props<T>) => {
  const { data, mapFields, prefixFileName } = props;

  const { csvData, fileName } = useTableCsvExportButton(data, {
    mapFields,
    prefixFileName,
  });

  if (!csvData?.length) {
    return null;
  }

  return (
    <div className="header-export__container">
      <CSVLink filename={fileName} data={csvData}>
        <i className="bi bi-download export__icon"></i>
      </CSVLink>
    </div>
  );
};

export default TableCsvExportButton;

import {
  Validation,
  ValidationTypes,
} from "../../../../../../common/validation/constants";
import { isValidNumber } from "../../../../../../common/utilities/number";

const VALIDATIONS: Array<Validation> = [
  {
    type: ValidationTypes.ERROR,
    message: "numberEntity.errors.invalid",
    icon: "ExclamationCircle",
    requiresValue: true,
    validate: isValidNumber,
  },
];

export default VALIDATIONS;

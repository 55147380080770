import React, { FC, useCallback, useEffect } from "react";
import { Button } from "antd";
import "./AnnotationSideControlsFooter.scss";
import { determineNonErrorStatus, Status } from "../../../common/status/status";
import { useTranslation } from "react-i18next";
import RejectModal from "./RejectModal/RejectModal";
import { useAppSelector } from "../../../app";
import DisabledButtonState from "./FooterButtonGroups/DisabledButtonState";
import AnnotationModeButtons from "./FooterButtonGroups/AnnotationModeButtons";
import DefaultButtonGroup from "./FooterButtonGroups/DefaultButtonGroup";
import useRejectModal from "../../hooks/useRejectModal";
import { canTakeOverDocumentSet } from "../../../documentSet/utils/helperFunctions";
import { useActiveDocumentSet } from "../../../documentSet";
import Loader from "../../../common/loader/Loader";
import Assignee from "./Assignee/Assignee";
import useUserConflictModal from "../../hooks/useUserConflictModal";
import UserConflictModal from "./UserConflictModal/UserConflictModal";
import useMeasure from "react-use-measure";
import ButtonType from "../../../common/button/ButtonTypes";
import ActionButton from "./ActionButton";

type AnnotationSideControlsFooterProps = {
  disabled: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setHeight: (height: number) => void;
};

const AnnotationSideControlsFooter: FC<AnnotationSideControlsFooterProps> = ({
  disabled,
  isLoading,
  setIsLoading,
  setHeight,
}) => {
  const [refHeightContainer, { height: heightContainer }] = useMeasure();

  useEffect(() => {
    if (!heightContainer) {
      return;
    }

    setHeight(heightContainer);
  }, [setHeight, heightContainer]);

  return (
    <div
      className="transition-view-footer__container"
      id="tour-detail-step-2"
      ref={refHeightContainer}
    >
      <Assignee />
      <AnnotationSideControlsFooterConditionalRenderer
        disabled={disabled}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

type ConditionalProps = {
  disabled: boolean;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const AnnotationSideControlsFooterConditionalRenderer: FC<ConditionalProps> = ({
  disabled,
  isLoading,
  setIsLoading,
}) => {
  const { t } = useTranslation("annotationSideControls");

  const activeButton = useAppSelector((state) => state.appReducer.activeButton);
  const isAnnotatingMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const { user } = useAppSelector((state) => state.userReducer);
  const { activeDocument } = useAppSelector((state) => state.documentReducer);

  const { updateAssigneeAndStatusWithExceptionHandling, activeDocumentSet } =
    useActiveDocumentSet();

  const {
    isOpenUserConflictModal,
    setIsOpenUserConflictModal,
    handleGoToOverview,
    openUserConflictModal,
  } = useUserConflictModal();

  const { open, setOpen, handleSend, handleAbort, handleRejectClick } =
    useRejectModal(openUserConflictModal);

  const onTakeOverButtonClick = useCallback(() => {
    try {
      updateAssigneeAndStatusWithExceptionHandling(
        activeDocumentSet!,
        determineNonErrorStatus(activeDocumentSet!.status),
        user!,
        true
      );
    } catch (error: any) {
      openUserConflictModal();
    }
  }, [
    openUserConflictModal,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
    user,
  ]);

  if (isLoading || !activeDocument || !activeDocumentSet) {
    return <Loader size="default" />;
  }

  if (canTakeOverDocumentSet(isAnnotatingMode, user, activeDocumentSet)) {
    return (
      <div className="action-button__container">
        <Button
          id="action-take-over"
          type="primary"
          onClick={onTakeOverButtonClick}
          className={activeButton?.id === "action-reject" ? "btn-focused" : ""}
          icon={<i className="bi bi-person-x person-take-over-icon" />}
        >
          {t("annotationSideControlsFooter.takeOver")}
        </Button>
        <UserConflictModal
          isOpen={isOpenUserConflictModal}
          setIsOpen={setIsOpenUserConflictModal}
          goToOverview={handleGoToOverview}
        />
      </div>
    );
  }

  if (disabled) {
    return <DisabledButtonState activeDocumentSet={activeDocumentSet} />;
  }

  if (isAnnotatingMode) {
    return (
      <>
        <AnnotationModeButtons
          setIsLoading={setIsLoading}
          openUserConflictModal={openUserConflictModal}
        />
        <UserConflictModal
          isOpen={isOpenUserConflictModal}
          setIsOpen={setIsOpenUserConflictModal}
          goToOverview={handleGoToOverview}
        />
      </>
    );
  }

  return (
    <>
      <div className="action-button__container">
        <DefaultButtonGroup
          activeDocumentSet={activeDocumentSet}
          handleRejectClick={handleRejectClick}
          setIsLoading={setIsLoading}
          openUserConflictModal={openUserConflictModal}
        />
        <ActionButton
          id="action-approve"
          status={Status.Approved}
          message={t("messages.approveSuccess")}
          buttonText={t("annotationSideControlsFooter.approve")}
          buttonType={ButtonType.Approve}
          setIsLoading={setIsLoading}
          icon={<i className="bi bi-check" />}
          openUserConflictModal={openUserConflictModal}
        />
      </div>
      <RejectModal
        open={open}
        setOpen={setOpen}
        setIsLoading={setIsLoading}
        handleAbort={handleAbort}
        handleSend={handleSend}
      />
      <UserConflictModal
        isOpen={isOpenUserConflictModal}
        setIsOpen={setIsOpenUserConflictModal}
        goToOverview={handleGoToOverview}
      />
    </>
  );
};

export default AnnotationSideControlsFooter;

import React, { memo, useMemo } from "react";
import { useToken } from "../../../hooks/useToken";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app";
import activeEntityForAnnotatorSelector from "../../../../annotator/selectors/activeEntityForAnnotatorSelector";

interface Props {
  token: string;
  dataI?: number;
}

const Token = ({ token, dataI }: Props) => {
  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const hideAnnotationBoxes = useToken();

  const annotatableBox = useMemo(() => {
    if (hideAnnotationBoxes) {
      return false;
    }

    return entity?.entityType === "NER";
  }, [entity, hideAnnotationBoxes]);

  if (dataI) {
    return (
      <span
        data-i={dataI}
        data-index={dataI}
        className={`token__text-item ${
          annotatableBox ? "annotatable" : ""
        }`.trim()}
      >
        {token}
      </span>
    );
  }

  return <span className="token__text-item">{token}</span>;
};

export default memo(Token);

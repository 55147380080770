import React, { FC, useCallback, useMemo, MouseEvent, useRef } from "react";
import { Avatar } from "antd";
import {
  getAccessibleTextColor,
  primaryColor,
} from "../../../../../common/utilities/color";
import EditIcon from "../../../../../common/iconComponents/EditIcon";
import { useAppDispatch, useAppSelector } from "../../../../../app";
import { useActiveDocumentSet } from "../../../../../documentSet";
import { Status } from "../../../../../common/status/status";
import useScroll from "../../../../../configMap/hooks/useScroll";
import { GroupBlockEntityType } from "../../../../../configMap";
import {
  changeActiveEntity,
  changeAnnotating,
} from "../../../../../app/store/appSlice";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
};

const EntityHotKey: FC<Props> = ({
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useScroll({
    ref: scrollRef,
    groupBlockIndex,
    entity: groupBlockEntityType,
  });

  const dispatch = useAppDispatch();
  const { isAnnotating, isEditing, activeEntity } = useAppSelector(
    (state) => state.appReducer
  );

  const { activeDocumentSet } = useActiveDocumentSet();

  const { disabledEntities } = useAppSelector(
    (state) => state.configMapReducer
  );

  const colorToUse = useMemo(() => {
    const { color } = groupBlockEntityType;
    if (!color) {
      return primaryColor;
    }

    if (
      activeDocumentSet?.status === Status.Error ||
      disabledEntities.includes(groupBlockEntityType.entityType.id)
    ) {
      return "#bebebe";
    }

    if (activeEntity && isAnnotating) {
      if (
        activeEntity.entityType.id === groupBlockEntityType.entityType.id &&
        groupBlockIndex === activeEntity.index
      ) {
        return color;
      } else {
        return "#bebebe";
      }
    }

    return color;
  }, [
    activeEntity,
    isAnnotating,
    disabledEntities,
    groupBlockEntityType,
    groupBlockIndex,
    activeDocumentSet?.status,
  ]);

  const cursor = useMemo(() => {
    if (
      disabledEntities.includes(groupBlockEntityType.entityType.id) ||
      activeDocumentSet?.status === Status.Error
    ) {
      return "not-allowed";
    }

    if (disabled) {
      return "default";
    }

    return "pointer";
  }, [
    disabled,
    activeDocumentSet?.status,
    groupBlockEntityType.entityType.id,
    disabledEntities,
  ]);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      if (!activeDocumentSet) {
        return;
      }

      if (isEditing) {
        return;
      }

      if (disabled) {
        return;
      }

      if (activeDocumentSet.status === Status.Error) {
        return;
      }

      if (disabledEntities.includes(groupBlockEntityType.entityType.id)) {
        return;
      }

      if (
        isAnnotating &&
        activeEntity?.entityType.id === groupBlockEntityType.entityType.id &&
        activeEntity?.index === groupBlockIndex
      ) {
        dispatch(changeAnnotating(false));
        dispatch(changeActiveEntity(undefined));
      } else {
        dispatch(changeAnnotating(true));
        dispatch(
          changeActiveEntity({
            ...groupBlockEntityType,
            index: groupBlockIndex,
          })
        );
      }
    },
    [
      disabled,
      activeDocumentSet,
      isEditing,
      isAnnotating,
      groupBlockEntityType,
      groupBlockIndex,
      dispatch,
      activeEntity?.entityType.id,
      activeEntity?.index,
      disabledEntities,
    ]
  );

  if (groupBlockEntityType.hotkey) {
    return (
      <div
        id={`${groupBlockEntityType.entityType.id}-${groupBlockIndex}`}
        ref={scrollRef}
        onClick={handleClick}
      >
        <Avatar
          size={25}
          style={{
            backgroundColor: colorToUse,
            color: getAccessibleTextColor(colorToUse),
            width: 20,
            cursor,
            borderRadius: 3,
          }}
          shape="square"
        >
          {groupBlockEntityType.hotkey.toUpperCase()}
        </Avatar>
      </div>
    );
  }

  return (
    <Avatar
      size={25}
      style={{
        backgroundColor: colorToUse,
        width: 20,
        borderRadius: 3,
      }}
      shape="square"
      icon={<EditIcon fill={getAccessibleTextColor(colorToUse)} />}
    />
  );
};

export default EntityHotKey;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const getHighlightedEntities = (state: RootState) =>
  state.annotationReducer.highlightedEntities;

const selectHighlightedIndex = createSelector(
  [getHighlightedEntities],
  (highlightedEntities): number => {
    if (!highlightedEntities.length) {
      return -1;
    }

    return highlightedEntities[0].index;
  }
);

export default selectHighlightedIndex;

import React, { FC } from "react";
import EditEntityNumber from "./EditEntityNumber";
import EntityTextVisual from "../EntityTextVisual";
import { SingleAnnotationProps } from "../SingleAnnotation";
import useEntityAnnotation from "../../../../../../configMap/hooks/useEntityAnnotation";

const NumberEntity: FC<SingleAnnotationProps> = ({
  entityType,
  entityAnnotation,
  isEditing,
  isAnnotating,
  groupBlockIndex,
  documentId,
  isEditable = true,
  cellWidth,
  cellClasses,
}) => {
  const { value, normalizedValue } = useEntityAnnotation(entityAnnotation);

  if (!entityAnnotation) {
    return null;
  }

  return (
    <>
      {documentId && isEditing && !isAnnotating && isEditable ? (
        <EditEntityNumber
          entityType={entityType}
          documentId={documentId}
          defaultValue={
            normalizedValue ? parseFloat(normalizedValue) : parseFloat(value)
          }
          annotationId={entityAnnotation.id!}
          groupBlockIndex={groupBlockIndex}
          cellWidth={cellWidth}
        />
      ) : (
        <EntityTextVisual
          value={value}
          normalizedValue={normalizedValue}
          cellWidth={cellWidth}
          cellClasses={cellClasses}
        />
      )}
    </>
  );
};

export default NumberEntity;

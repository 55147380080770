import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo, useState } from "react";
import { setStpEntities } from "../store/organizationSlice";
import {
  IEntityStpTableData,
  IStpEntityResponse,
  StpEntityValidation,
} from "../interfaces/stp";
import { notification } from "antd";
import { Option } from "../../common/dataRefining/interfaces/filteringData";
import { useLazyAddOrUpdateStpEntityQuery } from "../store/organizationApi";
import useStpSettings from "./useStpSettings";
import { doesStpEntityExist, isSourceLinkedToSameEntity } from "../utils/utils";

const useStpEntityTable = () => {
  const dispatch = useAppDispatch();

  const { stpEntities, stpEntitiesDocumentType } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );

  const { inputTypes } = useAppSelector((state) => state.documentTypesReducer);

  const [addOrUpdateStpEntity] = useLazyAddOrUpdateStpEntityQuery();

  const [isAdding, setIsAdding] = useState(false);

  const { selectedRows, setSelectedRows, removeEntities, documentTypeId } =
    useStpSettings();

  const sourcesOptions: Array<Option> = useMemo(() => {
    const sourcesForDocumentType = inputTypes.filter((inputType) => {
      return (
        inputType.documentTypes.find((dt) => dt.id === documentTypeId) !==
        undefined
      );
    });

    return sourcesForDocumentType?.map((source) => ({
      label: source.displayName,
      value: source.id,
    }));
  }, [documentTypeId, inputTypes]);

  const entityOptions: Array<Option> = useMemo(() => {
    return stpEntitiesDocumentType.map((entity) => ({
      label: entity.name,
      value: entity.entityTypeId,
    }));
  }, [stpEntitiesDocumentType]);

  const validationOptions: Array<Option> = useMemo(() => {
    return [
      {
        label: "Is numeric",
        value: StpEntityValidation.NUMERIC,
      },
      {
        label: "1 week after today",
        value: StpEntityValidation.WEEK,
      },
      {
        label: "Characters",
        value: StpEntityValidation.CHARACTER,
      },
    ];
  }, []);

  const handleEntityChange = useCallback(
    async (
      stpEntityId: string,
      value: string | Array<string> | boolean | number | null,
      property: keyof IEntityStpTableData
    ) => {
      let updatedEntity: IEntityStpTableData | null = null;

      const updatedEntities = [...stpEntities].map((e): IEntityStpTableData => {
        if (e.id === stpEntityId) {
          updatedEntity = {
            ...e,
            [property]: value ?? null,
          };
          return updatedEntity;
        }

        return e;
      });

      if (!updatedEntity) {
        return;
      }

      const {
        id,
        entityTypeId,
        entityValidation,
        threshold,
        sources,
        isActive,
      } = updatedEntity as IEntityStpTableData;

      if (isActive && !sources?.length) {
        notification.error({
          message: "Sources are required for an active STP entity.",
        });
        return;
      }

      if (doesStpEntityExist(stpEntities, updatedEntity, documentTypeId!)) {
        notification.error({
          message: "This STP entity already exists.",
        });
        return;
      }
      if (
        isSourceLinkedToSameEntity(stpEntities, updatedEntity, documentTypeId!)
      ) {
        notification.error({
          message:
            "The source(s) are already linked to the same entity in another STP entity.",
        });
        return;
      }

      const newStpEntity: IStpEntityResponse = {
        id,
        entityValidation,
        threshold,
        entityTypeId,
        sources,
        isActive,
        documentTypeId: documentTypeId!,
      };

      try {
        await addOrUpdateStpEntity(newStpEntity).unwrap();
        dispatch(setStpEntities({ entities: updatedEntities }));
      } catch (error) {
        notification.error({
          message: "Something went wrong when updating the STP entity.",
        });
      }
    },
    [stpEntities, dispatch, documentTypeId, addOrUpdateStpEntity]
  );

  const onSelectSearch = useCallback(
    (input: string, option: Option | undefined) => {
      return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    },
    []
  );

  const postDocumentTypeChange = useCallback(
    () => () => {
      setSelectedRows([]);
      setIsAdding(false);
    },
    [setSelectedRows]
  );

  return {
    stpEntities,
    handleEntityChange,
    sourcesOptions,
    entityOptions,
    validationOptions,
    selectedRows,
    setSelectedRows,
    removeEntities,
    documentTypeId,
    onSelectSearch,
    postDocumentTypeChange,
    isAdding,
    setIsAdding,
  };
};

export default useStpEntityTable;

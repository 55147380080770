import React, { FC, useMemo } from "react";
import { Table } from "antd";
import "./EmailTemplates.scss";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { EmailTemplate } from "../interfaces/table";
import useEmailTemplates from "../hooks/useEmailTemplates";
import FavoriteCell from "./tableCellComponents/favoriteCell/FavoriteCell";
import RemoveCell from "./tableCellComponents/removeCell/RemoveCell";
import EmailTemplateModal from "./emailTemplateModal/EmailTemplateModal";
import ConfirmRemoveModal from "./confirmRemoveModal/ConfirmRemoveModal";
import useConfirmRemoveModal from "../hooks/useConfirmRemoveModal";
import useEmailTemplateModal from "../hooks/useEmailTemplateModal";
import useTableDynamicHeight, {
  TableType,
} from "../../common/table/hooks/useTableDynamicHeight";
import useFetchEmailTemplates from "../hooks/useFetchEmailTemplates";
import TableColumnContentLoader from "../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";

const SKELETON_LOAD_ROWS = 10;

const EmailTemplates: FC = () => {
  const {
    emailTemplates,
    selectedEmailTemplate,
    setFavoriteEmailTemplate,
    openModalAndSelectEmailTemplate,
  } = useEmailTemplates();

  const loading = useFetchEmailTemplates();

  const {
    open,
    loadingEmailTemplateModal,
    changeModalOpen,
    handleUpsertEmailTemplate,
  } = useEmailTemplateModal();

  const {
    openConfirmRemoveModal,
    setOpenConfirmRemoveModal,
    handleRemove,
    setEmailTemplateToDeleteAndOpenModal,
    loadingConfirmRemoveModal,
  } = useConfirmRemoveModal();

  const { t } = useTranslation("emailTemplates");

  const { refHeightContainer, heightTableContent } = useTableDynamicHeight(
    TableType.EMAIL_TEMPLATES
  );

  const filteredColumns: EmailTemplate[] = useMemo(() => {
    if (loading) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    if (!emailTemplates) {
      return [];
    }

    return [...emailTemplates].sort((a, b) => a.name.localeCompare(b.name));
  }, [emailTemplates, loading]);

  const columns: ColumnsType<EmailTemplate> = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => (
        <TableColumnContentLoader loading={loading}>
          <span>{name}</span>
        </TableColumnContentLoader>
      ),
    },
    {
      title: "",
      dataIndex: "remove",
      key: "remove",
      render: (_, emailTemplate) => (
        <TableColumnContentLoader loading={loading}>
          <RemoveCell
            emailTemplate={emailTemplate}
            setEmailTemplateToDelete={setEmailTemplateToDeleteAndOpenModal}
          />
        </TableColumnContentLoader>
      ),
      width: "80px",
      fixed: "right",
    },
    {
      title: "",
      dataIndex: "favorite",
      key: "favorite",
      render: (_, emailTemplate) => (
        <TableColumnContentLoader loading={loading}>
          <FavoriteCell
            emailTemplate={emailTemplate}
            setFavoriteEmailTemplate={setFavoriteEmailTemplate}
          />
        </TableColumnContentLoader>
      ),
      width: "80px",
      fixed: "right",
    },
  ];

  return (
    <div className="email-templates__container" ref={refHeightContainer}>
      <Table
        className="email-template__table"
        columns={columns}
        pagination={false}
        dataSource={filteredColumns}
        rowKey={(record) => record.id!}
        onRow={(record) => {
          return {
            onClick: () => openModalAndSelectEmailTemplate(record),
          };
        }}
        scroll={{ y: heightTableContent }}
      />
      <div className="email_templates__placeholder" />
      <EmailTemplateModal
        open={open}
        loading={loadingEmailTemplateModal}
        emailTemplate={selectedEmailTemplate}
        handleUpsert={handleUpsertEmailTemplate}
        closeModal={changeModalOpen}
      />
      <ConfirmRemoveModal
        open={openConfirmRemoveModal}
        loading={loadingConfirmRemoveModal}
        setOpen={setOpenConfirmRemoveModal}
        deleteEmailTemplate={handleRemove}
      />
    </div>
  );
};

export default EmailTemplates;

import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import activeDocumentIdSelector from "../../document/selectors/activeDocumentIdSelector";
import { removeAnnotationFromState } from "../../annotation/store/annotationSlice";
import annotationForIdSelector from "../../annotation/selectors/annotationForIdSelector";
import documentSetDisabledSelector from "../../documentSet/selectors/documentSetDisabledSelector";

const useRemoveAnnotation = (annotationId: string) => {
  const dispatch = useAppDispatch();

  const disabled = useAppSelector(documentSetDisabledSelector);
  const documentId = useAppSelector(activeDocumentIdSelector);
  const annotation = useAppSelector((state) =>
    annotationForIdSelector(state, annotationId)
  );

  return useCallback(() => {
    if (disabled) {
      return;
    }

    if (!documentId || !annotation) {
      return;
    }

    const { multipleGroupBlocks, id } = annotation;

    dispatch(
      removeAnnotationFromState({
        annotationId: id,
        documentId,
        multipleGroupBlocks,
      })
    );
  }, [dispatch, documentId, annotation, disabled]);
};

export default useRemoveAnnotation;

import React, { FC, useMemo } from "react";
import { useAppSelector } from "../../../app";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  role: string;
};
const RoleIconMapper: FC<Props> = ({ role }) => {
  const { t } = useTranslation("userManagement", {
    keyPrefix: "roles",
  });

  const { validRoles } = useAppSelector((state) => state.userManagementReducer);

  const foundRole = useMemo(() => {
    const found = validRoles.find((vr) => vr.id === role);

    if (!found) {
      return null;
    }

    return found.name.toLowerCase();
  }, [role, validRoles]);

  if (!foundRole) {
    return null;
  }

  switch (foundRole) {
    case "admin":
      return (
        <Tooltip title={t("adminPopover")} placement="bottom">
          <span className="bi bi-person-gear role__icon" />
        </Tooltip>
      );
    case "metrics":
      return (
        <Tooltip title={t("metricsPopover")} placement="bottom">
          <span className="bi bi-graph-up role__icon" />
        </Tooltip>
      );
    case "stp":
      return (
        <Tooltip title={t("stpPopover")} placement="bottom">
          <span className="bi bi-truck role__icon" />
        </Tooltip>
      );
    default:
      return null;
  }
};

export default RoleIconMapper;

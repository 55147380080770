import React, { FC } from "react";
import {
  calculatePercentageDistribution,
  numberAbbreviation,
} from "../../utils/number";
import { Tooltip } from "antd";
import { ValueTrend } from "../../interfaces/documentSetMetrics";

type Props = {
  manual: ValueTrend;
  automatic: ValueTrend;
  rejected: ValueTrend;
};

const TOTAL_WIDTH = 250;
const COLORS = ["#a5d8ff", "#b2f2bb", "#ffc9c9"];
const TITLE = ["Manual", "Automatic", "Rejected"];

const SourceDistribution: FC<Props> = ({ manual, automatic, rejected }) => {
  const values = [manual.value, automatic.value, rejected.value];
  const percentages = calculatePercentageDistribution(
    manual.value,
    automatic.value,
    rejected.value
  );

  if (!percentages) {
    return <div className="source-distribution__container" />;
  }

  const largest = Math.max(...percentages);

  return (
    <div className="source-distribution__container">
      {percentages.map((p, i) => {
        const rounded = Math.round(p * 100);

        return (
          <Tooltip
            key={COLORS[i]}
            title={`${TITLE[i]}: ${numberAbbreviation.format(
              values[i]
            )} (${rounded}%)`}
          >
            <div
              style={{
                backgroundColor: COLORS[i],
                height: 20,
                width: `${TOTAL_WIDTH * p}px`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {p === largest ? `${values[i]} (${rounded}%)` : null}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default SourceDistribution;

import React, { FC } from "react";
import useTextMap from "../../hooks/useTextMap";
import Page from "./Page";
import useAnnotatorConfig from "../../hooks/useAnnotatorConfig";
import useAnnotatorData from "../../hooks/useAnnotatorData";

interface Props {
  fetchPage: (index: number) => any;
}

const Pages: FC<Props> = ({ fetchPage }) => {
  const { textLayer } = useAnnotatorData();
  const getTextLayerForPage = useTextMap(textLayer);

  const { pages, createTable, scale } = useAnnotatorConfig();

  return (
    <>
      {Array(pages)
        .fill(0)
        .map((_, index) => {
          const key = `pdf-page-${index}`;
          const pageNumber = index + 1;
          const page = fetchPage(pageNumber);
          const [initialTextLayer, shouldRender] =
            getTextLayerForPage(pageNumber);

          return (
            <Page
              shouldRender={shouldRender}
              page={page}
              scale={scale}
              key={key}
              pageNumber={pageNumber}
              initialTextLayer={initialTextLayer}
              createTable={createTable}
            />
          );
        })}
    </>
  );
};

export default Pages;

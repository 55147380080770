//@ts-ignore
import parseDecimalNumber from "one-separator-decimal";

export const isValidNumber = (valueToCheck: string): boolean =>
  !isNaN(Number(valueToCheck));

export const parseNumber = (input: string): number => {
  const cleanedInput = cleanNumber(input);
  if (cleanedInput.includes(",") && cleanedInput.includes(".")) {
    const indexOfComma = cleanedInput.indexOf(",");
    const indexOfPoint = cleanedInput.indexOf(".");

    if (indexOfComma < indexOfPoint) {
      return parseDecimalNumber(cleanedInput.replaceAll(",", ""));
    } else {
      return parseDecimalNumber(cleanedInput.replaceAll(".", ""));
    }
  }

  if (cleanedInput.includes(",")) {
    const len = cleanedInput.split(",")[1].length;

    if (len === 3) {
      return parseDecimalNumber(cleanedInput.replaceAll(",", ""));
    }

    return parseDecimalNumber(cleanedInput);
  }

  if (cleanedInput.includes(".")) {
    const len = cleanedInput.split(".")[1].length;

    if (len === 3) {
      return parseDecimalNumber(cleanedInput.replaceAll(".", ""));
    }

    return parseDecimalNumber(cleanedInput);
  }

  return parseDecimalNumber(cleanedInput);
};

export const parseNumberSimple = (input: string): number => {
  const cleanedInput = cleanNumber(input);
  return parseDecimalNumber(cleanedInput);
};

export const cleanNumber = (valueToClean: string): string =>
  valueToClean.replace(/[^0-9.,]/g, "");

export const getNormalizedNumber = (value: number): number =>
  value < 0 ? 0 : value;

export const minutesToMilliseconds = (value: number): number =>
  value * 60 * 1000;

import React, { useCallback } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useAppSelector } from "../../../app";

const Timestamp = () => {
  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  const formatTime = useCallback((stringToFormat: Dayjs): string => {
    return dayjs(stringToFormat).format("DD/MM/YYYY HH:mm");
  }, []);

  return (
    <p className="timestamp">
      {activeDocumentSet ? formatTime(activeDocumentSet.modifiedDate) : null}
    </p>
  );
};

export default Timestamp;

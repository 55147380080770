import React, { FC } from "react";
import { DocumentSetData } from "../../interfaces/documentSet";
import { OverviewType } from "../../../app";
import RestoreCell from "./tableCellComponents/restoreCell/RestoreCell";
import RemoveCell from "./tableCellComponents/removeCell/RemoveCell";

type Props = {
  overviewType: OverviewType;
  documentSet: DocumentSetData;
};

const RemoveCellConditionalWrapper: FC<Props> = ({
  overviewType,
  documentSet,
}) => {
  if (overviewType === OverviewType.Remove) {
    return <RestoreCell documentSet={documentSet} />;
  }

  if (
    overviewType === OverviewType.Home ||
    overviewType === OverviewType.Error
  ) {
    return <RemoveCell documentSet={documentSet} />;
  }

  return null;
};

export default RemoveCellConditionalWrapper;

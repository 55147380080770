import React, { FC } from "react";
import classNames from "classnames";
import { parseNumberSimple } from "../../../common/utilities/number";

type Props = {
  requiresAttention: boolean;
  isEditing: boolean;
  modelScore: number;
};

const EntityPercentage: FC<Props> = ({
  requiresAttention,
  isEditing,
  modelScore,
}) => {
  const className = classNames({
    "entity-accuracy-score": true,
    "requires-attention": requiresAttention,
    hidden: isEditing,
  });

  if (modelScore === -1) {
    return null;
  }

  if (requiresAttention) {
    return (
      <div className={className}>
        <span className="bi bi-exclamation-triangle-fill attention-required__icon" />
        <label className="entity-accuracy__warning">{`${Math.round(
          parseNumberSimple(modelScore.toString())
        )}%`}</label>
      </div>
    );
  }

  return (
    <div className={className}>
      <label>{`${Math.round(
        parseNumberSimple(modelScore.toString())
      )}%`}</label>
    </div>
  );
};

export default EntityPercentage;

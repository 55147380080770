import { PIE_GRAPH_HEIGHT } from "../../../../constants";
import { Skeleton } from "antd";
import React, { FC } from "react";
import "./pieSkeletonLoader.scss";

export const PieSkeletonLoader: FC = () => {
  return (
    <div
      className="pie-graph__container skeleton__container"
      style={{
        height: PIE_GRAPH_HEIGHT + 20,
      }}
    >
      <Skeleton.Avatar active size={PIE_GRAPH_HEIGHT} shape={"circle"} />
    </div>
  );
};

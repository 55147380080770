import { useEffect, useState } from "react";
import { TextLayerItem } from "../interfaces/textLayer";
import {
  calculateFontSize,
  calculateTransform,
  recalculateBoundingBox,
} from "../helpers/pdfHelpers";

const useTextLayer = (
  scale: number,
  context: CanvasRenderingContext2D,
  initialTextLayer?: Array<TextLayerItem>
) => {
  const [textLayer, setTextLayer] = useState<Array<TextLayerItem> | undefined>(
    initialTextLayer
  );
  const [baseScale, setBaseScale] = useState(scale);

  useEffect(() => {
    setTextLayer(initialTextLayer);
  }, [initialTextLayer]);

  useEffect(() => {
    if (textLayer && baseScale !== scale) {
      const rescaledWords = textLayer.map((word) => {
        const coords = recalculateBoundingBox(word.coords, baseScale, scale);
        const fontSize = calculateFontSize(
          coords.width,
          coords.height,
          word.text
        );
        const transform = calculateTransform(
          coords.width,
          fontSize,
          word.fontFamily || "sans-serif",
          word.text,
          context
        );
        return {
          ...word,
          coords,
          fontSize,
          transform,
        };
      });

      setTextLayer(rescaledWords);
      setBaseScale(scale);
    }
  }, [scale, baseScale, textLayer, context]);

  return textLayer;
};

export default useTextLayer;

import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { DatePicker } from "antd";
import { ColumnItem } from "../../../interfaces/overview";
import useDateFilterItem from "../../../hooks/useDateFilterItem";
import { DATE_FILTER_FORMAT } from "../../../constants";
import { OverviewType } from "../../../../app";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import DropdownActions from "../../../../common/tableHeaderFilter/dropdownActions/DropdownActions";

const { RangePicker } = DatePicker;

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  renderRef: MutableRefObject<any>;
  overviewType: OverviewType;
  isOpen: boolean;
};

const DateFilterItem: FC<Props> = ({
  columnItem,
  label,
  renderRef,
  overviewType,
  isOpen,
}) => {
  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter
    )?.split("+");
  }, [columnItem?.metadata, getValueFromKey, overviewType]);

  const {
    isBlankEntriesChecked,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { onChange, rangeDates, ref, open, setOpen } = useDateFilterItem(
    columnItem,
    overviewType,
    isOpen,
    showBlankEntriesCheckbox,
    blankValue,
    values
  );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={isBlankEntriesChecked}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <RangePicker
        ref={ref}
        className="filter-item range-picker"
        format={DATE_FILTER_FORMAT}
        value={rangeDates}
        allowClear
        onChange={onChange}
        onCalendarChange={onChange}
        allowEmpty={[false, true]}
        getPopupContainer={() => renderRef.current}
        open={open}
        onOpenChange={(open) => setOpen(open)}
        renderExtraFooter={(content) => (
          <DropdownActions handleOkClick={() => setOpen(false)} />
        )}
      />
    </div>
  );
};

export default DateFilterItem;

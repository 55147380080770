import { useMemo } from "react";
import { useAppSelector } from "../../app";
import useAttentionRequired from "./useAttentionRequired";
import { IssuesEntity } from "../interfaces/issuesEntity";
import { SHOW_ISSUES_STATUS_LIST } from "../constants";

const useIssuesHeaderTag = (disabled: boolean) => {
  const groupBlocks = useAppSelector(
    (state) => state.configMapReducer.configMap?.groupBlocks
  );

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const issueEntities = useMemo(() => {
    if (!groupBlocks?.length) {
      return [];
    }

    const entities: Array<IssuesEntity> = groupBlocks.flatMap((gb) =>
      gb.groupBlockEntityTypes.map((entity) => ({
        entity: entity,
        entityId: entity.entityType.id,
        entityIndex: entity.index,
        entityTranslations: entity.entityType.translations,
        groupBlockId: gb.id,
        groupBlockIndex: gb.index,
        multipleGroupBlocks: gb.multipleGroupBlocks,
        groupBlockName: gb.translations,
      }))
    );

    if (!entities?.length) {
      return [];
    }

    return entities;
  }, [groupBlocks]);

  const entityIds = useMemo(() => {
    if (!issueEntities?.length) {
      return [];
    }

    return issueEntities.map((entity) => entity.entityId);
  }, [issueEntities]);

  const {
    errorAttentionRequired,
    scoreAttentionRequired,
    errorAttentionAnnotations,
    scoreAttentionAnnotations,
  } = useAttentionRequired(entityIds);

  const tagColor = useMemo(() => {
    if (errorAttentionRequired) {
      return "error";
    }
    if (scoreAttentionRequired) {
      return "warning";
    }
    return "default";
  }, [errorAttentionRequired, scoreAttentionRequired]);

  const areThereIssues = useMemo(() => {
    if (
      disabled ||
      !activeDocumentSet ||
      !SHOW_ISSUES_STATUS_LIST.includes(activeDocumentSet.status)
    ) {
      return false;
    }

    return errorAttentionRequired || scoreAttentionRequired;
  }, [
    errorAttentionRequired,
    scoreAttentionRequired,
    activeDocumentSet,
    disabled,
  ]);

  const issuesTagHeight = useMemo(() => {
    return areThereIssues ? 30 : 0;
  }, [areThereIssues]);

  return {
    errorAttentionAnnotations,
    scoreAttentionAnnotations,
    tagColor,
    issueEntities,
    areThereIssues,
    issuesTagHeight,
  };
};

export default useIssuesHeaderTag;

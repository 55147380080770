import React, { FC, useMemo } from "react";
import { Document } from "../../../../../document";
import "./documentPreview/DocumentPreview.scss";
import { getExtensionFromFileName } from "../../../../../common/utilities/file";
import { Popover, Tooltip } from "antd";
import DocumentPreview from "./documentPreview/DocumentPreview";

type Props = {
  document: Document;
  children: any;
};

const DocumentPillHoverAction: FC<Props> = ({ document, children }) => {
  const isExtensionPdf = useMemo(
    () =>
      getExtensionFromFileName(document.name)?.toLocaleLowerCase() === "pdf",
    [document]
  );

  if (isExtensionPdf) {
    return (
      <Popover
        content={<DocumentPreview documentId={document.id} />}
        placement="rightTop"
        mouseEnterDelay={0.5}
        title={
          <div className="document-pill-popover__title">{document.name}</div>
        }
      >
        {children}
      </Popover>
    );
  }

  return (
    <Tooltip placement="top" title={document.name}>
      {children}
    </Tooltip>
  );
};

export default DocumentPillHoverAction;

import React, { FC } from "react";
import { GroupBlockEntityType } from "../../../../configMap";
import Loader from "../../../../common/loader/Loader";
import SingleAnnotation from "../entityCell/entityAnnotation/SingleAnnotation";
import HoverButtonsWrapper from "../entityCell/entityAnnotation/HoverButtons/HoverButtonsWrapper";
import EntityPercentage from "../../../../configMap/components/entity/EntityPercentage";
import { EntityAnnotation } from "../../../../annotation";
import InvalidIcon from "./entityAnnotation/InvalidIcon";

type EntityProps = {
  activeDocumentId?: string;
  annotationForEntity: EntityAnnotation;
  isEditing: string | null;
  editingKey: string;
  isAnnotating: boolean;
  removeAnnotation: (annotationId: string) => void;
  isAnnotationLoading?: boolean;
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
  requiresAttention: boolean;
  isFirstAnnotation?: boolean;
  isEditable: boolean;
  cellWidth: number;
  modelScore: number;
  cellClasses: string;
};

const CellRow: FC<EntityProps> = ({
  activeDocumentId,
  annotationForEntity,
  isEditing,
  editingKey,
  isAnnotating,
  removeAnnotation,
  isAnnotationLoading,
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
  requiresAttention,
  isFirstAnnotation = true,
  isEditable,
  cellWidth,
  modelScore,
  cellClasses,
}) => {
  return (
    <div className="list-item-description">
      <SingleAnnotation
        documentId={activeDocumentId}
        entityType={groupBlockEntityType}
        entityAnnotation={annotationForEntity}
        isEditing={isEditable && isEditing === editingKey}
        isAnnotating={isAnnotating}
        groupBlockIndex={groupBlockIndex}
        disabled={disabled}
        cellWidth={cellWidth}
        cellClasses={cellClasses}
      />
      <HoverButtonsWrapper
        isEditing={isEditing === editingKey}
        entityAnnotation={annotationForEntity}
        removeAnnotation={removeAnnotation}
        disabled={disabled}
      />
      {isFirstAnnotation && !isAnnotationLoading ? (
        <>
          <EntityPercentage
            requiresAttention={requiresAttention}
            isEditing={isEditing === editingKey}
            modelScore={modelScore}
          />
          <InvalidIcon cellClasses={cellClasses} />
        </>
      ) : null}
      {isAnnotationLoading ? (
        <div className="loader">
          <Loader size="small" />
        </div>
      ) : null}
    </div>
  );
};

export default CellRow;

import { Locales } from "../../app";

export const getLocaleByLanguage = (language: string): Locales => {
  switch (language?.toLowerCase()) {
    case "en":
      return Locales.EN;
    case "nl":
      return Locales.NL;
    case "fr":
      return Locales.FR;
    case "be":
      return Locales.BE;
    case "bg":
      return Locales.BG;
    case "bn":
      return Locales.BN;
    case "bo":
      return Locales.BO;
    case "br":
      return Locales.BR;
    case "bs":
      return Locales.BS;
    case "ca":
      return Locales.CA;
    case "cs":
      return Locales.CS;
    case "cv":
      return Locales.CV;
    case "cy":
      return Locales.CY;
    case "de":
      return Locales.DE;
    case "it":
      return Locales.IT;
    case "es":
      return Locales.ES;
    case "pl":
      return Locales.PL;
    case "pt":
      return Locales.PT;
    case "ro":
      return Locales.RO;
    case "ru":
      return Locales.RU;
    case "zh":
      return Locales.ZH;
    default:
      return Locales.EN;
  }
};

export const getValidLocaleForBrowser = async (userLanguage: string) => {
  const browserLocale = getLocaleByBrowser().toLowerCase();

  if (supportedLocales.includes(browserLocale)) {
    await importDayjsLocale(browserLocale);
    return browserLocale;
  }

  if (!browserLocale.includes("-")) {
    return getLocaleByLanguage(userLanguage);
  }

  const localeWithoutDash = browserLocale.split("-")[0];
  if (supportedLocales.includes(localeWithoutDash)) {
    await importDayjsLocale(localeWithoutDash);
    return localeWithoutDash;
  }

  return getLocaleByLanguage(userLanguage);
};

const getLocaleByBrowser = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }

  return navigator.language;
};

const importDayjsLocale = async (locale: string) => {
  await import(`dayjs/locale/${locale}.js`);
};

const supportedLocales = [
  "af",
  "am",
  "ar-dz",
  "ar-iq",
  "ar-kw",
  "ar-ly",
  "ar-ma",
  "ar-sa",
  "ar-tn",
  "ar",
  "az",
  "be",
  "bg",
  "bi",
  "bm",
  "bn-bd",
  "bn",
  "bo",
  "br",
  "bs",
  "ca",
  "cs",
  "cv",
  "cy",
  "da",
  "de-at",
  "de-ch",
  "de",
  "dv",
  "el",
  "en-au",
  "en-ca",
  "en-gb",
  "en-ie",
  "en-il",
  "en-in",
  "en-nz",
  "en-sg",
  "en-tt",
  "en",
  "eo",
  "es-do",
  "es-mx",
  "es-pr",
  "es-us",
  "es",
  "et",
  "eu",
  "fa",
  "fi",
  "fo",
  "fr-ca",
  "fr-ch",
  "fr",
  "fy",
  "ga",
  "gd",
  "gl",
  "gom-latn",
  "gu",
  "he",
  "hi",
  "hr",
  "ht",
  "hu",
  "hy-am",
  "id",
  "is",
  "it-ch",
  "it",
  "ja",
  "jv",
  "ka",
  "kk",
  "km",
  "kn",
  "ko",
  "ku",
  "ky",
  "lb",
  "lo",
  "lt",
  "lv",
  "me",
  "mi",
  "mk",
  "ml",
  "mn",
  "mr",
  "ms-my",
  "ms",
  "mt",
  "my",
  "nb",
  "ne",
  "nl-be",
  "nl",
  "nn",
  "oc-lnc",
  "pa-in",
  "pl",
  "pt-br",
  "pt",
  "rn",
  "ro",
  "ru",
  "rw",
  "sd",
  "se",
  "si",
  "sk",
  "sl",
  "sq",
  "sr-cyrl",
  "sr",
  "ss",
  "sv-fi",
  "sv",
  "sw",
  "ta",
  "te",
  "tet",
  "tg",
  "th",
  "tk",
  "tl-ph",
  "tlh",
  "tr",
  "tzl",
  "tzm-latn",
  "tzm",
  "ug-cn",
  "uk",
  "ur",
  "uz-latn",
  "uz",
  "vi",
  "x-pseudo",
  "yo",
  "zh-cn",
  "zh-hk",
  "zh-tw",
  "zh",
];

import React from "react";
import { Button, Select, Space } from "antd";
import ConfigmapGroupBlockTable from "./ConfigmapGroupBlockTable";
import useConfigMapSettings from "../../../hooks/useConfigMapSettings";

const ConfigmapSettings = () => {
  const {
    selectedDocumentTypeId,
    documentTypesSelectOptions,
    selectedConfigMapGroupBlocks,
    handleDocumentTypeChange,
  } = useConfigMapSettings();

  return (
    <div className="organization-settings__section-items">
      <div className="organization-settings__actions">
        <Space size="large">
          {/*<Space>*/}
          {/*  <label>Select Input:</label>*/}
          {/*  <Select*/}
          {/*    onChange={handleInputChange}*/}
          {/*    style={{ width: "200px" }}*/}
          {/*    value={selectedInput.id}*/}
          {/*    options={inputTypesSelectOptions}*/}
          {/*  />*/}
          {/*</Space>*/}
          <Space>
            <label>Select Configmap:</label>
            <Select
              onChange={handleDocumentTypeChange}
              style={{ width: "200px" }}
              value={selectedDocumentTypeId}
              options={documentTypesSelectOptions}
            />
          </Space>
        </Space>
        <Button type="primary" size="small">
          Create new configmap
        </Button>
      </div>
      <ConfigmapGroupBlockTable groupBlockData={selectedConfigMapGroupBlocks} />
    </div>
  );
};

export default ConfigmapSettings;

import { useCallback, useEffect, useState } from "react";
import useKeyPressedListener from "../../annotator/hooks/useKeyPressedListener";

const useTableHeaderPopover = (updateFilter: () => void) => {
  const [isOpen, setIsOpen] = useState(false);

  const enterPressed = useKeyPressedListener("ENTER");

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      setIsOpen(open);
      if (!open) {
        updateFilter();
      }
    },
    [updateFilter]
  );

  useEffect(() => {
    if (enterPressed && isOpen) {
      updateFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPressed, isOpen]);

  return {
    handleOpenChange,
    isOpen,
  };
};

export default useTableHeaderPopover;

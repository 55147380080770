import { MutableRefObject, useCallback, useMemo, useRef } from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { ColumnItem } from "../interfaces/overview";
import { Option } from "../../common/dataRefining/interfaces/filteringData";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { getKeyFromMetaData } from "../utils/columnItemMetaDataHelpers";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import useTableHeaderFilter from "./useTableHeaderFilter";
import { EXTRA_SELECT_FILTER_PREFIX } from "../constants";
import { BaseSelectRef } from "rc-select";
import useFocus from "./useFocus";

const useExtraSelectFilter = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean
) => {
  const dispatch = useAppDispatch();

  const {
    t,
    i18n: { language },
  } = useTranslation("overview");

  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const ref: MutableRefObject<null | BaseSelectRef> = useRef(null);

  const { open, setOpen } = useFocus(ref, isOpen);

  const {
    enabled = false,
    selectLabel = null,
    selectOptions = [],
    delimiter = null,
  } = columnItem.metadata?.extraSelectFilter || {};

  const selectColumnItem = useMemo(() => {
    return {
      ...columnItem,
      metadata: {
        filterAble: {
          delimiter,
          filterKey: `${columnItem.metadata?.filterAble?.filterKey}${EXTRA_SELECT_FILTER_PREFIX}`,
          filterComponentType: "SELECT",
        },
      },
    };
  }, [columnItem, delimiter]);

  const { metadata } = selectColumnItem;

  const showExtraSelectFilter = useMemo(() => {
    return enabled && selectOptions.length && delimiter;
  }, [enabled, selectOptions.length, delimiter]);

  const options: Array<Option> = useMemo(() => {
    if (!showExtraSelectFilter) {
      return [];
    }

    return selectOptions.map((option: string) => ({
      key: option,
      label: option,
      value: option,
    }));
  }, [showExtraSelectFilter, selectOptions]);

  const label: string | undefined = useMemo(() => {
    if (!selectLabel) {
      return undefined;
    }

    return getInLanguageOrDefault(selectLabel, language);
  }, [selectLabel, language]);

  const selectValue = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(metadata, overviewType),
      metadata?.filterAble!.delimiter
    );
  }, [getValueFromKey, metadata, overviewType]);

  const handleChange = useCallback(
    (value: string) => {
      const filterKey: string = metadata?.filterAble?.filterKey;

      const updateCall = {
        key: filterKey,
        delimiter: metadata?.filterAble!.delimiter,
        value,
        overviewType,
      };

      dispatch(updateFilterArray(updateCall));
    },
    [dispatch, overviewType, metadata]
  );

  const placeholder = useMemo(() => {
    if (!label) {
      return;
    }

    return `${t("filters.placeholder")} ${label.toLowerCase()}`;
  }, [t, label]);

  return {
    showExtraSelectFilter,
    options,
    label,
    placeholder,
    selectValue,
    handleChange,
    open,
    setOpen,
  };
};

export default useExtraSelectFilter;

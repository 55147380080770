import React, { FC } from "react";
import { Tooltip } from "antd";
import { ICategorization } from "../../../configMap/interfaces/category";
import useLinkIcon from "../../hooks/useLinkIcon";
import "./LinkIcon.scss";

type Props = {
  categorization: ICategorization;
  isTableControls?: boolean;
};

const LinkIcon: FC<Props> = ({ categorization, isTableControls }) => {
  const { tooltip, classNames } = useLinkIcon(categorization, isTableControls);

  if (!tooltip) {
    return null;
  }

  return (
    <Tooltip title={tooltip} mouseEnterDelay={0.5}>
      <i className={`bi bi-link ${classNames}`} />
    </Tooltip>
  );
};

export default LinkIcon;

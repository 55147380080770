import React, { FC } from "react";
import { OverviewType, useAppSelector } from "../../../app";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import {
  HEIGHT_ANNOTATION_MODE_ALERT,
  HEIGHT_REMOVED_PAGE_ALERT,
} from "../../constants";

type Props = {
  overviewType: OverviewType;
};

const AlertRenderer: FC<Props> = ({ overviewType }) => {
  const { t } = useTranslation("overview", {
    keyPrefix: "info",
  });

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  if (isAnnotationMode) {
    return (
      <Alert
        style={{ height: `${HEIGHT_ANNOTATION_MODE_ALERT}px` }}
        message={t("annotation.message")}
        description={t("annotation.description")}
        type="info"
        showIcon
      />
    );
  }

  if (overviewType === OverviewType.Remove) {
    return (
      <Alert
        style={{ height: `${HEIGHT_REMOVED_PAGE_ALERT}px` }}
        message={t("removeTab")}
        type="info"
        showIcon
      />
    );
  }

  return null;
};

export default AlertRenderer;

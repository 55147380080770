import React, { FC } from "react";
import { Skeleton } from "antd";
import "./SourceOverviewSkeletonLoader.scss";

export const SourceOverviewSkeletonLoader: FC = () => {
  return (
    <div className="skeleton-source__container">
      <Skeleton paragraph={false} className="skeleton-source__title" active />
      <div className="skeleton-source__sections">
        <div className="skeleton-source__section">
          <Skeleton
            paragraph={false}
            className="skeleton-source__section-title"
            active
          />
        </div>
        <div className="skeleton-source__section">
          <Skeleton
            paragraph={false}
            className="skeleton-source__section-title"
            active
          />
        </div>
        <div className="skeleton-source__section">
          <Skeleton
            paragraph={false}
            className="skeleton-source__section-title"
            active
          />
        </div>
        <div className="skeleton-source__section">
          <Skeleton
            paragraph={false}
            className="skeleton-source__section-title"
            active
          />
        </div>
      </div>
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} className="skeleton-source__sections">
          <div className="skeleton-source__section">
            <Skeleton
              paragraph={false}
              className="skeleton-source__section-content"
              active
            />
          </div>
          <div className="skeleton-source__section">
            <Skeleton
              paragraph={false}
              className="skeleton-source__section-volume"
              active
            />
          </div>
          <div className="skeleton-source__section">
            <Skeleton
              paragraph={false}
              className="skeleton-source__section-volume"
              active
            />
          </div>
          <div className="skeleton-source__section">
            <Skeleton
              paragraph={false}
              className="skeleton-source__section-source"
              active
            />
          </div>
        </div>
      ))}
    </div>
  );
};

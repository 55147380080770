import { To, useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";

const useNavigateWithState = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateWithState = useCallback(
    (to: To) => {
      navigate(to, { state: { previousPath: pathname } });
    },
    [navigate, pathname]
  );

  return {
    navigateWithState,
  };
};

export default useNavigateWithState;

import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app";
import { FETCH_INTERVAL } from "../constants";
import selectUserInputIds from "../../user/selectors/userInputIdsSelector";
import { useLazyGetDocumentSetCountQuery } from "../store/documentSetApi";
import { CounterState } from "../interfaces/documentSet";
import useIsAuthenticated from "../../user/hooks/useIsAuthenticated";

const useDocumentSetCounter = () => {
  const { isAuthenticated } = useIsAuthenticated();

  const [numberOfDocumentSets, setNumberOfDocumentSets] =
    useState<CounterState>({
      overview: -1,
      error: -1,
      remove: -1,
    });

  const inputIds = useAppSelector(selectUserInputIds);

  const { fetchDocumentSetsTrigger } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const [getDocumentSetCount] = useLazyGetDocumentSetCountQuery();

  const fetchNumberOfDocumentSets = useCallback(
    async (signal: AbortSignal) => {
      if (!inputIds.length) {
        return;
      }

      const idString = inputIds.join(",");

      try {
        const data = await getDocumentSetCount(idString).unwrap();
        setNumberOfDocumentSets(data);
      } catch {
        return;
      }
    },
    [inputIds, getDocumentSetCount]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const controller = new AbortController();

    fetchNumberOfDocumentSets(controller.signal);

    const interval = setInterval(async () => {
      await fetchNumberOfDocumentSets(controller.signal);
    }, FETCH_INTERVAL);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [fetchNumberOfDocumentSets, isAuthenticated, fetchDocumentSetsTrigger]);

  return numberOfDocumentSets;
};

export default useDocumentSetCounter;

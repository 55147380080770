export const mapValidationToStatus = (
  validation?: string,
  status?: string
): "error" | undefined => {
  if (validation === undefined) {
    return undefined;
  }

  if (status && validation !== status) {
    return undefined;
  }

  return "error";
};

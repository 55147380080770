import {
  ICell,
  ICol,
  IRow,
  ITable,
  ITableData,
  ITableInput,
} from "../interfaces/ITable";
import { Annotation, AnnotationParams } from "../interfaces/annotation";
import {
  convertRectangleToCenterPoint,
  intersect,
  pointInRectangle,
} from "./generalHelpers";
import { generateRandomHash } from "./hashHelper";
import { Rectangle, ITextVertex } from "../interfaces/textLayer";
import { getSideAndTableControlAnnotations } from "../../annotation/utils/utils";
import { intersects } from "./selectionHelpers";

export const buildCellsForTable = (
  table: ITable,
  tokens: Array<ITextVertex>,
  text: string
): Array<ICell> => {
  const { rows, columns } = table;

  const cellData = rows.map((row) => {
    if (row.exclude) {
      return null;
    }

    return columns.map((col): ICell => {
      const cellCoords: Rectangle = {
        left: Math.round(col.x + table.x),
        top: Math.round(row.y + table.y),
        height: Math.round(row.height),
        width: Math.round(col.width),
        rotation: 0,
      };

      const textLayerItemsForCell = tokens.filter((t) => {
        const textCenterPoint = convertRectangleToCenterPoint(t);
        return pointInRectangle(textCenterPoint, cellCoords);
      });

      const data = textLayerItemsForCell.map((item) => ({
        dataI: item.dataI,
        text: text.slice(item.textStartIndex, item.textEndIndex),
      }));

      return {
        id: generateRandomHash(),
        rowId: row.id,
        columnId: col.id,
        data,
      };
    });
  });

  return cellData.filter((f): f is Array<ICell> => f !== null).flat();
};

export const getAnnotationsInCellsForSelection = (
  tables: Array<ITable>,
  markToAdd: AnnotationParams,
  coords: Rectangle
) => {
  const targetTable = findIntersectingTableForMark(
    tables,
    coords,
    markToAdd.page
  );

  if (!targetTable) {
    return [];
  }

  const allTables = [
    targetTable,
    ...tables.filter((t) => targetTable.linkedTables.includes(t.id)),
  ];

  const allCells = allTables.flatMap((t) => t.cells);
  const targetCell = allCells.find(
    (cell) =>
      cell.data
        .map((d) => d.dataI)
        .filter((value) => markToAdd.pageTokenIndices?.includes(value)).length
  );

  if (!targetCell) {
    return [];
  }

  let index = markToAdd.index! - 1 || 0;

  return allTables.flatMap(
    (t): Array<AnnotationParams> =>
      t.cells
        .filter((c) => c.columnId === targetCell.columnId)
        .map((c): AnnotationParams => {
          const textIds = c.data.flatMap((d) => d.dataI);
          const tokens = c.data.flatMap((d) => d.text);
          index += 1;
          return {
            ...markToAdd,
            entity: markToAdd.entity,
            page: t.page,
            tableId: t.id,
            tempAnnotation: true,
            index,
            pageTokenIndices: textIds,
            values: tokens,
          };
        })
        .filter((a) => a.values.length)
  );
};

export const recalculateTables = (
  scale: number,
  oldScale: number,
  table: ITable
) => {
  const columns = table.columns.map((column) => ({
    ...column,
    width: (column.width / oldScale) * scale,
    x: (column.x / oldScale) * scale,
  }));
  const rows = table.rows.map((row) => ({
    ...row,
    height: (row.height / oldScale) * scale,
    y: (row.y / oldScale) * scale,
  }));

  return {
    ...table,
    y: (table.y / oldScale) * scale,
    x: (table.x / oldScale) * scale,
    height: (table.height / oldScale) * scale,
    width: (table.width / oldScale) * scale,
    rows,
    columns,
  };
};

export const deleteExistingAnnotations = (
  table: ITable,
  annotations: Array<Annotation>,
  removeAnnotationsInBulk: (
    annotationsToRemove: Array<Annotation>,
    multipleGroupBlocks: boolean
  ) => void,
  pageNumber: number
) => {
  const cellDataIds = table.cells.flatMap((c) => c.data.map((cd) => cd.dataI));

  const toRemoveAnnotations = [...annotations].filter((annotation) => {
    const inDataIds = cellDataIds.some((cdi) =>
      annotation.pageTokenIndices?.includes(cdi)
    );

    return annotation.page === pageNumber && inDataIds;
  });

  const { tableControlAnnotations, sideControlAnnotations } =
    getSideAndTableControlAnnotations(toRemoveAnnotations);

  removeAnnotationsInBulk(tableControlAnnotations, true);
  removeAnnotationsInBulk(sideControlAnnotations, false);
};

export const getAnnotationsForRow = (
  table: ITable,
  annotations: Array<Annotation>,
  rowIndex: number
): Array<Annotation> => {
  const filteredRows = [...table.rows].filter((r) => !r.exclude);
  const row = filteredRows[rowIndex - 1];

  if (!row) {
    return [];
  }

  const cells = table.cells
    .filter((cell) => cell.rowId === row.id)
    .flatMap((cell) => cell.data.flatMap((data) => data.dataI));

  return annotations.filter((annotation) => {
    const dataI = annotation.pageTokenIndices;

    if (!dataI?.length) {
      return false;
    }

    return (
      annotation.tableId === table.id &&
      annotation.tempAnnotation &&
      intersect(cells, dataI).length
    );
  });
};

export const mapAnnotationsToTables = (
  tables: Array<ITable>,
  annotations: Array<Annotation>
): Array<Annotation> => {
  return annotations.map((a) => {
    if (a.tableId) {
      return a;
    }

    const inTable = tables.find((t) => {
      const cellTextIds = t.cells
        .map((c) => c.data.flatMap((cd) => cd.dataI))
        .flat();

      return (
        cellTextIds.some((cti) => a.pageTokenIndices?.includes(cti)) &&
        t.page === a.page
      );
    });

    if (inTable) {
      return {
        ...a,
        tableId: inTable.id,
      };
    }

    return a;
  });
};

const buildRowsFromInput = (
  rows: Array<ITableData>,
  startHeight: number
): Array<IRow> => {
  let height = startHeight;

  return [...rows]
    .sort((a, b) => a.position - b.position)
    .reverse()
    .map((tir) => {
      const rowHeight = height - tir.position;
      height -= rowHeight;
      return {
        id: generateRandomHash(),
        y: tir.position,
        height: rowHeight,
        exclude: tir.excluded,
      };
    })
    .reverse();
};

const buildColumnsFromInput = (
  columns: Array<ITableData>,
  startWidth: number
): Array<ICol> => {
  let width = startWidth;

  return [...columns]
    .sort((a, b) => a.position - b.position)
    .reverse()
    .map((tic) => {
      const columnWidth = width - tic.position;
      width -= columnWidth;
      return {
        id: generateRandomHash(),
        x: tic.position,
        width: columnWidth,
      };
    })
    .reverse();
};

export const mapTablesFromInput = (
  tokens: Array<ITextVertex> | undefined,
  text: string | undefined,
  tableInputs: Array<ITableInput> | undefined
): Array<ITable> => {
  if (!tableInputs?.length || !tokens?.length || !text) {
    return [];
  }

  return tableInputs.map((ti) => {
    const tempTable = {
      id: generateRandomHash(),
      height: ti.height,
      width: ti.width,
      x: ti.x,
      y: ti.y,
      page: ti.page,
      rows: buildRowsFromInput(ti.rows, ti.height),
      columns: buildColumnsFromInput(ti.columns, ti.width),
      cells: [],
      linkedTables: ti.linkedTables,
    };

    const cells = buildCellsForTable(tempTable, tokens, text);

    return {
      ...tempTable,
      cells,
    };
  });
};

export const addRowAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  y: number,
  id?: string
) => {
  let height = 0;

  return [...tables].map((table) => {
    if (table.id === tableId) {
      // const textLayerForPage = textLayer.find((t) => t.page === table.page);
      height = table.height;

      const rows: Array<IRow> = [];
      const found = table.rows.find((row) => row.id === id);

      if (!found) {
        const newRow: IRow = {
          y,
          id: generateRandomHash(),
          height: 0,
          exclude: false,
        };

        rows.push(...table.rows, newRow);
      } else {
        const newRow: IRow = {
          y,
          id: found.id,
          height: 0,
          exclude: found.exclude,
        };

        rows.push(...table.rows.filter((r) => r.id !== found.id), newRow);
      }

      const newRows = [...rows]
        .sort((a, b) => a.y - b.y)
        .reverse()
        .map((row) => {
          const rowHeight = height - row.y;

          height -= rowHeight;
          return {
            ...row,
            height: rowHeight,
          };
        })
        .reverse();

      const newCells = buildCellsForTable(
        {
          ...table,
          rows: newRows,
        },
        tokens,
        text
      );

      return {
        ...table,
        rows: newRows,
        cells: newCells,
      };
    }

    return table;
  });
};

export const addColumnAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  x: number,
  id?: string
) => {
  let width = 0;

  return [...tables].map((table) => {
    if (table.id === tableId) {
      width = table.width;

      const cols: Array<ICol> = [];
      const found = table.columns.find((col) => col.id === id);

      if (!found) {
        const newColumn: ICol = {
          x,
          id: generateRandomHash(),
          width: 0,
        };

        cols.push(...table.columns, newColumn);
      } else {
        const newColumn: ICol = {
          x,
          id: found.id,
          width: 0,
        };
        cols.push(...table.columns.filter((c) => c.id !== found.id), newColumn);
      }

      const newColumns = [...cols]
        .sort((a, b) => a.x - b.x)
        .reverse()
        .map((col) => {
          const columnWidth = width - col.x;
          width -= columnWidth;

          return {
            ...col,
            width: columnWidth,
          };
        })
        .reverse();

      const newCells = buildCellsForTable(
        {
          ...table,
          columns: newColumns,
        },
        tokens,
        text
      );

      return {
        ...table,
        columns: newColumns,
        cells: newCells,
      };
    }

    return table;
  });
};

export const deleteRowAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  rowId: string
) => {
  return [...tables].map((table) => {
    if (table.id === tableId) {
      const newRows = table.rows.filter((row) => row.id !== rowId);

      const newCells = buildCellsForTable(
        {
          ...table,
          rows: newRows,
        },
        tokens,
        text
      );

      return {
        ...table,
        rows: newRows,
        cells: newCells,
      };
    }
    return table;
  });
};

export const deleteColumnAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  colId: string
) => {
  return [...tables].map((table) => {
    if (table.id === tableId) {
      const newColumns = table.columns.filter((row) => row.id !== colId);

      const newCells = buildCellsForTable(
        {
          ...table,
          columns: newColumns,
        },
        tokens,
        text
      );

      return {
        ...table,
        columns: newColumns,
        cells: newCells,
      };
    }
    return table;
  });
};

export const excludeRowAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  rowId: string,
  exclude: boolean
) => {
  return [...tables].map((table) => {
    if (table.id === tableId) {
      const updatedRows = table.rows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            exclude,
          };
        }
        return row;
      });

      const newCells = buildCellsForTable(
        {
          ...table,
          rows: updatedRows,
        },
        tokens,
        text
      );

      return {
        ...table,
        rows: updatedRows,
        cells: newCells,
      };
    }

    return table;
  });
};

export const linkTablesAndGetTables = (
  tables: Array<ITable>,
  tokens: Array<ITextVertex>,
  text: string,
  tableId: string,
  columns: Array<ICol>,
  x: number,
  width: number,
  targetTable: ITable
) => {
  return [...tables].map((table) => {
    if (table.id === tableId) {
      const newCells = buildCellsForTable(
        {
          ...table,
          x,
          columns,
          width,
        },
        tokens,
        text
      );

      return {
        ...table,
        x,
        columns,
        width,
        cells: newCells,
        linkedTables: [...table.linkedTables, targetTable.id],
      };
    }

    if (table.id === targetTable.id) {
      return {
        ...table,
        linkedTables: [...table.linkedTables, tableId],
      };
    }

    return table;
  });
};

const findIntersectingTableForMark = (
  tables: Array<ITable>,
  targetCoords: Rectangle,
  targetPage: number
): ITable | null => {
  const intersectingTable = tables.find((t) => {
    const tableRect: Rectangle = {
      left: t.x,
      top: t.y,
      width: t.width,
      height: t.height,
      rotation: 0,
    };

    return intersects(targetCoords, tableRect) && targetPage === t.page;
  });

  if (!intersectingTable) {
    return null;
  }

  return intersectingTable;
};

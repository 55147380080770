import React from "react";
import Logo from "../../common/assets/klassifaiDark.svg";
import "./Maintenance.scss";

const Maintenance = () => {
  return (
    <div className="maintenance__container">
      <img className="maintenance__logo" src={Logo} alt="Klassifai logo" />
      <div className="maintenance__text-container">
        <p className="maintenance__text">
          We are currently doing planned <mark>maintenance</mark>.
        </p>
        <p className="maintenance__text">
          The site will be back online shortly.
        </p>
      </div>
    </div>
  );
};

export default Maintenance;

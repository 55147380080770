import React, { FC } from "react";
import EditEntityDate from "../dateEntity/EditEntityDate";
import EntityTextVisual from "../EntityTextVisual";
import dayjs from "dayjs";
import { VALID_DATE_FORMATS } from "../../../../../../common/utilities/formats/dateFormats";
import { SingleAnnotationProps } from "../SingleAnnotation";
import useEntityAnnotation from "../../../../../../configMap/hooks/useEntityAnnotation";
import useEntityNormalization from "../../../../../../configMap/hooks/useEntityNormalization";

const WeekEntity: FC<SingleAnnotationProps> = ({
  entityType,
  entityAnnotation,
  isEditing,
  isAnnotating,
  groupBlockIndex,
  documentId,
  isEditable = true,
  cellWidth,
  cellClasses,
}) => {
  const { value, normalizedValue } = useEntityAnnotation(entityAnnotation);

  const { entityNormalizationFormat } = useEntityNormalization(
    entityType?.entityType?.entityNormalizations
  );

  return (
    <>
      {documentId && isEditing && !isAnnotating && isEditable ? (
        <EditEntityDate
          entityType={entityType}
          documentId={documentId}
          defaultValue={dayjs(
            normalizedValue ?? value,
            VALID_DATE_FORMATS["eu"]
          )}
          annotationId={entityAnnotation.id!}
          groupBlockIndex={groupBlockIndex}
          format={entityNormalizationFormat}
          cellWidth={cellWidth}
        />
      ) : (
        <EntityTextVisual
          value={value}
          normalizedValue={normalizedValue}
          cellWidth={cellWidth}
          cellClasses={cellClasses}
        />
      )}
    </>
  );
};

export default WeekEntity;

export const SupportedLanguages = [
  "en",
  "nl",
  "fr",
  "be",
  "bg",
  "bn",
  "bo",
  "br",
  "bs",
  "ca",
  "cs",
  "cv",
  "cy",
  "de",
  "es",
  "it",
  "pl",
  "pt",
  "ro",
  "ru",
  "zh",
] as const;

export type SupportedLanguage = typeof SupportedLanguages[number];

import React, { FC } from "react";
import { Skeleton } from "antd";
import { OVERVIEW_ORG_GRAPH_HEIGHT } from "../../../constants";
import "./MetricsOverviewSkeletonLoader.scss";

export const MetricsOverviewSkeletonLoader: FC = () => {
  return (
    <div
      className="skeleton-metrics__container"
      style={{ height: OVERVIEW_ORG_GRAPH_HEIGHT }}
    >
      <Skeleton paragraph={false} className="skeleton-metrics__title" active />
      <div className="skeleton-metrics__sections">
        <div className="skeleton-metrics__section">
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-header"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-value"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-progress"
            active
          />
        </div>
        <div className="skeleton-metrics__section">
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-header"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-value"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-progress"
            active
          />
        </div>
        <div className="skeleton-metrics__section">
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-header"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-value"
            active
          />
          <Skeleton
            paragraph={false}
            className="skeleton-metrics__sections-progress"
            active
          />
        </div>
      </div>
    </div>
  );
};

import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import { useMultipleGroupBlocks } from "../../configMap";
import { EMPTY_ARRAY } from "../../annotation/selectors/annotationsForEntitySelector";
import useDocumentTypes from "./useDocumentTypes";
import {
  configMapIsLoading,
  setConfigMap,
} from "../../configMap/store/configMapSlice";
import { useUpdateDocumentTypeRequestMutation } from "../store/documentTypeApi";
import { setIncompleteCategorizations } from "../../metadata/store/metadataSlice";

const useSelectDocumentType = () => {
  const dispatch = useAppDispatch();

  const { activeDocument, activeDocumentMinimal, setActiveDocument } =
    useActiveDocument();

  const { setInitialMultipleGroupBlocksCount } = useMultipleGroupBlocks();

  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const annotations = useAppSelector((state) => {
    if (!activeDocument) {
      return EMPTY_ARRAY;
    }

    return state.annotationReducer.tableControlAnnotations.concat(
      state.annotationReducer.sideControlAnnotations
    );
  });

  const activeDocumentTypeId = useMemo(() => {
    return activeDocumentMinimal?.documentType?.id;
  }, [activeDocumentMinimal]);

  const { documentTypes } = useDocumentTypes();

  const [updateDocumentTypeRequest] = useUpdateDocumentTypeRequestMutation();

  const changeDocumentType = useCallback(
    async (documentTypeId: string) => {
      dispatch(configMapIsLoading());
      if (!activeDocumentMinimal?.id) {
        return;
      }

      try {
        const data = await updateDocumentTypeRequest({
          documentId: activeDocumentMinimal.id,
          documentTypeId,
        }).unwrap();

        setActiveDocument({ ...activeDocumentMinimal, documentType: data });
        dispatch(setConfigMap(data.configMap!));
        dispatch(setIncompleteCategorizations([]));
        setInitialMultipleGroupBlocksCount(
          data.configMap!,
          annotations,
          documentCategoryAnnotations
        );
      } catch (e) {
        console.error(e);
      }
    },
    [
      activeDocumentMinimal,
      setActiveDocument,
      setInitialMultipleGroupBlocksCount,
      annotations,
      dispatch,
      documentCategoryAnnotations,
      updateDocumentTypeRequest,
    ]
  );

  return { activeDocumentTypeId, changeDocumentType, documentTypes };
};

export default useSelectDocumentType;

import Login from "./components/login/Login";
import WelcomePage from "./components/welcomePage/WelcomePage";
import PersonalPage from "./components/personalPage/PersonalPage";
import Auth0ProviderWithRedirect from "./components/Auth0ProviderWithRedirect";
import useAuth0thenticate from "./hooks/useAuth0thenticate";
import useAuth0IdleTimer from "./hooks/useAuth0IdleTimer";
import usePassword from "./hooks/usePassword";
import usePersonalPage from "./hooks/usePersonalPage";
import userReducer from "./store/userSlice";
import { User, UserSettings, UserProfileState } from "./interfaces/interfaces";
import {
  USER_SETTINGS_LOCALSTORAGE_NAME,
  USER_LOCALSTORAGE_NAME,
  initialsRegex,
  validLanguages,
} from "./constants";
import { getInitialsFromName, parseJwt } from "./utils/utils";

export {
  useAuth0thenticate,
  useAuth0IdleTimer,
  usePassword,
  usePersonalPage,
  userReducer,
  getInitialsFromName,
  parseJwt,
  Login,
  WelcomePage,
  Auth0ProviderWithRedirect,
};
export {
  USER_SETTINGS_LOCALSTORAGE_NAME,
  USER_LOCALSTORAGE_NAME,
  initialsRegex,
  validLanguages,
};
export type { User, UserSettings, UserProfileState };

export default PersonalPage;

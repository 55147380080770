import { Rectangle } from "../interfaces/textLayer";

export const calculateLineProperties = (
  rect1: Rectangle,
  rect2: Rectangle
): [Rectangle, Rectangle] => {
  const rightRect2 = rect2.left + rect2.width;

  const width1 = (rect1.left - rightRect2) / 2;

  const top1 = rect1.top + rect1.height / 2;
  const top2 = rect2.top + rect2.height / 2;

  const left1 = rect1.left - width1;
  const left2 = rect2.left + rect2.width;

  const a = left1 - left2;
  const b = top1 - top2;

  const width2 = Math.sqrt(a * a + b * b) + 2;
  const angle = (Math.atan2(b, a) * 180) / Math.PI;

  const line1: Rectangle = {
    width: width1,
    height: 0,
    top: top1,
    left: left1,
    rotation: 0,
  };

  const line2: Rectangle = {
    width: width2,
    height: 0,
    top: top2,
    left: left2,
    rotation: angle,
  };

  return [line1, line2];
};

// @ts-ignore
import hash from "object-hash";

export const generateRandomHash = (includeNew = true): string => {
  const rand =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  if (includeNew) {
    return `NEW-${hash({
      dateTime: new Date().toLocaleString(),
      rand,
    })}`;
  }
  return hash({
    dateTime: new Date().toLocaleString(),
    rand,
  });
};

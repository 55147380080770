import { useCallback, useEffect, useRef, useState } from "react";
import { resizeImage } from "simple-image-resize";
import { UserProfileState } from "../index";
import { useAppSelector } from "../../app";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { updateUserProfile } from "../store/userSlice";
import { defaultSettings } from "../utils/utils";
import useAuth0thenticate from "./useAuth0thenticate";
import { useUpdateUserMutation } from "../store/userApi";

const usePersonalPage = (updateOnBlur = true) => {
  const dispatch = useDispatch();
  const updating = useRef(false);

  const { user, userSettings } = useAppSelector((state) => state.userReducer);

  const { fetchTokenAndMetaData } = useAuth0thenticate(false, true);

  const {
    t,
    i18n: { language },
  } = useTranslation("personalPage", {
    keyPrefix: "userSettings",
  });

  const [state, setState] = useState<UserProfileState>({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    username: user?.username || "",
    email: user?.email || "",
    company: user?.organization.name || "",
    avatar: user?.avatar || "",
  });

  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);

  const [updateUser] = useUpdateUserMutation();

  const handleBlur = useCallback((key: string, value: string) => {
    setState((oldState) => ({
      ...oldState,
      [key]: value,
    }));
    setHasBeenUpdated(true);
  }, []);

  const removeAvatar = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      avatar: user?.defaultAvatar || "",
    }));
    setHasBeenUpdated(true);
  }, [user]);

  const handleAvatarChange = useCallback(async (event: any) => {
    const file = event.target.files[0];

    const blob = await resizeImage(file, {
      maxWidth: 500,
      maxHeight: 500,
      quality: 0.5,
    });

    const reader = new FileReader();
    reader.onload = function (event) {
      const imageBase64 = event.target?.result?.toString();

      if (!imageBase64) {
        return;
      }
      setState((prevState) => ({
        ...prevState,
        avatar: imageBase64,
      }));
      setHasBeenUpdated(true);
    };
    reader.readAsDataURL(blob);
  }, []);

  const updateProfile = useCallback(async () => {
    if (updating.current) {
      return;
    }

    if (!user) {
      return;
    }
    try {
      updating.current = true;

      const body = {
        ...state,
        email: state.email.toLowerCase(),
        metadata: {
          ...userSettings,
          picture: state.avatar || null,
          overviewSorter: userSettings.saveSorter
            ? userSettings.overviewSorter
            : defaultSettings.overviewSorter,
          archiveSorter: userSettings.saveSorter
            ? userSettings.archiveSorter
            : defaultSettings.archiveSorter,
          overviewFilters: userSettings.saveFilters
            ? userSettings.overviewFilters
            : [],
          archiveFilters: userSettings.saveFilters
            ? userSettings.archiveFilters
            : [],
          showWelcome: false,
          language,
        },
      };

      await updateUser({ userId: user.id, data: body }).unwrap();
      dispatch(
        updateUserProfile({
          ...user,
          ...state,
          email: state.email.toLowerCase(),
          showWelcome: false,
        })
      );
      notification.success({
        key: "userSettingsUpdatedToast",
        message: t("success.title"),
        description: t("success.description"),
      });
      setHasBeenUpdated(false);
    } catch (e) {
      const error = e as AxiosError;

      const data = error?.response?.data as any;

      console.error(e);
      notification.error({
        message: t("error.title"),
        description: data?.message || t("error.description"),
      });
    }

    updating.current = false;
  }, [dispatch, state, userSettings, user, t, language, updateUser]);

  useEffect(() => {
    if (!updateOnBlur) {
      return;
    }

    if (!hasBeenUpdated) {
      return;
    }

    updateProfile();
  }, [hasBeenUpdated, updateProfile, updateOnBlur]);

  const updateProfileAndFetchUserInputIds = useCallback(async () => {
    await updateProfile();
    if (user) {
      await fetchTokenAndMetaData();
      return;
    }
    await fetchTokenAndMetaData();
  }, [updateProfile, fetchTokenAndMetaData, user]);

  return {
    state,
    user,
    handleBlur,
    removeAvatar,
    handleAvatarChange,
    setHasBeenUpdated,
    updateProfile,
    updateProfileAndFetchUserInputIds,
  };
};

export default usePersonalPage;

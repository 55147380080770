import React, { FC, useEffect, useState } from "react";
import "./CsvViewer.scss";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import PDFSkeleton from "../../../app/pages/detailView/pdfSkeleton/PDFSkeleton";
import { usePapaParse } from "react-papaparse";
import { convertBufferToString } from "../../utils/binary";
import EmptyNavigationHeader from "../emptyNavigationHeader/EmptyNavigationHeader";

const CsvViewer: FC = () => {
  const [csvFile, setCsvFile] = useState<Array<any> | null>(null);

  const { documentData, loading } = useDocumentViewer();
  const { readString } = usePapaParse();

  useEffect(() => {
    if (!documentData) {
      return;
    }

    readString(convertBufferToString(documentData), {
      worker: true,
      complete: ({ data }) => setCsvFile(data),
    });
  }, [documentData, readString]);

  if (loading || !documentData || !csvFile) {
    return <PDFSkeleton />;
  }

  return (
    <div className="container">
      <EmptyNavigationHeader />
      <div className="csv-viewer__container">
        <div className="page__container">
          <table className="document-table">
            <tbody>
              {csvFile.map((array: any, id: number) => {
                const values = array
                  .filter((item: any) => item !== null && item !== "")
                  .flatMap((currValue: any, index: string) => {
                    return <td key={index}>{currValue}</td>;
                  });
                return <tr key={"table-row_" + id}>{values}</tr>;
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CsvViewer;

import { useMemo } from "react";
import {
  ERROR_WHILE_NORMALIZING,
  INVALID_VALUE,
} from "../../configMap/utils/validationUtils";
import classNames from "classnames";
import { Validation, ValidationTypes } from "../../common/validation/constants";
import useEntityAnnotation from "../../configMap/hooks/useEntityAnnotation";
import {
  Component,
  GroupBlockEntityType,
  useValidation,
} from "../../configMap";
import { Annotation } from "../../annotator/interfaces/annotation";
import NUMBER_VALIDATIONS from "../components/tableEntityCell/entityCell/entityAnnotation/numberEntity/validations";
import DATE_VALIDATIONS from "../components/tableEntityCell//entityCell/entityAnnotation/dateEntity/validations";
import WEEK_VALIDATIONS from "../components/tableEntityCell//entityCell/entityAnnotation/weekEntity/validations";
import { ValidationStatuses } from "../interfaces/validation";
import useEntityValidationMessage from "./useEntityValidationMessage";
import { EntityAnnotation } from "../../annotation";

const useAnnotationValidation = (
  groupBlockEntityType: GroupBlockEntityType,
  annotationsForEntity: Array<Annotation>,
  groupBlockIndex: number,
  requiresAttention: boolean
) => {
  const { entityType } = groupBlockEntityType;

  const entityAnnotation: EntityAnnotation | undefined = useMemo(() => {
    let annotation;

    if (annotationsForEntity.length >= 1) {
      annotation = annotationsForEntity[0];
    }

    if (annotation) {
      return {
        ...annotation,
        index: annotation.index!,
        pageTokenIndices: annotation.pageTokenIndices!,
        value: annotation.values?.join(" ") || "",
        modelScore: annotation.modelScore!,
        isByUser: annotation.isByUser!,
        isLoading: annotation.isLoading,
      };
    }

    return undefined;
  }, [annotationsForEntity]);

  const { value, normalizedValue, options } =
    useEntityAnnotation(entityAnnotation);

  const textValue = useMemo(
    () => normalizedValue ?? value,
    [value, normalizedValue]
  );

  const hasNormalizedValue = useMemo(
    () => !!normalizedValue,
    [normalizedValue]
  );

  const mergedValidations = useMemo((): Array<Validation> => {
    const output: Array<Validation> = [];
    switch (entityType.component.toLowerCase()) {
      case Component.NUMBER.toLowerCase():
        return [...output, ...NUMBER_VALIDATIONS];
      case Component.DATE.toLowerCase():
        return [...output, ...DATE_VALIDATIONS];
      case Component.WEEK.toLowerCase():
        return [...output, ...WEEK_VALIDATIONS];
      case Component.INPUT.toLowerCase():
      default:
        return output;
    }
  }, [entityType.component]);

  const validation = useValidation(
    textValue,
    `${entityType.id}-${groupBlockIndex}`,
    mergedValidations
  );

  const isValidOption = useMemo(() => {
    if (!options?.length || !normalizedValue) {
      return true;
    }

    return options.includes(normalizedValue.toLowerCase());
  }, [normalizedValue, options]);

  const hasInvalidNormalizedValue = useMemo(() => {
    if (!hasNormalizedValue) {
      return false;
    }

    return (
      textValue?.toLowerCase() === INVALID_VALUE.toLowerCase() ||
      textValue?.toLowerCase() === ERROR_WHILE_NORMALIZING.toLowerCase()
    );
  }, [hasNormalizedValue, textValue]);

  const entityAnnotations: Array<EntityAnnotation> = useMemo(() => {
    return annotationsForEntity.map((annotation) => ({
      ...annotation,
      index: annotation.index!,
      pageTokenIndices: annotation.pageTokenIndices!,
      value: annotation.values?.join(" ") || "",
      modelScore: annotation.modelScore!,
      isByUser: annotation.isByUser!,
      isLoading: annotation.isLoading,
    }));
  }, [annotationsForEntity]);

  const validationStatuses: ValidationStatuses = useMemo(
    () => ({
      isError: validation.type === ValidationTypes.ERROR || !isValidOption,
      isWarning: validation.type === ValidationTypes.WARNING,
      isInfo: validation.type === ValidationTypes.INFO,
      isInvalid: hasInvalidNormalizedValue,
      requiresAttention,
    }),
    [validation, hasInvalidNormalizedValue, requiresAttention, isValidOption]
  );

  const validationClasses = classNames({
    "error-status": validationStatuses.isError,
    "warning-status": validationStatuses.isWarning,
    "info-status": validationStatuses.isInfo,
    invalid: validationStatuses.isInvalid,
    "requires-attention": validationStatuses.requiresAttention,
  });

  const validationMessage = useEntityValidationMessage(
    value,
    normalizedValue,
    validation,
    options
  );

  return {
    validationClasses,
    validation,
    mergedValidations,
    value,
    normalizedValue,
    options,
    entityAnnotations,
    validationMessage,
  };
};

export default useAnnotationValidation;

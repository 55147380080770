export const mapData = <Type extends object>(
  values: Array<string>,
  source: Array<Type>,
  property: string
): Array<Type> => {
  return values
    .map((v): Type | null => {
      const found = source.find((s) => {
        if (!Object.prototype.hasOwnProperty.call(s, property)) {
          return false;
        }

        const value: string = s[property as keyof Type] as unknown as string;
        return v.toLowerCase() === value.toLowerCase();
      });
      if (found) {
        return found;
      }

      return null;
    })
    .filter((x): x is Type => x !== null);
};

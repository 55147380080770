import React, { FC } from "react";
import { GraphPieSource } from "../../../../interfaces/documentSetMetrics";
import { InputType } from "../../../../../documentTypes";

type TooltipProps = {
  data: Array<any>;
  inputs: Array<InputType>;
};

const Tooltip: FC<TooltipProps> = ({ data, inputs }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <ul className="tooltip__items">
      {data?.map((d: any) =>
        d.data.otherSources?.length ? (
          <div key={d.value}>
            {d.data.otherSources.length > 1 ? (
              <div className="tooltip__title">
                <span className="tooltip__item-name">Total:</span>
                <span className="tooltip__item-value">{d.value}</span>
              </div>
            ) : null}

            {d.data.otherSources.map((source: GraphPieSource) => {
              const color =
                inputs.find(
                  (i) =>
                    i.displayName.toLowerCase() === source.name.toLowerCase()
                )?.color || d.color;

              return (
                <li className="tooltip__item" key={source.name}>
                  <span className="tooltip__item-source">
                    <span
                      className="tooltip__item-color"
                      style={{ background: color }}
                    />
                    <span>{source.name}:</span>
                  </span>
                  <span className="tooltip__item-value">{source.total}</span>
                </li>
              );
            })}
          </div>
        ) : (
          <li className="tooltip__item" key={d.value}>
            <span
              className="tooltip__item-color"
              style={{ background: d.color }}
            />
            <span>{d.name}:</span>
            <span className="tooltip__item-value">{d.value}</span>
          </li>
        )
      )}
    </ul>
  );
};

export default Tooltip;

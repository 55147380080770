import React, { FC, MouseEvent, useCallback } from "react";
import { notification } from "antd";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import { useActiveDocumentSet } from "../../../../index";
import { useTranslation } from "react-i18next";

type Props = {
  documentSet: DocumentSetData;
};

const RestoreCell: FC<Props> = ({ documentSet }) => {
  const { t } = useTranslation("messages");
  const { handleRestoreDocumentSet } = useActiveDocumentSet();

  const handleRestoreClicked = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      await handleRestoreDocumentSet(documentSet);
      notification.success({
        key: "restoredToast",
        message: t("documentRestored.message"),
        description: t("documentRestored.description"),
      });
    },
    [t, documentSet, handleRestoreDocumentSet]
  );

  return (
    <div className="restore-icon" onClick={handleRestoreClicked}>
      <i className="bi bi-arrow-counterclockwise" />
    </div>
  );
};

export default RestoreCell;

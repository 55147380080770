import React, { FC, useCallback, useEffect, useMemo } from "react";
import "./OrganizationSettings.scss";
import ConfigmapSettings from "./configmapSettings/ConfigmapSettings";
import { Menu } from "antd";
import UserManagement from "../../../userManagement";
import classnames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../app";
import { setSelectedKey } from "../../store/organizationSlice";
import { OrganizationSettingsMenuKeys } from "../../utils/constants";
import {
  ADMIN_ROLE_ID,
  getVariableFromEnv,
  STP_ROLE_ID,
} from "../../../common/utilities/env";
import StpEntities from "./stpSettings/stpEntities/StpEntities";
import StpSuppliers from "./stpSettings/stpSupplier/StpSuppliers";
import { MenuItem } from "../../interfaces/organizationSettings";
import { Navigate } from "react-router-dom";
import { validateAccess } from "../../../common/menu/utils/utils";
import EdmacArticleCsvSettings from "./edmacArticleCsvSettings/EdmacArticleCsvSettings";

const environment = getVariableFromEnv("ENVIRONMENT", "development");

const OrganizationsSettings: FC = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.userReducer);

  const selectedKey = useAppSelector(
    (state) => state.organizationReducer.selectedKey
  );

  const contentClassNames = classnames({
    "organizations-settings__content": true,
    padding: selectedKey !== "usermanagement",
  });

  const handleMenuItemClick = useCallback(
    (event: any) => {
      const key = event.key as OrganizationSettingsMenuKeys;
      dispatch(setSelectedKey(key));
    },
    [dispatch]
  );

  const items: Array<MenuItem> = useMemo(() => {
    const defaultList: Array<MenuItem> = [];

    if (user?.access?.includes(ADMIN_ROLE_ID)) {
      defaultList.push({
        key: OrganizationSettingsMenuKeys.USERMANAGEMENT,
        label: "User management",
        icon: <span className="bi bi-people" />,
      });
    }

    if (environment.toLowerCase() === "development") {
      defaultList.push(
        ...[
          {
            key: OrganizationSettingsMenuKeys.STP,
            label: "Straight through processing",
            icon: <span className="bi bi-truck" />,
            children: [
              {
                key: OrganizationSettingsMenuKeys.STP_SUPPLIERS,
                label: "Suppliers",
              },
              {
                key: OrganizationSettingsMenuKeys.STP_ENTITIES,
                label: "Entities",
              },
            ],
          },
          {
            key: OrganizationSettingsMenuKeys.CSV_ARTICLE,
            label: "CSV Article - POs",
            icon: <span className="bi bi-filetype-csv" />,
          },
          {
            key: OrganizationSettingsMenuKeys.CONFIGMAP,
            label: "Configmap",
            icon: <span className="bi bi-menu-button-wide" />,
          },
        ]
      );
    }
    if (
      // TODO: This is temp until STP retrieves major adaption
      ["ravago", "klassifai"].some((org) =>
        user?.organization.name.toLowerCase().includes(org)
      ) &&
      (user?.access?.includes(STP_ROLE_ID) ||
        user?.access?.includes(ADMIN_ROLE_ID))
    ) {
      defaultList.push({
        key: OrganizationSettingsMenuKeys.STP,
        label: "Straight through processing",
        icon: <span className="bi bi-truck" />,
        children: [
          {
            key: OrganizationSettingsMenuKeys.STP_SUPPLIERS,
            label: "Suppliers",
          },
          { key: OrganizationSettingsMenuKeys.STP_ENTITIES, label: "Entities" },
        ],
      });
    }
    if (
      ["edmac", "klassifai"].some((org) =>
        user?.organization.name.toLowerCase().includes(org)
      )
    ) {
      defaultList.push({
        key: OrganizationSettingsMenuKeys.CSV_ARTICLE,
        label: "CSV Article - POs",
        icon: <span className="bi bi-filetype-csv" />,
      });
    }

    return defaultList.filter((value, index, self) => {
      return self.findIndex((v) => v.label === value.label) === index;
    });
  }, [user]);

  useEffect(() => {
    if (user && !user.access.includes(ADMIN_ROLE_ID)) {
      dispatch(dispatch(setSelectedKey(OrganizationSettingsMenuKeys.STP)));
    }
  }, [user, dispatch]);

  if (user && !validateAccess(user.access)) {
    return <Navigate to="/" />;
  }

  return (
    <div className="organizations-settings__container">
      <div className="organizations-settings__side-menu">
        <Menu
          className="organizations-settings-menu__side-menu"
          selectedKeys={[selectedKey]}
          mode="inline"
          onClick={handleMenuItemClick}
          items={items}
          defaultOpenKeys={["stp"]}
        />
      </div>
      <div className={contentClassNames}>
        <OrganizationSettingsContent />
      </div>
    </div>
  );
};

const OrganizationSettingsContent: FC = () => {
  const selectedKey = useAppSelector(
    (state) => state.organizationReducer.selectedKey
  );

  switch (selectedKey) {
    case OrganizationSettingsMenuKeys.USERMANAGEMENT:
      return <UserManagement />;
    case OrganizationSettingsMenuKeys.STP_ENTITIES:
      return <StpEntities />;
    case OrganizationSettingsMenuKeys.STP_SUPPLIERS:
      return <StpSuppliers />;
    case OrganizationSettingsMenuKeys.CONFIGMAP:
      return <ConfigmapSettings />;
    case OrganizationSettingsMenuKeys.CSV_ARTICLE:
      return <EdmacArticleCsvSettings />;
    default:
      return null;
  }
};

export default OrganizationsSettings;

import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { setMetricsInputIds } from "../../common/dataRefining/store/dataRefiningSlice";
import { triggerRefreshMetrics } from "../store/analyticsSlice";

const useSourceFilter = () => {
  const dispatch = useAppDispatch();

  const { inputTypes } = useAppSelector((state) => state.documentTypesReducer);

  const { inputIds } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const [isFiltered, setIsFiltered] = useState(false);

  const inputTypesSelectOptions = useMemo(() => {
    return inputTypes.map((it) => ({
      label: it.displayName,
      value: it.id,
    }));
  }, [inputTypes]);

  const handleChange = useCallback(
    (value: Array<string>) => {
      dispatch(setMetricsInputIds(value));
      dispatch(triggerRefreshMetrics());
    },
    [dispatch]
  );

  const setInputFilterByName = useCallback(
    (name: string) => {
      const found = inputTypes.find(
        (it) => it.displayName.toLowerCase() === name.toLowerCase()
      );

      if (!found) {
        return;
      }

      if (isFiltered) {
        dispatch(setMetricsInputIds([]));
        dispatch(triggerRefreshMetrics());
        setIsFiltered(false);
      } else {
        dispatch(setMetricsInputIds([found.id]));
        dispatch(triggerRefreshMetrics());
        setIsFiltered(true);
      }
    },
    [dispatch, inputTypes, isFiltered]
  );

  return {
    inputIds,
    inputTypesSelectOptions,
    handleChange,
    setInputFilterByName,
  };
};

export default useSourceFilter;

export const parseSearchableString = (
  searchable: string,
  key: string
): string => {
  if (!searchable || !key) {
    return "N/A";
  }

  const searchAbleList = searchable?.split(";");

  if (!searchAbleList.length) {
    return "N/A";
  }

  const found = searchAbleList
    .map((searchAbleListItem) => searchAbleListItem.toUpperCase())
    .find((searchAbleListItem) =>
      searchAbleListItem.includes(key.toUpperCase())
    );

  if (!found || !found.includes(":")) {
    return "N/A";
  }

  return found.split(":")[1];
};

import React, { FC } from "react";
import { Checkbox } from "antd";
import { DocumentSetData } from "../../../interfaces/documentSet";
import useBulkActionHeader from "../../../hooks/useBulkActionHeader";
import "./BulkActionHeader.scss";

type Props = {
  documentSets: Array<DocumentSetData>;
};
const BulkActionHeader: FC<Props> = ({ documentSets }) => {
  const { selectedRows, isAllSelected, toggleSelectAll } =
    useBulkActionHeader(documentSets);

  return (
    <div className="bulk-action-header__container">
      {selectedRows.length >= 1 && (
        <Checkbox
          indeterminate={!isAllSelected && selectedRows.length >= 1}
          checked={isAllSelected}
          disabled={selectedRows.length < 1}
          onClick={toggleSelectAll}
        />
      )}
    </div>
  );
};

export default BulkActionHeader;

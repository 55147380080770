import { OverviewType, useAppSelector } from "../../app";
import { useMemo } from "react";
import {
  buildValidFilterArrayForOverview,
  getValidStatusesForOverviewType,
} from "../utils/helperFunctions";
import { getOverviewTypeByStatus } from "../utils/dataFiltering";

const useCleanedFilter = (fallBackByStatus = false) => {
  const { overviewType, activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );
  const { overviewFilterArray, archiveFilterArray, filterString } =
    useAppSelector((state) => state.dataRefiningReducer);

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const newOverviewType = useMemo(() => {
    if (!activeDocumentSet || !fallBackByStatus) {
      return overviewType;
    }

    if (!overviewType) {
      return getOverviewTypeByStatus(
        activeDocumentSet.status,
        isAnnotationMode
      );
    }

    return overviewType;
  }, [overviewType, isAnnotationMode, activeDocumentSet, fallBackByStatus]);

  const validStatuses = useMemo(
    () => getValidStatusesForOverviewType(newOverviewType),
    [newOverviewType]
  );

  const correctFilterArray = useMemo(() => {
    if (!newOverviewType) {
      return [];
    }

    if (newOverviewType === OverviewType.Archived) {
      return archiveFilterArray;
    }

    return overviewFilterArray;
  }, [newOverviewType, overviewFilterArray, archiveFilterArray]);

  const filterArray = useMemo(() => {
    return buildValidFilterArrayForOverview(correctFilterArray, validStatuses);
  }, [correctFilterArray, validStatuses]);

  return { filterString, filterArray, overviewType: newOverviewType };
};

export default useCleanedFilter;

import React, { FC } from "react";
import TooltipValue from "./TooltipValue";

type Props = {
  firstColumnLabel: string;
  firstColumnValue?: string;
  secondColumnLabel: string;
  secondColumnValue?: string;
};

const TwoColumnLine: FC<Props> = ({
  firstColumnLabel,
  firstColumnValue,
  secondColumnLabel,
  secondColumnValue,
}) => {
  return (
    <div className="two-column-line">
      {firstColumnValue ? (
        <div className="column">
          <h5 className="label">{firstColumnLabel}</h5>
          <TooltipValue value={firstColumnValue} />
        </div>
      ) : null}
      {secondColumnValue ? (
        <div className="column">
          <h5 className="label">{secondColumnLabel}</h5>
          <TooltipValue value={secondColumnValue} />
        </div>
      ) : null}
    </div>
  );
};

export default TwoColumnLine;

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { resetAnnotatorState } from "../../annotator/store/AnnotatorSlice";
import { useLazyGetPdfDocumentQuery } from "../store/documentApi";

const useDocumentViewer = () => {
  const dispatch = useAppDispatch();

  const { activeDocument } = useAppSelector((state) => state.documentReducer);

  const [getPdfDocument, { data: documentData, isFetching: loading }] =
    useLazyGetPdfDocumentQuery();

  useEffect(() => {
    if (!activeDocument) {
      return;
    }

    getPdfDocument(activeDocument.id);

    return () => {
      dispatch(resetAnnotatorState());
    };
  }, [activeDocument, dispatch, getPdfDocument]);

  return { documentData, loading };
};

export default useDocumentViewer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DEFAULT_TABLE_CONTROLS_HEIGHT } from "../constants";

interface AnnotationTableControlsState {
  selectedGroupBlockId: string;
  triggerGroupBlockTabCheck: boolean;
  isTableControlsResizing: boolean;
  tableControlsHeight: number;
  autoResizeDisabled: boolean;
}

const initialState: AnnotationTableControlsState = {
  selectedGroupBlockId: "",
  triggerGroupBlockTabCheck: false,
  isTableControlsResizing: false,
  tableControlsHeight: DEFAULT_TABLE_CONTROLS_HEIGHT,
  autoResizeDisabled: false,
};

export const annotationTableControlsSlice = createSlice({
  name: "annotationTableControls",
  initialState,
  reducers: {
    setSelectedGroupBlockId: (state, action: PayloadAction<string>) => {
      state.selectedGroupBlockId = action.payload;
    },
    triggerGroupBlockTabCheck: (state) => {
      state.triggerGroupBlockTabCheck = !state.triggerGroupBlockTabCheck;
    },
    setIsTableControlsResizing: (state, action: PayloadAction<boolean>) => {
      state.isTableControlsResizing = action.payload;
    },
    setTableControlsHeight: (state, action: PayloadAction<number>) => {
      state.tableControlsHeight = action.payload;
    },
    setAutoResizeDisabled: (state, action: PayloadAction<boolean>) => {
      state.autoResizeDisabled = action.payload;
    },
  },
});

export const {
  setSelectedGroupBlockId,
  triggerGroupBlockTabCheck,
  setIsTableControlsResizing,
  setTableControlsHeight,
  setAutoResizeDisabled,
} = annotationTableControlsSlice.actions;

export default annotationTableControlsSlice.reducer;

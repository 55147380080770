import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TourPage } from "../hooks/useTour";

const initialState: TourState = {
  run: false,
};

interface TourState {
  run: boolean;
  tourPage?: TourPage;
}

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    stopTour: (state) => {
      return {
        ...state,
        run: false,
        tourPage: undefined,
      };
    },
    setTourPageAndStart: (state, action: PayloadAction<TourPage>) => {
      return {
        ...state,
        tourPage: action.payload,
        run: true,
      };
    },
  },
});

export const { stopTour, setTourPageAndStart } = tourSlice.actions;

export default tourSlice.reducer;

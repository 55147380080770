import { useCallback, useState } from "react";
import { useAppSelector } from "../../app";
import useActiveDocumentSet from "../../documentSet/hooks/useActiveDocumentSet";
import { notification } from "antd";
import useRouting from "./useRouting";
import { ButtonType } from "../../common";
import { Status } from "../../common/status/status";
import { useSendEmailReplyMutation } from "../store/annotationSideControlsApi";

const useRejectModal = (openUserConflictModal: () => void) => {
  const [open, setOpen] = useState(false);

  const user = useAppSelector((state) => state.userReducer.user);

  const { updateAssigneeAndStatus, activeDocumentSet } = useActiveDocumentSet();

  const handleRouting = useRouting(ButtonType.Reject);

  const [sendEmailReply] = useSendEmailReplyMutation();

  const isTextBoxEditorEmpty = useCallback((value: string) => {
    return value.replace(/<(.|\n)*?>/g, "").trim().length === 0;
  }, []);

  const handleAbort = useCallback(
    async (setIsLoading: (isLoading: boolean) => void) => {
      if (!activeDocumentSet) return;

      setIsLoading(true);

      try {
        await updateAssigneeAndStatus(
          activeDocumentSet,
          Status.Rejected,
          user!
        );
        await handleRouting(() => {
          setOpen(false);
          setIsLoading(false);
        });
      } catch (error: any) {
        setOpen(false);
        setIsLoading(false);

        if (error?.status === 409) {
          openUserConflictModal();
        } else {
          notification.error({
            message: "Something went wrong",
            description: `Included message from server: ${error}`,
          });
        }
      }
    },
    [
      activeDocumentSet,
      updateAssigneeAndStatus,
      user,
      handleRouting,
      openUserConflictModal,
    ]
  );

  const handleRejectClick = useCallback(
    async (setIsLoading: (isLoading: boolean) => void) => {
      if (!activeDocumentSet?.email?.sender) {
        await handleAbort(setIsLoading);
        return;
      }

      setOpen(true);
    },
    [activeDocumentSet?.email?.sender, handleAbort]
  );

  const handleSend = useCallback(
    async (setIsLoading: (isLoading: boolean) => void, message: string) => {
      if (!activeDocumentSet) return;

      if (isTextBoxEditorEmpty(message)) {
        notification.error({
          message: "No message written",
          description: "Please provide a message to send.",
        });

        return;
      }

      setIsLoading(true);

      const replyText = message.replace(/<p><br><\/p>/g, "<br>");

      const data = {
        documentSetId: activeDocumentSet.id,
        replyText,
      };

      try {
        setIsLoading(true);

        await updateAssigneeAndStatus(
          activeDocumentSet,
          Status.Rejected,
          user!
        );
        await handleRouting(() => {
          setOpen(false);
          setIsLoading(false);
        });

        await sendEmailReply(data).unwrap();
      } catch (error: any) {
        if (error?.status === 409) {
          openUserConflictModal();
        } else {
          notification.error({
            message: "Something went wrong",
            description: `Included message from server: ${error}`,
          });
        }

        setIsLoading(false);
        setOpen(false);
      }
    },
    [
      activeDocumentSet,
      isTextBoxEditorEmpty,
      openUserConflictModal,
      handleRouting,
      updateAssigneeAndStatus,
      user,
      sendEmailReply,
    ]
  );

  return { open, setOpen, handleRejectClick, handleAbort, handleSend };
};

export default useRejectModal;

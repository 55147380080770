export const onRender = (
  id: string,
  phase: string,
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number
) => {
  // console.log(id, phase, actualDuration, baseDuration);
};

import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo } from "react";
import { EmailTemplate } from "../interfaces/table";
import {
  setFavoriteTemplate,
  setSelectedEmailTemplate,
} from "../store/emailTemplatesSlice";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import useEmailTemplateModal from "./useEmailTemplateModal";
import { useUpdateEmailTemplateMutation } from "../store/emailTemplatesApi";

export const MAX_EMAIL_TEMPLATES = 10;

const useEmailTemplates = () => {
  const { t } = useTranslation("emailTemplates");

  const dispatch = useAppDispatch();

  const { changeModalOpen } = useEmailTemplateModal();

  const { emailTemplates, selectedEmailTemplate } = useAppSelector(
    (state) => state.emailTemplatesReducer
  );

  const [updateEmailTemplateDb] = useUpdateEmailTemplateMutation();

  const user = useAppSelector((state) => state.userReducer.user);

  const favoriteTemplate = useMemo(() => {
    return emailTemplates.find(
      (emailTemplate: EmailTemplate) => emailTemplate.favorite
    );
  }, [emailTemplates]);

  const setFavoriteEmailTemplate = useCallback(
    async (emailTemplate: EmailTemplate) => {
      const oldFavorite = favoriteTemplate
        ? { ...favoriteTemplate }
        : undefined;

      try {
        if (!user) {
          return;
        }

        const updatedEmailTemplate = { ...emailTemplate };
        updatedEmailTemplate.favorite = true;

        dispatch(setFavoriteTemplate(updatedEmailTemplate.id));

        await updateEmailTemplateDb(updatedEmailTemplate).unwrap();

        notification.success({
          key: "defaultTemplateToast",
          message: t("updatedDefaultEmailTemplate").replace(
            "$TEMPLATE",
            emailTemplate.name
          ),
        });
      } catch (e) {
        dispatch(setFavoriteTemplate(oldFavorite?.id));

        notification.error({
          message: t("errorMessage.message"),
          description: t("errorMessage.description").replace(
            "$MESSAGE",
            "setting an email template to default"
          ),
        });
        console.log(e);
      }
    },
    [dispatch, t, user, favoriteTemplate, updateEmailTemplateDb]
  );

  const isMaxEmailTemplateReached = useMemo((): boolean => {
    return emailTemplates.length >= MAX_EMAIL_TEMPLATES;
  }, [emailTemplates]);

  const openModalAndSelectEmailTemplate = useCallback(
    (emailTemplate?: EmailTemplate) => {
      if (!emailTemplate && isMaxEmailTemplateReached) {
        notification.warning({
          message: t("maxLimitReached").replace(
            "$MAX_LIMIT",
            MAX_EMAIL_TEMPLATES.toString()
          ),
        });
        return;
      }

      if (!emailTemplate) {
        emailTemplate = { name: "", body: "" };
      }

      dispatch(setSelectedEmailTemplate(emailTemplate));
      changeModalOpen();
    },
    [isMaxEmailTemplateReached, changeModalOpen, t, dispatch]
  );

  return {
    emailTemplates,
    selectedEmailTemplate,
    setFavoriteEmailTemplate,
    favoriteTemplate,
    isMaxEmailTemplateReached,
    openModalAndSelectEmailTemplate,
  };
};

export default useEmailTemplates;

import { useAppSelector } from "../../app";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { useFetchEmailTemplatesQuery } from "../store/emailTemplatesApi";

const useFetchEmailTemplates = () => {
  const user = useAppSelector((state) => state.userReducer.user);

  const { isFetching } = useFetchEmailTemplatesQuery(user?.id ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  return isFetching;
};

export default useFetchEmailTemplates;

import React, { FC, useMemo } from "react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app";
import { DocumentSetFetchingErrorType } from "../../interfaces/documentSet";

const FetchingErrorAlert: FC = () => {
  const { t } = useTranslation("overview", {
    keyPrefix: "fetchingErrorType",
  });

  const { fetchingErrorType } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const message = useMemo(() => {
    if (!fetchingErrorType) {
      return null;
    }

    if (fetchingErrorType === DocumentSetFetchingErrorType.INVALID_QUERY) {
      return t("invalidQuery");
    } else {
      return t("otherError");
    }
  }, [fetchingErrorType, t]);

  if (!fetchingErrorType) {
    return null;
  }

  return <Alert message={message} type="error" showIcon />;
};

export default FetchingErrorAlert;

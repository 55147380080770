import { ExtensionType } from "../interfaces";
import { Document } from "../";
import { CSV_FILE_TYPES, XML_FILE_TYPES } from "../constants";
import { DocumentSetData } from "../../documentSet";

export const determineExtension = (
  document?: Document,
  documentSet?: DocumentSetData
): ExtensionType | null => {
  if (!document || !documentSet) {
    return ExtensionType.LOADING;
  }

  const {
    input: { legacyAnnotator },
  } = documentSet;
  const { name, metaData, pageCount } = document;

  if (XML_FILE_TYPES.includes(metaData?.fileType)) {
    return ExtensionType.XML;
  }

  if (CSV_FILE_TYPES.includes(metaData?.fileType)) {
    return ExtensionType.CSV;
  }

  if (legacyAnnotator || pageCount <= 0) {
    return ExtensionType.PDF_LEGACY;
  }

  if (name.toLowerCase().includes(".pdf")) {
    return ExtensionType.PDF;
  }

  return null;
};

import { SortDirection, SortState } from "../interfaces";
import { DocumentSetColumns } from "../../../documentSet/interfaces/documentSet";

export const parseActiveSorterFromSessionStorage = (
  storageKey: string,
  defaultDirection: SortDirection
): SortState | null => {
  if (storageKey in sessionStorage) {
    const sessionStorageValue = sessionStorage.getItem(storageKey);
    if (sessionStorageValue?.includes(":")) {
      const [key, value] = sessionStorageValue.split(":");
      return {
        key: key as keyof DocumentSetColumns,
        sortDirection: parseInt(value),
      };
    }

    return null;
  }

  return {
    key: "createdDate",
    sortDirection: defaultDirection,
  };
};

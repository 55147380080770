import React, { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import "./PillLabel.scss";
import { Status } from "../status/status";
import classNames from "classnames";

type Props = {
  status: Status;
};

const TO_DO_STATUSES = [Status.ToDo, Status.AnnotationToDo];
const ONGOING_STATUSES = [
  Status.InProgress,
  Status.OnHold,
  Status.AnnotationInProgress,
  Status.AnnotationOnHold,
];
const RED_STATUSES = [
  Status.Rejected,
  Status.Error,
  Status.Remove,
  Status.ExportFailed,
];
const DONE_STATUSES = [
  Status.Approved,
  Status.Archived,
  Status.AnnotationDone,
  Status.Exported,
];
const PROCESSING_STATUSES = [Status.Processing];

const PillLabel: FC<Props> = ({ status }) => {
  const { t } = useTranslation("overview");
  const colorClass: string = classNames({
    "pill-label": true,
    "to-do": TO_DO_STATUSES.includes(status),
    ongoing: ONGOING_STATUSES.includes(status),
    done: DONE_STATUSES.includes(status),
    rejected: RED_STATUSES.includes(status),
    processing: PROCESSING_STATUSES.includes(status),
  });

  return <div className={colorClass}>{t(`status.${status}`)}</div>;
};

export default memo(PillLabel);

import React, { FC, useMemo } from "react";
import { Button, Table } from "antd";
import useOrganization from "../hooks/useOrganization";
import { useTranslation } from "react-i18next";
import UserManagementModal from "./userManagementModal/UserManagementModal";
import { ColumnsType } from "antd/es/table";
import { TableData } from "../interfaces/table";
import { CustomAvatar } from "../../common/avatar";
import AddMemberModal from "./addMemberModal/AddMemberModal";
import { changeEditingMemberId } from "../store/UserManagementSlice";
import { useAppDispatch, useAppSelector } from "../../app";
import useTableDynamicHeight, {
  TableType,
} from "../../common/table/hooks/useTableDynamicHeight";
import "./UserManagement.scss";
import OrganizationInvitesModal from "./organizationInvitesModal/OrganizationInvitesModal";
import PermissionTags from "./permissionTags/PermissionTags";
import TableHeaderFilter from "../../common/tableHeaderFilter/TableHeaderFilter";
import RoleIcons from "./roleIconMapper/RoleIcons";
import useOrgPermissionTagsInfo from "../hooks/useOrgPermissionTagsInfo";
import useTableDataFilter, {
  FuseConfig,
} from "../../common/dataFiltering/useTableDataFilter";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useFetchMembersQuery } from "../store/UserManagementApi";
import TableColumnContentLoader from "../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";
import { SkeletonLoaderType } from "../../common/skeletonLoader/interfaces/type";
import useMemberModal from "../hooks/useMemberModal";
import useOrganizationInvites from "../hooks/useOrganizationInvites";
import TitleRowWithContent from "../../common/titleRowWithContent/TitleRowWithContent";
import TableCsvExportButton from "../../common/tableCsvExportButton/TableCsvExportButton";

const SKELETON_LOAD_ROWS = 5;

const emptyFilter: Partial<TableData> = {
  fullName: "",
  email: "",
  permissions: [],
  roles: [],
};

const fuseConfig: FuseConfig<TableData> = {
  keys: ["fullName", "email", "permissions", "roles"],
};

const UserManagement: FC = () => {
  const dispatch = useAppDispatch();

  const { members, organizationSources, validRoles } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const user = useAppSelector((state) => state.userReducer.user);

  const { changeModalOpen } = useMemberModal();
  const { changeModalOpen: changeModalOpenInvites } =
    useOrganizationInvites(false);

  const { isFetching } = useFetchMembersQuery(
    user?.organization.id ?? skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const {
    handleFilterUpdate,
    applyFilter,
    filteredData,
    getValueFromFilterQuery,
  } = useTableDataFilter(members, emptyFilter, fuseConfig, "id");

  const { mapMemberFieldsForExport } = useOrganization();

  const orgPermissionTagsInfo = useOrgPermissionTagsInfo();

  const { t } = useTranslation("userManagement");

  const { refHeightContainer, heightTableContent } = useTableDynamicHeight(
    TableType.USER_MANAGEMENT
  );
  const memberRows = useMemo(() => {
    if (isFetching) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    return filteredData;
  }, [isFetching, filteredData]);

  const columns: ColumnsType<TableData> = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      width: 70,
      render: (_, { avatar }) => (
        <TableColumnContentLoader
          loading={isFetching}
          type={SkeletonLoaderType.AVATAR}
        >
          <CustomAvatar size="default" src={avatar} />
        </TableColumnContentLoader>
      ),
    },
    {
      title: (
        <div className="header-filter__container">
          {t("name")}
          <TableHeaderFilter
            prop="fullName"
            updateFilter={handleFilterUpdate}
            label={t("name")}
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("fullName")}
          />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 300,
      render: (_, { firstName, lastName }) => (
        <TableColumnContentLoader loading={isFetching}>
          <span>
            {firstName} {lastName}
          </span>
        </TableColumnContentLoader>
      ),
    },
    {
      title: (
        <div className="header-filter__container">
          {t("email")}
          <TableHeaderFilter
            prop="email"
            updateFilter={handleFilterUpdate}
            label={t("email")}
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("email")}
          />
        </div>
      ),
      dataIndex: "email",
      key: "email",
      width: 350,
      render: (_, column) => (
        <TableColumnContentLoader loading={isFetching}>
          {column.email}
        </TableColumnContentLoader>
      ),
    },
    {
      title: (
        <div className="header-filter__container">
          {t("rolesTitle")}
          <TableHeaderFilter
            prop="roles"
            updateFilter={handleFilterUpdate}
            label={t("rolesTitle")}
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("roles")}
            options={validRoles.map((role) => ({
              value: role.id,
              label: role.name,
            }))}
          />
        </div>
      ),
      dataIndex: "roles",
      key: "roles",
      width: 120,
      render: (_, { roles }) => (
        <TableColumnContentLoader loading={isFetching}>
          <RoleIcons roles={roles} />
        </TableColumnContentLoader>
      ),
    },
    {
      title: (
        <div className="header-filter__container">
          {t("sources")}
          <TableHeaderFilter
            prop="permissions"
            updateFilter={handleFilterUpdate}
            label={t("sources")}
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("permissions")}
            options={organizationSources.map((source) => ({
              value: source.id,
              label: source.displayName,
            }))}
          />
        </div>
      ),
      dataIndex: "sources",
      key: "sources",
      render: (_, { permissions }) => (
        <TableColumnContentLoader loading={isFetching}>
          <PermissionTags
            permissions={permissions}
            orgPermissionTagsInfo={orgPermissionTagsInfo}
          />
        </TableColumnContentLoader>
      ),
    },
    {
      title: (
        <TableCsvExportButton
          data={filteredData}
          prefixFileName="Members"
          mapFields={mapMemberFieldsForExport}
        />
      ),
      dataIndex: "export",
      key: "export",
      width: 60,
    },
  ];

  return (
    <div className="user-management__container" ref={refHeightContainer}>
      <TitleRowWithContent
        title="User management"
        content={
          <>
            <Button
              type="primary"
              size="small"
              className="open-invite-button"
              onClick={changeModalOpenInvites}
              icon={<i className="bi bi-envelope"></i>}
            >
              {t("buttons.showPendingInvites")}
            </Button>
            <Button type="primary" size="small" onClick={changeModalOpen}>
              {t("buttons.addMember")}
            </Button>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={memberRows}
          pagination={false}
          rowKey={(record) => record.id}
          className="user-management__table"
          onRow={(record) => {
            return {
              onClick: () => dispatch(changeEditingMemberId(record.id)),
            };
          }}
          scroll={{ y: heightTableContent }}
        />
        <div className="user-management__placeholder" />
        <AddMemberModal />
        <UserManagementModal />
        <OrganizationInvitesModal />
      </TitleRowWithContent>
    </div>
  );
};

export default UserManagement;

import { ConfigMap, Translation } from "./interfaces/configmap";
import {
  GroupBlockData,
  UpdateGroupBlockCountAction,
  GroupBlockCount,
} from "./interfaces/groupblock";
import { Component } from "./interfaces/component";
import { EntityType, GroupBlockEntityType } from "./interfaces/entity";
import ConfigMapReducer from "./store/configMapSlice";
import { getFlatEntityListFromConfigMap } from "./utils/entityUtils";
import useMultipleGroupBlocks from "./hooks/useMultipleGroupBlocks";
import useHotKeyListener from "./hooks/useHotKeyListener";
import useValidation from "./hooks/useValidation";
import useTab from "./hooks/useTab";
import useTableAnnotatingListener from "./hooks/useTableAnnotatingListener";
import GroupBlocksContainer from "./components/groupBlock/GroupBlocksContainer";
import GroupBlocksHeaderLoading from "./components/groupBlock/groupBlockHeader/GroupBlocksHeaderLoading";

export {
  ConfigMapReducer,
  getFlatEntityListFromConfigMap,
  useMultipleGroupBlocks,
  useHotKeyListener,
  useValidation,
  useTab,
  useTableAnnotatingListener,
  GroupBlocksHeaderLoading,
  Component,
};

export type {
  ConfigMap,
  GroupBlockData,
  GroupBlockEntityType,
  GroupBlockCount,
  EntityType,
  Translation,
  UpdateGroupBlockCountAction,
};

export default GroupBlocksContainer;

import http from "../../common/utilities/HttpModule";
import { OptionType } from "../interfaces/classifier";

export const fetchClassifierOptions = (endpoint: string, signal: AbortSignal) =>
  http.get<Array<OptionType>>(`data/api/v1${endpoint}`, {
    signal,
  });

export const fetchClassifierOptionsSearch = (endpoint: string, value: string) =>
  http.get<Array<OptionType>>(`${endpoint}?${encodeURI(value)}`);

import React, { forwardRef, MouseEventHandler, useMemo } from "react";
import { Button as AntButton } from "antd";
import "./Button.scss";
import ShowDetailIcon from "./ShowDetailIcon";
import ButtonType from "./ButtonTypes";
import propBuilder from "classnames";

interface ButtonProps {
  text: string;
  buttonType: ButtonType;
  handleClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  htmlType?: "button" | "submit" | "reset";
  className?: string;
  icon?: React.ReactNode;
  id?: string;
}

const PRIMARY_BUTTON_TYPES = [
  ButtonType.Primary,
  ButtonType.Approved,
  ButtonType.Approve,
  ButtonType.Upload,
];
const DEFAULT_BUTTON_TYPES = [
  ButtonType.Default,
  ButtonType.ShowDetails,
  ButtonType.Hold,
  ButtonType.Reject,
];

const Button = forwardRef<any, ButtonProps>(
  (
    {
      id,
      text,
      buttonType,
      handleClick = undefined,
      disabled = false,
      htmlType = undefined,
      className = undefined,
      icon = undefined,
    }: ButtonProps,
    ref
  ) => {
    const buttonClass: string = propBuilder([
      className,
      {
        "btn-approve": buttonType === ButtonType.Approve,
        "btn-approved": buttonType === ButtonType.Approved,
        "btn-reject":
          buttonType === ButtonType.Reject || buttonType === ButtonType.Hold,
        "btn-rejected": buttonType === ButtonType.Rejected,
        "btn-show-details": buttonType === ButtonType.ShowDetails,
      },
    ]);

    const type: "primary" | "default" | "dashed" | undefined = propBuilder({
      primary: PRIMARY_BUTTON_TYPES.includes(buttonType),
      default: DEFAULT_BUTTON_TYPES.includes(buttonType),
      danger: buttonType === ButtonType.Rejected,
    }) as "primary" | "default" | "dashed" | undefined;

    const shape: "round" | undefined = propBuilder({
      round:
        buttonType === ButtonType.Primary || buttonType === ButtonType.Default,
    }) as "round" | undefined;

    const iconToUse = useMemo(() => {
      if (icon) {
        return icon;
      }

      switch (buttonType) {
        case ButtonType.Approve:
          return <i className="bi bi-check-lg" />;
        case ButtonType.Reject:
          return <i className="bi bi-trash" />;
        case ButtonType.Hold:
          return <i className="bi bi-eraser" />;
        case ButtonType.Upload:
          return <i className="bi bi-upload" />;
        default:
          return null;
      }
    }, [buttonType, icon]);

    return (
      <AntButton
        id={id}
        ref={ref}
        type={type}
        className={buttonClass}
        icon={iconToUse}
        shape={shape}
        onClick={handleClick}
        disabled={disabled}
        htmlType={htmlType}
      >
        {text}
        {buttonType === ButtonType.ShowDetails && <ShowDetailIcon size={19} />}
      </AntButton>
    );
  }
);

Button.displayName = "Button";

export default Button;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import { getAccessibleTextColor, hex2rgba } from "../../common/utilities/color";
import { groupItems } from "../utils/clustering";
import { CSSProperties } from "react";
import { calculateBoundingBoxForAnnotationGroup } from "../../annotator/helpers/pdfHelpers";
import { Rectangle } from "../../annotator/interfaces/textLayer";
import { INACTIVE_TOKEN_COLOR } from "../constants";
import { GroupBlockEntityType } from "../../configMap";
import { HighlightedEntity } from "../interfaces/annotation";

type Props = {
  pageNumber: number;
  tokenIndices: Array<number> | null;
  isTempAnnotation: boolean;
  color: string;
  entityId: string;
  index: number;
};

type Response = {
  id: string;
  style: CSSProperties;
  coords: Rectangle;
};

const buildStyle = (
  isTempAnnotation: boolean,
  color: string,
  backgroundColor: string,
  width: number,
  height: number,
  top: number,
  left: number,
  rotation: number
): CSSProperties => {
  if (isTempAnnotation) {
    return {
      backgroundColor: hex2rgba(backgroundColor, 0.4),
      outline: `2px dashed ${hex2rgba(backgroundColor, 0.5)}`,
      width: `${width}px`,
      height: `${height}px`,
      transform: `rotate(${rotation}deg)`,
      top,
      left,
    };
  }

  return {
    backgroundColor: hex2rgba(backgroundColor, 0.4),
    outline: `2px solid ${hex2rgba(backgroundColor, 0.5)}`,
    width: `${width}px`,
    height: `${height}px`,
    transform: `rotate(${rotation}deg)`,
    color,
    top,
    left,
  };
};

const shouldBeHighlighted = (
  index: number,
  entityId: string,
  highlightedEntities: Array<HighlightedEntity>,
  activeEntity?: GroupBlockEntityType
) => {
  if (!activeEntity && !highlightedEntities.length) {
    return true;
  }

  if (
    activeEntity?.entityType.id === entityId &&
    activeEntity?.index === index
  ) {
    return true;
  }

  const found = highlightedEntities.find(
    (he) => he.id === entityId && he.index === index
  );

  return !!found;
};

const getPdf = (state: RootState) => state.documentReducer.pdf;
const getActiveEntity = (state: RootState) => state.appReducer.activeEntity;
const getHighlightedEntities = (state: RootState) =>
  state.annotationReducer.highlightedEntities;
const getScale = (state: RootState) => state.annotationReducer.scale;
const getPageNumber = (_: RootState, props: Props) => props.pageNumber;
const getTokenIndices = (_: RootState, props: Props) => props.tokenIndices;
const getIsTempAnnotation = (_: RootState, props: Props) =>
  props.isTempAnnotation;
const getColor = (_: RootState, props: Props) => props.color;
const getEntityId = (_: RootState, props: Props) => props.entityId;
const getIndex = (_: RootState, props: Props) => props.index;

const tokenForAnnotationsSelector = createSelector(
  [
    getPdf,
    getActiveEntity,
    getHighlightedEntities,
    getScale,
    getPageNumber,
    getTokenIndices,
    getIsTempAnnotation,
    getColor,
    getEntityId,
    getIndex,
  ],
  (
    pdf,
    activeEntity,
    highlightEntities,
    scale,
    pageNumber,
    tokenIndices,
    isTempAnnotation,
    color,
    entityId,
    index
  ): Array<Response> | null => {
    if (!pdf?.pages?.length) {
      return null;
    }

    const { pages } = pdf;

    const page = pages.find((p) => p.page === pageNumber);

    if (!page) {
      return null;
    }

    if (!tokenIndices?.length) {
      return null;
    }

    const foundTokensForIndices = page.tokens.filter((t) =>
      tokenIndices.includes(t.dataI)
    );

    if (!foundTokensForIndices.length) {
      return null;
    }

    const groupedIndices = groupItems(foundTokensForIndices, 100 * scale);

    return groupedIndices.map((gi) => {
      const id = gi.map((i) => i.dataI).join("");

      const calcCoords = calculateBoundingBoxForAnnotationGroup(gi, scale);
      let colorToUse = color;

      if (
        !shouldBeHighlighted(index, entityId, highlightEntities, activeEntity)
      ) {
        colorToUse = INACTIVE_TOKEN_COLOR;
      }

      const { top, left, width, height, rotation } = calcCoords;
      const accessibleTextColor = getAccessibleTextColor(colorToUse);

      const style = buildStyle(
        isTempAnnotation,
        accessibleTextColor,
        colorToUse,
        width,
        height,
        top,
        left,
        rotation
      );

      return {
        id,
        style,
        coords: calcCoords,
      };
    });
  }
);

export default tokenForAnnotationsSelector;

import { useCallback } from "react";
import { useLazyGetPdfDocumentQuery } from "../../../document/store/documentApi";

const useDownloadPdf = () => {
  const [getPdfDocument] = useLazyGetPdfDocumentQuery();

  return useCallback(
    async (id: string, fileName: string) => {
      try {
        const data = await getPdfDocument(id).unwrap();
        const blob = new Blob([data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [getPdfDocument]
  );
};

export default useDownloadPdf;

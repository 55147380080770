import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import { ICategorization } from "../../configMap/interfaces/category";
import {
  modifyDocumentCategoryAnnotationsByGroupBlockIndexInState,
  removeDocumentCategoryAnnotationsByCategorizationsInState,
  resetDocumentCategoryAnnotationsInState,
  updateGroupBlockDocumentCategoryAnnotationsInState,
} from "../store/metadataSlice";

const useDocumentCategoryAnnotations = () => {
  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const dispatch = useAppDispatch();

  const modifyDocumentCategoryAnnotations = useCallback(
    (
      categorizations: Array<ICategorization>,
      groupBlockIndex: number,
      isGroupBlockRemoved: boolean,
      documentId?: string
    ) => {
      if (!documentId || !documentCategoryAnnotations.length) {
        return;
      }

      dispatch(
        modifyDocumentCategoryAnnotationsByGroupBlockIndexInState({
          documentId,
          categorizations,
          groupBlockIndex,
          isGroupBlockRemoved,
        })
      );
    },
    [documentCategoryAnnotations, dispatch]
  );

  const removeDocumentCategoryAnnotationsByCategorizations = useCallback(
    (categorizations: Array<ICategorization>, documentId?: string) => {
      if (!documentId || !documentCategoryAnnotations.length) {
        return;
      }

      dispatch(
        removeDocumentCategoryAnnotationsByCategorizationsInState({
          documentId,
          categorizations,
        })
      );
    },
    [documentCategoryAnnotations, dispatch]
  );

  const updateGroupBlockDocumentCategoryAnnotations = useCallback(
    (
      categorizations: Array<ICategorization>,
      groupBlockIndex: number,
      addBefore: boolean = false
    ) => {
      if (!documentCategoryAnnotations.length) {
        return;
      }

      dispatch(
        updateGroupBlockDocumentCategoryAnnotationsInState({
          categorizations,
          groupBlockIndex,
          addBefore,
        })
      );
    },
    [documentCategoryAnnotations, dispatch]
  );

  const resetDocumentCategoryAnnotations = useCallback(() => {
    dispatch(resetDocumentCategoryAnnotationsInState());
  }, [dispatch]);

  return {
    modifyDocumentCategoryAnnotations,
    removeDocumentCategoryAnnotationsByCategorizations,
    updateGroupBlockDocumentCategoryAnnotations,
    resetDocumentCategoryAnnotations,
  };
};

export default useDocumentCategoryAnnotations;

import React, { FC, MutableRefObject, useEffect, useRef } from "react";
import { Badge, Button, Popover, Row } from "antd";
import { OverviewType } from "../../../../app";
import { useTranslation } from "react-i18next";
import FilterableItemSwitch from "./FilterableItemSwitch";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import FilterItemLabel from "./FilterItemLabel";
import { ColumnItem } from "../../../interfaces/overview";
import "./TableHeaderFilter.scss";
import useKeyPressedListener from "../../../../annotator/hooks/useKeyPressedListener";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";

interface Props {
  overviewType: OverviewType;
  columnItem: ColumnItem;
  loading: boolean;
}

interface PropExtended extends Props {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
}

const TableHeaderFilter: FC<Props> = ({
  overviewType,
  columnItem,
  loading,
}) => {
  const { hasFilter, handleOpenChange, isOpen } = useTableHeaderFilter(
    columnItem,
    overviewType,
    loading
  );

  const enterPressed = useKeyPressedListener("ENTER");

  useEffect(() => {
    if (enterPressed) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, enterPressed]);

  return (
    <div
      className="column-header-container"
      onClick={(event) => event.stopPropagation()}
    >
      <Row className="content-align-container">
        <Popover
          placement="bottomRight"
          content={
            <FilterPopoverBody
              overviewType={overviewType}
              setIsOpen={handleOpenChange}
              columnItem={columnItem}
              isOpen={isOpen}
              loading={loading}
            />
          }
          overlayClassName="filter-popover"
          trigger="click"
          onOpenChange={handleOpenChange}
          open={isOpen}
          destroyTooltipOnHide
        >
          <Badge dot={hasFilter} size="small" color="#38b2ac">
            <i
              className="bi bi-funnel-fill overview-header__icon"
              style={{ cursor: "pointer", color: "white" }}
            />
          </Badge>
        </Popover>
      </Row>
    </div>
  );
};

const FilterPopoverBody: FC<PropExtended> = ({
  overviewType,
  setIsOpen,
  columnItem,
  isOpen,
}) => {
  const renderRef: MutableRefObject<any> = useRef(null);
  const {
    t,
    i18n: { language },
  } = useTranslation("overview");

  return (
    <div className="filter-popover__container" ref={renderRef}>
      <FilterableItemSwitch
        renderRef={renderRef}
        overviewType={overviewType}
        columnItem={columnItem}
        filterComponentType={
          columnItem.metadata?.filterAble!.filterComponentType
        }
        label={
          <FilterItemLabel
            translations={columnItem.translations!}
            tooltip={columnItem.metadata?.filterAble!.tooltip}
          />
        }
        placeholder={`${t("filters.placeholder")} ${getInLanguageOrDefault(
          columnItem.translations!,
          language
        ).toLowerCase()}`}
        options={columnItem.metadata?.filterAble!.options}
        mode={columnItem.metadata?.filterAble!.mode}
        isOpen={isOpen}
      />
      <Button type="primary" onClick={() => setIsOpen(false)} block>
        {t("filters.button")}
      </Button>
    </div>
  );
};

export default TableHeaderFilter;

import React, { FC, useMemo } from "react";
import {
  InputNumber,
  Select,
  Space,
  Switch,
  Table,
  TableColumnsType,
  Tooltip,
} from "antd";
import { IEntityStpTableData } from "../../../../interfaces/stp";
import TableHeaderFilter from "../../../../../common/tableHeaderFilter/TableHeaderFilter";
import {
  sortAlphabetically,
  sortByBoolean,
  sortNumerically,
} from "../../../../utils/sorting";
import useTableDataFilter, {
  FuseConfig,
} from "../../../../../common/dataFiltering/useTableDataFilter";
import useConfigMapSettings from "../../../../hooks/useConfigMapSettings";
import "./StpEntities.scss";
import TableColumnContentLoader from "../../../../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";
import { SkeletonLoaderType } from "../../../../../common/skeletonLoader/interfaces/type";
import { useTranslation } from "react-i18next";
import useStpEntityTable from "../../../../hooks/useStpEntityTable";
import StpEntityTableFooter from "./StpEntityTableFooter";
import useFetchStpSettings from "../../../../hooks/useFetchStpSettings";
import useTableDynamicHeight, {
  TableType,
} from "../../../../../common/table/hooks/useTableDynamicHeight";
import TitleRowWithContent from "../../../../../common/titleRowWithContent/TitleRowWithContent";
import { OrganizationSettingsMenuKeys } from "../../../../utils/constants";

const SKELETON_LOAD_ROWS = 10;

const emptyFilter: Partial<IEntityStpTableData> = {
  name: "",
};

const fuseConfig: FuseConfig<IEntityStpTableData> = {
  keys: ["entityTypeId", "entityValidation", "sources"],
};

const StpEntities: FC = () => {
  const { t } = useTranslation("stp");

  const { refHeightContainer, heightTableContent } = useTableDynamicHeight(
    TableType.STP
  );

  const { loadingEntities: loading } = useFetchStpSettings(
    OrganizationSettingsMenuKeys.STP_ENTITIES
  );

  const {
    stpEntities,
    sourcesOptions,
    entityOptions,
    validationOptions,
    handleEntityChange,
    selectedRows,
    setSelectedRows,
    removeEntities,
    documentTypeId,
    onSelectSearch,
    postDocumentTypeChange,
    isAdding,
    setIsAdding,
  } = useStpEntityTable();

  const {
    selectedDocumentTypeId,
    documentTypesSelectOptions,
    handleDocumentTypeChange,
  } = useConfigMapSettings(postDocumentTypeChange);

  const {
    handleFilterUpdate,
    applyFilter,
    filteredData,
    getValueFromFilterQuery,
  } = useTableDataFilter(stpEntities, emptyFilter, fuseConfig, "id");

  const data = useMemo(() => {
    if (loading) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    return filteredData;
  }, [loading, filteredData]);

  const columns: TableColumnsType<IEntityStpTableData> = [
    {
      title: () => (
        <div className="header-filter__container">
          {t("entities.entity")}
          <TableHeaderFilter
            prop="entityTypeId"
            updateFilter={handleFilterUpdate}
            label="Entity"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("entityTypeId")}
            options={entityOptions}
          />
        </div>
      ),
      key: "entityTypeId",
      dataIndex: "entityTypeId",
      showSorterTooltip: true,
      width: "180px",
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <Select
            className="entity-select"
            placeholder={t("entities.selectEntity")}
            style={{ width: "100%" }}
            value={e.entityTypeId}
            allowClear={true}
            onChange={(value) =>
              handleEntityChange(e.id!, value, "entityTypeId")
            }
            options={entityOptions}
            disabled={true}
          />
        </TableColumnContentLoader>
      ),
    },
    {
      title: () => (
        <div className="header-filter__container">
          {t("entities.validation")}
          <TableHeaderFilter
            prop="entityValidation"
            updateFilter={handleFilterUpdate}
            label="Validation"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("entityValidation")}
            options={validationOptions}
          />
        </div>
      ),
      key: "entityValidation",
      width: "150px",
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <Select
            placeholder={t("entities.selectValidation")}
            style={{ width: "100%" }}
            value={e.entityValidation}
            allowClear={true}
            onChange={(value) =>
              handleEntityChange(e.id!, value, "entityValidation")
            }
            options={validationOptions}
          />
        </TableColumnContentLoader>
      ),
    },
    {
      title: t("entities.threshold"),
      key: "threshold",
      width: "80px",
      sorter: (a, b) => sortNumerically(a.threshold, b.threshold),
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <InputNumber
            min={50}
            max={100}
            style={{ width: "100%" }}
            value={e.threshold}
            onChange={(value) => handleEntityChange(e.id!, value, "threshold")}
            changeOnWheel={false}
          />
        </TableColumnContentLoader>
      ),
    },
    {
      title: () => (
        <div className="header-filter__container">
          {t("entities.sources")}
          <TableHeaderFilter
            prop="sources"
            updateFilter={handleFilterUpdate}
            label="Sources"
            applyFilter={applyFilter}
            value={getValueFromFilterQuery("sources")}
            options={sourcesOptions}
          />
        </div>
      ),
      key: "sources",
      width: "300px",
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <Select
            style={{ width: "100%" }}
            placeholder={t("entities.selectSources")}
            value={e.sources?.length ? e.sources : null}
            allowClear={true}
            mode="multiple"
            onChange={(value) => handleEntityChange(e.id!, value, "sources")}
            options={sourcesOptions}
            filterOption={onSelectSearch}
            maxTagCount={4}
            maxTagPlaceholder={(omittedValues) => (
              <Tooltip
                title={omittedValues.map((value) => value.label).join(", ")}
              >
                +{omittedValues.length}
              </Tooltip>
            )}
          />
        </TableColumnContentLoader>
      ),
    },
    {
      title: t("entities.active"),
      key: "action",
      width: "70px",
      showSorterTooltip: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => sortByBoolean(a.isActive, b.isActive),
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <div>
            <Switch
              value={e.isActive}
              onChange={(value) => handleEntityChange(e.id!, value, "isActive")}
            />
          </div>
        </TableColumnContentLoader>
      ),
    },
    {
      key: "delete",
      width: "70px",
      render: (_, e) => (
        <TableColumnContentLoader
          loading={loading}
          type={SkeletonLoaderType.LINE}
        >
          <div className="delete-container">
            <div
              className="delete-button"
              onClick={() => removeEntities([e.id!])}
            >
              <span className="bi bi-trash" />
            </div>
          </div>
        </TableColumnContentLoader>
      ),
    },
  ];

  return (
    <div className="stp-entities__container" ref={refHeightContainer}>
      <TitleRowWithContent
        title="STP Entities"
        content={
          <Space>
            <label>{t("entities.selectDocumentType")}:</label>
            <Select
              onChange={handleDocumentTypeChange}
              style={{ width: "250px" }}
              value={selectedDocumentTypeId}
              options={documentTypesSelectOptions}
            />
          </Space>
        }
      >
        <Table
          className="stp-entities-table"
          columns={columns}
          dataSource={data}
          size="small"
          rowSelection={{
            selectedRowKeys: selectedRows,
            onChange: setSelectedRows,
            columnWidth: 30,
          }}
          pagination={false}
          scroll={{ y: heightTableContent }}
          footer={() => (
            <div className="footer__add-button">
              <StpEntityTableFooter
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                sourcesOptions={sourcesOptions}
                validationOptions={validationOptions}
                removeEntities={removeEntities}
                documentTypeId={documentTypeId}
                isAdding={isAdding}
                setIsAdding={setIsAdding}
              />
            </div>
          )}
        />
      </TitleRowWithContent>
    </div>
  );
};

export default StpEntities;

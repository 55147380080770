import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailTemplate } from "../interfaces/table";

interface EmailTemplatesState {
  emailTemplates: Array<EmailTemplate>;
  selectedEmailTemplate: EmailTemplate | undefined;
}

const initialState: EmailTemplatesState = {
  emailTemplates: [],
  selectedEmailTemplate: undefined,
};

export const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    fetchedEmailTemplates: (
      state,
      action: PayloadAction<Array<EmailTemplate>>
    ) => {
      state.emailTemplates = action.payload;
    },
    updateEmailTemplate: (state, action: PayloadAction<EmailTemplate>) => {
      const toUpdateEmailTemplate = action.payload;

      state.emailTemplates = (state.emailTemplates || []).map((et) => {
        if (et.id === toUpdateEmailTemplate.id) {
          return toUpdateEmailTemplate;
        }
        return et;
      });
    },
    setFavoriteTemplate: (state, action: PayloadAction<string | undefined>) => {
      const emailTemplateId = action.payload;

      state.emailTemplates = (state.emailTemplates || []).map((d) => {
        d.favorite = d.id === emailTemplateId;
        return d;
      });
    },
    addEmailTemplate: (state, action: PayloadAction<EmailTemplate>) => {
      const toCreateEmailTemplate = action.payload;
      state.emailTemplates.push(toCreateEmailTemplate);
    },
    removeEmailTemplate: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.emailTemplates = state.emailTemplates.filter((d) => d.id !== id);
    },
    setSelectedEmailTemplate: (
      state,
      action: PayloadAction<EmailTemplate | undefined>
    ) => {
      state.selectedEmailTemplate = action.payload;
    },
  },
});

export const {
  fetchedEmailTemplates,
  updateEmailTemplate,
  setFavoriteTemplate,
  addEmailTemplate,
  removeEmailTemplate,
  setSelectedEmailTemplate,
} = emailTemplatesSlice.actions;

export default emailTemplatesSlice.reducer;

import React, { useEffect } from "react";
import useKeyPressedListener from "../../../common/utilities/hooks/useKeyPressedListener";
import { Col, Modal, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import "./ShortcutHelp.scss";
import { getPlatform } from "../../../common/utilities/platform";
import { useAppDispatch, useAppSelector } from "../../../app";
import selectModalStateByKey from "../../../app/selectors/modalStateSelector";
import { changeModalStateForKey } from "../../../app/store/appSlice";
import reactHtmlParser from "html-react-parser";

const GLOBAL_SHORTCUTS = ["help"];

const DETAILS_SHORTCUTS = [
  "previousDocument",
  "nextDocument",
  "anyKey",
  "ctrlAnnotation",
  "previousTab",
  "nextTab",
];

const PLATFORM = getPlatform();
export const shortcutModalKey = "shortcutHelp";

const ShortcutHelp = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("help");
  const { keyPressed } = useKeyPressedListener("H", true, true);

  const open = useAppSelector((state) =>
    selectModalStateByKey(state, { modalKey: shortcutModalKey })
  );

  useEffect(() => {
    if (keyPressed) {
      dispatch(changeModalStateForKey(shortcutModalKey));
    }
  }, [keyPressed, dispatch]);

  return (
    <Modal
      title={t("headers.modalTitle")}
      centered
      open={open}
      width={1000}
      onCancel={() => dispatch(changeModalStateForKey(shortcutModalKey))}
      footer={null}
    >
      <div className="shortcut-help__container">
        <div className="shortcut-help__section">
          <h4 className="shortcut-help__sub-title">
            {t("headers.global.title")}
            <Tooltip title={t("headers.global.info")}>
              <i className="bi bi-question-circle extra-info__icon" />
            </Tooltip>
          </h4>
          {GLOBAL_SHORTCUTS.map((s) => (
            <Row key={s}>
              <Col span={6}>
                {reactHtmlParser(t(`shortcuts.${s}.key.${PLATFORM}`))}
              </Col>
              <Col span={18}>
                {reactHtmlParser(
                  t(`shortcuts.${s}.explanation`).replace(
                    "[KEY]",
                    t(`shortcuts.${s}.key.${PLATFORM}`)
                  )
                )}
              </Col>
            </Row>
          ))}
        </div>
        <div className="shortcut-help__section">
          <h4 className="shortcut-help__sub-title">
            {t("headers.detail.title")}
            <Tooltip title={t("headers.detail.info")}>
              <i className="bi bi-question-circle extra-info__icon" />
            </Tooltip>
          </h4>
          {DETAILS_SHORTCUTS.map((s) => (
            <Row key={s}>
              <Col span={6}>
                {reactHtmlParser(t(`shortcuts.${s}.key.${PLATFORM}`))}
              </Col>
              <Col span={18}>
                {reactHtmlParser(
                  t(`shortcuts.${s}.explanation`).replace(
                    "[KEY]",
                    t(`shortcuts.${s}.key.${PLATFORM}`)
                  )
                )}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ShortcutHelp;

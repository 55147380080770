import React, { FC, MutableRefObject, useRef } from "react";
import SelectionRectangle from "./SelectionRectangle";
import { PDFMetaData } from "../../../interfaces/pdf";
import CursorText from "./CursorText";
import useSelection from "../../../hooks/useSelection";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app";
import activeEntityForAnnotatorSelector from "../../../../annotator/selectors/activeEntityForAnnotatorSelector";

interface Props {
  pageNumber: number;
  children: React.ReactNode;
  className?: string;
  style?: { [key: string]: string };
  pdfInformation: PDFMetaData;
  pdfContext: CanvasRenderingContext2D;
  createTable: boolean;
}

const Selection: FC<Props> = ({
  pageNumber,
  children,
  className,
  style,
  pdfInformation,
  pdfContext,
  createTable,
}) => {
  const selectionRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const {
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    mode,
    coords,
    mousePosition,
    isDragging,
  } = useSelection({
    createTable,
    pageNumber,
    pdfContext,
    pdfInformation,
    ref: selectionRef,
  });

  return (
    <div
      role="document"
      ref={selectionRef}
      className={`selection-container ${className} ${mode}`}
      style={style}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <CursorText entity={entity} mouseCoords={mousePosition} />
      <SelectionRectangle isDragging={isDragging} coordinates={coords} />
      {children}
    </div>
  );
};

export default Selection;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { Option } from "../../common/dataRefining/interfaces/filteringData";

const getInputTypes = (state: RootState) =>
  state.documentTypesReducer.inputTypes;

const sourceOptionsSelector = createSelector(
  [getInputTypes],
  (inputTypes): Array<Option> => {
    return inputTypes.map((source) => ({
      label: source.displayName,
      value: source.id,
    }));
  }
);

export default sourceOptionsSelector;

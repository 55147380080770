import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";

type Props = {
  hideNonRequiredEntities: boolean;
};

const selectHideNonRequiredEntities = (_: RootState, props: Props) =>
  props.hideNonRequiredEntities;

const select = (state: RootState) =>
  state.configMapReducer.configMap?.groupBlocks;

const groupBlockWrapperSelector = createSelector(
  select,
  selectHideNonRequiredEntities,
  (groupBlocks, hideNonRequiredEntities) => {
    if (!groupBlocks?.length) {
      return {
        singleLineGroupBlocks: [],
        multipleLinesGroupBlocks: [],
      };
    }

    const singleLineGroupBlocks = groupBlocks
      .filter((gb) => !gb.multipleGroupBlocks)
      .map((block) => ({
        ...block,
        groupBlockEntityTypes: [...block.groupBlockEntityTypes!]
          .filter((entity) => {
            if (!hideNonRequiredEntities) {
              return true;
            }

            return entity.required;
          })
          .sort((a, b) => a.index - b.index),
      }))
      .sort((a, b) => a.index - b.index);
    const multipleLinesGroupBlocks = groupBlocks
      .filter((gb) => gb.multipleGroupBlocks)
      .map((block) => ({
        ...block,
        groupBlockEntityTypes: [...block.groupBlockEntityTypes!]
          .filter((entity) => {
            if (!hideNonRequiredEntities) {
              return true;
            }

            return entity.required;
          })
          .sort((a, b) => a.index - b.index),
      }))
      .sort((a, b) => a.index - b.index);

    return {
      singleLineGroupBlocks,
      multipleLinesGroupBlocks,
    };
  }
);

export default groupBlockWrapperSelector;

import React from "react";
import { TextLayerItem } from "../../../interfaces/textLayer";
import Token from "./Token";
import Mark from "./Mark";
import { isBetween } from "../../../helpers/generalHelpers";
import useTokenContainer from "../../../hooks/useTokenContainer";

let spaceAsMark = false;

interface Props {
  textLayerItem: TextLayerItem;
  tokens: Array<string>;
  pageNumber: number;
}

const TokenContainer = ({ textLayerItem, tokens, pageNumber }: Props) => {
  const { annotations, style } = useTokenContainer(textLayerItem, pageNumber);

  return (
    <span className="token-container" style={style}>
      {tokens.map((token, keyIndex) => {
        const dataI = textLayerItem.dataI!;
        const annotation = annotations.find((a) =>
          a.pageTokenIndices?.includes(dataI)
        );
        const tokenIndexIsNotFirstOrLast = isBetween(
          keyIndex,
          0,
          tokens.length - 1
        );

        if (token === " ") {
          if (annotation && spaceAsMark && tokenIndexIsNotFirstOrLast) {
            spaceAsMark = false;
            return (
              <Mark
                key={keyIndex}
                token={token}
                annotation={annotation}
                dataI={dataI}
              />
            );
          }
          return <Token key={keyIndex} token={token} />;
        }

        if (annotation) {
          spaceAsMark = true;
          return (
            <Mark
              key={keyIndex}
              token={token}
              annotation={annotation}
              dataI={dataI}
            />
          );
        }

        return <Token key={keyIndex} token={token} dataI={dataI} />;
      })}
    </span>
  );
};

export default TokenContainer;

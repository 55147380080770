export const numberAbbreviation = Intl.NumberFormat("en-US", {
  notation: "compact",
  maximumFractionDigits: 1,
});

export const calculatePercentageDistribution = (
  manual: number,
  automatic: number,
  rejected: number
): [number, number, number] | null => {
  const total = manual + automatic + rejected;

  if (!total) {
    return null;
  }

  return [manual / total, automatic / total, rejected / total];
};

import React, { FC } from "react";
import { Image, Tooltip } from "antd";
import { useAppSelector } from "../../../app";
import useOrganizationAvatar from "./hooks/useOrganizationAvatar";

type CustomAvatarProps = {
  size: number | "large" | "small" | "default";
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | undefined;
};

const OrganizationAvatar: FC<CustomAvatarProps> = ({ size, placement }) => {
  const user = useAppSelector((state) => state.userReducer.user);

  const image = useOrganizationAvatar();

  if (!image) {
    return null;
  }

  return (
    <Tooltip placement={placement} title={user?.organization?.name}>
      <Image width={size} src={image} preview={false} alt="Company" />
    </Tooltip>
  );
};

export default OrganizationAvatar;

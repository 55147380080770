import React, { CSSProperties, FC, useEffect, useRef } from "react";
import GroupBlocks from "./groupBlocks/GroupBlocks";
import classNames from "classnames";
import { useAppSelector } from "../../../app";
import { useTab } from "../../../configMap";
import useActiveEntityListener from "../../hooks/useActiveEntityListener";
import CategorizationBlock from "../../../metadata";
import { GroupBlocksWrapper } from "../../interfaces/groupblock";
import groupBlockWrapperSelector from "../../selectors/groupBlockWrapperSelector";

type GroupBlocksContainerProps = {
  disabled: boolean;
  style: CSSProperties;
};

const GroupBlocksContainer: FC<GroupBlocksContainerProps> = ({
  disabled,
  style,
}) => {
  const hideNonRequiredEntities = useAppSelector(
    (state) => state.userReducer.userSettings.hideNonRequiredEntities
  );

  const categorizationConfigMaps = useAppSelector(
    (state) => state.configMapReducer.configMap?.categorizationConfigMaps
  );

  useTab(hideNonRequiredEntities, disabled);
  useActiveEntityListener();

  const groupBlockContainerClass = classNames({
    "group-blocks-container": true,
    "in-detail-view": true,
  });
  const groupBlocksScrollRef = useRef<any>();

  const { singleLineGroupBlocks, multipleLinesGroupBlocks } =
    useAppSelector<GroupBlocksWrapper>((state) =>
      groupBlockWrapperSelector(state, { hideNonRequiredEntities })
    );

  const { lastGroupBlockId } = useAppSelector(
    (state) => state.configMapReducer.groupBlock
  );

  useEffect(() => {
    if (groupBlocksScrollRef.current && lastGroupBlockId) {
      const offsetTop = document.getElementById(lastGroupBlockId)?.offsetTop;
      if (!offsetTop) {
        return;
      }
      groupBlocksScrollRef.current?.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }, [lastGroupBlockId]);

  return (
    <div className={groupBlockContainerClass} style={style}>
      <div className="group-blocks" ref={groupBlocksScrollRef}>
        <CategorizationBlock
          disabled={disabled}
          categorizationsWithConfig={categorizationConfigMaps}
          showHeader
        />
        <GroupBlocks
          disabled={disabled}
          groupBlocks={singleLineGroupBlocks}
          multipleLinesGroupBlocks={multipleLinesGroupBlocks}
          hideNonRequiredEntities={hideNonRequiredEntities}
        />
      </div>
    </div>
  );
};

export default GroupBlocksContainer;

import { useCallback, useEffect, useState } from "react";
import useTableUpdater from "./useTableUpdater";

const useDraggable = (
  tableId: string,
  id: string,
  originalPos: number,
  mouseLoc: number,
  type: "row" | "col",
  enabled: boolean
) => {
  const { addRow, addColumn } = useTableUpdater();

  const [dragging, setDragging] = useState(false);
  const [pos, setPos] = useState(originalPos);

  useEffect(() => {
    if (!dragging) {
      return;
    }

    setPos(mouseLoc);
  }, [dragging, mouseLoc]);

  const handleDraggingChange = useCallback(
    (value: boolean) => {
      if (!enabled) {
        return;
      }

      setDragging(value);

      if (!value) {
        if (type === "row") {
          addRow(tableId, pos, id);
        } else {
          addColumn(tableId, pos, id);
        }
      }
    },
    [type, tableId, id, pos, enabled, addRow, addColumn]
  );

  return {
    dragging,
    pos,
    handleDraggingChange,
  };
};

export default useDraggable;

import { OverviewType } from "../../../app";
import { useMemo } from "react";
import { ARCHIVE_SORTER_KEY, OVERVIEW_SORTER_KEY } from "../constants";

const useStorageSortingKey = (overviewType: OverviewType) => {
  return useMemo((): string => {
    if (overviewType === OverviewType.Archived) {
      return ARCHIVE_SORTER_KEY;
    }

    return OVERVIEW_SORTER_KEY;
  }, [overviewType]);
};

export default useStorageSortingKey;

import React, { FC, useCallback, useState } from "react";
import { Popover, Row, Tooltip } from "antd";
import useOrderColumns from "../../../hooks/useOrderColumns";
import { OrderColumnItem } from "../../../interfaces/overview";
import OrderColumnsContainer from "./OrderColumnsContainer";
import "../TableHeader.scss";
import "./OrderColumns.scss";

type Props = {
  loading: boolean;
};
const OrderColumnsPopover: FC<Props> = ({ loading }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { updateOrderColumns, filteredColumns, titlePopover, helpTooltip } =
    useOrderColumns();

  const [columns, setColumns] =
    useState<Array<OrderColumnItem>>(filteredColumns);

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      if (loading) {
        return;
      }

      setIsOpen(open);

      if (open) {
        setColumns(filteredColumns);
      }
    },
    [setIsOpen, setColumns, filteredColumns, loading]
  );

  return (
    <div className="column-header-container">
      <Row className="content-align-container">
        <Popover
          placement="bottomLeft"
          title={
            <>
              {titlePopover}
              <label className="help-icon">
                <Tooltip title={helpTooltip}>
                  <i className="bi bi-question-circle" />
                </Tooltip>
              </label>
            </>
          }
          content={
            <OrderColumnsContainer
              columns={columns}
              setColumns={setColumns}
              updateOrderColumns={updateOrderColumns}
            />
          }
          overlayClassName="order-columns-popover"
          trigger="click"
          arrow={{ pointAtCenter: true }}
          onOpenChange={handleOpenChange}
          open={isOpen}
        >
          <div className="menu-icon">
            <i className="bi-bootstrap bi-list" />
          </div>
        </Popover>
      </Row>
    </div>
  );
};

export default OrderColumnsPopover;

import { AddStpEntity, IEntityStpTableData } from "../interfaces/stp";

export const doesStpEntityExist = (
  stpEntities: Array<IEntityStpTableData>,
  stpEntity: AddStpEntity,
  documentTypeId: string
) => {
  const { id, entityTypeId, entityValidation, threshold } = stpEntity;

  return stpEntities.find((entity) => {
    return (
      entity.id !== id &&
      entity.entityTypeId === entityTypeId &&
      entity.threshold === threshold &&
      entity.entityValidation === entityValidation &&
      entity.documentTypeId === documentTypeId
    );
  });
};

export const isSourceLinkedToSameEntity = (
  stpEntities: Array<IEntityStpTableData>,
  stpEntity: AddStpEntity,
  documentTypeId: string
) => {
  const { id, entityTypeId, sources } = stpEntity;

  return stpEntities.find((entity) => {
    return (
      entity.id !== id &&
      entity.entityTypeId === entityTypeId &&
      entity.sources?.some((source) => sources?.includes(source))
    );
  });
};

import SelectDocumentType from "./selectDocumentType/SelectDocumentType";
import documentTypesReducer from "./store/documentTypesSlice";
import { DocumentType, InputType } from "./interfaces";
import useDocumentTypes from "./hooks/useDocumentTypes";

export { useDocumentTypes, documentTypesReducer };

export type { DocumentType, InputType };

export default SelectDocumentType;

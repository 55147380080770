import { useCallback, useMemo } from "react";
import useControls from "./useControls";
import lodash from "lodash";
import { Dictionary } from "@reduxjs/toolkit";
import { Annotation } from "../interfaces/annotation";
import useTableUpdater from "./useTableUpdater";
import { useAppSelector } from "../../app";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";

const useTableOverview = () => {
  const {
    confirmAnnotations,
    deleteAnnotations,
    deleteRowAnnotations,
    removeTable,
  } = useTableUpdater();

  const { tables } = useAppSelector(
    (state) => state.annotatorReducer.tableState
  );

  const annotations = useAppSelector(selectAnnotationReducer);

  const tableControlAnnotations = useMemo(() => {
    return annotations.filter((a) => a.multipleGroupBlocks);
  }, [annotations]);

  const { setCreateTable } = useControls();

  const groupedData: never[] | Dictionary<Annotation[]> = useMemo(() => {
    if (!tables.length) {
      return [];
    }

    const filtered = tableControlAnnotations.filter(
      (annotation) => annotation.tempAnnotation
    );
    return lodash(filtered).groupBy("index").value();
  }, [tables, tableControlAnnotations]);

  const tableHeaders = useMemo(() => {
    if (Object.keys(groupedData).length) {
      const firstKey = Object.keys(groupedData)[0];
      return (groupedData as any)[firstKey].map((a: any) => ({
        id: a.entity.id,
        value: a.entity.name,
      }));
    }

    return [];
  }, [groupedData]);

  const deleteTableAnnotations = useCallback(() => {
    tables.forEach((table) => {
      deleteAnnotations(table);
      removeTable(table);
    });
  }, [tables, deleteAnnotations, removeTable]);

  const confirmTableAnnotations = useCallback(() => {
    setCreateTable(false);

    tables.forEach(() => {
      confirmAnnotations();
    });
  }, [tables, setCreateTable, confirmAnnotations]);

  const deleteRow = useCallback(
    (rowIndex: number) => {
      tables.forEach((table) => {
        deleteRowAnnotations(table, rowIndex);
      });
    },
    [tables, deleteRowAnnotations]
  );

  return {
    tableHeaders,
    deleteTableAnnotations,
    confirmTableAnnotations,
    deleteRow,
    groupedData,
  };
};

export default useTableOverview;

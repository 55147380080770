import http from "../../common/utilities/HttpModule";
import { DocumentType } from "../interfaces";

export const updateDocumentTypeRequest = (
  documentId: string,
  documentTypeId: string
) =>
  http.post<DocumentType>(
    `api/v1/documents/${documentId}/document-type/${documentTypeId}`
  );

export const fetchDocumentTypeInfo = (documentTypeId: string) =>
  http.get<DocumentType>(`api/v1/document-type/${documentTypeId}`);

import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../app";
import selectHasMissingCategorizations from "../../metadata/selectors/selectHasMissingCategorizations";
import selectHasMissingAnnotations from "../../annotation/selectors/hasMissingAnnotationsSelector";
import useMeasure from "react-use-measure";
import { LogWebhookRequest } from "../../documentSet/interfaces/documentSet";
import {
  AlertError,
  ErrorType,
  MoreDetailsInfo,
} from "../interfaces/errorType";
import { useTranslation } from "react-i18next";
import { GroupBlocksWrapper } from "../../configMap/interfaces/groupblock";
import groupBlockWrapperSelector from "../../configMap/selectors/groupBlockWrapperSelector";

const useExportFailedAlert = (setHeight: (height: number) => void) => {
  const { t } = useTranslation("annotationSideControls", {
    keyPrefix: "alert.exportFailed",
  });

  const hasMissingCategorizations = useAppSelector(
    selectHasMissingCategorizations
  );
  const hasMissingAnnotations = useAppSelector(selectHasMissingAnnotations);

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const user = useAppSelector((state) => state.userReducer.user);

  const hideNonRequiredEntities = useAppSelector(
    (state) => state.userReducer.userSettings.hideNonRequiredEntities
  );

  const { singleLineGroupBlocks, multipleLinesGroupBlocks } =
    useAppSelector<GroupBlocksWrapper>((state) =>
      groupBlockWrapperSelector(state, { hideNonRequiredEntities })
    );

  const [refHeightContainer, { height }] = useMeasure();

  const latestLogWebhookRequest = useMemo(() => {
    if (!singleLineGroupBlocks.length && !multipleLinesGroupBlocks.length) {
      return;
    }

    if (!activeDocumentSet?.logWebhookRequests?.length) {
      return;
    }

    return [...activeDocumentSet.logWebhookRequests].sort(
      (a: LogWebhookRequest, b: LogWebhookRequest) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    )[0];
  }, [
    activeDocumentSet?.logWebhookRequests,
    singleLineGroupBlocks.length,
    multipleLinesGroupBlocks.length,
  ]);

  const moreDetailsInfo: MoreDetailsInfo | undefined = useMemo(() => {
    if (!latestLogWebhookRequest) {
      return;
    }

    let message = latestLogWebhookRequest.responseBody?.message;
    if (!message) {
      message = "/";
    }

    return {
      statusCode: latestLogWebhookRequest.responseStatusCode,
      message: typeof message === "string" ? message : JSON.stringify(message),
    };
  }, [latestLogWebhookRequest]);

  const hasMissingAlert = useMemo(() => {
    return hasMissingCategorizations || hasMissingAnnotations;
  }, [hasMissingCategorizations, hasMissingAnnotations]);

  const errorMessageObject: AlertError | undefined = useMemo(() => {
    if (!latestLogWebhookRequest) {
      return;
    }

    const organizationName = user?.organization?.name;
    const type = latestLogWebhookRequest.responseBody.type;

    switch (type) {
      case ErrorType.VALIDATION_ERROR:
        return {
          title: t("validationError.title"),
          message: hasMissingAlert
            ? t("missingAnnotations.description")
            : t("validationError.description"),
        };
      case ErrorType.CUSTOMER_API_ERROR:
        return {
          title: organizationName
            ? t("customerApiError.title").replace(
                "$CUSTOMER",
                `(${organizationName})`
              )
            : t("customerApiError.title").replace("$CUSTOMER", ""),
          message: t("customerApiError.description"),
        };
      case ErrorType.SYSTEM_ERROR:
        return {
          title: t("systemError.title"),
          message: t("systemError.description"),
        };
      default:
        return;
    }
  }, [latestLogWebhookRequest, user?.organization?.name, hasMissingAlert, t]);

  useEffect(() => {
    setHeight(height);

    return () => {
      setHeight(0);
    };
  }, [height, setHeight]);

  return {
    refHeightContainer,
    errorMessageObject,
    latestLogWebhookRequest,
    hasMissingAnnotations,
    hasMissingCategorizations,
    moreDetailsInfo,
    t,
  };
};

export default useExportFailedAlert;

import { useCallback, useEffect, useState } from "react";

const MODIFIER_KEYS = ["ALT", "CONTROL", "META"];

const useModifierKeyListener = () => {
  const [modifierKeyPressed, setModifierKeyPressed] = useState(false);

  const downHandler = useCallback(({ key }: KeyboardEvent) => {
    if (MODIFIER_KEYS.includes(key.toUpperCase())) {
      setModifierKeyPressed(true);
    }
  }, []);

  const upHandler = useCallback(({ key }: KeyboardEvent) => {
    if (MODIFIER_KEYS.includes(key.toUpperCase())) {
      setModifierKeyPressed(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  return modifierKeyPressed;
};

export default useModifierKeyListener;

import React, { FC } from "react";
import "./HeaderWithAccent.scss";

type Props = {
  children: string;
};

const HeaderWithAccent: FC<Props> = ({ children }) => {
  return (
    <div className="header-accent__container">
      <h3 className="header-accent__text">{children}</h3>
      <hr className="header-accent__accent" />
    </div>
  );
};

export default HeaderWithAccent;

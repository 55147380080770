import React from "react";
import "./PDFSkeleton.scss";
import { Skeleton } from "antd";

const PDFSkeleton = () => {
  return (
    <div className="pdf-skeleton__container">
      <div className="pdf-skeleton__page">
        <Skeleton.Image />
        <Skeleton paragraph={{ rows: 5 }} style={{ marginTop: 20 }} active />
        <Skeleton paragraph={{ rows: 2 }} style={{ marginTop: 20 }} active />
        <Skeleton paragraph={{ rows: 8 }} style={{ marginTop: 20 }} active />
      </div>
    </div>
  );
};

export default PDFSkeleton;

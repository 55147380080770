import { useCallback } from "react";
import { ConfigMap, GroupBlockCount, GroupBlockData } from "../";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  initialGroupBlockCounts,
  resetGroupBlock,
  resetGroupBlocks,
  setLastBlockId,
  updateGroupBlockCount,
} from "../store/configMapSlice";
import { throttle } from "../../common/utilities/func";
import {
  changeActiveButton,
  changeActiveEntity,
  changeAnnotating,
} from "../../app/store/appSlice";
import { Annotation } from "../../annotator/interfaces/annotation";
import { RAVAGO_COMPANY_NAME } from "../../common/constants";
import { DocumentCategoryAnnotation } from "../interfaces/category";

const MINIMAL_GROUP_BLOCK_COUNT = 1;

const calculateGroupBlockCounts = (
  annotations: Array<Annotation>,
  groupBlocks: Array<GroupBlockData | undefined>,
  categoryAnnotations: Array<DocumentCategoryAnnotation>
): Array<GroupBlockCount> =>
  groupBlocks
    .map((groupBlock) => {
      if (!groupBlock?.multipleGroupBlocks) {
        return { groupBlockId: "0", count: 0 };
      }

      const entityIds = groupBlock?.groupBlockEntityTypes?.map(
        (entityType) => entityType.entityType.id
      );
      const categorizationIds = groupBlock?.categorizationGroupBlocks?.map(
        (categorizationGroupBlock) => categorizationGroupBlock.categorization.id
      );
      if (!entityIds?.length && !categorizationIds?.length) {
        return { groupBlockId: "0", count: 0 };
      }

      const filteredAnnotations = annotations
        .filter((annotation) => entityIds?.includes(annotation.entity.id))
        .map((annotation) => annotation.index || MINIMAL_GROUP_BLOCK_COUNT);
      const filteredCategoryAnnotations = categoryAnnotations
        .filter((dca) => categorizationIds.includes(dca.categorizationId!))
        .map((dca) => dca.index || MINIMAL_GROUP_BLOCK_COUNT);

      const highestCountAnnotations = filteredAnnotations.length
        ? Math.max(...filteredAnnotations)
        : MINIMAL_GROUP_BLOCK_COUNT;
      const highestCountCategoryAnnotations = filteredCategoryAnnotations.length
        ? Math.max(...filteredCategoryAnnotations)
        : MINIMAL_GROUP_BLOCK_COUNT;

      return {
        groupBlockId: groupBlock.id,
        count: Math.max(
          highestCountAnnotations,
          highestCountCategoryAnnotations
        ),
      };
    })
    .filter((groupBlockCount) => groupBlockCount.count !== 0);

const useMultipleGroupBlocks = () => {
  const dispatch = useAppDispatch();
  const { groupBlockCount } = useAppSelector(
    (state) => state.configMapReducer.groupBlock
  );

  const user = useAppSelector((state) => state.userReducer.user);

  const setInitialMultipleGroupBlocksCount = useCallback(
    (
      configMap: ConfigMap,
      annotations: Array<Annotation>,
      documentCategoryAnnotations: Array<DocumentCategoryAnnotation>
    ) => {
      const allGroupBlocks = configMap.groupBlocks.flat();

      if (!allGroupBlocks?.length) {
        return;
      }

      dispatch(
        initialGroupBlockCounts(
          calculateGroupBlockCounts(
            annotations,
            allGroupBlocks,
            documentCategoryAnnotations
          )
        )
      );
    },
    [dispatch]
  );

  const countPerGroupBlockId = useCallback(
    (id: string) =>
      groupBlockCount.find((item) => item.groupBlockId === id)?.count ||
      MINIMAL_GROUP_BLOCK_COUNT,
    [groupBlockCount]
  );

  // eslint-disable-next-line
  const addGroupBlock = useCallback(
    throttle(
      (groupBlock: GroupBlockData, createdId: string, targetIndex: number) => {
        dispatch(updateGroupBlockCount({ groupBlock, countUpdate: 1 }));
        dispatch(setLastBlockId(createdId));

        if (groupBlock.groupBlockEntityTypes?.length) {
          const sortedEntityTypes = [...groupBlock.groupBlockEntityTypes].sort(
            (a, b) => a.index - b.index
          );
          const targetEntity = sortedEntityTypes[0];
          dispatch(changeAnnotating(true));
          dispatch(changeActiveButton(undefined));
          dispatch(
            changeActiveEntity({
              ...targetEntity,
              index: targetIndex,
            })
          );
        }
      },
      250
    ),
    [dispatch]
  );

  const removeGroupBlock = useCallback(
    (groupBlock: GroupBlockData) => {
      dispatch(updateGroupBlockCount({ groupBlock, countUpdate: -1 }));
      dispatch(setLastBlockId(groupBlock.id.toString()));
    },
    [dispatch]
  );

  const resetMultipleGroupBlock = useCallback(
    (groupBlock: GroupBlockData) => {
      dispatch(resetGroupBlock(groupBlock));
    },
    [dispatch]
  );

  const resetMultipleGroupBlocks = useCallback(() => {
    dispatch(resetGroupBlocks());
  }, [dispatch]);

  const hasMultipleAnnotationsInGroupBlock = useCallback(
    (
      annotation: Annotation,
      configMap: ConfigMap | undefined,
      allAnnotations: Array<Annotation>
    ): boolean => {
      if (
        user?.organization?.name?.toLowerCase() &&
        user?.organization?.name?.toLowerCase().includes(RAVAGO_COMPANY_NAME)
      ) {
        return false;
      }

      const annotationsInGroup = allAnnotations.filter(
        (a) =>
          a.entity.id === annotation?.entity.id && a.index === annotation.index
      );

      if (annotationsInGroup.length <= 1) {
        return false;
      }

      const { groupBlocks } = configMap!;
      let groupBlockData: GroupBlockData | undefined;

      for (const groupBlock of groupBlocks) {
        const entity = groupBlock?.groupBlockEntityTypes?.find(
          (gb) => gb.entityType.id === annotation.entity.id
        );

        if (entity) {
          groupBlockData = groupBlock;
          break;
        }
      }

      return (
        !!groupBlockData?.multipleGroupBlocks && annotationsInGroup.length > 1
      );
    },
    [user?.organization.name]
  );

  return {
    addGroupBlock,
    removeGroupBlock,
    countPerGroupBlockId,
    setInitialMultipleGroupBlocksCount,
    resetMultipleGroupBlocks,
    resetMultipleGroupBlock,
    hasMultipleAnnotationsInGroupBlock,
  };
};

export default useMultipleGroupBlocks;

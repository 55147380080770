import App from "./App";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import { RootState, store } from "./store";
import { OverviewType } from "./interfaces/overview";
import { SupportedLanguages, SupportedLanguage } from "./interfaces/language";
import { Locales } from "./interfaces/locale";
import { ButtonType } from "./interfaces/store";

export {
  useAppSelector,
  useAppDispatch,
  store,
  OverviewType,
  Locales,
  SupportedLanguages,
};

export type { RootState, ButtonType, SupportedLanguage };

export default App;

import { ITextVertex } from "../../annotator/interfaces/textLayer";

const calculateDistance = (item1: ITextVertex, item2: ITextVertex): number => {
  const dx = item1.left - item2.left;
  const dy = item1.top - item2.top;
  return Math.sqrt(dx * dx + dy * dy);
};

export const groupItems = (
  tokens: Array<ITextVertex>,
  threshold: number = 100
): ITextVertex[][] => {
  return tokens.reduce((groups: ITextVertex[][], item: ITextVertex) => {
    const foundGroup = groups.find((group) =>
      group.some((groupItem) => calculateDistance(item, groupItem) <= threshold)
    );

    if (foundGroup) {
      foundGroup.push(item);
    } else {
      groups.push([item]);
    }

    return groups;
  }, []);
};

import React, { FunctionComponent, useCallback } from "react";
import { Switch } from "antd";
import { UserSettings } from "../../../.";
import { useAppDispatch, useAppSelector } from "../../../../app";
import { updateUserSettings } from "../../../store/userSlice";

type Props = {
  icon: React.ReactNode;
  text: string;
  property: keyof UserSettings;
  changeHasBeenUpdated: (state: boolean) => void;
};

const SettingsSwitchSelector: FunctionComponent<Props> = ({
  icon,
  text,
  property,
  changeHasBeenUpdated,
}) => {
  const dispatch = useAppDispatch();
  const { userSettings } = useAppSelector((state) => state.userReducer);

  const onChange = useCallback(
    (checked: boolean) => {
      dispatch(
        updateUserSettings({
          ...userSettings,
          [property]: checked,
        })
      );
      changeHasBeenUpdated(true);
    },
    [dispatch, changeHasBeenUpdated, property, userSettings]
  );

  return (
    <div className="account-popover__item">
      <div>
        <span className="account-popover__item-icon">{icon}</span>
        <label className="account-popover__item-label">{text}:</label>
      </div>
      <Switch checked={!!userSettings[property]} onChange={onChange} />
    </div>
  );
};

export default SettingsSwitchSelector;

import React, { FC, MouseEvent, useCallback } from "react";
import { EmailTemplate } from "../../../interfaces/table";

type Props = {
  emailTemplate: EmailTemplate;
  setFavoriteEmailTemplate: (emailTemplate: EmailTemplate) => void;
};

const FavoriteCell: FC<Props> = ({
  emailTemplate,
  setFavoriteEmailTemplate,
}) => {
  const handleClicked = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      setFavoriteEmailTemplate(emailTemplate);
    },
    [emailTemplate, setFavoriteEmailTemplate]
  );

  if (emailTemplate.favorite) {
    return <i className="bi bi-star-fill" style={{ color: "orange" }} />;
  }

  return <i className="bi bi-star" onClick={handleClicked} />;
};

export default FavoriteCell;

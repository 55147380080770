import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocumentSetMetrics } from "../interfaces/documentSetMetrics";

interface AnalyticsState {
  numberOfAdditions: number;
  numberOfDeletions: number;
  numberOfUpdates: number;
  metrics: MetricsState;
}

interface MetricsState {
  loading: boolean;
  refreshMetrics: boolean;
  metricsData?: DocumentSetMetrics;
}

interface AnalyticsStateIncrement {
  numberOfAdditions: number;
  numberOfDeletions: number;
  numberOfUpdates: number;
}

const initialState: AnalyticsState = {
  numberOfAdditions: 0,
  numberOfDeletions: 0,
  numberOfUpdates: 0,
  metrics: {
    loading: false,
    refreshMetrics: false,
  },
};

export const analyticsSlice = createSlice({
  name: "analyticsState",
  initialState,
  reducers: {
    incrementOperations: (
      state,
      action: PayloadAction<AnalyticsStateIncrement>
    ) => {
      const { numberOfAdditions, numberOfDeletions, numberOfUpdates } =
        action.payload;
      state.numberOfAdditions += numberOfAdditions;
      state.numberOfDeletions += numberOfDeletions;
      state.numberOfUpdates += numberOfUpdates;
    },
    resetAnalyticsReducer: () => {
      return initialState;
    },
    setMetricsLoading: (state, action: PayloadAction<boolean>) => {
      state.metrics.loading = action.payload;
    },
    triggerRefreshMetrics: (state) => {
      state.metrics.refreshMetrics = !state.metrics.refreshMetrics;
    },
    setMetricsData: (
      state,
      action: PayloadAction<DocumentSetMetrics | undefined>
    ) => {
      state.metrics.metricsData = action.payload;
    },
  },
});

export const {
  incrementOperations,
  resetAnalyticsReducer,
  setMetricsLoading,
  triggerRefreshMetrics,
  setMetricsData,
} = analyticsSlice.actions;

export default analyticsSlice.reducer;

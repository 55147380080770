import { SearchQuery } from "../../documentSet/interfaces/api";
import { get } from "./object";
import { User } from "../../user";

export const buildSearchQuery = (
  query: SearchQuery,
  searchValue: string,
  state: User | null,
  language: string,
  inputIds?: Array<string>,
  attributeFilter?: string,
  organizationId?: string,
  values?: Array<string>
): string => {
  const { params } = query;

  let searchQuery = params
    .map((param) => {
      if (param.name.toLowerCase() === "__query__") {
        return `&query=${searchValue}`;
      }

      if (param.location && param.isMultiple) {
        const list = get(state, param.location, "");
        return `&${param.name}=${list.join(",")}`;
      }

      if (param.location) {
        return `&${param.name}=${get(state, param.location, "")}`;
      }

      return `&${param.name}=${param.value}`;
    })
    .join("");

  if (inputIds) {
    for (const id of inputIds) {
      searchQuery += `&input_ids=${id}`;
    }
  }

  if (attributeFilter) {
    searchQuery += `&attribute_filter=${attributeFilter}`;
  }

  if (organizationId) {
    searchQuery += `&org_id=${organizationId}`;
  }

  if (values?.length) {
    searchQuery += `&values=${values.join(",")}`;
  }

  searchQuery += `&language=${language}`;

  // Remove the leading & from the search query
  return searchQuery.substring(1);
};

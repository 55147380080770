import React, { FC } from "react";
import "../xmlViewer/XmlViewer.scss";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import PDFSkeleton from "../../../app/pages/detailView/pdfSkeleton/PDFSkeleton";
import InvalidXml from "../xmlViewer/InvalidXml";
import { convertBufferToString } from "../../utils/binary";
import EmptyNavigationHeader from "../emptyNavigationHeader/EmptyNavigationHeader";

const TextViewer: FC = () => {
  const { documentData, loading } = useDocumentViewer();

  if (loading || !documentData) {
    return <PDFSkeleton />;
  }

  return (
    <div className="container">
      <EmptyNavigationHeader />
      <div className="xml-viewer__container">
        <div className="page__container">
          <InvalidXml invalidXmlString={convertBufferToString(documentData)} />
        </div>
      </div>
    </div>
  );
};

export default TextViewer;

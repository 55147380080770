import React, { FC, useCallback } from "react";
import { Select } from "antd";
import {
  updateMetricsFilterArray,
  updateMetricsFilterString,
} from "../../../../common/dataRefining/store/dataRefiningSlice";
import { Option } from "../../../../common/dataRefining/interfaces/filteringData";
import { useAppDispatch } from "../../../../app";
import { MetricFilterItem } from "../../../interfaces/documentSetMetrics";
import useMetricsFilterItem from "../../../hooks/useMetricsFilterItem";
import { triggerRefreshMetrics } from "../../../store/analyticsSlice";
import { METRIC_FILTER_ITEM_DELIMITER } from "../../../constants";

type Props = {
  filterItem: MetricFilterItem;
  placeholder: string;
  options: Array<Option>;
  loading: boolean;
  mode?: "multiple" | "tags";
};

const FilterableItem: FC<Props> = ({
  filterItem,
  placeholder,
  options,
  loading,
  mode = "multiple",
}) => {
  const { metadata, key } = filterItem;

  const dispatch = useAppDispatch();

  const { getValueFromKey } = useMetricsFilterItem(filterItem);

  const handleChange = useCallback(
    (value: Array<string>) => {
      dispatch(
        updateMetricsFilterArray({
          key,
          delimiter: METRIC_FILTER_ITEM_DELIMITER,
          value: value.join("+"),
        })
      );
      dispatch(updateMetricsFilterString());
      dispatch(triggerRefreshMetrics());
    },
    [dispatch, key]
  );

  return (
    <div className="filter-item">
      <Select
        className="filter-item__select"
        mode={mode}
        value={getValueFromKey(
          metadata?.filterAble!.filterKey,
          METRIC_FILTER_ITEM_DELIMITER
        )?.split("+")}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        allowClear
        disabled={loading}
      />
    </div>
  );
};

export default FilterableItem;

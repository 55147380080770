import { useCallback, useMemo } from "react";
import { ColumnItem, CustomCellType } from "../interfaces/overview";
import { get } from "../../common/utilities/object";

const useCustomCell = (columnItem: ColumnItem) => {
  const keyInMetaData = useCallback(
    (key: string): boolean => {
      if (!columnItem.metadata) {
        return false;
      }

      return !!get(columnItem.metadata, key, "");
    },
    [columnItem]
  );

  const customCellType = useMemo(() => {
    if (keyInMetaData("isCopyable")) {
      return CustomCellType.COPYABLE;
    }

    return CustomCellType.TEXT;
  }, [keyInMetaData]);

  return {
    customCellType,
  };
};

export default useCustomCell;

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toggleHideNonRequiredEntities } from "../store/userSlice";
import { defaultSettings } from "../utils/utils";
import { useTranslation } from "react-i18next";
import { User, UserSettings } from "../interfaces/interfaces";
import { useUpdateUserMutation } from "../store/userApi";

const useUserSettings = () => {
  const dispatch = useDispatch();

  const {
    i18n: { language },
  } = useTranslation();

  const [updateUser] = useUpdateUserMutation();

  const toggleHideNonRequiredEntitiesForUser = useCallback(() => {
    dispatch(toggleHideNonRequiredEntities());
  }, [dispatch]);

  const updateUserFiltersAuth0 = useCallback(
    async (
      user: User | null,
      userSettings: UserSettings,
      overviewFilterArray: Array<string>,
      archiveFilterArray: Array<string>
    ) => {
      if (!user) {
        return;
      }

      if (userSettings.saveFilters) {
        const body = {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email.toLowerCase(),
          metadata: {
            ...userSettings,
            overviewFilters: overviewFilterArray,
            archiveFilters: archiveFilterArray,
            picture: user.avatar,
            language,
          },
        };

        await updateUser({ userId: user.id, data: body }).unwrap();
      }
    },
    [language, updateUser]
  );

  const updateUserSettings = useCallback(
    async (user: User, userSettings: UserSettings) => {
      if (!user) {
        return;
      }

      try {
        const body = {
          firstName: user?.firstName || "",
          lastName: user?.lastName || "",
          username: user?.username || "",
          email: user?.email?.toLowerCase() || "",
          company: user?.organization.name || "",
          avatar: user?.avatar || "",
          metadata: {
            ...userSettings,
            picture: user.avatar || null,
            overviewSorter: userSettings.saveSorter
              ? userSettings.overviewSorter
              : defaultSettings.overviewSorter,
            archiveSorter: userSettings.saveSorter
              ? userSettings.archiveSorter
              : defaultSettings.archiveSorter,
            overviewFilters: userSettings.saveFilters
              ? userSettings.overviewFilters
              : [],
            archiveFilters: userSettings.saveFilters
              ? userSettings.archiveFilters
              : [],
            showWelcome: false,
            overviewOrderColumns: userSettings.overviewOrderColumns,
            archiveOrderColumns: userSettings.archiveOrderColumns,
            language,
          },
        };

        await updateUser({ userId: user.id, data: body }).unwrap();
      } catch (e) {
        console.error(e);
      }
    },
    [language, updateUser]
  );

  return {
    toggleHideNonRequiredEntitiesForUser,
    updateUserSettings,
    updateUserFiltersAuth0,
  };
};

export default useUserSettings;

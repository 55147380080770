export enum Organization {
  RAVAGO,
  ATLAS_COPCO,
  EDMAC,
  VANDERSANDEN,
  FLEXSO,
  ARCELORMITTAL,
  DEFAULT,
}

export type OrganizationImageMap = {
  [key in Organization]: string;
};

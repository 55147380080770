import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";
import flatEntityListSelector from "./flatEntityListSelector";
import { getParentAnnotationsForEntities } from "../utils/entityAnnotationUtils";

export interface ParentAnnotationsResponse {
  value: string;
  childrenIds: Array<string>;
}

const entityParentAnnotationsSelector = createSelector(
  [selectAnnotationReducer, flatEntityListSelector],
  (annotations, entities): Array<ParentAnnotationsResponse> | null =>
    getParentAnnotationsForEntities(annotations, entities)
);

export default entityParentAnnotationsSelector;

import useActiveDocument from "../../document/hooks/useActiveDocument";
import useAnnotations from "../../annotation";
import { useMultipleGroupBlocks } from "../../configMap";
import { useCallback, useState } from "react";
import useDownloadPdf from "../../common/downloadPdf/hooks/useDownloadPdf";
import { useAppSelector } from "../../app";
import useUserSettings from "../../user/hooks/useUserSettings";
import useDocumentCategoryAnnotations from "../../metadata/hooks/useDocumentCategoryAnnotations";
import { useDispatch } from "react-redux";
import { setDrawerTypeAndOpen } from "../store/AnnotationSideControlsSlice";
import { DrawerType } from "../interfaces/drawer";

const useActions = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const { activeDocument } = useActiveDocument();

  const { removeAllAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const { resetMultipleGroupBlocks } = useMultipleGroupBlocks();

  const { resetDocumentCategoryAnnotations } = useDocumentCategoryAnnotations();

  const downloadPdf = useDownloadPdf();

  const hideNonRequiredEntities = useAppSelector(
    (state) => state.userReducer.userSettings.hideNonRequiredEntities
  );

  const { toggleHideNonRequiredEntitiesForUser } = useUserSettings();

  const removeAllAnnotationsAndGroupBlocks = useCallback(() => {
    removeAllAnnotations();
    resetMultipleGroupBlocks();
    resetDocumentCategoryAnnotations();
  }, [
    removeAllAnnotations,
    resetMultipleGroupBlocks,
    resetDocumentCategoryAnnotations,
  ]);

  const downloadPdfAndClose = () => {
    downloadPdf(activeDocument?.id || "", activeDocument?.name || "");
    setOpen(false);
  };

  const removeAllAnnotationsAndClose = () => {
    removeAllAnnotationsAndGroupBlocks();
    setOpen(false);
  };

  const openDrawerAndSetType = useCallback(
    (drawerType: DrawerType) => {
      setOpen(false);
      dispatch(setDrawerTypeAndOpen(drawerType));
    },
    [dispatch]
  );

  return {
    downloadPdfAndClose,
    removeAllAnnotationsAndClose,
    toggleHideNonRequiredEntitiesForUser,
    hideNonRequiredEntities,
    open,
    setOpen,
    activeDocument,
    openDrawerAndSetType,
  };
};

export default useActions;

import { useMemo } from "react";
import { get } from "../../common/utilities/object";
import { DocumentSetData } from "../interfaces/documentSet";
import { ColumnItem } from "../interfaces/overview";
import { parseSearchableString } from "../utils/searchableParser";

const useCellValue = (documentSet: DocumentSetData, columnItem: ColumnItem) => {
  return useMemo(() => {
    if (columnItem?.metadata?.source?.toLowerCase() === "searchable") {
      return parseSearchableString(documentSet.searchable, columnItem.key);
    }

    return get(documentSet, columnItem.key, "N/A");
  }, [documentSet, columnItem]);
};

export default useCellValue;

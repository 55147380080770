import { KeyByAny } from "../interfaces/overview";
import { OverviewType } from "../../app";
import { FilterArrayAction } from "../../common/dataRefining/store/dataRefiningSlice";

export const getKeyFromMetaData = (
  metadata: KeyByAny | null,
  overviewType: OverviewType
): string => {
  const filterKey: Array<string> | string = metadata?.filterAble?.filterKey;

  if (!filterKey) {
    return "";
  }

  if (Array.isArray(filterKey)) {
    if (overviewType === OverviewType.Archived) {
      return filterKey[1];
    }

    return filterKey[0];
  }

  return filterKey;
};

export const buildFilterUpdateCalls = (
  value: string | Array<string>,
  metadata: KeyByAny | null,
  overviewType: OverviewType
): Array<FilterArrayAction> => {
  const filterKey: Array<string> | string = metadata?.filterAble?.filterKey;

  if (!filterKey) {
    return [];
  }

  if (Array.isArray(value) && Array.isArray(filterKey)) {
    return filterKey.map((f) => ({
      key: f,
      delimiter: metadata?.filterAble!.delimiter,
      value: value.join("+"),
      overviewType,
    }));
  }

  if (Array.isArray(value)) {
    return [
      {
        key: filterKey as string,
        delimiter: metadata?.filterAble!.delimiter,
        value: value.join("+"),
        overviewType,
      },
    ];
  }

  return [
    {
      key: filterKey as string,
      delimiter: metadata?.filterAble!.delimiter,
      value: value,
      overviewType,
    },
  ];
};

export const getVariableFromEnv = (
  varName: string,
  defaultValue: string = ""
): string => {
  // @ts-ignore
  const globalConfig = window.globalConfig;

  if (globalConfig) {
    return globalConfig[varName] || defaultValue;
  }

  return process.env[`REACT_APP_${varName}`] || defaultValue;
};

export const METRICS_ROLE_ID = getVariableFromEnv("AUTH0_METRICS_ROLE_ID");
export const ADMIN_ROLE_ID = getVariableFromEnv("AUTH0_ADMIN_ROLE_ID");
export const STP_ROLE_ID = getVariableFromEnv("AUTH0_STP_ROLE_ID");

import { createSelector } from "@reduxjs/toolkit";
import selectGroupBlockCounts from "./groupBlockCountsSelector";

const highestGroupBlockCountSelector = createSelector(
  selectGroupBlockCounts,
  (groupBlockCounts): number => {
    if (!groupBlockCounts.length) {
      return 1;
    }

    return Math.max(...groupBlockCounts.map((gc) => gc.count));
  }
);

export default highestGroupBlockCountSelector;

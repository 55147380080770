import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Point } from "../interfaces/point";
import { Rectangle } from "../../annotator/interfaces/textLayer";
import useMouse from "@react-hook/mouse-position";
import {
  calculateSelectionRectangle,
  isCoordsEmpty,
} from "../helpers/selectionHelpers";
import {
  buildAreaAnnotation,
  buildNerAnnotation,
} from "../helpers/annotationHelpers";
import { generateRandomHash } from "../helpers/hashHelper";
import { PDFMetaData } from "../interfaces/pdf";
import useKeyPressedListener from "./useKeyPressedListener";
import { useAppDispatch, useAppSelector } from "../../app";
import { setIsDragging } from "../../annotation/store/annotationSlice";
import useTableOverview from "./useTableOverview";
import useAnnotationUpdater from "./useAnnotationUpdater";
import useTableUpdater from "./useTableUpdater";
import { useTranslation } from "react-i18next";
import activeEntityForAnnotatorSelector from "../../annotator/selectors/activeEntityForAnnotatorSelector";

const initialCoords: Rectangle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  rotation: 0,
};

interface Props {
  pageNumber: number;
  pdfInformation: PDFMetaData;
  pdfContext: CanvasRenderingContext2D;
  createTable: boolean;
  ref: MutableRefObject<HTMLDivElement | null>;
}

const useSelection = ({
  createTable,
  ref,
  pageNumber,
  pdfInformation,
  pdfContext,
}: Props) => {
  const dispatch = useAppDispatch();

  const { isDragging } = useAppSelector((state) => state.annotationReducer);
  const [mouseCoords, setMouseCoords] = useState<Point>({ x: 0, y: 0 });
  const [coords, setCoords] = useState(initialCoords);

  const { groupedData } = useTableOverview();

  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const { addAnnotation, updateLastAnnotationForEntity } =
    useAnnotationUpdater();

  const { tables, addTable, mapAnnotationToTable } = useTableUpdater();

  const keyPressed = useKeyPressedListener();
  const mouse = useMouse(ref);

  const mode = useMemo(() => {
    if (createTable && entity) {
      return "normal-mode";
    }

    if ((entity && isDragging) || createTable) {
      return "annotating-mode";
    }
    if (entity || !createTable) {
      return "normal-mode";
    }

    return "text-selection-mode";
  }, [entity, isDragging, createTable]);

  const mousePosition = useMemo((): Point => {
    const { x, y } = mouse;
    if (!x || !y) {
      return { x: 0, y: 0 };
    }

    return { x, y };
  }, [mouse]);

  const handleMouseDown = useCallback(() => {
    const { x, y } = mouse;
    if (entity) {
      setMouseCoords({ x: x!, y: y! });
      dispatch(setIsDragging(true));
    }
    if (createTable) {
      setMouseCoords({ x: x!, y: y! });
      dispatch(setIsDragging(true));
    }
  }, [entity, createTable, mouse, dispatch]);

  const handleMouseUp = useCallback(() => {
    if (ref && entity) {
      let coordsToUse = coords;
      if (isCoordsEmpty(coords) && entity.entityType === "NER") {
        const { x, y } = mouse;
        coordsToUse = { left: x!, top: y!, width: 1, height: 1, rotation: 0 };
      }

      switch (entity.entityType) {
        case "NER": {
          const { children: selectionChildren } = ref.current!;
          const markToAdd = buildNerAnnotation(
            pageNumber,
            entity,
            selectionChildren,
            coordsToUse
          );
          if (createTable && entity) {
            if (tables && markToAdd.values.length > 0) {
              mapAnnotationToTable(markToAdd);
            }
            break;
          }
          if (markToAdd.pageTokenIndices.length) {
            if (keyPressed) {
              updateLastAnnotationForEntity(markToAdd);
            } else {
              addAnnotation(markToAdd);
            }
          }
          break;
        }
        case "AREA": {
          const areaToAdd = buildAreaAnnotation(
            pageNumber,
            entity,
            coordsToUse,
            pdfInformation,
            pdfContext
          );
          if (areaToAdd) {
            addAnnotation(areaToAdd);
          }
          break;
        }
        default:
          break;
      }
    }

    if (
      ref &&
      createTable &&
      mode === "annotating-mode" &&
      !Object.entries(groupedData).length
    ) {
      const { left, top, width, height } = coords;

      const tableId = generateRandomHash();
      const _table = {
        id: tableId,
        x: left,
        y: top,
        page: pageNumber,
        width,
        height,
        rows: [],
        columns: [],
        cells: [],
        linkedTables: [],
      };

      addTable(_table, pageNumber);
    }
    dispatch(setIsDragging(false));
    setMouseCoords({ x: 0, y: 0 });
    setCoords(initialCoords);
  }, [
    dispatch,
    entity,
    createTable,
    mode,
    coords,
    mouse,
    pageNumber,
    tables,
    mapAnnotationToTable,
    keyPressed,
    updateLastAnnotationForEntity,
    addAnnotation,
    pdfInformation,
    pdfContext,
    addTable,
    ref,
    groupedData,
  ]);

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event?.key?.toLowerCase() === "escape" && isDragging) {
        dispatch(setIsDragging(false));
        setMouseCoords({ x: 0, y: 0 });
        setCoords(initialCoords);
      }
    },
    [isDragging, dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent, false);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent, false);
    };
  }, [handleKeyEvent]);

  const handleMouseMove = useCallback(() => {
    if (isDragging && entity) {
      const { x, y } = mousePosition;
      setCoords(calculateSelectionRectangle(mouseCoords, { x: x, y: y }));
    }
    if (isDragging && createTable) {
      const { x, y } = mousePosition;
      setCoords(calculateSelectionRectangle(mouseCoords, { x: x, y: y }));
    }
  }, [isDragging, entity, mouseCoords, createTable, mousePosition]);

  return {
    mode,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    mousePosition,
    coords,
    isDragging,
  };
};

export default useSelection;

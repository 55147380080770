import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const getActiveDocument = (state: RootState) =>
  state.documentReducer.activeDocument;

const activeDocumentIdSelector = createSelector(
  [getActiveDocument],
  (activeDocument): string | null => {
    if (!activeDocument) {
      return null;
    }

    return activeDocument.id;
  }
);

export default activeDocumentIdSelector;

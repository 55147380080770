import React, { FC } from "react";

type Props = {
  showEditIcon: boolean;
  showRemoveIcon: boolean;
  onClickRemove?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const HoverButtons: FC<Props> = ({
  showEditIcon,
  showRemoveIcon,
  onClickRemove,
}) => {
  return (
    <div className="hover-icon">
      {showEditIcon && <i className="bi bi-pencil edit"></i>}
      {showRemoveIcon && (
        <i className="bi bi-trash remove" onClick={onClickRemove} />
      )}
    </div>
  );
};

export default HoverButtons;

import React, { FC, ReactNode } from "react";
import { Avatar, Tooltip } from "antd";
import "./CustomAvatar.scss";

type CustomAvatarProps = {
  size: number | "large" | "small" | "default";
  icon?: ReactNode;
  src: string;
  fontSize?: number;
  tooltipTitle?: string;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
    | undefined;
};

const CustomAvatar: FC<CustomAvatarProps> = ({
  size,
  icon = undefined,
  src = undefined,
  fontSize = undefined,
  tooltipTitle,
  placement,
}) => {
  return (
    <Tooltip placement={placement} title={tooltipTitle}>
      <span>
        <Avatar
          size={size}
          icon={icon}
          src={src}
          style={fontSize ? { fontSize: fontSize } : undefined}
          className="custom-avatar"
        >
          {src}
        </Avatar>
      </span>
    </Tooltip>
  );
};

export default CustomAvatar;

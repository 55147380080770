import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "./annotationReducerSelector";
import { Annotation } from "../../annotator/interfaces/annotation";
import { RootState } from "../../app";

type Props = {
  documentId?: string;
  entityId?: string;
  groupBlockIndex?: number;
};

export const EMPTY_ARRAY: Array<Annotation> = [];

const selectDocumentId = (_: RootState, props: Props) => props.documentId;
const selectEntityId = (_: RootState, props: Props) => props.entityId;
const selectGroupBlockIndex = (_: RootState, props: Props) =>
  props.groupBlockIndex;

const annotationsForEntitySelector = createSelector(
  [
    selectAnnotationReducer,
    selectDocumentId,
    selectEntityId,
    selectGroupBlockIndex,
  ],
  (
    selectAnnotations,
    documentId,
    entityId,
    groupBlockIndex
  ): Array<Annotation> => {
    if (
      !selectAnnotations?.length ||
      !documentId ||
      !entityId ||
      !groupBlockIndex
    ) {
      return EMPTY_ARRAY;
    }

    return selectAnnotations
      .filter(
        (annotation) =>
          annotation?.entity?.id === entityId &&
          annotation?.index === groupBlockIndex
      )
      .filter((annotation) => !annotation.tempAnnotation)
      .map((annotation, index) => ({
        ...annotation,
        index,
        scores: [annotation.modelScore!],
      }));
  }
);

export default annotationsForEntitySelector;

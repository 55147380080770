import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";
import { CounterState, DocumentSetData } from "../interfaces/documentSet";
import { Status } from "../../common/status/status";
import { User } from "../../user";
import {
  DocumentSetDto,
  HistoryItem,
} from "../../annotationSideControls/interfaces/drawer";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const documentSetApi = createApi({
  reducerPath: "documentSetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/document-sets`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    getDocumentSetCount: build.query<CounterState, string>({
      query: (idString) => ({
        url: `/count?inputIds=${idString}`,
        method: "GET",
      }),
    }),
    getDocumentSetData: build.query<DocumentSetData, string>({
      query: (documentSetId) => ({
        url: `/${documentSetId}`,
        method: "GET",
      }),
    }),
    getHistoryOfDocumentSet: build.query<Array<HistoryItem>, string>({
      query: (documentSetId) => ({
        url: `/history/${documentSetId}`,
        method: "GET",
      }),
    }),
    getDetailsOfDocumentSet: build.query<DocumentSetDto, string>({
      query: (documentSetId) => ({
        url: `/details/${documentSetId}`,
        method: "GET",
      }),
    }),
    updateDocumentSet: build.query<
      void,
      { documentSet: DocumentSetData; newStatus?: Status; assignee?: User }
    >({
      query: ({ documentSet, newStatus, assignee }) => {
        let url = `/${documentSet.id}/update`;

        if (assignee) {
          url = `${url}?assignee=${encodeURI(assignee.id)}`;
        } else {
          url = `${url}?isUnassign=true`;
        }

        if (newStatus) {
          url = `${url}&status=${newStatus}`;
        }

        return {
          url: url,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useLazyGetDocumentSetCountQuery,
  useLazyGetDocumentSetDataQuery,
  useLazyUpdateDocumentSetQuery,
  useGetHistoryOfDocumentSetQuery,
  useGetDetailsOfDocumentSetQuery,
} = documentSetApi;

import React, { FC, useCallback } from "react";
import useTableUpdater from "../../../../hooks/useTableUpdater";

type Props = {
  tableId: string;
  rowId: string;
  excluded: boolean;
  isCreatingTable: boolean;
  top: number;
};

const ExcludeRowButton: FC<Props> = ({
  tableId,
  rowId,
  excluded,
  isCreatingTable,
  top,
}) => {
  const { excludeRow } = useTableUpdater();

  const handleClick = useCallback(() => {
    excludeRow(tableId, rowId, !excluded);
  }, [excludeRow, excluded, tableId, rowId]);

  if (!isCreatingTable) {
    return null;
  }

  if (excluded) {
    return (
      <div
        className="exclude-button__container"
        style={{ top: top + 5 }}
        onClick={handleClick}
      >
        <span className="bi bi-eye-fill" />
      </div>
    );
  }

  return (
    <div
      className="exclude-button__container"
      style={{ top: top + 5 }}
      onClick={handleClick}
    >
      <span className="bi bi-eye-slash-fill" />
    </div>
  );
};

export default ExcludeRowButton;

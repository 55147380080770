export enum ErrorType {
  VALIDATION_ERROR = "validationError",
  CUSTOMER_API_ERROR = "customerApiError",
  SYSTEM_ERROR = "systemError",
}

export interface AlertError {
  title: string;
  message: string;
}

export interface MoreDetailsInfo {
  statusCode: number;
  message: string;
}

import { useCallback, useEffect } from "react";
import { setActiveSorter } from "../store/dataRefiningSlice";
import { SortDirection } from "../interfaces";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { OverviewType, useAppSelector } from "../../../app";
import { ARCHIVE_SORTER_KEY, OVERVIEW_SORTER_KEY } from "../";
import useStorageSortingKey from "./useStorageSortingKey";

const useRouteListener = (overviewType: OverviewType) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const activeSorterStorageKey = useStorageSortingKey(overviewType);

  const {
    userSettings: { archiveSorter, overviewSorter },
  } = useAppSelector((state) => state.userReducer);

  const checkPathForSorter = useCallback(() => {
    if (overviewSorter || archiveSorter) {
      return;
    }

    if (activeSorterStorageKey in sessionStorage) {
      return;
    }

    if (pathname.toLowerCase() === "/") {
      dispatch(
        setActiveSorter({
          storageKey: OVERVIEW_SORTER_KEY,
          state: {
            key: "createdDate",
            sortDirection: SortDirection.Ascending,
          },
        })
      );
      return;
    }
    if (pathname.toLowerCase() === "/archive") {
      dispatch(
        setActiveSorter({
          storageKey: ARCHIVE_SORTER_KEY,
          state: {
            key: "approvedDate",
            sortDirection: SortDirection.Descending,
          },
        })
      );
      return;
    }
  }, [
    dispatch,
    pathname,
    overviewSorter,
    archiveSorter,
    activeSorterStorageKey,
  ]);

  useEffect(() => {
    checkPathForSorter();
    // This is intentional as we only want to trigger the useEffect on pathname change
    // eslint-disable-next-line
  }, [pathname]);
};

export default useRouteListener;

import React, { FC } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  setOpenModal: (open: boolean) => void;
  isNextDocument: boolean | undefined;
  openModal: boolean;
  ignoreChangesAndProceed: () => void;
};

const ChangesFoundWhileNavigatingModal: FC<Props> = ({
  setOpenModal,
  isNextDocument,
  openModal,
  ignoreChangesAndProceed,
}) => {
  const { t } = useTranslation("detail", {
    keyPrefix: "controlsNavigationHeader",
  });

  return (
    <Modal
      onCancel={() => setOpenModal(false)}
      title={t("changesFoundModal.title").replace(
        "$KEY",
        isNextDocument ? t("next") : t("previous")
      )}
      open={openModal}
      footer={[
        <Button key="leave" danger onClick={() => setOpenModal(false)}>
          {t("changesFoundModal.cancel")}
        </Button>,
        <Button
          key="leaveSave"
          type="primary"
          onClick={ignoreChangesAndProceed}
        >
          {t("changesFoundModal.undoChangesAndProceed")}
        </Button>,
      ]}
    />
  );
};

export default ChangesFoundWhileNavigatingModal;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { DocumentType, InputType } from "../interfaces";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";
import { fetchedDocumentTypes } from "./documentTypesSlice";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const documentTypeApi = createApi({
  reducerPath: "documentTypeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    updateDocumentTypeRequest: build.mutation<
      DocumentType,
      { documentId: string; documentTypeId: string }
    >({
      query: ({ documentId, documentTypeId }) => ({
        url: `api/v1/documents/${documentId}/document-type/${documentTypeId}`,
        method: "POST",
      }),
    }),
    getDocumentTypes: build.query<Array<InputType>, string>({
      query: (inputIds) => ({
        url: `api/v1/input/document-types?ids=${inputIds}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchedDocumentTypes(data));
        } catch (err: any) {
          console.error(
            `An error occurred while retrieving the document types: ${parseReduxQueryError(
              err
            )}`
          );
        }
      },
    }),
  }),
});

export const {
  useUpdateDocumentTypeRequestMutation,
  useLazyGetDocumentTypesQuery,
} = documentTypeApi;

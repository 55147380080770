import React from "react";
import ApiFilterableItem from "./ApiFilterableItem";
import FilterableItem from "./FilterableItem";
import InputFilterItem from "./InputFilterItem";
import useStatusSelector from "../../../../common/menu/hooks/useStatusSelector";
import { FilterComponentType } from "../../../../documentSet/interfaces/overview";

const FilterableItemSwitch = (props: any) => {
  const filteringData = useStatusSelector(props.overviewType);

  switch (props.filterComponentType) {
    case FilterComponentType.API_SELECT:
      return <ApiFilterableItem {...props} />;
    case FilterComponentType.SELECT:
      return (
        // @ts-ignore
        <FilterableItem {...props} options={filteringData[[props.options]]} />
      );
    default:
      return <InputFilterItem {...props} />;
  }
};

export default FilterableItemSwitch;

import React, { FC } from "react";
import "./DocumentInformation.scss";
import SelectDocumentType from "../../../../documentTypes";

type Props = {
  disabled: boolean;
};

const DocumentInformation: FC<Props> = ({ disabled }) => {
  return <SelectDocumentType disabled={disabled} />;
};

export default DocumentInformation;

import React, { FC } from "react";
import "./GroupBlockTabs.scss";
import { Button, Tooltip } from "antd";
import useGroupBlockTabs from "../../hooks/useGroupBlockTabs";
import { GroupBlockData, useMultipleGroupBlocks } from "../../../configMap";
import { useTranslation } from "react-i18next";

type Props = {
  multipleLinesGroupBlocks: Array<GroupBlockData>;
  handleResizing: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const GroupBlockTabs: FC<Props> = ({
  multipleLinesGroupBlocks,
  handleResizing,
}) => {
  const {
    handleTabChange,
    groupBlockOptions,
    selectedGroupBlockId,
    hasMultipleGroupblocks,
    isCollapsed,
    collapseOrExpandTableControls,
  } = useGroupBlockTabs(multipleLinesGroupBlocks);

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const { t } = useTranslation("annotationTableControls");

  return (
    <div className="group-block-tabs-container">
      <div className="group-block__flex-container">
        {groupBlockOptions.map((option) => {
          const isActive =
            selectedGroupBlockId === option.value && hasMultipleGroupblocks;
          const count = countPerGroupBlockId(option.value);

          return (
            <div
              key={option.value}
              className="group-block-tab-button-container"
            >
              <div
                className="annotation-table-controls-resizer"
                onMouseDown={handleResizing}
              />
              <Button
                className={`group-block-tab-button 
                ${hasMultipleGroupblocks ? "has-multiple" : ""} 
                ${isActive ? "active" : ""}
              `}
                style={{ width: `100%` }}
                onClick={() => handleTabChange(option.value)}
              >
                <h1 className="group-block-name">
                  {`${option.label} - ${count} row(s)`}
                </h1>
              </Button>
            </div>
          );
        })}
      </div>
      <Tooltip title={t("buttons.resize")}>
        <i
          className="bi bi-chevron-bar-expand group-block__resize-icon"
          onMouseDown={handleResizing}
        />
      </Tooltip>
      <div className="group-block__flex-container">
        <div
          className="collapse-or-expand-container"
          onClick={() => collapseOrExpandTableControls(true, true)}
        >
          <Tooltip title={t("buttons.halfScreen")} placement="leftTop">
            <i className="bi bi-arrows-angle-expand" />
          </Tooltip>
        </div>
        <div
          className="collapse-or-expand-container"
          onClick={() => collapseOrExpandTableControls()}
        >
          <Tooltip
            title={isCollapsed ? t("buttons.expand") : t("buttons.collapse")}
            placement="leftTop"
          >
            {isCollapsed ? (
              <i className="bi bi-arrows-expand" />
            ) : (
              <i className="bi bi-arrows-collapse" />
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default GroupBlockTabs;

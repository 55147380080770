import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Status } from "../status/status";
import "./StatusColoredLabel.scss";

const TO_DO_STATUSES = [Status.ToDo, Status.AnnotationToDo];
const ON_HOLD_STATUSES = [Status.OnHold, Status.AnnotationOnHold];
const IN_PROGRESS_STATUSES = [Status.InProgress, Status.AnnotationInProgress];

const DONE_STATUSES = [Status.Approved, Status.Archived, Status.AnnotationDone];

type Props = {
  status: Status;
};

const StatusColoredLabel: FC<Props> = ({ status }) => {
  const { t } = useTranslation("overview");
  const colorClass: string = classNames({
    "to-do": TO_DO_STATUSES.includes(status),
    "in-progress": IN_PROGRESS_STATUSES.includes(status),
    "on-hold": ON_HOLD_STATUSES.includes(status),
    done: DONE_STATUSES.includes(status),
    rejected: status === Status.Rejected,
    exported: status === Status.Exported,
    "export-failed": status === Status.ExportFailed,
    error: status === Status.Error,
    removed: status === Status.Remove,
    processing: status === Status.Processing,
  });

  return (
    <>
      <span className={`circle-indicator ${colorClass}`} />
      {t(`status.${status}`)}
    </>
  );
};

export default StatusColoredLabel;

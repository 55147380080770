import { ButtonType } from "../../common";

export const shouldRouteToNextDocument = (
  buttonType: ButtonType,
  approveOpenNextDocument: boolean,
  holdOpenNextDocument: boolean,
  rejectOpenNextDocument: boolean
): boolean => {
  if (approveOpenNextDocument && buttonType === ButtonType.Approve) {
    return true;
  }

  if (holdOpenNextDocument && buttonType === ButtonType.Hold) {
    return true;
  }

  return rejectOpenNextDocument && buttonType === ButtonType.Reject;
};

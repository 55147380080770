import React, { FC, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Loader from "../loader/Loader";
import { useAppSelector } from "../../app";
import useIsAuthenticated from "../../user/hooks/useIsAuthenticated";

type PrivateRoutePops = {
  children: ReactNode;
};

const PrivateRoute: FC<PrivateRoutePops> = ({ children }) => {
  const { isAuthenticated, isLoading } = useIsAuthenticated();
  const { user } = useAppSelector((state) => state.userReducer);
  const { pathname } = useLocation();

  if (isLoading) {
    return <Loader fullPage />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ previousPath: pathname }} />;
  }

  if (!user) {
    return <Loader fullPage />;
  }

  return <>{children}</>;
};
export default PrivateRoute;

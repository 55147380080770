import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../app";
import { triggerRefreshMetrics } from "../../../analytics/store/analyticsSlice";
import { setMetricsMode } from "../../dataRefining/store/dataRefiningSlice";
import { MetricsMode } from "../../../analytics/constants";

const useMetricsModeSelector = () => {
  const { metricsMode } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const { loading } = useAppSelector((state) => state.analyticsReducer.metrics);

  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (metricsMode: MetricsMode) => {
      dispatch(setMetricsMode(metricsMode));
      dispatch(triggerRefreshMetrics());
    },
    [dispatch]
  );

  return {
    metricsMode,
    handleChange,
    loading,
  };
};

export default useMetricsModeSelector;

import { useCallback } from "react";
import { useAppSelector } from "../../app";

const X_SCROLL = 50;
const Y_SCROLL = 50;

const useAutoScroll = () => {
  const { isDragging } = useAppSelector((state) => state.annotationReducer);

  return useCallback(
    (event: any) => {
      if (!isDragging) {
        return;
      }

      const rect = event.currentTarget.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const { width, height } = rect;

      if (y <= 5) {
        event.currentTarget.scrollBy({
          top: -Y_SCROLL,
          left: 0,
          behavior: "smooth",
        });
      }

      if (y >= height - 5) {
        event.currentTarget.scrollBy({
          top: Y_SCROLL,
          left: 0,
          behavior: "smooth",
        });
      }

      if (x <= 5) {
        event.currentTarget.scrollBy({
          top: 0,
          left: -X_SCROLL,
          behavior: "smooth",
        });
      }

      if (x >= width + 5) {
        event.currentTarget.scrollBy({
          top: 0,
          left: X_SCROLL,
          behavior: "smooth",
        });
      }
    },
    [isDragging]
  );
};

export default useAutoScroll;

import React, { FC, useMemo } from "react";
import "./Detail.scss";
import AnnotationSideControls from "../../../annotationSideControls";
import DocumentTypeViewer from "./DocumentTypeViewer";
import ConfirmLeaveModal from "./confirmLeaveModal/ConfirmLeaveModal";
import useDetailPage from "../../hooks/useDetailPage";
import AnnotationTableControls from "../../../annotationTableControls";
import { useAppSelector } from "../../hooks/hooks";
import groupBlockWrapperSelector from "../../../configMap/selectors/groupBlockWrapperSelector";
import { GroupBlocksWrapper } from "../../../configMap/interfaces/groupblock";
import useResizableTableControls from "../../../annotationTableControls/hooks/useResizableTableControls";

export const VALID_RETURN_PATHS = ["/", "/archive", "/errors"];

const Detail: FC = () => {
  const {
    routeToOverview,
    detailContainer,
    renderConfirm,
    setRenderConfirm,
    disabled,
    documentId,
  } = useDetailPage();

  const hideNonRequiredEntities = useAppSelector(
    (state) => state.userReducer.userSettings.hideNonRequiredEntities
  );

  const { multipleLinesGroupBlocks } = useAppSelector<GroupBlocksWrapper>(
    (state) => groupBlockWrapperSelector(state, { hideNonRequiredEntities })
  );

  const hasMultipleLinesGroupBlocks = useMemo(() => {
    return multipleLinesGroupBlocks.length > 0;
  }, [multipleLinesGroupBlocks.length]);

  const {
    handleMouseDown: handleResizing,
    tableControlsHeight,
    resizableRef,
    heightAnnotator,
  } = useResizableTableControls(hasMultipleLinesGroupBlocks);

  return (
    <div className="detail-container" ref={detailContainer}>
      <ConfirmLeaveModal
        open={renderConfirm}
        setOpen={setRenderConfirm}
        routeToOverview={routeToOverview}
      />
      <div
        className={`annotator-container`}
        style={{ height: heightAnnotator }}
      >
        <div id="tour-detail-step-3">
          <DocumentTypeViewer />
        </div>
        <AnnotationSideControls
          disabled={disabled}
          documentSetId={documentId}
        />
      </div>

      {hasMultipleLinesGroupBlocks ? (
        <>
          <div
            className="annotation-table-controls-container"
            style={{ height: `${tableControlsHeight}px` }}
            ref={resizableRef}
          >
            <AnnotationTableControls
              disabled={disabled}
              multipleLinesGroupBlocks={multipleLinesGroupBlocks}
              hideNonRequiredEntities={hideNonRequiredEntities}
              handleResizing={handleResizing}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Detail;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupBlockEntityType } from "../../configMap";
import { getActivePageFromSessionStorage } from "../../common/menu/utils/utils";
import { ButtonType } from "../";
import { ColumnItem, KeyByAny } from "../../documentSet/interfaces/overview";
import { IEntityHover } from "../../annotator/interfaces/entity";
import { MetricFilterItem } from "../../analytics/interfaces/documentSetMetrics";

interface AppState {
  isEditing: string | null;
  isAnnotating: boolean;
  hasBeenEdited: boolean;
  activePage: number;
  hoveredEntities: Array<any>;
  blurredEntities: Array<string>;
  activeEntity?: GroupBlockEntityType;
  activeButton?: ButtonType;
  overviewMap: Array<ColumnItem>;
  metricsMap: Array<MetricFilterItem>;
  detailBackButtonTrigger: boolean;
  modalStates: KeyByAny;
  scrollIntoView?: GroupBlockEntityType;
}

const initialState: AppState = {
  isEditing: null,
  isAnnotating: false,
  hasBeenEdited: false,
  activePage: getActivePageFromSessionStorage(),
  hoveredEntities: [],
  blurredEntities: [],
  activeEntity: undefined,
  activeButton: undefined,
  scrollIntoView: undefined,
  overviewMap: [],
  metricsMap: [],
  detailBackButtonTrigger: false,
  modalStates: {
    userManagementAddMembers: false,
    userManagementInvites: false,
  },
};

export const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    changeEditing: (state, action: PayloadAction<string | null>) => {
      state.isEditing = action.payload;
    },
    changeAnnotating: (state, action: PayloadAction<boolean>) => {
      state.isAnnotating = action.payload;
    },
    changeActiveEntity: (
      state,
      action: PayloadAction<GroupBlockEntityType | undefined>
    ) => {
      state.activeEntity = action.payload;
    },
    changeScrollToEntity: (
      state,
      action: PayloadAction<GroupBlockEntityType | undefined>
    ) => {
      state.scrollIntoView = action.payload;
    },
    changeActiveButton: (
      state,
      action: PayloadAction<ButtonType | undefined>
    ) => {
      state.activeButton = action.payload;
    },
    changeHasBeenEdited: (state, action: PayloadAction<boolean>) => {
      state.hasBeenEdited = action.payload;
    },
    changeActivePage: (state, action: PayloadAction<number>) => {
      if (action.payload < 0) {
        sessionStorage.setItem("activePage", JSON.stringify(0));
        state.activePage = 0;
        return;
      }

      sessionStorage.setItem("activePage", JSON.stringify(action.payload));
      state.activePage = action.payload;
    },
    setHoveredEntities: (state, action: PayloadAction<Array<IEntityHover>>) => {
      state.hoveredEntities = action.payload;
    },
    addHoveredEntities: (state, action: PayloadAction<Array<IEntityHover>>) => {
      state.hoveredEntities = [...state.hoveredEntities, ...action.payload];
    },
    removedHoveredEntities: (
      state,
      action: PayloadAction<Array<IEntityHover>>
    ) => {
      state.hoveredEntities = [...state.hoveredEntities].filter(
        (a) => !action.payload.some((b) => b.id === a.id && b.index === a.index)
      );
    },
    clearHoveredEntities: (state) => {
      state.hoveredEntities = [];
    },
    addBlurredEntity: (state, action: PayloadAction<string>) => {
      if (!state.blurredEntities.includes(action.payload)) {
        state.blurredEntities = [...state.blurredEntities, action.payload];
      }
    },
    setOverviewMap: (state, action: PayloadAction<Array<ColumnItem>>) => {
      state.overviewMap = action.payload;
    },
    setMetricsMap: (state, action: PayloadAction<Array<MetricFilterItem>>) => {
      state.metricsMap = action.payload;
    },
    resetAppReducer: (state) => {
      return {
        ...initialState,
        activePage: state.activePage,
        overviewMap: state.overviewMap,
        metricsMap: state.metricsMap,
      };
    },
    changeModalStateForKey: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      if (key in state.modalStates) {
        const open = state.modalStates[key];
        state.modalStates[key] = !open;
        return;
      }

      state.modalStates[key] = true;
    },
    triggerDetailBackButton: (state) => {
      state.detailBackButtonTrigger = !state.detailBackButtonTrigger;
    },
  },
});

export const {
  changeEditing,
  changeAnnotating,
  changeActiveEntity,
  changeActiveButton,
  changeHasBeenEdited,
  changeActivePage,
  setHoveredEntities,
  addHoveredEntities,
  removedHoveredEntities,
  clearHoveredEntities,
  addBlurredEntity,
  setOverviewMap,
  setMetricsMap,
  resetAppReducer,
  triggerDetailBackButton,
  changeModalStateForKey,
  changeScrollToEntity,
} = appSlice.actions;

export default appSlice.reducer;

import { useEffect, useState } from "react";
import { arrayBufferToBase64 } from "../../document/utils/binary";
import { useLazyGetPdfDocumentQuery } from "../../document/store/documentApi";

const useDocumentPreview = (documentId: string) => {
  const [pdfFile, setPdfFile] = useState<string | null>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [getPdfDocument] = useLazyGetPdfDocumentQuery();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const loadPdf = async () => {
      try {
        setIsLoading(true);
        setIsError(false);
        setPdfFile(null);
        const data = await getPdfDocument(documentId).unwrap();
        setPdfFile(arrayBufferToBase64(data));
      } catch (err) {
        if (!signal.aborted) {
          setIsError(true);
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadPdf();

    return () => {
      controller.abort();
    };
  }, [documentId, setPdfFile, getPdfDocument]);

  return {
    pdfFile,
    isError,
    isLoading,
  };
};

export default useDocumentPreview;

import React, { FC } from "react";
import { ErrorType } from "../../../interfaces/errorType";

type Props = {
  type?: string;
};

const AlertIconRenderer: FC<Props> = ({ type }) => {
  switch (type) {
    case ErrorType.SYSTEM_ERROR:
      return <i className="bi bi-exclamation-circle error__icon" />;
    case ErrorType.CUSTOMER_API_ERROR:
      return <i className="bi bi-exclamation-diamond error__icon" />;
    default:
      return <i className="bi bi-exclamation-triangle error__icon" />;
  }
};

export default AlertIconRenderer;

import React, { FC, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./RejectModal.scss";
import { useAppDispatch, useAppSelector } from "../../../../app";
import { changeEditing } from "../../../../app/store/appSlice";
import "react-quill/dist/quill.snow.css";
import useEmailTemplates from "../../../../emailTemplates/hooks/useEmailTemplates";
import EmailTemplateSelector from "./emailTemplateSelector/EmailTemplateSelector";

type Props = {
  open: boolean;
  setOpen(value: boolean): void;
  setIsLoading: (isLoading: boolean) => void;
  handleAbort: (setIsLoading: (isLoading: boolean) => void) => void;
  handleSend: (
    setIsLoading: (isLoading: boolean) => void,
    message: string
  ) => void;
};

const RejectModal: FC<Props> = ({
  open,
  setOpen,
  setIsLoading,
  handleAbort,
  handleSend,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("annotationSideControls");

  const activeDocumentSet = useAppSelector(
    (state) => state.documentSetsReducer.activeDocumentSet
  );

  const [message, setMessage] = useState("");

  const { emailTemplates, favoriteTemplate } = useEmailTemplates();

  useEffect(() => {
    if (open) {
      dispatch(changeEditing("rejectModal"));
    } else {
      dispatch(changeEditing(null));
    }
  }, [dispatch, open]);

  return (
    <Modal
      width={1000}
      open={open}
      onCancel={() => setOpen(false)}
      footer={[
        <Button
          className="reject-modal__button"
          key="abort"
          onClick={() => handleAbort(setIsLoading)}
        >
          {t("rejectModal.abort")}
        </Button>,
        <Button
          className="reject-modal__button"
          key="send"
          type="primary"
          onClick={() => handleSend(setIsLoading, message)}
        >
          {t("rejectModal.send")}
        </Button>,
      ]}
    >
      <div className="reject-modal__content-container">
        <h3>{t("rejectModal.title")}</h3>
        <div />
        <div className="reject-modal__sender">
          <h4>{t("rejectModal.subTitle")}</h4>
          <h5>
            {activeDocumentSet?.email?.sender?.name}{" "}
            {`<${activeDocumentSet?.email?.sender?.address}>`}
          </h5>
        </div>

        {emailTemplates?.length > 0 && (
          <EmailTemplateSelector
            setMessage={setMessage}
            emailTemplates={emailTemplates}
            favoriteTemplate={favoriteTemplate}
          />
        )}

        <ReactQuill
          theme="snow"
          value={message}
          className="reject-modal__content-editor"
          onChange={setMessage}
        />
      </div>
    </Modal>
  );
};

export default RejectModal;

export const baseScale = 1.5;

export const PDF_BASE_WIDTH_PORTRAIT = 612;
export const PDF_BASE_HEIGHT_PORTRAIT = 792;

export const PDF_BASE_WIDTH_LANDSCAPE = 792;
export const PDF_BASE_HEIGHT_LANDSCAPE = 612;

export const MIN_SCALE = 0.5;

export const INACTIVE_TOKEN_COLOR = "#aaaaac";

export enum EAnnotatorMode {
  TEXT_SELECTION_MODE,
  ANNOTATION_MODE,
  TABLE_CREATION_MODE,
  TABLE_ANNOTATION_MODE,
}

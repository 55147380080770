import React, { FC } from "react";

type Props = {
  icon?: string;
};

const ValidationIconMapper: FC<Props> = ({ icon }) => {
  if (!icon) {
    return null;
  }

  switch (icon) {
    case "ExclamationCircle":
      return <i className="bi bi-exclamation-circle" />;
    case "Warning":
      return <i className="bi bi-exclamation-triangle" />;
    default:
      return null;
  }
};

export default ValidationIconMapper;

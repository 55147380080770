import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import { ColumnItem } from "../interfaces/overview";
import useFocus from "./useFocus";
import { InputRef } from "antd";

const useInputFilterItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean,
  isBlankEntriesChecked: boolean,
  blankValue: string,
  values: Array<string> | undefined
) => {
  const dispatch = useAppDispatch();

  const ref: MutableRefObject<null | InputRef> = useRef(null);

  useFocus(ref, isOpen);

  const selectValue = useMemo(() => {
    const filteredValues = isBlankEntriesChecked
      ? values?.filter((value) => value !== blankValue)
      : values;

    if (filteredValues?.length) {
      return filteredValues[0];
    }

    return "";
  }, [values, isBlankEntriesChecked, blankValue]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      let updatedValues: Array<string> = [value];
      if (isBlankEntriesChecked) {
        updatedValues = [...updatedValues, blankValue];
      }

      const updateCalls = buildFilterUpdateCalls(
        updatedValues,
        columnItem?.metadata,
        overviewType
      );

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [
      dispatch,
      columnItem?.metadata,
      overviewType,
      isBlankEntriesChecked,
      blankValue,
    ]
  );

  return {
    ref,
    selectValue,
    handleChange,
  };
};

export default useInputFilterItem;

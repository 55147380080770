import React, { FC } from "react";
import "./UserGraphs.scss";
import { DocumentSetMetrics } from "../../interfaces/documentSetMetrics";
import { useTranslation } from "react-i18next";
import InteractiveLinePieGraph from "../graphs/InteractiveLinePieGraph";
import { Alert } from "antd";
import LineGraph from "../graphs/lineGraph/LineGraph";

type Props = {
  metrics: DocumentSetMetrics | undefined;
  loading: boolean;
  showNoMetricsAlert: boolean;
};

const UserGraphs: FC<Props> = ({ metrics, loading, showNoMetricsAlert }) => {
  const { t } = useTranslation("analytics");

  if (showNoMetricsAlert && !loading) {
    return (
      <Alert
        message={t("noMetricsFound")}
        type="info"
        showIcon
        className="graph-alert"
      />
    );
  }

  return (
    <div className="metric-graphs__container">
      <InteractiveLinePieGraph
        title={t("metricGraphs.documentsProcessed")}
        lineGraphMetrics={metrics?.approvedGraphData}
        pieGraphMetrics={metrics?.approvedPieMetricsTotals}
        loading={loading}
        useSourceColors={true}
        tooltipTitle={t("tooltips.manualUser")}
        sourceColors={metrics?.sourceColors}
      />
      <InteractiveLinePieGraph
        title={t("metricGraphs.rejected")}
        lineGraphMetrics={metrics?.rejectedGraphData}
        pieGraphMetrics={metrics?.rejectedPieMetricsTotals}
        loading={loading}
        useSourceColors={true}
        tooltipTitle={t("tooltips.rejectedUser")}
        sourceColors={metrics?.sourceColors}
      />
      <LineGraph
        title={t("metricGraphs.averageProcessingTime")}
        metrics={metrics?.processingGraphData}
        loading={loading}
        tooltipTitle={t("tooltips.averageProcessingTimeUser")}
        sourceColors={metrics?.sourceColors}
      />
    </div>
  );
};

export default UserGraphs;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { Filters, FilterType } from "../interfaces/table";
import {
  setFilteredMembers,
  updateFilters,
} from "../store/UserManagementSlice";
import { Option } from "../../common/dataRefining/interfaces/filteringData";
import Fuse from "fuse.js";

const useTableHeaderFilter = (filterType: FilterType) => {
  const dispatch = useAppDispatch();

  const { filters, members, validRoles } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const { organizationSources } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const [value, setValue] = useState<string | Array<string>>(() => {
    switch (filterType) {
      case FilterType.name:
        return filters.name;
      case FilterType.email:
        return filters.email;
      case FilterType.sources:
        return filters.sources;
      case FilterType.roles:
        return filters.roles;
      default:
        return "";
    }
  });

  const fuse = useMemo(() => {
    return new Fuse(members, {
      keys: ["fullName", "email", "permissions", "roles"],
      threshold: 0.15,
    });
  }, [members]);

  const updateFilter = useCallback(() => {
    const updatedFilters: Filters = { ...filters };

    switch (filterType) {
      case FilterType.name:
        updatedFilters.name = String(value);
        break;
      case FilterType.email:
        updatedFilters.email = String(value);
        break;
      case FilterType.sources:
        updatedFilters.sources = Array.isArray(value)
          ? (value as Array<string>)
              .map(
                (v: string) =>
                  organizationSources.find(
                    (s) => s.id.toLowerCase() === v.toLowerCase()
                  )?.id ?? ""
              )
              .filter((v) => v.length !== 0)
          : [];
        break;
      case FilterType.roles:
        updatedFilters.roles = Array.isArray(value)
          ? (value as Array<string>)
              .map(
                (v: string) =>
                  validRoles.find(
                    (vr) => vr.id.toLowerCase() === v.toLowerCase()
                  )?.id ?? ""
              )
              .filter((v) => v.length !== 0)
          : [];
        break;
    }

    dispatch(updateFilters(updatedFilters));
  }, [dispatch, filters, filterType, value, organizationSources, validRoles]);

  const updateFilteredMembers = useCallback(() => {
    const query: Fuse.Expression[] = [];
    if (filters.name.length) {
      query.push({ fullName: `${filters.name}` });
    }
    if (filters.email.length) {
      query.push({ email: filters.email });
    }
    if (filters.sources.length) {
      filters.sources.forEach((filter) => query.push({ permissions: filter }));
    }
    if (filters.roles.length) {
      filters.roles.forEach((filter) => query.push({ roles: filter }));
    }

    if (!query.length) {
      dispatch(setFilteredMembers(members));
      return;
    }
    const result = fuse.search({
      $and: [...query],
    });
    const filtered = result.map((member) => member.item);

    dispatch(setFilteredMembers(filtered));
  }, [filters, dispatch, members, fuse]);

  const options: Array<Option> = useMemo(() => {
    switch (filterType) {
      case FilterType.sources:
        return organizationSources.map((source) => ({
          value: source.id,
          label: source.displayName,
        }));
      case FilterType.roles:
        return validRoles.map((vr) => ({
          value: vr.id,
          label: vr.name,
        }));
      default:
        return [];
    }
  }, [organizationSources, filterType, validRoles]);

  useEffect(() => {
    updateFilteredMembers();
  }, [filters, updateFilteredMembers, members]);

  return {
    updateFilter,
    value,
    setValue,
    options,
  };
};

export default useTableHeaderFilter;

import { Point } from "../interfaces/point";
import { Rectangle } from "../../annotator/interfaces/textLayer";

export const isCoordsEmpty = (coordinates: Rectangle): boolean => {
  return coordinates.width * coordinates.height <= 25;
};

export const calculateSelectionRectangle = (
  startPoint: Point,
  endPoint: Point
): Rectangle => {
  const x3 = Math.min(startPoint.x, endPoint.x);
  const x4 = Math.max(startPoint.x, endPoint.x);
  const y3 = Math.min(startPoint.y, endPoint.y);
  const y4 = Math.max(startPoint.y, endPoint.y);

  return { left: x3, top: y3, width: x4 - x3, height: y4 - y3, rotation: 0 };
};

export const findIntersectingChildren = (
  children: Array<any>,
  selectionRect: Rectangle
) => {
  return Array.from(children).filter((child) =>
    intersects(child, selectionRect)
  );
};

const intersects = (child: any, selectionRect: Rectangle): boolean => {
  if (
    Object.values(child.attributes)
      .map((value: any) => value.name)
      .includes("data-ignore")
  ) {
    return false;
  }

  const width =
    child.lastElementChild === null
      ? child.offsetParent.offsetWidth
      : child.offsetWidth;
  const height =
    child.lastElementChild === null
      ? child.offsetParent.offsetHeight
      : child.offsetHeight;

  const offsetX =
    child.lastElementChild === null ? child.offsetParent.offsetLeft : 0;
  const offsetY =
    child.lastElementChild === null ? child.offsetParent.offsetTop : 0;

  const leftX = Math.max(selectionRect.left, child.offsetLeft + offsetX);
  const rightX = Math.min(
    selectionRect.left + selectionRect.width,
    child.offsetLeft + offsetX + width
  );
  const topY = Math.max(selectionRect.top, child.offsetTop + offsetY);
  const bottomY = Math.min(
    selectionRect.top + selectionRect.height,
    child.offsetTop + offsetY + height
  );

  return leftX < rightX && topY < bottomY;
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { GroupBlockData } from "../../configMap";
import { GroupBlockTab } from "../interfaces/groupBlockTable";
import { useDispatch } from "react-redux";
import { RootState, useAppSelector } from "../../app";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import {
  setAutoResizeDisabled,
  setSelectedGroupBlockId,
} from "../store/AnnotationTableControlsSlice";
import { changeActiveEntity, changeAnnotating } from "../../app/store/appSlice";
import {
  MAX_TABLE_CONTROLS_HEIGHT,
  MIN_TABLE_CONTROLS_HEIGHT,
} from "../constants";
import activeGroupBlockEntityTypeSelector from "../../configMap/selectors/activeGroupBlockEntityTypeSelector";
import useSetGroupBlockTabHeightByLines from "./useSetTableControlsHeight";
import useStableCallbackRef from "../../common/utilities/hooks/useStableCallbackRef";
import { setHighlightedEntities } from "../../annotation/store/annotationSlice";

const useGroupBlockTabs = (multipleLinesGroupBlocks: Array<GroupBlockData>) => {
  const dispatch = useDispatch();

  const {
    selectedGroupBlockId,
    triggerGroupBlockTabCheck,
    tableControlsHeight,
    isTableControlsResizing,
  } = useAppSelector(
    (state: RootState) => state.annotationTableControlsReducer
  );

  const activeGroupBlockEntityType = useAppSelector(
    activeGroupBlockEntityTypeSelector
  );

  const { setTableControlsHeightByLines, setTableControlHeight } =
    useSetGroupBlockTabHeightByLines();

  const setTableControlsHeightByLinesRef = useStableCallbackRef(
    setTableControlsHeightByLines
  );

  const [previousHeight, setPreviousHeight] = useState(-1);

  const {
    i18n: { language },
  } = useTranslation();

  const groupBlockTabs: Array<GroupBlockTab> = useMemo(() => {
    if (!multipleLinesGroupBlocks) {
      return [];
    }

    return multipleLinesGroupBlocks.map((gb): GroupBlockTab => {
      const categorizationColumnItems = gb.categorizationGroupBlocks.map(
        (categorizationWithConfig) => categorizationWithConfig.id
      );
      const entityColumnItems = gb.groupBlockEntityTypes.map(
        (et) => et.entityType.id
      );

      return {
        groupBlock: gb,
        columnsIds: categorizationColumnItems.concat(entityColumnItems),
      };
    });
  }, [multipleLinesGroupBlocks]);

  const groupBlockOptions = useMemo(() => {
    return groupBlockTabs.map((gb) => {
      return {
        value: gb.groupBlock.id,
        label: getInLanguageOrDefault(gb.groupBlock.translations, language),
      };
    });
  }, [groupBlockTabs, language]);

  const hasMultipleGroupblocks = useMemo(() => {
    return groupBlockOptions.length > 1;
  }, [groupBlockOptions.length]);

  const handleTabChange = useCallback(
    (value: string) => {
      if (!hasMultipleGroupblocks) {
        return;
      }

      dispatch(changeActiveEntity(undefined));
      dispatch(changeAnnotating(false));
      dispatch(setSelectedGroupBlockId(value));
      dispatch(setHighlightedEntities([]));

      setTableControlsHeightByLines(value);
    },
    [hasMultipleGroupblocks, dispatch, setTableControlsHeightByLines]
  );

  const isCollapsed = useMemo(() => {
    return tableControlsHeight === MIN_TABLE_CONTROLS_HEIGHT;
  }, [tableControlsHeight]);

  const collapseOrExpandTableControls = useCallback(
    (expandToMaxHeight = false, disableAutoResize = false) => {
      dispatch(setAutoResizeDisabled(disableAutoResize));
      if (expandToMaxHeight) {
        if (previousHeight !== -1) {
          setTableControlHeight(previousHeight);
          setPreviousHeight(-1);
          return;
        }

        setPreviousHeight(tableControlsHeight);
        setTableControlHeight(MAX_TABLE_CONTROLS_HEIGHT);
        return;
      }

      if (!isCollapsed && activeGroupBlockEntityType?.multipleGroupBlocks) {
        dispatch(changeAnnotating(false));
        dispatch(changeActiveEntity(undefined));
      }

      if (isCollapsed) {
        setTableControlsHeightByLines();
      } else {
        setTableControlHeight(MIN_TABLE_CONTROLS_HEIGHT);
        dispatch(setHighlightedEntities([]));
      }
    },
    [
      dispatch,
      isCollapsed,
      activeGroupBlockEntityType?.multipleGroupBlocks,
      previousHeight,
      tableControlsHeight,
      setTableControlHeight,
      setTableControlsHeightByLines,
    ]
  );

  useEffect(() => {
    if (!activeGroupBlockEntityType) {
      return;
    }

    const activeEntityGroupBlock = groupBlockTabs.find((groupBlock) => {
      return groupBlock.columnsIds.find(
        (id) => id.toLowerCase() === activeGroupBlockEntityType?.entityType.id
      );
    });

    if (!activeEntityGroupBlock) {
      return;
    }

    if (isCollapsed && !isTableControlsResizing) {
      setTableControlsHeightByLines();
    }

    if (selectedGroupBlockId !== activeEntityGroupBlock.groupBlock.id) {
      dispatch(setSelectedGroupBlockId(activeEntityGroupBlock.groupBlock.id));
    }
  }, [
    triggerGroupBlockTabCheck,
    dispatch,
    groupBlockTabs,
    activeGroupBlockEntityType,
    selectedGroupBlockId,
    isCollapsed,
    isTableControlsResizing,
    setTableControlsHeightByLines,
  ]);

  useEffect(() => {
    setTableControlsHeightByLinesRef.current(selectedGroupBlockId);
  }, [selectedGroupBlockId, setTableControlsHeightByLinesRef]);

  useEffect(() => {
    if (!groupBlockOptions.length) {
      return;
    }

    dispatch(setSelectedGroupBlockId(groupBlockOptions[0].value));
  }, [groupBlockOptions, dispatch]);

  return {
    handleTabChange,
    groupBlockOptions,
    selectedGroupBlockId,
    hasMultipleGroupblocks,
    isCollapsed,
    collapseOrExpandTableControls,
    setTableControlsHeightByLines,
  };
};

export default useGroupBlockTabs;

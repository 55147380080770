import React, { FC, MouseEvent, useCallback } from "react";
import { notification } from "antd";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import { useActiveDocumentSet } from "../../../../index";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../app";
import { doneStatuses } from "../../../../../common/status/status";

type Props = {
  documentSet: DocumentSetData;
};

const RemoveCell: FC<Props> = ({ documentSet }) => {
  const { t } = useTranslation("messages");
  const { handleRemoveDocumentSet } = useActiveDocumentSet();
  const user = useAppSelector((state) => state.userReducer.user);

  const handleRemoveClicked = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      await handleRemoveDocumentSet(documentSet);
      notification.success({
        key: "deleteToast",
        message: t("documentRemoved.message"),
        description: t("documentRemoved.description"),
      });
    },
    [t, documentSet, handleRemoveDocumentSet]
  );

  if (doneStatuses.includes(documentSet.status)) {
    return <div style={{ minWidth: "55px" }} />;
  }

  if (documentSet.assignee) {
    if (documentSet.assignee.id !== user?.id) {
      return <div style={{ minWidth: "55px" }} />;
    }
  }

  return (
    <div className="delete-icon" onClick={handleRemoveClicked}>
      <i className="bi bi-trash" />
    </div>
  );
};

export default RemoveCell;

import React, { FC, useCallback, useState } from "react";
import {
  Component,
  EntityType,
  GroupBlockEntityType,
} from "../../../../configMap";
import {
  Button,
  ColorPicker,
  Input,
  Select,
  Table,
  TableColumnsType,
} from "antd";
import { getInLanguageOrDefault } from "../../../../common/utilities/language";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../../documentSet/components/tableHeader/orderColumns/StrictModeDroppable";

const DraggableJsx: any = Draggable;
const DragDropContextJsx: any = DragDropContext;

type Props = {
  entitiesData: Array<GroupBlockEntityType>;
  language: string;
};

interface EntityTableData {
  key: string;
  index: number;
  color: string;
  hotkey: string;
  entityType: EntityType;
}

const ConfigmapEntitiesTable: FC<Props> = ({ entitiesData, language }) => {
  const [entities, setEntities] = useState(entitiesData);

  const columns: TableColumnsType<EntityTableData> = [
    {
      title: "",
      key: "dragIcon",
      width: "40px",
      render: () => <span className="bi bi-grip-vertical" />,
    },
    {
      title: "Translations",
      key: "translations",
      width: "300px",
      render: (_, { entityType: { translations } }) =>
        `${getInLanguageOrDefault(translations, language)}`,
    },
    {
      title: "Color",
      key: "color",
      render: (_, { color }) => <ColorPicker value={color} showText />,
    },
    {
      title: "Hotkey",
      key: "hotkey",
      render: (_, { hotkey }) => (
        <Input style={{ width: 50, textAlign: "center" }} value={hotkey} />
      ),
    },
    {
      title: "Component",
      key: "component",
      render: (_, { entityType: { component } }) => {
        return (
          <Select
            value={component}
            style={{ width: 200 }}
            options={Object.values(Component).map((c) => ({
              label: c,
              value: c,
            }))}
          />
        );
      },
    },
    {
      title: "",
      key: "action",
      render: () => (
        <>
          <Button type="text" icon={<span className="bi bi-globe" />} />
          <Button danger type="text" icon={<span className="bi bi-trash" />} />
        </>
      ),
    },
  ];

  const handleOnDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(entities);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setEntities(items);
    },
    [entities]
  );

  return (
    <DragDropContextJsx onDragEnd={handleOnDragEnd}>
      <StrictModeDroppable droppableId="card__container">
        {(provided: any) => (
          <div
            className="card__container"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Table
              columns={columns}
              dataSource={entities}
              pagination={false}
              onRow={(record, index) => {
                return {
                  ...record,
                  index,
                };
              }}
              components={{
                body: {
                  row: Row,
                },
              }}
              footer={() => (
                <div className="footer__add-button">
                  <Button
                    type="text"
                    icon={<span className="bi bi-plus-square" />}
                  >
                    Add entity
                  </Button>
                </div>
              )}
            />
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContextJsx>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
  index: number;
}

const Row: FC<RowProps> = (props) => {
  return (
    <DraggableJsx draggableId={props["data-row-key"]} index={props.index}>
      {(provided: any) => {
        return (
          <tr
            {...props}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={`card ${props.className}`}
          >
            {props.children}
          </tr>
        );
      }}
    </DraggableJsx>
  );
};

export default ConfigmapEntitiesTable;

import { ITable } from "../interfaces/ITable";
import { useCallback, useMemo } from "react";
import useAnnotations from "../../annotation";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import { updateTableAnnotationsInState } from "../../annotator/store/AnnotatorSlice";
import { useAppDispatch, useAppSelector } from "../../app";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";

const useAnnotatorData = (pageNumber?: number) => {
  const dispatch = useAppDispatch();

  const { activeDocument } = useActiveDocument();

  const { textLayer } = useAppSelector((state) => state.annotatorReducer);

  const { tables } = useAppSelector(
    (state) => state.annotatorReducer.tableState
  );

  const annotations = useAppSelector(selectAnnotationReducer);

  const { updateAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const updateTables = useCallback(
    (tables: Array<ITable>) => {
      dispatch(updateTableAnnotationsInState(tables));
    },
    [dispatch]
  );

  const annotationsForPage = useMemo(() => {
    if (!pageNumber) {
      return [];
    }

    return annotations.filter(
      (annotation) =>
        !!annotation.areaAnnotation && annotation.page === pageNumber
    );
  }, [annotations, pageNumber]);

  return {
    tables,
    annotations,
    annotationsForPage,
    updateAnnotations,
    updateTables,
    textLayer,
  };
};

export default useAnnotatorData;

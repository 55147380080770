import React, { FunctionComponent } from "react";
import "./Loader.scss";
import classNames from "classnames";

type LoaderProps = {
  fullPage?: boolean;
  size?: "small" | "default" | "large" | number;
};

const Loader: FunctionComponent<LoaderProps> = ({
  fullPage = false,
  size = "large",
}) => {
  const className: string = classNames("loader__container", {
    "full-page": fullPage,
  });

  return (
    <div className={className}>
      <div className={`circle-loader ${size}`} />
    </div>
  );
};

export default Loader;

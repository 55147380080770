export const getClassNameForTrend = (
  trend: number,
  defaultClassName: string,
  inverse = false
) => {
  let calculatedTrend = trend;
  if (inverse) {
    calculatedTrend *= -1;
  }

  if (calculatedTrend > 0) {
    if (inverse) {
      return `${defaultClassName} positive-inverse`;
    }
    return `${defaultClassName} positive`;
  }

  if (calculatedTrend < 0) {
    if (inverse) {
      return `${defaultClassName} negative-inverse`;
    }

    return `${defaultClassName} negative`;
  }

  return `${defaultClassName} neutral`;
};

import { useCallback, useEffect, useState } from "react";
import { getModifierKeyForPlatform, getPlatform } from "../platform";

const useKeyPressedListener = (
  listenKey: string,
  requiresModifierKey = false,
  preventBrowserActions = false
) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      const { key } = event;
      const platform = getPlatform();
      const modifierKeyPressed = event.getModifierState(
        getModifierKeyForPlatform(platform)
      );

      if (requiresModifierKey && !modifierKeyPressed) {
        return;
      }

      if (key.toUpperCase() === listenKey.toUpperCase()) {
        setKeyPressed(true);

        if (preventBrowserActions) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },
    [listenKey, requiresModifierKey, preventBrowserActions]
  );

  const upHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (requiresModifierKey) {
        setKeyPressed(false);
      }

      if (key.toUpperCase() === listenKey.toUpperCase()) {
        setKeyPressed(false);
      }
    },
    [listenKey, requiresModifierKey]
  );

  const resetState = useCallback(() => {
    setKeyPressed(false);
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [downHandler, upHandler]);

  return { keyPressed, resetState };
};

export default useKeyPressedListener;

import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { OverviewType } from "../../../../app";
import { Select } from "antd";
import SelectEmptyState from "../../../../metadata/components/categorization/SelectEmptyState";
import { ColumnItem } from "../../../interfaces/overview";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import useFilteringSearch from "../../../hooks/useFilteringSearch";
import useApiFilterableItem from "../../../hooks/useApiFilterableItem";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import ExtraSelectFilter from "./ExtraSelectFilter";
import DropdownActions from "../../../../common/tableHeaderFilter/dropdownActions/DropdownActions";

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  placeholder: string;
  mode?: "multiple";
  renderRef: MutableRefObject<any>;
  isOpen: boolean;
  overviewType: OverviewType;
};

const ApiFilterableItem: FC<Props> = ({
  columnItem,
  label,
  placeholder,
  mode,
  renderRef,
  isOpen,
  overviewType,
}) => {
  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter
    )?.split("+");
  }, [getValueFromKey, columnItem?.metadata, overviewType]);

  const {
    options,
    debouncedHandleSearch,
    handlePreFetch,
    handleFilter,
    handleBlur,
  } = useFilteringSearch(
    columnItem?.metadata?.filterAble!.query,
    undefined,
    values
  );

  const {
    isBlankEntriesChecked,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { ref, selectValue, handleChange, open, setOpen } =
    useApiFilterableItem(
      columnItem,
      overviewType,
      isOpen,
      isBlankEntriesChecked,
      blankValue,
      values
    );

  return (
    <div className="filter-popover__item">
      <label className="filter-popover__label">{label}</label>
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={isBlankEntriesChecked}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <Select
        ref={ref}
        showSearch
        allowClear
        value={selectValue}
        placeholder={placeholder}
        onSearch={debouncedHandleSearch}
        notFoundContent={<SelectEmptyState />}
        onChange={handleChange}
        onBlur={() => {
          handleBlur();
        }}
        mode={mode}
        filterOption={handleFilter}
        options={options}
        getPopupContainer={() => renderRef.current}
        onInputKeyDown={handleKeyEvent}
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        onFocus={() => handlePreFetch()}
        dropdownRender={(menu) => (
          <DropdownActions handleOkClick={() => setOpen(false)}>
            {menu}
          </DropdownActions>
        )}
      />
      <ExtraSelectFilter
        columnItem={columnItem}
        overviewType={overviewType}
        isOpen={isOpen}
      />
    </div>
  );
};

export default ApiFilterableItem;

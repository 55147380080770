import { useCallback, useEffect, useMemo } from "react";
import { OverviewType, useAppDispatch, useAppSelector } from "../../app";
import {
  buildValidFilterArrayForOverview,
  getValidStatusesForOverviewType,
} from "../utils/helperFunctions";
import useCleanedFilter from "./useCleanedFilter";
import { setFilterString } from "../../common/dataRefining/store/dataRefiningSlice";
import { setOverviewType } from "../store/documentSetSlice";

const useSetFilterStringOnInit = (overviewType: OverviewType) => {
  const { filterString } = useCleanedFilter();
  const dispatch = useAppDispatch();

  const { overviewFilterArray, archiveFilterArray, shouldInitFilterString } =
    useAppSelector((state) => state.dataRefiningReducer);

  const filterArray = useMemo(() => {
    if (!overviewType) {
      return [];
    }

    if (overviewType === OverviewType.Archived) {
      return archiveFilterArray;
    }

    return overviewFilterArray;
  }, [overviewFilterArray, archiveFilterArray, overviewType]);

  const updateFilterString = useCallback(
    (overviewType: OverviewType | null) => {
      if (!overviewType) {
        return;
      }

      const validStatuses = getValidStatusesForOverviewType(overviewType);

      const filterString = buildValidFilterArrayForOverview(
        filterArray,
        validStatuses
      ).join(",");

      dispatch(setFilterString(filterString));
      dispatch(setOverviewType(overviewType));
    },
    [dispatch, filterArray]
  );

  // Upon page load, the filter array is initialized, but the filter string remains unset as the overview type is yet to be determined so that's why we set it here
  useEffect(() => {
    if (
      shouldInitFilterString &&
      filterArray.length &&
      !filterString &&
      overviewType
    ) {
      updateFilterString(overviewType);
    }
  }, [
    filterArray,
    overviewType,
    filterString,
    updateFilterString,
    shouldInitFilterString,
  ]);
};

export default useSetFilterStringOnInit;

import { useCallback, useMemo } from "react";
import { OverviewType, useAppSelector } from "../../app";
import { DEFAULT_ORDER_COLUMNS, REQUIRED_COLUMNS } from "../constants";

const useOverviewMapColumns = () => {
  const { overviewType } = useAppSelector((state) => state.documentSetsReducer);
  const { overviewMap } = useAppSelector((state) => state.appReducer);
  const { archiveOrderColumns, overviewOrderColumns } = useAppSelector(
    (state) => state.userReducer.userSettings
  );

  const overviewMapKeys = overviewMap.map((o) => o.key);

  const defaultSort = useMemo(() => {
    const defaultOrderMapKeys = DEFAULT_ORDER_COLUMNS.map((doc) => doc.key);
    return [...overviewMap].sort((a, b) => {
      const indexA = defaultOrderMapKeys.indexOf(a.key);
      const indexB = defaultOrderMapKeys.indexOf(b.key);

      return indexA - indexB;
    });
  }, [overviewMap]);

  const overviewOrderedMap = useCallback(
    (filter = true) => {
      if (overviewOrderColumns?.length) {
        const overviewOrderColumnsKeys = overviewOrderColumns
          .filter((ooc) => {
            if (filter) {
              return ooc.show;
            }

            return true;
          })
          .filter((ooc) => overviewMapKeys.includes(ooc.key))
          .map((ooc) => ooc.key);
        return [...overviewMap]
          .filter(
            (item) =>
              !item.archiveOnly && overviewOrderColumnsKeys.includes(item.key)
          )
          .sort((a, b) => {
            const indexA = overviewOrderColumnsKeys.indexOf(a.key);
            const indexB = overviewOrderColumnsKeys.indexOf(b.key);

            return indexA - indexB;
          });
      }

      return defaultSort;
    },
    [overviewOrderColumns, defaultSort, overviewMap, overviewMapKeys]
  );

  const archivedOrderedMap = useCallback(
    (filter = true) => {
      if (archiveOrderColumns?.length) {
        const archiveOrderColumnKeys = archiveOrderColumns
          .filter((ooc) => {
            if (filter) {
              return ooc.show;
            }

            return true;
          })
          .filter((ooc) => overviewMapKeys.includes(ooc.key))
          .map((ooc) => ooc.key);
        return [...overviewMap]
          .filter((item) => archiveOrderColumnKeys.includes(item.key))
          .sort((a, b) => {
            const indexA = archiveOrderColumnKeys.indexOf(a.key);
            const indexB = archiveOrderColumnKeys.indexOf(b.key);

            return indexA - indexB;
          });
      }

      return defaultSort;
    },
    [archiveOrderColumns, defaultSort, overviewMap, overviewMapKeys]
  );

  const tableItems = useMemo(() => {
    if (overviewType === OverviewType.Archived) {
      return [REQUIRED_COLUMNS.order, ...archivedOrderedMap()];
    }

    return [
      REQUIRED_COLUMNS.bulkDelete,
      REQUIRED_COLUMNS.order,
      ...overviewOrderedMap(),
      REQUIRED_COLUMNS.remove,
    ];
  }, [archivedOrderedMap, overviewOrderedMap, overviewType]);

  const orderItems = useMemo(() => {
    if (overviewType === OverviewType.Archived) {
      return archivedOrderedMap(false);
    }

    return overviewOrderedMap(false);
  }, [archivedOrderedMap, overviewOrderedMap, overviewType]);

  return { tableItems, orderItems };
};

export default useOverviewMapColumns;

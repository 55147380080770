import dayjs, { Dayjs } from "dayjs";
import { getLocaleByLanguage } from "./locale";
import { VALID_DATE_FORMATS } from "./formats/dateFormats";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SupportedLanguage } from "../../app";

dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
export const isValidDate = (
  valueTocCheck: string,
  language: SupportedLanguage,
  dateFormatKey: string = "eu"
): boolean => {
  return parseDateByLanguage(valueTocCheck, language, dateFormatKey).isValid();
};

export const isFutureDate = (
  valueTocCheck: string,
  language: SupportedLanguage,
  dateFormatKey: string = "eu"
): boolean => {
  if (!isValidDate(valueTocCheck, language)) {
    return false;
  }

  return parseDateByLanguage(valueTocCheck, language, dateFormatKey).isAfter(
    dayjs()
  );
};

export const parseDateByLanguage = (
  input: string,
  language: SupportedLanguage,
  dateFormatKey: string = "eu"
): Dayjs => {
  let parsed = dayjs(
    input.toLowerCase(),
    VALID_DATE_FORMATS[dateFormatKey],
    true
  );

  if (parsed.isValid()) {
    parsed = convertDayjsDefaultYearToCurrentYear(input, parsed);
    return parsed;
  }

  parsed = dayjs(
    input.toLowerCase().replace(/[,\s]/g, ""),
    VALID_DATE_FORMATS[dateFormatKey],
    true
  );

  if (parsed.isValid()) {
    parsed = convertDayjsDefaultYearToCurrentYear(input, parsed);
    return parsed;
  }

  parsed = dayjs(input.toLowerCase());
  return convertDayjsDefaultYearToCurrentYear(input, parsed);
};

const convertDayjsDefaultYearToCurrentYear = (input: string, date: Dayjs) => {
  if (date.isValid() && !input.includes("2001") && date.year() === 2001) {
    return date.year(dayjs().year());
  }

  return date;
};

const convertMonthsAndAbbreviationsToLowerCase = (localeLanguage: string) => {
  const months = dayjs.months();
  const abbreviations = dayjs.monthsShort();
  dayjs.updateLocale(localeLanguage, {
    months: months.map((month) => month.toLowerCase()),
    monthsShort: abbreviations.map((abbreviation) =>
      abbreviation.toLowerCase()
    ),
  });
};

export const setDayJsLocale = (language: string) => {
  const localeLanguage = getLocaleByLanguage(language);
  import(`dayjs/locale/${language}.js`).then(() => {
    dayjs.locale(localeLanguage);
    convertMonthsAndAbbreviationsToLowerCase(localeLanguage);
  });
};

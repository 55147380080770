import React, { FC, useMemo } from "react";
import {
  HOTKEY_WIDTH,
  PERCENTAGE_WIDTH,
  REMOVE_ICON_WIDTH,
} from "../../../../constants";

type Props = {
  value: string;
  normalizedValue: string | undefined;
  cellWidth: number;
  cellClasses: string;
};

const EntityTextVisual: FC<Props> = ({
  value,
  normalizedValue,
  cellWidth,
  cellClasses,
}) => {
  const textValue = useMemo(
    () => normalizedValue ?? value,
    [value, normalizedValue]
  );

  const textWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH - REMOVE_ICON_WIDTH - PERCENTAGE_WIDTH;
  }, [cellWidth]);

  return (
    <div
      className={`entity-visual__container`}
      style={{ width: `${textWidth}px` }}
    >
      <div className={`entity-visual__text ${cellClasses}`}>{textValue}</div>
    </div>
  );
};

export default EntityTextVisual;

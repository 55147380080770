import { useMemo } from "react";
import { OverviewType, useAppSelector } from "../../../app";
import { useTranslation } from "react-i18next";

const useOverviewHeader = () => {
  const { overviewType } = useAppSelector((state) => state.documentSetsReducer);

  const { t } = useTranslation("header");

  const headerTitle = useMemo(() => {
    switch (overviewType) {
      case OverviewType.Home:
        return t("overviewHeader.home");
      case OverviewType.Archived:
        return t("overviewHeader.archived");
      case OverviewType.Remove:
        return t("overviewHeader.remove");
      case OverviewType.Error:
        return t("overviewHeader.error");
      case OverviewType.AnnotationTodo:
        return t("overviewHeader.home");
      case OverviewType.AnnotationDone:
        return t("overviewHeader.archived");
    }
  }, [overviewType, t]);

  return {
    headerTitle,
    overviewType,
  };
};

export default useOverviewHeader;

import { useCallback, useEffect, useRef, useState } from "react";
import { VALID_RETURN_PATHS } from "../pages/detailView/Detail";
import { useAppDispatch, useAppSelector } from "./hooks";
import { useLocation, useParams } from "react-router-dom";
import { resetDocumentSetsReducer } from "../../documentSet/store/documentSetSlice";
import { resetDocumentReducer } from "../../document/store/documentSlice";
import useDateLocaleSwitcher from "../../common/utilities/hooks/useDateLocaleSwitcher";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";
import useDetailPageRouting from "./useDetailPageRouting";
import useFetchEmailTemplates from "../../emailTemplates/hooks/useFetchEmailTemplates";
import { determineNonErrorStatus, Status } from "../../common/status/status";
import { getPathToRouteByStatus } from "../../documentSet/utils/dataFiltering";

type RouteParams = {
  documentId: string;
};

const useDetailPage = () => {
  useDateLocaleSwitcher();

  const isRoutingBack = useRef(false);

  const dispatch = useAppDispatch();

  const { activeEntity, hasBeenEdited, detailBackButtonTrigger } =
    useAppSelector((rootState) => rootState.appReducer);

  const detailContainer: any = useRef();

  const [inTransition, setInTransition] = useState<boolean>(false);
  const [renderConfirm, setRenderConfirm] = useState<boolean>(false);

  const { navigateWithState } = useNavigateWithState();
  const { state } = useLocation();
  const { documentId } = useParams<RouteParams>();

  const [onInit, setOnInit] = useState(true);

  const [oldActiveDocumentSetId, setOldActiveDocumentSetId] =
    useState<string>();

  useFetchEmailTemplates();

  const {
    disabled,
    newStatus,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
    user,
  } = useDetailPageRouting();

  const isAnnotatingMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  // Set user (and status if needed) on init
  useEffect(() => {
    if (!activeDocumentSet) {
      return;
    }

    if (isRoutingBack.current) {
      return;
    }

    if (VALID_RETURN_PATHS.includes(state?.previousPath)) {
      return;
    }

    if (
      activeDocumentSet?.assignee &&
      activeDocumentSet?.assignee?.id !== user?.id
    ) {
      setOldActiveDocumentSetId(activeDocumentSet.id);
      return;
    }

    if (activeDocumentSet.id === oldActiveDocumentSetId) {
      return;
    }

    if (!disabled) {
      const newStatus = determineNonErrorStatus(
        activeDocumentSet.status,
        isAnnotatingMode ? Status.AnnotationInProgress : Status.InProgress
      );

      updateAssigneeAndStatusWithExceptionHandling(
        activeDocumentSet,
        newStatus,
        user!
      );
      setOldActiveDocumentSetId(activeDocumentSet.id);
    }
  }, [
    activeDocumentSet,
    updateAssigneeAndStatusWithExceptionHandling,
    user,
    state,
    disabled,
    oldActiveDocumentSetId,
    isAnnotatingMode,
  ]);

  const focusDetailContainer = useCallback(() => {
    const { current } = detailContainer;
    if (current) current.focus();
  }, []);

  useEffect(() => {
    if (!inTransition) setTimeout(focusDetailContainer, 1000);
  }, [focusDetailContainer, inTransition]);

  const routeToOverview = useCallback(async () => {
    isRoutingBack.current = true;

    if (!disabled) {
      await updateAssigneeAndStatusWithExceptionHandling(
        activeDocumentSet!,
        newStatus
      );
    }

    setInTransition(true);

    let pathToRoute;
    if (state?.previousPath.includes("/detail/") && activeDocumentSet?.status) {
      pathToRoute = getPathToRouteByStatus(
        activeDocumentSet.status,
        isAnnotatingMode
      );
    } else {
      pathToRoute = VALID_RETURN_PATHS.includes(state?.previousPath)
        ? state?.previousPath
        : VALID_RETURN_PATHS[0];
    }

    dispatch(resetDocumentSetsReducer());
    dispatch(resetDocumentReducer());

    setTimeout(() => setInTransition(false), 500);
    navigateWithState(pathToRoute);
  }, [
    dispatch,
    state,
    disabled,
    navigateWithState,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
    newStatus,
    isAnnotatingMode,
  ]);

  const handleRouteToOverview = useCallback(async () => {
    if (!disabled && (hasBeenEdited || activeEntity !== undefined)) {
      setRenderConfirm(true);
    } else {
      await routeToOverview();
    }
  }, [activeEntity, hasBeenEdited, routeToOverview, disabled]);

  useEffect(() => {
    if (onInit) {
      setOnInit(false);
      return;
    }

    handleRouteToOverview();
    // The useEffect needs only to be triggered when we push the back button in the header (detailBackButtonTrigger)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailBackButtonTrigger]);

  return {
    routeToOverview,
    detailContainer,
    renderConfirm,
    setRenderConfirm,
    disabled,
    documentId,
  };
};

export default useDetailPage;

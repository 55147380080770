import { useCallback, useEffect, useMemo, useState } from "react";
import { OptionType } from "../../metadata/interfaces/classifier";
import { SearchQuery } from "../interfaces/api";
import { useAppSelector } from "../../app";
import { runSearch } from "../utils/requests";
import { Input } from "../interfaces/documentSet";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

const useFilteringSearch = (
  query: SearchQuery,
  inputs?: Array<Input>,
  values?: Array<string>
) => {
  const user = useAppSelector((state) => state.userReducer.user);

  const [options, setOptions] = useState<Array<OptionType>>([]);

  const {
    i18n: { language },
  } = useTranslation();

  const handleBlur = useCallback(() => {
    setOptions([]);
  }, []);

  const inputIds = useMemo(() => {
    return inputs?.map((item) => item.id);
  }, [inputs]);

  const handleSearch = useCallback(
    async (searchValue: string, values?: Array<string>) => {
      try {
        const data = await runSearch(
          searchValue,
          query,
          language,
          user,
          inputIds,
          values
        );
        setOptions(data);
      } catch (e) {
        console.log(e);
        setOptions([]);
      }
    },
    [query, language, user, inputIds]
  );

  const debouncedHandleSearch = useMemo(
    () => debounce(handleSearch, 500),
    [handleSearch]
  );

  useEffect(() => {
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, [debouncedHandleSearch]);

  const handleFilter = useCallback(
    (input: string, option: any) => {
      if (!query?.prefetch) {
        return true;
      }

      return option.label.toLowerCase().includes(input.toLowerCase());
    },
    [query]
  );

  const handlePreFetch = useCallback(() => {
    if (!query?.prefetch) {
      return;
    }

    handleSearch("", values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, handleSearch]);

  return {
    options,
    handleSearch,
    debouncedHandleSearch,
    handlePreFetch,
    handleFilter,
    handleBlur,
  };
};

export default useFilteringSearch;

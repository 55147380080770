import React, { FC, ReactElement } from "react";
import { Button, Divider } from "antd";
import "./DropDownActions.scss";

type Props = {
  children?: ReactElement;
  handleOkClick: () => void;
};

const DropdownActions: FC<Props> = ({ children, handleOkClick }) => {
  return (
    <>
      {children}
      {children ? <Divider className="dropdown-actions__divider" /> : null}
      <div className="dropdown-actions__buttons">
        <Button
          type="primary"
          onClick={handleOkClick}
          onMouseDown={(e) => e.preventDefault()}
          size="small"
        >
          OK
        </Button>
      </div>
    </>
  );
};

export default DropdownActions;

import React, { FC } from "react";
import "./WelcomePage.scss";
import TwoSidePage from "../twoSidePage/TwoSidePage";
import ButtonType from "../../../common/button/ButtonTypes";
import Button from "../../../common/button/Button";
import { usePersonalPage } from "../../index";
import ValueInput from "./ValueInput";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

const WelcomePage: FC = () => {
  const { handleBlur, state, updateProfileAndFetchUserInputIds } =
    usePersonalPage(false);

  const { t } = useTranslation("welcomePage");

  return (
    <TwoSidePage>
      <div className="welcome-page__container">
        <h1>{t("title")}</h1>
        <div className="welcome-page__form">
          <p>{t("description")}</p>
          <ValueInput
            value={state.firstName}
            name="firstName"
            label={t("firstNameLabel")}
            handleBlur={handleBlur}
          />
          <ValueInput
            value={state.lastName}
            name="lastName"
            label={t("lastNameLabel")}
            handleBlur={handleBlur}
          />
          <LanguageSelector label={t("languageLabel")} />
          <Button
            className="sign-in-button"
            handleClick={updateProfileAndFetchUserInputIds}
            buttonType={ButtonType.Primary}
            text={t("saveButton")}
            icon={<i className="bi bi-check2" />}
          />
        </div>
      </div>
    </TwoSidePage>
  );
};

export default WelcomePage;

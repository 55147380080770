import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const selectCategorizationConfigMaps = (state: RootState) =>
  state.configMapReducer.configMap?.categorizationConfigMaps;

const selectCategorizationAnnotations = (state: RootState) =>
  state.metadataReducer.categorizationState.documentCategoryAnnotations;

const selectHasMissingCategorizations = createSelector(
  selectCategorizationConfigMaps,
  selectCategorizationAnnotations,
  (categorizationConfigMaps, categorizationAnnotations): boolean => {
    if (!categorizationConfigMaps) {
      return false;
    }

    return categorizationConfigMaps
      .filter((categorizationConfigMap) => categorizationConfigMap.required)
      .map((categorizationConfigMap): boolean => {
        const found = categorizationAnnotations.find(
          (ca) =>
            ca.categorizationId === categorizationConfigMap.categorization.id
        );

        return !!found;
      })
      .includes(false);
  }
);

export default selectHasMissingCategorizations;

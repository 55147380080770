import { Translation } from "../../configMap";

export enum ClassifierType {
  date = "DATE",
  currency = "CURRENCY",
  custom = "CUSTOM",
}

export interface OptionType {
  key: string;
  value: string;
  label: string;
  visibleValue?: string;
}

export interface IClassifierSearch {
  prefetch: boolean;
  endpoint: string;
  limit?: number;
}

export interface IClassifier {
  id: string;
  index: number;
  label: Translation;
  color: string;
  updatesEntities: Array<string>;
  classifierType: ClassifierType;
  options?: Array<OptionType>;
  search?: IClassifierSearch;
}

export interface IClassifierMap {
  id: string;
  classifierEntities: Array<IClassifier>;
}

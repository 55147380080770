import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DrawerType } from "../interfaces/drawer";

interface annotationSideControlsSliceState {
  isDrawerOpen: boolean;
  drawerType: DrawerType | undefined;
}

const initialState: annotationSideControlsSliceState = {
  isDrawerOpen: false,
  drawerType: undefined,
};

export const annotationSideControlsSlice = createSlice({
  name: "annotationSideControls",
  initialState,
  reducers: {
    setDrawerTypeAndOpen: (state, action: PayloadAction<DrawerType>) => {
      state.drawerType = action.payload;
      state.isDrawerOpen = true;
    },
    resetAndCloseDrawer: (state) => {
      state.drawerType = undefined;
      state.isDrawerOpen = false;
    },
  },
});

export const { setDrawerTypeAndOpen, resetAndCloseDrawer } =
  annotationSideControlsSlice.actions;

export default annotationSideControlsSlice.reducer;

import { createSelector } from "@reduxjs/toolkit";
import selectUser from "./userSelector";

const selectUserInputIds = createSelector(selectUser, (user) => {
  if (!user) {
    return [];
  }

  return user.inputIds;
});

export default selectUserInputIds;

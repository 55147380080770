import React, { FC, useCallback } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { OrderColumnItem } from "../../../interfaces/overview";
import StrictModeDroppable from "./StrictModeDroppable";

type Props = {
  columns: Array<OrderColumnItem>;
  setColumns: (item: OrderColumnItem[]) => void;
  updateOrderColumns: (columns: Array<OrderColumnItem>) => void;
};

const DragDropColumns: FC<Props> = ({
  columns,
  setColumns,
  updateOrderColumns,
}) => {
  const handleOnDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(columns);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setColumns(items);
      updateOrderColumns(items);
    },
    [columns, setColumns, updateOrderColumns]
  );

  const handleShowClick = useCallback(
    (key: string, show: boolean) => {
      const newColumns = [...columns].map((c) => {
        if (c.key === key) {
          return {
            ...c,
            show,
          };
        }

        return c;
      });
      setColumns(newColumns);
      updateOrderColumns(newColumns);
    },
    [setColumns, columns, updateOrderColumns]
  );

  return (
    <div className="order-columns-popover__item">
      {/*@ts-ignore*/}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StrictModeDroppable droppableId="card__container">
          {(provided: any) => (
            <div
              className="card__container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {columns.map(
                ({ key, title, show }: OrderColumnItem, index: number) => (
                  // @ts-ignore
                  <Draggable key={key} draggableId={key} index={index}>
                    {(provided: any) => (
                      <div
                        className="card"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className="card-children-group__wrapper">
                          <div className="card__icon">
                            <i className="bi bi-arrows-move" />
                          </div>
                          <div className={show ? "" : "text_strike-through"}>
                            {title}
                          </div>
                        </div>
                        <div
                          className="card-hide__button"
                          onClick={() => handleShowClick(key, !show)}
                        >
                          <i
                            className={`bi ${show ? "bi-eye" : "bi-eye-slash"}`}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

export default DragDropColumns;

import React, { FC, useMemo } from "react";
import { List, Tooltip } from "antd";
import { GroupBlockEntityType } from "../../../../configMap";
import "./EntityCell.scss";
import EntityHotKey from "./entityAnnotation/EntityHotkey";
import useEntityCell from "../../../hooks/useEntityCell";
import CellRow from "./CellRow";

type EntityProps = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  disabled: boolean;
  requiresAttention: boolean;
  cellWidth: number;
};

const EntityCell: FC<EntityProps> = ({
  groupBlockEntityType,
  groupBlockIndex,
  disabled,
  requiresAttention,
  cellWidth,
}) => {
  const {
    cellClasses,
    textWidthValue,
    textWidth,
    validationMessage,
    validationClasses,
    hasNormalizedValue,
    value,
    normalizedValue,
    annotationsForEntity,
    handleEntityClick,
    removeAnnotation,
    isAnnotating,
    entityAnnotations,
    activeDocument,
    listItemClassNames,
    annotationForEntity,
    isEditing,
    editingKey,
    isFirstAnnotation,
    isEditable,
    modelScore,
  } = useEntityCell(
    groupBlockEntityType,
    groupBlockIndex,
    disabled,
    requiresAttention,
    cellWidth
  );

  const tooltipValue = useMemo(() => {
    const showTooltip = cellClasses || textWidthValue > textWidth;

    if (validationMessage || hasNormalizedValue || showTooltip) {
      return (
        <>
          {validationMessage ? (
            <div className={`validation-message ${validationClasses}`}>
              <strong>{validationMessage}</strong>
            </div>
          ) : null}
          <div>
            <strong>Labeled value: </strong>
            {`${!value ? "/" : value}`}
          </div>
          {normalizedValue ? (
            <div>
              <strong>Normalized value: </strong>
              {normalizedValue}
            </div>
          ) : null}
        </>
      );
    }
    return "";
  }, [
    value,
    normalizedValue,
    hasNormalizedValue,
    cellClasses,
    validationMessage,
    validationClasses,
    textWidth,
    textWidthValue,
  ]);

  return (
    <div
      className={`multiple-groupblock list-item-container ${cellClasses} ${
        disabled ? "disabled" : ""
      }`.trim()}
    >
      {annotationsForEntity.length <= 1 ? (
        <List.Item className={listItemClassNames} onClick={handleEntityClick}>
          <List.Item.Meta
            className="list-item-meta"
            avatar={
              <EntityHotKey
                groupBlockEntityType={groupBlockEntityType}
                groupBlockIndex={groupBlockIndex}
                disabled={disabled}
              />
            }
            description={
              <Tooltip placement={"top"} title={tooltipValue}>
                <div onClick={() => isEditable && handleEntityClick()}>
                  <CellRow
                    activeDocumentId={activeDocument?.id}
                    annotationForEntity={annotationForEntity}
                    isEditing={isEditing}
                    editingKey={editingKey}
                    isAnnotating={isAnnotating}
                    removeAnnotation={removeAnnotation}
                    isAnnotationLoading={annotationForEntity.isLoading}
                    groupBlockIndex={groupBlockIndex}
                    groupBlockEntityType={groupBlockEntityType}
                    disabled={disabled}
                    requiresAttention={requiresAttention}
                    isFirstAnnotation={isFirstAnnotation}
                    isEditable={isEditable}
                    cellWidth={cellWidth}
                    modelScore={modelScore}
                    cellClasses={cellClasses}
                  />
                </div>
              </Tooltip>
            }
          />
        </List.Item>
      ) : (
        <div className="multiple-annotations-group-block">
          <List.Item>
            <List.Item.Meta
              className="list-item-meta"
              avatar={
                <EntityHotKey
                  groupBlockEntityType={groupBlockEntityType}
                  groupBlockIndex={groupBlockIndex}
                  disabled={disabled}
                />
              }
              description={
                <Tooltip title={tooltipValue}>
                  {(entityAnnotations || []).map((entityAnnotation) => {
                    const isFirst = entityAnnotation.index === 0;
                    const isEditable =
                      isFirst || annotationsForEntity.length === 0;
                    const classes = isFirst ? cellClasses : "";

                    return (
                      <div
                        key={entityAnnotation.id}
                        onClick={() => isEditable && handleEntityClick()}
                      >
                        <CellRow
                          activeDocumentId={activeDocument?.id}
                          annotationForEntity={entityAnnotation}
                          isEditing={isEditing}
                          editingKey={editingKey}
                          isAnnotating={isAnnotating}
                          removeAnnotation={removeAnnotation}
                          isAnnotationLoading={annotationForEntity.isLoading}
                          groupBlockIndex={groupBlockIndex}
                          groupBlockEntityType={groupBlockEntityType}
                          disabled={disabled}
                          requiresAttention={requiresAttention}
                          isFirstAnnotation={isFirst}
                          isEditable={isEditable}
                          cellWidth={cellWidth}
                          modelScore={modelScore}
                          cellClasses={classes}
                        />
                      </div>
                    );
                  })}
                </Tooltip>
              }
            />
          </List.Item>
        </div>
      )}
    </div>
  );
};

export default EntityCell;

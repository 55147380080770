import { hotjar } from "react-hotjar";
import { getVariableFromEnv } from "./common/utilities/env";

export let hotjarInitialized = false;

const environment = getVariableFromEnv("ENVIRONMENT");
const hotjarId = getVariableFromEnv("HOTJAR_ID");

const SNIPPET_VERSION = 6;

if (hotjarId && environment.toLowerCase() === "production") {
  hotjar.initialize(parseInt(hotjarId), SNIPPET_VERSION);
  hotjarInitialized = true;
}

import React, { FC } from "react";
import StatusSelector from "./StatusSelector/StatusSelector";
import useOverviewHeader from "../../hooks/useOverviewHeader";
import { Layout } from "antd";

const OverviewHeader: FC = () => {
  const { headerTitle, overviewType } = useOverviewHeader();

  return (
    <Layout.Header className="header__container">
      {overviewType && (
        <>
          <div className="header-title">{headerTitle}</div>
          <StatusSelector overviewType={overviewType} />
        </>
      )}
    </Layout.Header>
  );
};

export default OverviewHeader;

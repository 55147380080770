import React, { FC } from "react";
import { Button, Tooltip } from "antd";
import useEmailTemplates, {
  MAX_EMAIL_TEMPLATES,
} from "../../hooks/useEmailTemplates";
import { useTranslation } from "react-i18next";

const AddEmailTemplateButton: FC = () => {
  const { t } = useTranslation("emailTemplates");

  const { isMaxEmailTemplateReached, openModalAndSelectEmailTemplate } =
    useEmailTemplates();

  return (
    <Tooltip
      title={
        isMaxEmailTemplateReached
          ? t("maxLimitReached").replace(
              "$MAX_LIMIT",
              MAX_EMAIL_TEMPLATES.toString()
            )
          : ""
      }
    >
      <Button
        className="email-templates__button"
        disabled={isMaxEmailTemplateReached}
        onClick={() => openModalAndSelectEmailTemplate()}
        ghost
      >
        {t("button")}
      </Button>
    </Tooltip>
  );
};

export default AddEmailTemplateButton;

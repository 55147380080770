import React, { FC } from "react";
import { Select } from "antd";
import { ColumnItem } from "../../../interfaces/overview";
import useExtraSelectFilter from "../../../hooks/useExtraSelectFilter";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import { OverviewType } from "../../../../app";
import DropdownActions from "../../../../common/tableHeaderFilter/dropdownActions/DropdownActions";

type Props = {
  columnItem: ColumnItem;
  overviewType: OverviewType;
  isOpen: boolean;
};

const ExtraSelectFilter: FC<Props> = ({ columnItem, overviewType, isOpen }) => {
  const {
    showExtraSelectFilter,
    label,
    placeholder,
    options,
    selectValue,
    handleChange,
    open,
    setOpen,
  } = useExtraSelectFilter(columnItem, overviewType, isOpen);

  if (!showExtraSelectFilter) {
    return null;
  }

  return (
    <div className="extra-select-filter-container">
      {label}
      <Select
        value={selectValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onInputKeyDown={handleKeyEvent}
        allowClear
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        dropdownRender={(menu) => (
          <DropdownActions handleOkClick={() => setOpen(false)}>
            {menu}
          </DropdownActions>
        )}
      />
    </div>
  );
};

export default ExtraSelectFilter;

import React, { FC } from "react";
import { Annotation } from "../../../interfaces/annotation";
import { useAppSelector } from "../../../../app";
import AnnotationItemConnection from "./AnnotationItemConnection";
import AnnotationItemMenu from "./AnnotationItemMenu";
import tokensForAnnotationsSelector from "../../../../annotation/selectors/tokensForAnnotationsSelector";
import useScrollToAnnotation from "../../../hooks/useScrollToAnnotation";

type Props = {
  page: number;
  annotation: Annotation;
};

const AnnotationItem: FC<Props> = ({ page, annotation }) => {
  const scrollClassName = useScrollToAnnotation(annotation);

  const isTempAnnotation = annotation.tempAnnotation || false;
  const color = annotation.entity.color;
  const items = useAppSelector((state) =>
    tokensForAnnotationsSelector(state, {
      pageNumber: page,
      tokenIndices: annotation.pageTokenIndices,
      isTempAnnotation,
      color,
      entityId: annotation.entity.id,
      index: annotation.index!,
    })
  );

  if (!items?.length) {
    return null;
  }

  return (
    <div className="annotation-layer__item-container">
      {items.map((item, index) => {
        const { id, style, coords } = item;

        return (
          <div key={id} className="annotation-layer__item-container-inner">
            <mark
              className={`annotation-layer__item ${scrollClassName}`.trim()}
              style={style}
              id={`${annotation.id}-${annotation.index}-${index}`}
            />
            <AnnotationItemMenu
              id={annotation.id}
              entityId={annotation.entity.id}
              index={annotation.index || 1}
              style={{
                top: coords.top - 33,
                left: coords.left + coords.width - 5,
              }}
            />
          </div>
        );
      })}
      <AnnotationItemConnection
        vertices={[...items]
          .map((i) => i.coords)
          .sort((a, b) => a.left - b.left)}
        color={annotation.entity.color}
      />
    </div>
  );
};

export default AnnotationItem;

import { useAppDispatch, useAppSelector } from "../../app";
import { setTourPageAndStart, stopTour } from "../store/TourSlice";
import { useCallback, useMemo } from "react";
import useUpdateShowTour from "./useUpdateShowTour";
import { useTranslation } from "react-i18next";
import { TourProps } from "antd";
import React from "react";
import ReactHtmlParser from "html-react-parser";

export enum TourPage {
  OVERVIEW,
  DETAIL,
}

const useTour = () => {
  const dispatch = useAppDispatch();
  const { updateShowTourUserSettings } = useUpdateShowTour();

  const { tourPage, run } = useAppSelector((state) => state.tourReducer);

  const { t } = useTranslation("tour");

  const stepsOverview: TourProps["steps"] = useMemo(
    () => [
      {
        title: "",
        description: <>{ReactHtmlParser(t("overview.step1"))}</>,
        placement: "center",
        target: () =>
          document.getElementById("tour-overview-step-1") as HTMLElement,
        nextButtonProps: {
          children: t("nextButton"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
      {
        title: "",
        description: ReactHtmlParser(t("overview.step2")),
        placement: "right",
        target: () =>
          document.getElementById("tour-overview-step-2") as HTMLElement,
        nextButtonProps: {
          children: t("nextButton"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
      {
        title: "",
        description: ReactHtmlParser(t("overview.step3")),
        placement: "rightTop",
        target: () => document.getElementById("tour-overview-step-3") as any,
        nextButtonProps: {
          children: t("endTour"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
    ],
    [t]
  );

  const stepsDetail: TourProps["steps"] = useMemo(
    () => [
      {
        title: "",
        description: ReactHtmlParser(t("detail.step1")),
        placement: "left",
        target: () =>
          document.getElementById("tour-detail-step-1") as HTMLElement,
        nextButtonProps: {
          children: t("nextButton"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
      {
        title: "",
        description: ReactHtmlParser(t("detail.step2")),
        placement: "leftTop",
        target: () =>
          document.getElementById("tour-detail-step-2") as HTMLElement,
        nextButtonProps: {
          children: t("nextButton"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
      {
        title: "",
        description: ReactHtmlParser(t("detail.step3")),
        placement: "center",
        target: () => document.getElementById("tour-detail-step-3") as any,
        nextButtonProps: {
          children: t("endTour"),
        },
        prevButtonProps: {
          children: t("backButton"),
        },
      },
    ],
    [t]
  );

  const steps = useMemo((): TourProps["steps"] => {
    if (tourPage === TourPage.OVERVIEW) {
      return stepsOverview;
    }

    return stepsDetail;
  }, [stepsDetail, stepsOverview, tourPage]);

  const startTour = useCallback(
    (page: TourPage) => {
      dispatch(setTourPageAndStart(page));
    },
    [dispatch]
  );

  const stopTourRunAndUpdateUserSettings = useCallback(() => {
    updateShowTourUserSettings();
    dispatch(stopTour());
  }, [dispatch, updateShowTourUserSettings]);

  return {
    startTour,
    stopTourRunAndUpdateUserSettings,
    steps,
    run,
    tourPage,
  };
};

export default useTour;

import { Status } from "../common/status/status";

export const SIDE_CONTROLS_HEADER = 65;
export const SIDE_CONTROLS_WIDTH = 480;

export const SHOW_ISSUES_STATUS_LIST = [
  Status.ToDo,
  Status.InProgress,
  Status.OnHold,
  Status.ExportFailed,
  Status.Error,
];

import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import { defaultSettings } from "../../user/utils/utils";
import { updateUserSettings } from "../../user/store/userSlice";
import { useTranslation } from "react-i18next";
import { TourPage } from "./useTour";
import { useUpdateUserMutation } from "../../user/store/userApi";

const showTourHelper = (
  tourPage: TourPage | undefined,
  targetTourPage: TourPage,
  userSetting: boolean
) => {
  if (tourPage === targetTourPage) {
    return false;
  }

  return userSetting;
};

const useUpdateShowTour = () => {
  const dispatch = useAppDispatch();
  const { user, userSettings } = useAppSelector((state) => state.userReducer);

  const tourState = useAppSelector((state) => state.tourReducer);

  const {
    i18n: { language },
  } = useTranslation("personalPage", {
    keyPrefix: "userSettings",
  });

  const [updateUser] = useUpdateUserMutation();

  const updateShowTourUserSettings = useCallback(async () => {
    if (!user) {
      return;
    }

    const tourPage = tourState?.tourPage;

    const showOverviewTour = showTourHelper(
      tourPage,
      TourPage.OVERVIEW,
      userSettings.showOverviewTour
    );
    const showDetailTour = showTourHelper(
      tourPage,
      TourPage.DETAIL,
      userSettings.showDetailTour
    );

    dispatch(
      updateUserSettings({
        ...userSettings,
        showOverviewTour: showOverviewTour,
        showDetailTour: showDetailTour,
      })
    );

    try {
      const body = {
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        username: user?.username || "",
        email: user?.email?.toLowerCase() || "",
        company: user?.organization.name || "",
        avatar: user?.avatar || "",
        metadata: {
          ...userSettings,
          picture: user.avatar || null,
          overviewSorter: userSettings.saveSorter
            ? userSettings.overviewSorter
            : defaultSettings.overviewSorter,
          archiveSorter: userSettings.saveSorter
            ? userSettings.archiveSorter
            : defaultSettings.archiveSorter,
          overviewFilters: userSettings.saveFilters
            ? userSettings.overviewFilters
            : [],
          archiveFilters: userSettings.saveFilters
            ? userSettings.archiveFilters
            : [],
          showWelcome: false,
          language,
          showOverviewTour: showOverviewTour,
          showDetailTour: showDetailTour,
        },
      };

      await updateUser({ userId: user.id, data: body }).unwrap();
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, userSettings, user, language, tourState, updateUser]);

  return {
    updateShowTourUserSettings,
  };
};

export default useUpdateShowTour;

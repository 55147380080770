import { TextLayer, TextLayerItem } from "../interfaces/textLayer";
import { useCallback } from "react";

const useTextMap = (initialTextMap: Array<TextLayer>) => {
  return useCallback(
    (page: number): [Array<TextLayerItem>, boolean] | [undefined, boolean] => {
      if (initialTextMap?.length) {
        const found = initialTextMap.find((layer) => layer.page === page);
        if (!found) {
          return [undefined, true];
        }

        const shouldRender =
          found.shouldRender !== undefined ? found.shouldRender : true;
        return found ? [found.textMapItems, shouldRender] : [undefined, true];
      }
      return [undefined, true];
    },
    [initialTextMap]
  );
};

export default useTextMap;

import { useCallback, useEffect, useState } from "react";
import { RangePickerProps } from "antd/es/date-picker";
import { useAppDispatch } from "../../app";
import {
  setMetricsDatePeriod,
  updateMetricsDates,
} from "../../common/dataRefining/store/dataRefiningSlice";
import dayjs from "dayjs";
import { MetricsDatePeriod } from "../constants";
import { triggerRefreshMetrics } from "../store/analyticsSlice";

const useRangeDatePicker = (startDate: Date, endDate: Date) => {
  const [rangeDates, setRangeDates] = useState<RangePickerProps["value"]>();

  const dispatch = useAppDispatch();

  const onChange = useCallback(
    (values: RangePickerProps["value"]) => {
      if (values?.length && values[0] && values[1]) {
        const startDate = values[0].toDate();
        const endDate = values[1].toDate();
        dispatch(
          updateMetricsDates({
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          })
        );
        dispatch(setMetricsDatePeriod(MetricsDatePeriod.CUSTOM));
        dispatch(triggerRefreshMetrics());
      }

      setRangeDates(values);
    },
    [dispatch]
  );

  useEffect(() => {
    setRangeDates([dayjs(startDate), dayjs(endDate)]);
  }, [startDate, endDate]);

  return {
    onChange,
    rangeDates,
  };
};

export default useRangeDatePicker;

import React, { FC } from "react";
import { humanizeTime } from "../../../../utils/time";

type TooltipProps = {
  title: string;
  data: Array<any>;
  isTime: boolean;
  language: string;
  suffix?: string;
};

const ToolTip: FC<TooltipProps> = ({
  title,
  data,
  isTime,
  language,
  suffix,
}) => {
  if (!data?.length) {
    return null;
  }

  return (
    <>
      <div className="tooltip__title">{title}</div>
      <ul className="tooltip__items">
        {data?.map((d: any) => (
          <li className="tooltip__item" key={d.value}>
            <span
              className="tooltip__item-color"
              style={{ background: d.color }}
            />
            <span className="tooltip__item-name">{d.name}</span>:
            {isTime ? (
              <span className="tooltip__item-value">
                {humanizeTime(d.value, language)}
              </span>
            ) : (
              <span className="tooltip__item-value">
                {d.value}
                {suffix}
              </span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default ToolTip;

import React, { FC, MouseEvent, useCallback } from "react";
import { EmailTemplate } from "../../../interfaces/table";

type Props = {
  emailTemplate: EmailTemplate;
  setEmailTemplateToDelete: (value: EmailTemplate) => void;
};

const RemoveCell: FC<Props> = ({ emailTemplate, setEmailTemplateToDelete }) => {
  const handleRemoveClicked = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setEmailTemplateToDelete(emailTemplate);
    },
    [emailTemplate, setEmailTemplateToDelete]
  );

  return <i className="bi bi-trash" onClick={handleRemoveClicked} />;
};

export default RemoveCell;

import React, { FC, useCallback, useMemo } from "react";
import "./AnnotationHistoryContent.scss";
import { Alert, Timeline } from "antd";
import { useAnnotationHistoryContent } from "../../../../hooks/useAnnotationHistoryContent";
import { ActionType, HistoryItem } from "../../../../interfaces/drawer";

type Props = {
  items: HistoryItem[] | undefined;
};

const AnnotationHistoryContent: FC<Props> = ({ items }) => {
  const { getDate, getTitle, getEntityName, getIsError, t } =
    useAnnotationHistoryContent();

  const getIcon = useCallback((item: HistoryItem) => {
    if (item.actionType === ActionType.ADD) {
      return <i className="bi bi-plus-circle add-icon" />;
    } else if (item.actionType === ActionType.MODIFIED) {
      return <i className="bi bi-pencil-square modified-icon" />;
    } else if (item.actionType === ActionType.DELETE) {
      return <i className="bi bi-x-circle delete-icon" />;
    }
  }, []);

  const timeLineItems = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    return items.map((item) => {
      const isError = getIsError(item);
      const title = getTitle(item);
      const entityName = getEntityName(item);

      return {
        label: <div className="time-title">{getDate(item)}</div>,
        dot: getIcon(item),
        color: "red",
        children: (
          <>
            <label className="title">{title}</label>
            <label className="entity-group-block-name">{entityName}</label>
            <label className={`value ${isError ? "error" : ""}`}>
              {item.value}
            </label>
          </>
        ),
      };
    });
  }, [items, getTitle, getDate, getEntityName, getIsError, getIcon]);

  return (
    <div className="content-container">
      <div className="annotation-history-container">
        {timeLineItems.length ? (
          <Timeline mode="left" items={timeLineItems} />
        ) : (
          <Alert
            message={t("noAnnotationsAlert.message")}
            type="info"
            showIcon
          />
        )}
      </div>
    </div>
  );
};

export default AnnotationHistoryContent;

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import { Annotation } from "../../annotator/interfaces/annotation";
import selectAnnotationReducer from "./annotationReducerSelector";

type Props = {
  page: number;
};

const EMPTY_ARRAY: Array<Annotation> = [];

const getPage = (_: RootState, props: Props) => props.page;

const annotationsForPageSelector = createSelector(
  [selectAnnotationReducer, getPage],
  (annotations, page): Array<Annotation> => {
    if (!annotations.length) {
      return EMPTY_ARRAY;
    }

    return [...annotations.filter((annotation) => annotation.page === page)];
  }
);

export default annotationsForPageSelector;

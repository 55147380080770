import React, { FC } from "react";
import { Skeleton } from "antd";

const AvatarSkeletonLoader: FC = () => {
  return (
    <Skeleton
      avatar={{ shape: "circle" }}
      title
      active={true}
      paragraph={false}
    />
  );
};

export default AvatarSkeletonLoader;

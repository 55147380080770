import { Point } from "../interfaces/point";
import { ITextVertex, Rectangle } from "../interfaces/textLayer";

export const isCoordsEmpty = (coordinates: Rectangle): boolean => {
  return coordinates.width * coordinates.height <= 25;
};

export const getMinimalCoords = (
  coordinates: Rectangle,
  mousePosition: Point
): Rectangle => {
  if (!isCoordsEmpty(coordinates)) {
    return coordinates;
  }

  const { x, y } = mousePosition;

  return { left: x, top: y, width: 1, height: 1, rotation: 0 };
};

export const calculateSelectionRectangle = (
  startPoint: Point,
  endPoint: Point
): Rectangle => {
  const x3 = Math.min(startPoint.x, endPoint.x);
  const x4 = Math.max(startPoint.x, endPoint.x);
  const y3 = Math.min(startPoint.y, endPoint.y);
  const y4 = Math.max(startPoint.y, endPoint.y);

  return { left: x3, top: y3, width: x4 - x3, height: y4 - y3, rotation: 0 };
};

export const findIntersectingChildren = (
  tokens: Array<ITextVertex>,
  selectionRect: Rectangle
) => {
  return tokens.filter((token) => intersects(token, selectionRect));
};

export const intersects = (rect1: Rectangle, rect2: Rectangle): boolean => {
  return (
    rect1.left < rect2.left + rect2.width &&
    rect1.left + rect1.width > rect2.left &&
    rect1.top < rect2.top + rect2.height &&
    rect1.top + rect1.height > rect2.top
  );
};

import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Input, Modal, notification, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { EmailTemplate } from "../../interfaces/table";
import ReactQuill from "react-quill";
import "./EmailTemplateModal.scss";
import "react-quill/dist/quill.snow.css";

type Props = {
  open: boolean;
  loading: boolean;
  emailTemplate: EmailTemplate | undefined;
  handleUpsert: (emailTemplate: EmailTemplate) => void;
  closeModal: () => void;
};

const EmailTemplateModal: FC<Props> = ({
  open,
  loading,
  emailTemplate,
  handleUpsert,
  closeModal,
}) => {
  const { t } = useTranslation("emailTemplates");

  const [name, setName] = useState("");
  const [body, setBody] = useState("");
  const [favorite, setFavorite] = useState(false);

  useEffect(() => {
    setName(emailTemplate?.name ?? "");
    setBody(emailTemplate?.body ?? "");
    setFavorite(emailTemplate?.favorite ?? false);
  }, [emailTemplate]);

  const isTextBoxEditorEmpty = useCallback((value: string) => {
    return value.replace(/<(.|\n)*?>/g, "").trim().length === 0;
  }, []);

  const handleSave = useCallback(async () => {
    if (!name || isTextBoxEditorEmpty(body)) {
      notification.error({
        message: t("modal.notificationError.message"),
        description: t("modal.notificationError.description"),
      });

      return;
    }

    const toUpsertEmailTemplate = emailTemplate
      ? { ...emailTemplate }
      : { name: "", body: "", favorite: false };
    toUpsertEmailTemplate.name = name;
    toUpsertEmailTemplate.body = body;
    toUpsertEmailTemplate.favorite = favorite;

    handleUpsert(toUpsertEmailTemplate);
  }, [
    name,
    body,
    favorite,
    emailTemplate,
    handleUpsert,
    t,
    isTextBoxEditorEmpty,
  ]);

  const handleChangeName = useCallback((event: any) => {
    setName(event.target.value);
  }, []);

  return (
    <Modal
      width={1000}
      open={open}
      onCancel={closeModal}
      footer={[
        <Button key="abort" onClick={closeModal}>
          {t("modal.cancelButton")}
        </Button>,
        <Button
          key="send"
          type="primary"
          onClick={handleSave}
          loading={loading}
        >
          {emailTemplate?.id === undefined
            ? t("modal.addButton")
            : t("modal.updateButton")}
        </Button>,
      ]}
    >
      <div className="email-template-modal__content-container">
        <h3>{t("modal.title")}</h3>
        <div className="email-template-modal__name">
          <h4 className="label">{t("name")}:</h4>
          <Input size="large" value={name} onChange={handleChangeName} />
        </div>
        <h4 className="label">{t("body")}:</h4>
        <ReactQuill
          theme="snow"
          value={body}
          className="email-template-modal__content-editor"
          onChange={setBody}
        />
        <div>
          <h4 className="label">{t("favorite")}:</h4>
          <Switch checked={favorite} onChange={setFavorite} />
        </div>
      </div>
    </Modal>
  );
};

export default EmailTemplateModal;

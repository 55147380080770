import { ChangeEvent, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { setStpSuppliers } from "../store/organizationSlice";
import { IStpSupplierResponse } from "../interfaces/stp";
import { notification } from "antd";
import { useLazyInsertStpSupplierQuery } from "../store/organizationApi";
import sourceOptionsSelector from "../selectors/sourceOptionsSelector";

const useAddStpSupplier = () => {
  const dispatch = useAppDispatch();

  const { suppliers } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );

  const { user } = useAppSelector((state) => state.userReducer);

  const sourceOptions = useAppSelector(sourceOptionsSelector);

  const [supplierId, setSupplierId] = useState("");
  const [vat, setVat] = useState("");
  const [source, setSource] = useState("");

  const [insertStpSupplier] = useLazyInsertStpSupplierQuery();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    switch (name.toLowerCase()) {
      case "id":
        setSupplierId(value);
        break;
      case "vat":
        setVat(value);
        break;
      default:
        break;
    }
  }, []);

  const handleSourceChange = useCallback((value: string) => {
    setSource(value);
  }, []);

  const handleClick = useCallback(
    async (closeCallback: (isAdding: boolean) => void) => {
      try {
        if (!user?.organization?.id) {
          return;
        }

        if (!supplierId) {
          notification.error({
            message: "Supplier ID is required.",
          });
          return;
        }

        const requestBody: IStpSupplierResponse = {
          id: "",
          supplierId,
          vatNumber: vat,
          input: undefined,
          organizationId: user.organization.id,
        };
        if (source) {
          requestBody.input = {
            id: source,
            displayName: "",
          };
        }

        const data = await insertStpSupplier(requestBody).unwrap();

        const updatedSuppliers = [
          ...suppliers,
          { id: data.id, key: data.id, supplierId, vat, source },
        ];

        dispatch(setStpSuppliers({ suppliers: updatedSuppliers }));
        closeCallback(false);
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, supplierId, source, suppliers, vat, user, insertStpSupplier]
  );

  return {
    supplierId,
    vat,
    source,
    handleChange,
    handleSourceChange,
    handleClick,
    sourceOptions,
  };
};

export default useAddStpSupplier;

enum EPlatform {
  MAC = "MAC",
  LINUX = "LINUX",
  WINDOWS = "WINDOWS",
}

export const getPlatform = (): EPlatform => {
  const agent = window.navigator.userAgent.toUpperCase();

  if (agent.includes("MAC OS")) {
    return EPlatform.MAC;
  } else if (agent.includes("LINUX")) {
    return EPlatform.LINUX;
  } else {
    return EPlatform.WINDOWS;
  }
};

export const getModifierKeyForPlatform = (platform: EPlatform): string => {
  if (platform === EPlatform.MAC) {
    return "Meta";
  }

  return "Control";
};

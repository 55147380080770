import React, { useMemo } from "react";

interface Props {
  hasNoData: boolean;
}

const Error = ({ hasNoData }: Props) => {
  const message = useMemo(() => {
    if (hasNoData) {
      return "You need to provide valid PDF data.";
    }

    return "Something went wrong.";
  }, [hasNoData]);

  return (
    <div className="error-container">
      <i className="bi bi-file-earmark-x error-icon"></i>
      <span className="error-message">{message}</span>
    </div>
  );
};

export default Error;

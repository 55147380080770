import React, { FC, useMemo } from "react";
import useDraggable from "../../../../hooks/useDraggable";
import RemoveButton from "./RemoveButton";
import useTableUpdater from "../../../../hooks/useTableUpdater";

type Props = {
  isCreatingTable: boolean;
  id: string;
  tableId: string;
  left: number;
  height: number;
  mouseLoc: number;
  isHiddenColumn: boolean;
};

const TableColumn: FC<Props> = ({
  isCreatingTable,
  id,
  tableId,
  left,
  height,
  mouseLoc,
  isHiddenColumn,
}) => {
  const { deleteColumn } = useTableUpdater();

  const { dragging, pos, handleDraggingChange } = useDraggable(
    tableId,
    id,
    left,
    mouseLoc,
    "col",
    isCreatingTable
  );

  const className = useMemo(() => {
    if (dragging) {
      return "col dragging";
    }

    return "col";
  }, [dragging]);

  if (isHiddenColumn) {
    return null;
  }

  return (
    <>
      <div
        className={className}
        style={{ left: pos, height }}
        onMouseDown={() => handleDraggingChange(true)}
        onMouseUp={() => handleDraggingChange(false)}
      />
      <RemoveButton
        visible={isCreatingTable}
        tableId={tableId}
        id={id}
        style={{ left: pos - 7, top: height + 3 }}
        callBack={deleteColumn}
      />
    </>
  );
};

export default TableColumn;

export const calculateHideDelay = (message: string): number =>
  Math.min(8000, Math.max(message.length * 30, 500)) / 1000;

export const calculateTextWidth = (
  text: string,
  fontSize: number = 14,
  fontFamily: string = "sans-serif"
): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = `${fontSize}px ${fontFamily}`;
  return context.measureText(text).width;
};

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const getPagesInView = (state: RootState) => state.documentReducer.pagesInView;
const getPage = (_: RootState, page: number) => page;

const shouldFetchPageDataSelector = createSelector(
  [getPagesInView, getPage],
  (pagesInView, page): boolean => {
    if (!pagesInView.length) {
      return false;
    }

    const isInView = pagesInView.find((p) => p.page === page)?.inView || false;
    const isNextInView =
      pagesInView.find((p) => p.page + 1 === page)?.inView || false;
    const isPreviousInView =
      pagesInView.find((p) => p.page - 1 === page)?.inView || false;

    return isInView || isNextInView || isPreviousInView;
  }
);

export default shouldFetchPageDataSelector;

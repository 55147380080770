import React, { FC } from "react";
import { getVariableFromEnv } from "../utilities/env";

const version = getVariableFromEnv("VERSION");

type Props = {
  className: string;
};

const AppVersion: FC<Props> = ({ className }) => {
  return <div className={className}>Klassifai {version}</div>;
};

export default AppVersion;

import { Status } from "../common/status/status";
import { OrderColumnItem } from "./interfaces/overview";

export const TODO_STATUSES = [Status.ToDo, Status.OnHold];
export const IN_PROGRESS_STATUSES = [Status.OnHold, Status.InProgress];

export const ANNOTATION_TODO_STATUSES = [
  Status.Approved,
  Status.Exported,
  Status.AnnotationToDo,
  Status.AnnotationOnHold,
];
export const ANNOTATION_IN_PROGRESS_STATUSES = [
  Status.AnnotationOnHold,
  Status.AnnotationInProgress,
];
export const ERROR_STATUSES = [Status.Error, Status.ExportFailed];
export const DEFAULT_PAGE_SIZE = 20;
export const PAGE_OFFSET = 1;
export const FETCH_INTERVAL = 5000;

export const HEIGHT_TABLE_HEADER_AND_PAGINATION = 124; // pagination (64px) + header table (60px)
export const HEIGHT_TABLE_HEADER = 60;
export const HEIGHT_BULK_REMOVE_HEADER = 40;
export const HEIGHT_ANNOTATION_MODE_ALERT = 120;
export const HEIGHT_REMOVED_PAGE_ALERT = 40;

export const DATE_FILTER_FORMAT = "DD/MM/YYYY";
export const DATES_FILTER_STRING_SEPARATOR = ";";

export const SKELETON_LOAD_ROWS = 10;

export const DEFAULT_BLANK_VALUE = "SHOW_BLANK_ENTRIES";
export const EXTRA_SELECT_FILTER_PREFIX = ".extra-select";

export const LANGUAGE_OPTIONS = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "German",
    label: "German",
  },
];

export const REQUIRED_COLUMNS = {
  bulkDelete: {
    key: "bulkDelete",
    metadata: null,
    translations: null,
    generic: true,
    archiveOnly: false,
    defaultIndex: 0,
  },
  remove: {
    key: "remove",
    metadata: null,
    translations: null,
    generic: true,
    archiveOnly: false,
    defaultIndex: 0,
  },
  order: {
    key: "order",
    metadata: null,
    translations: null,
    generic: true,
    archiveOnly: false,
    defaultIndex: 0,
  },
};

export const DEFAULT_ORDER_COLUMNS: Array<OrderColumnItem> = [
  {
    title: "Name",
    key: "name",
    show: true,
  },
  {
    title: "Source",
    key: "input",
    show: true,
  },
  {
    title: "Date",
    key: "createdDate",
    show: true,
  },
  {
    title: "Document type",
    key: "documents",
    show: true,
  },
  {
    title: "Assignee",
    key: "assignee",
    show: true,
  },
  {
    title: "Status",
    key: "status",
    show: true,
  },
];

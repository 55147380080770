import React, { FC, ReactNode } from "react";
import "./TitleRowWithContent.scss";

type Props = {
  title: string;
  content: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
};

const TitleRowWithContent: FC<Props> = ({ title, children, content }) => {
  return (
    <div className="container">
      <div className="header">
        <h4>{title}</h4>
        <div>{content}</div>
      </div>
      {children}
    </div>
  );
};

export default TitleRowWithContent;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { GroupBlockEntityTypeWithGroupBlock } from "../interfaces/entity";

type Props = {
  entityId: string;
  index: number;
};

const getConfigMap = (state: RootState) => state.configMapReducer.configMap;
const getEntityId = (_: RootState, props: Props) => props.entityId;
const getIndex = (_: RootState, props: Props) => props.index;

const entityWithGroupBlockSelector = createSelector(
  [getConfigMap, getEntityId, getIndex],
  (configMap, entityId, index): GroupBlockEntityTypeWithGroupBlock | null => {
    if (!configMap?.groupBlocks?.length) {
      return null;
    }

    const flatEntityWithGroupBlockList = configMap.groupBlocks.flatMap(
      (groupBlock) =>
        groupBlock.groupBlockEntityTypes.map((entity) => ({
          entity,
          groupBlock,
        }))
    );

    if (!flatEntityWithGroupBlockList.length) {
      return null;
    }

    const found = flatEntityWithGroupBlockList.find(
      (e) => e.entity.entityType.id === entityId
    );

    if (!found) {
      return null;
    }

    return {
      groupBlock: found.groupBlock,
      entity: {
        ...found.entity,
        index,
      },
    };
  }
);

export default entityWithGroupBlockSelector;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const selectActiveDocumentSet = (state: RootState) =>
  state.documentSetsReducer.activeDocumentSet;

const documentIdsSelector = createSelector(
  selectActiveDocumentSet,
  (activeDocumentSet): Array<string> => {
    if (!activeDocumentSet?.documents?.length) {
      return [];
    }

    return activeDocumentSet.documents.map((d) => d.id);
  }
);

export default documentIdsSelector;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/user`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    removePermissionFromUser: build.mutation<
      void,
      {
        userId: string;
        data: any;
      }
    >({
      query: ({ userId, data }) => ({
        url: `/${encodeURI(userId)}/permission`,
        body: data,
        method: "DELETE",
      }),
    }),
    addPermissionToUser: build.mutation<
      void,
      {
        userId: string;
        permission: string;
      }
    >({
      query: ({ userId, permission }) => ({
        url: `/${encodeURI(userId)}/permission/${permission}`,
        method: "POST",
      }),
    }),
    updateUser: build.mutation<
      void,
      {
        userId: string;
        data: any;
      }
    >({
      query: ({ userId, data }) => ({
        url: `/${encodeURI(userId)}/update`,
        body: data,
        method: "PUT",
      }),
    }),
    updatePassword: build.mutation<
      void,
      {
        userId: string;
        data: { password: string };
      }
    >({
      query: ({ userId, data }) => ({
        url: `/${encodeURI(userId)}/password`,
        body: data,
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useRemovePermissionFromUserMutation,
  useAddPermissionToUserMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
} = userApi;

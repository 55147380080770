import React, { FC } from "react";
import "./MetricGraphs.scss";
import { DocumentSetMetrics } from "../../interfaces/documentSetMetrics";
import LineGraph from "../graphs/lineGraph/LineGraph";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import AverageNumber from "./number/AverageNumber";
import MetricsOverview from "../metricsOverview/MetricsOverview";
import SourcesOverview from "../sourcesOverview/SourcesOverview";
import { useAppSelector } from "../../../app";
import calculateMetricsSelector from "../../selectors/calculateMetricsSelector";
import InteractiveLinePieGraph from "../graphs/InteractiveLinePieGraph";

type Props = {
  metrics: DocumentSetMetrics | undefined;
  loading: boolean;
  showNoMetricsAlert: boolean;
};

const MetricGraphs: FC<Props> = ({ metrics, loading, showNoMetricsAlert }) => {
  const { t } = useTranslation("analytics");

  const { organizationMetrics, sourceMetrics } = useAppSelector(
    calculateMetricsSelector
  );

  return (
    <div className="metric-graphs__container">
      <MetricsOverview
        totalVolume={organizationMetrics.volume.value}
        totalVolumeTrend={organizationMetrics.volume.trend}
        avgTimeSpend={organizationMetrics.avgTimeSpend.value}
        avgTimeSpendTrend={organizationMetrics.avgTimeSpend.trend}
        automaticallyProcessed={organizationMetrics.automatic.value}
        automaticallyProcessedTrend={organizationMetrics.automatic.trend}
        loading={loading}
      />
      <SourcesOverview sourceMetrics={sourceMetrics} loading={loading} />
      {showNoMetricsAlert && !loading ? (
        <Alert
          message={t("noMetricsFound")}
          type="info"
          showIcon
          className="graph-alert"
        />
      ) : null}
      <InteractiveLinePieGraph
        title={t("metricGraphs.documentsProcessed")}
        lineGraphMetrics={metrics?.approvedGraphData}
        pieGraphMetrics={metrics?.approvedPieMetricsTotals}
        loading={loading}
        useSourceColors={true}
        tooltipTitle={t("tooltips.manual")}
      />
      <InteractiveLinePieGraph
        title={t("metricGraphs.autoDocumentsProcessed")}
        lineGraphMetrics={metrics?.autoApprovedGraphData}
        pieGraphMetrics={metrics?.autoApprovedPieMetricsTotals}
        loading={loading}
        useSourceColors={true}
        tooltipTitle={t("tooltips.stp")}
      />
      <InteractiveLinePieGraph
        title={t("metricGraphs.manualVsStpDocumentsProcessed")}
        lineGraphMetrics={metrics?.autoApprovedVsManualGraphData}
        pieGraphMetrics={metrics?.manualVsStpApprovedPieMetricsTotals}
        loading={loading}
        useSourceColors={false}
        tooltipTitle={t("tooltips.manualVsStp")}
      />
      <InteractiveLinePieGraph
        title={t("metricGraphs.rejected")}
        lineGraphMetrics={metrics?.rejectedGraphData}
        pieGraphMetrics={metrics?.rejectedPieMetricsTotals}
        loading={loading}
        useSourceColors={true}
        tooltipTitle={t("tooltips.rejected")}
      />
      <div className="metric-graphs-row">
        <div className="line-graph">
          <LineGraph
            title={t("metricGraphs.firstTimeRight")}
            tooltipTitle={t("tooltips.organizationFirstTimeRight")}
            metrics={metrics?.firstTimeRight}
            loading={loading}
            tooltipSuffix="%"
          />
        </div>
        <div className="pie-graph" style={{ flexDirection: "column" }}>
          <AverageNumber
            metrics={metrics?.firstTimeRight}
            valueSuffix="%"
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default MetricGraphs;

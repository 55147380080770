import { Role } from "./role";

export interface TableData {
  id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  permissions: Array<string>;
  roles: Array<string>;
}

export interface MemberRole extends Role {
  isActive: boolean;
}

export interface Invite {
  id: string;
  email: string;
  createdAt: string;
  expiresAt: string;
}

export interface OrganizationInput {
  id: string;
  displayName: string;
}

export interface Filters {
  name: string;
  email: string;
  sources: Array<string>;
  roles: Array<string>;
}

export interface OrganizationResponse {
  members: Array<TableData>;
  inputs: Array<OrganizationInput>;
  roles: Array<Role>;
}

export enum FilterType {
  name,
  text,
  email,
  options,
  sources,
  roles,
}

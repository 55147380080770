import React, { FC } from "react";
import Table from "./Table";
import { useAppSelector } from "../../../../app";

interface Props {
  pageNumber: number;
  isCreatingTable: boolean;
  zIndex: number;
  style: any;
}

const TableBuilder: FC<Props> = ({
  pageNumber,
  isCreatingTable,
  zIndex,
  style,
}) => {
  const tables = useAppSelector(
    (state) => state.annotatorReducer.tableState.tables
  );

  return (
    <div className="table-builder__container" style={style}>
      {tables
        .filter((table) => table.page === pageNumber)
        .map((table) => {
          return (
            <Table
              key={table.id}
              table={table}
              isCreatingTable={isCreatingTable}
              zIndex={zIndex}
            />
          );
        })}
    </div>
  );
};

export default TableBuilder;

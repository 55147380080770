import React, { FC, MutableRefObject, ReactNode, useMemo } from "react";
import { Select } from "antd";
import { Option } from "../../../../common/dataRefining/interfaces/filteringData";
import { OverviewType } from "../../../../app";
import { ColumnItem } from "../../../interfaces/overview";
import { handleKeyEvent } from "../../../utils/keyboardHelper";
import useShowBlankEntriesCheckbox from "../../../hooks/useShowBlankEntriesCheckbox";
import useFilterableItem from "../../../hooks/useFilterableItem";
import BlankEntriesCheckbox from "./BlankEntriesCheckbox";
import useTableHeaderFilter from "../../../hooks/useTableHeaderFilter";
import { getKeyFromMetaData } from "../../../utils/columnItemMetaDataHelpers";
import ExtraSelectFilter from "./ExtraSelectFilter";
import DropdownActions from "../../../../common/tableHeaderFilter/dropdownActions/DropdownActions";

type Props = {
  columnItem: ColumnItem;
  label: ReactNode;
  placeholder: string;
  options: Array<Option>;
  mode?: "multiple" | "tags";
  renderRef: MutableRefObject<any>;
  isOpen: boolean;
  overviewType: OverviewType;
};

const FilterableItem: FC<Props> = ({
  columnItem,
  label,
  placeholder,
  options,
  mode = "multiple",
  renderRef,
  isOpen,
  overviewType,
}) => {
  const { getValueFromKey } = useTableHeaderFilter(columnItem, overviewType);

  const values = useMemo(() => {
    return getValueFromKey(
      getKeyFromMetaData(columnItem?.metadata, overviewType),
      columnItem?.metadata?.filterAble!.delimiter
    )?.split("+");
  }, [getValueFromKey, columnItem?.metadata, overviewType]);

  const {
    isBlankEntriesChecked,
    handleChangeBlankEntries,
    labelCheckbox,
    showBlankEntriesCheckbox,
    blankValue,
  } = useShowBlankEntriesCheckbox(columnItem, overviewType, values);

  const { ref, selectValue, handleChange, open, setOpen } = useFilterableItem(
    columnItem,
    overviewType,
    isOpen,
    isBlankEntriesChecked,
    blankValue,
    values
  );

  return (
    <div className="filter-popover__item">
      {label}
      {showBlankEntriesCheckbox ? (
        <BlankEntriesCheckbox
          checkboxValue={isBlankEntriesChecked}
          handleChangeBlankEntries={handleChangeBlankEntries}
          labelCheckbox={labelCheckbox}
        />
      ) : null}
      <Select
        ref={ref}
        mode={mode}
        value={selectValue}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        getPopupContainer={() => renderRef.current}
        onInputKeyDown={handleKeyEvent}
        allowClear
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        dropdownRender={(menu) => (
          <DropdownActions handleOkClick={() => setOpen(false)}>
            {menu}
          </DropdownActions>
        )}
      />
      <ExtraSelectFilter
        columnItem={columnItem}
        overviewType={overviewType}
        isOpen={isOpen}
      />
    </div>
  );
};

export default FilterableItem;

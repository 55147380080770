import { createSelector } from "@reduxjs/toolkit";
import selectConfigMap from "./configMapSelector";
import { GroupBlockData } from "../interfaces/groupblock";
import { RootState } from "../../app";

type Props = {
  hideNonRequiredEntities: boolean;
};

const EMPTY_ARRAY: Array<GroupBlockData> = [];

const selectHideNonRequiredEntities = (_: RootState, props: Props) =>
  props.hideNonRequiredEntities;

const flatGroupBlockListSelector = createSelector(
  [selectConfigMap, selectHideNonRequiredEntities],
  (configMap, hideNonRequiredEntities) => {
    if (!configMap?.groupBlocks.length) {
      return EMPTY_ARRAY;
    }

    return configMap.groupBlocks
      .map((block) => ({
        ...block,
        groupBlockEntityTypes: [...block.groupBlockEntityTypes!]
          .filter((entity) => {
            if (!hideNonRequiredEntities) {
              return true;
            }

            return entity.required;
          })
          .sort((a, b) => a.index - b.index),
      }))
      .sort((a, b) => a.index - b.index);
  }
);

export default flatGroupBlockListSelector;

import { SearchQuery } from "../interfaces/api";
import { User } from "../../user";
import { fetchClassifierOptionsSearch } from "../../metadata/utils/classifier";
import { buildSearchQuery } from "../../common/utilities/requests";

export const runSearch = async (
  searchValue: string,
  query: SearchQuery,
  language: string,
  user: User | null,
  inputIds: Array<string> | undefined,
  values: Array<string> | undefined
) => {
  if (!query.prefetch && searchValue.length < 3) {
    return [];
  }

  const searchQuery = buildSearchQuery(
    query,
    searchValue,
    user,
    language,
    inputIds,
    undefined,
    undefined,
    values
  );

  const { data } = await fetchClassifierOptionsSearch(
    query.endpoint,
    searchQuery
  );
  return data.map((d) => {
    if (d.visibleValue) {
      return {
        key: d.key,
        value: d.visibleValue,
        label: d.visibleValue,
      };
    }

    return d;
  });
};

import { useMemo } from "react";
import useMeasure from "react-use-measure";
import { HEIGHT_TABLE_HEADER } from "../../../documentSet/constants";
import {
  HEIGHT_TITLE_HEADER,
  PAGE_PADDING,
} from "../../../userManagement/constants";

export enum TableType {
  USER_MANAGEMENT,
  STP,
  EMAIL_TEMPLATES,
  RELEASES,
}

const useTableDynamicHeight = (tableType: TableType) => {
  const [refHeightContainer, { height: heightContainer }] = useMeasure();

  const heightTableContent = useMemo(() => {
    if (
      tableType === TableType.USER_MANAGEMENT ||
      tableType === TableType.STP
    ) {
      return (
        heightContainer -
        HEIGHT_TABLE_HEADER -
        HEIGHT_TITLE_HEADER -
        PAGE_PADDING
      );
    }

    return heightContainer - HEIGHT_TABLE_HEADER;
  }, [heightContainer, tableType]);

  return {
    refHeightContainer,
    heightTableContent,
  };
};

export default useTableDynamicHeight;

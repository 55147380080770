import React, { FC, MouseEventHandler } from "react";
import { Button, Tooltip } from "antd";
import "./NotRenderedOverlay.scss";
import { useTranslation } from "react-i18next";

type Props = {
  handleClick: MouseEventHandler;
};

const NotRenderedOverlay: FC<Props> = ({ handleClick }) => {
  const { t } = useTranslation("annotator");

  return (
    <div className="not-rendered-overlay__container">
      <div className="not-rendered-overlay__content">
        <Tooltip placement="top" title={t("notRenderedOverlay.info")}>
          <span className="bi bi-info-circle-fill not-rendered-overlay__info-icon" />
        </Tooltip>
        <Button
          style={{ margin: "10px" }}
          type="primary"
          shape="round"
          size="large"
          onClick={handleClick}
        >
          {t("notRenderedOverlay.button")}
        </Button>
      </div>
    </div>
  );
};

export default NotRenderedOverlay;

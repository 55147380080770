import { useMemo } from "react";
import {
  ERROR_WHILE_NORMALIZING,
  INVALID_VALUE,
} from "../../configMap/utils/validationUtils";
import { ValidationResult } from "../../common/validation/constants";
import { useTranslation } from "react-i18next";

const useEntityValidationMessage = (
  value: string,
  normalizedValue: string | undefined,
  validation: ValidationResult,
  options?: Array<string>
) => {
  const { t } = useTranslation("entityValidations");

  const isErrorWhileNormalizing = useMemo(() => {
    return (
      (normalizedValue ?? value)?.toLowerCase() ===
      ERROR_WHILE_NORMALIZING.toLowerCase()
    );
  }, [normalizedValue, value]);

  const isValidOption = useMemo(() => {
    if (!options?.length || !normalizedValue) {
      return true;
    }

    return options.includes(normalizedValue.toLowerCase());
  }, [normalizedValue, options]);

  const isInvalidValue = useMemo(() => {
    return (
      (normalizedValue ?? value)?.toLowerCase() === INVALID_VALUE.toLowerCase()
    );
  }, [normalizedValue, value]);

  return useMemo(() => {
    if (options?.length && !isValidOption) {
      return isInvalidValue
        ? t("common.errors.errorWhileMapping").replace(
            "$OPTIONS",
            options.join(", ")
          )
        : t("common.errors.errorWhileMappingManual").replace(
            "$OPTIONS",
            options.join(", ")
          );
    }

    if (!validation.show) {
      return null;
    }

    if (isErrorWhileNormalizing) {
      return t(`common.errors.errorWhileNormalizing`);
    }

    return t(`${validation.message}`);
  }, [
    options,
    isValidOption,
    t,
    validation,
    isErrorWhileNormalizing,
    isInvalidValue,
  ]);
};

export default useEntityValidationMessage;

import React, { FC } from "react";
import RemoveButton from "./RemoveButton";

type Props = {
  isCreatingTable: boolean;
  id: string;
  tableId: string;
  top: number;
  width: number;
  isHiddenRow: boolean;
  page: number;
};

const TableRow: FC<Props> = ({
  isCreatingTable,
  id,
  tableId,
  top,
  width,
  isHiddenRow,
  page,
}) => {
  if (isHiddenRow) {
    return null;
  }

  return (
    <>
      <div className="row" style={{ top, width }} />
      <RemoveButton
        visible={isCreatingTable}
        tableId={tableId}
        id={id}
        style={{ top: top - 7, left: width + 3 }}
        page={page}
      />
    </>
  );
};

export default TableRow;

import { useEffect, useMemo, useState } from "react";
import { passwordValidations } from "../constants";
import { PasswordValidation } from "../interfaces/password";

const usePasswordValidation = (password: string) => {
  const [validations, setValidations] = useState<Array<PasswordValidation>>(
    passwordValidations.map((val) => ({
      name: val.name,
      passed: false,
    }))
  );

  useEffect(() => {
    setValidations(
      passwordValidations.map((val) => {
        if (password.match(val.validation)) {
          return {
            name: val.name,
            passed: true,
          };
        }

        return {
          name: val.name,
          passed: false,
        };
      })
    );
  }, [password]);

  const isValidPassword = useMemo(() => {
    const length = validations
      .map((val) => val.passed)
      .filter((passed) => !passed).length;
    return length === 0;
  }, [validations]);

  return { validations, isValidPassword };
};

export default usePasswordValidation;

import React, { FC } from "react";
import { Popover, Tag } from "antd";
import "./PermissionTags.scss";
import usePermissionTags from "../../hooks/usePermissionTags";
import { TagInfo } from "../../utils/tags";

type Props = {
  permissions: Array<string>;
  orgPermissionTagsInfo: Array<TagInfo>;
};

const PermissionTags: FC<Props> = ({ permissions, orgPermissionTagsInfo }) => {
  const { numberOfVisibleTags, containerRef, tagsToShow, tagsToShowInPlusTag } =
    usePermissionTags(permissions, orgPermissionTagsInfo);

  if (numberOfVisibleTags === undefined) {
    return <div ref={containerRef}></div>;
  }

  return (
    <div ref={containerRef} className="permission-tag__container">
      {tagsToShow.map((permission) => (
        <Tag key={permission.id} color="blue">
          {permission.displayName}
        </Tag>
      ))}
      {tagsToShowInPlusTag.length > 0 ? (
        <Popover
          placement="topLeft"
          content={
            <div className="permission-tags-popover__container">
              {tagsToShowInPlusTag.map((permission) => (
                <Tag
                  key={permission.id}
                  color="blue"
                  className="permission-tag"
                >
                  {permission.displayName}
                </Tag>
              ))}
            </div>
          }
        >
          <Tag color="blue">{`+${tagsToShowInPlusTag.length}`}</Tag>
        </Popover>
      ) : null}
    </div>
  );
};

export default PermissionTags;

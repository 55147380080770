import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../app";
import { GroupBlockEntityType } from "../../configMap";

const EMPTY_ARRAY: Array<GroupBlockEntityType> = [];

const selectActiveConfigMap = (state: RootState) =>
  state.organizationReducer.settings.configMap;

const selectConfigMapEntities = createSelector(
  selectActiveConfigMap,
  (activeConfigMap): Array<GroupBlockEntityType> => {
    if (!activeConfigMap) {
      return EMPTY_ARRAY;
    }

    return [...activeConfigMap.groupBlocks]
      .sort((a, b) => a.index - b.index)
      .flatMap((g) => g.groupBlockEntityTypes);
  }
);

export default selectConfigMapEntities;

import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { doneStatuses, Status } from "../../common/status/status";
import useBulkAction from "./useBulkAction";
import { DocumentSetData } from "../interfaces/documentSet";
import {
  resetSelectedRows,
  updateSelectedRows,
} from "../store/documentSetSlice";

const useBulkActionHeader = (documentSets: Array<DocumentSetData>) => {
  const { selectedRows } = useBulkAction();

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.userReducer.user);

  const rowsThatCanBeSelected = useMemo(() => {
    return documentSets.filter(
      (documentSet) =>
        !doneStatuses.includes(documentSet.status) &&
        (!documentSet.assignee || documentSet.assignee?.id === user?.id) &&
        documentSet.status !== Status.Remove
    );
  }, [documentSets, user]);

  const isAllSelected = useMemo(() => {
    return selectedRows.length === rowsThatCanBeSelected.length;
  }, [selectedRows, rowsThatCanBeSelected]);

  const toggleSelectAll = useCallback(() => {
    if (selectedRows.length === rowsThatCanBeSelected.length) {
      dispatch(resetSelectedRows());
    } else {
      const ids = rowsThatCanBeSelected.map((row) => row.id);
      dispatch(updateSelectedRows(ids));
    }
  }, [dispatch, rowsThatCanBeSelected, selectedRows]);

  return {
    selectedRows,
    isAllSelected,
    toggleSelectAll,
  };
};

export default useBulkActionHeader;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { DocumentSetMetrics } from "../interfaces/documentSetMetrics";
import { setMetricsData } from "./analyticsSlice";
import { notification } from "antd";
import { ArgsProps } from "antd/lib/notification";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";
import { MetricEvent } from "../interfaces/metricEvent";
import { calculateAllTotalMetrics } from "../utils/utils";
import { MetricsMode } from "../constants";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/api/v1/metrics`,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    getAllMetricsForInputs: build.query<
      DocumentSetMetrics,
      {
        startDate: string;
        endDate: string;
        filterString: string;
        metricsMode: MetricsMode;
        inputIds: Array<string>;
        notificationErrorBody: ArgsProps;
      }
    >({
      query: ({ startDate, endDate, filterString, inputIds, metricsMode }) => {
        let queryPath: string;
        if (metricsMode === MetricsMode.OPERATIONAL) {
          queryPath = "inputs";
        } else if (metricsMode === MetricsMode.ADVANCED) {
          queryPath = "advanced";
        } else {
          queryPath = "users";
        }

        return {
          url: `/${queryPath}?inputIds=${inputIds.join(
            ","
          )}&startDate=${startDate}&endDate=${endDate}&filter=${filterString}`,
          method: "GET",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const metrics = calculateAllTotalMetrics({ ...data });
          dispatch(setMetricsData(metrics));
        } catch (err: any) {
          console.error(
            `An error occurred while retrieving the metrics for the document sets: ${parseReduxQueryError(
              err
            )}`
          );
          notification.error(args.notificationErrorBody);
          dispatch(setMetricsData(undefined));
        }
      },
    }),
    saveMetricEvent: build.mutation<void, MetricEvent>({
      query: (metricEvent) => ({
        url: "/events",
        body: metricEvent,
        method: "POST",
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          console.error(
            `An error occurred while saving a metric event: ${parseReduxQueryError(
              err
            )}`
          );
        }
      },
    }),
  }),
});

export const { useGetAllMetricsForInputsQuery, useSaveMetricEventMutation } =
  analyticsApi;

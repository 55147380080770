import { useCallback, useMemo } from "react";
import { ITable } from "../interfaces/ITable";
import { AnnotationParams } from "../interfaces/annotation";
import {
  addColumnAndGetTables,
  addRowAndGetTables,
  buildCellsForTable,
  deleteColumnAndGetTables,
  deleteExistingAnnotations,
  deleteRowAndGetTables,
  excludeRowAndGetTables,
  getAnnotationsForRow,
  getAnnotationsInCellsForSelection,
  linkTablesAndGetTables,
} from "../helpers/tableHelper";
import { generateRandomHash } from "../helpers/hashHelper";
import useAnnotatorConfig from "./useAnnotatorConfig";
import useAnnotationUpdater from "./useAnnotationUpdater";
import { useAppDispatch, useAppSelector } from "../../app";
import { updateTableAnnotationsInState } from "../store/AnnotatorSlice";
import { ITextVertex, Rectangle } from "../interfaces/textLayer";
import selectAnnotationReducer from "../../annotation/selectors/annotationReducerSelector";

const useTableUpdater = () => {
  const dispatch = useAppDispatch();

  const tables = useAppSelector(
    (state) => state.annotatorReducer.tableState.tables
  );
  const annotations = useAppSelector(selectAnnotationReducer);

  const {
    previousTableState,
    setPreviousTableState,
    setTableLastAction,
    lastAction,
    readonly,
  } = useAnnotatorConfig();

  const {
    updateAnnotationsInBulk,
    addAnnotationsInBulk,
    removeAnnotationsInBulk,
  } = useAnnotationUpdater();

  const tableControlAnnotations = useMemo(() => {
    return annotations.filter((a) => a.multipleGroupBlocks);
  }, [annotations]);

  const setTables = useCallback(
    (tables: Array<ITable>) => {
      if (readonly) {
        return;
      }

      dispatch(updateTableAnnotationsInState(tables));
    },
    [dispatch, readonly]
  );

  const addTable = useCallback(
    (
      table: ITable,
      pageNumber: number,
      tokens: Array<ITextVertex>,
      text: string
    ) => {
      const { width, height } = table;
      if (width < 5 || height < 5) {
        return;
      }

      table.rows.push({
        id: generateRandomHash(),
        height: table.height,
        y: 0,
        exclude: false,
      });
      table.columns.push({
        id: generateRandomHash(),
        width: table.width,
        x: 0,
      });
      table.cells = buildCellsForTable(table, tokens, text);

      if (tokens.length) {
        deleteExistingAnnotations(
          table,
          annotations,
          removeAnnotationsInBulk,
          pageNumber
        );
      }

      setTableLastAction("undo");
      setPreviousTableState(tables);
      setTables([...tables, table]);
    },
    [
      annotations,
      setTableLastAction,
      setPreviousTableState,
      tables,
      setTables,
      removeAnnotationsInBulk,
    ]
  );

  const removeTable = useCallback(
    (table: ITable) => {
      const toRemoveAnnotations = [...tableControlAnnotations].filter(
        (a) => a.tableId === table.id
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);

      setTableLastAction("undo");
      setPreviousTableState(tables);

      const toSetTables = [...tables].filter(
        (prevTable) => prevTable.id !== table.id
      );
      setTables(toSetTables);
    },
    [
      tableControlAnnotations,
      setTableLastAction,
      setPreviousTableState,
      setTables,
      tables,
      removeAnnotationsInBulk,
    ]
  );

  const mapAnnotationToTable = useCallback(
    (markToAdd: AnnotationParams, coords: Rectangle) => {
      if (!tables.length) {
        return;
      }

      const annotationsToAdd = getAnnotationsInCellsForSelection(
        tables,
        markToAdd,
        coords
      );
      addAnnotationsInBulk(annotationsToAdd, markToAdd.multipleGroupBlocks);
    },
    [tables, addAnnotationsInBulk]
  );

  const confirmAnnotations = useCallback(() => {
    const toUpdateAnnotations = [...tableControlAnnotations].map((a) => {
      if (a.tableId) {
        return {
          ...a,
          tempAnnotation: false,
        };
      }

      return a;
    });

    updateAnnotationsInBulk(toUpdateAnnotations, true);
  }, [tableControlAnnotations, updateAnnotationsInBulk]);

  const deleteAnnotations = useCallback(
    (table: ITable) => {
      const toRemoveAnnotations = [...tableControlAnnotations].filter(
        (a) => a.tableId === table.id
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);
    },
    [tableControlAnnotations, removeAnnotationsInBulk]
  );

  const deleteRowAnnotations = useCallback(
    (table: ITable, rowIndex: number) => {
      const toRemoveAnnotations = getAnnotationsForRow(
        table,
        [...tableControlAnnotations],
        rowIndex
      );
      removeAnnotationsInBulk(toRemoveAnnotations, true);
    },
    [tableControlAnnotations, removeAnnotationsInBulk]
  );

  const addRow = useCallback(
    (
      tableId: string,
      tokens: Array<ITextVertex>,
      text: string,
      y: number,
      id?: string
    ) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(addRowAndGetTables(tables, tokens, text, tableId, y, id));
    },
    [setPreviousTableState, setTableLastAction, setTables, tables]
  );

  const addColumn = useCallback(
    (
      tableId: string,
      tokens: Array<ITextVertex>,
      text: string,
      x: number,
      id?: string
    ) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(addColumnAndGetTables(tables, tokens, text, tableId, x, id));
    },
    [setTables, setPreviousTableState, setTableLastAction, tables]
  );

  const deleteRow = useCallback(
    (
      tableId: string,
      tokens: Array<ITextVertex>,
      text: string,
      rowId: string
    ) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(deleteRowAndGetTables(tables, tokens, text, tableId, rowId));
    },
    [tables, setTables, setTableLastAction, setPreviousTableState]
  );

  const deleteColumn = useCallback(
    (
      tableId: string,
      tokens: Array<ITextVertex>,
      text: string,
      colId: string
    ) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(deleteColumnAndGetTables(tables, tokens, text, tableId, colId));
    },
    [tables, setTables, setPreviousTableState, setTableLastAction]
  );

  const excludeRow = useCallback(
    (
      tableId: string,
      tokens: Array<ITextVertex>,
      text: string,
      rowId: string,
      exclude: boolean
    ) => {
      setTableLastAction("undo");
      setPreviousTableState(tables);

      setTables(
        excludeRowAndGetTables(tables, tokens, text, tableId, rowId, exclude)
      );
    },
    [tables, setTables, setPreviousTableState, setTableLastAction]
  );

  const linkTables = useCallback(
    (tableId: string, tokens: Array<ITextVertex>, text: string) => {
      const targetTableIndex = tables.findIndex((t) => t.id === tableId) - 1;

      if (targetTableIndex < 0) {
        return;
      }

      const targetTable = tables[targetTableIndex];
      const { columns, x, width } = targetTable;

      setPreviousTableState(tables);

      setTables(
        linkTablesAndGetTables(
          tables,
          tokens,
          text,
          tableId,
          columns,
          x,
          width,
          targetTable
        )
      );
    },
    [tables, setPreviousTableState, setTables]
  );

  const undoOrRedoLastAction = useCallback(() => {
    setTableLastAction("redo");
    setPreviousTableState(tables);
    setTables(previousTableState);
  }, [
    tables,
    setTableLastAction,
    setPreviousTableState,
    setTables,
    previousTableState,
  ]);

  return {
    tables,
    addTable,
    removeTable,
    addColumn,
    addRow,
    mapAnnotationToTable,
    confirmAnnotations,
    deleteAnnotations,
    deleteRowAnnotations,
    deleteRow,
    deleteColumn,
    excludeRow,
    linkTables,
    undoOrRedoLastAction,
    lastAction,
  };
};

export default useTableUpdater;

import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import { changeActiveEntity, changeAnnotating } from "../../app/store/appSlice";
import flatGroupBlockListSelector from "../selectors/flatGroupBlockListSelector";
import { updateDisabledEntities } from "../store/configMapSlice";

const useTableAnnotatingListener = () => {
  const dispatch = useAppDispatch();

  const groupBlocks = useAppSelector((state) =>
    flatGroupBlockListSelector(state, { hideNonRequiredEntities: false })
  );

  const handleTableAnnotatingChange = useCallback(
    (tableAnnotating: boolean) => {
      const nonMultiEntityIds = groupBlocks
        .filter((block) => !block.multipleGroupBlocks)
        .flatMap((block) => block.groupBlockEntityTypes)
        .map((entity) => entity.entityType.id);

      if (tableAnnotating) {
        dispatch(changeAnnotating(false));
        dispatch(changeActiveEntity(undefined));
        dispatch(updateDisabledEntities(nonMultiEntityIds));
      } else {
        dispatch(updateDisabledEntities([]));
      }
    },
    [dispatch, groupBlocks]
  );

  return { handleTableAnnotatingChange };
};

export default useTableAnnotatingListener;

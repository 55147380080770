import { createSelector } from "@reduxjs/toolkit";
import metricsFilterSelector from "./metricsFilterSelector";
import { getRangeDateByPeriod } from "../utils/rangePicker";

// Return a date range if no dates are found
const rangeDates = createSelector(metricsFilterSelector, (metricsFilter) => {
  const { startDate, endDate, activePeriod } = metricsFilter;

  if (!startDate || !endDate) {
    return getRangeDateByPeriod(activePeriod);
  }

  return { startDate: new Date(startDate), endDate: new Date(endDate) };
});

export default rangeDates;

import React, { FC, useMemo } from "react";
import "./Assignee.scss";
import { CustomAvatar } from "../../../../common/avatar";
import { getInitialsFromName } from "../../../../user";
import { useAppSelector } from "../../../../app";
import {
  annotationDoneStatuses,
  doneStatuses,
} from "../../../../common/status/status";

const Assignee: FC = () => {
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const user = useMemo(() => {
    if (!activeDocumentSet) {
      return;
    }

    if (doneStatuses.includes(activeDocumentSet.status)) {
      return activeDocumentSet.approvedBy;
    }
    if (annotationDoneStatuses.includes(activeDocumentSet.status)) {
      return activeDocumentSet.annotatedBy;
    }

    return activeDocumentSet.assignee;
  }, [activeDocumentSet]);

  if (user) {
    const { firstName, lastName, avatar } = user;
    return (
      <CustomAvatar
        size={42}
        fontSize={14}
        src={avatar || getInitialsFromName(firstName, lastName)}
        tooltipTitle={`${firstName} ${lastName}`}
        placement="right"
      />
    );
  }

  return null;
};

export default Assignee;

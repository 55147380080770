import React, { FC } from "react";
import useTableBorder from "../../../../hooks/useTableBorder";

interface Props {
  type: "top" | "left";
  style: any;
  tableId: string;
  isCreatingTable: boolean;
  width: number;
  height: number;
  page: number;
}

const TableBorder: FC<Props> = ({
  type,
  style,
  tableId,
  isCreatingTable,
  width,
  height,
  page,
}) => {
  const { ref, icon, addTableDetails } = useTableBorder(
    type,
    tableId,
    isCreatingTable,
    width,
    height,
    page
  );

  return (
    <div ref={ref} className="table-builder__border" style={{ ...style }}>
      <i
        className="bi-plus table-builder__border-icon"
        style={{ top: icon[0].y, left: icon[0].x }}
        onClick={() => addTableDetails()}
      />
      <div
        style={{
          position: "absolute",
          top: icon[1].top,
          left: icon[1].left,
          border: "2px solid #69c0ff",
          width: icon[1].width,
          height: icon[1].height,
        }}
      />
    </div>
  );
};

export default TableBorder;

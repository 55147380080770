import React, { FC, ReactNode } from "react";
import useHeaderText from "../../hooks/useHeaderText";
import { Layout } from "antd";
import TextWithMaxCharacters from "../HeaderWithTitle/TextWithMaxCharacters";
import GoBackRoute from "../HeaderWithTitle/GoBackRoute";
import "./HeaderWithChildren.scss";
import PreviousNextButton from "../HeaderWithTitle/PreviousNextButton";

type Props = {
  children: ReactNode;
};

const HeaderWithChildren: FC<Props> = ({ children }) => {
  const {
    headerTitle,
    showBackButton,
    showBackButtonAndUpDownButton,
    pathname,
  } = useHeaderText();

  if (showBackButtonAndUpDownButton) {
    return (
      <Layout.Header className="header-with-title__container">
        <div className="flex__wrapper">
          <GoBackRoute pathName={pathname} />
          <PreviousNextButton />
          <div className="header-with-title__title">
            <TextWithMaxCharacters text={headerTitle} maxLength={40} />
          </div>
        </div>
        {children}
      </Layout.Header>
    );
  }

  if (showBackButton) {
    return (
      <Layout.Header className="header-with-title__container">
        <div className="flex__wrapper">
          <GoBackRoute pathName={pathname} />
          <div className="header-with-title__title">
            <TextWithMaxCharacters text={headerTitle} maxLength={40} />
          </div>
        </div>
        {children}
      </Layout.Header>
    );
  }

  return (
    <Layout.Header className="header-with-title__container">
      <div className="header-with-title__title">
        <TextWithMaxCharacters text={headerTitle} maxLength={40} />
      </div>
      {children}
    </Layout.Header>
  );
};

export default HeaderWithChildren;

import { isNotEmpty } from "../../common/utilities/value";

export const shouldBeValidated = (
  value: any,
  requiresValue: boolean
): boolean => {
  if (!requiresValue) {
    return true;
  }

  return requiresValue && isNotEmpty(value);
};

export const INVALID_VALUE = "Invalid value";
export const ERROR_WHILE_NORMALIZING = "Error while normalizing";

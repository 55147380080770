import { useCallback, useMemo, useRef } from "react";
import { RangePickerProps } from "antd/es/date-picker";
import { updateFilterArray } from "../../common/dataRefining/store/dataRefiningSlice";
import dayjs from "dayjs";
import { OverviewType, useAppDispatch } from "../../app";
import { ColumnItem } from "../interfaces/overview";
import { DATES_FILTER_STRING_SEPARATOR } from "../constants";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import useFocus from "./useFocus";

const useDateFilterItem = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  isOpen: boolean,
  isBlankEntriesChecked: boolean,
  blankValue: string,
  values: Array<string> | undefined
) => {
  const dispatch = useAppDispatch();

  const { metadata } = columnItem;

  const ref = useRef(null);

  const { open, setOpen } = useFocus(ref, isOpen);

  const onChange = useCallback(
    (dateValues: RangePickerProps["value"]) => {
      if (dateValues?.length) {
        if (!dateValues[0]) {
          return;
        }

        const startDate = dateValues[0]?.format("YYYY-MM-DDTHH:mm:ss.SSS");
        const endDate = dateValues[1]?.format("YYYY-MM-DDTHH:mm:ss.SSS");

        let valuesString = startDate ?? "";
        if (endDate) {
          valuesString += `;${endDate}`;
        }

        let updatedValues: Array<string> | string = [valuesString];
        if (isBlankEntriesChecked) {
          updatedValues = [valuesString, blankValue];
        }

        const updateCalls = buildFilterUpdateCalls(
          updatedValues,
          metadata,
          overviewType
        );

        updateCalls.forEach((uc) => {
          dispatch(updateFilterArray(uc));
        });
      } else {
        dispatch(
          updateFilterArray({
            key: metadata?.filterAble!.filterKey,
            delimiter: metadata?.filterAble!.delimiter,
            value: isBlankEntriesChecked ? blankValue : "",
            overviewType: overviewType,
          })
        );
      }
    },
    [dispatch, metadata, overviewType, isBlankEntriesChecked, blankValue]
  );

  const rangeDates: RangePickerProps["value"] = useMemo(() => {
    if (!values?.length) {
      return undefined;
    }

    let dateValue: string | undefined = values[0];
    if (isBlankEntriesChecked) {
      const valueWithoutBlankEntries = values?.filter(
        (value) => value !== blankValue
      );
      dateValue = valueWithoutBlankEntries?.length
        ? valueWithoutBlankEntries[0]
        : undefined;
    }

    if (!dateValue) {
      return undefined;
    }

    let startDate: Date;
    let endDate: Date | null = null;

    const dateSplitted = dateValue?.split(DATES_FILTER_STRING_SEPARATOR);
    startDate = new Date(dateSplitted[0]);

    if (dateSplitted?.length === 2) {
      endDate = new Date(dateSplitted[1]);
    }

    return [dayjs(startDate), endDate && dayjs(endDate)];
  }, [values, isBlankEntriesChecked, blankValue]);

  return {
    onChange,
    rangeDates,
    ref,
    open,
    setOpen,
  };
};

export default useDateFilterItem;

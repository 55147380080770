import React, { FC } from "react";
import { TextLayerItem } from "../../interfaces/textLayer";
import Selection from "./selection/Selection";
import TextLayer from "./textLayer/TextLayer";
import AreaLayer from "./areaLayer/AreaLayer";
import TableBuilder from "./tableBuilder/TableBuilder";
import usePage from "../../hooks/usePage";

interface Props {
  pageNumber: number;
  shouldRender: boolean;
  page: any;
  scale: number;
  initialTextLayer?: Array<TextLayerItem>;
  createTable: boolean;
}

const Page: FC<Props> = ({
  pageNumber,
  shouldRender,
  page,
  scale,
  initialTextLayer,
  createTable,
}) => {
  const {
    containerRef,
    canvasRef,
    pageViewport,
    pageNumberRef,
    tableZIndex,
    context,
    inView,
    textLayer,
  } = usePage(pageNumber, page, scale, createTable, initialTextLayer);

  return (
    <div className="page" ref={containerRef}>
      <div
        className="page__container"
        style={{
          width: `${pageViewport.width}px`,
          height: `${pageViewport.height}px`,
        }}
      >
        <div className="page__number_item" ref={pageNumberRef} />
        <TableBuilder
          pageNumber={pageNumber}
          isCreatingTable={createTable}
          zIndex={tableZIndex}
          style={{
            width: `${pageViewport.width}px`,
            height: `${pageViewport.height}px`,
          }}
        />
        <div
          className="page__canvas-container"
          style={{
            width: `${pageViewport.width}px`,
            height: `${pageViewport.height}px`,
          }}
        >
          <canvas
            ref={canvasRef}
            style={{
              width: `${pageViewport.width}px`,
              height: `${pageViewport.height}px`,
            }}
          />
        </div>
        <Selection
          pageNumber={pageNumber}
          className="page__text-layer-container"
          style={{
            width: `${pageViewport.width}px`,
            height: `${pageViewport.height}px`,
          }}
          pdfInformation={{
            width: pageViewport.width,
            height: pageViewport.height,
            scale,
          }}
          pdfContext={context!}
          createTable={createTable}
        >
          <TextLayer
            inView={inView}
            shouldRender={shouldRender}
            canvasInitialized={!!canvasRef}
            textLayer={textLayer}
            pageNumber={pageNumber}
          />
          <AreaLayer pdfScale={scale} pageNumber={pageNumber} />
        </Selection>
      </div>
    </div>
  );
};

export default Page;

import React, { FC, ReactNode } from "react";
import LineSkeletonLoader from "../LineSkeletonLoader";
import { SkeletonLoaderType } from "../interfaces/type";
import AvatarSkeletonLoader from "../AvatarSkeletonLoader";

type Props = {
  loading: boolean;
  type?: SkeletonLoaderType;
  children: ReactNode;
};

const TableColumnContentLoader: FC<Props> = ({
  loading,
  type = SkeletonLoaderType.LINE,
  children,
}) => {
  if (loading) {
    if (type === SkeletonLoaderType.LINE) {
      return <LineSkeletonLoader />;
    } else {
      return <AvatarSkeletonLoader />;
    }
  }

  return <>{children}</>;
};

export default TableColumnContentLoader;

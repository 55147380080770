import { useMemo } from "react";
import { GroupBlockData } from "../../configMap";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import useGroupBlock from "../../configMap/hooks/useGroupBlock";

const useHeaderActions = (groupBlock: GroupBlockData) => {
  const {
    i18n: { language },
  } = useTranslation();

  const groupBlockName = useMemo(() => {
    return getInLanguageOrDefault(groupBlock.translations, language);
  }, [groupBlock.translations, language]);

  const { activeDocument } = useActiveDocument();

  const { removeGroupBlocksForEntityAndAnnotations } = useGroupBlock(
    true,
    groupBlock,
    activeDocument
  );

  return {
    groupBlockName,
    removeGroupBlocksForEntityAndAnnotations,
  };
};

export default useHeaderActions;

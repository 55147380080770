import { KeyboardEvent } from "react";

export const handleKeyEvent = (
  keyEvent: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const { key } = keyEvent;

  if (key.toUpperCase() === "ENTER") {
    keyEvent.preventDefault();
    return;
  }
};

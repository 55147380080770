import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import usePasswordValidation from "./usePasswordValidation";
import { AxiosError } from "axios";
import { useUpdatePasswordMutation } from "../store/userApi";

type PasswordState = {
  newPassword: string;
  repeatPassword: string;
};

const defaultState = {
  newPassword: "",
  repeatPassword: "",
};

const usePassword = () => {
  const { user } = useAppSelector((state) => state.userReducer);

  const { t } = useTranslation("personalPage", {
    keyPrefix: "password",
  });

  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState<PasswordState>(defaultState);
  const [validation, setValidation] = useState<string | undefined>("error");

  const { isValidPassword } = usePasswordValidation(state.newPassword);

  const [updatePassword] = useUpdatePasswordMutation();

  useEffect(() => {
    if (!state.repeatPassword) {
      return;
    }

    if (!isValidPassword) {
      setValidation("error");
      return;
    }

    if (state.repeatPassword !== state.newPassword) {
      setValidation("notMatching");
      return;
    }

    setValidation(undefined);
  }, [isValidPassword, state]);

  const handleEditingClick = useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setState((oldState) => ({
      ...oldState,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handlePasswordUpdate = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const body = {
        password: state.newPassword,
      };

      await updatePassword({ userId: user.id, data: body }).unwrap();
      setIsEditing(false);
      notification.success({
        message: t("success.title"),
        description: t("success.description"),
      });
    } catch (e) {
      const error = e as AxiosError;

      console.error(e);
      notification.error({
        message: t("error.title"),
        description: error?.message || t("error.description"),
      });
    }
  }, [user, state, t, updatePassword]);

  const handleCancelClick = useCallback(() => {
    setState(defaultState);
    setIsEditing(false);
  }, []);

  return {
    state,
    validation,
    isEditing,
    handleChange,
    handlePasswordUpdate,
    handleEditingClick,
    handleCancelClick,
  };
};

export default usePassword;

import React, { FC } from "react";
import useTour from "../hooks/useTour";
import { Tour } from "antd";
import "./OnboardingTour.scss";

const OnboardingTour: FC = () => {
  const { steps, run, stopTourRunAndUpdateUserSettings } = useTour();

  return (
    <Tour
      open={run}
      onClose={stopTourRunAndUpdateUserSettings}
      steps={steps}
      onFinish={stopTourRunAndUpdateUserSettings}
    />
  );
};

export default OnboardingTour;

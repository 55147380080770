import { useAuth0thenticate } from "../index";
import { useIdleTimer } from "react-idle-timer";
import { useCallback, useEffect, useRef } from "react";
import { minutesToMilliseconds } from "../../common/utilities/number";
import useIsAuthenticated from "./useIsAuthenticated";

// delay * minutes * milliseconds
const INTERVAL = minutesToMilliseconds(30);

const useAuth0IdleTimer = () => {
  const timeOut = useRef<number | null>(null);
  const { handleLogout } = useAuth0thenticate(true);
  const { isAuthenticated } = useIsAuthenticated();

  const handleInactive = useCallback(() => {
    if (!timeOut.current) {
      return;
    }

    // console.log(
    //   `%c[INFO] [${dayjs().format(
    //     "HH:mm:ss"
    //   )}] User was idle for 30 minutes and will be logged out`,
    //   "background: #1890ff; color: #fff"
    // );

    window.clearTimeout(timeOut.current);
    timeOut.current = null;
    handleLogout();
  }, [handleLogout]);

  const onIdle = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }

    const timeoutId = window.setTimeout(handleInactive, INTERVAL);
    timeOut.current = timeoutId;
    localStorage.setItem("timeoutId", timeoutId.toString());

    // console.log(
    //   `%c[INFO] [${dayjs().format("HH:mm:ss")}] User is now idle`,
    //   "background: #1890ff; color: #fff"
    // );
  }, [isAuthenticated, handleInactive]);

  const onActive = useCallback(() => {
    if (!isAuthenticated || !timeOut.current) {
      return;
    }

    window.clearTimeout(timeOut.current);
    timeOut.current = null;
    localStorage.removeItem("timeoutId");
    // console.log(
    //   `%c[INFO] [${dayjs().format("HH:mm:ss")}] User is active again`,
    //   "background: #1890ff; color: #fff"
    // );
  }, [isAuthenticated]);

  const handleStorageChange = useCallback(() => {
    const timeoutId = localStorage.getItem("timeoutId");

    if (timeoutId) {
      return;
    }

    if (timeOut.current) {
      window.clearTimeout(timeOut.current);
      timeOut.current = null;

      // console.log(
      //   `%c[INFO] [${dayjs().format(
      //     "HH:mm:ss"
      //   )}] User is active in another tab`,
      //   "background: #1890ff; color: #fff"
      // );
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [handleStorageChange]);

  useIdleTimer({
    timeout: 10000,
    onIdle,
    onActive,
  });
};

export default useAuth0IdleTimer;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { useTranslation } from "react-i18next";
import selectModalStateByKey from "../../app/selectors/modalStateSelector";
import { changeModalStateForKey } from "../../app/store/appSlice";
import { Invite } from "../interfaces/table";
import { notification } from "antd";
import {
  useDeleteInviteMutation,
  useLazyFetchInvitesQuery,
} from "../store/UserManagementApi";
import { ADMIN_ROLE_ID } from "../../common/utilities/env";

const modalKey = "userManagementInvites";
const SKELETON_LOAD_ROWS = 2;

const useOrganizationInvites = (fetch: boolean = true) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation("userManagement");

  const open = useAppSelector((state) =>
    selectModalStateByKey(state, { modalKey })
  );

  const { user } = useAppSelector((state) => state.userReducer);
  const { userSettings } = useAppSelector((state) => state.userReducer);

  const [loading, setLoading] = useState(false);
  const [loadingDeleteInvite, setLoadingDeleteInvite] = useState(false);
  const [invites, setInvites] = useState<Array<Invite>>([]);
  const [triggerLoadInvites, setTriggerLoadInvites] = useState<boolean>(false);

  const [fetchInvites] = useLazyFetchInvitesQuery();

  const [deleteInvite] = useDeleteInviteMutation();

  const changeModalOpen = useCallback(() => {
    dispatch(changeModalStateForKey(modalKey));
  }, [dispatch]);

  const handleDeleteInvite = useCallback(
    async (inviteId: string, email: string) => {
      if (!user?.access.includes(ADMIN_ROLE_ID) || loadingDeleteInvite) {
        return;
      }

      if (!user?.organization.id) {
        return;
      }

      try {
        setLoadingDeleteInvite(true);

        await deleteInvite({
          organizationId: user.organization.id,
          inviteId,
          email,
        }).unwrap();
        setTriggerLoadInvites((trigger) => !trigger);
      } catch (e) {
        dispatch(changeModalStateForKey(modalKey));
        notification.error({
          message: t("deleteInvite.errorMessage.message"),
          description: t("deleteInvite.errorMessage.description"),
        });
        console.log(e);
      } finally {
        setLoadingDeleteInvite(false);
      }
    },
    [user, t, dispatch, loadingDeleteInvite, deleteInvite]
  );

  const loadInvites = useCallback(
    async (signal: AbortSignal) => {
      if (!user?.access.includes(ADMIN_ROLE_ID) || !user?.organization.id) {
        return;
      }

      try {
        setLoading(true);
        const data = await fetchInvites(user.organization.id).unwrap();
        setInvites(data);
      } catch (e) {
        if (signal.aborted) {
          return;
        }

        console.log(e);
        notification.error({
          message: t("invites.errorMessage.message"),
          description: t("invites.errorMessage.description"),
        });
      } finally {
        setLoading(false);
      }
    },
    [user, t, fetchInvites]
  );

  const data: Invite[] = useMemo(() => {
    if (loading) {
      return [...Array(SKELETON_LOAD_ROWS)].map((_, index) => ({
        id: index.toString(),
      })) as any;
    }

    return invites;
  }, [invites, loading]);

  useEffect(() => {
    if (!open || !fetch) {
      return;
    }

    const controller = new AbortController();

    loadInvites(controller.signal);

    return () => {
      controller.abort();
    };
  }, [loadInvites, open, fetch, triggerLoadInvites]);

  return {
    open,
    changeModalOpen,
    loading,
    data,
    userSettings,
    handleDeleteInvite,
  };
};

export default useOrganizationInvites;

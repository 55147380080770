import React, { FC } from "react";
import { Document } from "../../../../../document";
import "./DocumentPill.scss";
import { Popover } from "antd";
import DocumentPillHoverAction from "./DocumentPillHoverAction";
import DocumentIconMapper from "./DocumentIconMapper";

type Props = {
  documents: Array<Document>;
};

const DocumentItems: FC<Props> = ({ documents }) => {
  return (
    <ul className="document-pill__container list-container">
      <h5>Other documents:</h5>
      {documents.map((document) => (
        <li key={document.id}>
          <div className="document-pill">
            <DocumentIconMapper documentName={document.name} />
            <label className="document-pill__label">{document.name}</label>
          </div>
        </li>
      ))}
    </ul>
  );
};

const DocumentPill: FC<Props> = ({ documents }) => {
  if (!documents?.length) {
    return null;
  }

  if (documents?.length === 1) {
    return (
      <div className="document-pill__container">
        <DocumentPillHoverAction document={documents[0]}>
          <div className="document-pill">
            <DocumentIconMapper documentName={documents[0].name} />
            <label className="document-pill__label">{documents[0].name}</label>
          </div>
        </DocumentPillHoverAction>
      </div>
    );
  }

  return (
    <div className="document-pill__container">
      <DocumentPillHoverAction document={documents[0]}>
        <div className="document-pill">
          <DocumentIconMapper documentName={documents[0].name} />
          <label className="document-pill__label">{documents[0].name}</label>
        </div>
      </DocumentPillHoverAction>
      <Popover
        placement="rightTop"
        trigger="click"
        content={<DocumentItems documents={documents.slice(1)} />}
      >
        <div
          className="document-pill"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <label className="document-pill__label-overflow">
            +{documents?.length - 1}
          </label>
        </div>
      </Popover>
    </div>
  );
};

export default DocumentPill;

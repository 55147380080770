import {
  DocumentCategoryAnnotation,
  DocumentCategoryAnnotationRemove,
} from "../../configMap/interfaces/category";

export const equals = (
  obj1: DocumentCategoryAnnotation,
  obj2: DocumentCategoryAnnotation | DocumentCategoryAnnotationRemove
) => {
  return (
    obj1.documentId === obj2.documentId &&
    obj1.categorizationId === obj2.categorizationId &&
    obj1.groupBlockId === obj2.groupBlockId &&
    obj1.index === obj2.index
  );
};

import { useAppSelector } from "../../app";
import { useMemo } from "react";
import { ICategorization } from "../../configMap/interfaces/category";
import { useTranslation } from "react-i18next";
import selectCategorizations from "../selectors/selectCategorizations";
import { getInLanguageOrDefault } from "../../common/utilities/language";

const useLinkIcon = (
  categorization: ICategorization,
  isTableControls?: boolean
) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("annotationSideControls", {
    keyPrefix: "linkedParentCategorization",
  });

  const categorizations = useAppSelector((state) =>
    selectCategorizations(state, { withConfig: false })
  ) as Array<ICategorization>;

  const parentCategorization = categorization.search.parentCategorization;

  const linkedChildLabels = useMemo(() => {
    if (!categorizations) {
      return null;
    }

    const linkedCategorizations = categorizations.filter(
      (c) => c.search.parentCategorization?.id === categorization.id
    );
    if (!linkedCategorizations?.length) {
      return null;
    }

    return linkedCategorizations.map((lc) =>
      getInLanguageOrDefault(lc.label, language)
    );
  }, [categorizations, categorization, language]);

  const linkedParentLabel = useMemo(() => {
    if (!parentCategorization) {
      return null;
    }

    const parent = categorizations.find(
      (c) => c.id === parentCategorization.id
    );
    if (!parent) {
      return null;
    }

    return getInLanguageOrDefault(parent.label, language);
  }, [parentCategorization, categorizations, language]);

  const label = useMemo(() => {
    return getInLanguageOrDefault(categorization.label, language);
  }, [categorization.label, language]);

  const tooltip = useMemo(() => {
    if (!linkedChildLabels?.length && !linkedParentLabel) {
      return null;
    }

    if (linkedChildLabels?.length) {
      const childLabels = linkedChildLabels.join(", ");
      return t("parentLinkIcon")
        .replace("$PARENT", label.toUpperCase())
        .replace("$CHILD", childLabels.toUpperCase());
    }

    return t("childLinkIcon")
      .replace("$PARENT", linkedParentLabel?.toUpperCase() ?? "")
      .replace("$CHILD", label.toUpperCase());
  }, [linkedParentLabel, label, linkedChildLabels, t]);

  const classNames = useMemo(() => {
    if (!isTableControls) {
      return "link-icon";
    }

    return "link-icon margin-right";
  }, [isTableControls]);

  return {
    tooltip,
    classNames,
  };
};

export default useLinkIcon;

import { Rectangle } from "../interfaces/textLayer";

export const calculateTransform = (
  canvasWidth: number,
  fontSize: number,
  fontFamily: string,
  text: string,
  context: CanvasRenderingContext2D
): number => {
  let transform = 1;

  if (canvasWidth) {
    context.font = `${fontSize}px ${fontFamily}`;

    const { width } = context.measureText(text);

    transform = canvasWidth / width;
  }
  return transform;
};

export const calculateFontSize = (
  width: number,
  height: number,
  text: string
): number => {
  const area = width * height;
  const { length } = text;

  return Math.sqrt(area / length) * 1.3333;
};

export const recalculateBoundingBox = (
  coordinates: Rectangle,
  oldScale: number,
  newScale: number
): Rectangle => {
  return {
    left: (coordinates.left / oldScale) * newScale,
    top: (coordinates.top / oldScale) * newScale,
    width: (coordinates.width / oldScale) * newScale,
    height: (coordinates.height / oldScale) * newScale,
  };
};

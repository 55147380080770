import React from "react";
import InputFilterItem from "./InputFilterItem";
import FilterableItem from "./FilterableItem";

const FilterableItemSwitch = (props: any) => {
  if (props.options) {
    return <FilterableItem {...props} />;
  }

  return <InputFilterItem {...props} />;
};

export default FilterableItemSwitch;

import * as Sentry from "@sentry/react";
import { getVariableFromEnv } from "./common/utilities/env";
import { BrowserTracing, Replay } from "@sentry/react";

const environment = getVariableFromEnv("ENVIRONMENT", "development");
const version = getVariableFromEnv("VERSION");
const dsn = getVariableFromEnv("SENTRY_DSN");

try {
  Sentry.init({
    dsn,
    environment,
    release: version,
    integrations: [new BrowserTracing(), new Replay()],
    tracesSampleRate: environment === "production" ? 0.1 : 0.05,
    normalizeDepth: 4,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment === "development" ? 0 : 1,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
    ],
  });
} catch (e) {
  console.error("failed to setup Sentry", e);
}

export default Sentry;

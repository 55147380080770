import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  setMetricsDatePeriod,
  updateMetricsDates,
} from "../../common/dataRefining/store/dataRefiningSlice";
import { RadioChangeEvent } from "antd";
import { getRangeDateByPeriod } from "../utils/rangePicker";
import { triggerRefreshMetrics } from "../store/analyticsSlice";

const useDatePeriods = () => {
  const { activePeriod } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const dispatch = useAppDispatch();

  const onChangePeriod = useCallback(
    (event: RadioChangeEvent) => {
      dispatch(setMetricsDatePeriod(event.target.value));

      const rangeDates = getRangeDateByPeriod(event.target.value);
      dispatch(
        updateMetricsDates({
          startDate: rangeDates.startDate.toISOString(),
          endDate: rangeDates.endDate.toISOString(),
        })
      );
      dispatch(triggerRefreshMetrics());
    },
    [dispatch]
  );

  return {
    activePeriod,
    onChangePeriod,
  };
};

export default useDatePeriods;

import React, { FC } from "react";
import useHeaderText from "../../hooks/useHeaderText";
import { Layout } from "antd";
import "./HeaderWithTitle.scss";
import TextWithMaxCharacters from "./TextWithMaxCharacters";
import GoBackRoute from "./GoBackRoute";

const HeaderWithTitle: FC = () => {
  const { headerTitle, showBackButton, pathname } = useHeaderText();

  return (
    <Layout.Header className="header-with-title__container">
      {showBackButton && <GoBackRoute pathName={pathname} />}

      <div className="header-with-title__title">
        <TextWithMaxCharacters text={headerTitle} maxLength={40} />
      </div>
    </Layout.Header>
  );
};

export default HeaderWithTitle;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { getHeadersWithAuthorization } from "../../common/reduxQuery/helpers";
import { AdjacentDocumentSetIds } from "../../documentSet/interfaces/documentSet";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const annotationSideControlsApi = createApi({
  reducerPath: "annotationSideControlsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    sendEmailReply: build.mutation<
      void,
      {
        documentSetId: string;
        replyText: string;
      }
    >({
      query: (data) => ({
        url: `api/v1/mail/reply`,
        body: data,
        method: "POST",
      }),
    }),
    getAdjacentDocumentSetIds: build.query<
      AdjacentDocumentSetIds,
      {
        documentSetId: string;
        page: any;
        sort: any;
        filter: any;
      }
    >({
      query: ({ documentSetId, page, sort, filter }) => {
        const parsedSort = sort ? `&sort=${encodeURIComponent(sort)}` : "";

        return {
          url: `api/v1/document-sets/${documentSetId}/adjacent-ids?page=${page}${parsedSort}&filter=${encodeURIComponent(
            filter
          )}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useSendEmailReplyMutation,
  useLazyGetAdjacentDocumentSetIdsQuery,
} = annotationSideControlsApi;

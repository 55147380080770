import React, { FC } from "react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";
import { HEIGHT_REMOVED_PAGE_ALERT } from "../../constants";
import { useAppSelector } from "../../../app";
import selectUserInputIds from "../../../user/selectors/userInputIdsSelector";

const NoSourcesAlert: FC = () => {
  const { t } = useTranslation("overview", {
    keyPrefix: "warning",
  });

  const userInputIds = useAppSelector(selectUserInputIds);

  if (!userInputIds.length) {
    return (
      <Alert
        style={{ height: `${HEIGHT_REMOVED_PAGE_ALERT}px` }}
        message={t("noUserSources")}
        type="warning"
        showIcon
      />
    );
  }

  return null;
};

export default NoSourcesAlert;

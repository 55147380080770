import React, { FC } from "react";
import { Status } from "../../../common/status/status";
import ExportFailedAlert from "./exportFailed/ExportFailedAlert";
import ErrorAlert from "./ErrorAlert";

type Props = {
  status?: Status;
  setHeight: (height: number) => void;
};

const AlertRenderer: FC<Props> = ({ status, setHeight }) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case Status.Error:
      return <ErrorAlert setHeight={setHeight} />;
    case Status.ExportFailed:
      return <ExportFailedAlert setHeight={setHeight} />;
    default:
      return null;
  }
};

export default AlertRenderer;

import http from "../utilities/HttpModule";

export const getHeadersWithAuthorization = (headers: Headers) => {
  const token = http.axios.defaults.headers.common.Authorization;
  if (token) {
    headers.set("Authorization", token as string);
  }
  return headers;
};

export const parseReduxQueryError = (err: any) => {
  let errorMessage;
  if ("error" in err) {
    errorMessage = err.error;
  } else {
    errorMessage = err;
  }

  return JSON.stringify(errorMessage);
};

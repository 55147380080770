import { useCallback, useMemo } from "react";
import { OverviewType, useAppDispatch } from "../../app";
import { DEFAULT_BLANK_VALUE } from "../constants";
import { buildFilterUpdateCalls } from "../utils/columnItemMetaDataHelpers";
import {
  FilterArrayAction,
  updateFilterArray,
} from "../../common/dataRefining/store/dataRefiningSlice";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ColumnItem } from "../interfaces/overview";

const useShowBlankEntriesCheckbox = (
  columnItem: ColumnItem,
  overviewType: OverviewType,
  values: Array<string> | string | undefined
) => {
  const dispatch = useAppDispatch();

  const { metadata } = columnItem;

  const {
    enabled: showBlankEntriesCheckbox = false,
    blankValue: newBlankValue = null,
    blankValueLabel = null,
  } = metadata?.showBlankEntriesCheckbox || {};

  const blankValue = useMemo(() => {
    return newBlankValue ?? DEFAULT_BLANK_VALUE;
  }, [newBlankValue]);

  const valuesArray: Array<string> = useMemo(() => {
    if (Array.isArray(values)) {
      return values;
    }

    return values ? [values] : [];
  }, [values]);

  const handleChangeBlankEntries = useCallback(
    (event: CheckboxChangeEvent) => {
      let updateCalls: Array<FilterArrayAction>;

      if (event.target.checked) {
        const updatedValues = valuesArray.length
          ? [...valuesArray, blankValue]
          : [blankValue];
        updateCalls = buildFilterUpdateCalls(
          updatedValues,
          metadata,
          overviewType
        );
      } else {
        const updatedValues = valuesArray.length
          ? valuesArray.filter((value) => value !== blankValue)
          : [];
        updateCalls = buildFilterUpdateCalls(
          updatedValues,
          metadata,
          overviewType
        );
      }

      updateCalls.forEach((uc) => {
        dispatch(updateFilterArray(uc));
      });
    },
    [dispatch, metadata, overviewType, blankValue, valuesArray]
  );

  const isBlankEntriesChecked = useMemo(() => {
    return showBlankEntriesCheckbox && valuesArray.includes(blankValue);
  }, [valuesArray, showBlankEntriesCheckbox, blankValue]);

  const labelCheckbox = useMemo(() => {
    const label = blankValueLabel ?? newBlankValue;
    return `Show only ${label ?? "blank"} entries`;
  }, [newBlankValue, blankValueLabel]);

  return {
    isBlankEntriesChecked,
    handleChangeBlankEntries,
    showBlankEntriesCheckbox,
    blankValue,
    labelCheckbox,
  };
};

export default useShowBlankEntriesCheckbox;

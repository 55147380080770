import React, { FC } from "react";
import { DatePicker } from "antd";
import useRangeDatePicker from "../../../hooks/useRangeDatePicker";
import { useTranslation } from "react-i18next";
import DatePeriods from "./DatePeriods";

const { RangePicker } = DatePicker;

type Props = {
  loading: boolean;
  startDate: Date;
  endDate: Date;
};

const RangeDatePicker: FC<Props> = ({ loading, startDate, endDate }) => {
  const { onChange, rangeDates } = useRangeDatePicker(startDate, endDate);

  const { t } = useTranslation("analytics");

  return (
    <div className="filter-item__container">
      <div className="filter-item-periods__container">
        <label className="filter-item-periods__label">
          {t("filterHeader.period")}
        </label>
        <DatePeriods loading={loading} />
      </div>
      <RangePicker
        className="filter-item range-picker"
        format="DD/MM/YYYY"
        value={rangeDates}
        allowClear={false}
        onChange={onChange}
        disabled={loading}
        allowEmpty={[false, false]}
      />
    </div>
  );
};

export default RangeDatePicker;

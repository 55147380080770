import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { NumberMetrics } from "../interfaces/documentSetMetrics";

interface ICalculatedMetricsResponse {
  organizationMetrics: NumberMetrics;
  sourceMetrics: Array<NumberMetrics>;
}

const getMetrics = (state: RootState) =>
  state.analyticsReducer.metrics.metricsData;

const calculateMetricsSelector = createSelector(
  [getMetrics],
  (metrics): ICalculatedMetricsResponse => {
    if (!metrics?.organizationMetrics || !metrics?.sourcesMetrics) {
      return {
        organizationMetrics: {
          volume: {
            value: 0,
            previousValue: 0,
            trend: 0,
          },
          avgTimeSpend: {
            value: 0,
            previousValue: 0,
            trend: 0,
          },
          automatic: {
            value: 0,
            previousValue: 0,
            trend: 0,
          },
          manual: {
            value: 0,
            previousValue: 0,
            trend: 0,
          },
          rejected: {
            value: 0,
            previousValue: 0,
            trend: 0,
          },
          source: "Organization",
        },
        sourceMetrics: [],
      };
    }

    return {
      organizationMetrics: metrics.organizationMetrics,
      sourceMetrics: metrics.sourcesMetrics,
    };
  }
);

export default calculateMetricsSelector;

import React, {
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { Select } from "antd";
import { BaseSelectRef } from "rc-select";
import useFocus from "../../documentSet/hooks/useFocus";
import { Option } from "../dataRefining/interfaces/filteringData";
import DropdownActions from "./dropdownActions/DropdownActions";

type Props = {
  label: ReactNode;
  isOpen: boolean;
  value: any;
  prop: any;
  updateFilter: (prop: any, value: any) => void;
  renderRef: MutableRefObject<any>;
  options: Array<Option>;
};

const FilterableItem: FC<Props> = ({
  label,
  isOpen,
  value,
  prop,
  updateFilter,
  renderRef,
  options,
}) => {
  const ref: MutableRefObject<null | BaseSelectRef> = useRef(null);
  const { open, setOpen } = useFocus(ref, isOpen);

  const handleChange = useCallback(
    (value: any) => {
      updateFilter(prop, value);
    },
    [updateFilter, prop]
  );

  return (
    <div className="filter-popover__item">
      {label}
      <Select
        ref={ref}
        mode={"multiple"}
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        value={value}
        options={options}
        placeholder={"Search"}
        onChange={handleChange}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        getPopupContainer={() => renderRef.current}
        allowClear
        dropdownRender={(menu) => (
          <DropdownActions handleOkClick={() => setOpen(false)}>
            {menu}
          </DropdownActions>
        )}
      />
    </div>
  );
};

export default FilterableItem;

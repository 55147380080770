import React, { FC, useMemo } from "react";
import useControls from "../hooks/useControls";

const Controls: FC = () => {
  const {
    scale,
    handleScaleChange,
    incrementScale,
    createTable,
    currentPage,
    handlePageChange,
    pages,
    handleUndoOrRedoClick,
    undoDisabled,
    redoDisabled,
    readonly,
    handleCreateTableClick,
  } = useControls();

  const createTableClassName = useMemo(() => {
    if (readonly) {
      return "disabled";
    }

    if (createTable) {
      return "active";
    }
    return "";
  }, [readonly, createTable]);

  return (
    <div className="controls__container">
      <div className="controls__page-container">
        <label>Page:</label>
        <input
          disabled
          type="text"
          value={currentPage}
          onChange={(event) => handlePageChange(Number(event.target.value))}
        />
        <label>/ {pages}</label>
      </div>
      <span className="controls__divider" />
      <div className="controls__size-container">
        <span
          role="button"
          className="controls__button"
          onClick={() => incrementScale(-0.1)}
        >
          <i className="bi-dash-lg controls__button-icon" />
        </span>
        <input
          type="number"
          value={scale}
          onChange={(event) => handleScaleChange(Number(event.target.value))}
        />
        <span
          role="button"
          className="controls__button"
          onClick={() => incrementScale(0.1)}
        >
          <i className="bi-plus-lg controls__button-icon" />
        </span>
      </div>
      <span className="controls__divider" />
      <div className="controls__table-container">
        <span
          role="button"
          className={`controls__button ${
            undoDisabled ? "disabled" : ""
          }`.trim()}
          onClick={() => handleUndoOrRedoClick(true)}
        >
          <i className="bi bi-arrow-counterclockwise controls__button-icon" />
        </span>
        <span
          role="button"
          className={`controls__button ${createTableClassName}`.trim()}
          style={
            createTable
              ? { backgroundColor: "#69c0ff" }
              : { backgroundColor: "#323639" }
          }
          onClick={handleCreateTableClick}
        >
          <i className="bi bi-table controls__button-icon" />
        </span>
        <span
          role="button"
          className={`controls__button ${
            redoDisabled ? "disabled" : ""
          }`.trim()}
          onClick={() => handleUndoOrRedoClick(false)}
        >
          <i className="bi bi-arrow-clockwise controls__button-icon" />
        </span>
      </div>
    </div>
  );
};

export default Controls;

import { Organization, OrganizationImageMap } from "../interfaces/organization";

const organizationImageMap: OrganizationImageMap = {
  [Organization.RAVAGO]: "companyLogos/ravagoLogo.png",
  [Organization.ATLAS_COPCO]: "companyLogos/atlasCopcoLogo.svg",
  [Organization.EDMAC]: "companyLogos/edmacLogo.png",
  [Organization.FLEXSO]: "companyLogos/flexsoLogo.png",
  [Organization.VANDERSANDEN]: "companyLogos/vandersandenLogo.png",
  [Organization.ARCELORMITTAL]: "companyLogos/arcelormittalLogo.png",
  [Organization.DEFAULT]: "klassifaiWhiteIcon.svg",
};

export const mapOrganizationToImage = (organization: string): string => {
  if (organization.includes("ravago")) {
    return organizationImageMap[Organization.RAVAGO];
  } else if (organization.includes("atlas-copco")) {
    return organizationImageMap[Organization.ATLAS_COPCO];
  } else if (organization === "arcelormittal") {
    return organizationImageMap[Organization.ARCELORMITTAL];
  } else if (organization === "vandersanden") {
    return organizationImageMap[Organization.VANDERSANDEN];
  } else if (organization === "flexso") {
    return organizationImageMap[Organization.FLEXSO];
  } else if (organization === "edmac") {
    return organizationImageMap[Organization.EDMAC];
  } else {
    return organizationImageMap[Organization.DEFAULT];
  }
};

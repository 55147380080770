import { useAppDispatch, useAppSelector } from "../../app";
import { annotationsForEntitySelector } from "../index";
import { useCallback, useMemo } from "react";
import {
  addAnnotationToState,
  removeAllAnnotationsForDocument,
  removeAnnotationFromState,
  removeAnnotationsForEntitiesByGroupBlockIndex,
  removeAnnotationsForEntity,
  updateAnnotationInState,
  updateAnnotationsBatch,
  updateGroupBlockAnnotations,
} from "../store/annotationSlice";
import { changeHasBeenEdited } from "../../app/store/appSlice";
import { Annotation } from "../../annotator/interfaces/annotation";
import { GroupBlockEntityType, useMultipleGroupBlocks } from "../../configMap";
import { getAnnotationForId } from "../utils/utils";
import { incrementOperations } from "../../analytics/store/analyticsSlice";
import { UpdateMultiGroupBlockValuePayload } from "../interfaces/annotation";
import { throttle } from "../../common/utilities/func";
import { Status } from "../../common/status/status";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import useAnnotationNormalization from "../../configMap/hooks/useAnnotationNormalization";

type Props = {
  documentId?: string;
  entityId?: string;
  groupBlockIndex?: number;
};

const useAnnotations = ({ documentId, entityId, groupBlockIndex }: Props) => {
  const dispatch = useAppDispatch();
  const {
    setInitialMultipleGroupBlocksCount,
    hasMultipleAnnotationsInGroupBlock,
  } = useMultipleGroupBlocks();

  const { normalizeIfAnnotationNeedsToBeNormalized } =
    useAnnotationNormalization();

  const configMap = useAppSelector((state) => state.configMapReducer.configMap);
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const { tableControlAnnotations, sideControlAnnotations } = useAppSelector(
    (state) => state.annotationReducer
  );

  const documentCategoryAnnotations = useAppSelector(
    (state) =>
      state.metadataReducer.categorizationState.documentCategoryAnnotations
  );

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const annotationsForEntity = useAppSelector((state) =>
    annotationsForEntitySelector(state, {
      documentId,
      entityId,
      groupBlockIndex,
    })
  );

  const { t } = useTranslation("annotations");

  const annotations = useMemo<Array<Annotation>>(() => {
    if (!tableControlAnnotations && sideControlAnnotations) {
      return [];
    }

    return tableControlAnnotations.concat(sideControlAnnotations);
  }, [tableControlAnnotations, sideControlAnnotations]);

  const annotationsForAnnotator = useMemo((): Array<Annotation> => {
    if (activeDocumentSet?.status === Status.Error) {
      return [];
    }

    if (!documentId) {
      return [];
    }

    return annotations.filter(
      (annotation) => annotation.id && annotation.documentId === documentId
    );
  }, [annotations, activeDocumentSet, documentId]);

  const removeAllAnnotations = useCallback(() => {
    if (!documentId) {
      return;
    }

    dispatch(
      incrementOperations({
        numberOfAdditions: 0,
        numberOfDeletions: annotations.length,
        numberOfUpdates: 0,
      })
    );
    dispatch(removeAllAnnotationsForDocument(documentId.toString()));
    dispatch(changeHasBeenEdited(true));
  }, [documentId, dispatch, annotations]);

  const removeAnnotation = useCallback(
    (
      annotationId: string,
      multipleGroupBlocks: boolean,
      userAction = false
    ) => {
      if (!documentId) {
        return;
      }

      dispatch(
        removeAnnotationFromState({
          documentId,
          annotationId,
          multipleGroupBlocks,
        })
      );
      dispatch(changeHasBeenEdited(true));

      if (userAction) {
        dispatch(
          incrementOperations({
            numberOfAdditions: 0,
            numberOfDeletions: 1,
            numberOfUpdates: 0,
          })
        );
      }
    },
    [dispatch, documentId]
  );

  const updateAnnotation = useCallback(
    (
      annotationId: string,
      newValue: string,
      entity: GroupBlockEntityType,
      userAction: boolean,
      multipleGroupBlocks: boolean
    ) => {
      if (!documentId || !groupBlockIndex) {
        return;
      }

      const annotation = getAnnotationForId(
        annotationId,
        groupBlockIndex,
        annotations,
        entity
      );

      if (!annotation) {
        return;
      }

      dispatch(changeHasBeenEdited(true));

      if (!newValue) {
        removeAnnotation(annotationId, multipleGroupBlocks);

        if (userAction) {
          dispatch(
            incrementOperations({
              numberOfAdditions: 0,
              numberOfDeletions: 1,
              numberOfUpdates: 0,
            })
          );
        }
        return;
      }

      if (!annotationId) {
        dispatch(
          addAnnotationToState({
            documentId,
            annotation: {
              ...annotation,
              id: `temp_${crypto.randomUUID()}`,
              documentId: documentId,
              isByUser: true,
              values: [],
              pageTokenIndices: [],
              entityAnnotationNormalization: {
                normalizedMethod: "manual",
                normalizedValue: newValue,
                options: annotation.entityAnnotationNormalization?.options,
                isByUser: true,
              },
              multipleGroupBlocks,
            },
            multipleGroupBlocks,
          })
        );

        if (userAction) {
          dispatch(
            incrementOperations({
              numberOfAdditions: 1,
              numberOfDeletions: 0,
              numberOfUpdates: 0,
            })
          );
        }
        return;
      }

      dispatch(
        updateAnnotationInState({
          annotation: {
            ...annotation,
            isByUser: true,
          },
          newValue: newValue,
          method: "manual",
          options: annotation.entityAnnotationNormalization?.options,
          multipleGroupBlocks,
          isByUser: true,
        })
      );

      if (userAction) {
        dispatch(
          incrementOperations({
            numberOfAdditions: 0,
            numberOfDeletions: 0,
            numberOfUpdates: 1,
          })
        );
      }
    },
    [documentId, groupBlockIndex, annotations, dispatch, removeAnnotation]
  );

  const updateAnnotations = useCallback(
    (newAnnotations: Array<Annotation>, multipleGroupBlocks: boolean) => {
      if (!documentId) {
        return;
      }

      const newAnnotation = newAnnotations.filter(
        (a) => !annotations.some((b) => JSON.stringify(a) === JSON.stringify(b))
      )[0];

      if (
        hasMultipleAnnotationsInGroupBlock(
          newAnnotation,
          configMap,
          newAnnotations
        ) &&
        !isAnnotationMode
      ) {
        notification.error({
          message: t("multipleForGroupBlockWarning"),
        });
        return;
      }

      dispatch(changeHasBeenEdited(true));
      dispatch(
        updateAnnotationsBatch({
          documentId,
          annotations: newAnnotations,
          multipleGroupBlocks,
        })
      );

      normalizeIfAnnotationNeedsToBeNormalized(newAnnotations);

      if (multipleGroupBlocks) {
        setInitialMultipleGroupBlocksCount(
          configMap!,
          newAnnotations,
          documentCategoryAnnotations
        );
      }

      const diff = annotations.length - newAnnotations.length;

      if (diff <= -1) {
        dispatch(
          incrementOperations({
            numberOfAdditions: Math.abs(diff),
            numberOfDeletions: 0,
            numberOfUpdates: 0,
          })
        );
      } else if (diff >= 1) {
        dispatch(
          incrementOperations({
            numberOfAdditions: 0,
            numberOfDeletions: diff,
            numberOfUpdates: 0,
          })
        );
      }
    },
    [
      documentId,
      annotations,
      configMap,
      setInitialMultipleGroupBlocksCount,
      hasMultipleAnnotationsInGroupBlock,
      normalizeIfAnnotationNeedsToBeNormalized,
      isAnnotationMode,
      t,
      dispatch,
      documentCategoryAnnotations,
    ]
  );

  const clearAnnotationsForEntities = useCallback(
    (
      entityIds: Array<string>,
      groupBlockIndex: number,
      isGroupRemoved: boolean,
      multipleGroupBlocks: boolean
    ) => {
      if (!documentId) {
        return [];
      }

      const annotationsRemoved = annotations.filter(
        (annotation) =>
          entityIds.includes(annotation.entity.id) &&
          annotation.index === groupBlockIndex
      ).length;

      dispatch(
        incrementOperations({
          numberOfAdditions: 0,
          numberOfUpdates: 0,
          numberOfDeletions: annotationsRemoved,
        })
      );
      dispatch(
        removeAnnotationsForEntitiesByGroupBlockIndex({
          documentId,
          groupBlockIndex,
          entityIds,
          isGroupRemoved,
          multipleGroupBlocks,
        })
      );
      dispatch(changeHasBeenEdited(true));
    },
    [documentId, annotations, dispatch]
  );

  // eslint-disable-next-line
  const updateMultiGroupBlockAnnotations = useCallback(
    throttle(
      (
        multipleGroupBlocks: boolean,
        entityIds: Array<string>,
        groupBlockIndex: number,
        documentId: string,
        addBefore: boolean = false
      ) => {
        const payload: UpdateMultiGroupBlockValuePayload = {
          entityIds,
          documentId,
          groupBlockIndex,
          addBefore,
          multipleGroupBlocks,
        };
        if (documentId) {
          dispatch(updateGroupBlockAnnotations(payload));
        }
      },
      250
    ),
    [dispatch]
  );

  const clearAnnotationsForEntity = useCallback(
    (
      entityIds: Array<string>,
      groupBlockIndex: number,
      multipleGroupBlocks: boolean
    ) => {
      if (!documentId) {
        return [];
      }

      dispatch(
        incrementOperations({
          numberOfAdditions: 0,
          numberOfUpdates: 0,
          numberOfDeletions: 1,
        })
      );
      dispatch(
        removeAnnotationsForEntity({
          documentId,
          groupBlockIndex,
          entityIds,
          multipleGroupBlocks,
        })
      );
      dispatch(changeHasBeenEdited(true));
    },
    [documentId, dispatch]
  );

  const isAnnotationLoading = useMemo(() => {
    return (
      annotationsForEntity?.length === 1 && annotationsForEntity[0].isLoading
    );
  }, [annotationsForEntity]);

  return {
    annotationsForEntity,
    annotationsForAnnotator,
    annotations,
    updateAnnotation,
    updateAnnotations,
    removeAnnotation,
    removeAllAnnotations,
    clearAnnotationsForEntities,
    updateMultiGroupBlockAnnotations,
    clearAnnotationsForEntity,
    isAnnotationLoading,
  };
};

export default useAnnotations;

import React, { FC, useMemo, useState } from "react";
import { Modal, Table } from "antd";
import { useTranslation } from "react-i18next";
import useOrganizationInvites from "../../hooks/useOrganizationInvites";
import { ColumnsType } from "antd/es/table";
import { Invite } from "../../interfaces/table";
import dayjs from "dayjs";
import { getLocaleByLanguage } from "../../../common/utilities/locale";
import "./OrganizationInvitesModal.scss";
import TableColumnContentLoader from "../../../common/skeletonLoader/tableColumnContentLoader/TableColumnContentLoader";

const OrganizationInvitesModal: FC = () => {
  const { t } = useTranslation("userManagement");

  const {
    open,
    changeModalOpen,
    data,
    loading,
    userSettings,
    handleDeleteInvite,
  } = useOrganizationInvites();

  const [browserLocale] = useState<string>(
    getLocaleByLanguage(userSettings.language)
  );

  const columns = useMemo(
    (): ColumnsType<Invite> => [
      {
        title: t("email"),
        dataIndex: "email",
        key: "email",
        render: (_, { email }) => (
          <TableColumnContentLoader loading={loading}>
            {email}
          </TableColumnContentLoader>
        ),
      },
      {
        title: t("created"),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_, { createdAt }) => (
          <TableColumnContentLoader loading={loading}>
            <label className="document-name-cell__label">
              {dayjs(createdAt).locale(browserLocale).format("L LT")}
            </label>
          </TableColumnContentLoader>
        ),
      },
      {
        title: t("expires"),
        dataIndex: "expiresAt",
        key: "expiresAt",
        render: (_, { expiresAt }) => (
          <TableColumnContentLoader loading={loading}>
            <label className="document-name-cell__label">
              {dayjs(expiresAt).locale(browserLocale).format("L LT")}
            </label>
          </TableColumnContentLoader>
        ),
      },
      {
        title: null,
        dataIndex: "deleteInvite",
        key: "deleteInvite",
        render: (_, { id, email }) => (
          <TableColumnContentLoader loading={loading}>
            <div
              className="delete-invite-icon"
              onClick={() => handleDeleteInvite(id, email)}
            >
              <i className="bi bi-trash" />
            </div>
          </TableColumnContentLoader>
        ),
      },
    ],
    [browserLocale, t, handleDeleteInvite, loading]
  );

  return (
    <Modal
      title={
        <div className="organization-invites-modal__title">
          <h2>{t("invitesModal.header")}</h2>
        </div>
      }
      centered
      open={open}
      footer={null}
      onCancel={changeModalOpen}
      width={800}
      wrapClassName="organization-invites-modal__container"
    >
      {
        <Table
          className="organization-invites-modal__table"
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
        />
      }
    </Modal>
  );
};

export default OrganizationInvitesModal;

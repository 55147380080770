import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { resetAndCloseDrawer } from "../store/AnnotationSideControlsSlice";
import { ContentItems, DrawerType } from "../interfaces/drawer";
import { useTranslation } from "react-i18next";
import {
  useGetDetailsOfDocumentSetQuery,
  useGetHistoryOfDocumentSetQuery,
} from "../../documentSet/store/documentSetApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

const useSideDrawer = () => {
  const { t } = useTranslation("annotationSideControls", {
    keyPrefix: "sideDrawer",
  });

  const dispatch = useAppDispatch();

  const { isDrawerOpen, drawerType } = useAppSelector(
    (state) => state.annotationSideControlsReducer
  );

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const {
    data: annotationHistoryData,
    isFetching: isLoadingHistoryAnnotations,
    refetch,
    isUninitialized,
  } = useGetHistoryOfDocumentSetQuery(
    drawerType !== DrawerType.ANNOTATION_HISTORY || !activeDocumentSet
      ? skipToken
      : activeDocumentSet.id
  );

  const {
    data: detailsData,
    isFetching: isLoadingDetails,
    refetch: refetchDetails,
    isUninitialized: isUninitializedDetails,
  } = useGetDetailsOfDocumentSetQuery(
    drawerType !== DrawerType.DETAILS || !activeDocumentSet
      ? skipToken
      : activeDocumentSet.id
  );

  const closeDrawer = useCallback(() => {
    dispatch(resetAndCloseDrawer());
  }, [dispatch]);

  const title = useMemo(() => {
    if (drawerType === DrawerType.DETAILS) {
      return t("detailLabels.details");
    } else if (drawerType === DrawerType.ANNOTATION_HISTORY) {
      return t("annotationHistory.title");
    } else {
      return undefined;
    }
  }, [drawerType, t]);

  const detailsContent = useMemo(() => {
    if (drawerType === DrawerType.DETAILS) {
      return detailsData;
    } else {
      return undefined;
    }
  }, [drawerType, detailsData]);

  const annotationHistoryContent = useMemo(() => {
    if (drawerType === DrawerType.ANNOTATION_HISTORY) {
      return annotationHistoryData;
    } else {
      return undefined;
    }
  }, [drawerType, annotationHistoryData]);

  const content: ContentItems = useMemo(() => {
    return {
      detailsData: detailsContent,
      annotationHistoryData: annotationHistoryContent,
    };
  }, [detailsContent, annotationHistoryContent]);

  const loading = useMemo(() => {
    if (drawerType === DrawerType.ANNOTATION_HISTORY) {
      return isLoadingHistoryAnnotations;
    } else if (drawerType === DrawerType.DETAILS) {
      return isLoadingDetails;
    } else {
      return false;
    }
  }, [drawerType, isLoadingHistoryAnnotations, isLoadingDetails]);

  useEffect(() => {
    if (
      isDrawerOpen &&
      !isUninitialized &&
      drawerType === DrawerType.ANNOTATION_HISTORY
    ) {
      refetch();
    }
    if (
      isDrawerOpen &&
      !isUninitializedDetails &&
      drawerType === DrawerType.DETAILS
    ) {
      refetchDetails();
    }
  }, [
    isDrawerOpen,
    refetch,
    isUninitialized,
    isUninitializedDetails,
    refetchDetails,
    drawerType,
  ]);

  return {
    content,
    loading,
    title,
    closeDrawer,
    isDrawerOpen,
    drawerType,
  };
};

export default useSideDrawer;

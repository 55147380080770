import React, { FunctionComponent } from "react";
import "./AnnotationSideControls.scss";
import GroupBlocksContainer from "../configMap";
import { TourPage } from "../tours/hooks/useTour";
import useStartTour from "../tours/hooks/useStartTour";
import AnnotationSideControlsHeader from "./components/Header/AnnotationSideControlsHeader";
import AlertRenderer from "./components/alert/AlertRenderer";
import AnnotationSideControlsFooter from "./components/Footer/AnnotationSideControlsFooter";
import IssuesHeaderTag from "./components/issuesHeaderTag/IssuesHeaderTag";
import useIssuesHeaderTag from "./hooks/useIssuesHeaderTag";
import { SIDE_CONTROLS_HEADER } from "./constants";
import useAnnotationSideControls from "./hooks/useAnnotationSideControls";
import SideDrawer from "./components/sideDrawer/SideDrawer";

type AnnotationSideControlsProps = {
  disabled: boolean;
  documentSetId?: string;
};

const AnnotationSideControls: FunctionComponent<
  AnnotationSideControlsProps
> = ({ disabled, documentSetId }) => {
  const {
    isLoading,
    setIsLoading,
    alertHeight,
    heightFooter,
    setHeightFooter,
    annotationSideControlsClassnames,
    userSettings,
    activeDocumentSet,
    setAlertHeight,
    currentHeight,
    containerRef,
  } = useAnnotationSideControls(documentSetId);

  useStartTour({
    tourPage: TourPage.DETAIL,
    triggerStartTour: userSettings.showDetailTour,
    dependencies: [userSettings.showDetailTour],
  });

  const {
    issueEntities,
    errorAttentionAnnotations,
    scoreAttentionAnnotations,
    tagColor,
    areThereIssues,
    issuesTagHeight,
  } = useIssuesHeaderTag(disabled);

  return (
    <div
      className={annotationSideControlsClassnames}
      id="tour-detail-step-1"
      ref={containerRef}
    >
      <AnnotationSideControlsHeader disabled={disabled} />
      <AlertRenderer
        status={activeDocumentSet?.status}
        setHeight={setAlertHeight}
      />
      <IssuesHeaderTag
        disabled={disabled}
        entities={issueEntities}
        errorAttentionAnnotations={errorAttentionAnnotations}
        scoreAttentionAnnotations={scoreAttentionAnnotations}
        tagColor={tagColor}
        areThereIssues={areThereIssues}
      />
      <div className="horizontal-rule" />
      <div className="side-controls">
        <SideDrawer height={currentHeight} />
        <GroupBlocksContainer
          style={{
            height: `calc(${currentHeight}px - ${SIDE_CONTROLS_HEADER}px - ${heightFooter}px - ${issuesTagHeight}px - ${alertHeight}px)`,
          }}
          disabled={disabled}
        />
        <AnnotationSideControlsFooter
          disabled={disabled}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setHeight={setHeightFooter}
        />
      </div>
    </div>
  );
};
export default AnnotationSideControls;

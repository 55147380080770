import React, { FC } from "react";
import "./IssuesOverviewPopover.scss";
import { Annotation } from "../../../../annotator/interfaces/annotation";
import useIssuesOverviewPopover from "../../../hooks/useIssuesOverviewPopover";
import { IssuesEntity } from "../../../interfaces/issuesEntity";
import GroupBlockIssuesContainer from "./GroupBlockIssuesContainer";

type Props = {
  errorAttentionAnnotations: Array<Annotation>;
  scoreAttentionAnnotations: Array<Annotation>;
  entities: Array<IssuesEntity>;
};

const IssuesOverviewPopover: FC<Props> = ({
  errorAttentionAnnotations,
  scoreAttentionAnnotations,
  entities,
}) => {
  const { groupedByGroupBlockAnnotations, navigateToEntity } =
    useIssuesOverviewPopover(
      errorAttentionAnnotations,
      scoreAttentionAnnotations,
      entities
    );

  return (
    <div className="issues-popover-container">
      {groupedByGroupBlockAnnotations.map(
        ({ groupBlockName, errorAnnotations }, index) => {
          return (
            <GroupBlockIssuesContainer
              key={index}
              groupBlockName={groupBlockName}
              issueAnnotations={errorAnnotations}
              isError={true}
              navigateToEntity={navigateToEntity}
            />
          );
        }
      )}
      {groupedByGroupBlockAnnotations.map(
        ({ groupBlockName, warningAnnotations }, index) => {
          return (
            <GroupBlockIssuesContainer
              key={index}
              groupBlockName={groupBlockName}
              issueAnnotations={warningAnnotations}
              isError={false}
              navigateToEntity={navigateToEntity}
            />
          );
        }
      )}
    </div>
  );
};

export default IssuesOverviewPopover;

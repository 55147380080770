import React, { FC, useCallback, useEffect } from "react";
import { Select } from "antd";
import { validLanguages } from "../../constants";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
};

const LanguageSelector: FC<Props> = ({ label }) => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation("accountPopover");

  useEffect(() => {
    if (!validLanguages.includes(language)) {
      changeLanguage("en");
    }
  }, [language, changeLanguage]);

  const handleLanguageChange = useCallback(
    async (lang: string) => {
      if (validLanguages.includes(lang)) {
        await changeLanguage(lang);
      }
    },
    [changeLanguage]
  );

  return (
    <div className="welcome-page__form-item">
      <label>{label}</label>
      <Select value={language} onChange={handleLanguageChange} size="large">
        {validLanguages.map((languageInList) => (
          <Select.Option key={languageInList} value={languageInList}>
            {t(`language.languages.${languageInList}`)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default LanguageSelector;

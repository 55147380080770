import { Point } from "../interfaces/point";
import { Rectangle } from "../interfaces/textLayer";

export const isBetween = (
  number: number,
  lowerbound: number,
  upperbound: number
): boolean => {
  return number > lowerbound && number < upperbound;
};

export const convertRectangleToCenterPoint = (rect: Rectangle): Point => {
  const left = Math.round(rect.left);
  const top = Math.round(rect.top);
  const width = Math.round(rect.width);
  const height = Math.round(rect.height);

  const centerX = Math.round(left + width / 2);
  const centerY = Math.round(top + height / 2);

  return {
    x: centerX,
    y: centerY,
  };
};

export const pointInRectangle = (point: Point, rect: Rectangle): boolean => {
  const { x, y } = point;

  return (
    rect.left <= x &&
    x <= rect.left + rect.width &&
    rect.top <= y &&
    y <= rect.top + rect.height
  );
};

export const intersect = <T>(a: Array<T>, b: Array<T>): Array<T> => {
  if (b?.length > a?.length) {
    let t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { Input, InputRef } from "antd";
import useFocus from "../../documentSet/hooks/useFocus";

type Props = {
  label: ReactNode;
  isOpen: boolean;
  value: any;
  prop: any;
  updateFilter: (prop: any, value: any) => void;
};

const InputFilterItem: FC<Props> = ({
  label,
  isOpen,
  value,
  prop,
  updateFilter,
}) => {
  const ref: MutableRefObject<null | InputRef> = useRef(null);
  useFocus(ref, isOpen);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      updateFilter(prop, value);
    },
    [updateFilter, prop]
  );

  return (
    <div className="filter-popover__item">
      {label}
      <Input
        ref={ref}
        value={value}
        className="filter-popover__input"
        placeholder={"Search"}
        onChange={handleChange}
        allowClear
      />
    </div>
  );
};

export default InputFilterItem;

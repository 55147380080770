import { useMemo } from "react";
import { useAppSelector } from "../../app";
import activeEntityForAnnotatorSelector from "../selectors/activeEntityForAnnotatorSelector";
import { useTranslation } from "react-i18next";

const useTableZIndex = (createTable: boolean) => {
  const {
    i18n: { language },
  } = useTranslation();

  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  return useMemo(() => {
    if (createTable && entity) {
      return 0;
    }
    if (createTable) {
      return 10;
    }

    return 1;
  }, [createTable, entity]);
};

export default useTableZIndex;

import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../app";
import { mapOrganizationToImage } from "../utils/companyMapper";

const useOrganizationAvatar = () => {
  const { user } = useAppSelector((state) => state.userReducer);
  const [image, setImage] = useState("");

  const loadImage = useCallback(
    async (image: string, controller: AbortController) => {
      try {
        const file = await import(`../../../assets/${image}`);
        const response = await fetch(file.default, {
          signal: controller.signal,
        });
        const data = response.url;
        setImage(data);
      } catch (e) {
        console.log(e);
      }
    },
    []
  );

  useEffect(() => {
    if (!user?.organization?.name) {
      return;
    }

    const organization = user.organization.name;
    let controller = new AbortController();

    loadImage(mapOrganizationToImage(organization), controller);

    return () => {
      controller.abort();
    };
  }, [user, loadImage]);

  return image;
};

export default useOrganizationAvatar;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  User,
  UserSettings,
  UserSettingsSorters,
} from "../interfaces/interfaces";
import { USER_LOCALSTORAGE_NAME } from "../constants";
import { defaultSettings, getUserFromLocalStorage } from "../utils/utils";
import { OrderColumnItem } from "../../documentSet/interfaces/overview";

interface UserSorterAction {
  key: string;
  value: string;
}

interface UserState {
  user: User | null;
  userSettings: UserSettings;
  isJwtLoggedIn: boolean;
  forceTokenReFetch: boolean;
}

const initialState: UserState = {
  user: getUserFromLocalStorage(),
  userSettings: defaultSettings,
  isJwtLoggedIn: false,
  forceTokenReFetch: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem(USER_LOCALSTORAGE_NAME);
      state.user = null;
    },
    updateUserProfile: (state, action: PayloadAction<User>) => {
      localStorage.setItem("user", JSON.stringify(action.payload));
      state.user = action.payload;
    },
    updateUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.userSettings = action.payload;
      // @ts-ignore
      delete state.userSettings.picture;
    },
    updateUserSorter: (state, action: PayloadAction<UserSorterAction>) => {
      const { key, value } = action.payload;
      state.userSettings[key as keyof UserSettingsSorters] = value;
    },
    updateUserOverviewOrderColumns: (
      state,
      action: PayloadAction<Array<OrderColumnItem>>
    ) => {
      state.userSettings.overviewOrderColumns = action.payload;
    },
    updateUserArchiveOrderColumns: (
      state,
      action: PayloadAction<Array<OrderColumnItem>>
    ) => {
      state.userSettings.archiveOrderColumns = action.payload;
    },
    toggleHideNonRequiredEntities: (state) => {
      state.userSettings.hideNonRequiredEntities =
        !state.userSettings.hideNonRequiredEntities;
    },
    changeJwtLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isJwtLoggedIn = action.payload;
    },
    changeForceTokenReFetch: (state, action: PayloadAction<boolean>) => {
      state.forceTokenReFetch = action.payload;
    },
  },
});

export const {
  login,
  logout,
  updateUserProfile,
  updateUserSettings,
  updateUserSorter,
  updateUserOverviewOrderColumns,
  updateUserArchiveOrderColumns,
  toggleHideNonRequiredEntities,
  changeJwtLoggedIn,
  changeForceTokenReFetch,
} = userSlice.actions;

export default userSlice.reducer;

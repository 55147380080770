import React, { FC } from "react";
import "./Actions.scss";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { GroupBlockData } from "../../../configMap";
import useActions from "../../hooks/useActions";

type Props = {
  groupBlock: GroupBlockData;
  index: number;
  amountOfLines: number;
  disabled: boolean;
  addGroupBlock: () => void;
};

const Actions: FC<Props> = ({
  groupBlock,
  index,
  amountOfLines,
  disabled,
  addGroupBlock,
}) => {
  const { t } = useTranslation("annotationSideControls");

  const {
    removeGroupBlockAndAnnotations,
    removeAnnotations,
    hasMultipleLines,
    isAddButtonInFocus,
  } = useActions(groupBlock, index, amountOfLines, disabled, addGroupBlock);

  if (disabled) {
    return null;
  }

  return (
    <div className="actions-container">
      <Tooltip
        placement="topLeft"
        mouseEnterDelay={0.5}
        title={t("groupBlockTitleActions.deleteAnnotationsInGroupBlock")}
      >
        <div className="default-icon" onClick={() => removeAnnotations(index)}>
          <i className="bi bi-eraser" />
        </div>
      </Tooltip>
      {hasMultipleLines ? (
        <Tooltip
          placement="topLeft"
          mouseEnterDelay={0.5}
          title={t("groupBlockTitleActions.deleteGroupBlockAction")}
        >
          <div
            className="delete-line-icon"
            onClick={() => removeGroupBlockAndAnnotations(index)}
          >
            <i className="bi bi-trash" />
          </div>
        </Tooltip>
      ) : null}
      <Tooltip
        placement="topLeft"
        mouseEnterDelay={0.5}
        title={t("groupBlockTitleActions.addInstanceGroupBlock")}
      >
        <div
          className={`default-icon group-block-button-add ${
            isAddButtonInFocus ? "btn-focused" : ""
          }`.trim()}
          onClick={addGroupBlock}
        >
          <i className="bi bi-plus-circle group-block__add-button-icon" />
        </div>
      </Tooltip>
    </div>
  );
};

export default Actions;

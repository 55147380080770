import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { Annotation } from "../../annotator/interfaces/annotation";
import {
  GroupBlockIssues,
  GroupedAnnotations,
  IssueLine,
  IssuesEntity,
} from "../interfaces/issuesEntity";
import { GroupBlockEntityType } from "../../configMap";
import { setSelectedGroupBlockId } from "../../annotationTableControls/store/AnnotationTableControlsSlice";
import {
  changeActiveEntity,
  changeAnnotating,
  changeScrollToEntity,
} from "../../app/store/appSlice";
import { useAppDispatch, useAppSelector } from "../../app";
import { MIN_TABLE_CONTROLS_HEIGHT } from "../../annotationTableControls/constants";
import useSetGroupBlockTabHeightByLines from "../../annotationTableControls/hooks/useSetTableControlsHeight";

const useIssuesOverviewPopover = (
  errorAttentionAnnotations: Array<Annotation>,
  scoreAttentionAnnotations: Array<Annotation>,
  entities: Array<IssuesEntity>
) => {
  const dispatch = useAppDispatch();

  const {
    i18n: { language },
  } = useTranslation();

  const { activeEntity } = useAppSelector((state) => state.appReducer);

  const { tableControlsHeight } = useAppSelector(
    (state) => state.annotationTableControlsReducer
  );

  const { setTableControlsHeightByLines } = useSetGroupBlockTabHeightByLines();

  const getIssuesAnnotations = useCallback(
    (annotation: Annotation, isError: boolean): IssueLine | null => {
      const entityType = entities?.find(
        (gbet) => gbet.entityId === annotation.entity.id
      );

      if (!entityType) {
        return null;
      }

      const entity = {
        ...entityType.entity,
        index: annotation.index!,
      };
      const entityName = getInLanguageOrDefault(
        entityType.entityTranslations,
        language
      );
      const groupBlockName = getInLanguageOrDefault(
        entityType.groupBlockName,
        language
      );
      const lineName = annotation.multipleGroupBlocks
        ? `${entityName} - Line ${annotation.index}`
        : entityName;

      return {
        entityType: entity,
        entityIndex: entityType.entityIndex,
        index: annotation.index!,
        groupBlockId: entityType.groupBlockId,
        multipleGroupBlocks: entityType.multipleGroupBlocks,
        entityName: lineName,
        groupBlockName,
        groupBlockIndex: entityType.groupBlockIndex,
        isError: isError,
      };
    },
    [entities, language]
  );

  const errorAttentionLines = useMemo(() => {
    return errorAttentionAnnotations
      .map((annotation) => {
        return getIssuesAnnotations(annotation, true);
      })
      .filter((annotation) => annotation !== null);
  }, [errorAttentionAnnotations, getIssuesAnnotations]);

  const scoreAttentionLines = useMemo(() => {
    return scoreAttentionAnnotations
      .map((annotation) => {
        return getIssuesAnnotations(annotation, false);
      })
      .filter((annotation) => annotation !== null);
  }, [scoreAttentionAnnotations, getIssuesAnnotations]);

  const groupedByGroupBlockAnnotations = useMemo(() => {
    const annotations = [...errorAttentionLines, ...scoreAttentionLines];

    // Group annotations by group block name
    const groupedData: GroupedAnnotations = annotations.reduce((acc, obj) => {
      const key = obj?.groupBlockName ?? "";

      if (!acc[key]) {
        acc[key] = { error: [], warning: [] };
      }

      if (obj?.isError) {
        acc[key].error.push(obj);
      } else if (obj) {
        acc[key].warning.push(obj);
      }

      return acc;
    }, {} as GroupedAnnotations);

    // Sort annotations within each group
    Object.values(groupedData).forEach((group) => {
      group.error.sort(
        (a, b) => a.entityIndex - b.entityIndex || a.index - b.index
      );
      group.warning.sort(
        (a, b) => a.entityIndex - b.entityIndex || a.index - b.index
      );
    });

    // Transform grouped data into the desired format
    const annotationsPerGroupBlock: Array<GroupBlockIssues> = Object.keys(
      groupedData
    )
      .map((groupBlockName) => {
        const { error = [], warning = [] } = groupedData[groupBlockName];
        const firstAnnotation = error[0] || warning[0];

        return {
          groupBlockName,
          groupBlockIndex: firstAnnotation?.groupBlockIndex,
          errorAnnotations: error,
          warningAnnotations: warning,
          multipleGroupBlocks: firstAnnotation?.multipleGroupBlocks ?? false,
        };
      })
      .sort((a, b) => (a.groupBlockIndex ?? 100) - (b.groupBlockIndex ?? 100));

    return annotationsPerGroupBlock;
  }, [errorAttentionLines, scoreAttentionLines]);

  const navigateToEntity = useCallback(
    (
      entityType: GroupBlockEntityType | undefined,
      groupBlockId: string | undefined,
      multipleGroupBlocks: boolean | undefined
    ) => {
      if (!entityType) {
        return;
      }

      if (activeEntity) {
        dispatch(changeAnnotating(false));
        dispatch(changeActiveEntity(undefined));
      }

      if (multipleGroupBlocks && groupBlockId) {
        if (tableControlsHeight === MIN_TABLE_CONTROLS_HEIGHT) {
          setTableControlsHeightByLines();
        }

        dispatch(setSelectedGroupBlockId(groupBlockId));
      }
      dispatch(changeScrollToEntity(entityType));
    },
    [dispatch, activeEntity, tableControlsHeight, setTableControlsHeightByLines]
  );

  return {
    groupedByGroupBlockAnnotations,
    errorAttentionLines,
    scoreAttentionLines,
    navigateToEntity,
  };
};

export default useIssuesOverviewPopover;

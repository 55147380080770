import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Input, Modal, notification, Select } from "antd";
import { useTranslation } from "react-i18next";
import useMemberModal from "../../hooks/useMemberModal";
import "./AddMemberModal.scss";
import useTableHeaderFilter from "../../hooks/useTableHeaderFilter";
import { FilterType } from "../../interfaces/table";
import { BaseSelectRef } from "rc-select";

const AddMemberModal: FC = () => {
  const { t } = useTranslation("userManagement");
  const ref: MutableRefObject<null | BaseSelectRef> = useRef(null);

  const [email, setEmail] = useState("");
  const [selectStatus, setSelectStatus] = useState<
    undefined | "error" | "warning"
  >(undefined);

  const { open, changeModalOpen, handleInviteMember } = useMemberModal();
  const { value, setValue, options } = useTableHeaderFilter(FilterType.sources);

  useEffect(() => {
    if (options.length === 1) {
      setValue(options[0].value);
    }
  }, [options, setValue]);

  useEffect(() => {
    const select = ref?.current;
    if (value.length && select && value.length === options.length) {
      select.blur();
    }
  }, [value, options]);

  const handleOk = useCallback(async () => {
    const permissions = Array.isArray(value) ? value : [value];

    if (permissions.length) {
      await handleInviteMember(email, permissions);
      setEmail("");
      setValue([]);
      setSelectStatus(undefined);
    } else {
      notification.error({
        message: "No permissions selected",
        description: "You need to select at least one permission",
      });
      setSelectStatus("error");
    }
  }, [email, handleInviteMember, value, setValue]);

  const handleChange = useCallback((event: any) => {
    setEmail(event.target.value);
  }, []);

  const handleClose = useCallback(() => {
    setEmail("");
    setValue([]);
    setSelectStatus(undefined);
    changeModalOpen();
  }, [changeModalOpen, setValue]);

  return (
    <Modal
      centered
      title={t("addModal.header")}
      okText={t("addModal.button")}
      open={open}
      onOk={handleOk}
      onCancel={handleClose}
      className="add-member-modal__container"
    >
      <div className="add-member-modal__body">
        <h4>Member email:</h4>
        <Input
          size="large"
          placeholder="email@example.com"
          value={email}
          onChange={handleChange}
        />
        <div />
        <h4>Member permissions:</h4>
        <Select
          ref={ref}
          status={selectStatus}
          mode={"multiple"}
          size="large"
          value={value}
          options={options}
          placeholder={"Search"}
          onChange={setValue}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          allowClear
        />
      </div>
    </Modal>
  );
};

export default AddMemberModal;

import { useAppSelector } from "../../app";
import { useGetMetricFilterItemsQuery } from "../../app/store/appApi";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";

const useInitMetricsMap = () => {
  const user = useAppSelector((state) => state.userReducer?.user);

  useGetMetricFilterItemsQuery(user?.inputIds ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });
};

export default useInitMetricsMap;

import { RootState } from "../../app";
import { ICategorization } from "../../configMap/interfaces/category";
import { createSelector } from "@reduxjs/toolkit";

const EMPTY_ARRAY: Array<ICategorization> = [];

const selectCategorizationConfigMaps = (state: RootState) =>
  state.configMapReducer.configMap?.categorizationConfigMaps;

const selectConfigMapCategorizations = createSelector(
  selectCategorizationConfigMaps,
  (categorizationConfigMaps) => {
    if (!categorizationConfigMaps) {
      return EMPTY_ARRAY;
    }

    return [...categorizationConfigMaps]
      .map((categorizationConfigMap) => categorizationConfigMap.categorization)
      .sort(
        (item1, item2) =>
          (item1?.index ?? Number.MAX_VALUE) -
          (item2?.index ?? Number.MAX_VALUE)
      );
  }
);

export default selectConfigMapCategorizations;

import { useCallback, useEffect, useRef, useState } from "react";
import useTableUpdater from "./useTableUpdater";
import useMouse from "@react-hook/mouse-position";
import { ITable } from "../interfaces/ITable";

const useTableBorder = (
  type: "top" | "left",
  style: any,
  table: ITable,
  isCreatingTable: boolean
) => {
  const { addRow, addColumn } = useTableUpdater();

  const ref = useRef(null);
  const mouse = useMouse(ref);

  const [iconTop, setIconTop] = useState<number>();
  const [iconLeft, setIconLeft] = useState<number>();
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();

  // columns top: style.top, left: style.left + mouse.x
  // rows top: style.top, left: style.left + mouse.y
  useEffect(() => {
    if (isCreatingTable) {
      const { x, y } = mouse;

      if (x === null || y === null) {
        return;
      }

      setX(x);
      setY(y);

      //info: 20 is the height and the width of the icon
      if (type === "top") {
        setIconLeft(x - 20 / 2 - 2);
        setIconTop(-9);
      } else {
        setIconTop(y - 20 / 2 - 2);
        setIconLeft(-9);
      }
    }
  }, [type, mouse, isCreatingTable, style.left, style.top]);

  const addTableDetails = useCallback(() => {
    if (type === "top" && isCreatingTable && x) {
      addColumn(table.id, x);
    }
    if (type === "left" && isCreatingTable && y) {
      addRow(table.id, y);
    }
  }, [addColumn, addRow, isCreatingTable, table.id, type, x, y]);

  return {
    ref,
    iconTop,
    iconLeft,
    addTableDetails,
  };
};

export default useTableBorder;

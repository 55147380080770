import React, { FC } from "react";
import { Divider } from "antd";
import "./DetailsContent.scss";
import useDetailsContent from "../../../../hooks/useDetailsContent";
import dayjs from "dayjs";
import ReactHtmlParser from "html-react-parser";
import FullWidthLine from "./line/FullWidthLine";
import TwoColumnLine from "./line/TwoColumnLine";
import { useTranslation } from "react-i18next";
import { DocumentSetDto } from "../../../../interfaces/drawer";

type Props = {
  documentSetDetails: DocumentSetDto | undefined;
};

const DetailsContent: FC<Props> = ({ documentSetDetails }) => {
  const data = useDetailsContent(documentSetDetails);

  const { t } = useTranslation("annotationSideControls", {
    keyPrefix: "sideDrawer.detailLabels",
  });

  return (
    <div className="content-container">
      <div className="group-container">
        <h5 className="group-label">{t("documentSet")}</h5>
        <FullWidthLine label={t("name")} value={data?.documentSetName} />
        <FullWidthLine label={t("source")} value={data?.source} />
        <FullWidthLine label="Id" value={data?.id} />
        {data?.email ? (
          <>
            <Divider className="divider" />
            <div className="group-container">
              <h5 className="group-label">{t("email")}</h5>
              <TwoColumnLine
                firstColumnLabel={t("subject")}
                firstColumnValue={data.email.subject}
                secondColumnLabel={t("receivedDate")}
                secondColumnValue={dayjs(data.email.receivedDate).format(
                  "DD/MM/YYYY HH:mm"
                )}
              />
              <TwoColumnLine
                firstColumnLabel={t("senderName")}
                firstColumnValue={data.email.senderName}
                secondColumnLabel={t("senderAddress")}
                secondColumnValue={data.email.senderAddress}
              />
              <FullWidthLine label={t("to")} value={data.email.toAddresses} />
              <FullWidthLine label={t("cc")} value={data.email.ccAddresses} />
              <FullWidthLine
                label={t("categories")}
                value={data.email.categories}
              />
              {data.email.content ? (
                <>
                  <h5 className="label">{t("emailBody")}</h5>
                  <div className="value-email-content">
                    {ReactHtmlParser(data.email.content)}
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DetailsContent;

import React, { ChangeEvent, FC, memo, useMemo, useRef, useState } from "react";
import { Input } from "antd/lib";
import { useAppDispatch } from "../../../../../../app";
import {
  addBlurredEntity,
  changeEditing,
} from "../../../../../../app/store/appSlice";
import { isEmpty } from "../../../../../../common/utilities/value";
import { GroupBlockEntityType } from "../../../../../../configMap";
import { EDIT_ICONS_WIDTH, HOTKEY_WIDTH } from "../../../../../constants";
import useAnnotations from "../../../../../../annotation/hooks/useAnnotations";

type EditEntityTextProps = {
  entityType: GroupBlockEntityType;
  documentId: string;
  annotationId: string;
  defaultValue: string;
  groupBlockIndex: number;
  cellWidth: number;
};

const EditEntityText: FC<EditEntityTextProps> = ({
  entityType,
  documentId,
  annotationId,
  defaultValue,
  groupBlockIndex,
  cellWidth,
}: EditEntityTextProps) => {
  const dispatch = useAppDispatch();

  const isCancelled = useRef(false);
  const [value, setValue] = useState<string>(defaultValue);
  const { updateAnnotation } = useAnnotations({
    documentId,
    groupBlockIndex,
  });

  const inputCellWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH;
  }, [cellWidth]);

  const inputBoxWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH - EDIT_ICONS_WIDTH;
  }, [cellWidth]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setValue(target.value);
  };

  const handleEnter = (event: any) => {
    event.stopPropagation();
    dispatch(changeEditing(null));
    if (!isEmpty(value) && !isCancelled.current) {
      updateAnnotation(annotationId, value, entityType, true, true);
    }
  };

  const onBlur = (event: any) => {
    handleEnter(event);
    dispatch(
      addBlurredEntity(`${entityType.entityType.id}-${groupBlockIndex}`)
    );
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    isCancelled.current = true;
    dispatch(changeEditing(null));
  };

  return (
    <div
      className="edit-entity edit-entity-text"
      style={{ width: `${inputCellWidth}px` }}
    >
      <Input
        size={"small"}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        onPressEnter={handleEnter}
        onBlur={onBlur}
        autoFocus
        onClick={(e) => e.stopPropagation()}
        style={{ width: `${inputBoxWidth}px` }}
      />
      <div className="edit-actions">
        <i className="bi bi-slash-circle cancel" onMouseDown={handleCancel} />
        <i className="bi bi-check-lg submit" onMouseDown={handleEnter} />
      </div>
    </div>
  );
};

export default memo(EditEntityText);

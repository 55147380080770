import { Menu, Popover } from "antd";
import React, { FC, useCallback, useMemo, useState } from "react";
import "./GroupBlockTitleActions.scss";
import { useTranslation } from "react-i18next";
import { ItemType } from "antd/lib/menu/interface";

type Props = {
  groupBlockName: string;
  groupBlockIndex: number | null;
  removeGroupBlockAndAnnotations: () => void;
  removeGroupBlocksForEntityAndAnnotations: () => void;
};

const GroupBlockTitleActions: FC<Props> = (props) => {
  const {
    groupBlockName,
    groupBlockIndex,
    removeGroupBlockAndAnnotations,
    removeGroupBlocksForEntityAndAnnotations,
  } = props;
  const [open, setOpen] = useState(false);

  const closePopoverAndExecuteMethod = useCallback(
    (toExecuteMethod: () => void) => {
      toExecuteMethod();
      setOpen(false);
    },
    [setOpen]
  );

  const { t } = useTranslation("annotationSideControls");

  const items: Array<ItemType> = useMemo(
    () => [
      {
        icon: <i className="bi bi-trash" />,
        label: t("groupBlockTitleActions.deleteGroupBlockAction").replace(
          "%NAME",
          `${groupBlockName} ${groupBlockIndex}`
        ),
        key: "deleteGroupBlock",
        onClick: () =>
          closePopoverAndExecuteMethod(removeGroupBlockAndAnnotations),
      },
      {
        icon: <i className="bi bi-trash" />,
        label: t("groupBlockTitleActions.deleteAllInstancesOfGroupBlockAction"),
        key: "deleteAllInstancesOfGroupBlock",
        onClick: () =>
          closePopoverAndExecuteMethod(
            removeGroupBlocksForEntityAndAnnotations
          ),
      },
    ],
    [
      t,
      groupBlockName,
      groupBlockIndex,
      closePopoverAndExecuteMethod,
      removeGroupBlockAndAnnotations,
      removeGroupBlocksForEntityAndAnnotations,
    ]
  );

  const content = useCallback(() => {
    return <Menu selectable={false} className="popover-menu" items={items} />;
  }, [items]);

  return (
    <Popover
      open={open}
      placement="rightTop"
      content={content}
      trigger="click"
      onOpenChange={() => setOpen(!open)}
    >
      <i className="bi bi-three-dots-vertical group-block-icon" />
    </Popover>
  );
};

export default GroupBlockTitleActions;

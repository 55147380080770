import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const selectPdf = (state: RootState) => state.documentReducer.pdf;

const selectPdfNumberOfPages = createSelector(selectPdf, (pdf): number => {
  if (!pdf) {
    return 0;
  }
  return pdf.metadata.pageCount;
});

export default selectPdfNumberOfPages;

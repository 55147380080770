import { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { EmailTemplate } from "../interfaces/table";
import {
  addEmailTemplate,
  setFavoriteTemplate,
  setSelectedEmailTemplate,
  updateEmailTemplate,
} from "../store/emailTemplatesSlice";
import selectModalStateByKey from "../../app/selectors/modalStateSelector";
import { changeModalStateForKey } from "../../app/store/appSlice";
import {
  useAddEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from "../store/emailTemplatesApi";

const modalKey = "emailTemplatesModal";

const useEmailTemplateModal = () => {
  const { t } = useTranslation("emailTemplates");

  const [loadingEmailTemplateModal, setLoadingEmailTemplateModal] =
    useState(false);

  const open = useAppSelector((state) =>
    selectModalStateByKey(state, { modalKey })
  );

  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.userReducer.user);

  const [updateEmailTemplateDb] = useUpdateEmailTemplateMutation();

  const [addEmailTemplateDb] = useAddEmailTemplateMutation();

  const { emailTemplates, selectedEmailTemplate } = useAppSelector(
    (state) => state.emailTemplatesReducer
  );

  const handleUpsertEmailTemplate = (emailTemplate: EmailTemplate) => {
    const isFavoriteChanged = isFavoriteTemplateChanged(emailTemplate);

    if (!emailTemplate.id) {
      addTemplate(emailTemplate, isFavoriteChanged);
    } else {
      updateTemplate(emailTemplate, isFavoriteChanged);
    }
  };

  const updateTemplate = useCallback(
    async (
      emailTemplate: EmailTemplate,
      isFavoriteTemplateChanged: boolean
    ) => {
      try {
        setLoadingEmailTemplateModal(true);

        await updateEmailTemplateDb(emailTemplate).unwrap();

        if (isFavoriteTemplateChanged) {
          dispatch(setFavoriteTemplate(emailTemplate.id));
        }

        dispatch(updateEmailTemplate(emailTemplate));

        notification.success({
          message: t("updatedSuccesMessage").replace(
            "$TEMPLATE",
            emailTemplate.name
          ),
        });
      } catch (e) {
        notification.error({
          message: t("errorMessage.message"),
          description: t("errorMessage.description").replace(
            "$MESSAGE",
            "updating an email template"
          ),
        });
        console.log(e);
      } finally {
        setLoadingEmailTemplateModal(false);
        dispatch(changeModalStateForKey(modalKey));
      }
    },
    [dispatch, t, updateEmailTemplateDb]
  );

  const addTemplate = useCallback(
    async (
      emailTemplate: EmailTemplate,
      isFavoriteTemplateChanged: boolean
    ) => {
      try {
        if (!user) {
          notification.error({
            message: t("errorMessage.message"),
            description: t("errorMessage.description").replace(
              "$MESSAGE",
              "adding an email template"
            ),
          });
        }

        setLoadingEmailTemplateModal(true);

        await addEmailTemplateDb({
          emailTemplate,
          userId: user?.id!,
        }).unwrap();
        dispatch(addEmailTemplate(emailTemplate));

        if (isFavoriteTemplateChanged) {
          dispatch(setFavoriteTemplate(emailTemplate.id));
        }

        notification.success({
          message: t("addedSuccesMessage").replace(
            "$TEMPLATE",
            emailTemplate.name
          ),
        });
      } catch (e) {
        notification.error({
          message: t("errorMessage.message"),
          description: t("errorMessage.description").replace(
            "$MESSAGE",
            "adding an email template"
          ),
        });
        console.log(e);
      } finally {
        setLoadingEmailTemplateModal(false);
        dispatch(changeModalStateForKey(modalKey));
      }
    },
    [dispatch, t, user, addEmailTemplateDb]
  );

  const changeModalOpen = useCallback(() => {
    if (open) {
      dispatch(setSelectedEmailTemplate(undefined));
    }

    dispatch(changeModalStateForKey(modalKey));
  }, [open, dispatch]);

  const isFavoriteTemplateChanged = useCallback(
    (emailTemplate: EmailTemplate) => {
      const favoriteTemplate = emailTemplates.find(
        (emailTemplate: EmailTemplate) => emailTemplate.favorite
      );
      return !!(
        emailTemplate.id !== favoriteTemplate?.id && emailTemplate.favorite
      );
    },
    [emailTemplates]
  );

  return {
    open,
    loadingEmailTemplateModal,
    changeModalOpen,
    handleUpsertEmailTemplate,
    selectedEmailTemplate,
    setSelectedEmailTemplate,
  };
};

export default useEmailTemplateModal;

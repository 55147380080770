import { useCallback, useState } from "react";
import { notification } from "antd";
import { EmailTemplate } from "../interfaces/table";
import { useTranslation } from "react-i18next";
import { useRemoveEmailTemplateMutation } from "../store/emailTemplatesApi";
import { removeEmailTemplate } from "../store/emailTemplatesSlice";
import { useAppDispatch } from "../../app";

const useEmailTemplateModal = () => {
  const { t } = useTranslation("emailTemplates");

  const dispatch = useAppDispatch();

  const [loadingConfirmRemoveModal, setLoadingConfirmRemoveModal] =
    useState(false);

  const [openConfirmRemoveModal, setOpenConfirmRemoveModal] = useState(false);

  const [emailTemplateToDelete, setEmailTemplateToDelete] =
    useState<EmailTemplate>();

  const [removeEmailTemplateDb] = useRemoveEmailTemplateMutation();

  const handleRemove = useCallback(async () => {
    try {
      if (!emailTemplateToDelete) {
        return;
      }

      setLoadingConfirmRemoveModal(true);

      await removeEmailTemplateDb(emailTemplateToDelete.id!).unwrap();
      dispatch(removeEmailTemplate(emailTemplateToDelete.id!));

      notification.success({
        key: "defaultTemplateToast",
        message: t("removeSuccesMessage").replace(
          "$TEMPLATE",
          emailTemplateToDelete.name
        ),
      });
    } catch (e) {
      notification.error({
        message: t("errorMessage.message"),
        description: t("errorMessage.description").replace(
          "$MESSAGE",
          "removing an email template"
        ),
      });
      console.log(e);
    } finally {
      setLoadingConfirmRemoveModal(false);
      setOpenConfirmRemoveModal(false);
    }
  }, [emailTemplateToDelete, t, removeEmailTemplateDb, dispatch]);

  const setEmailTemplateToDeleteAndOpenModal = (
    emailTemplate: EmailTemplate
  ) => {
    setEmailTemplateToDelete(emailTemplate);
    setOpenConfirmRemoveModal(true);
  };

  return {
    openConfirmRemoveModal,
    setOpenConfirmRemoveModal,
    handleRemove,
    setEmailTemplateToDeleteAndOpenModal,
    loadingConfirmRemoveModal,
  };
};

export default useEmailTemplateModal;

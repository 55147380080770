import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../app";
import rangeDates from "../selectors/rangeDatesSelector";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useGetAllMetricsForInputsQuery } from "../store/analyticsApi";
import { METRICS_ROLE_ID } from "../../common/utilities/env";
import { MetricsMode } from "../constants";

const useDocumentSetMetrics = () => {
  const { filterString, metricsMode, inputIds } = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter
  );

  const { refreshMetrics, metricsData } = useAppSelector(
    (state) => state.analyticsReducer.metrics
  );

  const { startDate, endDate } = useAppSelector(rangeDates);

  const user = useAppSelector((state) => state.userReducer?.user);

  const { t } = useTranslation("analytics");

  const startDateString = useMemo(() => {
    return dayjs(startDate).format("YYYY-MM-DD");
  }, [startDate]);

  const endDateString = useMemo(() => {
    return dayjs(endDate).format("YYYY-MM-DD");
  }, [endDate]);

  const skipFetch = useMemo(() => {
    return !user?.access?.includes(METRICS_ROLE_ID) || !user?.inputIds?.length;
  }, [user]);

  const metricsInputIds = useMemo(() => {
    if (inputIds.length) {
      return inputIds;
    }

    return user!.inputIds;
  }, [user, inputIds]);

  const notificationErrorBody = useMemo(() => {
    return {
      message: t("fetchDataError.message"),
      description: t("fetchDataError.description"),
    };
  }, [t]);

  const { isFetching, refetch, isUninitialized } =
    useGetAllMetricsForInputsQuery(
      {
        startDate: startDateString,
        endDate: endDateString,
        filterString,
        metricsMode,
        inputIds: metricsInputIds,
        notificationErrorBody,
      },
      { skip: skipFetch }
    );

  const showNoMetricsAlert = useMemo(() => {
    if (metricsMode === MetricsMode.OPERATIONAL) {
      return (
        !metricsData?.organizationMetrics &&
        !metricsData?.corrections?.length &&
        !metricsData?.sourcesMetrics?.length &&
        !metricsData?.firstTimeRight?.length
      );
    } else if (metricsMode === MetricsMode.USER) {
      return (
        !metricsData?.approvedGraphData?.length &&
        !metricsData?.rejectedGraphData?.length &&
        !metricsData?.processingGraphData?.length
      );
    }

    return !metricsData?.entityMetrics?.length;
  }, [metricsMode, metricsData]);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [refreshMetrics, refetch, isUninitialized]);

  return {
    metricsData,
    loading: isFetching,
    startDate,
    endDate,
    metricsMode,
    showNoMetricsAlert,
  };
};

export default useDocumentSetMetrics;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const getPdf = (state: RootState) => state.documentReducer.pdf;
const getPage = (_: RootState, page: number) => page;

const pdfPageImageSelector = createSelector(
  [getPdf, getPage],
  (pdf, page): string | null => {
    if (!pdf?.pages?.length) {
      return null;
    }

    const found = pdf.pages.find((p) => p.page === page);

    if (!found) {
      return null;
    }

    return found.image;
  }
);

export default pdfPageImageSelector;

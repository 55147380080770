import React, { FC } from "react";
import { ColumnItem } from "../../../../../interfaces/overview";
import { DocumentSetData } from "../../../../../interfaces/documentSet";
import "./TextTypeCell.scss";
import { Tooltip } from "antd";
import useCellValue from "../../../../../hooks/useCellValue";

type Props = {
  documentSet: DocumentSetData;
  columnItem: ColumnItem;
};

const TextTypeCell: FC<Props> = ({ documentSet, columnItem }) => {
  const value = useCellValue(documentSet, columnItem);

  return (
    <div className="text-cell__container">
      <Tooltip placement="top" title={value}>
        <label className="text-cell__label">{value}</label>
      </Tooltip>
    </div>
  );
};

export default TextTypeCell;

import {
  Validation,
  ValidationTypes,
} from "../../../common/validation/constants";
import { isNotEmpty } from "../../../common/utilities/value";

export const isRequiredValidation: Validation = {
  type: ValidationTypes.ERROR,
  message: "common.warnings.required",
  icon: "ExclamationCircle",
  requiresValue: false,
  awaitBlur: true,
  validate: isNotEmpty,
};

import { useCallback } from "react";
import { AxiosError } from "axios";
import { OverviewType, useAppSelector } from "../../app";
import { useSorting } from "../../common/dataRefining";
import { cleanObject } from "../../common/utilities/object";
import {
  buildFilters,
  buildSorters,
  cleanFilterString,
} from "../utils/dataFiltering";
import selectUserInputIds from "../../user/selectors/userInputIdsSelector";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { useLazyGetAdjacentDocumentSetIdsQuery } from "../../annotationSideControls/store/annotationSideControlsApi";
import usePagination from "./usePagination";
import { AdjacentDocumentSetIds } from "../interfaces/documentSet";
import useCleanedFilter from "./useCleanedFilter";

const useAdjacentDocumentSetIds = () => {
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const { t } = useTranslation("overview", {
    useSuspense: false,
  });

  const userInputIds = useAppSelector(selectUserInputIds);

  const [getAdjacentDocumentSetIds] = useLazyGetAdjacentDocumentSetIdsQuery();

  const { pageable } = usePagination();

  const { filterArray, overviewType } = useCleanedFilter(true);

  const { activeSorter } = useSorting(overviewType!);

  const fetchAdjacentDocumentSetIds = useCallback(
    (
      documentSetId: string,
      inputIds: Array<string>,
      overviewType: OverviewType
    ): Promise<AdjacentDocumentSetIds> => {
      const filterString = filterArray.join(",");
      const cleanedFilterString = cleanFilterString(filterString, overviewType);

      const data = cleanObject({
        page: pageable!.page,
        sort: buildSorters(activeSorter),
        filter: buildFilters(
          inputIds,
          cleanedFilterString,
          overviewType,
          false
        ),
      });

      return getAdjacentDocumentSetIds({
        documentSetId,
        page: data.page,
        sort: data.sort,
        filter: data.filter,
      }).unwrap();
    },
    [activeSorter, pageable, filterArray, getAdjacentDocumentSetIds]
  );

  return useCallback(async () => {
    if (!userInputIds.length || !activeDocumentSet?.id || !overviewType) {
      return;
    }

    try {
      return await fetchAdjacentDocumentSetIds(
        activeDocumentSet.id,
        userInputIds,
        overviewType
      );
    } catch (e) {
      const error = e as AxiosError;

      if (error?.response?.status === 400) {
        notification.error({
          message: t("error.invalidQuery.title"),
          description: error?.message || t("error.invalidQuery.description"),
        });
      }
    }
  }, [
    fetchAdjacentDocumentSetIds,
    userInputIds,
    activeDocumentSet,
    overviewType,
    t,
  ]);
};

export default useAdjacentDocumentSetIds;

import React, { FC } from "react";
import { ITable } from "../../../interfaces/ITable";
import Table from "./Table";
import TableVisualizer from "./TableVisualizer";

type Props = {
  table: ITable;
  isCreatingTable: boolean;
  zIndex: number;
};

const TableRenderer: FC<Props> = ({ table, isCreatingTable, zIndex }) => {
  if (isCreatingTable) {
    return (
      <Table
        key={table.id}
        table={table}
        isCreatingTable={isCreatingTable}
        zIndex={zIndex}
      />
    );
  }

  return (
    <div
      style={{
        width: table.width,
        height: table.height,
        position: "absolute",
        top: table.y,
        left: table.x,
      }}
    >
      <TableVisualizer rows={table.rows} columns={table.columns} />
    </div>
  );
};

export default TableRenderer;

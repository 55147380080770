import React, { FC, useMemo } from "react";
import { isRequiredValidation } from "../commonValidation";
import { Validation } from "../../../../common/validation/constants";
import EditEntityDate from "../dateEntity/EditEntityDate";
import EntityTextVisual from "../EntityTextVisual";
import EntityValidation from "../EntityValidation";
import dayjs from "dayjs";
import { VALID_DATE_FORMATS } from "../../../../common/utilities/formats/dateFormats";
import useEntityAnnotation from "../../../hooks/useEntityAnnotation";
import VALIDATIONS_WEEK from "./validations";
import useEntityNormalization from "../../../hooks/useEntityNormalization";
import { SingleAnnotationProps } from "../SingleAnnotation";

const WeekEntity: FC<SingleAnnotationProps> = ({
  entityType,
  entityAnnotation,
  isEditing,
  isAnnotating,
  groupBlockIndex,
  requiresAttention,
  documentId,
  showLabels = true,
  isEditable = true,
  showNormalizedValue = true,
}) => {
  const { value, normalizedValue } = useEntityAnnotation(entityAnnotation);

  const { entityNormalizationFormat } = useEntityNormalization(
    entityType?.entityType?.entityNormalizations
  );

  const mergedValidations = useMemo((): Array<Validation> => {
    const output: Array<Validation> = [];
    if (entityType.required) {
      output.push(isRequiredValidation);
    }

    return [...output, ...VALIDATIONS_WEEK];
  }, [entityType.required]);

  return (
    <>
      {documentId && isEditing && !isAnnotating && isEditable ? (
        <EditEntityDate
          entityType={entityType}
          documentId={documentId}
          defaultValue={dayjs(
            normalizedValue ?? value,
            VALID_DATE_FORMATS["eu"]
          )}
          annotationId={entityAnnotation.id!}
          groupBlockIndex={groupBlockIndex}
          format={entityNormalizationFormat}
        />
      ) : (
        <EntityTextVisual
          value={value}
          normalizedValue={normalizedValue}
          showLabels={showLabels}
          showNormalizedValue={showNormalizedValue}
          requiresAttention={requiresAttention}
        />
      )}

      {isEditable && (
        <EntityValidation
          validationId={`${entityType.entityType.id}-${groupBlockIndex}`}
          value={value}
          normalizedValue={normalizedValue}
          validations={mergedValidations}
        />
      )}
    </>
  );
};

export default WeekEntity;

import {
  Validation,
  ValidationResult,
} from "../../common/validation/constants";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app";
import { selectActiveDocumentLanguage } from "../../document";
import { shouldBeValidated } from "../utils/validationUtils";

const defaultValidation = {
  show: false,
  type: null,
};

const useValidation = (
  value: string,
  validationId: string,
  validations: Array<Validation>
) => {
  const [validationResult, setValidationResult] =
    useState<ValidationResult>(defaultValidation);

  const docLanguage = useAppSelector(selectActiveDocumentLanguage);
  const blurredEntities = useAppSelector(
    (state) => state.appReducer.blurredEntities
  );

  useEffect(() => {
    let validated = false;

    validations.forEach((validation) => {
      const { requiresValue, awaitBlur, validate, type, message, icon } =
        validation;

      if (!shouldBeValidated(value, requiresValue)) {
        return;
      }

      if (!validate(value, docLanguage)) {
        setValidationResult({
          show: awaitBlur ? blurredEntities.includes(validationId) : true,
          type,
          message,
          icon,
        });
        validated = true;
      }
    });

    if (!validated) {
      setValidationResult(defaultValidation);
    }
  }, [value, validations, validationId, docLanguage, blurredEntities]);

  return validationResult;
};

export default useValidation;

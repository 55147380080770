import {
  Annotation,
  AnnotationParams,
  UpdatedAnnotations,
} from "../../annotator/interfaces/annotation";
import { primaryColor } from "../../common/utilities/color";
import { GroupBlockEntityType } from "../../configMap";
import {
  flattenGroupedOutput,
  groupAnnotations,
} from "../../annotation/utils/utils";

export const convertAnnotationsToAdd = (
  annotation: AnnotationParams,
  annotations: Array<Annotation>,
  entityList: Array<GroupBlockEntityType>
): UpdatedAnnotations => {
  const isInExistingAnnotation = annotations.some(
    (pa) =>
      pa.pageTokenIndices?.filter((tid) =>
        annotation.pageTokenIndices?.includes(tid)
      ).length > 0 && pa.page === annotation.page
  );
  if (isInExistingAnnotation) {
    return { updatedAnnotations: annotations, newAnnotation: undefined };
  }

  const entity = entityList.find(
    (entity) => entity.entityType.id === annotation.entity.id
  );
  if (!entity) {
    return { updatedAnnotations: annotations, newAnnotation: undefined };
  }

  const filteredAnnotations = annotations.filter(
    (a) => a.multipleGroupBlocks === entity.multipleGroupBlocks
  );

  const newAnnotation: Annotation = {
    id: `temp_${crypto.randomUUID()}`,
    ...annotation,
    entity: {
      id: entity.entityType.id,
      name: entity.entityType.id.toString(),
      color: entity.color || primaryColor,
      entityNormalizations: entity.entityType.entityNormalizations,
      entityType: "NER",
    },
    isOutput: false,
    multipleGroupBlocks: entity.multipleGroupBlocks,
  };

  const hasMultipleAnnotations =
    [...filteredAnnotations, newAnnotation].filter(
      (selectedAnnotation) =>
        selectedAnnotation?.entity?.id === annotation.entity.id &&
        selectedAnnotation?.index === annotation?.index
    ).length > 1;

  newAnnotation.triggerNormalization =
    !hasMultipleAnnotations && !!entity.entityType.entityNormalizations?.length;

  // Check and normalize first annotation of this entityAnnotation when it isn't normalized yet
  const prevAnnotationsWithTriggerNormalization = [...filteredAnnotations].map(
    (currentAnnotation) => {
      if (
        currentAnnotation.entity.id === entity.entityType.id &&
        currentAnnotation.isOutput &&
        !currentAnnotation.entityAnnotationNormalization &&
        entity.entityType.entityNormalizations?.length
      ) {
        return {
          ...currentAnnotation,
          triggerNormalization: true,
        };
      }

      return currentAnnotation;
    }
  );

  return {
    updatedAnnotations: [
      ...prevAnnotationsWithTriggerNormalization,
      newAnnotation,
    ],
    newAnnotation: newAnnotation,
  };
};

export const convertToRemoveAnnotations = (
  removeId: string,
  annotations: Array<Annotation>,
  multipleGroupBlocks: boolean
) => {
  const filteredAnnotations = annotations.filter(
    (a) => a.multipleGroupBlocks === multipleGroupBlocks
  );

  const annotationToDelete = filteredAnnotations.find((a) => a.id === removeId);

  if (annotationToDelete?.isOutput) {
    // Find new first annotation of entityAnnotation and normalize this if needed
    const groupedAnnotations = groupAnnotations(
      [...filteredAnnotations].filter((a) => a.id !== removeId)
    );
    const annotationsOutput = flattenGroupedOutput(groupedAnnotations);
    return annotationsOutput.map((annotation) => {
      if (
        annotation.entity.id === annotationToDelete.entity.id &&
        annotation.isOutput &&
        !annotation.entityAnnotationNormalization &&
        annotationToDelete.entity.entityNormalizations?.length
      ) {
        return {
          ...annotation,
          triggerNormalization: true,
        };
      }

      return annotation;
    });
  }

  return [...filteredAnnotations].filter((a) => a.id !== removeId);
};

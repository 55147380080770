import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  fetchDocumentSetsTrigger,
  removeDocumentSets,
  resetSelectedRows,
  updateSelectedRow,
} from "../store/documentSetSlice";
import http from "../../common/utilities/HttpModule";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import { NotificationBody } from "../../app/interfaces/notification";

const useBulkAction = () => {
  const { t } = useTranslation("notifications");

  const dispatch = useAppDispatch();

  const { selectedRows } = useAppSelector((state) => state.documentSetsReducer);

  const user = useAppSelector((state) => state.userReducer.user);

  const toggleBulkActionCheckbox = useCallback(
    (id: string) => {
      dispatch(updateSelectedRow(id));
    },
    [dispatch]
  );

  const resetRows = useCallback(() => {
    dispatch(resetSelectedRows());
  }, [dispatch]);

  const handleAction = useCallback(
    async (
      url: string,
      selectedRows: Array<string>,
      successNotification: NotificationBody,
      errorNotification: NotificationBody,
      errorLog: string
    ) => {
      if (!selectedRows.length) {
        return;
      }

      try {
        await http.post(url, selectedRows);

        dispatch(removeDocumentSets(selectedRows));
        resetRows();
        dispatch(fetchDocumentSetsTrigger());

        notification.success(successNotification);
      } catch (e) {
        console.error(errorLog);

        notification.error(errorNotification);
      }
    },
    [dispatch, resetRows]
  );

  const removeRows = useCallback(async () => {
    if (!selectedRows.length) {
      return;
    }

    const url = `api/v1/document-sets/remove?assignee=${encodeURI(user!.id)}`;

    const successNotification = {
      key: "deleteToast",
      message: t("documentsRemoved.success.message"),
      description: t("documentsRemoved.success.description"),
    };
    const errorNotification = {
      key: "deleteToast",
      message: t("documentsRemoved.error.message"),
      description: t("documentsRemoved.error.description"),
    };
    const errorLog = "Failed to remove document sets";

    await handleAction(
      url,
      selectedRows,
      successNotification,
      errorNotification,
      errorLog
    );
  }, [selectedRows, t, user, handleAction]);

  const rejectRows = useCallback(async () => {
    if (!selectedRows.length) {
      return;
    }

    const url = `api/v1/document-sets/reject?assignee=${encodeURI(user!.id)}`;

    const successNotification = {
      key: "rejectToast",
      message: t("documentsRejected.success.message"),
      description: t("documentsRejected.success.description"),
    };
    const errorNotification = {
      key: "rejectToast",
      message: t("documentsRejected.error.message"),
      description: t("documentsRejected.error.description"),
    };
    const errorLog = "Failed to reject document sets";

    await handleAction(
      url,
      selectedRows,
      successNotification,
      errorNotification,
      errorLog
    );
  }, [selectedRows, t, user, handleAction]);

  return {
    selectedRows,
    toggleBulkActionCheckbox,
    removeRows,
    rejectRows,
    resetRows,
  };
};

export default useBulkAction;

import React, { FC } from "react";
import usePasswordValidation from "../../../hooks/usePasswordValidation";
import { useTranslation } from "react-i18next";

type Props = {
  password: string;
};

const PasswordChecklist: FC<Props> = ({ password }) => {
  const { validations, isValidPassword } = usePasswordValidation(password);

  const { t } = useTranslation("personalPage", {
    keyPrefix: "password.validations",
  });

  if (isValidPassword) {
    return null;
  }

  return (
    <ul className="password-validation__list">
      {validations.map((val) => {
        if (val.passed) {
          return (
            <li className="valid" key={val.name}>
              <i className="bi bi-check-circle" />
              <label>{t(val.name)}</label>
            </li>
          );
        }

        return (
          <li className="error" key={val.name}>
            <i className="bi bi-x-circle" />
            <label>{t(val.name)}</label>
          </li>
        );
      })}
    </ul>
  );
};

export default PasswordChecklist;

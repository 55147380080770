import React, { FC, useEffect, useMemo, useRef } from "react";
import { Select, Tooltip } from "antd";
import useCategorization from "../../hooks/useCategorization";
import { getInLanguageOrDefault } from "../../../common/utilities/language";
import { useTranslation } from "react-i18next";
import CategoryVisibleValue from "./CategoryVisibleValue";
import SelectEmptyState from "./SelectEmptyState";
import { useAppSelector } from "../../../app";
import { BaseSelectRef } from "rc-select";
import { CategorizationWithConfig } from "../../../configMap/interfaces/category";
import LinkIcon from "./LinkIcon";

type Props = {
  disabled: boolean;
  categorizationWithConfig: CategorizationWithConfig;
  index: number;
  groupBlockId?: string;
};

const { Option } = Select;

const mapping = { value: "key" };

const Categorization: FC<Props> = ({
  categorizationWithConfig,
  disabled,
  index,
  groupBlockId,
}) => {
  const ref = useRef<BaseSelectRef | null>(null);
  const { label } = categorizationWithConfig.categorization;

  const { activeButton, isAnnotating } = useAppSelector(
    (state) => state.appReducer
  );

  const {
    i18n: { language },
  } = useTranslation();

  const {
    tabId,
    handleChange,
    selectedCategory,
    options,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
  } = useCategorization(categorizationWithConfig, index, groupBlockId);

  const className = useMemo(() => {
    if (disabled) {
      return "classifier-item__select-disabled";
    }

    let classes = "";
    if (isIncomplete) {
      classes += "classifier-item__select-is-incomplete";
    }
    if (isCategorizationDisabledByParent) {
      classes += " classifier-item__select-no-parent-value";
    }

    return classes;
  }, [disabled, isCategorizationDisabledByParent, isIncomplete]);

  useEffect(() => {
    if (!activeButton || !ref.current) {
      return;
    }

    if (activeButton.id !== tabId) {
      return;
    }

    ref.current!.focus();
  }, [activeButton, tabId]);

  useEffect(() => {
    if (isAnnotating) {
      ref.current!.blur();
    }
  }, [isAnnotating]);

  return (
    <div className="classifier-item__container">
      <div className="classifier-item__data-wrapper">
        <LinkIcon categorization={categorizationWithConfig.categorization} />
        <div className="classifier-item__label-wrapper">
          <label>{`${getInLanguageOrDefault(label, language)} ${
            categorizationWithConfig.required ? "*" : ""
          }`}</label>
          <CategoryVisibleValue visibleValue={selectedCategory?.visibleValue} />
        </div>
      </div>
      <Tooltip
        title={isCategorizationDisabledByParent && disabledParentTooltip}
      >
        <Select
          open={disableTyping}
          ref={ref}
          allowClear
          showSearch
          value={selectedCategory?.value}
          placeholder="Select a value"
          optionFilterProp="children"
          onChange={(value) => handleChange(value, ref.current!.blur)}
          onFocus={handleTypingChange}
          onBlur={handleTypingChange}
          onSearch={handleSearch}
          filterOption={handleFilter}
          notFoundContent={<SelectEmptyState />}
          fieldNames={mapping}
          disabled={disabled || isCategorizationDisabledByParent}
          className={className}
          status={status}
        >
          {options.map((o) => {
            const { label, value, key } = o;
            const split = label.split(/\n/);

            if (split.length > 1) {
              const height = split.length * 25;
              return (
                <Option
                  key={key}
                  value={value}
                  label={label}
                  style={{ height: `${height}px` }}
                >
                  <ul>
                    {split.map((s) => (
                      <li key={s}>{s}</li>
                    ))}
                  </ul>
                </Option>
              );
            }

            return (
              <Option key={key} value={value} label={label}>
                {label}
              </Option>
            );
          })}
        </Select>
      </Tooltip>
    </div>
  );
};

export default Categorization;

import { useAppDispatch } from "../../app";
import { useCallback } from "react";
import { DocumentSetData } from "../interfaces/documentSet";
import { updateDocumentSet } from "../store/documentSetSlice";

const useUpdateDocumentSet = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (documentSetAction: DocumentSetData) => {
      dispatch(updateDocumentSet(documentSetAction));
    },
    [dispatch]
  );
};

export default useUpdateDocumentSet;

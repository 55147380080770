import { EmailTemplate } from "../interfaces/table";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { AddEmailTemplateBody } from "../interfaces/api";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";
import { fetchedEmailTemplates } from "./emailTemplatesSlice";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const emailTemplatesApi = createApi({
  reducerPath: "emailTemplatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    fetchEmailTemplates: build.query<Array<EmailTemplate>, string>({
      query: (userId) => ({
        url: `api/v1/user/${userId}/email-templates`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchedEmailTemplates(data));
        } catch (err: any) {
          console.error(
            `An error occurred while retrieving the email templates: ${parseReduxQueryError(
              err
            )}`
          );
        }
      },
    }),
    removeEmailTemplate: build.mutation<void, string>({
      query: (id) => ({
        url: `api/v1/email-templates/delete/${id}`,
        method: "DELETE",
      }),
    }),
    addEmailTemplate: build.mutation<EmailTemplate, AddEmailTemplateBody>({
      query: ({ emailTemplate, userId }) => ({
        url: `api/v1/user/${userId}/email-template`,
        body: emailTemplate,
        method: "POST",
      }),
    }),
    updateEmailTemplate: build.mutation<void, EmailTemplate>({
      query: (emailTemplate) => ({
        url: `api/v1/email-templates/update`,
        body: emailTemplate,
        method: "PUT",
      }),
    }),
    setFavoriteEmailTemplate: build.mutation<
      void,
      { emailTemplateId: string; userId: string }
    >({
      query: ({ emailTemplateId, userId }) => ({
        url: `api/v1/email-templates/set-favorite/${emailTemplateId}/${userId}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useFetchEmailTemplatesQuery,
  useRemoveEmailTemplateMutation,
  useAddEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} = emailTemplatesApi;

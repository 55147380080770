import { RootState } from "../../app";
import { ICategorization } from "../../configMap/interfaces/category";
import { createSelector } from "@reduxjs/toolkit";
import { ConfigMap } from "../../configMap";

const EMPTY_ARRAY: Array<ICategorization> = [];

type Props = {
  withConfig?: boolean;
};

const selectConfigMap = (state: RootState) => state.configMapReducer.configMap;

const selectWithConfig = (_: RootState, props: Props) => props.withConfig;

const selectCategorizations = createSelector(
  selectConfigMap,
  selectWithConfig,
  (
    configMap: ConfigMap | undefined,
    selectWithConfig?: boolean | undefined
  ) => {
    if (!configMap) {
      return EMPTY_ARRAY;
    }

    if (selectWithConfig) {
      return [
        ...configMap.categorizationConfigMaps,
        ...configMap.groupBlocks.flatMap((g) => g.categorizationGroupBlocks),
      ];
    }

    const configMapCategorizations = configMap.categorizationConfigMaps.map(
      (ccm) => ccm.categorization
    );
    const groupBlockCategorizations = configMap.groupBlocks.flatMap((gb) =>
      gb.categorizationGroupBlocks.map((cgb) => cgb.categorization)
    );
    return [...configMapCategorizations, ...groupBlockCategorizations];
  }
);

export default selectCategorizations;

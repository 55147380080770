import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo } from "react";
import { MemberRole, TableData } from "../interfaces/table";
import { fetchedMemberList } from "../store/UserManagementSlice";
import {
  useAddRoleApiMutation,
  useDeleteRoleApiMutation,
} from "../store/UserManagementApi";
import { ADMIN_ROLE_ID } from "../../common/utilities/env";

const useMemberRoles = () => {
  const dispatch = useAppDispatch();
  const { validRoles, editingMemberId, members } = useAppSelector(
    (state) => state.userManagementReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);

  const [deleteRoleApi] = useDeleteRoleApiMutation();

  const [addRoleApi] = useAddRoleApiMutation();

  const roles = useMemo(() => {
    const foundMember = members.find((m) => m.id === editingMemberId);

    if (foundMember) {
      return validRoles.map((vr): MemberRole => {
        const found = foundMember.roles.find((d) => d === vr.id);

        if (found) {
          return {
            ...vr,
            isActive: true,
          };
        }

        return {
          ...vr,
          isActive: false,
        };
      });
    }

    return [];
  }, [editingMemberId, members, validRoles]);

  const roleIds = useMemo(() => {
    const foundMember = members.find((m) => m.id === editingMemberId);

    if (foundMember) {
      return foundMember.roles;
    }

    return [];
  }, [editingMemberId, members]);

  const updateMemberRolesInRedux = useCallback(
    (roles: Array<string>) => {
      const membersUpdated = members.map((m): TableData => {
        if (m.id === editingMemberId) {
          return {
            ...m,
            roles,
          };
        }

        return m;
      });

      dispatch(fetchedMemberList(membersUpdated));
    },
    [dispatch, members, editingMemberId]
  );

  const deleteRole = useCallback(
    async (roleId: string) => {
      if (!user?.access.includes(ADMIN_ROLE_ID)) {
        return;
      }

      if (!editingMemberId || !user?.organization.id) {
        return;
      }

      const roleToRemove = roles.find((r) => r.id === roleId);

      if (!roleToRemove) {
        return;
      }

      const originalRoles = [...roleIds];
      const newRoles = [...roleIds].filter((r) => r !== roleToRemove.id);
      try {
        const data = {
          roles: [roleToRemove.id],
        };

        updateMemberRolesInRedux(newRoles);
        await deleteRoleApi({
          organizationId: user.organization.id,
          memberId: editingMemberId,
          data,
        }).unwrap();
      } catch (e) {
        updateMemberRolesInRedux(originalRoles);
        console.log(e);
      }
    },
    [
      user,
      editingMemberId,
      roleIds,
      roles,
      updateMemberRolesInRedux,
      deleteRoleApi,
    ]
  );

  const addRole = useCallback(
    async (roleId: string) => {
      if (!user?.access.includes(ADMIN_ROLE_ID)) {
        return;
      }

      if (!user?.organization.id || !editingMemberId) {
        return;
      }

      const data = {
        roles: [roleId],
      };

      const originalRoles = [...roleIds];
      const newRoles = [...roleIds, roleId];

      try {
        updateMemberRolesInRedux(newRoles);
        await addRoleApi({
          organizationId: user.organization.id,
          memberId: editingMemberId,
          data,
        }).unwrap();
      } catch (e) {
        updateMemberRolesInRedux(originalRoles);
        console.log(e);
      }
    },
    [user, editingMemberId, roleIds, updateMemberRolesInRedux, addRoleApi]
  );

  const handleCheckedChange = useCallback(
    async (checked: boolean, roleId: string) => {
      if (checked) {
        await addRole(roleId);
        return;
      }

      await deleteRole(roleId);
    },
    [addRole, deleteRole]
  );

  return {
    roles,
    handleCheckedChange,
  };
};

export default useMemberRoles;

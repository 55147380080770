import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useMemo, useState } from "react";
import { setStpEntities } from "../store/organizationSlice";
import {
  AddStpEntity,
  IEntityStpTableData,
  IStpEntityResponse,
} from "../interfaces/stp";
import { notification } from "antd";
import { useLazyAddOrUpdateStpEntityQuery } from "../store/organizationApi";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import selectConfigMapEntities from "../selectors/selectConfigMapEntities";
import { doesStpEntityExist, isSourceLinkedToSameEntity } from "../utils/utils";
import { Option } from "../../common/dataRefining/interfaces/filteringData";

const useAddStpEntity = (cancel: (isAdding: boolean) => void) => {
  const dispatch = useAppDispatch();

  const { stpEntitiesDocumentType, stpEntities } = useAppSelector(
    (state) => state.organizationReducer.settings.stp
  );

  const { documentTypeId } = useAppSelector(
    (state) => state.organizationReducer.settings
  );

  const selectedConfigMapEntities = useAppSelector(selectConfigMapEntities);

  const [addOrUpdateStpEntity] = useLazyAddOrUpdateStpEntityQuery();

  const [stpEntity, setStpEntity] = useState<AddStpEntity>({
    threshold: 100,
    isActive: true,
    entityValidation: null,
    id: null,
  });

  const handleCancel = useCallback(() => {
    setStpEntity({
      threshold: 100,
      isActive: true,
      entityValidation: null,
      id: null,
    });

    cancel(false);
  }, [cancel]);

  const handleClick = useCallback(async () => {
    try {
      if (!stpEntity || !documentTypeId) {
        return;
      }

      if (!stpEntity.entityTypeId) {
        notification.error({
          message: "Entity is required.",
        });
        return;
      }

      if (stpEntity.threshold < 50 || stpEntity.threshold > 100) {
        notification.error({
          message: "Threshold must be between 50 and 100.",
        });
        return;
      }

      if (stpEntity.isActive && !stpEntity.sources?.length) {
        notification.error({
          message: "Sources are required for an active STP entity.",
        });
        return;
      }

      const newStpEntity: IStpEntityResponse = {
        ...stpEntity,
        id: null,
        entityTypeId: stpEntity.entityTypeId,
        documentTypeId,
      };

      if (doesStpEntityExist(stpEntities, newStpEntity, documentTypeId)) {
        notification.error({
          message: "This STP entity already exists.",
        });
        return;
      }
      if (
        isSourceLinkedToSameEntity(stpEntities, newStpEntity, documentTypeId)
      ) {
        notification.error({
          message:
            "The source(s) are already linked to the same entity in another STP entity.",
        });
        return;
      }

      const data = await addOrUpdateStpEntity(newStpEntity).unwrap();

      const found = selectedConfigMapEntities.find(
        (entity) => entity.entityType.id === stpEntity.entityTypeId
      );

      const addedStpEntity: IEntityStpTableData = {
        ...newStpEntity,
        id: data.id,
        key: data.id!,
        name: found
          ? getInLanguageOrDefault(found.entityType.translations, "en")
          : "n/a",
      };

      const updatedEntities: Array<IEntityStpTableData> = [
        ...stpEntities,
        addedStpEntity,
      ];
      dispatch(setStpEntities({ entities: updatedEntities }));

      handleCancel();
    } catch (e) {
      console.error(e);
    }
  }, [
    dispatch,
    stpEntity,
    stpEntities,
    documentTypeId,
    handleCancel,
    addOrUpdateStpEntity,
    selectedConfigMapEntities,
  ]);

  const options = useMemo(() => {
    return stpEntitiesDocumentType.map((entity) => ({
      value: entity.entityTypeId,
      label: entity.name,
    }));
  }, [stpEntitiesDocumentType]);

  const onSelectSearch = useCallback(
    (input: string, option: Option | undefined) => {
      return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
    },
    []
  );

  return {
    stpEntitiesDocumentType,
    options,
    stpEntity,
    setStpEntity,
    handleClick,
    onSelectSearch,
  };
};

export default useAddStpEntity;

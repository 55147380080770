import React, { FC, useMemo } from "react";
import { ICol, IRow } from "../../../interfaces/ITable";

type Props = {
  rows: Array<IRow>;
  columns: Array<ICol>;
};

const TableVisualizer: FC<Props> = ({ rows, columns }) => {
  const style = useMemo(() => {
    const rowTemplate = rows.map((r) => `${r.height}px`).join(" ");
    const columnTemplate = columns.map((r) => `${r.width}px`).join(" ");

    return {
      display: "grid",
      gridTemplateRows: rowTemplate,
      gridTemplateColumns: columnTemplate,
      outline: "8px solid #69c0ff",
    };
  }, [rows, columns]);
  return (
    <div style={style}>
      {Array(rows.length * columns.length)
        .fill(0)
        .map((_, i) => (
          <div style={{ outline: "1px solid #69c0ff" }} key={i} />
        ))}
    </div>
  );
};

export default TableVisualizer;

import React, { FC } from "react";
import { Modal, Button } from "antd";
import "./ConfirmRemoveModal.scss";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  loading: boolean;
  setOpen: (value: boolean) => void;
  deleteEmailTemplate: () => void;
};

const ConfirmRemoveModal: FC<Props> = ({
  open,
  loading,
  setOpen,
  deleteEmailTemplate,
}) => {
  const { t } = useTranslation("emailTemplates");

  return (
    <Modal
      onCancel={() => setOpen(false)}
      open={open}
      footer={[
        <Button key="leave" danger onClick={() => setOpen(false)}>
          {t("removeModal.cancelButton")}
        </Button>,
        <Button
          key="leaveSave"
          type="primary"
          onClick={deleteEmailTemplate}
          loading={loading}
        >
          {t("removeModal.removeButton")}
        </Button>,
      ]}
    >
      <div className="confirm-remove-modal__content-container">
        <i className="bi bi-exclamation-circle" style={{ color: "#faad14" }} />
        <h3>{t("removeModal.header")}</h3>
        <div />
      </div>
    </Modal>
  );
};

export default ConfirmRemoveModal;

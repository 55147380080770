import React, { FC } from "react";
import Loader from "../../../../../../common/loader/Loader";
import "./DocumentPreview.scss";
import useDocumentPreview from "../../../../../hooks/useDocumentPreview";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  documentId: string;
};

const DocumentPreview: FC<Props> = ({ documentId }) => {
  const { t } = useTranslation("overview");

  const { pdfFile, isError, isLoading } = useDocumentPreview(documentId);

  if (isLoading) {
    return (
      <div className="document-preview__container">
        <Loader size="default" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="document-preview__container">
        <Alert
          message={t("documentPreviewErrorAlert")}
          type="warning"
          showIcon
          className="error-alert"
        />
      </div>
    );
  }

  return (
    <iframe
      title="Preview"
      src={`data:application/pdf;base64,${pdfFile}#toolbar=0`}
      className="document-preview__container"
      loading="lazy"
    />
  );
};

export default DocumentPreview;

import React, { FC } from "react";
import { Tooltip } from "antd";
import "./HeaderWithTitle.scss";

type Props = {
  text: string | null | undefined;
  maxLength: number;
};

const TextWithMaxCharacters: FC<Props> = ({ text, maxLength }) => {
  if (!text) {
    return null;
  }

  if (text?.length > maxLength) {
    return (
      <Tooltip title={text}>{`${text.substring(0, maxLength)}...`}</Tooltip>
    );
  }

  return <>{text}</>;
};

export default TextWithMaxCharacters;

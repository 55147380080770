import React from "react";
import { Tooltip } from "antd";
import useHandlePreviousOrNextDocSet from "../../../../documentSet/hooks/useHandlePreviousOrNextDocSet";
import ChangesFoundWhileNavigatingModal from "../../../../document/components/emptyNavigationHeader/ChangesFoundWhileNavigatingModal";
import { useTranslation } from "react-i18next";

const PreviousNextButton = () => {
  const { t } = useTranslation("header", { keyPrefix: "buttons" });

  const {
    openChangesModal,
    setOpenChangesModal,
    checkChangesAndGoToDocumentSet,
    goToNextOrPreviousDocumentSet,
    adjacentDocumentSetIds,
    isNextDocument,
    isFetching,
  } = useHandlePreviousOrNextDocSet();

  return (
    <>
      <ChangesFoundWhileNavigatingModal
        openModal={openChangesModal}
        setOpenModal={setOpenChangesModal}
        isNextDocument={isNextDocument}
        ignoreChangesAndProceed={() =>
          goToNextOrPreviousDocumentSet(adjacentDocumentSetIds, isNextDocument)
        }
      />
      <div className="flex__wrapper">
        <Tooltip title={t("previous")}>
          <div
            onClick={() => checkChangesAndGoToDocumentSet(false)}
            role="button"
            className={`header-with-title__back-button ${
              isFetching ? "disabled" : ""
            }`}
          >
            <i className="bi-arrow-up-short controls__button-icon" />
          </div>
        </Tooltip>
        <Tooltip title={t("next")}>
          <div
            onClick={() => checkChangesAndGoToDocumentSet(true)}
            role="button"
            className={`header-with-title__back-button ${
              isFetching ? "disabled" : ""
            }`}
          >
            <i className="bi-arrow-down-short controls__button-icon" />
          </div>
        </Tooltip>
      </div>
      <span className="header-with-title__divider" />
    </>
  );
};

export default PreviousNextButton;

import { AnalyticsBrowser } from "@segment/analytics-next";
import { getVariableFromEnv } from "../common/utilities/env";

const writeKey = getVariableFromEnv("SEGMENT_WRITE_KEY");
const environment = getVariableFromEnv("ENVIRONMENT");

export const analytics =
  environment.toLowerCase() === "production"
    ? AnalyticsBrowser.load({ writeKey })
    : null;

import {
  PDF_BASE_HEIGHT_LANDSCAPE,
  PDF_BASE_HEIGHT_PORTRAIT,
  PDF_BASE_WIDTH_LANDSCAPE,
  PDF_BASE_WIDTH_PORTRAIT,
} from "../constants";
import { IDimension } from "../../annotator/interfaces/pdf";
import { ITextVertex } from "../../annotator/interfaces/textLayer";
import { EDocumentOrientation } from "../../document/interfaces";
import { store } from "../../app";

const determineOrientation = (
  width: number,
  height: number
): EDocumentOrientation => {
  if (width > height) {
    return EDocumentOrientation.LANDSCAPE;
  } else if (height > width) {
    return EDocumentOrientation.PORTRAIT;
  } else {
    return EDocumentOrientation.SQUARE;
  }
};

export const calculateScalingFactor = (
  width: number,
  height: number
): [number, number] => {
  const scale = store.getState().annotationReducer.scale;

  if (determineOrientation(width, height) === EDocumentOrientation.PORTRAIT) {
    const widthScalingFactor = width / (PDF_BASE_WIDTH_PORTRAIT * scale);
    const heightScalingFactor = height / (PDF_BASE_HEIGHT_PORTRAIT * scale);

    return [widthScalingFactor, heightScalingFactor];
  }

  const widthScalingFactor = width / (PDF_BASE_WIDTH_LANDSCAPE * scale);
  const heightScalingFactor = height / (PDF_BASE_HEIGHT_LANDSCAPE * scale);

  return [widthScalingFactor, heightScalingFactor];
};

export const recalculateDimensions = (
  dimension: IDimension,
  oldScale: number,
  newScale: number
): IDimension => {
  return {
    ...dimension,
    width: (dimension.width / oldScale) * newScale,
    height: (dimension.height / oldScale) * newScale,
  };
};

export const scalePDFPageTokens = (
  tokens: Array<ITextVertex>,
  oldScale: number,
  newScale: number
): Array<ITextVertex> => {
  return tokens.map(
    (t): ITextVertex => ({
      ...t,
      width: (t.width / oldScale) * newScale,
      height: (t.height / oldScale) * newScale,
      left: (t.left / oldScale) * newScale,
      top: (t.top / oldScale) * newScale,
    })
  );
};

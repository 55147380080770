import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";

const selectAnnotationReducer = createSelector(
  (state: RootState) => state.annotationReducer.tableControlAnnotations,
  (state: RootState) => state.annotationReducer.sideControlAnnotations,
  (tableControlAnnotations, sideControlAnnotations) => [
    ...tableControlAnnotations,
    ...sideControlAnnotations,
  ]
);

export default selectAnnotationReducer;

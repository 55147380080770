import { useCallback, useEffect, useState } from "react";
import { throttle } from "lodash";
import useAnnotatorConfig from "./useAnnotatorConfig";
import useTableUpdater from "./useTableUpdater";

const useControls = () => {
  const { undoOrRedoLastAction, lastAction } = useTableUpdater();

  const {
    pages,
    createTable,
    setCreateTable,
    currentPage,
    setCurrentPage,
    scale,
    setScale,
    readonly,
  } = useAnnotatorConfig();

  const [undoDisabled, setUndoDisable] = useState(true);
  const [redoDisabled, setRedoDisabled] = useState(true);

  useEffect(() => {
    if (lastAction === "undo") {
      setUndoDisable(false);
      setRedoDisabled(true);
    }

    if (lastAction === "redo") {
      setUndoDisable(true);
      setRedoDisabled(false);
    }
  }, [lastAction]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incrementScale = useCallback(
    throttle((increment: number) => {
      const newScale = Math.round((scale + increment) * 10) / 10;
      setScale(newScale);
    }, 250),
    [setScale, scale]
  );

  const handleScaleChange = useCallback(
    (targetScale: number) => {
      if (targetScale < 0.5) {
        setScale(0.5);
        return;
      }

      setScale(Math.round(targetScale * 10) / 10);
    },
    [setScale]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      if (page > pages || page <= 0) {
        return;
      }

      setCurrentPage(page);
    },
    [pages, setCurrentPage]
  );

  const handleUndoOrRedoClick = useCallback(
    (isUndo: boolean) => {
      if (isUndo && undoDisabled) {
        return;
      }

      if (!isUndo && redoDisabled) {
        return;
      }

      undoOrRedoLastAction();
    },
    [undoOrRedoLastAction, undoDisabled, redoDisabled]
  );

  const handleCreateTableClick = useCallback(() => {
    if (readonly) {
      return;
    }

    setCreateTable(!createTable);
  }, [readonly, setCreateTable, createTable]);

  return {
    scale,
    handleScaleChange,
    incrementScale,
    createTable,
    currentPage,
    handlePageChange,
    pages,
    handleUndoOrRedoClick,
    undoDisabled,
    redoDisabled,
    readonly,
    handleCreateTableClick,
    setCreateTable,
  };
};

export default useControls;

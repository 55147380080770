import React, { FC } from "react";

type Props = {
  visible: boolean;
  tableId: string;
  id: string;
  style: any;
  callBack: (tableId: string, id: string) => void;
};

const RemoveButton: FC<Props> = ({ visible, tableId, id, style, callBack }) => {
  if (!visible) {
    return null;
  }

  return (
    <span
      className="bi bi-dash-circle-fill table-line__remove-button"
      style={style}
      onClick={() => callBack(tableId, id)}
    />
  );
};

export default RemoveButton;

import React, { FunctionComponent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import klassifIcon from "../../assets/klassifaiWhiteIcon.svg";
import { Badge, Layout, Menu, Popover } from "antd";
import "./SideMenu.scss";
import { useTranslation } from "react-i18next";
import { OverviewType, useAppSelector } from "../../../app";
import { useDocumentSetCounter } from "../../../documentSet";
import { getNormalizedNumber } from "../../utilities/number";
import {
  AccountPopoverButton,
  AccountPopoverTitle,
} from "./accountPopover/AccountPopover";
import { CustomAvatar } from "../../avatar";
import useAccountPopover from "../hooks/useAccountPopover";
import OrganizationAvatar from "../../avatar/organizationAvatar/OrganizationAvatar";
import useSideMenuClick from "../hooks/useSideMenuClick";
import { validateAccess } from "../utils/utils";
import { METRICS_ROLE_ID } from "../../utilities/env";

const SIDE_MENU_WIDTH = 85;

type SideMenuProps = {
  activeKey: string;
};

const SideMenu: FunctionComponent<SideMenuProps> = ({ activeKey }) => {
  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );
  const access = useAppSelector((state) => state.userReducer?.user?.access);

  const { t } = useTranslation(["header", "accountPopover"]);

  const { overview, error, remove } = useDocumentSetCounter();

  const { user, avatar } = useAccountPopover();

  const { pathname } = useLocation();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const onSideMenuClickChecks = useSideMenuClick();

  const handleOpenChange = (open: boolean) => setPopoverOpen(open);

  if (isAnnotationMode) {
    return (
      <Layout.Sider
        width={SIDE_MENU_WIDTH}
        trigger={null}
        id="tour-overview-step-2"
      >
        <div className="side-menu__container">
          <div className="side-menu__logo-container">
            <img
              className="side-menu__logo"
              src={klassifIcon}
              alt="Klassifai logo"
            />
          </div>
          <Menu className="side-menu__items" selectable={false}>
            <Menu.Item
              key="home"
              className={`side-menu__item ${
                activeKey === "home" ? "active" : ""
              }`.trim()}
              icon={<i className="bi bi-highlighter side-menu__item-icon" />}
            >
              <Link
                to="/"
                className="side-menu__item-link"
                state={{ previousPath: pathname }}
                onClick={() =>
                  onSideMenuClickChecks(OverviewType.AnnotationTodo)
                }
              >
                {t("sideMenu.home")}
              </Link>
            </Menu.Item>
            <Menu.Item
              key="archive"
              className={`side-menu__item ${
                activeKey === "archive" ? "active" : ""
              }`.trim()}
              icon={
                <i className="bi bi-file-earmark-check side-menu__item-icon" />
              }
            >
              <Link
                to="/archive"
                className="side-menu__item-link"
                state={{ previousPath: pathname }}
                onClick={() =>
                  onSideMenuClickChecks(OverviewType.AnnotationDone)
                }
              >
                {t("sideMenu.archive")}
              </Link>
            </Menu.Item>
          </Menu>
          <div className={`side-menu__profile-container`}>
            <OrganizationAvatar size={50} placement={"right"} />
            {user && (
              <Popover
                id="tour-overview-step-3"
                placement="rightTop"
                title={
                  <AccountPopoverTitle
                    avatar={avatar}
                    firstName={user?.firstName}
                    lastName={user?.lastName}
                    username={user?.username}
                    callback={() => setPopoverOpen(false)}
                  />
                }
                content={<AccountPopoverButton />}
                trigger="click"
                overlayClassName="account-popover__overlay"
                onOpenChange={handleOpenChange}
                open={popoverOpen}
              >
                <div className="side-menu__profile-account">
                  <CustomAvatar size={50} src={avatar} />
                </div>
              </Popover>
            )}
          </div>
        </div>
      </Layout.Sider>
    );
  }

  return (
    <Layout.Sider
      width={SIDE_MENU_WIDTH}
      trigger={null}
      id="tour-overview-step-2"
    >
      <div className="side-menu__container">
        <div className="side-menu__logo-container">
          <img
            className="side-menu__logo"
            src={klassifIcon}
            alt="Klassifai logo"
          />
        </div>
        <Menu className="side-menu__items" selectable={false}>
          <Menu.Item
            key="home"
            className={`side-menu__item ${
              activeKey === "home" ? "active" : ""
            }`.trim()}
            icon={
              <Badge
                style={{ paddingTop: "1px" }}
                offset={[0, 0]}
                size="small"
                count={getNormalizedNumber(overview)}
                color="#38b2ac"
              >
                <i className="bi bi-house-door side-menu__item-icon" />
              </Badge>
            }
          >
            <Link
              to="/"
              className="side-menu__item-link"
              state={{ previousPath: pathname }}
              onClick={() => onSideMenuClickChecks(OverviewType.Home)}
            >
              {t("sideMenu.home")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="archive"
            className={`side-menu__item ${
              activeKey === "archive" ? "active" : ""
            }`.trim()}
            icon={<i className="bi bi-archive side-menu__item-icon" />}
          >
            <Link
              to="/archive"
              className="side-menu__item-link"
              state={{ previousPath: pathname }}
              onClick={() => onSideMenuClickChecks(OverviewType.Archived)}
            >
              {t("sideMenu.archive")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="error"
            className={`side-menu__item ${
              activeKey === "error" ? "active" : ""
            }`.trim()}
            icon={
              <Badge
                style={{ paddingTop: "1px" }}
                offset={[0, 0]}
                size="small"
                count={getNormalizedNumber(error)}
              >
                <i className="bi bi-file-earmark-x side-menu__item-icon" />
              </Badge>
            }
          >
            <Link
              to="/errors"
              className="side-menu__item-link"
              state={{ previousPath: pathname }}
              onClick={() => onSideMenuClickChecks(OverviewType.Error)}
            >
              {t("sideMenu.error")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="remove"
            className={`side-menu__item ${
              activeKey === "remove" ? "active" : ""
            }`.trim()}
            icon={
              <Badge
                style={{ paddingTop: "1px" }}
                offset={[0, 0]}
                size="small"
                count={getNormalizedNumber(remove)}
                color="#38b2ac"
              >
                <i className="bi bi-trash side-menu__item-icon" />
              </Badge>
            }
          >
            <Link
              to="/remove"
              className="side-menu__item-link"
              state={{ previousPath: pathname }}
              onClick={() => onSideMenuClickChecks(OverviewType.Remove)}
            >
              {t("sideMenu.remove")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="notifications"
            className="side-menu__item hidden"
            icon={<i className="bi bi-bell side-menu__item-icon" />}
            disabled
          >
            {t("sideMenu.inbox")}
          </Menu.Item>
          <Menu.Item
            key="tasks"
            className="side-menu__item hidden"
            icon={<i className="bi bi-check-circle side-menu__item-icon" />}
            disabled
          >
            {t("sideMenu.myTasks")}
          </Menu.Item>
          <Menu.Item
            key="analytics"
            className={`side-menu__item 
            ${activeKey === "analytics" ? "active" : ""}
            ${!access?.includes(METRICS_ROLE_ID) ? "hidden" : ""}
            `.trim()}
            icon={<i className="bi bi-graph-up side-menu__item-icon" />}
            disabled={!access?.includes(METRICS_ROLE_ID)}
          >
            <Link
              to="/analytics"
              className="side-menu__item-link"
              state={{ previousPath: pathname }}
              onClick={() => onSideMenuClickChecks(null)}
            >
              {t("sideMenu.analytics")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="organizationsSettings"
            className={`side-menu__item 
            ${activeKey === "organizationsSettings" ? "active" : ""}
            ${!validateAccess(access) ? "hidden" : ""}
            `.trim()}
            icon={<i className="bi bi-building-gear side-menu__item-icon" />}
            disabled={!validateAccess(access)}
          >
            <Link
              to="/organization/settings"
              state={{ previousPath: pathname }}
              className="side-menu__item-link"
              onClick={() => onSideMenuClickChecks(null)}
            >
              {t("sideMenu.organizationSettings")}
            </Link>
          </Menu.Item>
        </Menu>
        <div className={`side-menu__profile-container`}>
          <OrganizationAvatar size={50} placement={"right"} />
          {user && (
            <Popover
              placement="rightTop"
              title={
                <AccountPopoverTitle
                  avatar={avatar}
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  username={user?.username}
                  callback={() => setPopoverOpen(false)}
                />
              }
              content={<AccountPopoverButton />}
              trigger="click"
              overlayClassName="account-popover__overlay"
              onOpenChange={handleOpenChange}
              open={popoverOpen}
            >
              <div
                className="side-menu__profile-account"
                id="tour-overview-step-3"
              >
                <CustomAvatar size={50} src={avatar} />
              </div>
            </Popover>
          )}
        </div>
      </div>
    </Layout.Sider>
  );
};

export default SideMenu;

import { useInView } from "react-intersection-observer";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  changePageInView,
  removePageDataFromPdf,
} from "../store/documentSlice";
import shouldFetchPageDataSelector from "../selectors/shouldFetchPageDataSelector";
import { useCallback, useEffect } from "react";

const useTrackPagesInView = (page: number) => {
  const dispatch = useAppDispatch();

  const handleInViewChange = useCallback(
    (inView: boolean) => {
      dispatch(changePageInView({ page, inView }));
    },
    [dispatch, page]
  );

  const { ref, inView } = useInView({
    threshold: 0,
    onChange: handleInViewChange,
    initialInView: page === 0,
  });

  const shouldFetchPageData = useAppSelector((state) =>
    shouldFetchPageDataSelector(state, page)
  );

  useEffect(() => {
    if (!shouldFetchPageData) {
      dispatch(removePageDataFromPdf(page));
    }
  }, [page, shouldFetchPageData, dispatch]);

  return { ref, shouldFetchPageData, inView };
};

export default useTrackPagesInView;

import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app";
import { notification } from "antd";
import { AdjacentDocumentSetIds } from "../interfaces/documentSet";
import useReduxReset from "../../common/reset";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";
import useDetailPageRouting from "../../app/hooks/useDetailPageRouting";
import useAdjacentDocumentSetIds from "./useAdjacentDocumentSetIds";
import { useTranslation } from "react-i18next";
import useKeyPressedListener from "../../common/utilities/hooks/useKeyPressedListener";

const useHandlePreviousOrNextDocSet = () => {
  const { keyPressed: arrowUpPressed, resetState: resetUp } =
    useKeyPressedListener("ARROWUP", true);
  const { keyPressed: arrowDownPressed, resetState: resetDown } =
    useKeyPressedListener("ARROWDOWN", true);

  const [openChangesModal, setOpenChangesModal] = useState(false);
  const [adjacentDocumentSetIds, setAdjacentDocumentSetIds] =
    useState<AdjacentDocumentSetIds>();
  const [isNextDocument, setIsNextDocument] = useState<boolean>();

  const [isFetching, setIsFetching] = useState<boolean>();

  const { t } = useTranslation("detail", {
    keyPrefix: "controlsNavigationHeader",
  });

  const { detailReset } = useReduxReset();

  const { navigateWithState } = useNavigateWithState();

  const {
    disabled,
    newStatus,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
  } = useDetailPageRouting();

  const { activeEntity, hasBeenEdited } = useAppSelector(
    (rootState) => rootState.appReducer
  );

  const getAdjacentDocumentSetIds = useAdjacentDocumentSetIds();

  const goToNextOrPreviousDocumentSet = useCallback(
    async (
      data: AdjacentDocumentSetIds | undefined,
      goToNext: boolean | undefined
    ) => {
      try {
        if (!activeDocumentSet || isFetching) {
          return;
        }

        setOpenChangesModal(false);

        if (
          !(goToNext && data?.nextDocumentSetId) &&
          !(!goToNext && data?.previousDocumentSetId)
        ) {
          notification.warning({
            message: t("noDocumentSetForNavigation").replace(
              "$KEY",
              goToNext ? t("next") : t("previous")
            ),
          });
          return;
        }

        if (!disabled) {
          await updateAssigneeAndStatusWithExceptionHandling(
            activeDocumentSet,
            newStatus
          );
        }
        detailReset();

        if (goToNext) {
          navigateWithState(`/detail/${data.nextDocumentSetId}`);
        } else {
          navigateWithState(`/detail/${data.previousDocumentSetId}`);
        }
      } catch (error: any) {
        notification.error({
          message: t("errorWhileGettingDocumentSet").replace(
            "$KEY",
            goToNext ? t("next") : t("previous")
          ),
        });
      } finally {
        setIsFetching(false);
      }
    },
    [
      detailReset,
      updateAssigneeAndStatusWithExceptionHandling,
      activeDocumentSet,
      navigateWithState,
      disabled,
      newStatus,
      isFetching,
      t,
    ]
  );

  const checkChangesAndGoToDocumentSet = useCallback(
    async (goToNext: boolean, callBack?: () => void) => {
      try {
        if (isFetching) {
          return;
        }

        setIsFetching(true);
        const data = await getAdjacentDocumentSetIds();

        // Has the document set been edited
        if (!disabled && (hasBeenEdited || activeEntity !== undefined)) {
          setAdjacentDocumentSetIds(data);
          setIsNextDocument(goToNext);
          setOpenChangesModal(true);
          return;
        }

        await goToNextOrPreviousDocumentSet(data, goToNext);
      } catch (error: any) {
        notification.error({
          message: t("errorWhileGettingDocumentSet").replace(
            "$KEY",
            goToNext ? t("next") : t("previous")
          ),
        });
      } finally {
        if (callBack) {
          callBack();
        }
        setIsFetching(false);
      }
    },
    [
      getAdjacentDocumentSetIds,
      disabled,
      hasBeenEdited,
      activeEntity,
      goToNextOrPreviousDocumentSet,
      isFetching,
      t,
    ]
  );

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if (arrowUpPressed) {
      checkChangesAndGoToDocumentSet(false, resetUp);
    }

    if (arrowDownPressed) {
      checkChangesAndGoToDocumentSet(true, resetDown);
    }
  }, [
    arrowUpPressed,
    arrowDownPressed,
    checkChangesAndGoToDocumentSet,
    isFetching,
    resetUp,
    resetDown,
  ]);

  return {
    openChangesModal,
    setOpenChangesModal,
    checkChangesAndGoToDocumentSet,
    goToNextOrPreviousDocumentSet,
    adjacentDocumentSetIds,
    isNextDocument,
    isFetching,
  };
};

export default useHandlePreviousOrNextDocSet;

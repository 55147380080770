import React, { FC, memo, Profiler } from "react";
import { useAppSelector } from "../../../../app";
import useTableZIndex from "../../../hooks/useTableZIndex";
import { onRender } from "../../../../app/utils/profiling";
import TableRenderer from "./TableRenderer";
import tablesForPageSelector from "../../../../annotation/selectors/tablesForPageSelector";

interface Props {
  page: number;
  pdfWidth: number;
  pdfHeight: number;
}

const TableBuilder: FC<Props> = ({ page, pdfWidth, pdfHeight }) => {
  const { createTable } = useAppSelector(
    (state) => state.annotatorReducer.annotationState
  );

  const tables = useAppSelector((state) =>
    tablesForPageSelector(state, { page })
  );

  const zIndex = useTableZIndex(createTable);

  return (
    <Profiler id="TableLayer" onRender={onRender}>
      <div
        className="table-builder__container"
        style={{ width: `${pdfWidth}px`, height: `${pdfHeight}px` }}
      >
        {tables.map((table) => {
          return (
            <TableRenderer
              key={table.id}
              table={table}
              isCreatingTable={createTable}
              zIndex={zIndex}
            />
          );
        })}
      </div>
    </Profiler>
  );
};

export default memo(TableBuilder);

import useTour, { TourPage } from "./useTour";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { START_TOUR_MILLISECONDS } from "../constants";

type Props = {
  dependencies: Array<boolean>;
  triggerStartTour: boolean;
  tourPage: TourPage;
};

const useStartTour = (props: Props) => {
  const { dependencies, triggerStartTour, tourPage } = props;

  const { startTour } = useTour();

  const location = useLocation();

  useEffect(() => {
    if (
      (tourPage === TourPage.OVERVIEW && location.pathname !== "/") ||
      (tourPage === TourPage.DETAIL && !location.pathname.includes("/detail/"))
    ) {
      return;
    }

    let timerId: NodeJS.Timeout;

    if (triggerStartTour) {
      timerId = setTimeout(() => startTour(tourPage), START_TOUR_MILLISECONDS);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
    startTour,
    tourPage,
    triggerStartTour,
    location.pathname,
  ]);
};

export default useStartTour;

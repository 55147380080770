import React, { ChangeEvent, FC, useCallback } from "react";
import { Input } from "antd";
import {
  updateMetricsFilterArray,
  updateMetricsFilterString,
} from "../../../../common/dataRefining/store/dataRefiningSlice";
import { useAppDispatch } from "../../../../app";
import { MetricFilterItem } from "../../../interfaces/documentSetMetrics";
import useMetricsFilterItem from "../../../hooks/useMetricsFilterItem";
import { triggerRefreshMetrics } from "../../../store/analyticsSlice";
import { METRIC_FILTER_ITEM_DELIMITER } from "../../../constants";

type Props = {
  filterItem: MetricFilterItem;
  placeholder: string;
  loading: boolean;
};

const InputFilterItem: FC<Props> = ({ filterItem, placeholder, loading }) => {
  const { metadata, key } = filterItem;

  const dispatch = useAppDispatch();

  const { getValueFromKey } = useMetricsFilterItem(filterItem);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateMetricsFilterArray({
          key,
          delimiter: METRIC_FILTER_ITEM_DELIMITER,
          value: event.target.value,
        })
      );
      dispatch(updateMetricsFilterString());
      dispatch(triggerRefreshMetrics());
    },
    [dispatch, key]
  );

  return (
    <div className="filter-item">
      <Input
        className="filter-item__select"
        value={getValueFromKey(
          metadata?.filterAble!.filterKey,
          METRIC_FILTER_ITEM_DELIMITER
        )}
        placeholder={placeholder}
        onChange={handleChange}
        allowClear
        disabled={loading}
      />
    </div>
  );
};

export default InputFilterItem;

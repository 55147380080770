import React, { FC, lazy, Suspense } from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import Loader from "../common/loader/Loader";
import { Login } from "../user";
import PrivateRoute from "../common/privateRoute/PrivateRoute";
import "./App.scss";
import WithLayout from "../common/menu/withLayout";
import { OverviewType, useAppSelector } from "./";
import useInvitation from "../user/hooks/useInvitation";
import { HeaderType } from "../common/menu/header/HeaderSwitch";

export type PreviousLocationState = {
  previousPath: string;
  caller: string;
};

const Overview = lazy(
  () => import(/* webpackPrefetch: true */ "./pages/overview/Overview")
);
const Detail = lazy(
  () => import(/* webpackPrefetch: true */ "./pages/detailView/Detail")
);

const Analytics = lazy(() => import("../analytics"));

const PersonalPage = lazy(() => import("../user"));

const EmailTemplates = lazy(() => import("../emailTemplates"));

const ReleaseOverview = lazy(
  () => import("../releases/components/releasesOverview/ReleasesOverview")
);

const OrganizationsSettings = lazy(
  () => import("../organization/components/settings/OrganizationSettings")
);

const Routes: FC = () => {
  useInvitation();

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  // TODO: Disabled for now, need to revalidate this approach
  // useTourStopper();

  return (
    <ReactRoutes>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loader fullPage />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout activeKey="home" headerType={HeaderType.Overview}>
                <Overview
                  overviewType={
                    isAnnotationMode
                      ? OverviewType.AnnotationTodo
                      : OverviewType.Home
                  }
                />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/archive"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout activeKey="archive" headerType={HeaderType.Overview}>
                <Overview
                  overviewType={
                    isAnnotationMode
                      ? OverviewType.AnnotationDone
                      : OverviewType.Archived
                  }
                />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/errors"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout activeKey="error" headerType={HeaderType.Overview}>
                <Overview overviewType={OverviewType.Error} />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/remove"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout activeKey="remove" headerType={HeaderType.Overview}>
                <Overview overviewType={OverviewType.Remove} />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout
                activeKey="analytics"
                headerType={HeaderType.Analytics}
              >
                <Analytics />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/user/settings"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout
                activeKey="organizationsSettings"
                headerType={HeaderType.Title}
              >
                <PersonalPage />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/user/email-templates"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout
                activeKey="emailTemplates"
                headerType={HeaderType.EmailTemplates}
              >
                <EmailTemplates />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/detail/:documentId"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout activeKey="detail" headerType={HeaderType.Details}>
                <Detail />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/releases"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout
                activeKey="releaseOverview"
                headerType={HeaderType.Title}
              >
                <ReleaseOverview />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path="/organization/settings"
        element={
          <PrivateRoute>
            <Suspense fallback={<Loader fullPage />}>
              <WithLayout
                activeKey="organizationsSettings"
                headerType={HeaderType.Title}
              >
                <OrganizationsSettings />
              </WithLayout>
            </Suspense>
          </PrivateRoute>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;

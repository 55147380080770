import { DetailsContent, DocumentSetDto } from "../interfaces/drawer";
import { useMemo } from "react";
import DOMPurify from "dompurify";

const useDetailsContent = (documentSetDetails: DocumentSetDto | undefined) => {
  const data: DetailsContent | undefined = useMemo(() => {
    if (!documentSetDetails) {
      return;
    }

    const detailsContent: DetailsContent = {
      id: documentSetDetails.id,
      documentSetName: documentSetDetails.name,
      source: documentSetDetails.input?.displayName,
    };

    if (documentSetDetails.email) {
      const toAddresses = documentSetDetails.email.toRecipients
        ?.map((t) => t.address)
        ?.join(", ");
      const ccAddresses = documentSetDetails.email.ccRecipient
        ?.map((t) => t.address)
        ?.join(", ");

      let content = undefined;
      if (documentSetDetails.email.itemBody?.content) {
        // This is against Cross-Site Scripting
        content = DOMPurify.sanitize(
          documentSetDetails.email.itemBody?.content
        );
      }

      detailsContent.email = {
        subject: documentSetDetails.email.subject,
        senderName: documentSetDetails.email.sender?.name,
        senderAddress: documentSetDetails.email.sender?.address,
        receivedDate: documentSetDetails.email.receivedDateTime,
        content,
        categories: documentSetDetails.email.categories?.join(", "),
        toAddresses,
        ccAddresses,
      };
    }

    return detailsContent;
  }, [documentSetDetails]);

  return data;
};

export default useDetailsContent;

import React, { FC } from "react";
import { Alert } from "antd";
import useExportFailedAlert from "../../../hooks/useExportFailedAlert";
import ExportFailedWithMessageAlert from "./ExportFailedWithMessageAlert";

type Props = {
  setHeight: (height: number) => void;
};

const ExportFailedAlert: FC<Props> = ({ setHeight }) => {
  const {
    refHeightContainer,
    errorMessageObject,
    latestLogWebhookRequest,
    hasMissingAnnotations,
    hasMissingCategorizations,
    moreDetailsInfo,
    t,
  } = useExportFailedAlert(setHeight);

  if (errorMessageObject) {
    return (
      <ExportFailedWithMessageAlert
        errorMessageObject={errorMessageObject}
        latestLogWebhookRequest={latestLogWebhookRequest!}
        moreDetailsInfo={moreDetailsInfo!}
        ref={refHeightContainer}
      />
    );
  }

  if (hasMissingCategorizations || hasMissingAnnotations) {
    return (
      <div ref={refHeightContainer}>
        <Alert
          className="error__alert-warning"
          message={t("missingAnnotations.title")}
          description={t("missingAnnotations.description")}
          type="error"
          icon={<i className="bi bi-exclamation-triangle error__icon" />}
          showIcon
        />
      </div>
    );
  }

  return null;
};

export default ExportFailedAlert;

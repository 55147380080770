import { useMemo } from "react";
import { getTextMetrics } from "../helpers/textMapHelpers";
import { TextLayerItem } from "../interfaces/textLayer";
import useAnnotatorData from "./useAnnotatorData";

const useTokenContainer = (
  textLayerItem: TextLayerItem,
  pageNumber: number
) => {
  const { text, coords, fontSize, transform, fontFamily } = textLayerItem;

  const { annotations } = useAnnotatorData();

  const annotationsForPage = useMemo(() => {
    return annotations.filter(
      (annotation) => !!annotation && annotation.page === pageNumber
    );
  }, [annotations, pageNumber]);

  const metrics = useMemo(() => getTextMetrics(text), [text]);
  const scale = useMemo(
    () => ({
      x: coords.width / metrics.width,
      y: coords.height / metrics.height,
    }),
    [metrics, coords]
  );

  const style = useMemo(() => {
    if (fontSize && transform && fontFamily) {
      return {
        left: `${coords.left}px`,
        top: `${coords.top}px`,
        fontSize: `${fontSize}px`,
        fontFamily: `${fontFamily}`,
        transform: `scaleX(${transform})`,
      };
    }

    return {
      left: `${coords.left}px`,
      top: `${coords.top}px`,
      width: `${coords.width}px`,
      height: `${coords.height}px`,
      font: "12px sans-serif",
      transform: `scale(${scale.x}, ${scale.y})`,
    };
  }, [
    fontSize,
    transform,
    fontFamily,
    coords.left,
    coords.top,
    coords.width,
    coords.height,
    scale.x,
    scale.y,
  ]);

  return { annotations: annotationsForPage, style };
};

export default useTokenContainer;

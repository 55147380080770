import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import {
  annotationDoneStatuses,
  doneStatuses,
  Status,
} from "../../common/status/status";

const getUser = (state: RootState) => state.userReducer.user;
const getActiveDocumentSet = (state: RootState) =>
  state.documentSetsReducer.activeDocumentSet;
const isAnnotationMode = (state: RootState) =>
  state.userReducer.userSettings.enableAnnotationMode;

const documentSetDisabledSelector = createSelector(
  [getUser, getActiveDocumentSet, isAnnotationMode],
  (user, activeDocumentSet, isAnnotationMode) => {
    if (!activeDocumentSet) {
      return true;
    }

    if (annotationDoneStatuses.includes(activeDocumentSet?.status)) {
      return true;
    }

    if (isAnnotationMode) {
      return false;
    }

    if (activeDocumentSet.status === Status.ToDo) {
      return false;
    }

    if (
      activeDocumentSet.assignee &&
      user?.id !== activeDocumentSet.assignee.id
    ) {
      return true;
    }

    return (
      doneStatuses.includes(activeDocumentSet.status) &&
      activeDocumentSet.status !== Status.Error
    );
  }
);

export default documentSetDisabledSelector;

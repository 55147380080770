import { useCallback, useEffect, useState } from "react";
import * as pdfjs from "pdfjs-dist/build/pdf";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import useAnnotatorConfig from "./useAnnotatorConfig";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const cMapUrl = `${window.location.origin}/cmaps/`;
const cMapPacked = true;

const usePDF = (data: Uint8Array | BufferSource | string) => {
  const { pages, error, setAnnotatorInfoDocument } = useAnnotatorConfig();
  const [document, setDocument] = useState(null);

  const loadPDF = useCallback(async () => {
    const pdfParams = {
      data,
      cMapUrl,
      cMapPacked,
    };

    try {
      const loadingTask = pdfjs.getDocument(pdfParams);
      const pdf = await loadingTask.promise;

      setAnnotatorInfoDocument({
        pages: pdf.numPages,
        error: false,
      });
      setDocument(pdf);
    } catch (e) {
      setAnnotatorInfoDocument({
        pages: 0,
        error: true,
      });
      setDocument(null);
    }
  }, [data, setAnnotatorInfoDocument]);

  useEffect(() => {
    loadPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchPage = useCallback(
    (index: number) => {
      if (document) {
        // @ts-ignore
        return document.getPage(index);
      }
      return null;
    },
    [document]
  );

  return { pages, error, fetchPage };
};

export default usePDF;

export const LINE_GRAPH_HEIGHT = 250;
export const PIE_GRAPH_HEIGHT = 270;
export const OVERVIEW_ORG_GRAPH_HEIGHT = 140;

export const METRIC_FILTER_ITEM_DELIMITER = "=";

export enum MetricsDatePeriod {
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  CUSTOM,
}

export enum MetricsMode {
  OPERATIONAL,
  ADVANCED,
  USER,
}

export const PIE_GRAPH_SHOW_MULTIPLE_PERCENTAGE = 5;

export const OTHER_SOURCE_COLOR = "gray";

export const SOURCE_COLORS = [
  "#3498db", // Light Blue
  "#e74c3c", // Red
  "#2ecc71", // Green
  "#f39c12", // Orange
  "#9b59b6", // Purple
  "#1abc9c", // Cyan
  "#e67e22", // Dark Orange
  "#27ae60", // Emerald Green
  "#d35400", // Burnt Orange
  "#34495e", // Dark Blue
  "#ff00ff", // Magenta
  "#1e90ff", // Dodger Blue
  "#ffff54", // Bright Yellow
  "#dda0dd", // Pale Violet
  "#ff1493", // Deep Pink
  "#98fb98", // Pale Green
  "#87cefa", // Light Sky Blue
  "#ffe4b5", // Moccasin
  "#2f4f4f", // Dark Slate Gray
  "#006400", // Dark Green
  "#00008b", // Dark Blue
  "#b03060", // Maroon
  "#ff4500", // Orange Red
  "#ffff00", // Yellow
  "#deb887", // Burlywood
  "#00ff00", // Lime
  "#00ffff", // Aqua
  "#6495ed", // Cornflower Blue
  "#2e8b57", // Sea Green
  "#ff8c00", // Dark Orange
  "#00ff88", // Medium Spring Green
  "#0000ff", // Blue
  "#008080", // Teal
  "#ff6347", // Tomato
  "#7fff00", // Chartreuse
  "#ffdab9", // Peach Puff
  "#ba55d3", // Medium Orchid
  "#8b0000", // Dark Red
  "#ffdead", // Navajo White
  "#dc143c", // Crimson
  "#4b0082", // Indigo
  "#ffebcd", // Blanched Almond
  "#8fbc8f", // Dark Sea Green
  "#00bfff", // Deep Sky Blue
  "#ff69b4", // Hot Pink
  "#daa520", // Goldenrod
  "#808000", // Olive
];

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Point } from "../interfaces/point";
import { Rectangle } from "../interfaces/textLayer";
import useMouse from "@react-hook/mouse-position";
import {
  calculateSelectionRectangle,
  getMinimalCoords,
} from "../helpers/selectionHelpers";
import { buildNerAnnotation } from "../helpers/annotationHelpers";
import useKeyPressedListener from "./useKeyPressedListener";
import { useAppDispatch, useAppSelector } from "../../app";
import useAnnotationUpdater from "./useAnnotationUpdater";
import { setIsDragging } from "../../annotation/store/annotationSlice";
import { generateRandomHash } from "../helpers/hashHelper";
import useTableUpdater from "./useTableUpdater";
import { AnnotationParams } from "../interfaces/annotation";
import textLayerForPageSelector from "../../annotation/selectors/textlayerForPageSelector";
import { EAnnotatorMode } from "../../annotation/constants";
import documentSetDisabledSelector from "../../documentSet/selectors/documentSetDisabledSelector";
import { useTranslation } from "react-i18next";
import activeEntityForAnnotatorSelector from "../selectors/activeEntityForAnnotatorSelector";

const initialCoords: Rectangle = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
  rotation: 0,
};

interface Props {
  pageNumber: number;
  ref: MutableRefObject<HTMLDivElement | null>;
}

const useSelection = ({ ref, pageNumber }: Props) => {
  const dispatch = useAppDispatch();

  const {
    i18n: { language },
  } = useTranslation();

  const disabled = useAppSelector(documentSetDisabledSelector);
  const textLayerForPage = useAppSelector((state) =>
    textLayerForPageSelector(state, {
      page: pageNumber,
      excludeTokensInUse: true,
    })
  );
  const { createTable } = useAppSelector(
    (state) => state.annotatorReducer.annotationState
  );
  const { isDragging } = useAppSelector((state) => state.annotationReducer);
  const entity = useAppSelector((state) =>
    activeEntityForAnnotatorSelector(state, { language })
  );

  const [mouseCoords, setMouseCoords] = useState<Point>({ x: 0, y: 0 });
  const [coords, setCoords] = useState(initialCoords);

  const { addAnnotation, updateLastAnnotationForEntity } =
    useAnnotationUpdater();

  const { addTable, mapAnnotationToTable } = useTableUpdater();

  const keyPressed = useKeyPressedListener();
  const mouse = useMouse(ref);

  const annotatorMode = useMemo((): EAnnotatorMode => {
    if (entity && createTable) {
      return EAnnotatorMode.TABLE_ANNOTATION_MODE;
    }

    if (createTable) {
      return EAnnotatorMode.TABLE_CREATION_MODE;
    }

    if (entity) {
      return EAnnotatorMode.ANNOTATION_MODE;
    }

    return EAnnotatorMode.TEXT_SELECTION_MODE;
  }, [entity, createTable]);

  const mousePosition = useMemo((): Point => {
    const { x, y } = mouse;
    if (!x || !y) {
      return { x: 0, y: 0 };
    }

    return { x, y };
  }, [mouse]);

  const handleMouseDown = useCallback(() => {
    const { x, y } = mouse;
    if (entity) {
      setMouseCoords({ x: x!, y: y! });
      dispatch(setIsDragging(true));
    }
    if (createTable) {
      setMouseCoords({ x: x!, y: y! });
      dispatch(setIsDragging(true));
    }
  }, [entity, createTable, mouse, dispatch]);

  const createNerAnnotation = useCallback(
    (coords: Rectangle): AnnotationParams | null => {
      if (!textLayerForPage || !entity) {
        return null;
      }

      const { tokens, text } = textLayerForPage;

      const markToAdd = buildNerAnnotation(
        pageNumber,
        entity,
        tokens,
        text,
        coords,
        entity.multipleGroupBlocks
      );

      if (!markToAdd?.pageTokenIndices?.length) {
        return null;
      }

      return markToAdd;
    },
    [textLayerForPage, entity, pageNumber]
  );

  const handleNerAnnotation = useCallback(
    (coords: Rectangle) => {
      const markToAdd = createNerAnnotation(coords);

      if (!markToAdd) {
        return;
      }

      if (keyPressed) {
        updateLastAnnotationForEntity(markToAdd);
      } else {
        addAnnotation(markToAdd);
      }
    },
    [
      keyPressed,
      updateLastAnnotationForEntity,
      addAnnotation,
      createNerAnnotation,
    ]
  );

  const handleTableAnnotation = useCallback(
    (coords: Rectangle) => {
      const markToAdd = createNerAnnotation(coords);

      if (!markToAdd) {
        return;
      }

      mapAnnotationToTable(markToAdd, coords);
    },
    [createNerAnnotation, mapAnnotationToTable]
  );

  const handleCreateTable = useCallback(
    (coords: Rectangle) => {
      if (!textLayerForPage || !createTable) {
        return;
      }

      const { text, tokens } = textLayerForPage;

      const { left: x, top: y, width, height } = coords;
      const id = generateRandomHash();

      const tableToAdd = {
        id,
        x,
        y,
        width,
        height,
        page: pageNumber,
        rows: [],
        columns: [],
        cells: [],
        linkedTables: [],
      };

      addTable(tableToAdd, pageNumber, tokens, text);
      return;
    },
    [textLayerForPage, createTable, pageNumber, addTable]
  );

  const handleMouseUp = useCallback(() => {
    if (disabled) {
      return;
    }

    const coordsToUse = getMinimalCoords(coords, mousePosition);

    switch (annotatorMode) {
      case EAnnotatorMode.TABLE_ANNOTATION_MODE:
        handleTableAnnotation(coordsToUse);
        break;
      case EAnnotatorMode.TABLE_CREATION_MODE:
        handleCreateTable(coordsToUse);
        break;
      case EAnnotatorMode.ANNOTATION_MODE:
        handleNerAnnotation(coordsToUse);
        break;
      default:
        break;
    }

    dispatch(setIsDragging(false));
    setMouseCoords({ x: 0, y: 0 });
    setCoords(initialCoords);
  }, [
    disabled,
    dispatch,
    annotatorMode,
    coords,
    mousePosition,
    handleCreateTable,
    handleNerAnnotation,
    handleTableAnnotation,
  ]);

  const handleKeyEvent = useCallback(
    (event: KeyboardEvent) => {
      if (event?.key?.toLowerCase() === "escape" && isDragging) {
        dispatch(setIsDragging(false));
        setMouseCoords({ x: 0, y: 0 });
        setCoords(initialCoords);
      }
    },
    [isDragging, dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvent, false);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent, false);
    };
  }, [handleKeyEvent]);

  const handleMouseMove = useCallback(() => {
    if (isDragging && (entity || createTable)) {
      const { x, y } = mousePosition;
      setCoords(calculateSelectionRectangle(mouseCoords, { x: x, y: y }));
    }
  }, [isDragging, entity, mouseCoords, createTable, mousePosition]);

  return {
    annotatorMode,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    mousePosition,
    coords,
    isDragging,
  };
};

export default useSelection;

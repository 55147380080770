import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchDocumentTypeInfo } from "../../documentTypes/utils/requests";
import {
  setSelectedConfigMap,
  setSelectedDocumentTypeId,
} from "../store/organizationSlice";
import { DocumentType } from "../../documentTypes/interfaces";

const useConfigMapSettings = (postDocumentTypeChange?: () => void) => {
  const dispatch = useAppDispatch();

  const { inputTypes } = useAppSelector((state) => state.documentTypesReducer);
  const { configMap, documentTypeId: selectedDocumentTypeId } = useAppSelector(
    (state) => state.organizationReducer.settings
  );

  const [selectedInput, setSelectedInput] = useState(inputTypes[0]);

  const handleInputChange = useCallback(
    (inputId: string) => {
      const found = inputTypes.find((it) => it.id === inputId);

      if (found) {
        setSelectedInput(found);
        dispatch(setSelectedDocumentTypeId(found.documentTypes[0].id));
      }
    },
    [dispatch, inputTypes]
  );

  const handleDocumentTypeChange = useCallback(
    async (documentTypeId: string) => {
      try {
        const { data } = await fetchDocumentTypeInfo(documentTypeId);

        if (data && data.configMap) {
          dispatch(setSelectedDocumentTypeId(documentTypeId));
          dispatch(setSelectedConfigMap(data.configMap));

          if (postDocumentTypeChange) {
            postDocumentTypeChange();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [dispatch, postDocumentTypeChange]
  );

  const inputTypesSelectOptions = useMemo(() => {
    return inputTypes.map((it) => ({
      label: it.displayName,
      value: it.id,
    }));
  }, [inputTypes]);

  const documentTypesSelectOptions = useMemo(() => {
    const documentTypes = inputTypes.map((it) => it.documentTypes).flat();

    const uniqueDocumentTypes: Array<DocumentType> = [];
    for (const dt of documentTypes) {
      if (!uniqueDocumentTypes.find((it) => it.id === dt.id)) {
        uniqueDocumentTypes.push(dt);
      }
    }

    return uniqueDocumentTypes.map((dt) => ({
      label: dt.name,
      value: dt.id,
    }));
  }, [inputTypes]);

  const selectedConfigMapGroupBlocks = useMemo(() => {
    if (!configMap) {
      return [];
    }

    return [...configMap.groupBlocks].sort((a, b) => a.index - b.index);
  }, [configMap]);

  const selectedConfigMapEntities = useMemo(() => {
    return selectedConfigMapGroupBlocks.flatMap((g) => g.groupBlockEntityTypes);
  }, [selectedConfigMapGroupBlocks]);

  useEffect(() => {
    if (!documentTypesSelectOptions.length || selectedDocumentTypeId) {
      return;
    }

    handleDocumentTypeChange(documentTypesSelectOptions[0].value);
  }, [
    documentTypesSelectOptions,
    handleDocumentTypeChange,
    selectedDocumentTypeId,
  ]);

  return {
    selectedInput,
    inputTypesSelectOptions,
    selectedDocumentTypeId,
    documentTypesSelectOptions,
    selectedConfigMapGroupBlocks,
    selectedConfigMapEntities,
    handleInputChange,
    handleDocumentTypeChange,
  };
};

export default useConfigMapSettings;

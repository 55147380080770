import { useMemo } from "react";
import {
  annotationTodoStatuses,
  Status,
  todoStatuses,
} from "../../common/status/status";
import { useActiveDocumentSet } from "../../documentSet";
import { useAppSelector } from "./hooks";
import documentSetDisabledSelector from "../../documentSet/selectors/documentSetDisabledSelector";

const useDetailPageRouting = () => {
  const user = useAppSelector((state) => state.userReducer.user);

  const isAnnotationMode = useAppSelector(
    (state) => state.userReducer.userSettings.enableAnnotationMode
  );

  const disabled = useAppSelector(documentSetDisabledSelector);

  const { updateAssigneeAndStatusWithExceptionHandling, activeDocumentSet } =
    useActiveDocumentSet();

  const newStatus = useMemo(() => {
    if (
      isAnnotationMode &&
      (!activeDocumentSet?.status ||
        annotationTodoStatuses.includes(activeDocumentSet.status))
    ) {
      return Status.AnnotationToDo;
    }

    if (
      !activeDocumentSet?.status ||
      todoStatuses.includes(activeDocumentSet.status)
    ) {
      return Status.ToDo;
    }

    if (activeDocumentSet?.status === Status.Error) {
      return Status.Error;
    }

    return activeDocumentSet.status;
  }, [isAnnotationMode, activeDocumentSet]);

  return {
    disabled,
    newStatus,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
    isAnnotationMode,
    user,
  };
};

export default useDetailPageRouting;

import React, { FC } from "react";
import { Button } from "antd";
import useMetricsModeSelector from "../../../hooks/useMetricsModeSelector";
import { useTranslation } from "react-i18next";
import { MetricsMode } from "../../../../../analytics/constants";

const MetricsModeSelector: FC = () => {
  const { metricsMode, handleChange, loading } = useMetricsModeSelector();

  const { t } = useTranslation("analytics");

  return (
    <div className="header-status__container">
      <Button
        className={`header__status-button ${
          metricsMode === MetricsMode.OPERATIONAL && "active"
        }`}
        icon={<i className="bi bi-graph-up" />}
        onClick={() => handleChange(MetricsMode.OPERATIONAL)}
        disabled={loading}
      >
        {t("metricsMode.operational")}
      </Button>
      <Button
        className={`header__status-button ${
          metricsMode === MetricsMode.USER && "active"
        }`}
        icon={<i className="bi bi-people" />}
        onClick={() => handleChange(MetricsMode.USER)}
        disabled={loading}
      >
        {t("metricsMode.user")}
      </Button>
      <Button
        className={`header__status-button ${
          metricsMode === MetricsMode.ADVANCED && "active"
        }`}
        icon={<i className="bi bi-bar-chart-steps" />}
        onClick={() => handleChange(MetricsMode.ADVANCED)}
        disabled={loading}
      >
        {t("metricsMode.advanced")}
      </Button>
    </div>
  );
};

export default MetricsModeSelector;

import React from "react";
import AreaMark from "./AreaMark";
import useAnnotatorData from "../../../hooks/useAnnotatorData";
import useAnnotationUpdater from "../../../hooks/useAnnotationUpdater";

interface Props {
  pdfScale: number;
  pageNumber: number;
}

const AreaLayer = ({ pdfScale, pageNumber }: Props) => {
  const { removeAnnotation, updateAnnotation } = useAnnotationUpdater();

  const { annotationsForPage } = useAnnotatorData(pageNumber);

  return (
    <>
      {annotationsForPage.map((annotation) => (
        <AreaMark
          pdfScale={pdfScale}
          key={annotation.id}
          annotation={annotation}
          removeAnnotation={removeAnnotation}
          updateAnnotation={updateAnnotation}
        />
      ))}
    </>
  );
};

export default AreaLayer;

import { createSelector } from "@reduxjs/toolkit";
import selectAnnotationReducer from "./annotationReducerSelector";
import flatEntityListSelector from "../../configMap/selectors/flatEntityListSelector";

const selectHasMissingAnnotations = createSelector(
  flatEntityListSelector,
  selectAnnotationReducer,
  (entities, annotations): boolean => {
    return entities
      .map((e): boolean => {
        const found = annotations.find((a) => a.entity.id === e.entityType.id);

        return !!found;
      })
      .includes(false);
  }
);

export default selectHasMissingAnnotations;

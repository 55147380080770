import React, { FC } from "react";
import HoverButtons from "./HoverButtons";
import { EntityAnnotation } from "../../../../../../annotation";

type Props = {
  isEditing: boolean;
  entityAnnotation: EntityAnnotation;
  removeAnnotation: (annotationId: string) => void;
  disabled: boolean;
};

const HoverButtonsWrapper: FC<Props> = ({
  isEditing,
  entityAnnotation,
  removeAnnotation,
  disabled,
}) => {
  if (disabled || !entityAnnotation?.id || isEditing) {
    return null;
  }

  return (
    <HoverButtons
      showEditIcon={false}
      showRemoveIcon={true}
      onClickRemove={(event) => {
        event.stopPropagation();
        removeAnnotation(entityAnnotation.id!);
      }}
    />
  );
};

export default HoverButtonsWrapper;

import React, { FC } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

type Props = {
  checkboxValue: boolean;
  handleChangeBlankEntries: (e: CheckboxChangeEvent) => void;
  labelCheckbox: string;
};

const BlankEntriesCheckbox: FC<Props> = ({
  checkboxValue,
  handleChangeBlankEntries,
  labelCheckbox,
}) => {
  return (
    <div className="blank-entries-checkbox-container">
      <Checkbox checked={checkboxValue} onChange={handleChangeBlankEntries}>
        {labelCheckbox}
      </Checkbox>
    </div>
  );
};

export default BlankEntriesCheckbox;

import React, { FC } from "react";
import ActionButton from "../ActionButton";
import { Status } from "../../../../common/status/status";
import ButtonType from "../../../../common/button/ButtonTypes";
import { useTranslation } from "react-i18next";

type Props = {
  setIsLoading: (state: boolean) => void;
  openUserConflictModal: () => void;
};

const AnnotationModeButtons: FC<Props> = ({
  setIsLoading,
  openUserConflictModal,
}) => {
  const { t } = useTranslation("annotationSideControls");

  return (
    <div className="action-button__container">
      <ActionButton
        id="action-hold"
        status={Status.AnnotationOnHold}
        message={t("messages.holdSuccess")}
        buttonText={t("annotationSideControlsFooter.hold")}
        buttonType={ButtonType.Hold}
        setIsLoading={setIsLoading}
        icon={<i className="bi bi-pause" />}
        openUserConflictModal={openUserConflictModal}
      />
      <ActionButton
        id="action-approve"
        status={Status.AnnotationDone}
        message={t("messages.approveSuccess")}
        buttonText={t("annotationSideControlsFooter.updateAnnotations")}
        buttonType={ButtonType.Approve}
        setIsLoading={setIsLoading}
        icon={<i className="bi bi-check2" />}
        openUserConflictModal={openUserConflictModal}
      />
    </div>
  );
};

export default AnnotationModeButtons;

import React, { FC } from "react";
import "./detailsContent/DetailsContent.scss";
import { ContentItems, DrawerType } from "../../../interfaces/drawer";
import DetailsContent from "./detailsContent/DetailsContent";
import AnnotationHistoryContent from "./annotationHistoryContent/AnnotationHistoryContent";

type Props = {
  drawerType?: DrawerType;
  content: ContentItems;
};

const ContentSwitch: FC<Props> = ({ drawerType, content }) => {
  if (!drawerType) {
    return null;
  }

  switch (drawerType) {
    case DrawerType.DETAILS:
      return <DetailsContent documentSetDetails={content.detailsData} />;
    case DrawerType.ANNOTATION_HISTORY:
      return <AnnotationHistoryContent items={content.annotationHistoryData} />;
    default:
      return null;
  }
};

export default ContentSwitch;

enum ButtonType {
  Primary,
  Default,
  Approve,
  Approved,
  Reject,
  Rejected,
  Hold,
  ShowDetails,
  Upload,
  Loading,
}

export default ButtonType;

import { createSelector } from "@reduxjs/toolkit";
import { getFlatEntityListFromConfigMap, GroupBlockEntityType } from "../";
import { RootState } from "../../app";

const selectConfigMapReducer = (state: RootState) => state.configMapReducer;
const selectAppReducer = (state: RootState) => state.appReducer;

const activeGroupBlockEntityTypeSelector = createSelector(
  selectConfigMapReducer,
  selectAppReducer,
  (configMapReducer, appReducer): GroupBlockEntityType | undefined => {
    const { activeEntity } = appReducer;
    const { configMap } = configMapReducer;
    if (!activeEntity) {
      return undefined;
    }

    if (!configMap?.groupBlocks.length) {
      return undefined;
    }

    const entityList = getFlatEntityListFromConfigMap(configMap);
    return entityList.find(
      (a) => a.entityType.id === activeEntity.entityType.id
    );
  }
);

export default activeGroupBlockEntityTypeSelector;

import React, { FC } from "react";
import { Radio } from "antd";
import useDatePeriods from "../../../hooks/useDatePeriods";
import { MetricsDatePeriod } from "../../../constants";

type Props = {
  loading: boolean;
};

const RangeDatePicker: FC<Props> = ({ loading }) => {
  const { activePeriod, onChangePeriod } = useDatePeriods();

  return (
    <Radio.Group
      disabled={loading}
      value={activePeriod}
      className="filter-item-periods"
    >
      <Radio.Button
        className="filter-item-periods__button"
        value={MetricsDatePeriod.ONE_MONTH}
        onChange={onChangePeriod}
      >
        1M
      </Radio.Button>
      <Radio.Button
        className="filter-item-periods__button"
        value={MetricsDatePeriod.THREE_MONTHS}
        onChange={onChangePeriod}
      >
        3M
      </Radio.Button>
      <Radio.Button
        className="filter-item-periods__button"
        value={MetricsDatePeriod.SIX_MONTHS}
        onChange={onChangePeriod}
      >
        6M
      </Radio.Button>
      <Radio.Button
        className="filter-item-periods__button"
        value={MetricsDatePeriod.ONE_YEAR}
        onChange={onChangePeriod}
      >
        1Y
      </Radio.Button>
    </Radio.Group>
  );
};

export default RangeDatePicker;

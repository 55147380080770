import DocumentSetTable from "./components/DocumentSetTable";
import documentSetsReducer from "./store/documentSetSlice";

import useDocumentSets from "./hooks/useDocumentSets";
import useActiveDocumentSet from "./hooks/useActiveDocumentSet";
import useDocumentSetCounter from "./hooks/useDocumentSetCounter";
import { DEFAULT_PAGE_SIZE, PAGE_OFFSET } from "./constants";

import {
  DocumentSetColumns,
  DocumentSetData,
  Input,
  InputType,
} from "./interfaces/documentSet";
import { SearchPageable, Pageable, DocumentSetResult } from "./interfaces/api";

type ColumnKey = keyof DocumentSetColumns;

export {
  useDocumentSets,
  useActiveDocumentSet,
  useDocumentSetCounter,
  documentSetsReducer,
  DEFAULT_PAGE_SIZE,
  PAGE_OFFSET,
};
export type {
  ColumnKey,
  DocumentSetData,
  Input,
  InputType,
  SearchPageable,
  Pageable,
  DocumentSetResult,
};

export default DocumentSetTable;

import React from "react";

type ShowDetailIconProps = {
  size: number;
};

const ShowDetailIcon = ({ size }: ShowDetailIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 1.5V17.5C19 17.9 18.65 18.25 18.25 18.25C17.85 18.25 17.5 17.9 17.5 17.5V1.5C17.5 1.1 17.85 0.75 18.25 0.75C18.65 0.75 19 1.1 19 1.5ZM9.55 2.95C9.25 2.65 8.8 2.65 8.5 2.95C8.2 3.25 8.2 3.7 8.5 4L13.2 8.7H1.5C1.1 8.7 0.75 9.05 0.75 9.45C0.75 9.85 1.1 10.2 1.5 10.2H13.2L8.5 14.9C8.2 15.2 8.2 15.65 8.5 15.95C8.65 16.1 8.85 16.15 9.05 16.15C9.25 16.15 9.45 16.1 9.6 15.95L15.6 9.95C15.9 9.65 15.9 9.2 15.6 8.9L9.55 2.95Z"
        fill="white"
      />
    </svg>
  );
};

export default ShowDetailIcon;

import React, { FC, memo, useMemo, useRef, useState } from "react";
import { InputNumber } from "antd";
import { useAppDispatch } from "../../../../../../app";
import {
  addBlurredEntity,
  changeEditing,
} from "../../../../../../app/store/appSlice";
import { isEmpty } from "../../../../../../common/utilities/value";
import { GroupBlockEntityType } from "../../../../../../configMap";
import { EDIT_ICONS_WIDTH, HOTKEY_WIDTH } from "../../../../../constants";
import useAnnotations from "../../../../../../annotation/hooks/useAnnotations";

type StepInfo = {
  offset: any;
  type: "up" | "down";
};

type EditEntityNumberProps = {
  entityType: GroupBlockEntityType;
  documentId: string;
  annotationId: string;
  defaultValue: number;
  groupBlockIndex: number;
  cellWidth: number;
};

const EditEntityNumber: FC<EditEntityNumberProps> = ({
  entityType,
  documentId,
  annotationId,
  defaultValue,
  groupBlockIndex,
  cellWidth,
}: EditEntityNumberProps) => {
  const dispatch = useAppDispatch();

  const isCancelled = useRef(false);
  const [value, setValue] = useState<number | null>(defaultValue);

  const { updateAnnotation } = useAnnotations({
    documentId,
    groupBlockIndex,
  });

  const inputCellWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH;
  }, [cellWidth]);

  const inputBoxWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH - EDIT_ICONS_WIDTH;
  }, [cellWidth]);

  const handleStep = (updateValue: number, info: StepInfo): void => {
    const { type } = info;

    switch (type) {
      case "up":
        setValue((oldValue: number | null) => {
          if (oldValue) {
            return oldValue + updateValue;
          }
          return oldValue;
        });
        break;
      case "down":
        setValue((oldValue: number | null) => {
          if (oldValue) {
            return oldValue - updateValue;
          }
          return oldValue;
        });
        break;
    }
  };

  const handleEnter = (event: any) => {
    event.stopPropagation();
    dispatch(changeEditing(null));
    if (
      value &&
      !isEmpty(value) &&
      !Number.isNaN(Number(value)) &&
      !isCancelled.current
    ) {
      updateAnnotation(annotationId, value.toString(), entityType, true, true);
    }
  };

  const onBlur = (event: any) => {
    handleEnter(event);
    dispatch(
      addBlurredEntity(`${entityType.entityType.id}-${groupBlockIndex}`)
    );
  };

  const handleCancel = (event: any) => {
    event.stopPropagation();
    isCancelled.current = true;
    dispatch(changeEditing(null));
  };

  return (
    <div
      className="edit-entity edit-entity-number"
      style={{ width: `${inputCellWidth}px` }}
    >
      <InputNumber
        size={"small"}
        defaultValue={value ? value : undefined}
        decimalSeparator={","}
        value={value}
        onStep={handleStep}
        onChange={setValue}
        onPressEnter={handleEnter}
        onBlur={onBlur}
        onClick={(e) => {
          e.stopPropagation();
        }}
        autoFocus
        style={{ width: `${inputBoxWidth}px` }}
      />

      <div className="edit-actions">
        <i className="bi bi-slash-circle cancel" onMouseDown={handleCancel} />
        <i className="bi bi-check-lg submit" onMouseDown={handleEnter} />
      </div>
    </div>
  );
};

export default memo(EditEntityNumber);

import { useCallback } from "react";
import { VALID_RETURN_PATHS } from "../../app/pages/detailView/Detail";
import { shouldRouteToNextDocument } from "../utils/actionHandlerHelpers";
import {
  doneStatuses,
  errorStatuses,
  Status,
} from "../../common/status/status";
import { useAppSelector } from "../../app";
import { useLocation } from "react-router-dom";
import { ButtonType } from "../../common";
import useReduxReset from "../../common/reset";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";
import useAdjacentDocumentSetIds from "../../documentSet/hooks/useAdjacentDocumentSetIds";

const getPathToRoute = (status: Status, previousPath?: string) => {
  if (previousPath && VALID_RETURN_PATHS.includes(previousPath)) {
    return previousPath;
  }

  if (errorStatuses.includes(status)) {
    return VALID_RETURN_PATHS[2];
  }

  if (doneStatuses.includes(status)) {
    return VALID_RETURN_PATHS[1];
  }

  return VALID_RETURN_PATHS[0];
};

const useRouting = (buttonType: ButtonType) => {
  const { navigateWithState } = useNavigateWithState();
  const { state } = useLocation();

  const { user, userSettings } = useAppSelector((state) => state.userReducer);
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const getAdjacentDocumentSetIds = useAdjacentDocumentSetIds();

  const { detailReset } = useReduxReset();

  return useCallback(
    async (callback: any) => {
      const {
        approveOpenNextDocument,
        holdOpenNextDocument,
        rejectOpenNextDocument,
      } = userSettings;

      const pathToRoute = getPathToRoute(
        activeDocumentSet?.status!,
        state?.previousPath
      );

      const shouldRouteNext = shouldRouteToNextDocument(
        buttonType,
        approveOpenNextDocument,
        holdOpenNextDocument,
        rejectOpenNextDocument
      );

      if (callback) {
        callback();
      }

      if (!shouldRouteNext || !user || !activeDocumentSet) {
        navigateWithState(pathToRoute);
        return;
      }

      try {
        const data = await getAdjacentDocumentSetIds();
        detailReset();

        if (data?.nextDocumentSetId) {
          navigateWithState(`/detail/${data?.nextDocumentSetId}`);
        } else {
          navigateWithState(pathToRoute);
        }
      } catch (e) {
        navigateWithState(pathToRoute);
      }
    },
    [
      detailReset,
      activeDocumentSet,
      buttonType,
      navigateWithState,
      userSettings,
      state?.previousPath,
      user,
      getAdjacentDocumentSetIds,
    ]
  );
};

export default useRouting;

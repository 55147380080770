import { DocumentSetData } from "../interfaces/documentSet";
import {
  annotationDoneStatuses,
  annotationTodoStatuses,
  doneStatuses,
  errorStatuses,
  Status,
  todoStatuses,
} from "../../common/status/status";
import {
  ANNOTATION_IN_PROGRESS_STATUSES,
  ANNOTATION_TODO_STATUSES,
  ERROR_STATUSES,
  IN_PROGRESS_STATUSES,
} from "../constants";
import { User } from "../../user";
import { OverviewType } from "../../app";

export const shouldUpdateStatusOrUser = (
  documentSet: DocumentSetData,
  user: User,
  isAnnotationMode: boolean
) => {
  if (
    isAnnotationMode &&
    ANNOTATION_TODO_STATUSES.includes(documentSet.status)
  ) {
    return true;
  }

  if (
    documentSet.status === Status.OnHold &&
    documentSet.assignee?.id === user.id
  ) {
    return true;
  }

  if (
    ERROR_STATUSES.includes(documentSet.status) &&
    !documentSet.assignee?.id
  ) {
    return true;
  }

  return documentSet.status === Status.ToDo;
};

export const canOpen = (documentSet: DocumentSetData, user: User | null) => {
  if (!user) {
    return false;
  }

  return ![Status.Processing, Status.Remove].includes(documentSet.status);
};

export const canUnassignUser = (
  documentSet: DocumentSetData,
  isAnnotationMode: boolean
) => {
  if (documentSet.status === Status.Error) {
    return true;
  }

  if (
    isAnnotationMode &&
    ANNOTATION_IN_PROGRESS_STATUSES.includes(documentSet.status)
  ) {
    return true;
  }

  if (documentSet.status === Status.Remove) {
    return false;
  }

  if (ERROR_STATUSES.includes(documentSet.status)) {
    return true;
  }

  return IN_PROGRESS_STATUSES.includes(documentSet.status);
};

export const canTakeOverDocumentSet = (
  isAnnotationMode: boolean,
  user: User | null,
  documentSet?: DocumentSetData
) => {
  if (!user) {
    return false;
  }

  if (!documentSet || !documentSet.assignee) {
    return false;
  }

  if (!canUnassignUser(documentSet, isAnnotationMode)) {
    return false;
  }

  return documentSet.assignee.id !== user.id;
};

export const mapToDtoType = (overviewType?: OverviewType): string => {
  switch (overviewType) {
    case OverviewType.AnnotationDone:
      return "ANNOTATION_ARCHIVE";
    case OverviewType.Archived:
      return "ARCHIVE";
    default:
      return "OVERVIEW";
  }
};

export const shouldFetchDocumentSetData = (documentSet?: DocumentSetData) => {
  if (!documentSet) {
    return true;
  }

  if (!documentSet?.documents?.length) {
    return true;
  }

  return !documentSet.documents[0].documentType?.configMap?.groupBlocks?.length;
};

export const getValidStatusesForOverviewType = (
  overviewType: OverviewType | null
) => {
  switch (overviewType) {
    case OverviewType.AnnotationTodo:
      return annotationTodoStatuses
        .filter((status) => status !== Status.Archived)
        .map((status) => status.toString());
    case OverviewType.AnnotationDone:
      return annotationDoneStatuses
        .filter((status) => status !== Status.Archived)
        .map((status) => status.toString());
    case OverviewType.Archived:
      return doneStatuses
        .filter((status) => status !== Status.Archived)
        .map((status) => status.toString());
    case OverviewType.Error:
      return errorStatuses.map((status) => status.toString());
    case OverviewType.Remove:
      return [Status.Remove.toString()];
    case OverviewType.Home:
      return todoStatuses.map((status) => status.toString());
    default:
      return [];
  }
};

export const buildValidFilterArrayForOverview = (
  filterArray: Array<string>,
  validStatuses: Array<string>
) => {
  return filterArray.map((value): string => {
    if (value.toLowerCase().includes("status")) {
      const updatedStatus = value
        .replace("status=", "")
        .split("+")
        .filter((v) => validStatuses.includes(v))
        .join("+");

      if (!updatedStatus.length) {
        return "";
      }

      return `status=${updatedStatus}`;
    }

    return value;
  });
};

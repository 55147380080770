import { useAppDispatch, useAppSelector } from "../../app";
import { RefObject, useCallback, useEffect } from "react";
import { GroupBlockEntityType } from "../";
import { changeScrollToEntity } from "../../app/store/appSlice";

type Props = {
  ref: RefObject<HTMLDivElement>;
  entity: GroupBlockEntityType;
  groupBlockIndex: number;
};

const useScroll = ({ ref, entity, groupBlockIndex }: Props) => {
  const dispatch = useAppDispatch();
  const { activeEntity, scrollIntoView } = useAppSelector(
    (state) => state.appReducer
  );

  const scrollToHotKey = useCallback(
    (entityToScroll: GroupBlockEntityType) => {
      if (!ref.current) {
        return;
      }

      if (entityToScroll.entityType.id !== entity.entityType.id) {
        return;
      }

      if (groupBlockIndex !== entityToScroll.index) {
        return;
      }

      dispatch(changeScrollToEntity(undefined));
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    },
    [groupBlockIndex, entity, ref, dispatch]
  );

  useEffect(() => {
    if (activeEntity) {
      scrollToHotKey(activeEntity);
    }

    if (scrollIntoView) {
      scrollToHotKey(scrollIntoView);
    }
  }, [activeEntity, scrollIntoView, scrollToHotKey, ref]);
};

export default useScroll;

import React, { CSSProperties, FC } from "react";
import useRemoveAnnotation from "../../../hooks/useRemoveAnnotation";
import useFindAnnotation from "../../../hooks/useFindAnnotation";
import { useAppSelector } from "../../../../app";
import documentSetDisabledSelector from "../../../../documentSet/selectors/documentSetDisabledSelector";

type Props = {
  id: string;
  style: CSSProperties;
  entityId: string;
  index: number;
};

const AnnotationItemMenu: FC<Props> = ({ id, style, entityId, index }) => {
  const removeAnnotation = useRemoveAnnotation(id);
  const findAnnotation = useFindAnnotation(entityId, index);

  const disabled = useAppSelector(documentSetDisabledSelector);
  const isAnnotating = useAppSelector((state) => state.appReducer.isAnnotating);

  if (isAnnotating) {
    return null;
  }

  if (disabled) {
    return (
      <ul className="annotation-item-menu__container" style={style}>
        <li onClick={findAnnotation}>
          <span className="annotation-item-menu__item bi bi-search" />
        </li>
      </ul>
    );
  }

  return (
    <ul className="annotation-item-menu__container" style={style}>
      <li onClick={findAnnotation}>
        <span className="annotation-item-menu__item bi bi-search" />
      </li>
      <li onClick={removeAnnotation}>
        <span className="annotation-item-menu__item bi bi-trash3" />
      </li>
    </ul>
  );
};

export default AnnotationItemMenu;

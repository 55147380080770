import React, { FC, memo, useMemo } from "react";
import { Annotation } from "../../../../annotator/interfaces/annotation";
import { getAccessibleTextColor } from "../../../helpers/color";
import useAnnotationUpdater from "../../../hooks/useAnnotationUpdater";
import useAnnotatorConfig from "../../../hooks/useAnnotatorConfig";

interface Props {
  token: string;
  annotation: Annotation;
  dataI: number;
}

const Mark: FC<Props> = ({ token, annotation, dataI }) => {
  const { hoveredEntities } = useAnnotatorConfig();
  const { removeAnnotation } = useAnnotationUpdater();

  const { id, tempAnnotation, entity, index } = annotation;

  const style = useMemo(() => {
    if (tempAnnotation) {
      return {
        border: `2px dashed ${entity.color}`,
        backgroundColor: "transparent",
        color: "#000000",
      };
    }

    const color = getAccessibleTextColor(entity.color);

    if (!hoveredEntities?.length) {
      return {
        backgroundColor: entity.color,
        color,
      };
    }

    if (
      hoveredEntities.some(
        (hoveredEntity) =>
          hoveredEntity.id === entity.id && hoveredEntity.index === index
      )
    ) {
      return {
        backgroundColor: entity.color,
        color,
      };
    }

    return {
      backgroundColor: "#d3d3d3",
      color: "#000000",
    };
  }, [entity.color, entity.id, hoveredEntities, index, tempAnnotation]);

  return (
    <mark
      id={`annotation-${id}`}
      className={`mark-container ${tempAnnotation ? "temp" : ""}`.trim()}
      onClick={() => removeAnnotation(id, annotation.multipleGroupBlocks)}
      style={style}
      data-index={dataI}
    >
      <span className={`mark__token ${tempAnnotation ? "temp" : ""}`.trim()}>
        {token}
      </span>
    </mark>
  );
};

export default memo(Mark);

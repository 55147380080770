import React, { FC } from "react";
import XMLViewer from "react-xml-viewer";
import useDocumentViewer from "../../hooks/useDocumentViewer";
import { convertBufferToString } from "../../utils/binary";
import PDFSkeleton from "../../../app/pages/detailView/pdfSkeleton/PDFSkeleton";
import "./XmlViewer.scss";
import InvalidXml from "./InvalidXml";
import EmptyNavigationHeader from "../emptyNavigationHeader/EmptyNavigationHeader";

const XmlViewer: FC = () => {
  const { documentData, loading } = useDocumentViewer();

  if (loading || !documentData) {
    return <PDFSkeleton />;
  }

  return (
    <div className="container">
      <EmptyNavigationHeader />
      <div className="xml-viewer__container">
        <div className="page__container">
          <XMLViewer
            collapsible={true}
            indentSize={4}
            xml={convertBufferToString(documentData)}
            invalidXml={
              <InvalidXml
                invalidXmlString={convertBufferToString(documentData)}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default XmlViewer;

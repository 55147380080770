import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useDetailPageRouting from "../../../app/hooks/useDetailPageRouting";
import { OverviewType, useAppDispatch, useAppSelector } from "../../../app";
import {
  buildValidFilterArrayForOverview,
  getValidStatusesForOverviewType,
} from "../../../documentSet/utils/helperFunctions";
import { setFilterString } from "../../dataRefining/store/dataRefiningSlice";
import { changeOverviewType } from "../../../documentSet/store/documentSetSlice";
import selectUserInputIds from "../../../user/selectors/userInputIdsSelector";

const useSideMenuClick = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const {
    disabled,
    newStatus,
    updateAssigneeAndStatusWithExceptionHandling,
    activeDocumentSet,
    user,
  } = useDetailPageRouting();

  const userInputIds = useAppSelector(selectUserInputIds);

  const { overviewFilterArray, archiveFilterArray } = useAppSelector(
    (state) => state.dataRefiningReducer
  );

  const updateDocumentSetAssigneeAndStatus = useCallback(() => {
    if (
      pathname.includes("/detail") &&
      activeDocumentSet?.assignee?.id === user?.id
    ) {
      if (!disabled) {
        updateAssigneeAndStatusWithExceptionHandling(
          activeDocumentSet!,
          newStatus
        );
      }
    }
  }, [
    activeDocumentSet,
    newStatus,
    updateAssigneeAndStatusWithExceptionHandling,
    pathname,
    user,
    disabled,
  ]);

  const getCorrectFilterArray = useCallback(
    (overviewType: OverviewType | null) => {
      if (!overviewType) {
        return [];
      }

      if (overviewType === OverviewType.Archived) {
        return archiveFilterArray;
      }

      return overviewFilterArray;
    },
    [overviewFilterArray, archiveFilterArray]
  );

  const updateFilterString = useCallback(
    (overviewType: OverviewType | null) => {
      if (!overviewType) {
        return;
      }

      const validStatuses = getValidStatusesForOverviewType(overviewType);
      const filterArray = getCorrectFilterArray(overviewType);

      const filterString = buildValidFilterArrayForOverview(
        filterArray,
        validStatuses
      ).join(",");

      dispatch(setFilterString(filterString));
      dispatch(
        changeOverviewType({
          overviewType,
          hasUserInputs: !!userInputIds.length,
        })
      );
    },
    [getCorrectFilterArray, userInputIds, dispatch]
  );

  return useCallback(
    (overviewType: OverviewType | null) => {
      updateDocumentSetAssigneeAndStatus();
      updateFilterString(overviewType);
    },
    [updateDocumentSetAssigneeAndStatus, updateFilterString]
  );
};

export default useSideMenuClick;

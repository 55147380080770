import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../app";
import { PLUS_TAG_WIDTH, TagInfo } from "../utils/tags";
import { mapData } from "../utils/dataMapper";

const usePermissionTags = (
  permissions: Array<string>,
  orgPermissionTagsInfo: Array<TagInfo>
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>();
  const [numberOfVisibleTags, setNumberOfVisibleTags] = useState<number>();

  const { organizationSources } = useAppSelector(
    (state) => state.userManagementReducer
  );

  const permissionsMapped = useMemo(() => {
    return [...mapData(permissions, organizationSources, "id")].sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 0;
    });
  }, [permissions, organizationSources]);

  const tagsToShow = useMemo(() => {
    return permissionsMapped.slice(0, numberOfVisibleTags);
  }, [permissionsMapped, numberOfVisibleTags]);

  const tagsToShowInPlusTag = useMemo(() => {
    return permissionsMapped.slice(numberOfVisibleTags);
  }, [permissionsMapped, numberOfVisibleTags]);

  const calculateVisibleTags = useCallback(() => {
    if (!containerWidth) {
      return;
    }

    if (permissionsMapped.length) {
      let totalWidthElements = PLUS_TAG_WIDTH;
      let numberOfTagsVisible = 0;

      for (let i = 0; i < permissionsMapped.length; i++) {
        const tag = orgPermissionTagsInfo.find(
          (tag) => tag.name === permissionsMapped[i].displayName
        );
        const width = tag?.width ?? 0;
        totalWidthElements += width;

        if (totalWidthElements >= containerWidth) {
          break;
        }

        numberOfTagsVisible++;
      }

      setNumberOfVisibleTags(numberOfTagsVisible);
    }
  }, [
    containerWidth,
    permissionsMapped,
    setNumberOfVisibleTags,
    orgPermissionTagsInfo,
  ]);

  useEffect(() => {
    if (!containerRef?.current) {
      return;
    }

    const handleResize = () => {
      const width = containerRef.current?.offsetWidth ?? 0;
      setContainerWidth(width);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerRef]);

  useEffect(() => {
    calculateVisibleTags();
  }, [
    containerWidth,
    permissionsMapped,
    orgPermissionTagsInfo,
    calculateVisibleTags,
  ]);

  return {
    numberOfVisibleTags,
    containerRef,
    tagsToShow,
    tagsToShowInPlusTag,
  };
};

export default usePermissionTags;

import { useCallback, useMemo, useState } from "react";

const useCollapsable = (defaultOpen = true) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClick = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const style = useMemo(() => {
    if (!open) {
      return {
        display: "none",
      };
    }

    return {
      display: "block",
    };
  }, [open]);

  return { handleClick, style, open };
};

export default useCollapsable;

import React, { FC, useMemo } from "react";

type Props = {
  cellClasses: string;
};

const InvalidIcon: FC<Props> = ({ cellClasses }) => {
  const showInvalidIcon = useMemo(() => {
    return (
      cellClasses.includes("invalid") || cellClasses.includes("error-status")
    );
  }, [cellClasses]);

  if (!showInvalidIcon) {
    return null;
  }

  return <i className="bi bi-exclamation-circle invalid-icon" />;
};

export default InvalidIcon;

import useQuery from "../../common/utilities/hooks/useQuery";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useInvitation = () => {
  const { loginWithRedirect } = useAuth0();

  const searchParams = useQuery();
  const invitation = searchParams.get("invitation");
  const organization = searchParams.get("organization");

  useEffect(() => {
    if (!invitation || !organization) {
      return;
    }

    loginWithRedirect({ authorizationParams: { invitation, organization } });
  }, [invitation, organization, loginWithRedirect]);
};

export default useInvitation;

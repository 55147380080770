import AnnotationReducer from "./store/annotationSlice";
import annotationsForEntitySelector from "./selectors/annotationsForEntitySelector";
import {
  EntityAnnotation,
  DocumentAnnotationsState,
} from "./interfaces/annotation";
import { mapAnnotationsToAnnotatorFormat } from "./utils/annotationMappers";
import useAnnotations from "./hooks/useAnnotations";
import { baseScale } from "./constants";
import { TableInput } from "./interfaces/table";

export {
  AnnotationReducer,
  annotationsForEntitySelector,
  mapAnnotationsToAnnotatorFormat,
  baseScale,
};

export type { EntityAnnotation, DocumentAnnotationsState, TableInput };

export default useAnnotations;

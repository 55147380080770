import React, { FC } from "react";
import TooltipValue from "./TooltipValue";

type Props = {
  label: string;
  value?: any;
};

const FullWidthLine: FC<Props> = ({ label, value }) => {
  if (!value) {
    return null;
  }

  return (
    <div className="full-width-line">
      <h5 className="label">{label}</h5>
      <TooltipValue value={value} />
    </div>
  );
};

export default FullWidthLine;

import { useCallback, useMemo } from "react";
import { SortDirection, SortState } from "../";
import { OverviewType, useAppDispatch, useAppSelector } from "../../../app";
import { ColumnKey } from "../../../documentSet";
import { useTranslation } from "react-i18next";
import { updateUserSorter } from "../../../user/store/userSlice";
import { setActiveSorter } from "../store/dataRefiningSlice";
import useStorageSortingKey from "./useStorageSortingKey";
import { useUpdateUserMutation } from "../../../user/store/userApi";

const useSorting = (overviewType: OverviewType) => {
  const {
    i18n: { language },
  } = useTranslation();

  const dispatch = useAppDispatch();

  const activeSorterStorageKey = useStorageSortingKey(overviewType);

  const { overviewSorter, archiveSorter } = useAppSelector(
    (state) => state.dataRefiningReducer
  );

  const { user, userSettings } = useAppSelector((state) => state.userReducer);

  const activeSorter = useMemo((): SortState | null => {
    if (overviewType === OverviewType.Archived) {
      return archiveSorter;
    }

    return overviewSorter;
  }, [overviewType, archiveSorter, overviewSorter]);

  const activeSortOrder = useMemo((): "ascend" | "descend" | null => {
    if (!activeSorter) {
      return null;
    }

    return activeSorter?.sortDirection === SortDirection.Ascending
      ? "ascend"
      : "descend";
  }, [activeSorter]);

  const [updateUser] = useUpdateUserMutation();

  const updateUserProfileSorter = useCallback(
    async (sorter: string) => {
      if (!user) {
        return;
      }

      dispatch(
        updateUserSorter({ key: activeSorterStorageKey, value: sorter })
      );

      const isArchive = overviewType === OverviewType.Archived;

      if (userSettings.saveSorter) {
        const body = {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
          metadata: {
            ...userSettings,
            picture: user.avatar,
            language,
            overviewSorter: isArchive ? userSettings.overviewSorter : sorter,
            archiveSorter: isArchive ? sorter : userSettings.archiveSorter,
          },
        };

        await updateUser({ userId: user.id, data: body }).unwrap();
      }
    },
    [
      dispatch,
      language,
      user,
      userSettings,
      activeSorterStorageKey,
      overviewType,
      updateUser,
    ]
  );

  const handleSorterToggle = useCallback(
    (clickedColumnHeaderKey: ColumnKey): void => {
      if (clickedColumnHeaderKey === activeSorter?.key) {
        dispatch(
          setActiveSorter({ storageKey: activeSorterStorageKey, state: null })
        );
        updateUserProfileSorter("");
      } else {
        dispatch(
          setActiveSorter({
            storageKey: activeSorterStorageKey,
            state: {
              key: clickedColumnHeaderKey,
              sortDirection: SortDirection.Ascending,
            },
          })
        );

        updateUserProfileSorter(
          `${clickedColumnHeaderKey}:${SortDirection.Ascending}`
        );
      }
    },
    [activeSorter, dispatch, updateUserProfileSorter, activeSorterStorageKey]
  );

  const handleSortDirectionToggle = useCallback(
    (key: ColumnKey): void => {
      if (!activeSorter) {
        return;
      }
      const { sortDirection }: SortState = activeSorter;
      if (sortDirection === SortDirection.Ascending) {
        dispatch(
          setActiveSorter({
            storageKey: activeSorterStorageKey,
            state: {
              key,
              sortDirection: SortDirection.Descending,
            },
          })
        );
        updateUserProfileSorter(`${key}:${SortDirection.Descending}`);
      } else {
        dispatch(
          setActiveSorter({
            storageKey: activeSorterStorageKey,
            state: {
              key,
              sortDirection: SortDirection.Ascending,
            },
          })
        );
        updateUserProfileSorter(`${key}:${SortDirection.Ascending}`);
      }
    },
    [activeSorter, dispatch, updateUserProfileSorter, activeSorterStorageKey]
  );

  const getSortOrderForKey = useCallback(
    (key: string) => {
      if (!activeSorter) {
        return null;
      }

      if (activeSorter.key !== key) {
        return null;
      }

      return activeSortOrder;
    },
    [activeSorter, activeSortOrder]
  );

  return {
    activeSorter,
    activeSortOrder,
    handleSortDirectionToggle,
    handleSorterToggle,
    getSortOrderForKey,
  };
};

export default useSorting;

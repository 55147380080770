import React, { FC } from "react";
import { OrderColumnItem } from "../../../interfaces/overview";
import DragDropColumns from "./DragDropColumns";
import "../TableHeader.scss";

interface Props {
  columns: Array<OrderColumnItem>;
  setColumns: (columns: Array<OrderColumnItem>) => void;
  updateOrderColumns: (columns: Array<OrderColumnItem>) => void;
}

const OrderColumnsContainer: FC<Props> = ({
  columns,
  setColumns,
  updateOrderColumns,
}) => {
  return (
    <div className="order-columns-popover__container">
      <DragDropColumns
        columns={columns}
        setColumns={setColumns}
        updateOrderColumns={updateOrderColumns}
      />
    </div>
  );
};

export default OrderColumnsContainer;

import { useCallback, useMemo } from "react";
import {
  GroupBlockData,
  GroupBlockEntityType,
  useMultipleGroupBlocks,
} from "../../configMap";
import useDocumentCategoryAnnotations from "../../metadata/hooks/useDocumentCategoryAnnotations";
import { RootState, useAppSelector } from "../../app";
import useAnnotations from "../../annotation/hooks/useAnnotations";

const useActionAddLine = (multipleLinesGroupBlocks: Array<GroupBlockData>) => {
  const { activeDocument } = useAppSelector((state) => state.documentReducer);

  const { addGroupBlock } = useMultipleGroupBlocks();

  const { updateMultiGroupBlockAnnotations } = useAnnotations({
    documentId: activeDocument?.id,
  });

  const { updateGroupBlockDocumentCategoryAnnotations } =
    useDocumentCategoryAnnotations();

  const selectedGroupBlock = useAppSelector(
    (state: RootState) =>
      state.annotationTableControlsReducer.selectedGroupBlockId
  );

  const groupBlockForTable = useMemo(() => {
    return multipleLinesGroupBlocks.find((gb) => gb.id === selectedGroupBlock);
  }, [selectedGroupBlock, multipleLinesGroupBlocks]);

  const categorizations = useMemo(() => {
    if (!groupBlockForTable?.categorizationGroupBlocks) {
      return [];
    }

    return [...groupBlockForTable?.categorizationGroupBlocks].map(
      (c) => c.categorization
    );
  }, [groupBlockForTable?.categorizationGroupBlocks]);

  return useCallback(
    (groupBlock: GroupBlockData, index: number) => {
      const currentIndex = index - 1;
      const targetIndex = index + 1;

      updateMultiGroupBlockAnnotations(
        true,
        groupBlock.groupBlockEntityTypes?.map(
          (entity: GroupBlockEntityType) => entity.entityType.id
        ) || [],
        currentIndex,
        activeDocument?.id || 0,
        false
      );
      updateGroupBlockDocumentCategoryAnnotations(
        categorizations,
        currentIndex,
        false
      );
      addGroupBlock(
        groupBlock,
        `group-block-${groupBlock.id}-${index}`,
        targetIndex
      );
    },
    [
      updateMultiGroupBlockAnnotations,
      activeDocument?.id,
      updateGroupBlockDocumentCategoryAnnotations,
      categorizations,
      addGroupBlock,
    ]
  );
};

export default useActionAddLine;

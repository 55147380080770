import { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../app";
import { changeJwtLoggedIn } from "../store/userSlice";
import useIsAuthenticated from "./useIsAuthenticated";
import useAuthentication from "./useAuthentication";

// @ts-ignore
const JWT_TOKEN = window.__JWT_TOKEN__;

const useJwtAuthenticate = () => {
  const dispatch = useAppDispatch();

  const authenticateUser = useAuthentication();

  const { isAuthenticated } = useIsAuthenticated();

  const handleJwtTokenLogin = useCallback(
    async (jwt: string) => {
      if (!jwt?.startsWith("Bearer")) {
        return;
      }

      const jwt_to_use = JWT_TOKEN.replace("Bearer ", "").trim();

      if (isAuthenticated) {
        return;
      }

      if (jwt_to_use) {
        await authenticateUser(jwt_to_use);

        dispatch(changeJwtLoggedIn(true));
      }
    },
    [authenticateUser, isAuthenticated, dispatch]
  );

  const handleJwtLogout = useCallback(() => {
    dispatch(changeJwtLoggedIn(false));
  }, [dispatch]);

  useEffect(() => {
    handleJwtTokenLogin(JWT_TOKEN);
  }, [handleJwtTokenLogin]);

  return handleJwtLogout;
};

export default useJwtAuthenticate;

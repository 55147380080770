import React, { FC } from "react";
import usePDF from "./hooks/usePDF";
import Error from "./components/Error";
import Controls from "./components/Controls";
import TableOverview from "./components/TableOverview";
import TempAnnotationOverview from "./components/TempAnnotationOverview";
import Pages from "./components/pages/Pages";
import useAutoScroll from "./hooks/useAutoScroll";
import "./scss/style.scss";

interface Props {
  data: Uint8Array | BufferSource | string;
}

const Annotator: FC<Props> = ({ data }) => {
  const { error, fetchPage } = usePDF(data);

  const autoScroll = useAutoScroll();

  if (!data || error) {
    return (
      <div className="annotator-container">
        <div className="annotator-pages-container">
          <div className="annotator-pages">
            <Error hasNoData={!data} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="annotator-container">
      <div
        id="annotator-pages-container"
        className="annotator-pages-container"
        onMouseMove={autoScroll}
      >
        <div className="annotator-pages">
          <Controls />
          <Pages fetchPage={fetchPage} />
          <TableOverview />
          <TempAnnotationOverview />
        </div>
      </div>
    </div>
  );
};

export default Annotator;

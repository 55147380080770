import React, { FC, useMemo } from "react";
import { Select, Tooltip } from "antd";
import SelectEmptyState from "./SelectEmptyState";
import { CategorizationWithConfig } from "../../../configMap/interfaces/category";
import "./CategorizationCell.scss";
import useCategorizationCell from "../../hooks/useCategorizationCell";

type Props = {
  disabled: boolean;
  categorizationWithConfig: CategorizationWithConfig;
  index: number;
  groupBlockId?: string;
};

const { Option } = Select;

const mapping = { value: "key" };

const CategorizationCell: FC<Props> = ({
  categorizationWithConfig,
  disabled,
  index,
  groupBlockId,
}) => {
  const {
    handleChange,
    selectedCategory,
    options,
    handleTypingChange,
    handleSearch,
    handleFilter,
    disableTyping,
    status,
    selectRef,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
    isIncomplete,
  } = useCategorizationCell(categorizationWithConfig, index, groupBlockId);

  const tooltipValue = useMemo(() => {
    if (isCategorizationDisabledByParent) {
      return disabledParentTooltip;
    }

    if (!selectedCategory) {
      return null;
    }

    if (selectedCategory.visibleValue) {
      return (
        <>
          <div>
            <strong>Name: </strong>
            {selectedCategory.visibleValue}
          </div>
          <div>
            <strong>Value: </strong>
            {selectedCategory.value}
          </div>
        </>
      );
    }

    return selectedCategory.value;
  }, [
    selectedCategory,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
  ]);

  const className = useMemo(() => {
    if (disabled) {
      return "categorization-item__select-disabled";
    }

    let classes = "";
    if (isIncomplete) {
      classes += "categorization-item__select-is-incomplete";
    }
    if (isCategorizationDisabledByParent) {
      classes += " categorization-item__select-no-parent-value";
    }

    return classes;
  }, [disabled, isCategorizationDisabledByParent, isIncomplete]);

  return (
    <div className="categorization-item__container">
      <Tooltip placement="top" title={tooltipValue}>
        <Select
          open={disableTyping}
          ref={selectRef}
          allowClear
          showSearch
          value={selectedCategory?.value}
          placeholder="Select a value"
          optionFilterProp="children"
          onChange={(value) => handleChange(value, selectRef.current!.blur)}
          onFocus={handleTypingChange}
          onBlur={handleTypingChange}
          onSearch={handleSearch}
          filterOption={handleFilter}
          notFoundContent={<SelectEmptyState />}
          fieldNames={mapping}
          disabled={disabled || isCategorizationDisabledByParent}
          className={className}
          status={status}
          popupMatchSelectWidth={false}
        >
          {options.map((o) => {
            const { label, value, key } = o;
            const split = label.split(/\n/);

            if (split.length > 1) {
              const height = split.length * 25;
              return (
                <Option
                  key={key}
                  value={value}
                  label={label}
                  style={{ height: `${height}px` }}
                >
                  <ul>
                    {split.map((s) => (
                      <li key={s}>{s}</li>
                    ))}
                  </ul>
                </Option>
              );
            }

            return (
              <Option key={key} value={value} label={label}>
                {o.label}
              </Option>
            );
          })}
        </Select>
      </Tooltip>
    </div>
  );
};

export default CategorizationCell;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { ITable } from "../interfaces/ITable";

type Props = {
  page: number;
};

const getTables = (state: RootState) =>
  state.annotatorReducer.tableState.tables;
const getPageNumber = (_: RootState, props: Props) => props.page;

const tablesForPageSelector = createSelector(
  [getTables, getPageNumber],
  (tables, page): Array<ITable> => {
    if (!tables.length) {
      return tables;
    }

    return tables.filter((table) => table.page === page);
  }
);

export default tablesForPageSelector;

import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app";
import useActiveDocument from "../../document/hooks/useActiveDocument";
import {
  ERROR_WHILE_NORMALIZING,
  INVALID_VALUE,
} from "../../configMap/utils/validationUtils";
import { Annotation } from "../../annotator/interfaces/annotation";
// @ts-ignore
import hash from "object-hash";
import { groupBy } from "lodash";
import annotationsForEntitiesSelector from "../../annotation/selectors/annotationsForEntitiesSelector";
import { SHOW_ISSUES_STATUS_LIST } from "../constants";

const useAttentionRequired = (entityIds: Array<string> | undefined) => {
  const { activeDocument } = useActiveDocument();

  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );

  const annotationsForEntity = useAppSelector((state) =>
    annotationsForEntitiesSelector(state, {
      documentId: activeDocument?.id,
      entityIds,
    })
  );

  const [oldAnnotationsForEntity, setOldAnnotationsForEntity] = useState<
    string | null
  >(null);

  const [scoreAttentionRequired, setScoreAttentionRequired] = useState(false);
  const [errorAttentionRequired, setErrorAttentionRequired] = useState(false);

  const [scoreAttentionAnnotations, setScoreAttentionAnnotations] = useState<
    Array<Annotation>
  >([]);
  const [errorAttentionAnnotations, setErrorAttentionAnnotations] = useState<
    Array<Annotation>
  >([]);

  const isScoreAttentionRequired = (annotations: Array<Annotation>) => {
    const total = annotations
      .map((a) => {
        if (!a.modelScore) {
          return 100;
        }

        return a.modelScore;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const avg = total / annotations.length;
    return avg <= 90;
  };

  const isErrorAttentionRequired = (annotations: Array<Annotation>) => {
    return annotations
      .map((a) => {
        const value =
          a.entityAnnotationNormalization?.normalizedValue?.toLowerCase();
        return (
          value === INVALID_VALUE.toLowerCase() ||
          value === ERROR_WHILE_NORMALIZING.toLowerCase()
        );
      })
      .includes(true);
  };

  const calculateAttentionRequired = useCallback(
    (annotations: Array<Annotation>) => {
      if (
        !activeDocumentSet ||
        !SHOW_ISSUES_STATUS_LIST.includes(activeDocumentSet.status)
      ) {
        setScoreAttentionRequired(false);
        setErrorAttentionRequired(false);
        return;
      }

      if (!annotations.length) {
        setScoreAttentionRequired(false);
        setErrorAttentionRequired(false);
        return;
      }

      let scoreAttention = false;
      let errorAttention = false;
      const scoreAttentionAnnotations = [];
      const errorAttentionAnnotations = [];

      const groupedAnnotationsByEntityAndIndex = groupBy(
        annotations,
        (annotation) => [annotation.entity.id, annotation.index]
      );

      for (const groupedAnnotations of Object.values(
        groupedAnnotationsByEntityAndIndex
      )) {
        const scoreAttentionTemp = isScoreAttentionRequired(groupedAnnotations);
        if (scoreAttentionTemp) {
          scoreAttention = true;
          scoreAttentionAnnotations.push(groupedAnnotations[0]);
        }

        const errorAttentionTemp = isErrorAttentionRequired(groupedAnnotations);
        if (errorAttentionTemp) {
          errorAttention = true;
          errorAttentionAnnotations.push(groupedAnnotations[0]);
        }
      }

      setScoreAttentionRequired(scoreAttention);
      setErrorAttentionRequired(errorAttention);
      setErrorAttentionAnnotations(errorAttentionAnnotations);
      setScoreAttentionAnnotations(scoreAttentionAnnotations);
    },
    [activeDocumentSet]
  );

  useEffect(() => {
    if (hash(annotationsForEntity) === oldAnnotationsForEntity) {
      return;
    }

    setOldAnnotationsForEntity(hash(annotationsForEntity));
    calculateAttentionRequired(annotationsForEntity);
  }, [
    annotationsForEntity,
    oldAnnotationsForEntity,
    calculateAttentionRequired,
  ]);

  return {
    errorAttentionRequired,
    scoreAttentionRequired,
    errorAttentionAnnotations,
    scoreAttentionAnnotations,
  };
};

export default useAttentionRequired;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { Entity } from "../interfaces/entity";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { primaryColor } from "../../common/utilities/color";

type Props = {
  language: string;
};

const getConfigMap = (state: RootState) => state.configMapReducer.configMap;
const getGroupBlocksState = (state: RootState) =>
  state.configMapReducer.groupBlock;
const getActiveEntity = (state: RootState) => state.appReducer.activeEntity;
const getIsAnnotating = (state: RootState) => state.appReducer.isAnnotating;
const getLanguage = (_: RootState, props: Props) => props.language;

const activeEntityForAnnotatorSelector = createSelector(
  [
    getConfigMap,
    getGroupBlocksState,
    getActiveEntity,
    getIsAnnotating,
    getLanguage,
  ],
  (
    configMap,
    groupBlockState,
    activeEntity,
    isAnnotating,
    language
  ): Entity | null => {
    if (!configMap || !activeEntity || !isAnnotating) {
      return null;
    }

    const foundGroupBlock = configMap.groupBlocks.find((block) =>
      block
        .groupBlockEntityTypes!.flatMap((entity) => entity.entityType.id)
        .includes(activeEntity.entityType.id)
    );

    if (!foundGroupBlock) {
      return null;
    }

    const count =
      groupBlockState.groupBlockCount.find(
        (block) => block.groupBlockId === foundGroupBlock.id
      )?.count || 1;

    return {
      id: activeEntity.entityType.id,
      name: getInLanguageOrDefault(
        activeEntity.entityType.translations,
        language
      ),
      color: activeEntity.color || primaryColor,
      entityType: "NER",
      index: activeEntity.index,
      info: {
        lineCount: count,
      },
      multipleGroupBlocks: foundGroupBlock.multipleGroupBlocks,
    };
  }
);

export default activeEntityForAnnotatorSelector;

import React, { FC } from "react";
import HoverButtons from "./HoverButtons";
import Loader from "../../../../common/loader/Loader";

type Props = {
  annotationLoading: boolean;
  isEditing: boolean;
  annotationsForEntity: any;
  removeAnnotation: (annotationId: string) => void;
  disabled: boolean;
};

const HoverButtonsWrapper: FC<Props> = ({
  annotationLoading,
  isEditing,
  annotationsForEntity,
  removeAnnotation,
  disabled,
}) => {
  if (annotationLoading) {
    return (
      <div className="loader">
        <Loader size="small" />
      </div>
    );
  }

  if (disabled) {
    return null;
  }

  if (isEditing) {
    return null;
  }

  switch (annotationsForEntity.length) {
    case 0:
      return <HoverButtons showEditIcon={true} showRemoveIcon={false} />;
    case 1:
      return (
        <HoverButtons
          showEditIcon={true}
          showRemoveIcon={true}
          onClickRemove={(event) => {
            event.stopPropagation();
            removeAnnotation(annotationsForEntity[0].id);
          }}
        />
      );
    default:
      return null;
  }
};

export default HoverButtonsWrapper;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Filters,
  OrganizationInput,
  OrganizationResponse,
  TableData,
} from "../interfaces/table";
import { Role } from "../interfaces/role";

interface UserManagementState {
  members: Array<TableData>;
  filteredMembers: Array<TableData>;
  validRoles: Array<Role>;
  organizationSources: Array<OrganizationInput>;
  editingMemberId?: string;
  filters: Filters;
}

const initialState: UserManagementState = {
  members: [],
  filteredMembers: [],
  validRoles: [],
  organizationSources: [],
  filters: {
    email: "",
    name: "",
    sources: [],
    roles: [],
  },
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    fetchedMemberList: (state, action: PayloadAction<Array<TableData>>) => {
      state.members = action.payload;
      state.filteredMembers = action.payload;
    },
    changeEditingMemberId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.editingMemberId = action.payload;
    },
    updateFilters: (state, action: PayloadAction<Filters>) => {
      state.filters = action.payload;
    },
    setFilteredMembers: (state, action: PayloadAction<Array<TableData>>) => {
      state.filteredMembers = action.payload;
    },
    setOrganizationResponse: (
      state,
      action: PayloadAction<OrganizationResponse>
    ) => {
      const { members, inputs, roles } = action.payload;

      const lowerCaseMembers = members.map((member) => {
        return {
          ...member,
          permissions: member.permissions.map((p) => p.toLowerCase()),
        };
      });
      state.members = lowerCaseMembers;
      state.filteredMembers = lowerCaseMembers;
      state.validRoles = roles;
      state.organizationSources = inputs.map((input) => ({
        ...input,
        id: input.id,
      }));
    },
  },
});

export const {
  fetchedMemberList,
  changeEditingMemberId,
  updateFilters,
  setFilteredMembers,
  setOrganizationResponse,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;

import { GroupBlockEntityType } from "../interfaces/entity";
import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import flatEntityListSelector from "./flatEntityListSelector";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
};

const getGroupBlockEntityType = (_: RootState, props: Props) =>
  props.groupBlockEntityType;

const linkedEntitiesSelector = createSelector(
  [flatEntityListSelector, getGroupBlockEntityType],
  (entities, groupBlockEntityType): Array<GroupBlockEntityType> => {
    const { parentId, children } = groupBlockEntityType;

    if (parentId) {
      return entities.filter((e) => e.id === parentId.id);
    }

    if (children?.length) {
      const childrenIds = children.map((c) => c.id);
      return entities.filter((e) => childrenIds.includes(e.id));
    }

    return [];
  }
);

export default linkedEntitiesSelector;

import React, { FC, MutableRefObject, useRef } from "react";
import { Badge, Button, Popover, Row } from "antd";
import { useTranslation } from "react-i18next";
import FilterableItemSwitch from "./FilterableItemSwitch";
import useTableHeaderPopover from "../../userManagement/hooks/useTableHeaderPopover";
import "./TableHeaderFilter.scss";
import { Option } from "../dataRefining/interfaces/filteringData";

interface Props {
  prop: any;
  value: any;
  label: string;
  updateFilter: (prop: any, value: any) => void;
  applyFilter: () => void;
  options?: Array<Option>;
}

interface PropExtended extends Props {
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
}

const TableHeaderFilter: FC<Props> = ({
  prop,
  value,
  updateFilter,
  applyFilter,
  label,
  options,
}) => {
  const { handleOpenChange, isOpen } = useTableHeaderPopover(applyFilter);

  const hasFilter = value && value.length;

  return (
    <div
      className="column-header-container"
      onClick={(event) => event.stopPropagation()}
    >
      <Row className="content-align-container">
        <Popover
          placement="bottomRight"
          content={
            <FilterPopoverBody
              setIsOpen={handleOpenChange}
              isOpen={isOpen}
              updateFilter={updateFilter}
              applyFilter={applyFilter}
              value={value}
              label={label}
              prop={prop}
              options={options}
            />
          }
          overlayClassName="filter-popover"
          trigger="click"
          onOpenChange={handleOpenChange}
          open={isOpen}
          destroyTooltipOnHide
        >
          <Badge dot={hasFilter} size="small" color="#38b2ac">
            <i className="bi bi-funnel-fill overview-header__icon" />
          </Badge>
        </Popover>
      </Row>
    </div>
  );
};

const FilterPopoverBody: FC<PropExtended> = ({
  setIsOpen,
  isOpen,
  applyFilter,
  updateFilter,
  value,
  label,
  prop,
  options,
}) => {
  const renderRef: MutableRefObject<any> = useRef(null);
  const { t } = useTranslation("overview");

  return (
    <div className="filter-popover__container" ref={renderRef}>
      <FilterableItemSwitch
        renderRef={renderRef}
        isOpen={isOpen}
        updateFilter={updateFilter}
        value={value}
        label={label}
        prop={prop}
        options={options}
      />
      <Button
        type="primary"
        onClick={() => {
          applyFilter();
          setIsOpen(false);
        }}
        block
      >
        {t("filters.button")}
      </Button>
    </div>
  );
};

export default TableHeaderFilter;

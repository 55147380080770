import React, { FC } from "react";
import { Button } from "antd";
import ActionButton from "../ActionButton";
import {
  determineNonErrorStatus,
  Status,
} from "../../../../common/status/status";
import { DocumentSetData } from "../../../../documentSet";
import { useTranslation } from "react-i18next";
import { ButtonType } from "../../../../common";
import { useAppSelector } from "../../../../app";

type Props = {
  activeDocumentSet: DocumentSetData;
  handleRejectClick: (setIsLoading: (isLoading: boolean) => void) => void;
  setIsLoading: (state: boolean) => void;
  openUserConflictModal: () => void;
};

const DefaultButtonGroup: FC<Props> = ({
  activeDocumentSet,
  handleRejectClick,
  setIsLoading,
  openUserConflictModal,
}) => {
  const { t } = useTranslation("annotationSideControls");

  const activeButton = useAppSelector((state) => state.appReducer.activeButton);

  return (
    <>
      <Button
        id="action-reject"
        onClick={() => handleRejectClick(setIsLoading)}
        className={activeButton?.id === "action-reject" ? "btn-focused" : ""}
        icon={<i className="bi bi-hand-thumbs-down" />}
        ghost
      >
        {t("annotationSideControlsFooter.reject")}
      </Button>
      <ActionButton
        id="action-hold"
        status={determineNonErrorStatus(
          activeDocumentSet?.status!,
          Status.OnHold
        )}
        message={t("messages.holdSuccess")}
        buttonText={t("annotationSideControlsFooter.hold")}
        buttonType={ButtonType.Hold}
        setIsLoading={setIsLoading}
        icon={<i className="bi bi-pause" />}
        openUserConflictModal={openUserConflictModal}
      />
    </>
  );
};

export default DefaultButtonGroup;

import React, { FC } from "react";
import { Modal, Button } from "antd";
import "./UserConflictModal.scss";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  goToOverview: () => void;
};

const UserConflictModal: FC<Props> = ({ isOpen, setIsOpen, goToOverview }) => {
  const { t } = useTranslation("annotationSideControls");

  return (
    <Modal
      onCancel={() => setIsOpen(false)}
      open={isOpen}
      footer={[
        <Button key="stayOnDocument" type="primary" onClick={goToOverview}>
          {t("userConflict.goToOverviewButton")}
        </Button>,
        <Button key="goToOverview" onClick={() => setIsOpen(false)}>
          {t("userConflict.stayOnDocumentButton")}
        </Button>,
      ]}
    >
      <div className="user-conflict-modal__content-container">
        <i className="bi bi-exclamation-circle" style={{ color: "#faad14" }} />
        <h3>{t("userConflict.title")}</h3>
      </div>
    </Modal>
  );
};

export default UserConflictModal;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { IPdfPageData } from "../../annotator/interfaces/pdf";

type Props = {
  page: number;
};

const selectPdf = (state: RootState) => state.documentReducer.pdf;
const selectPageNumber = (_: RootState, props: Props) => props.page;

const pageForPageNumberSelector = createSelector(
  [selectPdf, selectPageNumber],
  (pdf, page): IPdfPageData | null => {
    if (!pdf) {
      return null;
    }

    const found = pdf.pages.find((p) => p.page === page);

    if (!found) {
      return null;
    }

    return found;
  }
);

export default pageForPageNumberSelector;

import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback, useEffect, useMemo } from "react";
import { TableData } from "../interfaces/table";
import { changeEditingMemberId } from "../store/UserManagementSlice";
import useUserInputs from "./useUserInputs";
import useMemberRoles from "./useMemberRoles";
import { ADMIN_ROLE_ID } from "../../common/utilities/env";

const useManagementModal = () => {
  const dispatch = useAppDispatch();

  const { members, editingMemberId } = useAppSelector(
    (state) => state.userManagementReducer
  );
  const { user } = useAppSelector((state) => state.userReducer);

  const {
    userInputs,
    loadMemberInputs,
    handlePermissionsChange,
    options,
    handleSearch,
  } = useUserInputs();

  const { roles, handleCheckedChange } = useMemberRoles();

  const member = useMemo((): TableData | null => {
    if (!editingMemberId) {
      return null;
    }

    const foundMember = members.find((mem) => mem.id === editingMemberId);

    if (!foundMember) {
      return null;
    }

    return foundMember;
  }, [members, editingMemberId]);

  const stopEditingMember = useCallback(() => {
    dispatch(changeEditingMemberId(undefined));
  }, [dispatch]);

  const loadMember = useCallback(() => {
    if (!user?.access.includes(ADMIN_ROLE_ID)) {
      return;
    }

    if (!editingMemberId || !user?.organization.id) {
      return;
    }

    loadMemberInputs();
  }, [user, editingMemberId, loadMemberInputs]);

  useEffect(() => {
    loadMember();
  }, [loadMember]);

  return {
    member,
    roles,
    stopEditingMember,
    handleCheckedChange,
    userInputs,
    handlePermissionsChange,
    options,
    handleSearch,
  };
};

export default useManagementModal;

import React, { FC, MutableRefObject, useMemo, useRef } from "react";
import { Entity } from "../../../../annotator/interfaces/entity";
import { Point } from "../../../interfaces/point";
import { getAccessibleTextColor } from "../../../helpers/color";
import useCursorText from "../../../../annotator/hooks/useCursorText";

interface Props {
  mouseCoords: Point;
  entity: Entity | null;
}

const OFFSET = 15;

const CursorText: FC<Props> = ({ entity, mouseCoords }) => {
  const ref: MutableRefObject<HTMLSpanElement | null> = useRef(null);

  const style = useMemo(() => {
    if (!entity || !ref.current) {
      return {};
    }

    return {
      left: `${mouseCoords.x + OFFSET}px`,
      top: `${mouseCoords.y + OFFSET}px`,
      backgroundColor: entity.color,
      color: getAccessibleTextColor(entity.color),
    };
  }, [entity, mouseCoords]);

  const value = useCursorText(entity);

  if (!entity) {
    return null;
  }

  return (
    <span className="cursor-text" ref={ref} style={style}>
      {value}
    </span>
  );
};

export default CursorText;

import React, { FC, memo, Profiler } from "react";
import { useAppSelector } from "../../../../app";
import AnnotationItem from "./AnnotationItem";
import "./AnnotationLayer.scss";
import { onRender } from "../../../../app/utils/profiling";
import annotationsForPageSelector from "../../../../annotation/selectors/annotationsForPageSelector";

type Props = {
  page: number;
  pdfWidth: number;
  pdfHeight: number;
};

const AnnotationLayer: FC<Props> = ({ page, pdfWidth, pdfHeight }) => {
  const annotations = useAppSelector((state) =>
    annotationsForPageSelector(state, { page })
  );

  return (
    <Profiler id="AnnotationLayer" onRender={onRender}>
      <div
        className="annotation-layer__page-container"
        style={{ width: `${pdfWidth}px`, height: `${pdfHeight}px` }}
      >
        {annotations.map((annotation) => (
          <AnnotationItem
            key={annotation.id}
            page={page}
            annotation={annotation}
          />
        ))}
      </div>
    </Profiler>
  );
};

export default memo(AnnotationLayer);

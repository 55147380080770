import { useMemo } from "react";
import { useAppSelector } from "../../app";
import { calculateTextWidth } from "../../common/utilities/text";
import { TAG_MARGIN, TAG_PADDING } from "../utils/tags";

const useOrgPermissionTagsInfo = () => {
  const { organizationSources } = useAppSelector(
    (state) => state.userManagementReducer
  );

  return useMemo(() => {
    return [...organizationSources].map((os) => ({
      width:
        calculateTextWidth(os.displayName, 12, "inter, sans-serif") +
        TAG_MARGIN +
        TAG_PADDING,
      name: os.displayName,
    }));
  }, [organizationSources]);
};

export default useOrgPermissionTagsInfo;

import React from "react";
import "./PersonalPage.scss";
import { Avatar, Button, Input } from "antd";
import Loader from "../../../common/loader/Loader";
import HeaderWithAccent from "../../../common/headerWithAccent/headerWithAccent";
import SettingsSwitchSelector from "./settingsSwitchRefactor/SettingsSwitchSelector";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./languageSelector/LanguageSelector";
import ValueInput from "./valueInput/ValueInput";
import PasswordChange from "./passwordChange/PasswordChange";
import { usePersonalPage } from "../../index";

const PersonalPage = () => {
  const { t } = useTranslation("personalPage");

  const {
    handleBlur,
    removeAvatar,
    handleAvatarChange,
    user,
    state,
    setHasBeenUpdated,
  } = usePersonalPage();

  if (!user) {
    return <Loader fullPage />;
  }

  return (
    <div className="personal-page__container">
      <div className="personal-page__section">
        <HeaderWithAccent>{t("userSettings.header")}</HeaderWithAccent>
        <div className="personal-page__section-wrapper">
          <div className="personal-page__user-info-container">
            <div className="personal-page__user-info">
              <Input.Group>
                <h5>{t("userSettings.userInfo.firstNameLabel")}</h5>
                <ValueInput
                  name="firstName"
                  value={state.firstName}
                  handleBlur={handleBlur}
                />
                <h5>{t("userSettings.userInfo.lastNameLabel")}</h5>
                <ValueInput
                  name="lastName"
                  value={state.lastName}
                  handleBlur={handleBlur}
                />
                <h5>{t("userSettings.userInfo.usernameLabel")}</h5>
                <ValueInput
                  name="username"
                  value={state.username}
                  handleBlur={handleBlur}
                />
                <h5>{t("userSettings.userInfo.emailLabel")}</h5>
                <label className="personal-page__label">{user.email}</label>
                <h5>{t("userSettings.userInfo.companyLabel")}</h5>
                <label className="personal-page__label">
                  {user.organization.name}
                </label>
                <h5>{t("userSettings.userInfo.passwordLabel")}</h5>
                <PasswordChange />
              </Input.Group>
            </div>
            <div className="personal-page__avatar-container">
              <Avatar
                size={175}
                src={state.avatar || user.defaultAvatar}
                className="personal-page__avatar"
              >
                {state.avatar || user.defaultAvatar}
              </Avatar>
              <div className="personal-page__avatar-controls">
                <label className="personal-page__custom-input">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                  />
                  {t("userSettings.avatar.changeButton")}
                </label>
                <Button shape="round" onClick={removeAvatar}>
                  {t("userSettings.avatar.removeButton")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="personal-page__section">
        <HeaderWithAccent>{t("languageSettings.header")}</HeaderWithAccent>
        <div className="personal-page__section-wrapper">
          <div className="personal-page__settings-items">
            <LanguageSelector changeHasBeenUpdated={setHasBeenUpdated} />
          </div>
          <div className="personal-page__placeholder" />
        </div>
      </div>
      <div className="personal-page__section">
        <HeaderWithAccent>{t("applicationSettings.header")}</HeaderWithAccent>
        <div className="personal-page__section-wrapper">
          <div className="personal-page__settings-items">
            <SettingsSwitchSelector
              icon={<i className="bi bi-highlighter" />}
              text={t("applicationSettings.annotationModeSetting.label")}
              property="enableAnnotationMode"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-eye-slash" />}
              text={t("applicationSettings.requiredEntitySetting.label")}
              property="hideNonRequiredEntities"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-bounding-box-circles" />}
              text={t("applicationSettings.annotationBoxes.label")}
              property="hideAnnotationBoxes"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-funnel" />}
              text={t("applicationSettings.saveFiltersSetting.label")}
              property="saveFilters"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-sort-alpha-down" />}
              text={t("applicationSettings.saveSorterSetting.label")}
              property="saveSorter"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <div className="personal-page__spacer" />
            <SettingsSwitchSelector
              icon={<i className="bi bi-file-earmark-plus" />}
              text={t("applicationSettings.rejectNextDocumentSetting.label")}
              property="rejectOpenNextDocument"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-file-earmark-plus" />}
              text={t("applicationSettings.holdNextDocumentSetting.label")}
              property="holdOpenNextDocument"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
            <SettingsSwitchSelector
              icon={<i className="bi bi-file-earmark-plus" />}
              text={t("applicationSettings.approveNextDocumentSetting.label")}
              property="approveOpenNextDocument"
              changeHasBeenUpdated={setHasBeenUpdated}
            />
          </div>
          <div className="personal-page__placeholder" />
        </div>
      </div>
    </div>
  );
};

export default PersonalPage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ConfigMap,
  GroupBlockCount,
  GroupBlockData,
  UpdateGroupBlockCountAction,
} from "../";

interface GroupBlockState {
  groupBlockCount: Array<GroupBlockCount>;
  lastGroupBlockId: string | null;
}

interface ConfigMapState {
  configMap?: ConfigMap;
  groupBlock: GroupBlockState;
  loading: boolean;
  disabledEntities: Array<string>;
}

const initialState: ConfigMapState = {
  configMap: undefined,
  groupBlock: {
    groupBlockCount: [],
    lastGroupBlockId: null,
  },
  loading: false,
  disabledEntities: [],
};

export const configMapSlice = createSlice({
  name: "configMap",
  initialState,
  reducers: {
    updateGroupBlockCount: (
      state,
      action: PayloadAction<UpdateGroupBlockCountAction>
    ) => {
      const { groupBlock, countUpdate } = action.payload;
      if (!groupBlock.multipleGroupBlocks) {
        return;
      }
      state.groupBlock.groupBlockCount = state.groupBlock.groupBlockCount.map(
        (block) => {
          if (block.groupBlockId === groupBlock.id) {
            return { ...block, count: block.count + countUpdate };
          }
          return block;
        }
      );
    },
    updateDisabledEntities: (state, action: PayloadAction<Array<string>>) => {
      state.disabledEntities = action.payload;
    },
    initialGroupBlockCounts: (
      state,
      action: PayloadAction<Array<GroupBlockCount>>
    ) => {
      state.groupBlock.groupBlockCount = action.payload;
    },
    setLastBlockId: (state, action: PayloadAction<string>) => {
      state.groupBlock.lastGroupBlockId = action.payload;
    },
    configMapIsLoading: (state) => {
      state.loading = true;
    },
    resetGroupBlocks: (state) => {
      state.groupBlock = {
        groupBlockCount: [],
        lastGroupBlockId: null,
      };
    },
    setConfigMap: (state, action: PayloadAction<ConfigMap>) => {
      state.configMap = action.payload;
      state.loading = false;
    },
    resetConfigMapReducer: () => initialState,
    resetGroupBlock: (state, action: PayloadAction<GroupBlockData>) => {
      const groupBlock = action.payload;
      if (!groupBlock.multipleGroupBlocks) {
        return;
      }
      state.groupBlock.groupBlockCount = state.groupBlock.groupBlockCount.map(
        (block) => {
          if (block.groupBlockId === groupBlock.id) {
            return { ...block, count: 1 };
          }
          return block;
        }
      );
    },
  },
});

export const {
  setConfigMap,
  configMapIsLoading,
  resetConfigMapReducer,
  updateGroupBlockCount,
  updateDisabledEntities,
  setLastBlockId,
  initialGroupBlockCounts,
  resetGroupBlocks,
  resetGroupBlock,
} = configMapSlice.actions;

export default configMapSlice.reducer;

import { useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app";
import {
  setInitialDataForPdf,
  updateActiveDocument,
} from "../store/documentSlice";
import { Document } from "../";
import { setMetadataLanguage } from "../../metadata/store/metadataSlice";
import { Status } from "../../common/status/status";
import camelcaseKeys from "camelcase-keys";
import {
  useLazyFetchPdfTextQuery,
  useLazyGetDocumentQuery,
} from "../store/documentApi";
import { inflate } from "pako";

const useActiveDocument = () => {
  const dispatch = useAppDispatch();

  const { activeDocument: activeDocumentMinimal } = useAppSelector(
    (state) => state.documentReducer
  );
  const { activeDocumentSet } = useAppSelector(
    (state) => state.documentSetsReducer
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setActiveDocument = useCallback(
    (document?: Document) => {
      dispatch(updateActiveDocument(document));
    },
    [dispatch]
  );

  const activeDocument = useMemo(() => {
    if (!activeDocumentSet?.documents?.length || !activeDocumentMinimal) {
      return undefined;
    }

    return activeDocumentSet.documents.find(
      (d) => d.id === activeDocumentMinimal.id
    );
  }, [activeDocumentMinimal, activeDocumentSet]);

  const [getDocument] = useLazyGetDocumentQuery();
  const [getPdfText] = useLazyFetchPdfTextQuery();

  const fetchDocument = useCallback(
    async (documentId: string, status: Status, signal?: AbortSignal) => {
      if (activeDocumentMinimal?.id === documentId) {
        return;
      }

      setIsLoading(true);

      try {
        const data = await getDocument(documentId).unwrap();
        const { metaData, documentType, name, pageCount } = data;

        dispatch(
          updateActiveDocument({
            ...data,
            metaData: camelcaseKeys(metaData),
            documentType: documentType,
          })
        );

        if (
          name.toLowerCase().includes(".pdf") &&
          !activeDocumentSet!.input.legacyAnnotator
        ) {
          const textBuffer = await getPdfText(documentId).unwrap();
          const pdfText = inflate(textBuffer, { to: "string" });

          dispatch(
            setInitialDataForPdf({
              text: pdfText,
              pageCount,
            })
          );
        }

        dispatch(setMetadataLanguage(metaData?.language || "en"));

        setIsLoading(false);
      } catch (e) {
        if (signal?.aborted) {
          return;
        }

        console.error(e);
        setIsLoading(false);
      }
    },
    [
      activeDocumentMinimal?.id,
      dispatch,
      getDocument,
      getPdfText,
      activeDocumentSet,
    ]
  );

  return {
    activeDocument,
    activeDocumentMinimal,
    setActiveDocument,
    fetchDocument,
    isLoading,
  };
};

export default useActiveDocument;

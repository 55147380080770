export const cleanObject = (obj: any) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const get = (obj: any, path: string, def: string): any => {
  const fullPath = path
    .replace(/\[/g, ".")
    .replace(/]/g, "")
    .split(".")
    .filter(Boolean);

  return fullPath.every(everyFunc) ? obj : def;

  function everyFunc(step: string) {
    try {
      return !(step && (obj = JSON.parse(obj)[step]) === undefined);
    } catch (e) {
      return !(step && (obj = obj[step]) === undefined);
    }
  }
};

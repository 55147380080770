import { useAppSelector } from "../../../app";
import { useMemo } from "react";
import dayjs from "dayjs";

type Props<T> = {
  mapFields: (record: T) => Record<string, any>;
  prefixFileName: string;
};

const useTableCsvExportButton = <T>(records: Array<T>, props: Props<T>) => {
  const { mapFields, prefixFileName } = props;

  const user = useAppSelector((state) => state.userReducer.user);

  const csvData = useMemo(() => {
    if (!records?.length) {
      return [];
    }

    return records.map((record) => mapFields(record));
  }, [records, mapFields]);

  const fileName = useMemo(() => {
    const organizationName = user?.organization?.name;
    const timestamp = dayjs().format("YYYY-MM-DDTHH:mm:ss");

    return `${prefixFileName}_${organizationName}_${timestamp}.csv`;
  }, [prefixFileName, user?.organization.name]);

  return {
    csvData,
    fileName,
  };
};

export default useTableCsvExportButton;

import { useEffect, useState } from "react";
import http from "../../common/utilities/HttpModule";
import { IMaintenanceStatusResponse } from "../interfaces";

const INTERVAL = 10000;

const useMaintenance = () => {
  const [maintenance, setMaintenance] = useState(false);

  const pollMaintenance = async (signal: AbortSignal) => {
    try {
      const { data } = await http.get<IMaintenanceStatusResponse>(
        "api/v1/app-state",
        {
          signal,
        }
      );
      setMaintenance(data.maintenance);
    } catch (e) {
      if (signal.aborted) {
        return;
      }
      console.error(e);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    pollMaintenance(controller.signal);
    const interval = setInterval(() => {
      pollMaintenance(controller.signal);
    }, INTERVAL);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, []);

  return maintenance;
};

export default useMaintenance;

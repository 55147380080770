import React from "react";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import App, { store } from "./app";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import "./appSetup";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithRedirect } from "./user";
import { themeConfig } from "./theme";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <ConfigProvider theme={themeConfig}>
      <React.StrictMode>
        <BrowserRouter>
          <Auth0ProviderWithRedirect>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </Auth0ProviderWithRedirect>
        </BrowserRouter>
      </React.StrictMode>
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

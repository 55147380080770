import React, { FC } from "react";
import "./HeaderActions.scss";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { GroupBlockData } from "../../../configMap";
import useHeaderActions from "../../hooks/useHeaderActions";

type Props = {
  groupBlock: GroupBlockData;
  index: number;
  disabled: boolean;
};

const HeaderActions: FC<Props> = ({ groupBlock, index, disabled }) => {
  const { t } = useTranslation("annotationSideControls");

  const { removeGroupBlocksForEntityAndAnnotations } =
    useHeaderActions(groupBlock);

  if (disabled) {
    return null;
  }

  return (
    <div className="actions-container">
      <Tooltip
        placement="topLeft"
        mouseEnterDelay={0.5}
        title={t("groupBlockTitleActions.deleteAllInstancesOfGroupBlockAction")}
      >
        <div
          className="delete-all-lines-icon"
          onClick={() => removeGroupBlocksForEntityAndAnnotations(index)}
        >
          <i className="bi bi-trash" />
        </div>
      </Tooltip>
    </div>
  );
};

export default HeaderActions;

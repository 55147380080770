import React, { FC } from "react";
import { Button } from "antd";
import "./PasswordChange.scss";
import { Input } from "antd/lib";
import { usePassword } from "../../../index";
import PasswordChecklist from "./PasswordChecklist";
import { useTranslation } from "react-i18next";
import { mapValidationToStatus } from "../../../utils/passwordUtils";

const PasswordChange: FC = () => {
  const {
    state,
    handleChange,
    handlePasswordUpdate,
    handleEditingClick,
    handleCancelClick,
    validation,
    isEditing,
  } = usePassword();

  const { t } = useTranslation("personalPage", {
    keyPrefix: "password",
  });

  if (isEditing) {
    return (
      <div className="password-change__container-editing">
        <div>
          <label>New password</label>
          <Input.Password
            status={mapValidationToStatus(validation)}
            name="newPassword"
            onChange={handleChange}
          />
          <PasswordChecklist password={state.newPassword} />
        </div>
        <div>
          <label>Repeat new password</label>
          <Input.Password
            status={mapValidationToStatus(validation, "notMatching")}
            name="repeatPassword"
            onChange={handleChange}
          />
        </div>
        {validation === "notMatching" ? (
          <label className="password-change__error">
            {t("validations.notMatching")}
          </label>
        ) : null}
        <div className="password-change__button-group">
          <Button onClick={handleCancelClick} block>
            {t("buttons.cancel")}
          </Button>
          <Button
            onClick={handlePasswordUpdate}
            disabled={validation !== undefined}
            block
            type="primary"
          >
            {t("buttons.update")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="password-change__container">
      <label>**********</label>
      <Button type="link" onClick={handleEditingClick}>
        {t("buttons.change")}
      </Button>
    </div>
  );
};

export default PasswordChange;

import React, { FC } from "react";

type Props = {
  invalidXmlString: string;
};

const InvalidXml: FC<Props> = ({ invalidXmlString }) => {
  return (
    <div className="invalid-xml__container">
      <code>{invalidXmlString}</code>
    </div>
  );
};

export default InvalidXml;

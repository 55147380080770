import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app";
import { useEffect } from "react";
import { setPdfData } from "../../document/store/documentSlice";

const useAnnotatorReset = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname.includes("detail")) {
      dispatch(setPdfData(null));
    }
  }, [dispatch, pathname]);
};

export default useAnnotatorReset;

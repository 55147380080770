import React, { FC } from "react";
import { Status } from "../../../../../common/status/status";
import StatusColoredLabel from "../../../../../common/statusColoredLabel/StatusColoredLabel";

type Props = {
  status: Status;
};

const StatusCell: FC<Props> = ({ status }) => {
  return <StatusColoredLabel status={status} />;
};

export default StatusCell;

import { RootState } from "../../app";
import { createSelector } from "@reduxjs/toolkit";
import { ConfigMap } from "../../configMap";

type Props = {
  categorizationId: string | undefined;
};

const selectConfigMap = (state: RootState) => state.configMapReducer.configMap;

const selectCategorizationId = (_: RootState, props: Props) =>
  props.categorizationId;

const selectIsCategorizationInSideControls = createSelector(
  selectConfigMap,
  selectCategorizationId,
  (configMap: ConfigMap | undefined, categorizationId: string | undefined) => {
    if (!configMap || !categorizationId) {
      return undefined;
    }

    const categorizationConfigMaps = configMap?.categorizationConfigMaps;
    const groupBlocks = configMap?.groupBlocks;

    const isInSideControls = categorizationConfigMaps?.find(
      (ccm) => ccm.categorization.id === categorizationId
    );
    if (isInSideControls) {
      return true;
    }

    const isInTableControls = groupBlocks?.some((gb) =>
      gb.categorizationGroupBlocks.some(
        (cgb) => cgb.categorization.id === categorizationId
      )
    );
    if (isInTableControls) {
      return false;
    }

    return undefined;
  }
);

export default selectIsCategorizationInSideControls;

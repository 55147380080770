import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InputType } from "../";

interface DocumentTypesState {
  inputTypes: Array<InputType>;
}

const initialState: DocumentTypesState = {
  inputTypes: [],
};

export const documentTypesSlice = createSlice({
  name: "documentTypes",
  initialState,
  reducers: {
    fetchedDocumentTypes: (state, action: PayloadAction<Array<InputType>>) => {
      state.inputTypes = action.payload;
    },
    resetDocumentTypesReducer: () => initialState,
  },
});

export const { fetchedDocumentTypes, resetDocumentTypesReducer } =
  documentTypesSlice.actions;

export default documentTypesSlice.reducer;

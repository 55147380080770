import React, { FC } from "react";

type Props = {
  visibleValue?: string;
};

const CategoryVisibleValue: FC<Props> = ({ visibleValue }) => {
  if (!visibleValue) {
    return null;
  }

  return (
    <label className="classifier-item__visible-value">{visibleValue}</label>
  );
};

export default CategoryVisibleValue;

import React, { FC } from "react";
import { ColumnItem, CustomCellType } from "../../../../interfaces/overview";
import { DocumentSetData } from "../../../../interfaces/documentSet";
import "./CopyableTypeCell/CopyableTypeCell.scss";
import useCustomCell from "../../../../hooks/useCustomCell";
import TextTypeCell from "./TextTypeCell/TextTypeCell";
import CopyableTypeCell from "./CopyableTypeCell/CopyableTypeCell";

type Props = {
  documentSet: DocumentSetData;
  columnItem: ColumnItem;
};

const CustomCellSwitch: FC<Props> = ({ documentSet, columnItem }) => {
  const { customCellType } = useCustomCell(columnItem);

  if (customCellType === CustomCellType.COPYABLE) {
    return (
      <CopyableTypeCell documentSet={documentSet} columnItem={columnItem} />
    );
  }

  return <TextTypeCell documentSet={documentSet} columnItem={columnItem} />;
};

export default CustomCellSwitch;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getVariableFromEnv } from "../../common/utilities/env";
import { MetricFilterItem } from "../../analytics/interfaces/documentSetMetrics";
import { setMetricsMap } from "./appSlice";
import {
  getHeadersWithAuthorization,
  parseReduxQueryError,
} from "../../common/reduxQuery/helpers";
import { OverviewConfig } from "../../documentSet/interfaces/overview";

const API_BASE_URL = getVariableFromEnv("API_BASE_URL");

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers) => getHeadersWithAuthorization(headers),
  }),
  endpoints: (build) => ({
    getMetricFilterItems: build.query<Array<MetricFilterItem>, Array<string>>({
      query: (inputIds) => ({
        url: `api/v1/metrics/${inputIds.join(",")}`,
        method: "GET",
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setMetricsMap(data));
        } catch (err: any) {
          console.error(
            `An error occurred while retrieving the metric filter items: ${parseReduxQueryError(
              err
            )}`
          );
        }
      },
    }),
    getAppConfig: build.query<OverviewConfig, Array<string>>({
      query: (inputIds) => ({
        url: `api/v1/app-config/${inputIds.join(",")}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetMetricFilterItemsQuery, useLazyGetAppConfigQuery } =
  appApi;

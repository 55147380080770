import { useAppDispatch, useAppSelector } from "../../app";
import { useCallback } from "react";
import { changeScrollToEntity } from "../../app/store/appSlice";
import { MIN_TABLE_CONTROLS_HEIGHT } from "../../annotationTableControls/constants";
import { setSelectedGroupBlockId } from "../../annotationTableControls/store/AnnotationTableControlsSlice";
import entityWithGroupBlockSelector from "../../configMap/selectors/entityWithGroupBlockSelector";
import useSetGroupBlockTabHeightByLines from "../../annotationTableControls/hooks/useSetTableControlsHeight";

const useFindAnnotation = (entityId: string, index: number) => {
  const dispatch = useAppDispatch();

  const entityWithGroupBlock = useAppSelector((state) =>
    entityWithGroupBlockSelector(state, { entityId, index })
  );

  const { tableControlsHeight } = useAppSelector(
    (state) => state.annotationTableControlsReducer
  );

  const { setTableControlsHeightByLines } = useSetGroupBlockTabHeightByLines();

  return useCallback(() => {
    if (!entityWithGroupBlock) {
      return;
    }

    const { groupBlock } = entityWithGroupBlock;

    if (groupBlock.multipleGroupBlocks && groupBlock.id) {
      if (tableControlsHeight === MIN_TABLE_CONTROLS_HEIGHT) {
        setTableControlsHeightByLines();
      }

      dispatch(setSelectedGroupBlockId(groupBlock.id));
    }

    dispatch(changeScrollToEntity(entityWithGroupBlock.entity));
  }, [
    dispatch,
    entityWithGroupBlock,
    tableControlsHeight,
    setTableControlsHeightByLines,
  ]);
};

export default useFindAnnotation;

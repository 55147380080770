import useActiveDocument from "../../document/hooks/useActiveDocument";
import { useMemo } from "react";
import {
  HOTKEY_WIDTH,
  PERCENTAGE_WIDTH,
  REMOVE_ICON_WIDTH,
} from "../constants";
import { GroupBlockEntityType } from "../../configMap";
import useEntity from "../../configMap/hooks/useEntity";
import useAnnotationValidation from "./useAnnotationValidation";
import classNames from "classnames";
import useEntityAnnotation from "../../configMap/hooks/useEntityAnnotation";
import { calculateTextWidth } from "../../common/utilities/text";

const useEntityCell = (
  groupBlockEntityType: GroupBlockEntityType,
  groupBlockIndex: number,
  disabled: boolean,
  requiresAttention: boolean,
  cellWidth: number
) => {
  const {
    annotationsForEntity,
    annotationForEntity,
    removeAnnotation,
    editingKey,
    handleEntityClick,
    isEditing,
    isAnnotating,
  } = useEntity(groupBlockEntityType, groupBlockIndex, disabled, true);

  const { activeDocument } = useActiveDocument();

  const {
    entityAnnotations,
    value,
    normalizedValue,
    validationClasses,
    validationMessage,
  } = useAnnotationValidation(
    groupBlockEntityType,
    annotationsForEntity,
    groupBlockIndex,
    requiresAttention
  );

  const listItemClassNames = classNames({
    "list-item": true,
    "is-editing": isEditing === editingKey,
    "to-many-annotations": annotationsForEntity?.length > 1,
    disabled,
  });

  const { hasNormalizedValue } = useEntityAnnotation(annotationForEntity);

  const cellClasses = useMemo(() => {
    if (isEditing === editingKey) {
      return "";
    }

    if (annotationForEntity.isLoading) {
      return "";
    }

    if (!validationClasses && hasNormalizedValue) {
      return "annotated";
    }

    return validationClasses;
  }, [
    validationClasses,
    isEditing,
    editingKey,
    annotationForEntity.isLoading,
    hasNormalizedValue,
  ]);

  const textWidthValue = useMemo(
    () => calculateTextWidth(normalizedValue ?? value ?? ""),
    [normalizedValue, value]
  );

  const textWidth = useMemo(() => {
    return cellWidth - HOTKEY_WIDTH - REMOVE_ICON_WIDTH - PERCENTAGE_WIDTH;
  }, [cellWidth]);

  const isFirstAnnotation = useMemo(() => {
    return annotationForEntity.index === 0;
  }, [annotationForEntity.index]);

  const isEditable = useMemo(() => {
    return isFirstAnnotation || annotationsForEntity.length === 0;
  }, [isFirstAnnotation, annotationsForEntity.length]);

  const modelScore = useMemo(() => {
    if (!annotationsForEntity || annotationsForEntity.length === 1) {
      return annotationForEntity.modelScore;
    }

    const total = annotationsForEntity
      .map((a) => {
        if (!a.modelScore) {
          return 100;
        }

        return a.modelScore;
      })
      .reduce((acc, curr) => acc + curr, 0);

    return total / annotationsForEntity.length;
  }, [annotationsForEntity, annotationForEntity.modelScore]);

  return {
    cellClasses,
    textWidthValue,
    textWidth,
    validationMessage,
    validationClasses,
    hasNormalizedValue,
    value,
    normalizedValue,
    annotationsForEntity,
    handleEntityClick,
    removeAnnotation,
    isAnnotating,
    entityAnnotations,
    activeDocument,
    listItemClassNames,
    annotationForEntity,
    isEditing,
    editingKey,
    isFirstAnnotation,
    isEditable,
    modelScore,
  };
};

export default useEntityCell;

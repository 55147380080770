import { useInView } from "react-intersection-observer";
import { useCallback, useRef } from "react";
import { useAppDispatch } from "../../app";
import { setCurrentPage } from "../../annotator/store/AnnotatorSlice";

const useTrackCurrentPageNumber = (page: number) => {
  const initialSkipped = useRef(false);
  const dispatch = useAppDispatch();

  const handleChangeCurrentPage = useCallback(() => {
    if (initialSkipped.current) {
      initialSkipped.current = true;
      return;
    }

    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  const { ref } = useInView({
    threshold: 0.9,
    onChange: handleChangeCurrentPage,
  });

  return ref;
};

export default useTrackCurrentPageNumber;

import React, { FC, useMemo } from "react";
import TableBorder from "./table/TableBorder";
import { ITable } from "../../../interfaces/ITable";
import TableBody from "./table/TableBody";
import LinkTableButton from "./table/LinkTableButton";
import useTableUpdater from "../../../hooks/useTableUpdater";
import { useAppSelector } from "../../../../app";

interface Props {
  table: ITable;
  isCreatingTable: boolean;
  zIndex: number;
}

export const TABLE_OFFSET = 10;

const Table: FC<Props> = ({ table, isCreatingTable, zIndex }) => {
  const { tables } = useAppSelector(
    (state) => state.annotatorReducer.tableState
  );
  const { removeTable } = useTableUpdater();

  const isFirstTable = useMemo(
    () => tables.findIndex((t) => t.id === table.id) <= 0,
    [tables, table]
  );

  const style = useMemo(() => {
    return {
      width: `${table.width}px`,
      height: `${table.height}px`,
      top: table.y,
      left: table.x,
    };
  }, [table]);

  return (
    <div className="table-builder__border" style={{ zIndex }}>
      <span
        className="bi bi-trash table-builder__border-trash-icon"
        onClick={() => removeTable(table)}
        style={{
          visibility: isCreatingTable ? "visible" : "hidden",
          top: style.top - 20,
          left: style.left - 20,
          zIndex: zIndex + 1,
        }}
      />
      <LinkTableButton
        visible={!isFirstTable && !table.linkedTables.length && isCreatingTable}
        tableId={table.id}
        top={table.y - 45}
        left={table.width / 2 - 15 + table.x}
        page={table.page}
      />
      <TableBorder
        type="top"
        style={{
          ...style,
          top: table.y - TABLE_OFFSET,
          height: "10px",
          backgroundColor: "#69c0ff",
          zIndex,
        }}
        tableId={table.id}
        isCreatingTable={isCreatingTable}
        width={table.width}
        height={table.height}
        page={table.page}
      />
      <TableBorder
        type="left"
        style={{
          ...style,
          left: table.x - TABLE_OFFSET,
          width: "10px",
          backgroundColor: "#69c0ff",
          zIndex,
        }}
        tableId={table.id}
        isCreatingTable={isCreatingTable}
        width={table.width}
        height={table.height}
        page={table.page}
      />
      <TableBody
        isCreatingTable={isCreatingTable}
        table={table}
        style={style}
      />
    </div>
  );
};

export default Table;

import { useCallback } from "react";
import { RootState, useAppSelector } from "../../app";
import { useMultipleGroupBlocks } from "../../configMap";
import {
  MULTI_LINE_ROW_HEIGHT,
  MULTI_LINE_ROW_PADDING,
  SCROLLBAR_HEIGHT,
  SINGLE_ROW_HEIGHT,
} from "../constants";

const useCalculateTableRowsHeightInPx = () => {
  const { tableControlAnnotations } = useAppSelector(
    (state: RootState) => state.annotationReducer
  );
  const groupBlocks = useAppSelector(
    (state: RootState) => state.configMapReducer.configMap?.groupBlocks
  );

  const { countPerGroupBlockId } = useMultipleGroupBlocks();

  const getEntityIdsForGroupBlock = useCallback(
    (groupBlockId: string): string[] => {
      if (!groupBlocks) {
        return [];
      }

      const groupBlock = groupBlocks.find((gb) => gb.id === groupBlockId);
      if (!groupBlock) {
        return [];
      }

      return groupBlock?.groupBlockEntityTypes.map(
        (gbet) => gbet.entityType.id
      );
    },
    [groupBlocks]
  );

  const getCountOfRowsPerIndexAndEntity = useCallback(
    (entityIds: string[]): Record<number, Record<string, number>> => {
      return tableControlAnnotations
        .filter((annotation) => entityIds.includes(annotation?.entity?.id))
        .reduce<Record<number, Record<string, number>>>((acc, annotation) => {
          const index = annotation.index!;
          const entityId = annotation.entity.id;

          if (!acc[index]) {
            acc[index] = {};
          }

          acc[index][entityId] = (acc[index][entityId] || 0) + 1;
          return acc;
        }, {});
    },
    [tableControlAnnotations]
  );

  const calculateRowHeight = useCallback(
    (
      countOfRowsPerIndexAndEntity: Record<number, Record<string, number>>
    ): number => {
      return Object.values(countOfRowsPerIndexAndEntity).reduce(
        (totalHeight, entityCounts) => {
          const highestRows = Math.max(...Object.values(entityCounts));
          const rowHeight =
            highestRows === 1
              ? SINGLE_ROW_HEIGHT
              : highestRows * MULTI_LINE_ROW_HEIGHT + MULTI_LINE_ROW_PADDING;
          return totalHeight + rowHeight;
        },
        0
      );
    },
    []
  );

  const calculateEmptyRowsHeight = useCallback(
    (totalRows: number, filledRows: number): number => {
      const emptyRows = totalRows - filledRows;
      if (emptyRows <= 0) {
        return 0;
      }

      return emptyRows * SINGLE_ROW_HEIGHT;
    },
    []
  );

  return useCallback(
    (groupBlockId: string): number => {
      const totalRows = countPerGroupBlockId(groupBlockId);

      const entityIds = getEntityIdsForGroupBlock(groupBlockId);
      if (entityIds.length === 0) {
        return SINGLE_ROW_HEIGHT;
      }

      const countOfRowsPerIndexAndEntity =
        getCountOfRowsPerIndexAndEntity(entityIds);

      const filledRowsHeight = calculateRowHeight(countOfRowsPerIndexAndEntity);

      const emptyRowsHeight = calculateEmptyRowsHeight(
        totalRows,
        Object.keys(countOfRowsPerIndexAndEntity).length
      );

      const totalHeight = filledRowsHeight + emptyRowsHeight;
      if (totalHeight < SINGLE_ROW_HEIGHT) {
        return SINGLE_ROW_HEIGHT + SCROLLBAR_HEIGHT;
      }

      return totalHeight + SCROLLBAR_HEIGHT;
    },
    [
      countPerGroupBlockId,
      getEntityIdsForGroupBlock,
      getCountOfRowsPerIndexAndEntity,
      calculateRowHeight,
      calculateEmptyRowsHeight,
    ]
  );
};

export default useCalculateTableRowsHeightInPx;

export interface IEntityStpTableData {
  key: string;
  id: string | null;
  entityValidation: StpEntityValidation | null;
  threshold: number;
  entityTypeId: string;
  sources?: Array<string>;
  isActive: boolean;
  name: string;
  documentTypeId?: string;
}

export interface AddStpEntity {
  id: string | null;
  entityValidation: StpEntityValidation | null;
  threshold: number;
  entityTypeId?: string;
  sources?: Array<string>;
  isActive: boolean;
}

export interface IStpEntityResponse {
  id: string | null;
  entityValidation: StpEntityValidation | null;
  threshold: number;
  isActive: boolean;
  sources?: Array<string>;
  documentTypeId: string;
  entityTypeId: string;
}

export interface IStpSupplierTableData {
  key: string;
  id: string;
  supplierId: string;
  vat: string;
  source: string;
}

export interface IStpSupplierResponse {
  id: string;
  supplierId: string;
  vatNumber: string | undefined;
  organizationId: string | undefined;
  input: ISupplierInput | undefined;
}

export interface ISupplierInput {
  id: string;
  displayName: string;
}

export enum StpEntityValidation {
  NUMERIC = "NUMERIC",
  WEEK = "WEEK",
  CHARACTER = "CHARACTER",
}

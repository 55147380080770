import React, { FC, useRef } from "react";
import { GroupBlockEntityType } from "../../";
import EntityHotkey from "./EntityHotkey";
import { annotationsForEntitySelector } from "../../../annotation";
import useActiveDocument from "../../../document/hooks/useActiveDocument";
import "./EntityWrapper.scss";
import Entity from "./Entity";
import useEntityAttention from "../../hooks/useEntityAttention";
import { Visibility } from "../../hooks/useIntersectionObserver";
import { useAppSelector } from "../../../app";

type Props = {
  groupBlockEntityType: GroupBlockEntityType;
  groupBlockIndex: number;
  hideNonRequiredEntities: boolean;
  disabled: boolean;
  visibility: Visibility;
};

const EntityWrapper: FC<Props> = (props) => {
  const {
    hideNonRequiredEntities,
    groupBlockEntityType,
    groupBlockIndex,
    visibility,
  } = props;

  const { activeDocument } = useActiveDocument();

  const annotationsForEntity = useAppSelector((state) =>
    annotationsForEntitySelector(state, {
      documentId: activeDocument?.id,
      entityId: groupBlockEntityType.entityType.id,
      groupBlockIndex,
    })
  );

  const { requiresScoreAttention } = useEntityAttention(annotationsForEntity);

  const elRef = useRef<HTMLDivElement>(null);

  if (
    hideNonRequiredEntities &&
    !annotationsForEntity.length &&
    !groupBlockEntityType.required
  ) {
    return null;
  }

  if (visibility !== Visibility.VISIBLE) {
    return (
      <div className="entity-placeholder__container" ref={elRef}>
        <EntityHotkey {...props} />
      </div>
    );
  }

  return (
    <div ref={elRef}>
      <Entity {...props} requiresAttention={requiresScoreAttention} />
    </div>
  );
};

export default EntityWrapper;

import React, { ChangeEvent, useState } from "react";
import { Annotation } from "../../../../annotator/interfaces/annotation";

interface Props {
  showInput: boolean;
  annotation: Annotation;
  updateAnnotation: (
    annotation: Annotation,
    multipleGroupBlocks: boolean
  ) => void;
}

const AreaTextAnnotation = ({
  showInput,
  annotation,
  updateAnnotation,
}: Props) => {
  const [value, setValue] = useState(annotation.areaAnnotation?.text || "");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    updateAnnotation(
      {
        ...annotation,
        areaAnnotation: {
          ...annotation.areaAnnotation!,
          text: value,
        },
      },
      annotation.multipleGroupBlocks!
    );
  };

  return (
    <input
      className={`area-annotation__text-input ${
        showInput ? "" : "hidden"
      }`.trim()}
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default AreaTextAnnotation;

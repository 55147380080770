export enum Status {
  ToDo = "TO_DO",
  InProgress = "IN_PROGRESS",
  OnHold = "ON_HOLD",
  Processing = "PROCESSING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Archived = "ARCHIVED",
  Exported = "EXPORTED",
  Error = "ERROR",
  ExportFailed = "EXPORT_FAILED",
  Remove = "REMOVE",
  AnnotationToDo = "ANNOTATION_TO_DO",
  AnnotationInProgress = "ANNOTATION_IN_PROGRESS",
  AnnotationOnHold = "ANNOTATION_ON_HOLD",
  AnnotationDone = "ANNOTATION_DONE",
}

export const statuses = Object.values(Status);
export const todoStatuses = statuses.slice(0, 4);
export const doneStatuses = statuses.slice(4, 8);
export const errorStatuses = statuses.slice(8, 10);
export const annotationTodoStatuses = [
  Status.Approved,
  Status.Exported,
  ...statuses.slice(11, 14),
];
export const annotationDoneStatuses = [Status.AnnotationDone];

export const determineNonErrorStatus = (
  status: Status,
  defaultStatus = Status.InProgress
) => {
  if (status === Status.Error) {
    return Status.Error;
  }

  if (status === Status.ExportFailed) {
    return Status.ExportFailed;
  }

  return defaultStatus;
};

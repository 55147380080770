import { useAppSelector } from "../../../app";
import { useMemo } from "react";

const useAccountPopover = () => {
  const user = useAppSelector((state) => state.userReducer.user);

  const avatar = useMemo(() => {
    if (!user) {
      return "";
    }

    const { avatar, defaultAvatar } = user;

    return avatar ? avatar : defaultAvatar;
  }, [user]);

  return {
    user,
    avatar,
  };
};

export default useAccountPopover;

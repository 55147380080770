import React, { FC } from "react";
import { PillLabel } from "../../../../common";
import { Badge, Popover } from "antd";
import ReactHtmlParser from "html-react-parser";
import Timestamp from "../Timestamp";
import { DocumentSetData } from "../../../../documentSet";

type Props = {
  activeDocumentSet: DocumentSetData;
};

const DisabledButtonState: FC<Props> = ({ activeDocumentSet }) => {
  const replyText = activeDocumentSet.email?.emailReply?.replyText;
  return (
    <div className="footer-status">
      <div className="buttons__container">
        <PillLabel status={activeDocumentSet.status} />
        {replyText ? (
          <Popover
            placement="topRight"
            title={
              <div className="reply-body__header">
                <h4>Reply send to:</h4>
                <h5>
                  {activeDocumentSet.email?.sender?.name}{" "}
                  {`<${activeDocumentSet.email?.sender?.address}>`}
                </h5>
              </div>
            }
            content={ReactHtmlParser(replyText)}
            overlayClassName="reply-body"
            trigger="click"
          >
            <Badge count={1} overflowCount={9} className="reply-count">
              <div className="footer-reply-button">
                <i className="bi bi-hand-thumbs-down" />
              </div>
            </Badge>
          </Popover>
        ) : null}
      </div>
      <Timestamp />
    </div>
  );
};

export default DisabledButtonState;

import { useCookies } from "react-cookie";
import { useMemo } from "react";

export const COOKIE_NAME = "accessToken";

const useAuthenticationCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

  const cookie = useMemo(() => {
    if (cookies?.accessToken) {
      return cookies.accessToken;
    }

    return null;
  }, [cookies]);

  return {
    cookie,
    setCookie,
    removeCookie,
  };
};

export default useAuthenticationCookie;

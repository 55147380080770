import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { FC } from "react";
import { getVariableFromEnv } from "../../common/utilities/env";
import useNavigateWithState from "../../common/navigate/hooks/useNavigateWithState";

type Props = {
  children: React.ReactNode;
};

const Auth0ProviderWithRedirect: FC<Props> = ({ children }) => {
  const { navigateWithState } = useNavigateWithState();

  const onRedirectCallback = (appState?: AppState) => {
    navigateWithState(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={getVariableFromEnv("AUTH0_DOMAIN")}
      clientId={getVariableFromEnv("AUTH0_CLIENT_ID")}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: getVariableFromEnv("AUTH0_AUDIENCE"),
      }}
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirect;

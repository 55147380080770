import React, { FC } from "react";

type Props = {
  excluded: boolean;
  top: number;
  width: number;
  height: number;
};

const ExcludedRow: FC<Props> = ({ excluded, top, width, height }) => {
  if (!excluded) {
    return null;
  }

  return (
    <div className="excluded-row__container" style={{ top, width, height }} />
  );
};

export default ExcludedRow;
